<template>
  <div>
    <nav-bar />
    <div class="main">
      <section>
        <div class="container-fluid">
          <div class="row mt45Tab">
            <div class="col-md-12 col-lg-12 p-0">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-bg.jpg"
                alt="Business Image"
                class="w-100 img-fluid"
              />
              <h2 class="headGreenBg mb-0">MYLE Affiliate</h2>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-6 rotateDiv d-flex align-items-center">
              <div class="mtBusiness">
                <h5 class="">What is a MYLE Affiliate?</h5>
                <p>
                  A MYLE Affiliate is an influencer, organization or company who
                  refers MYLE to others in their network to help increase their
                  exposure to entertainment experiences or increase their
                  audience size, if business or event related.
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6 mtBusiness d-flex justify-content-center"
            >
              <div class="">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-1.svg"
                  alt="Affiliate-1"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-6 d-flex justify-content-center">
              <div class="mt-5">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-2.svg"
                  alt="Affiliate-2"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6 d-flex align-items-center">
              <div class="mt-5">
                <h5>How can I monetize the MYLE Affiliate program?</h5>
                <p>
                  The MYLE Affiliate program is designed for you to earn points
                  that may be redeemed for commission payouts. When you share
                  MYLE with your network via the MYLE affiliate link, your
                  account will be associated with the new MYLER who registers
                  through the link that you share.<br /><br />
                  You will receive points each time a new member signs up with
                  your affiliate link. You will also receive points each time
                  they complete an advertising purchase or ticket transaction on
                  MYLE.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-6 rotateDiv d-flex align-items-center">
              <div class="mt-5">
                <h5>How do I become a MYLE Affiliate?</h5>
                <p>
                  Once you register your account on MYLE and become a MYLER, you
                  will be eligible to participate. In order to began receiving
                  points, you will need to share the invitation link/referral
                  code with a potential MYLER. During the registration process
                  on MYLE, they will enter your referral code. Reference the
                  step by step guide below to Get Started.
                </p>

                <div class="download-btn mt-4 d-flex">
                  <a
                    href="https://drive.google.com/file/d/1KgtL5TVtvWIdfp1KrHm_x9J17Ou7pb_9/view"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class=""></span> Step By Step Guide</a
                  >

                  <a
                    href="https://myle.com/app/MYLEMARKERS"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class=""></span> Get The App
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 d-flex justify-content-center">
              <div class="mt-5">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-3.svg"
                  alt="Affiliate-3"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-6 col-lg-6 d-flex justify-content-center">
              <div class="mt-5">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-4.svg"
                  alt="Affiliate-4"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6 d-flex align-items-center">
              <div class="mt-5">
                <h5>How much will I earn?</h5>
                <p>
                  You can start earning commission when your referrals make
                  qualifying purchases which will be tied to your affiliate
                  link. The amount you earn is based on the services sold and
                  points acquired. Commission rates may be found on the chart
                  below and will also be visible in points on your MYLE
                  Affiliate/Referral Dashboard (on the MYLE App).
                </p>

                <div class="download-btn mt-4 d-flex">
                  <a
                    href="https://drive.google.com/file/d/1KgtL5TVtvWIdfp1KrHm_x9J17Ou7pb_9/view"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class=""></span> Step By Step Guide</a
                  >

                  <a
                    href="https://myle.com/app/MYLEMARKERS"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class=""></span> Get The App
                  </a>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="section-heading text-center">
            <h2>Referral Points Distribution</h2>
          </div>
          <hr />
          <div
            class="col-md-12 col-lg-12 d-flex align-items-center justify-content-center mb-5"
          >
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/referral_points.png"
              alt="Referral Points Distribution"
              class="img-fluid"
              style="width: 65%"
            />
          </div>
        </div>
      </section>
    </div>
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },

  metaInfo: {
    title: "MYLE Affiliate Marketing Program | Get Paid for Referrals",

    meta: [
      {
        name: "description",
        content:
          "Join MYLE affiliate marketing program and get paid for your referrals. MYLE offers commission and all the support you need. Sign up here.",
      },

      {
        name: "keywords",
        content:
          "events affiliate program | event affiliate marketing | monetize traffic affiliate marketing | monetizing blogs affiliate program",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/affiliate",
      },
    ],
  },
};
</script>
