<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_StateOfBlackMensHealth.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Times New Roman';
                font-weight: 300;
                font-size: 50px;
              "
            >
              MEN's HEALTH SUMMIT
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                In honor of this year's Men's Health Month, Platinum Men’s
                Health is proud to announce its collaboration with Raise Your
                Glass Promotion to present the inaugural Fully Alive Men’s
                Health Summit. Thanks to our presenting sponsor, Florida Blue,
                this groundbreaking series will shine a spotlight on men’s
                health and well-being, offering valuable insights, resources,
                and inspiration to men everywhere. This community initiative is
                aimed at not only the men, but just as importantly, to the women
                who can support the men in their lives and help them live
                longer, healthier lives. We want to engage and educate all women
                (wives, mothers, sisters, aunties, daughters, nieces,
                granddaughters and friends).

                <br /><br />
                The Fully Alive Men’s Health Summit will explore a wide range of
                topics, including physical health, mental wellness, nutrition,
                fitness, and more. Through engaging presentations, and panel
                testimonials, attendees will gain valuable knowledge and
                practical strategies for living their healthiest, most
                fulfilling lives.
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/mens-health-brunch" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthMensHealthBrunch.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            29 <br />
            June
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/mens-health-brunch"
            target="_self"
            style="color: black"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
              "
            >
              Men's Health Brunch
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
              "
            >
              Saturday, June 29, 2024<br />
              11:00am - 02:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/mens-health-brunch"
                  class="btn solid-btn-SWA mb-1 text-center"
                  target="_self"
                  >Register
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/EventHeader-01-StateBlackMensHealth-titleOnly.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            01 <br />
            June
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
            style="color: black"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
              "
            >
              State of Black Men's Health with Dr. Adewale
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
              "
            >
              Saturday, June 01, 2024<br />
              11:30am - 12:30pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://www.youtube.com/@platinummenshealth3576"
                  class="btn solid-btn-SWA mb-1 text-center"
                  target="_self"
                  >Watch the Replay
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthFiretalkTVLivestream.jpeg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            08 <br />
            June
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp"
            target="_self"
            style="color: black"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
              "
            >
              Firetalk TV Livestream
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
              "
            >
              Saturday, June 08, 2024<br />
              12:00pm - 01:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp"
                  class="btn solid-btn-SWA mb-1 text-center"
                  target="_self"
                  >Watch the Replay
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthYouAreWhatYouEatLivestream.jpeg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            15 <br />
            June
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
            style="color: black"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
              "
            >
              You are What you Eat Livestream
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
              "
            >
              Saturday, June 15, 2024<br />
              01:00pm - 02:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://www.youtube.com/@platinummenshealth3576"
                  class="btn solid-btn-SWA mb-1 text-center"
                  target="_self"
                  >Watch the Replay
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthFunctionalMobilityForMen.jpeg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            22 <br />
            June
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://www.youtube.com/@platinummenshealth3576"
            target="_self"
            style="color: black"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
              "
            >
              Functional Mobility for Men
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
              "
            >
              Saturday, June 22, 2024<br />
              12:00pm - 01:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://www.youtube.com/@platinummenshealth3576"
                  class="btn solid-btn-SWA mb-1 text-center"
                  target="_self"
                  >Watch the Replay
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />

      <div
        class="row w-100 m-0 promo-section MYLE-green"
        style="background-color: #304cb2"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center text-white">
                DOWNLOAD MYLE TODAY TO GET STARTED
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 
          <div
            class="row align-items-center justify-content-between test"
            style="margin: auto; margin-top: 20px"
          >
            <div class="col-lg-3 col-md-3">
              <img
                class="align-middle"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
                style="width: 100%; max-width: 100%; height: auto"
              />
            </div>
            <div class="col-lg-9 col-md-9">
              <p
                class="mt-0 mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 24px;
                  background-color: #ac1e2e;
                  color: white;
                "
              >
                Get ready for The 86th Kappa Konclave with MYLE
              </p>
    
              <p
                class="mb-2 ml-4"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                • Find all of the “Konclave on the Bay” events in MYLE
                <br />
                • Buy your event tickets with MYLE
                <br />
                • Keep all of your digital tickets in MYLE for easy and fast
                check-in
                <br />
                • Help the local chapter earn referral points – use referral code
                “TampaAlumniChapter” during account set-up
                <br />
                • Receive important updates before and during the event
                <br />
                • Get around Tampa seamlessly using the MYLE app for transportation
                options including Waze/Apple Maps/Google Maps/Uber and Lyft –
                integrated into MYLE mobile.
                <br />
                • Find other fun things to do in Tampa or on the way!
                <br />
              </p>
            </div>
          </div> -->

      <!-- <div class="row">
                <div class="col-md-6 col-lg-6 align-items-center">
                  <a
                    href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                        alt="Saturday Event"
                        class="w-100 img-fluid"
                      />
                    </div>
        
                    <p
                      class="text-center lead-heading ml-3 mb-0"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      For The Kings | Juneteenth + Father's Day Brunch
                    </p>
                    <p
                      class="text-center lead-heading ml-3 mb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 300;
                        font-size: 16px;
                      "
                    >
                      Sun, Jun 19, 12:00 PM
                    </p>
                  </a>
                </div>
              </div> -->
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Black Men's Health Collection",

    meta: [
      {
        name: "description",
        content: "MYLE - Black Men's Health Collection",
      },

      {
        name: "keywords",
        content: "MYLE - Black Men's Health Collection",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/black-mens-health-collection",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
