var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"screenshots-section ptb-100",class:_vm.isGray ? 'MYLE-green' : 'primary-bg',attrs:{"id":"screenshots"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"section-heading text-center"},[_c('h2',{staticClass:"white",class:_vm.isGray ? '' : 'text-white'},[_vm._v(" Check out some of the best MYLE app features ")])])])]),_c('div',{staticClass:"screen-slider-content mt-5"},[_c('div',{staticClass:"screenshot-frame"}),_c('carousel',{staticClass:"screen-carousel owl-carousel owl-theme dot-indicator owl-loaded owl-drag",attrs:{"autoplay":true,"dots":true,"loop":true,"responsive":{
          0: { items: 1 },
          768: { items: 3 },
          991: { items: 4 },
          1200: { items: 4 },
          1920: { items: 4 },
        },"margin":0,"center":true,"nav":false}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-1.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-2.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-3.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-4.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-5.png","alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-6.png","alt":"screenshots"}})])],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center d-flex ptb-50"},[_c('div',{staticClass:"col-md-12 justify-content-center"},[_c('h2',{staticClass:"white text-center"},[_vm._v(" DOWNLOAD OR REGISTER NOW "),_c('br'),_vm._v(" FIND THOUSANDS OF EVENTS!!! ")]),_c('div',{staticClass:"download-btn mt-4 animate__animated animate__fadeIn animate__delay-2s d-flex justify-content-center"},[_c('a',{staticClass:"btn solid-btn-white mr-3",attrs:{"href":"https://web.myle.com/post-event","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{}),_vm._v(" Create An Event")]),_c('a',{staticClass:"btn solid-btn-white mr-3",attrs:{"href":"https://web.myle.com/login","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{}),_vm._v(" Register/Login")]),_c('a',{staticClass:"btn solid-btn-white",attrs:{"href":"https://myle.com/app/MYLEMARKERS","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{}),_vm._v(" Get The App ")])])])])
}]

export { render, staticRenderFns }