<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="reservedMeal = ''"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Blw0jHEMfysqkAH'
              "
            >
              <label class="mt-3">Player Names</label>
              <textarea
                class="form-control"
                v-model="reservedMeal"
                rows="3"
              ></textarea>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                <div
                  class="row col-md-12 mt-4"
                  v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                >
                  <div class="col-md-6">
                    <label class="">Do you have a promo code?</label>

                    <input
                      type="text"
                      v-model="redeempromo"
                      class="form-control"
                      placeholder="Promo code"
                    />
                  </div>

                  <div class="col-md-4">
                    <button
                      type="button"
                      @click="redeem()"
                      :disabled="!redeempromo.trim().length"
                      class="btn solid-btn btn-sm my-auto"
                      id="reserve"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        padding: 7px;
                        margin-top: 11px !important;
                      "
                    >
                      Redeem
                    </button>
                  </div>
                </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  (TicketsResult[selected].ticketCode == 'tk_Blw0jHEMfysqkAH'
                    ? !reservedMeal.trim().length
                    : false) ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_3rd_big_golf.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: pink">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                The Big Golf Weekend
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Benefiting The Pink Is Possible Non-Profit Breast Cancer
                Awareness Organization.

                <br /><br />
                Ticket & Sponsor Info:
                <br /><br />
              </p>
              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                FRIDAY (MEET & GREET PARTY)
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Meet & Greet Party (EARLY BIRD) - $10<br />
                *Meet & Greet Party (General Admission) - $20<br />
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                SATURDAY (THE BIG SCRAMBLE)
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Scramble Single Golfer(Early Bird)-$125<br />
                *Scramble Foursome(Early Bird)-$500<br />
                <br />
                *Scramble Single Golfer-$150<br />
                *Scramble Foursome - $600<br />
                <br />
                *Back In The Day Old School Party, (EARLY BIRD) - $10<br />
                *Back In The Day Old School Party (General Admission) - $20<br />
              </p>
              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                SUNDAY (THE BIG SKINS GAME 7)
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Skins Game Single Golfer(Early Bird) - $125<br />
                *Skins Game Single Golfer - $150
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                THE BIG GOLF WEEKEND PASS (INDIVIDUAL - Early Bird) - $270
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Meet & Greet Party Early Bird - $10 <br />
                *Scramble Single Golfer - $125 <br />
                *Back In The Day (Old School Party) Early Bird - $10 <br />
                *Skins Game Single Golfer - $125 <br />
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                THE BIG GOLF WEEKEND PASS (INDIVIDUAL - General Admission) -
                $320
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Meet & Greet Party Early Bird - $10<br />
                *Scramble Single Golfer - $150<br />
                *Back In The Day (Old School Party) General Admission - $10<br />
                *Skins Game Single Golfer - $150<br />
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                Deposits (Non-Refundable)
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                *Scramble Single Golfer - $75 <br />
                *Scramble Foursome - $300 <br />
                *Skins Game Single Golfer - $75 <br />
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                SPONSORSHIPS
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                $10,000 – Title Sponsor:
                <br />

                o Large Banner with your business name/logo displayed at
                luncheon and in golfer registration area.<br />
                o Banner with your business name/logo displayed at awards
                banquet.<br />
                o Spotlight feature of your company in the promotional recap
                video of the event.<br />
                o Space provided for your business to set up a booth/display on
                the course.<br />
                o Business name/logo on all marketing material (print & social
                media).<br />
                o Business name announced during the event.<br />
                o Marketing material with your business name/logo distributed at
                the event.<br />
                o Sign with your business name/logo displayed on a tee box

                <br /><br />

                $5,000 – Luncheon:
                <br />

                o Large banner with your business name/logo displayed at
                luncheon. <br />
                o Banner with your business name/logo displayed in golfer
                registration area. <br />
                o Space provided for your business to set up a booth/display on
                the course.<br />
                o Business name/logo on all marketing material (print & social
                media). <br />
                o Business name announced during the event. <br />
                o Marketing material with your business name/logo distributed at
                the event. <br />
                o Sign with your business name/logo displayed on a tee box.

                <br /><br />

                $2,500 – Platinum:
                <br />
                o Banner with your business name/logo displayed at event. <br />
                o Space provided for your business to set up a booth/display on
                the course. <br />
                o Business name/logo on all marketing material (print & social
                media). <br />
                o Business name announced during the event. <br />
                o Marketing material with your business name/logo distributed at
                the event. <br />
                o Sign with your business name/logo displayed on a tee box.

                <br /><br />

                $1,000 – Gold Sponsor:
                <br />
                o Space provided for your business to set up a booth/display on
                the course. <br />
                o Business name/logo on all marketing material (print & social
                media). <br />
                o Business name announced during the event. <br />
                o Marketing material with your business name/logo distributed at
                the event. <br />
                o Sign with your business name/logo displayed on a tee box.

                <br /><br />

                $500 – Silver Sponsor:
                <br />
                o Business name/logo on all marketing material (print & social
                media).<br />
                o Business name announced during the event.<br />
                o Marketing material with your business name/logo distributed at
                the event.<br />
                o Sign with your business name/logo displayed on a tee box.

                <br /><br />
                $250 – Hole Sponsor:

                <br />
                o Sign with your business name/logo displayed on a tee box.

                <br /><br />
                A portion of the proceeds will be donated to charity.
                <br /><br />
                For more information, call Tricia Clark at

                <a href="tel:+18135261671"> 813.526.1671</a>

                or Tony Whitney at
                <a href="tel:+18104236141"> 810.423.6141</a>
                .
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                Hotel Reservations:
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Call <a href="tel:+18669966338"> 1-866-996-6338</a> and ask for
                the Big Golf Weekend/Pink is Possible Room rate.<br />
                Room rate of $169 per night<br />
                <a
                  href="https://www.phgsecure.com/IBE/bookingRedirect.ashx?propertyCode=ORLRS&group=GRPTHEBIGGOLFWEEKEND&arrivalDate=10-20-2023&departureDate=10-23-2023"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rosen Shingle Creek Resort</a
                ><br />
                9939 Universal Blvd, Orlando, FL 32819.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center mb-2">
              <h4 class="w-auto d-inline-block mb-2">
                All sales are final. No refunds!!!
              </h4>
            </div>
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in option2Tickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                        <b class="font-weight-bold">Price:</b>
                        ${{ obj.price }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p v-if="obj.ticketType != 'FREE'">
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sale Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : obj.ticketCode == "tk_c0hWrjXxIaak3LC" ||
                            obj.ticketCode == "tk_gSv1kn1PXHl408t" ||
                            obj.ticketCode == "tk_hRnzr1Nn30lrhJS" ||
                            obj.ticketCode == "tk_u3igv71H8rYKo7E"
                          ? "Donate Now"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center mb-2">
              <h4 class="w-auto d-inline-block mb-2">
                All sales are final. No refunds!!!
              </h4>
            </div>
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">Sponsor Options</h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in sponserTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                        <b class="font-weight-bold">Price:</b>
                        ${{ obj.price }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p v-if="obj.ticketType != 'FREE'">
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sale Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : obj.ticketCode == "tk_c0hWrjXxIaak3LC" ||
                            obj.ticketCode == "tk_gSv1kn1PXHl408t" ||
                            obj.ticketCode == "tk_hRnzr1Nn30lrhJS" ||
                            obj.ticketCode == "tk_u3igv71H8rYKo7E"
                          ? "Donate Now"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center mb-2">
              <h4 class="w-auto d-inline-block mb-2">
                All sales are final. No refunds!!!
              </h4>
            </div>
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">Donation Options</h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in donationTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                        <b class="font-weight-bold">Price:</b>
                        ${{ obj.price }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p v-if="obj.ticketType != 'FREE'">
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sale Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : obj.ticketCode == "tk_c0hWrjXxIaak3LC" ||
                            obj.ticketCode == "tk_gSv1kn1PXHl408t" ||
                            obj.ticketCode == "tk_hRnzr1Nn30lrhJS" ||
                            obj.ticketCode == "tk_u3igv71H8rYKo7E"
                          ? "Donate Now"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 2nd Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-3">
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">
                DEPOSIT OPTIONS <br />
                “PAY SOME NOW - PAY SOME LATER”
              </h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in option1Tickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                        <b class="font-weight-bold">Price:</b>
                        ${{ obj.price }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p v-if="obj.ticketType != 'FREE'">
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sale Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : obj.ticketCode == "tk_c0hWrjXxIaak3LC" ||
                            obj.ticketCode == "tk_gSv1kn1PXHl408t" ||
                            obj.ticketCode == "tk_hRnzr1Nn30lrhJS" ||
                            obj.ticketCode == "tk_u3igv71H8rYKo7E"
                          ? "Donate Now"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MANUAL PURCHASE -->
      <section id="download" class="white-bg ptb-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2 class="mt-4">Other Payment Options</h2>
                <p class="lead">
                  We are also accepting Cash App, Paypal, Zelle and Venmo for
                  ticket payments
                </p>

                <div class="row equal">
                  <div
                    class="col-md-3 col-lg-3"
                    style="
                      background-image: linear-gradient(
                        136deg,
                        #145143 0%,
                        #0c2f1f 100%
                      );
                    "
                  >
                    <a
                      href="https://cash.app/$MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class="">
                            <img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_cash_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                            />
                          </span>
                        </div>
                        <h3 class="text-white bold">Cash App</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://www.paypal.me/myletickets?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_paypal_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Paypal</h3>
                        <p class="text-white lead">tickets@myle.com</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <div
                      @click="openZelleModel"
                      class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                    >
                      <div class="circle-icon mb-3">
                        <span class=""
                          ><img
                            class="align-middle"
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_zelle_icon.png"
                            style="width: 15%; max-width: 15%; height: auto"
                        /></span>
                      </div>
                      <h3 class="text-white">Zelle</h3>
                      <p class="text-white lead">tickets@myle.com</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://venmo.com/code?user_id=3449043321620189533"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_venmo_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Venmo</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h6 class="mb-3 mt-5 font-bold">
            If using Cash App, Paypal, Zelle or Venmo, please follow these
            steps:
          </h6>
          <span class="font-weight-bold selfBullet">•</span> Send payment (Total
          Price) to Cash App, Paypal, Zelle or Venmo account shared above<br />
          <span class="font-weight-bold selfBullet">•</span> Take a screenshot
          of your payment confirmation<br />

          <span class="font-weight-bold selfBullet">•</span> Complete the ticket
          confirmation section below, click choose file and submit with the
          screenshot as your receipt confirmation.<br />
          <span class="font-weight-bold selfBullet">•</span> Our team will
          process your transaction and you will receive an email confirmation
          with your tickets.

          <h6 class="mb-3 mt-3 font-italic">
            Note: Tickets for Cash App, Paypal, Zelle and Venmo transactions are
            processed by our team between 9am and 7pm EST daily. You may submit
            at any time.
          </h6>
        </div>
      </section>
      <hr />

      <!-- Form SECTION -->
      <section>
        <form>
          <div class="container mt-5">
            <!-- heading -->
            <div class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <h3 class="w-auto d-inline-block">Ticket Confirmation</h3>
                </div>
              </div>
            </div>
            <!-- heading -->

            <!-- form -->
            <div class="row my-3">
              <div class="col-md-4">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="ticketForm.name"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="ticketForm.email"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Phone No.</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone"
                  v-model="ticketForm.phone"
                  required
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-6">
                <label>Ticket Options</label>
                <select
                  class="form-control"
                  v-model="ticketForm.ticketName"
                  @change="onChangeTicket($event, eventTicketResult)"
                >
                  <option
                    class="form-control"
                    v-bind:key="count + 'a'"
                    v-for="count in eventTicketResult.filter(
                      (e) =>
                        e.status != 'EXPIRED' &&
                        e.availableTickets &&
                        e.status != 'COMING_SOON' &&
                        e.ticketType != 'FREE'
                    )"
                    :value="count.ticketName"
                  >
                    {{ count.ticketName }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>No. of attendees</label>
                <select class="form-control" v-model="ticketForm.ticketCount">
                  <option
                    class="form-control"
                    v-bind:key="count + 'b'"
                    v-for="count in countsListShow"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div
                class="col-md-6"
                v-if="ticketForm.ticketCode == 'tk_Blw0jHEMfysqkAH'"
              >
                <label class="mt-3">Player Names</label>
                <textarea
                  class="form-control"
                  @input="ticketForm.mealName = $event.target.value"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12">
                <label class="mr-3">Receipt</label>
                <input
                  name="image"
                  type="file"
                  id="fileName"
                  accept=".jpg,.jpeg,.png"
                  @change="validateFileType($event)"
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12 d-flex justify-content-left">
                <label class="mr-3">Pay Method</label>
                <div class="payment-method mx-2">
                  <input
                    type="radio"
                    value="Paypal"
                    v-model="ticketForm.method"
                    id="paypal"
                  />
                  <label class="ml-1" for="paypal">Paypal</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="zelle"
                    type="radio"
                    value="Zelle"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="zelle">Zelle</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="venmo"
                    type="radio"
                    value="Venmo"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="venmo">Venmo</label>
                </div>
                <div class="payment-method">
                  <input
                    type="radio"
                    value="Cash App"
                    v-model="ticketForm.method"
                    id="cashup"
                  />
                  <label class="ml-1" for="cashup">Cash App</label>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 mb-4">
                <input type="checkbox" v-model="ticketForm.accept" />
                I understand that all sales are final. No refunds. I have read
                and agree with the MYLE
                <a
                  href="https://myle.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions.</a
                >
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  @click="payfornow()"
                  :disabled="
                    !ticketForm.name.trim().length ||
                    !ticketForm.ticketName.trim().length ||
                    !ticketForm.ticketCount === 0 ||
                    !invalidTicketEmail ||
                    !invalidTicketPhone ||
                    !ticketForm.accept ||
                    !ticketForm.file ||
                    (ticketForm.ticketCode == 'tk_Blw0jHEMfysqkAH'
                      ? ticketForm.mealName.trim().length <= 0
                      : false) ||
                    isSubmitted
                  "
                  class="btn solid-btn m-auto"
                  style="pointer-events: all; cursor: pointer"
                >
                  Reserve
                </button>
              </div>
            </div>
            <h6 class="mb-5 mt-5 font-italic">
              Reminder: Tickets for Cash App, Paypal, Zelle and Venmo
              transactions are processed by our team between 9am and 7pm EST
              daily. Once your payment submission is confirmed, you will receive
              your tickets via email from the MYLE Ticket Manager account.
            </h6>
          </div>
        </form>
      </section>
      <!-- Form SECTION -->

      <!-- <hr />
          <section class="download-section ptb-50 background-img bg-white">
            <div class="container">
              <div class="row justify-content-between">
                <div
                  class="col-md-4 d-flex align-items-center justify-content-center"
                >
                  <div class="download-img">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/STADIUM_LOGO.png"
                      alt="download"
                      class="img-fluid mobImg"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="download-content">
                    <h3 class="ml-5">Bottle Service</h3>

                    <ul class="lead ml-5">
                      <li>Endeavor Rum</li>
                      <li>Endeavor Gin</li>
                      <li>Endeavor Vodka</li>
                      <li>Bulleit Bourbon</li>
                      <li>Crown Royal Bourbon</li>
                      <li>Espolon Blanco</li>
                      <li>Espolon Reposado</li>
                    </ul>
                    <ul class="lead ml-5 mt-3">
                      <li>Includes Mixers</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="download-content">
                    <h3 class="ml-5">Menu</h3>

                    <ul class="lead ml-5">
                      <li>Caesar Salad</li>
                      <li>Side Salad</li>
                    </ul>
                    <ul class="lead ml-5 mt-2">
                      <li>OMG CAULIFLOWER</li>
                      <li>Mozzarella Sticks</li>
                      <li>Street Corn</li>
                      <li>Creekside Fries</li>
                      <li>NBA JAM</li>
                      <li>The Greatest</li>
                      <li>Chicken n Waffle</li>
                    </ul>
                    <ul class="lead ml-5 mt-2">
                      <li>Boneless Wings</li>
                      <li>Traditional Wings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr /> -->

      <!-- <div class="row justify-content-center">
            <div class="col-md-8">
              <div
                class="row w-100 m-0 mt-5 text-center promo-section MYLE-lite-green"
              >
                <div class="col-lg-12 col-md-12">
                  <div class="mt-3">
                    <div class="section-heading mb-3">
                      <h5 class="text-center text-white">STADIUM Gallery</h5>
                    </div>
                  </div>
                </div>
              </div>
              <section class="correctZindex mb-4">
                <vue-flux
                  :options="vfOptions"
                  :images="vfEventImgs"
                  :transitions="vfTransitions"
                  ref="slider"
                  style=""
                >
                  <template v-slot:preloader>
                    <flux-preloader />
                  </template>
                  <template v-slot:controls>
                    <flux-controls />
                  </template>
                </vue-flux>
              </section>
            </div>
          </div> -->

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      sponsers: [
        "tk_20NdTdEkuqQQnmr",
        "tk_hdyNBhv4UjM18C6",
        "tk_CVHYSKYlWzLwYVm",
        "tk_gYHZTo9ZZvr7eHv",
        "tk_NXckXrPBexLX6ZW",
        "tk_L7nl27snAuhw9nH",
      ],
      donationslist: [
        "tk_c0hWrjXxIaak3LC",
        "tk_gSv1kn1PXHl408t",
        "tk_hRnzr1Nn30lrhJS",
        "tk_u3igv71H8rYKo7E",
      ],
      option1: [
        "tk_NvBjXPY3YCncB8k",
        "tk_8O2PoRbh52NY1TS",
        "tk_5SmshcwjBctb0yh",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_c0hWrjXxIaak3LC: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $3.32",
            "Sales Tax of 6.5%",
          ],
        },

        tk_gSv1kn1PXHl408t: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $4.99",
            "Sales Tax of 6.5%",
          ],
        },
        tk_hRnzr1Nn30lrhJS: {
          messages: [
            "Ticket Price: $75",
            "Processing fee of $6.67",
            "Sales Tax of 6.5%",
          ],
        },
        tk_u3igv71H8rYKo7E: {
          messages: [
            "Ticket Price: $100",
            "Processing fee of $8.35",
            "Sales Tax of 6.5%",
          ],
        },

        tk_20NdTdEkuqQQnmr: {
          messages: ["Ticket Price: $10000", "Sales Tax of 6.5%"],
        },
        tk_hdyNBhv4UjM18C6: {
          messages: ["Ticket Price: $5000", "Sales Tax of 6.5%"],
        },
        tk_CVHYSKYlWzLwYVm: {
          messages: ["Ticket Price: $2500", "Sales Tax of 6.5%"],
        },
        tk_gYHZTo9ZZvr7eHv: {
          messages: ["Ticket Price: $1000", "Sales Tax of 6.5%"],
        },
        tk_NXckXrPBexLX6ZW: {
          messages: ["Ticket Price: $500", "Sales Tax of 6.5%"],
        },
        tk_L7nl27snAuhw9nH: {
          messages: ["Ticket Price: $250", "Sales Tax of 6.5%"],
        },
        tk_UVfs9gqW0FRetvv: {
          messages: [
            "Ticket Price: $10",
            "Processing fee of $2.31",
            "Sales Tax of 6.5%",
          ],
        },
        tk_gdWguDSK9dQybWW: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $2.98",
            "Sales Tax of 6.5%",
          ],
        },
        tk_qQXiCUGZQ9yCCgH: {
          messages: [
            "Ticket Price: $125",
            "Processing fee of $10.03",
            "Sales Tax of 6.5%",
          ],
        },
        tk_6Txl6dfi7IX1yFp: {
          messages: [
            "Ticket Price: $500",
            "Processing fee of $35.17",
            "Sales Tax of 6.5%",
          ],
        },
        tk_B61IQrSxggmV4PI: {
          messages: [
            "Ticket Price: $150",
            "Processing fee of $11.7",
            "Sales Tax of 6.5%",
          ],
        },
        tk_9SeHMUuL2fDLFOS: {
          messages: [
            "Ticket Price: $600",
            "Processing fee of $41.88",
            "Sales Tax of 6.5%",
          ],
        },
        tk_P07DEUXdoMztwlW: {
          messages: [
            "Ticket Price: $10",
            "Processing fee of $2.31",
            "Sales Tax of 6.5%",
          ],
        },
        tk_DdCILU3QOP7Y0cl: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $2.98",
            "Sales Tax of 6.5%",
          ],
        },
        tk_fYr0pfKxOdqAwYq: {
          messages: [
            "Ticket Price: $125",
            "Processing fee of $10.03",
            "Sales Tax of 6.5%",
          ],
        },
        tk_xqKhxlFwkYJikI1: {
          messages: [
            "Ticket Price: $150",
            "Processing fee of $11.7",
            "Sales Tax of 6.5%",
          ],
        },
        tk_qJxltq2Nk7L7kzV: {
          messages: [
            "Ticket Price: $270",
            "Processing fee of $19.75",
            "Sales Tax of 6.5%",
          ],
        },
        tk_GsVIndQh4GflfyC: {
          messages: [
            "Ticket Price: $320",
            "Processing fee of $23.1",
            "Sales Tax of 6.5%",
          ],
        },
        tk_NvBjXPY3YCncB8k: {
          messages: [
            "Ticket Price: $75",
            "Processing fee of $6.67",
            "Sales Tax of 6.5%",
          ],
        },
        tk_8O2PoRbh52NY1TS: {
          messages: [
            "Ticket Price: $300",
            "Processing fee of $21.76",
            "Sales Tax of 6.5%",
          ],
        },
        tk_5SmshcwjBctb0yh: {
          messages: [
            "Ticket Price: $75",
            "Processing fee of $6.67",
            "Sales Tax of 6.5%",
          ],
        },
      },

      priceIds: {
        tk_c0hWrjXxIaak3LC: "price_1OpvlBD0vTZ4QB9cwGphezz1",
        tk_gSv1kn1PXHl408t: "price_1OpvlCD0vTZ4QB9c9xeCHycz",
        tk_hRnzr1Nn30lrhJS: "price_1OpvlDD0vTZ4QB9c2rDFY6XS",
        tk_u3igv71H8rYKo7E: "price_1OpvlED0vTZ4QB9cOAhOxig8",
        tk_20NdTdEkuqQQnmr: "price_1OpvlFD0vTZ4QB9cdRxq4c5x",
        tk_hdyNBhv4UjM18C6: "price_1OpvlGD0vTZ4QB9ciLqm8DuA",
        tk_CVHYSKYlWzLwYVm: "price_1OpvlHD0vTZ4QB9cpEe6ZGuE",
        tk_gYHZTo9ZZvr7eHv: "price_1OpvlID0vTZ4QB9c3l6nHQjV",
        tk_NXckXrPBexLX6ZW: "price_1OpvlKD0vTZ4QB9cqjhuXbGT",
        tk_L7nl27snAuhw9nH: "price_1OpvlLD0vTZ4QB9cS14EDJtB",
        tk_UVfs9gqW0FRetvv: "price_1OpvlMD0vTZ4QB9cT3ZuwBFo",
        tk_gdWguDSK9dQybWW: "price_1OpvlND0vTZ4QB9c3l6v5GfE",
        tk_qQXiCUGZQ9yCCgH: "price_1OpvlOD0vTZ4QB9cIvnaHCuz",
        tk_6Txl6dfi7IX1yFp: "price_1OpvlPD0vTZ4QB9ckEWi9KqU",
        tk_B61IQrSxggmV4PI: "price_1OpvlQD0vTZ4QB9cI2BUNtS1",
        tk_9SeHMUuL2fDLFOS: "price_1OpvlRD0vTZ4QB9cuGP0W6eV",
        tk_P07DEUXdoMztwlW: "price_1OpvlSD0vTZ4QB9cfTdFCz5i",
        tk_DdCILU3QOP7Y0cl: "price_1OpvlTD0vTZ4QB9cYfZ0TTBy",
        tk_fYr0pfKxOdqAwYq: "price_1OpvlUD0vTZ4QB9cAwMCtgvY",
        tk_xqKhxlFwkYJikI1: "price_1OpvlVD0vTZ4QB9cE1mL3Ea3",
        tk_qJxltq2Nk7L7kzV: "price_1OpvlWD0vTZ4QB9cTYRqVToQ",
        tk_GsVIndQh4GflfyC: "price_1OpvlXD0vTZ4QB9cCWJQtFEw",
        tk_NvBjXPY3YCncB8k: "price_1OpvlYD0vTZ4QB9cyBt6svDX",
        tk_8O2PoRbh52NY1TS: "price_1OpvlZD0vTZ4QB9cTg0G7lFm",
        tk_5SmshcwjBctb0yh: "price_1OpvlaD0vTZ4QB9cfrvTwwlX",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        ticketCode: null,
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
        mealName: "",
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      reservedMeal: "",
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    setAffid() {
      let payload = {};
      let affid = null;
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.affid &&
        this.$route.query.affid.trim().length > 0
      ) {
        affid = this.$route.query.affid.trim();
      }

      if (affid && affid.trim().length > 0) {
        payload = {
          affiliate_code: affid,
        };
      }

      console.log(payload);

      return payload;
    },
    option1Tickets(list) {
      return list.filter((t) => this.option1.includes(t.ticketCode));
    },
    sponserTickets(list) {
      return list.filter((t) => this.sponsers.includes(t.ticketCode));
    },
    donationTickets(list) {
      return list.filter((t) => this.donationslist.includes(t.ticketCode));
    },
    option2Tickets(list) {
      return list.filter(
        (t) =>
          !this.option1.includes(t.ticketCode) &&
          !this.sponsers.includes(t.ticketCode) &&
          !this.donationslist.includes(t.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt, dataResult) {
      const countsList = [];

      dataResult.forEach((recc) => {
        if (recc.ticketName.trim() === evt.target.value.trim()) {
          this.ticketForm.ticketCode = recc.ticketCode;
        }
      });

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      if (
        this.TicketsResult[idx] &&
        this.TicketsResult[idx].ticketCode == "tk_Blw0jHEMfysqkAH"
      ) {
        this.reservedMeal = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo:
          vm.TicketsResult[vm.selected].ticketCode === "tk_Blw0jHEMfysqkAH"
            ? {
                type: "Player Names",
                value: this.reservedMeal,
              }
            : null,
      };

      const res = vm.setAffid();
      if (res.affiliate_code) {
        payloadToPush.extraInfo = res;
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsored3rdTheBigGolfEventTickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_c0hWrjXxIaak3LC: null,
            tk_gSv1kn1PXHl408t: null,
            tk_hRnzr1Nn30lrhJS: null,
            tk_u3igv71H8rYKo7E: null,
            tk_20NdTdEkuqQQnmr: null,
            tk_hdyNBhv4UjM18C6: null,
            tk_CVHYSKYlWzLwYVm: null,
            tk_gYHZTo9ZZvr7eHv: null,
            tk_NXckXrPBexLX6ZW: null,
            tk_L7nl27snAuhw9nH: null,
            tk_UVfs9gqW0FRetvv: null,
            tk_gdWguDSK9dQybWW: null,
            tk_qQXiCUGZQ9yCCgH: null,
            tk_6Txl6dfi7IX1yFp: null,
            tk_B61IQrSxggmV4PI: null,
            tk_9SeHMUuL2fDLFOS: null,
            tk_P07DEUXdoMztwlW: null,
            tk_DdCILU3QOP7Y0cl: null,
            tk_fYr0pfKxOdqAwYq: null,
            tk_xqKhxlFwkYJikI1: null,
            tk_qJxltq2Nk7L7kzV: null,
            tk_GsVIndQh4GflfyC: null,
            tk_NvBjXPY3YCncB8k: null,
            tk_8O2PoRbh52NY1TS: null,
            tk_5SmshcwjBctb0yh: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      if (this.ticketForm.ticketCode === "tk_Blw0jHEMfysqkAH") {
        bodyFormData.append(
          "additionalInfo",
          JSON.stringify({
            type: "Player Names",
            value: this.ticketForm.mealName,
          })
        );
      }

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            mealName: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            mealName: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    console.log("respose");
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - The Big Golf Weekend",

    meta: [
      {
        name: "description",
        content: "The Big Golf Weekend",
      },

      {
        name: "keywords",
        content: "The Big Golf Weekend",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/the-big-golf-weekend",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

textarea {
  resize: none;
}
</style>
