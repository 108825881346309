import { render, staticRenderFns } from "./MYLEExposure.vue?vue&type=template&id=2ddf821b&scoped=true"
import script from "./MYLEExposure.vue?vue&type=script&lang=js"
export * from "./MYLEExposure.vue?vue&type=script&lang=js"
import style0 from "./MYLEExposure.vue?vue&type=style&index=0&id=2ddf821b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddf821b",
  null
  
)

export default component.exports