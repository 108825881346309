var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg navColor1 fixed-top",class:{
      affix: _vm.hasAffix,
      'custom-nav': _vm.coloredLogo,
      'bg-transparent': !_vm.coloredLogo,
      'white-bg': _vm.coloredLogo,
    },staticStyle:{"background-image":"linear-gradient(136deg, #304cb2, #304cb2) !important"},attrs:{"id":"barr"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse main-menu h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item nav-link page-scroll"},[_c('router-link',{attrs:{"id":"newnav","to":"/the-southwest-landing-lounge"}},[_c('span',{staticStyle:{"font-family":"'Arial', sans-serif","font-size":"1.2em","color":"white"},attrs:{"n":""}},[_c('i',{staticClass:"fa fa-home",attrs:{"aria-hidden":"true"}}),_vm._v(" Home")])])],1),_c('li',{staticClass:"nav-item nav-link page-scroll"},[_c('router-link',{attrs:{"id":"newnav","to":"/the-southwest-landing-lounge-faqs"}},[_c('span',{staticStyle:{"font-family":"'Arial', sans-serif","font-size":"1.2em","color":"white"},attrs:{"n":""}},[_c('i',{staticClass:"fa fa-question-circle-o",attrs:{"aria-hidden":"true"}}),_vm._v(" FAQs")])])],1),_c('li',{staticClass:"nav-item nav-link page-scroll"},[_c('router-link',{staticClass:"nav-link",attrs:{"id":"newnav","to":"/the-southwest-landing-lounge-contact-us"}},[_c('span',{staticStyle:{"font-family":"'Arial', sans-serif","font-size":"1.2em","color":"white"},attrs:{"n":""}},[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}}),_vm._v(" Contact us")])])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/the-southwest-landing-lounge"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_navLogo.png","width":"210","alt":"logo"}})])
}]

export { render, staticRenderFns }