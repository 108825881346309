var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-section"},[_c('cookie-law',{attrs:{"theme":"dark-lime"}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance. "),_c('router-link',{staticClass:"themeLiteGreen",attrs:{"to":"Cookies"}},[_vm._v("Click here for more information")])],1)]),_c('div',{staticClass:"footer-top py-5 background-img-2 MYLE-green bg-footer-myle"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-between"},[_vm._m(0),_c('div',{staticClass:"col-lg-3 ml-auto mb-4 mb-lg-0"},[_c('div',{staticClass:"footer-nav-wrap text-white"},[_c('h5',{staticClass:"mb-3 text-white"},[_vm._v("Others Links")]),_c('ul',{staticClass:"list-unstyled"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',{staticClass:"mb-2"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#PrivacyPolicy'),expression:"'#PrivacyPolicy'"}],attrs:{"to":"privacy"}},[_vm._v("Privacy Policy")])],1),_c('li',{staticClass:"mb-2"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#Terms&Conditions'),expression:"'#Terms&Conditions'"}],attrs:{"to":"terms"}},[_vm._v(" Terms and Conditions")])],1),_c('li',{staticClass:"mb-2"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#Cookies'),expression:"'#Cookies'"}],attrs:{"to":"cookies"}},[_vm._v("Cookies Policy")])],1)])])]),_vm._m(7),_vm._m(8)])])]),_vm._m(9)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 mb-3 mb-lg-0"},[_c('div',{staticClass:"footer-nav-wrap text-white"},[_c('img',{staticClass:"img-fluid mb-3",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.svg","alt":"footer logo","width":"120"}}),_c('div',{staticClass:"social-list-wrap"},[_c('ul',{staticClass:"social-list list-inline list-unstyled"},[_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"https://www.facebook.com/MYLEMARKERS","target":"_blank","rel":"noopener noreferrer","title":"Facebook"}},[_c('span',{staticClass:"ti-facebook"})])]),_vm._v("   "),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"https://twitter.com/MYLEMARKERS","target":"_blank","rel":"noopener noreferrer","title":"Twitter"}},[_c('span',{staticClass:"ti-twitter"})])]),_vm._v("   "),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"https://www.instagram.com/mylemarkers/","target":"_blank","rel":"noopener noreferrer","title":"Instagram"}},[_c('span',{staticClass:"ti-instagram"})])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/myle/","target":"_blank","rel":"noopener noreferrer","title":"LinkedIn"}},[_c('span',{staticClass:"ti-linkedin"})])]),_vm._v("   ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/office-hours"}},[_vm._v("Office Hours")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/faqs"}},[_vm._v("Frequently Asked Questions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/myle-ticketing"}},[_vm._v("Ticketing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/#features"}},[_vm._v("Features")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/#contact"}},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mb-2"},[_c('a',{attrs:{"href":"/orlando-launch"}},[_vm._v("Orlando Launch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 ml-auto mb-4 mb-lg-0"},[_c('div',{staticClass:"footer-nav-wrap text-white"},[_c('h5',{staticClass:"mb-3 text-white"},[_vm._v("Support")]),_c('ul',{staticClass:"list-unstyled support-list"},[_c('li',{staticClass:"mb-2 d-flex align-items-center"},[_c('span',{staticClass:"ti-email mr-2"}),_c('a',{attrs:{"href":"mailto:hello@myle.com"}},[_vm._v(" hello@myle.com")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"footer-nav-wrap text-white"},[_c('h5',{staticClass:"mb-3 text-white"},[_vm._v("Location")]),_vm._v(" 175 S. 3rd Street, Suite 200 "),_c('br'),_vm._v("Columbus, Ohio 43215 "),_c('a',{attrs:{"href":"https://goo.gl/maps/HAhdvQ3cVbTrs6fp9","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/map-new.png","alt":"location map"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom gray-light-bg pt-4 pb-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-center justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-5"},[_c('p',{staticClass:"copyright-text pb-0 mb-0"},[_vm._v(" Copyrights © 2014-2024 All rights reserved by "),_c('a',{attrs:{"href":"#"}},[_vm._v("MYLE")])])])])])])
}]

export { render, staticRenderFns }