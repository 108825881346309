var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"client-section pt-100",class:_vm.isGray ? 'gray-light-bg' : 'white-bg'},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag",attrs:{"items":1,"nav":false,"autoplay":true,"dots":true,"loop":true,"margin":15,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":6000,"responsive":{
            0: { items: 2 },
            500: { items: 3 },
            600: { items: 4 },
            800: { items: 5 },
            1200: { items: 6 },
          }}},[_c('div',{staticClass:"item single-client"},[_c('a',{attrs:{"href":"https://www.cypclub.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/cypclub.png","alt":"client logo"}})])]),_c('div',{staticClass:"item single-client"},[_c('a',{attrs:{"href":"https://www.connectednetworkinggroup.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/connected.png","alt":"client logo"}})])]),_c('div',{staticClass:"item single-client"},[_c('a',{attrs:{"href":"https://www.columbusmakesart.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/artcolumbus.png","alt":"client logo"}})])]),_c('div',{staticClass:"item single-client"},[_c('a',{attrs:{"href":"https://cosi.org","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/cosi.png","alt":"client logo"}})])]),_c('div',{staticClass:"item single-client"},[_c('a',{attrs:{"href":"https://www.nphccolumbus.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"client-img",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohio.png","alt":"client logo"}})])])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v("Our Partners")]),_c('p',[_c('span',{staticClass:"lead d-block"},[_vm._v(" Thank You to Our Strategic Partners for Your Support!")]),_c('br'),_c('span',[_vm._v(" At MYLE we believe that we are better together and that working with partners is the best way to create winning collaborations that benefit all parties. We create customised partnership packages that reflect the goals of each partner and create meaningful value. To learn more about MYLE partnership opportunities - contact us! "),_c('a',{staticClass:"themeLiteGreen",attrs:{"href":"mailto:hello@myle.com"}},[_vm._v("hello@myle.com")])]),_c('br')])])])])
}]

export { render, staticRenderFns }