<template src="./VisaCardVerifyFailed.html"></template>

<script>
// Vuex Imports
// Scss Imports
import "./VisaCardVerifyFailed.scss";

export default {
  name: "VisaCardVerifyFailed",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {
    changeFavicon(url) {
      let favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        document.head.appendChild(favicon);
      }
      favicon.setAttribute("href", url);
    },
  },
  created() {},
  mounted() {
    // const r = window.location.origin;
    // this.changeFavicon(r + "/img/SWA_favicon.svg");
  },

  // metaInfo: {
  //   title: "The Breeze Easy™ Visa Signature® Cardmember Experience",
  //   meta: [
  //     {
  //       name: "description",
  //       content: "The Breeze Easy™ Visa Signature® Cardmember Experience",
  //     },

  //     {
  //       name: "keywords",
  //       content: "The Breeze Easy™ Visa Signature® Cardmember Experience",
  //     },

  //     {
  //       name: "copyright",
  //       content: "MYLE",
  //     },

  //     {
  //       name: "url",
  //       content:
  //         "https://myle.com/the-breeze-easy-visa-signature-cardmember-experience-card-validation-failed",
  //     },
  //   ],
  // },
};
</script>
