<template>
  <section
    id="contact"
    class="contact-us ptb-100"
    v-bind:class="isGray ? 'gray-light-bg' : 'white-bg'"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-12 pb-3 message-box d-done"
          v-bind:class="{
            'd-none': !isSuccess && !hasError,
            'd-block': isSuccess || hasError,
          }"
        >
          <div
            class="alert"
            v-bind:class="{
              'alert-danger': hasError,
              'alert-success': isSuccess,
            }"
          >
            {{ alertText }}
          </div>
        </div>
        <div class="col-md-5">
          <div class="section-heading">
            <h3>Contact us</h3>
            <p>Complete this form to connect with us.</p>
          </div>
          <div class="footer-address">
            <h6><strong>MYLE Headquarters</strong></h6>
            <p>175 S. 3rd Street, Suite 200 Columbus, Ohio 43215</p>
            <ul>
              <li>
                <span
                  >Email :
                  <a class="themeLiteGreen" href="mailto:hello@myle.com"
                    >hello@myle.com</a
                  ></span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-7">
          <form v-on:submit="submit" id="contactForm" class="contact-us-form">
            <h5>Reach us quickly</h5>
            <div class="row">
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    v-model="name"
                    placeholder="Enter name"
                    required="required"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    v-model="email"
                    placeholder="Enter email"
                    required="required"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <input
                    type="text"
                    name="Subject"
                    value=""
                    size="40"
                    class="form-control"
                    v-model="subject"
                    placeholder="Your Subject"
                    required="required"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <textarea
                    name="message"
                    v-model="message"
                    class="form-control"
                    rows="7"
                    cols="25"
                    placeholder="Message"
                    required="required"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-3">
                <button
                  type="submit"
                  class="btn solid-btn"
                  id="btnContactUs"
                  style="pointer-events: all; cursor: pointer"
                >
                  Submit Your Request
                </button>
              </div>
            </div>
          </form>
          <p class="form-message"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      message: "",
      name: "",
      phone: "",
      subject: "",
      alertText: "",
      hasError: false,
      isSuccess: false,
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();

      const contactFormData = {
        name: this.name,
        email: this.email.toLowerCase(),
        subject: this.subject,
        message: this.message,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/contact_request",
          contactFormData
        )
        .then(() => {
          this.name = "";
          this.message = "";
          this.email = "";
          this.subject = "";
          this.alertText =
            "Thank you! A MYLE Team Member will be in touch shortly";
          this.hasError = false;
          this.isSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.alertText = "Something went wrong. Please try again!";
          this.isSuccess = false;
          this.hasError = true;
        });
    },
    mounted() {
      this.alertText = "";
      this.hasError = false;
      this.isSuccess = false;
    },
  },
};
</script>
