<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Company Name</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Company Name"
                required
                v-model="company_name"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Business ID (optional)</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business ID"
                v-model="business_id"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Contact Person</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Contact Person"
                required
                v-model="contact_person"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Title</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Title"
                required
                v-model="title"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Company Address</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Company Address"
                required
                v-model="company_address"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Phone</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="xxx-xxx-xxxx"
                required
                v-model="phone"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Company Website/Social (optional)</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Company Website/Social"
                required
                v-model="company_wesbite"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Notes (optional)</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Notes"
                required
                v-model="notes"
              />
            </div>

            <div
              class="col-md-12 mt-3"
              v-if="
                TicketsResult[selected] &&
                vlist.includes(TicketsResult[selected].ticketCode) &&
                groupData.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="">Do you have any power needs?</label>
              <br />
              <input
                type="radio"
                v-model="required_power_needs"
                id="Yes"
                value="Yes"
              />
              <label for="Yes" class="ml-3">Yes</label>
              <br />
              <input
                type="radio"
                v-model="required_power_needs"
                id="No"
                value="No"
              />
              <label for="No" class="ml-3">No</label>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !invalidphone
                    : false) ||
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !phone.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !company_address.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !title.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !contact_person.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  vlist.includes(TicketsResult[selected].ticketCode)
                    ? !company_name.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_banner_playdate.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                Play Date - Presented by West Lakes District - A Mainstreet
                Organization
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 28px;
              "
            >
              A 'Play Date' for All Ages hosted by the West Lakes District
            </p>

            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Join us for this "FREE TO THE PUBLIC" community PLAY DATE on
              Saturday, May 20th from 9am-5pm. This event will include:
              <br />
              SPORTS, ARTS & CRAFTS, FOOD, MUSIC & VENDORS
              <br /><br />
              Location: Lake Lorna Doone Park (1519 W. Church St. Orlando, FL
              32805).
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <span
                  class="bolded"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 22px;
                  "
                  >About Play Date:</span
                >
                <br /><br />
                The event begins with a resource plaza, a space where attendees
                can learn more about local organizations and businesses. They
                will have the opportunity to speak with representatives from
                various organizations and get information about resources
                available in their area.
                <br />
                <br />
                Food trucks will be set up around the plaza, offering a variety
                of delicious food options. The food trucks will feature cuisine
                from all over the world, giving attendees a chance to sample
                different flavors and cuisines.
                <br />
                <br />
                The event will also feature live entertainment, with local bands
                and performers taking the stage throughout the day. These acts
                will provide a fun and exciting atmosphere that will entertain
                the whole family.
                <br />
                <br />
                The event will also feature local vendors, with a wide range of
                products available for purchase. Attendees can shop for handmade
                jewelry, clothing, art, and other unique items. For those
                interested in sports, we will be hosting professional sports
                clinics throughout the day. Kids and adults alike can
                participate in basketball drills and pickleball activities and
                get tips from team experts.
                <br />
                <br />
                Finally, the event will feature a dedicated esports area, where
                attendees can compete against other gamers in a variety of
                games. There will also be tournaments and giveaways throughout
                the day, giving gamers the chance to win prizes and prove their
                skills.
                <br />
                <br />
                Play Date is sure to provide a fun and exciting experience for
                the whole family. With a variety of activities, food,
                entertainment, and shopping, there’s something for everyone.
                <br />
                <br />

                <span
                  class="bolded"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 22px;
                  "
                  >About West Lakes District:</span
                >
                <br /><br />
                West Lakes District is Orlando’s newest, promising focal point
                for business and community growth. With Camping World Stadium
                and historic Lake Lorna Doone Park at its core, the District is
                a well-known destination for festival goers, sports enthusiasts,
                and concert fans. Locals remember the area’s history of
                diversity, leadership, and family business in a myriad of
                industries.
                <br />
                <br />
                As West Orlando welcomes revitalization and evolution, the West
                Lakes District will lead and elevate its corporations, small
                businesses, new startups, and aspiring entrepreneurs to their
                next levels of success. From automotive and manufacturing, to
                health and financial services, to retail and dining, the
                District will continue to grow in enterprise. Meanwhile, we will
                forever celebrate the historic value of the community while
                creating rich, new opportunities for all who know and love us.
                Ride with us as we set sail for transformation!
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                SPONSORSHIP
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in groupTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";
// import imgg from "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_banner_playdate.png";
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      required_power_needs: "No",
      notes: "",
      company_wesbite: "",
      phone: "",
      company_address: "",
      title: "",
      contact_person: "",
      business_id: "",
      company_name: "",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      vlist: [
        "tk_qpMKjkladkGm7ze",
        "tk_whDCk9TxAf6993U",
        "tk_cDZrnE5Vks96Ov9",
        "tk_B8nnJwBVru5Tdqq",
        "tk_nZXRfbOB1L8iq2r",
        "tk_Gi7ocV7LjG76SHX",
      ],
      individualsData: [
        "tk_1e2ivrU56dvyYy4",
        "tk_qpMKjkladkGm7ze",
        "tk_whDCk9TxAf6993U",
        "tk_cDZrnE5Vks96Ov9",
        "tk_B8nnJwBVru5Tdqq",
      ],
      groupData: ["tk_nZXRfbOB1L8iq2r", "tk_Gi7ocV7LjG76SHX"],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_1e2ivrU56dvyYy4: {
          messages: [
            "This special event will feature an array of fresh, local produce, delicious handmade snacks, entertainment, esport activations, arts, local resources and more. You'll have the opportunity to interact with it all.",
            "There will also be live music, food trucks, and activities for the whole family. We look forward to seeing you there!",
            "Ticket Price: $0",
          ],
        },

        tk_qpMKjkladkGm7ze: {
          messages: [
            "Will receive 9x15 space",
            "Vendor MUST bring own Table",
            "Table Cloths are NOT included",
            "Vendors will be contacted within 24 hours of payment",
            "Ticket Price: $35",
            "Processing fee of $3.99",
          ],
        },

        tk_whDCk9TxAf6993U: {
          messages: [
            "Will receive 9x15 space",
            "Vendor MUST bring own Table",
            "Table Cloths are NOT included",
            "Vendors will be contacted within 24 hours of payment",
            "Ticket Price: $75",
            "Processing fee of $6.67",
          ],
        },

        tk_cDZrnE5Vks96Ov9: {
          messages: [
            "Vendors will be contacted within 24 hours of payment",
            "Ticket Price: $150",
            "Processing fee of $11.7",
          ],
        },

        tk_B8nnJwBVru5Tdqq: {
          messages: [
            "Contribution to the West Lakes District",
            "Ticket Price: $100",
            "Processing fee of $8.35",
          ],
        },

        tk_nZXRfbOB1L8iq2r: {
          messages: [
            "10x10 ACTIVATION AREA LOGO ON WEBSITE",
            "1/4 AD ON ONLINE BROCHURE ONE OPPORTUNITY TO SHARE DURING EVENT",
            "LOGO AND WEBSITE LINK",
            "Ticket Price: $5000",
          ],
        },

        tk_Gi7ocV7LjG76SHX: {
          messages: [
            "3 SIGNAGE MARKERS IN THE ACTIVATION AREAS",
            "LOGO ON WEBSITE",
            "1/8 PAGE AD ON ONLINE BROCHURES",
            "DECORATIVE YARD SIGN IN THE FOOD COURT",
            "10X10 BOOTH AREA",
            "Ticket Price: $1000",
          ],
        },
      },

      priceIds: {
        tk_1e2ivrU56dvyYy4: "price_1N2l2bD0vTZ4QB9cbMvWKskR",
        tk_qpMKjkladkGm7ze: "price_1N1X6yD0vTZ4QB9cag2M5t2A",
        tk_whDCk9TxAf6993U: "price_1N1X6zD0vTZ4QB9clwYxn7DX",
        tk_cDZrnE5Vks96Ov9: "price_1N1X6zD0vTZ4QB9ciIBAkdAp",
        tk_B8nnJwBVru5Tdqq: "price_1N1X6zD0vTZ4QB9cvbwzRlbH",
        tk_nZXRfbOB1L8iq2r: "price_1N1X70D0vTZ4QB9c4M4bpKkG",
        tk_Gi7ocV7LjG76SHX: "price_1N1X70D0vTZ4QB9cEITGqqHQ",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          notes: this.notes,
          company_wesbite: this.company_wesbite,
          phone: this.phone,
          company_address: this.company_address,
          title: this.title,
          contact_person: this.contact_person,
          business_id: this.business_id,
          company_name: this.company_name,
        },
      };

      if (
        vm.TicketsResult[vm.selected] &&
        vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (
        vm.TicketsResult[vm.selected] &&
        vm.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        payloadToPush.extraInfo["shirtSize"] = this.selectedSizes;
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.notes = "";
            vm.company_wesbite = "";
            vm.phone = "";
            vm.company_address = "";
            vm.title = "";
            vm.contact_person = "";
            vm.business_id = "";
            vm.company_name = "";
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_playDate_EventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_1e2ivrU56dvyYy4: null,
            tk_qpMKjkladkGm7ze: null,
            tk_whDCk9TxAf6993U: null,
            tk_cDZrnE5Vks96Ov9: null,
            tk_B8nnJwBVru5Tdqq: null,
            tk_nZXRfbOB1L8iq2r: null,
            tk_Gi7ocV7LjG76SHX: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          if (
            this.$route.params &&
            this.$route.params &&
            this.$route.params.id
          ) {
            const ss = vm.TicketsResult.filter(
              (i) => i.ticketCode == this.$route.params.id
            );
            if (ss.length > 0) {
              setTimeout(() => {
                vm.openmodel(ss[0]);
              }, 500);
            }
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "MYLE | Play Date - Presented by West Lakes District - A Mainstreet Organization",

    meta: [
      {
        name: "description",
        content:
          "MYLE | Play Date - Presented by West Lakes District - A Mainstreet Organization",
      },

      {
        name: "keywords",
        content:
          "MYLE | Play Date - Presented by West Lakes District - A Mainstreet Organization",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/play-date-presented-by-west-lakes-district-a-mainstreet-organization",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
