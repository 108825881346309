var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('nav-bar')],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"container"},[_vm._m(5),_c('video-embed',{attrs:{"css":"embed-responsive-16by9","src":"https://youtu.be/3-LfkpFV2JE"}})],1)]),_vm._m(6),_vm._m(7)]),_c('site-footer',{attrs:{"show-subscribe":true}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12",attrs:{"id":"banner"}},[_c('img',{staticClass:"align-middle",staticStyle:{"width":"100%","max-width":"100%","height":"auto","margin-top":"4.6rem"},attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_100_black_men.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between test",staticStyle:{"margin":"auto","margin-top":"20px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading text-center mb-1",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"800","font-size":"45px"}},[_vm._v(" Welcome"),_c('br'),_vm._v(" 2023 Scholarship Gala Weekend Event Site ")])]),_c('hr'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading mb-5 text-center",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"22px"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 11 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/2023-scholarship-gala-weekend-social-kickoff","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Scholarship Gala Weekend Social Kickoff ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 11, 2023, 7:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/2023-scholarship-gala-weekend-social-kickoff","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/2023-scholarship-gala-weekend-social-kickoff","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Gala_Weekend_Social_Kickoff.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 12 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/scholarship-gala","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Scholarship Gala benefitting The Leadership Academy ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 12, 2023, 6:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/scholarship-gala","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/scholarship-gala","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Scholarship_Gala.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 13 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Jazz Brunch ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 13, 2023, 10:00am ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_jazz_brunch.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 m-0 text-center promo-section MYLE-green"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"section-heading mb-3"},[_c('h5',{staticClass:"text-center text-white"},[_vm._v(" 2023 Scholarship Gala Weekend ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-heading mb-2 mt-5"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center",attrs:{"href":"https://myle.com/scholarship-gala-donations","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Donations")]),_c('a',{staticClass:"btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center",attrs:{"href":"https://myle.com/scholarship-gala-silent-auction","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Silent Auction")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 m-0 promo-section",staticStyle:{"background":"#a19140"}},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"section-heading mb-5"},[_c('h2',{staticClass:"text-center"},[_vm._v("DOWNLOAD MYLE TODAY TO GET STARTED")]),_c('div',{staticClass:"download-btn mt-4 text-center animate__animated animate__fadeIn"},[_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-apple"}),_vm._v(" App Store")]),_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":"https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-android"}),_vm._v(" Google Play")]),_c('a',{staticClass:"btn google-play-btn",attrs:{"href":"https://web.myle.com","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-world"}),_vm._v(" MYLE Web")])])])])])])
}]

export { render, staticRenderFns }