<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Black_Food_Truck_Festival_Header.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 45px;
              "
            >
              Black Food Truck Festival
            </p>
          </div>
          <hr />

          <div class="row mt-5 mb-5">
            <div class="col-md-6 col-lg-6 align-items-center">
              <a
                href="https://myle.com/black-food-truck-festival-day-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Black_Food_Truck_Festival_Image-Day_1 .jpeg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Black Food Truck Festival - Day 1
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Saturday, Nov 19, 2022
                  <br />
                  11:30AM - 6:00PM
                </p>
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/black-food-truck-festival-day-1"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-6 justify-content-center align-items-center"
            >
              <a
                href=" https://myle.com/black-food-truck-festival-day-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Black_Food_Truck_Festival_Image-Day_2.jpeg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Black Food Truck Festival - Day 2
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Sunday, Nov 20, 2022
                  <br />
                  11:30AM - 6:00PM
                </p>
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/black-food-truck-festival-day-2"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-6 mt-3 justify-content-center align-items-center"
            >
              <a
                href=" https://myle.com/black-food-truck-festival-two-days-bundle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_two_day_bftf.jpg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Black Food Truck Festival - Two Day Pass
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Saturday - Sunday <br />Nov 19 - 20, 2022
                  <br />
                  11:30AM - 6:00PM
                </p>
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/black-food-truck-festival-two-days-bundle"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-6 mt-3 justify-content-center align-items-center"
            >
              <a
                href=" https://myle.com/black-food-truck-festival-weekend-bundle"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Black_Food_Truck_Festival_Image-Weekend_bundle.png"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Black Food Truck Festival - Weekend Bundle
                  <br />
                  (includes Sunday Night Finale Concert)
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Saturday - Sunday <br />Nov 19 - 20, 2022
                  <br />
                  11:30AM - 6:00PM
                </p>
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/black-food-truck-festival-weekend-bundle"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-lg-3 mt-3 justify-content-center align-items-center"
            ></div>
            <div
              class="col-md-6 col-lg-6 mt-3 justify-content-center align-items-center"
            >
              <a
                href="https://www.ticketmaster.com/event/2D005D1F878B23C5?_ga=2.81519647.966799422.1662231066-272518.1662231062&irgwc=1&clickid=V-I1%3Af2B8xyNU1AXoSTPUU5XUkDRK4zZP2A2xM0&camefrom=CFC_BUYAT_2870504&impradid=2870504&REFERRAL_ID=tmfeedbuyat2870504&wt.mc_id=aff_BUYAT_2870504&utm_source=2870504-MYLE&impradname=MYLE&utm_medium=affiliate&ircid=4272"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_finale_bftf.jpg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Black Food Truck Festival - Sunday Night Finale Concert
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Sunday, Nov 20, 2022
                  <br />
                  7:30PM - 12:00AM
                </p>
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://www.ticketmaster.com/event/2D005D1F878B23C5?_ga=2.81519647.966799422.1662231066-272518.1662231062&irgwc=1&clickid=V-I1%3Af2B8xyNU1AXoSTPUU5XUkDRK4zZP2A2xM0&camefrom=CFC_BUYAT_2870504&impradid=2870504&REFERRAL_ID=tmfeedbuyat2870504&wt.mc_id=aff_BUYAT_2870504&utm_source=2870504-MYLE&impradname=MYLE&utm_medium=affiliate&ircid=4272"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-lg-3 mt-3 justify-content-center align-items-center"
            ></div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row w-100 m-0 text-center promo-section MYLE-green">
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">
                  Black Food Truck Festival 2022 Promo
                </h5>
              </div>
            </div>
          </div>
        </div>
        <video-embed
          css="embed-responsive-16by9"
          src="https://youtu.be/u5KyJIuWw3s"
        ></video-embed>
      </div>

      <!-- <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">
                  Fearless Fund VC Summit Events
                </h5>
              </div>
            </div>
          </div>
        </div> -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                The Black Food Truck Festival is a family-oriented event
                featuring the finest food trucks in the region in Charleston,
                South Carolina. Our mission is to make an economic and social
                impact on the community by showcasing and investing in area
                Black-owned businesses.
                <br />
                <br />
                The Black Food Truck Festival is the ultimate family reunion
                featuring kid-friendly activities in a safe, secure environment.
                Expect to experience a range of different cuisines while
                enjoying performances by local bands and musicians.

                <br />
                <br />
                Our goal is to create an atmosphere of unity, love, and positive
                vibes. Come celebrate with us and have a great time while
                creating a memorable experience!
              </p>

              <h6
                class="mb-5"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                For Group Sales, email
                <a
                  href="mailto:info@blackfoodtruckfestival.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@blackfoodtruckfestival.com</a
                >
              </h6>
            </div>
          </div>
        </div>
      </div>

      <section class="container">
        <h2
          class="mt-2 text-center"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 35px;
          "
        >
          Black Food Truck Festival Map
        </h2>

        <div class="row">
          <div class="col-md-3 col-lg-3 align-items-center"></div>

          <div class="col-md-6 col-lg-6 align-items-center">
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bftf_faqs.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
          <div class="col-md-3 col-lg-3 align-items-center"></div>
        </div>
      </section>

      <div class="section-heading mb-2">
        <div class="text-center">
          <a
            href="https://www.blackfoodtruckfestival.com/frequently-asked-questions"
            class="btn solid-btn mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Rules and FAQ's</a
          >
          <a
            href="https://www.blackfoodtruckfestival.com/vendor-application"
            class="btn solid-btn mb-1 text-center"
            target="_self"
          >
            Vendor Inquiries</a
          >
        </div>
      </div>

      <!-- <div class="row">
          <div class="col-md-6 col-lg-6 align-items-center">
            <a
              href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
              target="_blank" rel="noopener noreferrer"
            >
              <div class="">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
  
              <p
                class="text-center lead-heading ml-3 mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                For The Kings | Juneteenth + Father's Day Brunch
              </p>
              <p
                class="text-center lead-heading ml-3 mb-3"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 300;
                  font-size: 16px;
                "
              >
                Sun, Jun 19, 12:00 PM
              </p>
            </a>
          </div>
        </div> -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - MYLE | Black Food Truck Festival",

    meta: [
      {
        name: "description",
        content: "MYLE | Black Food Truck Festival",
      },

      {
        name: "keywords",
        content: "MYLE | Black Food Truck Festival",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/black-food-truck-festival",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
