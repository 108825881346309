<template>
  <div>
    <div v-if="!isSuccess" class="w-full bg-gray-50 px-2 py-10 mt-0 mx-auto">
      <div
        class="sm:w-4/5 shadow-md rounded-none overflow-hidden relative pb-32 bg-white sm:px-0 px-4 mx-auto"
      >
        <div>
          <div class="mx-auto w-full px-4 text-center">
            <img :src="imgg" class="w-16 mt-5" alt="" />

            <h3 class="text-3xl mt-6 text-center font-semibold text-gray-800">
              Failed Transaction:
            </h3>
            <p class="text-gray-500 mt-5 text-center font-semibold">
              Request Failed<br />Uh-Oh…
            </p>
            <p class="text-gray-500 mt-5 text-center">
              Either tickets are sold out or unavailable Please click below to
              explore thousands of other options <br />#MakeYourLifeEntertaining
            </p>
          </div>

          <hr class="my-8 mx-auto w-full px-4 border-color" />
          <div class="text-center mt-3">
            <button
              class="m-auto w-48 border-green-900 block text-sm mx-auto rounded py-2 font-medium my-14 border bg-green-900 hover:border-green-700 transition ease-in-out duration-500 text-white hover:bg-green-700"
              @click="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isSuccess" class="w-full bg-gray-50 px-2 py-10 mt-0 mx-auto">
      <div
        class="shadow-md rounded-none overflow-hidden relative pb-32 bg-white sm:px-0 px-4 mx-auto"
      >
        <div>
          <div class="w-full px-4 text-center">
            <img :src="imgg" class="w-16 mt-5" />

            <h3 class="text-3xl mt-3 text-center font-semibold text-gray-800">
              Thank You
            </h3>
            <p
              class="mt-3 text-center font-semibold"
              v-if="$route.params.id == 'none'"
            >
              Free tickets purchased. Please check your email inbox for
              confirmation email.
            </p>
            <!-- <p
              class="mt-3 text-center font-semibold"
              v-if="$route.params.id != 'none'"
            >
              Your transaction was successfully completed.
            </p> -->
            <p class="mt-3 text-center">
              A confirmation has been sent to your email account (check spam
              too) <br />
              We have thousands of events for you to explore on MYLE! <br />
              <br />
              For Things to Do, Places to Go and Food to Eat, Just MYLE It!
              <br />

              <b>#MakeYourLifeEntertaining</b>
            </p>
          </div>

          <hr class="my-3 w-full px-4" />
          <img :src="manjpg" class="w-full mx-auto mt-2" alt="" />

          <div class="text-center">
            <button
              class="m-auto w-48 border-green-900 block text-sm mx-auto rounded py-2 font-medium my-14 border bg-green-900 hover:border-green-700 transition ease-in-out duration-500 text-white hover:bg-green-700"
              @click="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ConfirmationBlog",
  emits: ["closemodal"],
  data() {
    return {
      isSuccess: false,
      imgg: null,
      manjpg: null,
    };
  },
  props: {
    showsuccess: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showsuccess(n) {
      if (n) {
        this.isSuccess = true;
      }
    },
  },
  methods: {
    close() {
      this.$emit("closemodal", true);
    },
    getBase64(url, field) {
      axios.get(`${url}`, { responseType: "blob" }).then((blob) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(blob.data);
        reader.onload = () => {
          this[field] = reader.result;
        };
      });
    },
  },
  created() {
    const r = window.location.origin;
    this.getBase64(r + "/img/hdlogo.jpeg", "imgg");
    this.getBase64(r + "/img/illustration.png", "manjpg");
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.checkout &&
      this.$route.query.checkout == "success"
    ) {
      this.isSuccess = true;
    } else {
      this.isSuccess = false;
    }
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
.w-16 {
  width: 4rem;
}
.w-48 {
  width: 12rem;
}
.bg-green-700 {
  background-color: rgb(21, 128, 61);
}

.bg-green-900 {
  background-color: rgb(20, 83, 45);
}
.mx-auto {
  margin: auto;
}
</style>
