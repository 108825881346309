<template>
  <header class="header">
    <!--start navbar-->
    <nav
      id="barr"
      class="navbar navbar-expand-lg navColor1 fixed-top"
      style="
        background-image: linear-gradient(136deg, #304cb2, #304cb2) !important;
      "
      v-bind:class="{
        affix: hasAffix,
        'custom-nav': coloredLogo,
        'bg-transparent': !coloredLogo,
        'white-bg': coloredLogo,
      }"
    >
      <div class="container">
        <a class="navbar-brand" href="/the-southwest-landing-lounge"
          ><img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_navLogo.png"
            width="210"
            alt="logo"
            class="img-fluid"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          @click="mobileNavClicked"
          v-bind:class="{ collapsed: collapsed }"
        >
          <span class="ti-menu"></span>
        </button>

        <div
          class="collapse navbar-collapse main-menu h-auto"
          v-bind:class="{ show: !collapsed }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item nav-link page-scroll">
              <router-link id="newnav" to="/the-southwest-landing-lounge"
                ><span
                  n
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.2em;
                    color: white;
                  "
                  ><i class="fa fa-home" aria-hidden="true"></i> Home</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link id="newnav" to="/the-southwest-landing-lounge-faqs"
                ><span
                  n
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.2em;
                    color: white;
                  "
                  ><i class="fa fa-question-circle-o" aria-hidden="true"></i>
                  FAQs</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link
                id="newnav"
                class="nav-link"
                to="/the-southwest-landing-lounge-contact-us"
              >
                <span
                  n
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.2em;
                    color: white;
                  "
                  ><i class="fa fa-envelope" aria-hidden="true"></i> Contact
                  us</span
                >
              </router-link>
            </li>

            <!-- <li class="nav-item">
              <router-link
                id="newnav"
                class="nav-link page-scroll"
                to="/the-southwest-landing-lounge"
              >
                <span
                  @click="valclicked"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.2em;
                    color: white;
                  "
                  ><i class="fa fa-ticket"></i> Register now</span
                >
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
    <!--end navbar-->
  </header>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    valclicked() {
      window.showpopup = true;
      this.$emit("opencart", true);
    },
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>
