<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/win500_banner.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->

      <!-- <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                MYLE SUNSET SESSIONS – Atlanta
              </h1>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Information -->
      <section id="download" class="white-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2
                  class="mt-4"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 26px;
                  "
                >
                  Let MYLE – Make Your Summer Entertaining and Fund Your Fun!
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2
                  class="mt-4"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 30px;
                  "
                >
                  Want a chance to win $500? Fill out this form to learn more
                </h2>
              </div>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <div class="ml-3 text-center" id="hubspotForm" v-once></div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <h6
                class="mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
              >
                It’s Easy!
              </h6>
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Download the MYLE App </h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Set-up your account</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Go the $500 Summer Fun Money Event (you’ll find it under
                Featured Events)</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Click one of the “Like or Love” buttons</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Leave a comment</h8
              ><br />

              <h6
                class="mt-3 mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                That’s it!
              </h6>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download MYLE for a Chance to Win $500
                </h3>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <h6
                class="mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
              >
                Here’s the legal stuff:
              </h6>
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Sweepstakes dates – eligibility period April 13-30</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Sweepstakes winner will be drawn at random on Monday, May 2
                at 4:30 p.m. ET and will be notified by the email address
                provided upon registration.</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Must be 18 years of age or older to participate</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Must be a US citizen and resident</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Void where prohibited</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Chances of winning are random – 1/10,000</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Only one entry per account will be eligible</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Must have an active account on May 2 when the winner is
                selected at random</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ●
                <a
                  href="https://myle.com/winning-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Other terms and conditions apply</a
                ></h8
              ><br />

              <h6
                class="mt-3 mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                Good Luck!
              </h6>
            </div>
          </div>

          <h6
            class="mb-3 mt-5 text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 20px;
            "
          >
            Share it with your friends and earn points and cash with
            <a
              href="https://myle.com/affiliate"
              target="_blank"
              rel="noopener noreferrer"
            >
              MYLE’s Affiliate Program</a
            >
            <br /><br />
          </h6>
        </div>

        <div class="col-md-12 text-center">
          <h4
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 24px;
            "
          >
            Questions?
            <a href="mailto:hello@myle.com"> hello@myle.com</a>
          </h4>
        </div>
        <div class="row text-center">
          <div class="col-md-12">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/win500_collage.jpg"
              style="width: 100%; max-width: 100%; height: auto"
            />
          </div>
        </div>
      </section>
      <!-- Information -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h3 class="text-center text-white">Fun is waiting for you.</h3>
            <h2 class="text-center text-white">Download or Sign-up Today!</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  name: "TrainingComponent",
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },
  computed: {
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.payload.email
      );
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "9126612",
          formId: "8a5ea0d7-27e0-4f7d-bb29-3f3d6c970d64",
          target: "#hubspotForm",
        });
      }
    });
  },
  methods: {
    register: function () {
      const payloadToPush = {
        name: this.payload.name,
        email: this.payload.email,
        role: this.payload.role,
        attendees: this.payload.attendees,
        organization: this.payload.company,
        app_downloaded: Number(this.payload.appDownload),
        subscribed: this.payload.receiveEmail ? 1 : 0,
      };
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/atlanta_rsvp_requested",
          payloadToPush
        )
        .then((response) => {
          vm.isSubmitted = false;
          if (
            response.data &&
            response.data.Message &&
            response.data.Status == "Success"
          ) {
            vm.$toast.success("Request submitted", { timeout: 4000 });
            vm.payload = {
              role: "Convention/Tourism Representative",
              name: "",
              email: "",
              company: "",
              attendees: "0",
              appDownload: 1,
              receiveEmail: 1,
            };
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Something went wrong", { timeout: 4000 });
        });
    },
  },
  data() {
    return {
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      isSubmitted: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      payload: {
        role: "Convention/Tourism Representative",
        name: "",
        email: "",
        company: "",
        attendees: "0",
        appDownload: 1,
        receiveEmail: true,
      },
      roles: [
        "Convention/Tourism Representative",
        "DJ",
        "Event Goer",
        "Event Planner",
        "Faith Based Representative",
        "Hotel Representative",
        "Influencer",
        "Investor",
        "Lifestyle Critic/Blogger/Writer",
        "Non-Profit Representative",
        "Promoter",
        "Venue Owner",
        "Social Organization Representative",
        "Other",
      ],
      attendees: ["0", "1", "2", "3", "4"],
    };
  },

  metaInfo: {
    title: "Win $500 | MYLE",

    meta: [
      {
        name: "description",
        content: "Win $500 on MYLE",
      },

      {
        name: "keywords",
        content: "win $ $500 500",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/win500",
      },
    ],
  },
};
</script>

<style>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
</style>
