<template>
  <div>
    <nav-bar />
    <div class="main">
      <section>
        <div class="">
          <div class="row text-center">
            <div class="col-md-12 col-lg-12 position p-0">
              <div class="hero-content-left text-white">
                <h1
                  class="text-white mt-0 animate__animated animate__fadeIn animate__delay-1s"
                >
                  <span
                    style="
                      font-family: 'Quantico', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      color: white;
                    "
                    >BECOME A MYLE
                  </span>
                  <br />
                  <span
                    style="
                      font-family: 'Fredoka', sans-serif;
                      font-weight: 700;
                      font-size: 1.5em;
                      color: white;
                    "
                  >
                    PROFIT PARTNER</span
                  >
                </h1>

                <br />

                <!-- <button
                  v-scroll-to="'#tiersDetail'"
                  class="btn google-play-btn animate__animated animate__fadeIn animate__delay-2s"
                >
                  Learn More
                </button> -->

                <div class="mouse_scroll">
                  <div class="mouse">
                    <div class="wheel"></div>
                  </div>
                  <div>
                    <span class="m_scroll_arrows unu"></span>
                    <span class="m_scroll_arrows doi"></span>
                    <span class="m_scroll_arrows trei"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vue-flux
          :options="vfOptions"
          :images="vfImages"
          :transitions="vfTransitions"
          ref="slider"
          style="z-index: 0 !important"
        >
        </vue-flux>
      </section>

      <section
        class="download-section background-img bg-white"
        style="color: black"
        id="tiersDetail"
      >
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-12">
              <div class="download-content">
                <p class="text-center mb-0 mt-5">
                  <span
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 2.2em;
                      color: black;
                    "
                  >
                    Earn Cash 💰 Money 💰 When You Host Your Events on MYLE
                  </span>
                </p>

                <p
                  class="mt-5"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.3em;
                  "
                >
                  Earn a
                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                    "
                    >“Revenue Share”</span
                  >
                  BONUS PAYOUT when MYLE exceeds key revenue thresholds from
                  service fees generated by your event.

                  <br />
                </p>

                <p
                  class="mt-3 text-center"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.3em;
                  "
                >
                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                    "
                    >THE MORE YOU MAKE, THE MORE YOU MAKE!</span
                  >
                </p>
                <hr />

                <p
                  class="mt-5 mb-3 text-center"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 2.8em;
                    color: black;
                    border: 1px solid #104233;
                    background: #104233;
                    color: white;
                  "
                >
                  MYLE Profit Partner Model
                </p>

                <p
                  class="mt-5"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.3em;
                  "
                >
                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.45em;
                    "
                    >💰Tier Thresholds (MYLE revenue earned from service
                    fees):</span
                  >

                  <br />
                  <span class="bolded"> Tier 1</span> - $250<br />
                  <span class="bolded"> Tier 2</span> - $1000<br />
                  <span class="bolded"> Tier 3</span> - $2500

                  <br /><br />

                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.45em;
                    "
                  >
                    💵 Payouts: (% of MYLE revenue shared w/hosts):</span
                  >

                  <br />
                  <span class="bolded"> Tier 1</span> - 10%<br />
                  <span class="bolded"> Tier 2</span> - 15%<br />
                  <span class="bolded"> Tier 3</span> - 20%<br />
                </p>
                <p
                  class="mt-5"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 1.3em;
                  "
                >
                  Note: There is
                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                    "
                    >NO MAX</span
                  >

                  on the Tier 3 payout.
                  <span
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                    "
                    >THE MORE YOU MAKE, THE MORE YOU MAKE!</span
                  >
                </p>
                <br />
                <p
                  class="mt-3 mb-3 text-center"
                  style="
                    font-family: 'nunito', 'Quicksand', sans-serif;
                    font-weight: 900;
                    font-size: 2.5em;
                    color: #104233;
                  "
                >
                  Get Started
                </p>

                <div
                  class="download-btn d-flex justify-content-center mt-1 mb-5"
                >
                  <a
                    href="https://web.myle.com/post-event"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class=""></span> Create An Event</a
                  >
                </div>

                <hr />

                <section
                  id="refer-and-earn-cash-with-the-myle-affiliate-program"
                >
                  <p
                    class="text-center mt-5"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 2.2em;
                      border: 1px solid #104233;
                      background: #104233;
                      color: white;
                    "
                  >
                    Refer and Earn CASH with the MYLE AFFILIATE PROGRAM
                  </p>

                  <p
                    class="mt-3 text-center"
                    style="
                      font-family: 'Open sans', sans-serif;
                      font-weight: 500;
                      font-size: 1.3em;
                      color: black;
                    "
                  >
                    Earn up to 30% cash payout on revenue generated on MYLE from
                    your referrals <br />
                    (Referral results are available on the MYLE App dashboard)
                    <br />
                    Referral payout earned is based on the Revenue Share amount
                    earned by host. <br /><br />

                    <span class="bolded"> See details below</span>
                    <br /><br />
                  </p>

                  <p
                    class="mt-3 mb-5 text-center"
                    style="
                      font-family: 'Open sans', sans-serif;
                      font-weight: 600;
                      font-size: 1.5em;
                      color: black;
                    "
                  >
                    Total max payout from MYLE is 30% combined (Host +
                    Referral).
                  </p>

                  <p
                    class="mt-3"
                    style="
                      font-family: 'Open sans', sans-serif;
                      font-weight: 500;
                      font-size: 1.3em;
                      color: black;
                    "
                  >
                    Example:<br />
                    <span class="bolded"> Tier 0</span> - Host Bonus Payout 0%,
                    Referral Payout = 30%<br />
                    <span class="bolded"> Tier 1</span> - Host Bonus Payout 10%,
                    Referral Payout = 20%<br />
                    <span class="bolded"> Tier 2</span> - Host Bonus Payout 15%,
                    Referral Payout = 15%<br />
                    <span class="bolded"> Tier 3</span> - Host Bonus Payout 20%,
                    Referral Payout = 10%<br />
                  </p>
                  <p
                    class="mt-5"
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                    "
                  >
                    Note: Reference

                    <button v-scroll-to="'#tiersDetail'">
                      <span
                        style="
                          font-family: 'nunito', 'Quicksand', sans-serif;
                          font-weight: 700;
                          font-size: 1.3em;
                        "
                        >MYLE Profit Partner Model</span
                      >
                    </button>

                    above for Host Bonus Payout structure.
                  </p>
                  <p
                    class="mt-3 mb-0"
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 1.3em;
                    "
                  >
                    Additionally, EARN CASH when you post events and refer users
                    to
                    <span
                      style="
                        font-family: 'nunito', 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                      "
                      >DOWNLOAD THE MYLE APP</span
                    >. Earn points when referrals sign-up with your personalized
                    referral code and host revenue generating events or

                    <span
                      style="
                        font-family: 'nunito', 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                      "
                      >SUBSCRIBE</span
                    >

                    to promote events on the MYLE platform. Points are
                    redeemable for cash when minimum point threshold is
                    achieved.
                  </p>

                  <br />
                  <p
                    class="mt-3 mb-3 text-center"
                    style="
                      font-family: 'nunito', 'Quicksand', sans-serif;
                      font-weight: 900;
                      font-size: 2.5em;
                      color: #104233;
                    "
                  >
                    Get Started
                  </p>

                  <div class="download-btn d-flex justify-content-center mt-1">
                    <a
                      href="https://myle.com/affiliate"
                      class="btn solid-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><span class=""></span> Become an Affiliate</a
                    >
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { VueFlux } from "vue-flux";
import axios from "axios";

export default {
  name: "ExtraMyle",
  components: {
    NavBar,
    SiteFooter,
    VueFlux,
  },
  mounted() {
    const id = localStorage.getItem("refId");

    if (id && this.$route.query && this.$route.query.status == id) {
      localStorage.removeItem("refId");
      this.$toast.success(
        "Thank You for Your Donation to Extra MYLE International — you’re helping to change lives by going the Extra MYLE",
        { timeout: 4000 }
      );
    }
  },
  methods: {
    getRandomString(length) {
      const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    },
    submit(e) {
      e.preventDefault();
      e.stopPropagation();
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post("https://myle-19701.uc.r.appspot.com/api/donation_request", {
          donationAmount: this.formData.payAmount,
          firstName: this.formData.fname,
          lastName: this.formData.lname,
          email: this.formData.email.toLowerCase(),
          phoneNumber: this.formData.phone,
          comment: this.formData.comment,
          showNamePublicaly: this.formData.showPublically ? 1 : 0,
          allowFutureContact: this.formData.contactInfuture ? 1 : 0,
        })
        .then((re) => {
          if (re.data && re.data.Result) {
            vm.checkout.clientReferenceId =
              this.getRandomString(15) + "_" + re.data.Result.donationId;
            vm.checkout.lineItems[0].price = re.data.Result.priceId;
            localStorage.setItem("refId", vm.checkout.clientReferenceId);
            vm.checkout.successURL +=
              "?status=" + vm.checkout.clientReferenceId;
            vm.$refs.checkoutRef.redirectToCheckout();
          }
        })
        .catch((err) => {
          vm.isSubmitted = false;
          return err;
        });
    },
  },
  data: () => ({
    vfOptions: {
      autoplay: true,
    },
    vfImages: [
      "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_profit_partner.png",
    ],
    vfTransitions: ["fade"],
    formData: {
      payType: "One Time",
      payAmount: 25,
      fname: "",
      lname: "",
      showPublically: false,
      email: "",
      contactInfuture: true,
      phone: "",
      comment: "",
    },
    checkout: {
      clientReferenceId: null,
      publishKey:
        "pk_live_51K1gNdAt4BmGJKDTTxmif4IznfvZ0pkDH4ITJmHOaipcIXE0dQkW84DXdGc9cHLSKHHuzqhLiMkjH3tAVQWqRrp800W3nVRNAM",
      lineItems: [
        {
          price: null,
          quantity: 1,
        },
      ],
      successURL: "https://myle.com/extramyle",
      cancelURL: "https://myle.com/extramyle",
    },
    isSubmitted: false,
  }),

  metaInfo: {
    title: "MYLE - BECOME A MYLE PROFIT PARTNER",

    meta: [
      {
        name: "description",
        content: "MYLE - BECOME A MYLE PROFIT PARTNER",
      },

      {
        name: "keywords",
        content: "MYLE - BECOME A MYLE PROFIT PARTNER",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/profit-partner",
      },
    ],
  },
};
</script>

<style scoped>
.non-active {
  color: #104233 !important;
  border: 1px solid #104233 !important;
  background: white !important;
  outline: none !important;
  padding: 5px 25px;
}

.non-active:hover {
  color: white !important;
  border: 1px solid #104233 !important;
  background: #104233 !important;
  outline: none !important;
}

.active {
  color: white !important;
  border: 1px solid #104233 !important;
  background: #104233 !important;
  outline: none !important;
}

textarea {
  resize: none;
  height: 200px !important;
}

.pnone {
  pointer-events: none !important;
}

.bolded {
  font-weight: bold;
}
</style>
