<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold"
                  >{{
                    labelbytag[TicketsResult[selected].ticketCode] ||
                    "Per Person Price"
                  }}:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold"
                  >{{
                    labelbytag[TicketsResult[selected].ticketCode] ||
                    "Per Person Price"
                  }}:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketType !== 'DONATION'
              "
            >
              <label class="mt-3">{{
                labelByName[TicketsResult[selected].ticketCode] ||
                "Number of Ticket(s)"
              }}</label>
              <select class="form-control" v-model="tickcount" @change="change">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3 mt-2"
              v-if="
                TicketsResult[selected] &&
                donationOptions[TicketsResult[selected].ticketCode]
              "
            >
              <!-- test -->
              <label class="mt-2">Select Donation Type</label>
              <select class="form-control" v-model="donationPid">
                <option
                  class="form-control"
                  v-bind:key="idx + 'donation'"
                  v-for="(count, idx) in donationOptions[
                    TicketsResult[selected].ticketCode
                  ]"
                  :value="count.priceId"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>
            <!-- test -->

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                      <div
                        class="row col-md-12 mt-4"
                        v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                      >
                        <div class="col-md-6">
                          <label class="">Do you have a promo code?</label>

                          <input
                            type="text"
                            v-model="redeempromo"
                            class="form-control"
                            placeholder="Promo code"
                          />
                        </div>

                        <div class="col-md-4">
                          <button
                            type="button"
                            @click="redeem()"
                            :disabled="!redeempromo.trim().length"
                            class="btn solid-btn btn-sm my-auto"
                            id="reserve"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              padding: 7px;
                              margin-top: 11px !important;
                            "
                          >
                            Redeem
                          </button>
                        </div>
                      </div> -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0"
              v-if="option1Tickets.includes(TicketsResult[selected].ticketCode)"
            >
              <br />
              <hr class="w-100 p-2" />
              <br />
              <div class="col-md-6">
                <label class="mt-2">Institution Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Institution Name"
                  v-model="iname"
                />
              </div>
              <div class="col-md-6">
                <label class="mt-2">{{
                  allowTextField[TicketsResult[selected].ticketCode]
                    ? "Primary Contact Name"
                    : labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  v-if="!allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  :placeholder="
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="cname"
                ></textarea>
                <input
                  type="text"
                  v-if="allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  placeholder="Primary Contact Name"
                  v-model="cname"
                />
              </div>
              <div
                class="col-md-6"
                v-if="
                  TicketsResult[selected].ticketCode != 'tk_vxGMmLyrqzHfeJW'
                "
              >
                <label class="mt-2">{{
                  emailByLabel[TicketsResult[selected].ticketCode] ||
                  labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  class="form-control mb-3"
                  :placeholder="
                    emailByLabel[TicketsResult[selected].ticketCode] ||
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="emaillist"
                ></textarea>
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0"
              v-if="option2Tickets.includes(TicketsResult[selected].ticketCode)"
            >
              <br />
              <hr class="w-100 p-2" />
              <br />
              <div class="col-md-6">
                <label class="mt-2">
                  {{
                    TicketsResult[selected].ticketCode == "tk_ZnG2CFRtGKhTvTJ"
                      ? "Name of Institution"
                      : "Name of Company"
                  }}</label
                >
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Name of Company"
                  v-model="iname"
                />
              </div>
              <div class="col-md-6">
                <label class="mt-2">{{
                  allowTextField[TicketsResult[selected].ticketCode]
                    ? "Primary Contact Name"
                    : labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  v-if="!allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  :placeholder="
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="cname"
                ></textarea>
                <input
                  type="text"
                  v-if="allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  placeholder="Primary Contact Name"
                  v-model="cname"
                />
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (option1Tickets.includes(
                    TicketsResult[selected].ticketCode
                  ) &&
                  TicketsResult[selected].ticketCode != 'tk_vxGMmLyrqzHfeJW'
                    ? !listOfEmialsValid
                    : false) ||
                  (option1Tickets.includes(TicketsResult[selected].ticketCode)
                    ? !cname.trim().length || !iname.trim().length
                    : false) ||
                  (option2Tickets.includes(TicketsResult[selected].ticketCode)
                    ? !cname.trim().length || !iname.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  //   !this.reservedMeal.trim().length ||
                  (donationOptions[TicketsResult[selected].ticketCode]
                    ? !donationPid
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_naaahp.JPEG"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                31st Annual NAAAHP Conference
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p class="lead-heading text-center">
              The 31st Annual Conference of National Association of African
              American Honors Programs (NAAAHP)
            </p>

            <p class="lead-heading text-center" style="font-size: 20px">
              Nov. 9th, 2022 @ Noon - Nov.12th, 2022 @ Noon<br />
              <a
                href="https://goo.gl/maps/5FrPhH9u7BPTEwMUA"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hyatt Regency Baltimore Inner Harbor
              </a>
            </p>
          </div>
          <hr />
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center mb-2">
                    <h4 class="w-auto d-inline-block mb-2">
                      All sales are final.<br />
                      No refunds!!!
                    </h4>
                  </div>
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in TicketsResult"
                    >
                      <div class="card-header MYLE-green white">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode]
                            ? tkList[obj.ticketCode].messages
                            : []"
                        >
                          - {{ msgs }}
                        </p>
                        <!-- <p>
                              <b class="font-weight-bold">Price:</b>
                              ${{ obj.price }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(objk)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Purchase Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Theme: Homecoming: The Resilience of HBCU Excellence in a Global
                Pandemic
                <br /><br />
                The Purpose of the National Association of African American
                Honors Programs:
                <br /><br />
                The objective of this Association shall be the promotion and
                advancement of honors and similar educational programs in
                Historically Black Colleges and Universities (HBCU), and in
                Predominately Black Colleges and Universities (PBCU), as well as
                institutions which provide honors education for African-American
                students.
                <br /><br />
                The specific goals of the Association include: <br />
                1. To develop, enhance, and support honors programs in all
                Historically/Predominately Black Institutions. <br />
                2. To stimulate and encourage community service and leadership
                as one of the high aims of education. <br />
                3. To sponsor and encourage scholarly research that address
                admission and retention of talented students, as well other
                challenges confronting honors education in Historically Black
                Colleges and Universities. <br />
                4. To advocate the funding of honors programs by federal and
                state agencies as well as by private philanthropic foundations
                and organizations. <br />
                5. To facilitate the enrollment of African-American students
                into graduate and professional schools. <br />
                6. To promote a curriculum that fosters a life-long disciplined
                approach to knowledge and scholarship through inquiry and
                exploration. <br />
                7. To develop an undergraduate educational environment that
                promotes scholarship, knowledge, and an appreciation of
                African-American culture as a mirror for understanding other
                great world cultures.<br />
                <br />
                The Conference will highlight a variety of competitive
                activities which include oratorical, poster and platform
                presentations on specified topics, Model African United Nation
                Solutions, Debates, Quiz Bowl and social responsibility
                competitions, as well as allow students the opportunity to
                receive monetary scholarship awards.
                <br /><br />
                This is an opportunity for like minded scholars to network share
                their knowledge with peers globally.
                <br /><br />
                Click the Buy Tickets button to Register or Donate Today!
                <br /><br />
                If you would like to pay by check or money order, please follow
                the instructions below and remit payment by October 28th, 2022.
                Donations and Sponsorships will be accepted through Nov. 13th.
                <br /><br />
                Send payment to the address listed below:<br />
                National Association of African American Honors Programs<br />
                Attn: Mr. Eric Pugh, Treasurer NAAAHP<br />
                P.O. Box 10046<br />
                Baton Rouge, LA 70813<br />
                Make checks payable to: National Association of African American
                Honors Programs<br /><br />

                If there are questions, please call 225-772-3401. <br /><br />

                Please provide the following information with your payment to
                ensure accurate mail/e-mail correspondence.<br />
                Name: <br />
                Institution: <br />
                Mailing address:<br />
                Contact person: <br />
                Phone:<br />
                Email: <br />
                <br />
                NAAAHP’s Federal Employer Identification Number is 46-1142855
              </p>
            </div>

            <!-- <div
                  class="row w-100 m-0 mt-5 text-center promo-section MYLE-green"
                >
                  <div class="col-lg-12 col-md-12">
                    <div class="mt-3">
                      <div class="section-heading mb-3">
                        <h5 class="text-center text-white">NupeKoming</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <video-embed
                  css="embed-responsive-16by9"
                  src="https://www.youtube.com/watch?v=hDHke06JMeE&ab_channel=KASKMERE11"
                ></video-embed> -->
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- <hr />
                <section class="download-section ptb-50 background-img bg-white">
                  <div class="container">
                    <div class="row justify-content-between">
                      <div
                        class="col-md-4 d-flex align-items-center justify-content-center"
                      >
                        <div class="download-img">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/STADIUM_LOGO.png"
                            alt="download"
                            class="img-fluid mobImg"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="download-content">
                          <h3 class="ml-5">Bottle Service</h3>

                          <ul class="lead ml-5">
                            <li>Endeavor Rum</li>
                            <li>Endeavor Gin</li>
                            <li>Endeavor Vodka</li>
                            <li>Bulleit Bourbon</li>
                            <li>Crown Royal Bourbon</li>
                            <li>Espolon Blanco</li>
                            <li>Espolon Reposado</li>
                          </ul>
                          <ul class="lead ml-5 mt-3">
                            <li>Includes Mixers</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="download-content">
                          <h3 class="ml-5">Menu</h3>

                          <ul class="lead ml-5">
                            <li>Caesar Salad</li>
                            <li>Side Salad</li>
                          </ul>
                          <ul class="lead ml-5 mt-2">
                            <li>OMG CAULIFLOWER</li>
                            <li>Mozzarella Sticks</li>
                            <li>Street Corn</li>
                            <li>Creekside Fries</li>
                            <li>NBA JAM</li>
                            <li>The Greatest</li>
                            <li>Chicken n Waffle</li>
                          </ul>
                          <ul class="lead ml-5 mt-2">
                            <li>Boneless Wings</li>
                            <li>Traditional Wings</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <hr /> -->

      <!-- <div class="container mt-5">
              <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
                <div class="col-lg-12 col-md-12">
                  <div class="mt-3">
                    <div class="section-heading mb-3">
                      <h5 class="text-center text-white">NupeKoming</h5>
                    </div>
                  </div>
                </div>
              </div>
              <video-embed
                css="embed-responsive-16by9"
                src="https://www.youtube.com/shorts/hDHke06JMeE"
              ></video-embed>
            </div> -->

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      emaillist: "",
      labelbytag: {
        tk_vxGMmLyrqzHfeJW: "Per Member Price",
      },
      emailByLabel: {
        tk_Zz22IlJECxUJxIy: "Name(s) / Email Address(es)",
        tk_yCvq4I1ConAwFAZ: "Name(s) / Email Address(es)",
      },
      emailByLabelPlaceholder: {
        tk_Zz22IlJECxUJxIy:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",

        tk_yCvq4I1ConAwFAZ:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
      },
      allowTextField: {
        tk_vxGMmLyrqzHfeJW: true,
        tk_OaxszhomSDxidDV: true,
        tk_VJ6ZO7ZyXdAeTQf: true,
        tk_dNTjtNVelWNoxi9: true,
        tk_OXiBerVQCYic7mB: true,
        tk_Zz22IlJECxUJxIy: true,
        tk_yCvq4I1ConAwFAZ: true,
        tk_QlNaa6V4ExQ3XzC: true,
        // tk_ruS5HPgk5nx3gal: true,
        tk_ZnG2CFRtGKhTvTJ: true,
      },
      labelByContactUs: {
        tk_vxGMmLyrqzHfeJW: "Primary Contact Name",
        tk_OaxszhomSDxidDV: "Student Name(s) / Email Address(es)",
        tk_VJ6ZO7ZyXdAeTQf: "Student Name(s) / Email Address(es)",
        tk_dNTjtNVelWNoxi9: "Faculty / Staff Name(s) / Email Address(es)",
        tk_OXiBerVQCYic7mB: "Faculty / Staff Name(s) / Email Address(es)",
        tk_Zz22IlJECxUJxIy: "Primary Contact Name",
        tk_yCvq4I1ConAwFAZ: "Primary Contact Name",
        tk_QlNaa6V4ExQ3XzC: "Primary Contact Name",
        // tk_ruS5HPgk5nx3gal: "Primary Contact Name",
        tk_ZnG2CFRtGKhTvTJ: "Primary Contact Name",
      },
      labelByContactUsPlaceholder: {
        tk_vxGMmLyrqzHfeJW: "Primary Contact Name",
        tk_OaxszhomSDxidDV:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_VJ6ZO7ZyXdAeTQf:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_dNTjtNVelWNoxi9:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_OXiBerVQCYic7mB:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_Zz22IlJECxUJxIy: "Primary Contact Name",
        tk_yCvq4I1ConAwFAZ: "Primary Contact Name",
        tk_QlNaa6V4ExQ3XzC: "Primary Contact Name",
        // tk_ruS5HPgk5nx3gal: "Primary Contact Name",
        tk_ZnG2CFRtGKhTvTJ: "Primary Contact Name",
      },
      labelByName: {
        tk_vxGMmLyrqzHfeJW: "Number of Memberships",
        tk_OaxszhomSDxidDV: "Number of Students",
        tk_VJ6ZO7ZyXdAeTQf: "Number of Students",
        tk_dNTjtNVelWNoxi9: "Number of Faculty/Staff",
        tk_OXiBerVQCYic7mB: "Number of Faculty/Staff",
      },
      iname: "",
      cname: "",
      option1Tickets: [
        "tk_vxGMmLyrqzHfeJW",
        "tk_OaxszhomSDxidDV",
        "tk_VJ6ZO7ZyXdAeTQf",
        "tk_dNTjtNVelWNoxi9",
        "tk_OXiBerVQCYic7mB",
        "tk_Zz22IlJECxUJxIy",
        "tk_yCvq4I1ConAwFAZ",
      ],
      option2Tickets: [
        "tk_QlNaa6V4ExQ3XzC",
        // "tk_ruS5HPgk5nx3gal",
        "tk_ZnG2CFRtGKhTvTJ",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reservedMeal: "",
      donationPid: "",
      donationOptions: {
        tk_vD7aQFuhNKVw8Bm: [
          {
            priceId: "price_1LeMYND0vTZ4QB9cBJJQRn5u",
            name: "Gold - $500",
          },
          {
            priceId: "price_1LeMYND0vTZ4QB9cKuD37llT",
            name: "Silver - $250",
          },
          {
            priceId: "price_1LeMYND0vTZ4QB9cTxgdAmpS",
            name: "Bronze - $100",
          },
        ],
      },
      reserveFormAccept: false,
      tkList: {
        tk_vxGMmLyrqzHfeJW: {
          messages: [
            "This covers your annual institutional dues",
            "Ticket Price: $200",
          ],
        },

        tk_OaxszhomSDxidDV: {
          messages: [
            "Includes Conference Admission for each student",
            "Includes meals and participation in all activities",
            "Ticket Price: $225",
          ],
        },

        tk_VJ6ZO7ZyXdAeTQf: {
          messages: [
            "Includes Conference Admission for each student",
            "Includes meals and participation in all activities",
          ],
        },

        tk_dNTjtNVelWNoxi9: {
          messages: [
            "Includes Conference Admission for Faculty & Staff Members",
            "Includes meals and participation in all activities",
            "Ticket Price: $250",
          ],
        },

        tk_OXiBerVQCYic7mB: {
          messages: [
            "Includes Conference Admission for Faculty & Staff Members",
            "Includes meals and participation in all activities",
          ],
        },

        tk_Zz22IlJECxUJxIy: {
          messages: [
            "Quiz Bowl fee is for teams/students who are NOT registered for the NAAAHP conference.",
            "Please be sure to register student names via Quiz Bowl registration link on the NAAAHP.org website by Nov. 4th @ 5pm.",
            "For inquiries, contact Deadra Mackie at deadra_james@subr.edu",
            "Ticket Price: $200",
          ],
        },

        tk_yCvq4I1ConAwFAZ: {
          messages: [
            "Quiz Bowl fee is for teams/students who are NOT registered for the NAAAHP conference.",
            "Please be sure to register student names via Quiz Bowl registration link on the NAAAHP.org website by Nov. 4th @ 5pm.",
            "For inquiries, contact Deadra Mackie at deadra_james@subr.edu",
          ],
        },

        tk_QlNaa6V4ExQ3XzC: {
          messages: [
            "Includes Vendor Registration for Graduate Institution",
            "Ticket Price: $500",
            "Processing fee of $35.10",
          ],
        },

        // tk_ruS5HPgk5nx3gal: {
        //   messages: [
        //     "Includes access to Undergraduate and Graduate Students, Faculty and Staff.",
        //     "Ticket Price: $802.35",
        //     "Processing fee of $55.96",
        //   ],
        // },

        tk_ZnG2CFRtGKhTvTJ: {
          messages: [
            "Includes meals and all activities",
            "For Board Members and Coordinators Only",
            "Ticket Price: $250",
          ],
        },
      },

      priceIds: {
        tk_vxGMmLyrqzHfeJW: "price_1LrmmUD0vTZ4QB9cPhZjeMwd",
        tk_OaxszhomSDxidDV: "price_1LrmvdD0vTZ4QB9cLudi637f",
        tk_VJ6ZO7ZyXdAeTQf: "price_1LwjScD0vTZ4QB9czNNhuJdt",
        tk_dNTjtNVelWNoxi9: "price_1LrmydD0vTZ4QB9cKoFtmsHc",
        tk_OXiBerVQCYic7mB: "price_1LwjSdD0vTZ4QB9cNtIQMD59",
        tk_Zz22IlJECxUJxIy: "price_1LrmdID0vTZ4QB9chxx3G5Tc",
        tk_yCvq4I1ConAwFAZ: "price_1LwjSeD0vTZ4QB9ct3V3nKfp",
        tk_QlNaa6V4ExQ3XzC: "price_1Lrn1fD0vTZ4QB9cBo5K1V7i",
        // tk_ruS5HPgk5nx3gal: "price_1LrraKD0vTZ4QB9clLHIWmzv",
        tk_ZnG2CFRtGKhTvTJ: "price_1LtppND0vTZ4QB9cLd7SKhOZ",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        mealName: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_12.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_13.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_14.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_15.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_16.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_17.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_18.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_19.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_21.jpg",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    change: function (e) {
      if (e.target.value && Number(e.target.value) == 1) {
        this.redeemData = null;
        this.redeemPriceId = "";
        this.donationPid = null;
        this.redeempromo = "";
      }
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].availableTickets;
          const apr =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.ticketForm.ticketCount = 1;
      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.donationPid = null;
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";
      this.iname = "";
      this.cname = "";
      this.emaillist = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.donationOptions[vm.TicketsResult[vm.selected].ticketCode]) {
        payloadToPush.priceId = vm.donationPid;
      }

      if (
        this.option1Tickets.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.institute_name = this.iname;
        payloadToPush.extraInfo.contact_name = this.cname;
      }

      if (
        this.option2Tickets.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.vendor_company = this.iname;
        payloadToPush.extraInfo.vendor_contact_name = this.cname;
      }

      if (
        this.option1Tickets.includes(
          vm.TicketsResult[vm.selected].ticketCode
        ) &&
        vm.TicketsResult[vm.selected].ticketCode != "tk_vxGMmLyrqzHfeJW"
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.contact_email = this.emaillist;
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_NAAAHP_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_vxGMmLyrqzHfeJW: null,
            tk_OaxszhomSDxidDV: null,
            tk_VJ6ZO7ZyXdAeTQf: null,
            tk_dNTjtNVelWNoxi9: null,
            tk_OXiBerVQCYic7mB: null,
            tk_Zz22IlJECxUJxIy: null,
            tk_yCvq4I1ConAwFAZ: null,
            tk_QlNaa6V4ExQ3XzC: null,
            // tk_ruS5HPgk5nx3gal: null,
            tk_ZnG2CFRtGKhTvTJ: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    listOfEmialsValid() {
      return this.emaillist.trim().split(",").join("").length;
      /*
      const arr = this.emaillist.trim().split(',');
      let valid = true;
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

      if (this.emaillist.length <= 0) {
        valid = false
      }

      arr.forEach(em => {
        if (!re.test(em.trim())) {
          valid = false
        }
      })

      return valid */
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 31st Annual NAAAHP Conference",

    meta: [
      {
        name: "description",
        content: "MYLE - 31st Annual NAAAHP Conference",
      },

      {
        name: "keywords",
        content: "MYLE - 31st Annual NAAAHP Conference",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/31st-annual-naaahp-conference",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

textarea {
  resize: none;
}
</style>
