<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
            <div class="col-md-12" id="banner">
              <img
                class="align-middle"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
                style="
                  width: 100%;
                  max-width: 100%;
                  height: auto;
                  margin-top: 4.2rem;
                "
              />
            </div>
          </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section mt-5" style="background: #304cb2">
        <div class="col-lg-12 col-md-12 mt-5">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: bold;
                  font-size: 2.5em;
                  color: white;
                "
              >
                Contact us
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="mb-1"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: bold;
              font-size: 4.5em;
              color: #304cb2;
            "
          >
            We're here for you.
          </p>

          <p
            class="mb-0"
            style="
              font-family: 'Arial', sans-serif;
              font-size: 1.8em;
              color: #f9b329;
            "
          >
            For any technical questions related to registration, event-related
            inquiries, etc. - no question is too big or too small.
            <br /><br />
            Feel free to contact us at:
          </p>

          <p
            class="mt-0"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: bold;
              font-size: 1.8em;
              color: rgb(34, 33, 33);
              word-wrap: break-word;
            "
          >
            <a href="mailto:swavisanolaevent@vegagroup.com">
              swavisanolaevent@vegagroup.com</a
            >
          </p>
        </div>
      </div>

      <hr />
      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-lg-12 col-md-12">
          <p
            class="mb-1"
            style="
              font-family: 'Arial', sans-serif;
              font-size: 1.2em;
              color: black;
            "
          >
            Terms & Conditions
          </p>

          <p
            class="mb-1"
            style="
              font-family: 'Arial', sans-serif;
              font-size: 11px;
              color: black;
            "
          >
            The Southwest<sup>®</sup> Landing Lounge (the “Event”) is available
            to select Cardmembers. The Event will occur on 5/2/2024 between the
            hours of 7:00pm CST to 10:30pm CST and on 5/3/2024 - 5/4/2024
            between the hours of 6:30pm CST to 10:00pm CST. Participation in the
            Event is limited, and available on a first-come, first-served basis.
            Cardmembers and their guests (“Participants”) may register for no
            more than one (1) Event package (“Event Package”) per day for a
            total of up to three (3) days. Participants may register for up to
            six (6) nonrefundable tickets per day registered, i.e., per Event
            Package. Therefore, by way of example, should a Participant register
            for each Event Day (3 days) and for the maximum number of tickets
            per day (6), Participant would be entitled to receive a total of 18
            tickets. Participants must be able to provide proof (a) that they
            reside in the United States and (b) that they are 21 years of age or
            older.

            <br /><br />
            Event Packages may only be reserved by the named recipient in the
            email upon the successful completion of registration using the
            emailed link. Event Packages must be purchased with a Southwest
            Rapid Rewards Credit Card.

            <br /><br />

            Southwest Airlines<sup>®</sup> reserves the right to modify or
            cancel the Event at any time and will use good faith efforts to
            notify Participants. Event schedule and staffing are subject to
            change. Offer cannot be combined with any other offer or discount.

            <br /><br />
            Event Packages are for Southwest Rapid Rewards Cardmembers and their
            guest(s) only. Event Packages are non-transferable and may not be
            resold or redeemed for cash. In the event a Cardmember needs to
            cancel tickets for themselves or their guest for any reason, tickets
            are non-refundable and non-transferable.
            <br /><br />

            Offer is void where prohibited. Offer is valid through 5/1/2024.
            <br /><br />
            Event Packages are subject to terms and conditions as specified by
            Event Package issuer. By attending this Event, Participants accept
            these terms and conditions (“Terms and Conditions”). These Terms and
            Conditions apply to you and any guests attending the Event.
            References to “you” or “your” includes participating guests. The
            invitation is for you and for non-commercial use. Any other use or
            activity in connection with the Event is strictly prohibited.
            <br /><br />
            By attending this Event, you grant Southwest Airlines and its
            affiliates the irrevocable right and permission to use your name,
            likeness, image, and voice, whether derived from photographs, video
            footage, other recordings taken during the Event, for commercial and
            other purposes and that no royalties or compensation will be paid to
            you for such uses. All ownership and copyright interests shall
            become and remain property of Southwest Airlines.

            <br /><br />
            The Event Parties (as defined below) will solely make all
            determinations in connection with the Event and access to the Event.
            Event Parties reserve the right to ask Participants to leave if they
            determine that you are behaving in a disruptive manner or violating
            any of the rules of The Chicory LLC.

            <br /><br />

            Limitations of Liability & Release: You agree to comply with all
            applicable The Chicory LLC regulations with respect to the package
            offer. In consideration of your access to and participation in the
            Event, you agree that in no event will any of the Event Parties be
            liable under any theory of tort, contract, strict liability, or
            other legal or equitable theory for any lost profits, lost data,
            lost opportunities, costs of cover, exemplary, punitive, personal
            injury/wrongful death, contraction of illness or disease, special,
            incidental, indirect, or other consequential damages, and you
            release officers, directors, Employees, agents, and assigns of
            Southwest Airlines, The Chicory LLC, Vega Group LLC, Visa Inc., Visa
            U.S.A. Inc., Visa International Service Association, and their
            respective parents, subsidiaries, successors, affiliates, and
            related companies, independent contractors, subcontractors, client
            financial institutions, prize suppliers, and advertising, marketing,
            and promotional agencies (collectively, the “Event Parties”) from
            all such claims. Further, to the fullest extent permitted by law, in
            no event will the Event Parties be liable for any damages resulting
            from your access to or participation in the Event, or acceptance,
            possession, use, misuse, or nonuse of the offer (including any
            travel or travel-related activity thereto), each of which, to the
            fullest extent permitted by law, is hereby excluded by agreement of
            the parties, regardless of whether or not either party has been
            advised of the possibility of such damages.
            <br /><br />

            No Warranties. EVENT PARTIES MAKE NO WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT REGARDING THE OFFER. ATTENDANCE OF EVENT IS AT YOUR
            OWN RISK.
            <br /><br />
            Infectious Disease Acknowledgement. You acknowledge that by
            participating in or traveling to the Event you may come into contact
            with other individuals and may be at risk of contracting infectious
            diseases (including without limitation COVID-19). You assume all
            risks of contracting any such infectious diseases in connection with
            the Event. You agree to abide by any and all safety practices,
            guidelines and protocols provided by Event Parties.

            <br /><br />

            LIMITATIONS OF LIABILITY:
            <br />
            JPMorgan Chase Bank, N.A. (“Chase”), the issuer of the Southwest<sup
              >®</sup
            >
            Visa Signature Credit Card, and Visa Inc., Visa U.S.A. Inc., Visa
            International Service Association, and their respective successors,
            affiliates, and related companies are not responsible for offer
            fulfillment or the provision of or failure to provide the stated
            items, activities, and/or benefits described in the offer. Event
            Package is subject to terms and conditions as specified by Package
            issuer. Participant agrees to comply with all applicable venue
            regulations with respect to the event offer. Participant agrees to
            hold harmless Chase, Southwest Airlines, Visa Inc., Visa U.S.A.
            Inc., Visa International Service Association, and their respective
            officers, directors, employees, agents, successors and assigns,
            affiliates, prize suppliers, and advertising and promotion agencies,
            from any and all liability or damage of any kind resulting from or
            arising from participation in the offer or acceptance, possession,
            misuse or non-use of the offer (including travel-related activity).
            Appropriate attire required at all times. Must be 21 or older to
            consume alcoholic beverages. Void where prohibited.

            <br /><br />
            The Contactless Symbol and Contactless Indicator are trademarks
            owned by and used with the permission of EMVCo, LLC.

            <br /><br />

            JPMorgan Chase Bank, N.A. Member FDIC
            <br /><br />

            SOUTHWEST RAPID REWARDS<sup>®</sup> PROGRAM INFORMATION

            <br /><br />

            The Southwest Rapid Rewards Credit Card is brought to you by
            Southwest Airlines<sup>®</sup> and Chase. Southwest Airlines is
            responsible for the redemption of Rapid Rewards points toward
            benefits and services. The number of points needed for a particular
            Southwest flight is set by Southwest and will vary depending on
            destination, time, day of travel, demand, fare type, point
            redemption rate, and other factors, and is subject to change at any
            time until the booking is confirmed. Rapid Rewards points can only
            be transferred to the primary Cardmember’s Rapid Rewards account.
            All Rapid Rewards rules and regulations apply and can be found at
            <a
              href="http://www.southwest.com/rrterms"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #304cb2"
            >
              Southwest.com/rrterms</a
            >. Southwest reserves the right to amend, suspend, or change the
            program and/or program rules at any time without notice. Rapid
            Rewards Members do not acquire property rights in accrued points.
          </p>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/Navbar1";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  methods: {
    changeFavicon(url) {
      let favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        document.head.appendChild(favicon);
      }
      favicon.setAttribute("href", url);
    },
  },
  components: {
    NavBar,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/SWA_favicon.svg");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "The Southwest® Landing Lounge Contact Us",

    meta: [
      {
        name: "description",
        content: "The Southwest® Landing Lounge Contact Us",
      },

      {
        name: "keywords",
        content: "The Southwest® Landing Lounge Contact Us",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/the-southwest-landing-lounge-contact-us",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}
</style>
