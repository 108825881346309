<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label class="mt-3">Phone (optional)</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Phone Number"
                v-model="phoneNo"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Zp9izBaReeI9K6t' &&
                TicketsResult[selected].ticketCode !== 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in (TicketsResult[selected] || {})
                    .availableSeating"
                  :value="seating.alias"
                >
                  {{ seating.alias }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-MWCSFF btn-sm my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-MWCSFF m-auto mr-2"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: black"
    >
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Inauguration2025_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #e3353e"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: #fdeb96;
                "
              >
                Roadtrip to the White House: Inauguration 2025
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #fdeb96;
                "
              >
                Saturday, January 18 - 21, 2024 <br />
                06:00 AM - 08:00 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #fdeb96;
                "
              >
                <a
                  href="https://maps.app.goo.gl/GXTdsB3o3xPvmK326"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #fdeb96"
                >
                  United States Capitol
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: white"
      >
        <div class="col-md-12">
          <!-- <div class="download-content">
                    <p
                      class="lead-heading text-center"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      Everything you love about brunch and none of the things you don’t!
                      Catch a vibe with us this Father’s Day. Join us for an
                      All-Inclusive Brunch Event at The Monroe in Downtown Orlando.
                    </p>
                  </div>
                  <hr /> -->

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.4em;
                "
              >
                Join us for an unforgettable journey to witness history in the
                making!

                <br /><br />

                Celebrate the Inauguration 2025 with a memorable road trip to
                the nation’s capital. This 4-day, 3-night experience will take
                you from Columbus, Ohio, to Washington, DC, with comfortable
                coach transportation and flexible accommodation options. Choose
                your package and secure your reservation before the deadline on
                <span class="bolded">Sunday, November 10th at Noon.</span>
                <br /><br />
                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Event Details:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  📅 <span class="bolded"> Date:</span> Saturday, January 18th -
                  21st, 2025 (4 Days / 3 Nights)<br />
                  ⏰ <span class="bolded"> Time:</span> 6 AM - 8 PM
                  <br />
                  📍 <span class="bolded"> Location:</span>

                  <a
                    href="https://maps.app.goo.gl/GXTdsB3o3xPvmK326"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: white"
                  >
                    United States Capitol
                  </a>

                  <br />
                  <br />
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Package Options:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  <span class="bolded">
                    • Transportation Only - $175 Per Person</span
                  >
                  <br />
                  <br />
                  Enjoy roundtrip travel from Columbus, Ohio, to the Washington,
                  DC area in a comfortable coach bus.
                  <br />
                  Perfect for those who have already secured their
                  accommodations or want to explore on their own.

                  <br />
                  <br />

                  <span class="bolded">
                    • Hotel Reservations - Starting at $119 per Night +
                    Tax</span
                  >
                  <br />
                  <br />
                  Hotel reservations will be managed through the hotel link.

                  <br />
                  <br />
                  Important: Must stay all 3 nights to secure your room
                  reservation.
                  <br />
                  <br />

                  <span class="bolded"> Hotel Options Include:</span>
                  <br />
                  - Courtyard Dulles Town Center<br />
                  - Tru By Hilton Ashburn<br />
                  - Clarion Inn<br />

                  <br />
                  Rates range from $119 - $409 per night + tax. For detailed
                  rates and cancellation policies, please review the details via
                  the hotel reservation link.

                  <br />
                  <br />

                  <span class="bolded"> • T-Shirts - $25 Each</span>
                  <br />
                  <br />
                  Add a commemorative T-shirt to your purchase to celebrate this
                  historic trip. T-shirts are available in various sizes and
                  will be distributed during the departure.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Important Details:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  <span class="bolded"> Transportation Payment: </span>
                  All transportation bookings will be handled directly through
                  this registration page.
                  <br />
                  <br />
                  <span class="bolded"> Hotel Reservations: </span>
                  Click the hotel button below to book the hotel of your choice.
                  Reservations must be secured by
                  <span class="bolded">Sunday, November 10th at Noon.</span>

                  <br />
                  <br />
                  <span class="bolded"> Hotel Cancellation Policy: </span>
                  Please note that your hotel will place a hold or fully charge
                  your card to secure your reservation. If your reservation is
                  cancelled prior to the deadline date assigned by your hotel
                  (dates vary), your charge will be credited. Refer to the hotel
                  link for details.

                  <br />
                  <br />
                  <span class="bolded">
                    Deadline to Secure Your Reservation:
                  </span>
                  All transportation bookings and hotel reservations must be
                  completed by
                  <span class="bolded">Sunday, November 10th at Noon.</span>

                  <br />
                  <br />
                  <span class="bolded"> No Refunds: </span>
                  All sales are final, based on Transportation and Hotel
                  guidelines. Please ensure your plans are confirmed before
                  booking.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  Ready to Reserve Your Spot?
                  <!-- <br />
                  <br />
                  Click the button below to book your transportation and
                  T-shirts. For hotel reservations, please use the provided link
                  to contact our travel agent. -->
                </span>

                <br />
                <br />

                <!-- ///BUTONS -->

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Note:
                </span>

                <br />
                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  Space is limited, so don’t wait—secure your seat on the
                  Roadtrip to the White House today and be part of history!
                  <br />
                  For any questions or more details, please email
                  <a href="mailto:hello@myle.com"> hello@myle.com</a>
                  and an event coordinator will follow-up with you.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Cancellation and Refund Policy:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  Please note that all transportation reservations are subject
                  to non-refundable fees if the trip is canceled prior to the
                  deadline date. These fees cover costs already incurred for
                  securing transportation services and cannot be refunded.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Important:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  After the cancellation deadline, this event becomes
                  non-refundable. It is the sole responsibility of the purchaser
                  to find a replacement buyer and arrange for the transfer of
                  their travel arrangements if they are unable to attend. We
                  recommend utilizing social media platforms, travel groups, or
                  personal networks to sell your spot.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Event Association Disclaimer:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  This event is not an NPHC Columbus event and is not associated
                  with NPHC Columbus.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2em;
                    color: white;
                  "
                >
                  Safety Disclaimer:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  By participating in this event, all attendees acknowledge that
                  they assume any and all risks associated with travel and
                  participation, including but not limited to injury, illness,
                  or death that may occur during or after the event. The event
                  planners, organizers, and any affiliated parties are not
                  liable for any personal injury, loss, damage, or death that
                  may occur during the trip or as a result of participation in
                  this event. Attendees are strongly encouraged to obtain their
                  own travel insurance as needed.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                  "
                >
                  Thank you for your understanding and cooperation as we strive
                  to make this experience smooth for all participants.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
      <!--
            <div
              id="d1"
              style="max-width: 600px; margin: auto; height: auto; margin-top: 50px"
            ></div> -->

      <!-- <div
              class="row align-items-center justify-content-center test"
              style="margin: auto; margin-top: 20px"
            >
              <div
                class="col-md-3 align-items-center justify-content-center"
                style="display: flex"
              >
                <table>
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            background: #0ea293;
                            color: #0ea293;
                            width: 20px;
                            height: 20px;
                            margin-right: 3px;
                          "
                        ></div>
                      </td>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            font-family: 'Quicksand', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                          "
                        >
                          2024 Jazz Brunch - Individual Seating
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
      
              <div
                class="col-md-3 align-items-center justify-content-center"
                style="display: flex"
              >
                <table>
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            background: #a19140;
                            color: black;
                            width: 20px;
                            height: 20px;
                            margin-right: 3px;
                          "
                        ></div>
                      </td>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            font-family: 'Quicksand', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                          "
                        >
                          2024 Jazz Brunch - Table for 8
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
      
              <div
                class="col-md-3 align-items-center justify-content-center"
                style="display: flex"
              >
                <table>
                  <tbody>
                    <tr>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            background: lightgrey;
                            color: lightgrey;
                            width: 20px;
                            height: 20px;
                            margin-right: 3px;
                          "
                        ></div>
                      </td>
                      <td style="vertical-align: middle">
                        <div
                          style="
                            font-family: 'Quicksand', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                          "
                        >
                          Reserved
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->

      <!-- <div
              id="d2"
              style="max-width: 650px; margin: auto; height: auto; margin-top: 50px"
            ></div> -->
      <!-- Tickets Section starts -->

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-size: 2.6em;
                  color: white;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <br />
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                  box-shadow: 4px 4px #e3353e;
                  color: white;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="background-color: #e3353e"
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="
                    display: flex;
                    flex-direction: column;
                    background: black;
                  "
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-MWCSFF"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sales Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <h3
        class="w-auto d-inline-block mb-5"
        style="
          font-family: 'Quicksand', sans-serif;
          font-size: 1.3em;
          color: white;
        "
      >
        Pickup/Dropoff location in Columbus: TBD
      </h3>
    </div>

    <br /><br /><br />
    <!-- <div class="text-center">
        <h3
          class="w-auto"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 40px;
            color: white;
          "
        >
          Watch The Video
        </h3>
      </div> -->
    <!-- <br />
      <div class="row" style="margin: auto; background: black">
        <div class="col-md-2 col-md-2"></div>
        <div class="col-md-8 col-md-8">
          <div class="text-center">
            <video-embed
              css="embed-responsive-16by9"
              src="https://www.youtube.com/watch?v=nmVB02laCc8"
            ></video-embed>
          </div>
        </div>
  
        <div class="col-md-2 col-md-2"></div>
      </div>
      <br />
      <br />
      <br /> -->

    <section class="container mb-5">
      <div class="text-center">
        <h3
          class="w-auto d-inline-block mb-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-size: 2.6em;
            color: white;
          "
        >
          HOTEL OPTIONS
        </h3>
      </div>
      <!-- <div class="row">
        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Residence_Inn_Dulles_Airport_at_Dulles_28_Centre_(A).jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Residence Inn Dulles Airport at Dulles 28 Centre (A)
            </h3>
          </div>
        </div>

        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Courtyard_Dulles_Town_Center_(A).jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Courtyard Dulles Town Center
            </h3>
          </div>
        </div>

        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Residence_Inn_Chantilly_Dulles_South_(B).jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Residence Inn Chantilly Dulles South (B)
            </h3>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Clarion_Inn_V1.JPG"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Clarion by Choice Hotels
              <br />
              Hotel & Conference Center Leesburg
              <br />
              $119 USD/night
            </h3>

            <div class="text-center mt-4">
              <a
                href="https://www.choicehotels.com/reservations/groups/MW32T2"
                class="btn solid-btn-MWCSFF text-center"
                style="font-size: 18px"
                target="_blank"
                rel="noopener noreferrer"
                >Reserve Now
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Courtyard_Dulles_Airport_Chantilly.jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Courtyard by Marriott
              <br />
              Dulles Airport Chantilly
              <br />
              $339.00 USD/night
            </h3>

            <div class="text-center mt-4">
              <a
                href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1730395517969&key=GRP&guestreslink2=true"
                class="btn solid-btn-MWCSFF text-center"
                style="font-size: 18px"
                target="_blank"
                rel="noopener noreferrer"
                >Reserve Now
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-4 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Tru_By_Hilton_Ashburn.jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
            <br />
            <h3
              class="text-center mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-size: 1.1em;
                font-weight: 400;
                color: white;
              "
            >
              Tru By Hilton Ashburn
              <br />
              Ashburn One Loudoun
              <br />
              $409.00 USD/night
            </h3>
            <div class="text-center mt-4">
              <a
                href="https://www.hilton.com/en/attend-my-event/iadolru-92g-1b33ad2b-bec3-428a-ae25-2f2103db1fb7/"
                class="btn solid-btn-MWCSFF text-center"
                style="font-size: 18px"
                target="_blank"
                rel="noopener noreferrer"
                >Reserve Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <br />
    <section class="container mt-5">
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/FLYER_Inauguration2025_V2.jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>

        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
    </section>
    <br />

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #e3353e"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mock_MIDWESTCIGARSOCIAL_2F2F_V1.png"
          style="width: 100%; max-width: 100%; height: auto"
        /><br />
        <br />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 1.2em;
            color: white;
          "
        >
          Download the MYLE Events App via
          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or find it in your iOS or Google Play Store under MYLE Events.

          <br />
          <br />

          The MYLE App offers convenient transportation options, integrating
          Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your one-stop
          solution for a hassle-free travel experience.

          <br />
          <br />
          Note: Your tickets will be available on the MYLE App in the
          Account>Tickets section after purchase. You can easily access them via
          email (check spam if not received) or upload them to your phone wallet
          for quick access into the event.

          <br />
          <br />
          🌟 We can't wait to see you 🌟
        </p>
      </div>
    </div> -->

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: ["tk_yEQQN655VqEIKhw"],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_yEQQN655VqEIKhw: {
          messages: ["Ticket Price: $175", "Processing fee of $13.38"],
        },
      },

      priceIds: {
        tk_yEQQN655VqEIKhw: "price_1QBhF9D0vTZ4QB9c3YBcpVJb",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      phoneNo: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);
        console.log("--------");
        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? v.text == "1" || v.text == "9"
                  ? "#0ea293"
                  : "#a19140"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 300)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 306.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 40)
        .attr("height", 40)
        .attr("y", 100)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("y", 125)
        .attr("x", 2.5)
        .text("Band")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }

      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_NzrhJLxmsLhNLaD"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      }
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      payloadToPush.extraInfo = {
        phone: this.phoneNo,
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.phoneNo = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "f6gTgJbY52cc9I",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_yEQQN655VqEIKhw: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          // vm.drawseatsd1(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Roadtrip to the White House: Inauguration 2025",
    meta: [
      {
        name: "description",
        content: "MYLE - Roadtrip to the White House: Inauguration 2025",
      },

      {
        name: "keywords",
        content: "MYLE - Roadtrip to the White House: Inauguration 2025",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/roadtrip-to-the-white-house-inauguration-2025",
      },
    ],
  },
};
</script>

<style scoped>
.velmld-parent {
  background: black !important;
}

#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}
</style>
