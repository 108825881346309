<template>
  <header class="header">
    <!--start navbar-->
    <div
      class="navColor1"
      style="
        background-image: linear-gradient(136deg, #f05176, #f05176) !important;
        z-index: 999999;
      "
      v-bind:class="{
        affix: hasAffix,
        'custom-nav': coloredLogo,
        'bg-transparent': !coloredLogo,
        'white-bg': coloredLogo,
      }"
    >
      <div class="container-fluid p-0">
        <a
          v-if="false"
          class="navbar-brand"
          href="/the-breeze-easy-visa-signature-cardmember-experience"
          ><img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/BreezeVISA_navlogo.png"
            width="179"
            alt="logo"
            class="img-fluid"
        /></a>

        <button
          class="navbar-toggler w-100 bg-transparent"
          type="button"
          @click="mobileNavClicked"
          v-bind:class="{ collapsed: collapsed }"
          v-if="false"
        >
          <div
            class="w-100 d-flex justify-between bg-transparent"
            style="justify-content: space-between !important"
          >
            <span class="block">VISA Breeze</span>
            <span class="ti-menu block"></span>
          </div>
        </button>

        <div
          class="main-menu h-auto"
          v-bind:class="{ show: !collapsed }"
          id="navbarSupportedContent"
        >
          <ul
            class="d-flex text-center"
            style="justify-content: center !important"
          >
            <li class="nav-item nav-link page-scroll p-2">
              <router-link
                id="newnav"
                to="/the-breeze-easy-visa-signature-cardmember-experience"
                ><span
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-size: calc(0.2rem * (0.9vh + 0.9vw));
                    font-weight: 700;
                    color: white;
                  "
                >
                  Information</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll p-2">
              <router-link
                id="newnav"
                class="nav-link"
                to="/the-breeze-easy-visa-signature-cardmember-experience-venue"
              >
                <span
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-size: calc(0.2rem * (0.9vh + 0.9vw));
                    font-weight: 700;
                    color: white;
                  "
                >
                  Venue</span
                >
              </router-link>
            </li>

            <li class="nav-item nav-link page-scroll p-2">
              <router-link
                id="newnav"
                to="/the-breeze-easy-visa-signature-cardmember-experience-sofar-sounds"
                ><span
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-size: calc(0.2rem * (0.9vh + 0.9vw));
                    font-weight: 700;
                    color: white;
                  "
                >
                  Sofar Sounds</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll p-2">
              <router-link
                id="newnav"
                to="/the-breeze-easy-visa-signature-cardmember-experience-contact-us"
                ><span
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-size: calc(0.2rem * (0.9vh + 0.9vw));
                    font-weight: 700;
                    color: white;
                  "
                >
                  Contact Us</span
                ></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--end navbar-->
  </header>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    valclicked() {
      window.showpopup = true;
      this.$emit("opencart", true);
    },
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>

<style scoped>
.navbar-toggler {
  background: #2076ba;
  padding: 0.5rem 0.75rem;
  font-size: inherit;
  transition: all 0.3s ease-in-out;
}

.main-menu ul > li a {
  margin: 0px;
}
</style>
