import { render, staticRenderFns } from "./CommunityHallTown.vue?vue&type=template&id=04e10387&scoped=true"
import script from "./CommunityHallTown.vue?vue&type=script&lang=js"
export * from "./CommunityHallTown.vue?vue&type=script&lang=js"
import style0 from "./CommunityHallTown.vue?vue&type=style&index=0&id=04e10387&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e10387",
  null
  
)

export default component.exports