<template>
  <div>
    <nav-bar />
    <div class="main">
      <section>
        <div class="container">
          <div class="row text-center">
            <div class="col-md-12 col-lg-12 position p-0">
              <div class="hero-content-left text-white">
                <h1 class="text-white textMobHeading">
                  <span
                    class="animate__animated animate__fadeIn animate__delay-1s"
                    >Extra MYLE International</span
                  >
                </h1>
                <p
                  class="lead animate__animated animate__fadeInLeft animate__delay-2s textMobPara"
                >
                  Join us as we CHANGE LIVES BY GOING THE EXTRA MYLE.
                </p>

                <button
                  v-scroll-to="'#extraMYLEdonation'"
                  class="btn google-play-btn animate__animated animate__fadeInLeft animate__delay-2s"
                >
                  Donate Now
                </button>

                <div class="mouse_scroll">
                  <div class="mouse">
                    <div class="wheel"></div>
                  </div>
                  <div>
                    <span class="m_scroll_arrows unu"></span>
                    <span class="m_scroll_arrows doi"></span>
                    <span class="m_scroll_arrows trei"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vue-flux
          :options="vfOptions"
          :images="vfImages"
          :transitions="vfTransitions"
          ref="slider"
          style="z-index: 0 !important"
        >
        </vue-flux>
      </section>

      <section class="download-section ptb-50 background-img bg-white">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-3">
              <div class="download-img">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle.png"
                  alt="download"
                  class="img-fluid mobImg"
                />
              </div>
            </div>
            <div class="col-md-9">
              <div class="download-content">
                <p class="lead ml-5">
                  <b>MYLE co-founders Kevin and Sherry Lloyd</b> credit their
                  exposure to arts, culture, and travel as young adults, for
                  showing them the power of these experiences which opened their
                  eyes to a broader world, one of possibilities and
                  opportunities. <br /><br />With the founding of their
                  entertainment software platform, MYLE (Make Your Life
                  Entertaining), they determined that they would create a
                  non-profit organization, Extra MYLE International, that will
                  provide experiences to underprivileged youth and their
                  families to connect them to the power of arts and culture.
                  <br /><br />The Lloyd's aspiration is that young people will
                  be exposed to transformational experiences, positively
                  impacting the mindset of our under-served communities and
                  broadening their ambition to fulfill their capabilities
                  <br /><br />Extra MYLE aims to expose and educate at least
                  25,000 inner-city, socio-economically disadvantaged youth
                  (between K-12) and their families through arts, culture,
                  travel, and entertainment by 2025.
                </p>
                <!-- <div class="ml-5">
                  <a href="#" class="btn solid-btn">Coming Soon</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="download-section ptb-50 extraMyleSectionBg">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-3">
              <div class="download-img">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/girlImg.png"
                  alt="download"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-md-9">
              <div class="download-content">
                <h4 class="ml-5 text-white">
                  “We believe that if you can change the mindset of a child, you
                  may change their life which may change the world.”
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="promo-section ptb-50">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="section-heading mb-5">
                <p class="lead text-center">FOUR WAYS TO</p>
                <h2 class="text-center">Go the Extra MYLE</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6 mb-lg-0">
              <div class="card single-promo-card single-promo-hover">
                <div class="card-body miniumCardHeight">
                  <div class="pb-2">
                    <span class="ti-credit-card icon-md themeLiteGreen"></span>
                  </div>
                  <div class="pt-2 pb-3" v-scroll-to="'#extraMYLEdonation'">
                    <h5>Donate</h5>
                    <p class="text-muted mb-0">
                      Provide a direct donation* and Go the Extra MYLE. Click
                      here to donate now.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card single-promo-card single-promo-hover mb-lg-0">
                <div class="card-body miniumCardHeight">
                  <div class="pb-2">
                    <span
                      class="ti-control-record icon-md themeLiteGreen"
                    ></span>
                  </div>
                  <div class="pt-2 pb-3">
                    <h5>Upgrade</h5>
                    <p class="text-muted mb-0">
                      You may upgrade your free membership on MYLE to a Mogul
                      Account. We will allocate a portion of the proceeds from
                      all subscription-based accounts toward Extra MYLE
                      International.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card single-promo-card single-promo-hover mb-lg-0">
                <div class="card-body miniumCardHeight">
                  <div class="pb-2">
                    <span class="ti-vector icon-md themeLiteGreen"></span>
                  </div>
                  <a href="/#contact" target="_self">
                    <div class="pt-2 pb-3">
                      <h5>Community Partner</h5>
                      <p class="text-muted mb-0">
                        Become a Community Partner. Members of community-based
                        organizations may be eligible to receive financial
                        assistance to attend entertainment-related activities
                        including free or discounted access to experiences from
                        EMI Event Partners
                        <br />
                        Contact Us Today!
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card single-promo-card single-promo-hover mb-lg-0">
                <div class="card-body miniumCardHeight">
                  <div class="pb-2">
                    <span class="ti-receipt icon-md themeLiteGreen"></span>
                  </div>
                  <a href="/#contact" target="_self">
                    <div class="pt-2 pb-3">
                      <h5>Event Partner</h5>
                      <p class="text-muted mb-0">
                        Become an Event Partner. Event Partners provide a
                        portion of their event tickets for MYLE Community
                        Partner Members to receive free access to entertainment
                        experiences.<br />
                        Contact Us Today!
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <p class="mt-5">
              *Direct Donations are tax-deductible. Donation letters will be
              provided for donations above $250.
            </p>
          </div>
        </div>
      </section>

      <section id="extraMYLEdonation">
        <stripe-checkout
          ref="checkoutRef"
          mode="payment"
          :pk="checkout.publishKey"
          :line-items="checkout.lineItems"
          :success-url="checkout.successURL"
          :cancel-url="checkout.cancelURL"
          :clientReferenceId="checkout.clientReferenceId"
        />

        <form v-on:submit="submit">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2>Extra MYLE Donation</h2>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-12 mb-4" v-if="false">
                <div class="d-flex flex-row">
                  <input
                    type="button"
                    class="non-active"
                    value="One Time"
                    @click="formData.payType = 'One Time'"
                    v-bind:class="{
                      'non-active': formData.payType != 'One Time',
                      active: formData.payType == 'One Time',
                    }"
                  />
                  <input
                    type="button"
                    class="non-active"
                    value="Monthly"
                    @click="formData.payType = 'Monthly'"
                    v-bind:class="{
                      'non-active': formData.payType != 'Monthly',
                      active: formData.payType == 'Monthly',
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <p>Choose donation amount</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-4">
                <div class="d-flex flex-row">
                  <input
                    type="button"
                    class="btn non-active"
                    value="$25"
                    style="
                      border-top-right-radius: 0px !important;
                      border-bottom-right-radius: 0px !important;
                    "
                    v-bind:class="{ active: formData.payAmount == 25 }"
                    @click="formData.payAmount = 25"
                  />
                  <input
                    type="button"
                    class="non-active"
                    value="$50"
                    v-bind:class="{ active: formData.payAmount == 50 }"
                    @click="formData.payAmount = 50"
                  />
                  <input
                    type="button"
                    class="non-active"
                    value="$100"
                    v-bind:class="{ active: formData.payAmount == 100 }"
                    @click="formData.payAmount = 100"
                  />
                  <input
                    type="button"
                    class="non-active"
                    value="$250"
                    v-bind:class="{ active: formData.payAmount == 250 }"
                    @click="formData.payAmount = 250"
                  />
                  <input
                    type="button"
                    class="btn non-active"
                    value="$500"
                    style="
                      border-top-left-radius: 0px !important;
                      border-bottom-left-radius: 0px !important;
                    "
                    v-bind:class="{ active: formData.payAmount == 500 }"
                    @click="formData.payAmount = 500"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  v-model="formData.fname"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  v-model="formData.lname"
                  required
                />
              </div>
            </div>

            <div class="row mb-4">
              <div class="pl-3">
                <label class="customCheck"
                  >Don't show my name publicly.
                  <input
                    type="checkbox"
                    checked="false"
                    v-model="formData.showPublically"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required
                  v-model="formData.email"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.phone"
                  placeholder="Phone Number"
                  required
                />
              </div>
            </div>

            <div class="row mb-4">
              <div class="pl-3">
                <label class="customCheck"
                  >It's okay to contact me in the future.
                  <input type="checkbox" v-model="formData.contactInfuture" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-12">
                <textarea
                  class="form-control"
                  v-model="formData.comment"
                  placeholder="Leave a comment"
                  required
                />
              </div>
            </div>

            <div class="row mb-5">
              <div class="col-md-12 text-center">
                <button
                  type="submit"
                  class="btn solid-btn"
                  id="btnContactUs"
                  style="pointer-events: all; cursor: pointer"
                  :disabled="isSubmitted"
                  v-bind:class="{ pnone: isSubmitted }"
                >
                  Donate Now
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { VueFlux } from "vue-flux";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";

export default {
  name: "ExtraMyle",
  components: {
    NavBar,
    SiteFooter,
    VueFlux,
    StripeCheckout,
  },
  mounted() {
    const id = localStorage.getItem("refId");

    if (id && this.$route.query && this.$route.query.status == id) {
      localStorage.removeItem("refId");
      this.$toast.success(
        "Thank You for Your Donation to Extra MYLE International — you’re helping to change lives by going the Extra MYLE",
        { timeout: 4000 }
      );
    }
  },
  methods: {
    getRandomString(length) {
      const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    },
    submit(e) {
      e.preventDefault();
      e.stopPropagation();
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post("https://myle-19701.uc.r.appspot.com/api/donation_request", {
          donationAmount: this.formData.payAmount,
          firstName: this.formData.fname,
          lastName: this.formData.lname,
          email: this.formData.email.toLowerCase(),
          phoneNumber: this.formData.phone,
          comment: this.formData.comment,
          showNamePublicaly: this.formData.showPublically ? 1 : 0,
          allowFutureContact: this.formData.contactInfuture ? 1 : 0,
        })
        .then((re) => {
          if (re.data && re.data.Result) {
            vm.checkout.clientReferenceId =
              this.getRandomString(15) + "_" + re.data.Result.donationId;
            vm.checkout.lineItems[0].price = re.data.Result.priceId;
            localStorage.setItem("refId", vm.checkout.clientReferenceId);
            vm.checkout.successURL +=
              "?status=" + vm.checkout.clientReferenceId;
            vm.$refs.checkoutRef.redirectToCheckout();
          }
        })
        .catch((err) => {
          vm.isSubmitted = false;
          return err;
        });
    },
  },
  data: () => ({
    vfOptions: {
      autoplay: true,
    },
    vfImages: [
      "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle-bg.jpg",
      "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle-bg-1.jpg",
      "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle-bg-2.jpg",
      "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle-bg-3.jpg",
    ],
    vfTransitions: ["fade"],
    formData: {
      payType: "One Time",
      payAmount: 25,
      fname: "",
      lname: "",
      showPublically: false,
      email: "",
      contactInfuture: true,
      phone: "",
      comment: "",
    },
    checkout: {
      clientReferenceId: null,
      publishKey:
        "pk_live_51K1gNdAt4BmGJKDTTxmif4IznfvZ0pkDH4ITJmHOaipcIXE0dQkW84DXdGc9cHLSKHHuzqhLiMkjH3tAVQWqRrp800W3nVRNAM",
      lineItems: [
        {
          price: null,
          quantity: 1,
        },
      ],
      successURL: "https://myle.com/extramyle",
      cancelURL: "https://myle.com/extramyle",
    },
    isSubmitted: false,
  }),
};
</script>

<style scoped>
.non-active {
  color: #104233 !important;
  border: 1px solid #104233 !important;
  background: white !important;
  outline: none !important;
  padding: 5px 25px;
}

.non-active:hover {
  color: white !important;
  border: 1px solid #104233 !important;
  background: #104233 !important;
  outline: none !important;
}

.active {
  color: white !important;
  border: 1px solid #104233 !important;
  background: #104233 !important;
  outline: none !important;
}

textarea {
  resize: none;
  height: 200px !important;
}

.pnone {
  pointer-events: none !important;
}
</style>
