<template>
  <div>
    <nav-bar />
    <div class="main">
      <banner />
      <experiences />
      <uber />
      <features />
      <Ticketing />
      <!-- REASoNS -->
      <ReasonToUseMYLE />
      <ticketEvents />

      <about />
      <!-- <video-promo /> -->
      <screenshots :is-gray="true" />
      <clients />
      <contact :is-gray="false" />
      <key-features :is-green="true" />
      <site-footer />
    </div>
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-five/Banner";
import About from "../../views/index-five/About";
// import VideoPromo from "../../views/index-two/VideoPromo";
import Experiences from "../../views/index-five/Experiences";
import Features from "../../views/index-five/Features";
import Uber from "../../views/index-five/Uber";
import Screenshots from "../../views/commons/Screenshots";
import Contact from "../../views/commons/Contact";
import KeyFeatures from "../../views/index-five/KeyFeatures";
import Clients from "../../views/commons/Clients";
import SiteFooter from "../../views/index-five/SiteFooter";
import TicketEvents from "../../views/index-five/TicketEvents";
import Ticketing from "../../views/index-five/ticketing";
import ReasonToUseMYLE from "../../views/index-five/ReasonToUseMYLE";

export default {
  name: "IndexFive",
  components: {
    NavBar,
    Banner,
    About,
    // VideoPromo,
    Experiences,
    Features,
    Ticketing,
    ReasonToUseMYLE,
    TicketEvents,
    Uber,
    Screenshots,
    Contact,
    KeyFeatures,
    Clients,
    SiteFooter,
  },
};
</script>
