<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green mt-5">
        <div class="col-lg-12 col-md-12 mt-3">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                Ticketing FAQ
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Ticket Handling Fees:
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How much are the fees to use MYLE?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: The ticket handling fees are 4% + .30 per ticket
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Are there other additional handling fees?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: Yes, each ticket will be assessed a payment processing fee of
              2.9% + .30 per ticket, of which MYLE does not retain any of these
              fees.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Can the ticket handling fees be passed onto the ticket
              purchaser in full?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: Yes, you can pass all of the ticket handling fees of 6.9% + .60
              on to the ticket purchaser if desired.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Can we off-set the ticket handling fee that is passed onto the
              ticket purchaser?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: Yes, you can choose to pay a portion of the ticket handling
              fees on behalf of your ticket purchaser 4% + .30 per ticket, which
              will be deducted from your gross ticket sales amount. The ticket
              purchaser will be charged 2.9% + .30.
            </p>
          </div>

          <div class="download-content" style="overflow: scroll">
            <table id="customers" class="text-center">
              <thead>
                <tr>
                  <th>Scenario</th>
                  <th>Example Price</th>
                  <th>Ticket Purchaser Handling Fees</th>
                  <th>Host Ticket Handling Fees</th>
                  <th>Host Net Revenue</th>
                  <th>
                    Payment Processor Fee (out of ticket handling fees
                    collected)
                  </th>
                  <th>MYLE Fee (out of ticket handling fees collected)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ticket purchaser pays all ticket handling fees</td>
                  <td>$100</td>
                  <td>$100 + 6.9% + .60 = $107.50</td>
                  <td>$0</td>
                  <td>$100</td>
                  <td>$2.9% + .30 = $3.20</td>
                  <td>4% +.30 = $4.30</td>
                </tr>
                <tr>
                  <td>Host/ticket purchaser share ticket handling fees</td>
                  <td>$100</td>
                  <td>$100 + 2.9% + .30 = $103.20</td>
                  <td>4% + .30 $4.30</td>
                  <td>$95.70</td>
                  <td>2.9% + .30 = $3.20</td>
                  <td>4% + .30 = $4.30</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Payout Process
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: When do I receive my payout for tickets sold?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: We will initiate your payout process within two business days
              after your event date. You will receive an initial payout of 80%
              and within five business days, you will receive the balance minus
              any chargebacks. This payout schedule allows time for processing
              and securing all funds from your event.
              <br />
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I receive my payout for tickets sold?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: You may select to have your payout received via Stripe, Paypal
              or Bank Wire. A member of our support team will contact you to
              confirm your preference and to acquire your payout account
              information.
              <br />
              Note: Transfer fees will be deducted from the final payout.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Payment Confirmations
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I view attendees who purchase tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: You will receive a confirmation email immediately after each
              ticket is purchased for your event. Confirmations will be sent to
              the email established when setting up your MYLE account. If you
              would like to add an additional email address to receive
              confirmations, please send your request via email to
              hello@myle.com.
              <br />
              A member of our support team will follow-up within one (1)
              business day.
              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Ticket Changes
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I make edits to my event or tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: You may edit your event or ticket information by going to the
              account section on MYLE, via the mobile app or desktop version.
              Click on your event and make your edits. Note: Once a ticket has
              been purchased for a particular ticket type, you will not be able
              to change the price for that ticket type. However, you may add a
              new ticket type and price.
              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Checking in attendees
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I check-in my attendees during the event?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: You will have the ability to check-in attendees with the
              scanner on your mobile device. Once you complete the ticket set-up
              and post your event, you will notice a scan button on the explore
              screen. You may use this scanner to verify attendees. To add
              access for additional scanners, please provide their email
              addresses to hello@myle.com, at least one (1) business day prior
              to your event. All individuals who will be scanning, must have the
              MYLE App downloaded on their phones or tablet.

              <br />
              Note: We will be launching the MYLE Manager App which will provide
              you with admin access to view your attendees, transaction
              information, check-in attendees, review data insights and more.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Emails
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I email my attendees?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: You will receive real-time attendee confirmation emails that
              include purchaser email information. Additionally, you will
              receive a MYLE Daily Ticket Purchase Recap that will compile key
              purchaser information including email addresses. You may
              copy/paste or download the recap to import the addresses into an
              email client.

              <br />
              Note: We are developing an email client which will allow you to
              engage and email your attendees directly through MYLE.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Who do I contact if I have a question?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: Please send inquiries to hello@myle.com. A member of our
              support team will follow-up within one (1) business day. If you
              would like to learn more or ask specific questions, please join us
              during MYLE Office Hours on Tuesdays or Wednesdays. You may
              register here.
              <a
                href="https://myle.com/office-hours"
                target="_blank"
                rel="noopener noreferrer"
              >
                here</a
              >.
              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Why MYLE?
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Why should I use MYLE to sell my tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              A: MYLE is the most comprehensive event and entertainment platform
              on the market that also allows you to sell tickets and promote
              your event at the same time. Our seamless ticketing process, event
              amplification, video preview and COVID Safety Indicator features
              provide a simple process for you as well as attendees to get an
              overview of your event and complete their buying process in one
              place. MYLE also provides transportation features to easily get to
              your event including Uber/Lyft, an interactive feature for
              attendees to engage with event hosts, share events with their
              friends, view videos and set reminders.

              <br />
              <br />

              Up to 5% of the proceeds that are earn by MYLE from your event
              will be donated to our Non-Profit organization, Extra MYLE
              International. Extra MYLE was established to expose and educate,
              underprivileged youth with Entertainment, Arts and Travel
              experiences that may have a positive impact on their lives. We
              believe that these experiences may impact their mindset, which may
              change their lives so that they may change the world. Your event
              makes this a reality and you are Going The Extra MYLE.

              <br />
              <br />

              You have the ability to generate additional revenue through our
              Affiliate/Referral program when you sell tickets through MYLE. You
              may invite your attendees to either scan your QR code upon
              purchase/entry or to click your referral link. You will receive 50
              MYLE Referral points for each sign-up that may be redeemed for
              cash when established goals are achieved. This is an easy way to
              earn additional income while also increasing your audience size
              for current and future events.

              <br />
              <br />
              Note: To ensure you receive referral points,
              <br />
              1. Have users click your QR code/Link to download the app and
              <br />
              2. Click your QR code/Link again to access the sign-up page with
              your referral code
              <br />
              <br />
              To personalize your QR code, tap on the code and edit it to your
              code of choice.
              <br />
              For more information regarding our affiliate/referral program,
              click
              <a
                href="https://myle.com/affiliate"
                target="_blank"
                rel="noopener noreferrer"
              >
                here</a
              >.
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Ticketing FAQs",

    meta: [
      {
        name: "description",
        content: " Ticketing FAQ",
      },

      {
        name: "keywords",
        content: " Ticketing FAQ",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/ticketing-faq",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}
</style>
