<template>
  <div>
    <nav-bar />
    <div class="main">
      <section>
        <div class="container-fluid">
          <div class="row mt45Tab">
            <div class="col-md-12 col-lg-12 p-0">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/businessBg.png"
                alt="Business Image"
                class="w-100 img-fluid"
              />
              <h2 class="headGreenBg mb-0">MYLE for Business</h2>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row mb-5">
            <div class="col-md-8 col-lg-8">
              <div class="mtBusiness">
                <h5 class="mb-5">
                  How much time and money does it cost you to reach your
                  customers?
                </h5>
                <h6 class="mb-3 font-italic">
                  Are you still spending tons of money using multiple tools?
                </h6>
                <p class="mb-5">
                  MYLE offers an all-in-one entertainment marketing and event
                  sales solution specifically designed to deliver customer
                  connections through integrated channels on a digital platform
                  typically available to large corporations. Independents and
                  small companies are now able to compete without blowing their
                  budgets to grow and scale.
                </p>
                <div class="row">
                  <div class="col-md-4">
                    <div class="card border border-0 img-fluid">
                      <img
                        class="businessSmallImg"
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/BusinessImg1.png"
                        alt="Card image"
                      />
                      <div class="card-img-overlay">
                        <h5 class="card-title text-center white mb-0">
                          Curate customizable events
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card border border-0 img-fluid">
                      <img
                        class="businessSmallImg"
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/BusinessImg2.png"
                        alt="Card image"
                      />
                      <div class="card-img-overlay">
                        <h5 class="card-title text-center white mb-0">
                          Amplify<br />visibility
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card border border-0 img-fluid">
                      <img
                        class="businessSmallImg"
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/BusinessImg3.png"
                        alt="Card image"
                      />
                      <div class="card-img-overlay">
                        <h5 class="card-title text-center white mb-0">
                          Gain customer insights
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 class="mb-3 mt-5 font-italic">
                  Here are some of the features you get when you become a MYLE
                  subscriber.
                </h6>
                <span class="font-weight-bold selfBullet">•</span> Free Event
                Post (Independent)<br />
                <span class="font-weight-bold selfBullet">•</span> Promotional
                Event Post<br />
                <span class="ml-4"
                  ><span class="font-weight-bold selfBullet">-</span> Amplify
                  (Independent/Small Businesses)</span
                ><br />
                <span class="ml-4"
                  ><span class="font-weight-bold selfBullet">-</span> Mogul -
                  Monthly/Annual (Small Businesses/Orgs)</span
                ><br />
                <span class="font-weight-bold selfBullet">•</span> Digital
                Ads<br />
                <span class="font-weight-bold selfBullet">•</span> Enterprise
                Packages (Ad Campaigns/Marketing Analytics)
              </div>
            </div>

            <div class="col-md-4 col-lg-4 mtBusiness">
              <div class="card img-fluid h-75 subBox">
                <div class="card-img-overlay d-block p-0 mt-5">
                  <h6 class="card-title text-center mt-3">
                    If you are seeking an easier way.
                  </h6>
                  <h4 class="text-center mb-0 mt-5">
                    Subscribe to MYLE today!
                  </h4>
                  <div class="text-center mt-5">
                    <a href="#" class="btn solid-btn">Subscribe</a>
                  </div>
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/subscribeImg.jpg"
                    alt="Business Image"
                    class="w-100 img-fluid mt-5"
                  />
                </div>
              </div>
              <!-- <h5 class="mt-5">
                 If you are seeking an easier way. Subscribe to MYLE today!
                </h5> -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },

  metaInfo: {
    title: "Discover Local Events that match your passions",

    meta: [
      {
        name: "description",
        content:
          "Discover all-in-one entertainment marketing and event sales solution specifically designed to deliver customer connections through integrated channels on a digital platform.",
      },

      {
        name: "keywords",
        content:
          "online business events | Business networking events | sell tickets | event management software | sell tickets online | promote your event | manage an event",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/business",
      },
    ],
  },
};
</script>
