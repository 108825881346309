<template src="./WaitlistSuccess.html"></template>

<script>
// Vuex Imports
// Scss Imports
import "./WaitlistSuccess.scss";

export default {
  name: "WaitlistSuccess",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
