<template>
  <section
    id="5-reasons-why-you-should-use-myle-for-your-next-event"
    class="download-section ptb-50 background-img bg-white"
  >
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-12 text-center">
          <div class="download-img">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_why_icon.png"
              alt="ticket"
              class="img-fluid"
              style="width: 25%"
            />
          </div>
        </div>

        <div class="col-md-12" style="color: black">
          <div class="download-content">
            <h2
              class="text-center m-3"
              style="
                font-family: 'Montserrat', 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 3em;
              "
            >
              5 REASONS WHY YOU SHOULD USE MYLE FOR YOUR NEXT EVENT
            </h2>
            <p
              class="mt-5 mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.3em;
                "
                >1.
              </span>
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.2em;
                "
                >It’s FREE</span
              >
              to use -
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >NO FEE</span
              >
              to Host Events! Post Your Events & Sell Tickets in Minutes. Want
              to take your private or special event to the next level? Ask about
              our personalized event registration options to promote your brand.
            </p>

            <a
              href="https://myle.com/myle-ticketing"
              class="btn solid-btn mt-0 mb-5 text-center align-items-center justify-content-between"
              >Learn More</a
            >

            <p
              class="mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.3em;
                "
                >2.
              </span>
              Event hosts
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >EARN 10-20% OF THE FEES GENERATED BY MYLE</span
              >
              when the minimum threshold is met.
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >THE MORE YOU MAKE, THE MORE YOU MAKE!</span
              >
            </p>

            <a
              href="https://myle.com/profit-partner"
              class="btn solid-btn mt-0 mb-5"
              >Learn More</a
            >

            <p
              class="mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.3em;
                "
                >3.
              </span>
              Anyone who
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >REFERS NEW CLIENTS</span
              >
              to MYLE with their unique referral code may

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >EARN UP TO 30% OF THE FEES GENERATED BY MYLE</span
              >
              and also

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >MAKE MONEY BY USING THE MYLE APP</span
              >

              . Earn points that may be redeemed for

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >CASH</span
              >
              when event attendees download the

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >MYLE APP</span
              >
              using your referral code.
            </p>

            <a href="https://myle.com/affiliate" class="btn solid-btn mt-0 mb-5"
              >Learn More</a
            >

            <p
              class="mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.3em;
                "
                >4.
              </span>
              Ticket buyers

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >SAVE MONEY</span
              >
              on lower fees, creating more

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >VALUE</span
              >
              and less complaints. Event hosts have the option to pass along,
              split or absorb service/processing fees with buyers.
            </p>

            <a
              href="https://myle.com/myle-ticketing"
              class="btn solid-btn mt-0 mb-5"
              >Learn More</a
            >

            <p
              class="mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-size: 1.3em;
                "
                >5.
              </span>
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >EXPAND YOUR REACH AND PROMOTE</span
              >
              your events in seconds with our proprietary Geo-Fenced Push
              Notification technology to

              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >MYLE APP USERS</span
              >
              within 50 miles of your event.
            </p>

            <a href="https://myle.com/business" class="btn solid-btn mt-0 mb-5"
              >Learn More</a
            >

            <p
              class="mb-0"
              style="
                font-family: 'nunito-bold', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 3em;
              "
            >
              BONUS:
            </p>

            <p
              class="mb-2"
              style="
                font-family: 'nunito-regular', 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 1.2em;
              "
            >
              <span
                style="
                  font-family: 'nunito-bold', 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                "
                >MYLE DONATES UP TO 5%</span
              >
              of its net proceeds to underserved, youth based non-profit
              organizations through our non-profit, Extra MYLE International.

              <br />
              *Event hosts may earmark donations to a qualifying charity of
              choice.
            </p>

            <a href="https://myle.com/extramyle" class="btn solid-btn mt-0"
              >GET STARTED</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReasonToUseMYLE",
};
</script>

<style scoped>
.bolded {
  font-weight: bold;
}
</style>
