<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #0b3048;
                  "
                  v-if="showDonation"
                >
                  Donate
                </h4>
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #0b3048;
                  "
                  v-if="!showDonation"
                >
                  Reserve Your Tickets
                </h4>
              </div>
            </div>

            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Full Name
              </span>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Email
              </span>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mt-4" v-if="false">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Where did you go to school?
              </span>
              <input
                type="email"
                placeholder="Enter your school name"
                class="form-control mb-3"
                v-model="userSchool"
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Cart -->

            <!-- Ticket Options -->
            <div class="col-md-12" v-if="!showDonation">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #0b3048;
                  "
                >
                  Ticket Options
                </h4>
              </div>
            </div>

            <div class="col-md-4" v-if="!showDonation">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Ticket Type</label
              >
              <select
                @change="dayChange('daylist')"
                class="form-control"
                v-model="daylist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, true)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3" v-if="!showDonation">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist.length == 0 &&
                    initvalue[daylist.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option disabled v-if="initvalue[daylist.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center" v-if="!showDonation">
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Person:</b
                >

                <!-- ${{ ttypelist ? ttypelist.price.toFixed(2) : "" }} -->

                ${{
                  ttypelist && ttypelist.price
                    ? ttypelist.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Donation:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >

                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}

                <!-- ${{
                    ((ttypelist ? ttypelist.price : 0) * tickcount).toFixed(2)
                  }} -->
              </p>
              <p v-if="redeemData && redeemData.price">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                !showDonation
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                !showDonation
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                !showDonation
              "
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-EpicHowardAlumni btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 text-center my-4" v-if="!showDonation">
              <input
                type="button"
                @click="addtocart('daylist')"
                class="btn solid-btn-EpicHowardAlumni m-auto mr-2"
                :disabled="!daylist || !tickcount"
                value="Add to cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" v-if="!showDonation" />
            <!-- Ticket Options -->

            <!-- Donation Options -->
            <div class="col-md-12 mb-3" v-if="showDonation">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #0b3048;
                  "
                >
                  Select Donation(s)
                </span>
              </div>
            </div>

            <div class="col-md-4" v-if="showDonation">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Donation Type</label
              >
              <select
                @change="dayChange('daylist1')"
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3" v-if="showDonation">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Donation(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more donations
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center" v-if="showDonation">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Donation Amount:</b
                >
                ${{
                  ttypelist1 && ttypelist1.price
                    ? ttypelist1.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 text-center my-4" v-if="showDonation">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-EpicHowardAlumni m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" v-if="showDonation" />
            <!-- Ticket Options -->

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #0b3048;
                  "
                >
                  Cart Details
                </h4>
              </div>
            </div>

            <div class="col-md-12">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Selected item(s)
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Amount
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.ticketName }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>${{ (r.tttcount * r.price).toFixed(2) }}</p>
                    </td>
                    <td style="width: 10%; text-align: right; cursor: pointer">
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          X</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 20px;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.1em;
                        color: grey;
                      "
                    >
                      <p class="text-center" v-if="!showDonation">
                        No Ticket(s)
                      </p>
                      <p class="text-center" v-if="showDonation">
                        No Donation(s)
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Cart details -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>

            <hr
              class="w-100 p-2"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            />
            <div
              class="row"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6 mb-2">
                <label class="">Name of Business/Individual</label>
                <input
                  type="text"
                  v-model="nameOfBusiness"
                  class="form-control"
                  placeholder="Name of Business/Individual"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Address</label>
                <input
                  type="text"
                  v-model="address"
                  class="form-control"
                  placeholder="Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">City, State, Zip</label>
                <input
                  type="text"
                  v-model="cityStateZip"
                  class="form-control"
                  placeholder="City, State, Zip"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Contact Person</label>
                <input
                  type="text"
                  v-model="contactPerson"
                  class="form-control"
                  placeholder="Contact Person"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Contact Number</label>
                <input
                  type="text"
                  v-model="telephoneNumber"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Email Address</label>
                <input
                  type="text"
                  v-model="emailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: grey;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowcustom()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !selectedCarts.length ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-EpicHowardAlumni m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->
      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_EpicHowardAlumni.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #042278"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #ffffff;
                "
              >
                Epic Howard Alumni & Friends 100th Homecoming Celebration
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                Friday, October 18, 2024 <br />
                08:00 PM - 02:00 AM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                <a
                  href="https://maps.app.goo.gl/Zej2qEyzFCqCRxSm7"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #ffffff"
                >
                  DC Water HQQ Waterside
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content mt-2">
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.6em;
                  color: #0b3048;
                "
              >
                It is Howard University's 100th Homecoming!!!! It's About to be
                LIT🔥🔥🔥

                <br /><br />
                Join the Bison Group (Tiffany, Kamal, Matt & Joe) for a
                memorable evening of reconnecting with friends and dancing,
                while sipping handcrafted cocktails under the stars along the
                Capitol River waterfront. This ultra modern beautifully curated
                space features a large outdoor patio, an outside cigar lounge, a
                spacious indoor lounge and a rooftop outside space with stuninng
                city views of the Fredrick Douglas bridge, Audi & National
                Stadiums and Anacostia River.

                <br /><br />
                Bison Group is also excited to offer get you and/or your guest
                attending the Celebration:

                <br />

                • Open Bar - 8 to 11:30pm <br />
                • Open Food Bar - 8 to 11:30pm<br />
                • Rooftop Access - Limited to 8 to 10pm (Note: 9::45pm Rooftop -
                music, bars & access to rooftop will be shut down)<br />
                • Great Music - DJs - Howard (HU), Blinks (HU) & Cosi (NYC)<br />
                • Access to a Circle of Black Excellence - Howard, HBCU, & PWI
                Black Grads & Supporters for across the Globe<br />

                <br />
                Howard and Black Love to All!!! Come join us in the
                Celebration!!!
              </p>
            </div>

            <br />

            <div class="download-content">
              <p
                class="text-center"
                style="font-family: 'nunito', sans-serif; font-size: 1.6em"
              >
                <button
                  class="btn solid-btn-EpicHowardAlumni text-center mb-3"
                  @click="openmodelmyle(false)"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    margin: auto;
                    display: block;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1em;
                    font-weight: bold;
                  "
                >
                  Get Tickets
                </button>

                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 0.8em;
                    color: #000000;
                  "
                >
                  OR </span
                ><br />

                <button
                  class="btn solid-btn-EpicHowardAlumni text-center mt-3"
                  @click="openmodelmyle(true)"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    margin: auto;
                    display: block;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1em;
                    font-weight: bold;
                  "
                >
                  Student Donation
                </button>
              </p>
              <br />
              <!-- <br /> -->
              <!-- <div class="row" style="margin: auto; background: transparent">
                <div class="col-md-1 col-md-1"></div>
                <div class="col-md-5 col-md-5">
                  <div class="text-center">
                    <video-embed
                      css="embed-responsive-16by9"
                      src="https://www.youtube.com/watch?v=aiwMpKzt1Ac"
                    ></video-embed>
                  </div>
                </div>
                <div class="col-md-5 col-md-5">
                  <div class="text-center">
                    <video-embed
                      css="embed-responsive-16by9"
                      src="https://www.youtube.com/watch?v=C9XaLCMb4Uw"
                    ></video-embed>
                  </div>
                </div>
                <div class="col-md-1 col-md-1"></div>
              </div>
              <br /> -->
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.6em;
                  color: #0b3048;
                "
              >
                🗣️For a seamless ticketing and check-in experience, we've
                partnered with MYLE - Make Your Life Entertaining! MYLE is a
                HBCU owned AI-based event and entertainment platform.

                <br />
                <br />

                Download the MYLE Events App via
                <a
                  href="https://myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.myle.com</a
                >
                or find it in your iOS or Google Play Store under MYLE Events.
                Don't forget to use referral code “HBCUinfluencer" during
                account set-up!

                <br />
                <br />

                Note: Your tickets will be available on the MYLE App in the
                Account -> Tickets section after registration. You can easily
                access them via email (check spam if not received) or upload
                them to your phone wallet for quick access into the event.

                <br />
                <br />

                The MYLE App offers convenient transportation options,
                integrating Waze, Apple Maps, Google Maps, Uber, and Lyft. It's
                your one-stop solution for a hassle-free travel experience.

                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// import vueThermometer from "../../../components/VueThermometer.vue";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      showDonation: false,
      thermoResult: 0,
      options: {
        text: {
          color: "black",
          fontSize: 14,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 16,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 600,
          width: 140,
        },
      },

      selectedCarts: [],
      ttypelist: null,
      ttypelist1: null,
      nameOfBusiness: "",
      address: "",
      cityStateZip: "",
      contactPerson: "",
      telephoneNumber: "",
      emailAddress: "",
      names: {},
      showsuccess: false,

      vchange: true,
      validSection: true,
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {},
      section2: [],

      donations: [
        "tk_FOBpuoFspaJbEv6",
        "tk_BPv7StpVUQRHnEk",
        "tk_aXU0roeGWohEDYG",
        "tk_TxvXuTV00QiCSxe",
      ],
      pricemap: {
        tk_m6PGSCeSFCCAkWe: "price_1PjVrCD0vTZ4QB9cKK4Z5DZI",
        tk_jyySLPrDpffNKzm: "price_1PjVrCD0vTZ4QB9cKK4Z5DZI",
        tk_KX7cuxTAmj0vtjn: "price_1PjVrCD0vTZ4QB9cKK4Z5DZI",
        // tk_qnQm7UiP61VW3r9: "price_1PjVrCD0vTZ4QB9cKK4Z5DZI",
        tk_cQ8oKfvxnpefKm9: "price_1PjVKhD0vTZ4QB9cmeTkNSeD",
        tk_OVsaZTLoE9Y83ef: "price_1PsXFMD0vTZ4QB9cfaLDanCd",
        tk_3fxsxIXJ61UftiM: "price_1Psc2KD0vTZ4QB9cyFa3UUPX",
        tk_q9hjXCpl1hgkVEj: "price_1Psc29D0vTZ4QB9cNLHZ2Kch",
        tk_LDB4bEaqbsGuHkL: "price_1Psc20D0vTZ4QB9cs1rJiEXT",
      },

      daylist: {},
      daylist1: {},
      completeList: [],
      initvalue: {},

      section1: [
        // "tk_qnQm7UiP61VW3r9",
        "tk_cQ8oKfvxnpefKm9",
        "tk_OVsaZTLoE9Y83ef",
        "tk_3fxsxIXJ61UftiM",
        "tk_q9hjXCpl1hgkVEj",
        "tk_LDB4bEaqbsGuHkL",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_cQ8oKfvxnpefKm9: {
          messages: ["Ticket Price: $60.00", "Processing fee of $5.66"],
        },
        tk_OVsaZTLoE9Y83ef: {
          messages: ["Ticket Price: $25.00", "Processing fee of $3.32"],
        },
        tk_3fxsxIXJ61UftiM: {
          messages: ["Ticket Price: $50.00", "Processing fee of $4.99"],
        },
        tk_q9hjXCpl1hgkVEj: {
          messages: ["Ticket Price: $100.00", "Processing fee of $8.35"],
        },
        tk_LDB4bEaqbsGuHkL: {
          messages: ["Ticket Price: $250.00", "Processing fee of $18.41"],
        },
      },

      priceIds: {
        tk_m6PGSCeSFCCAkWe: "price_1Q3fzaD0vTZ4QB9c2yMdrVYc",
        tk_jyySLPrDpffNKzm: "price_1Q3fzrD0vTZ4QB9c2XWNSzHJ",
        tk_KX7cuxTAmj0vtjn: "price_1Q3fzxD0vTZ4QB9c0ZSlZiWZ",
        tk_FOBpuoFspaJbEv6: "price_1Q4v71D0vTZ4QB9ctijrmJZV",
        tk_BPv7StpVUQRHnEk: "price_1Q4v71D0vTZ4QB9cOduwi1oi",
        tk_aXU0roeGWohEDYG: "price_1Q4v72D0vTZ4QB9coTxWrzvK",
        tk_TxvXuTV00QiCSxe: "price_1Q4v72D0vTZ4QB9cKXcQwYcf",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      userSchool: "",
      tickcount: 1,
      tickcount1: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      tickcountlist1: [],

      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_1.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_2.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_3.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_4.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_5.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_8.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_9.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slideshow_HBCUNYClassic_10.png",
      ],
    };
  },
  components: {
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
    NavBar,
    SiteFooter,
  },
  methods: {
    nonDonations(list) {
      return list.filter((r) => r[0].t != "daylist1");
    },

    fetchRouteTickets(list, isRoute) {
      const routes = [
        "tk_m6PGSCeSFCCAkWe",
        "tk_jyySLPrDpffNKzm",
        "tk_KX7cuxTAmj0vtjn",
      ];
      const donationn = [
        "tk_FOBpuoFspaJbEv6",
        "tk_BPv7StpVUQRHnEk",
        "tk_aXU0roeGWohEDYG",
        "tk_TxvXuTV00QiCSxe",
      ];

      if (isRoute) {
        return list.filter((r) => routes.includes(r.ticketCode));
      }

      return list.filter((r) => donationn.includes(r.ticketCode));
    },

    fetchStatus() {
      const req = "https://myle-19701.uc.r.appspot.com/api/event_total_sales";
      const self = this;
      self.thermoResult = 0;
      axios
        .post(req, {
          eventId: "yGpMGzNSqtYTruck",
          userId: "",
        })
        .then((res) => {
          if (res && res.data && res.data.Result) {
            self.thermoResult =
              res.data.Result.totalSales > 0
                ? res.data.Result.totalSales / 1000
                : 0;
          }
        });
    },

    addtocart(t) {
      const rec = { ...this[t] };
      if (t == "daylist" && this.tickcount < 1) {
        return;
      }
      if (t == "daylist1" && this.tickcount1 < 1) {
        return;
      }
      if (rec && t == "daylist") {
        for (let c = 0; c < this.tickcount; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount,
          t
        );
        console.log("dsds", this.cntholder);
      }

      if (rec && t == "daylist1") {
        for (let c = 0; c < this.tickcount1; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount1,
          t
        );
        console.log("dsds", this.cntholder);
      }

      this.dayChange(t);
    },

    dayChange(t) {
      if (t == "daylist") {
        this.tickcount = 1;
        this.tickcountlist = [];
      } else {
        this.tickcount1 = 1;
        this.tickcountlist1 = [];
      }

      const w = this[t];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        if (!this.donations.includes(w.ticketCode)) {
          this.tickcountlist.push(k + 1);
        } else if (this.donations.includes(w.ticketCode)) {
          this.tickcountlist1.push(k + 1);
        }
      }
      if (t === "daylist") {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist.push(k1 + 1);
          }
        }

        if (this.tickcountlist.length == 0) {
          this.tickcount = 0;
        }
      } else {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist1.length;
          this.tickcountlist1 = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist1.push(k1 + 1);
          }
        }

        if (this.tickcountlist1.length == 0) {
          this.tickcount1 = 0;
        }
      }

      if (t == "daylist") {
        this.ttypelist = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist.length;
        }
      } else {
        this.ttypelist1 = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist1.length;
        }
      }
    },

    deleteRec(rec, i) {
      console.log(i);
      this.selectedCarts = this.selectedCarts.filter((x, it) => it != i);
      this.ttypelist = this.TicketsResult[0];
    },
    isvalidName() {
      let isValid = true;
      if (this.tickcount < 1) {
        return isValid;
      }
      Object.keys(this.names).forEach((e) => {
        if (!e.includes("email") && this.names[e].trim().length < 1) {
          isValid = false;
        } else if (e.includes("email")) {
          const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!re.test(this.names[e].trim())) {
            isValid = false;
          }
        }
      });

      return (this.vchange || !this.vchange) && isValid;
    },
    totalCount(list) {
      if (this.TicketsResult[this.selected]) {
        const tmp = Object.keys(list).length;
        return tmp > 1 ? [...Array(tmp - 1).keys()] : [];
      }

      return [];
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    openmodelmyle(flg) {
      this.showDonation = flg;
      this.validSection = true;
      this.nameOfBusiness = "";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.userSchool = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.selectedCarts = [];
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      this.ttypelist = null;
      this.ttypelist1 = null;
      this.daylist = {};
      this.daylist1 = {};
      this.tickcount = 1;
      this.tickcount1 = 1;
      this.selectedCarts = [];
      this.tickcountlist = [];
      this.tickcountlist1 = [];
      this.initvalue = {};
      this.dayChange("daylist");
      this.dayChange("daylist1");
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = 0;
      const idx = 0;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynowcustom: function () {
      this.isSubmitted = true;
      const vm = this;
      const payloadData = {
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: this.selectedCarts[0].eventId,
        origin: "WEB",
        tickets: [],
      };

      this.selectedCarts.forEach((re) => {
        payloadData.tickets.push({
          priceId: this.priceIds[re.ticketCode],
          ticketId: re.ticketCode,
          totalTickets: re.tttcount,
          // additionalInfo: {},
          // extraInfo: {
          //   school: this.userSchool,
          // },
        });
      });

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/request_cart_tickets",
          payloadData
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          /*  vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
                vm.$refs.modal.close();
                vm.reserveFormAccept = false;
                vm.fullname = "";
                vm.email = "";
                vm.tickcount = 1;
                vm.fetchAllTickets();
  
              vm.isSubmitted = false;
              vm.reserveFormAccept = false;
              vm.redeemData = null;
              vm.redeemPriceId = "";
  
              this.showsuccess = false; */
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEpicHowardAlumniTickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          if (ticketsResult && ticketsResult.data.Result) {
            vm.TicketsResult = ticketsResult.data.Result;
            vm.completeList = ticketsResult.data.Result;
          }
          vm.TicketsResult = vm.completeList;
          vm.eventTicketResult = vm.TicketsResult;
          vm.dayChange("daylist");
          vm.dayChange("daylist1");
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchStatus();
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal.open();
        localStorage.removeItem("refsId");
      }, 1000);
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal.open();
        localStorage.removeItem("refsId");
      }, 1000);
    }
  },
  computed: {
    isTicketSelected() {
      const routes = [
        // "tk_qnQm7UiP61VW3r9",
        "tk_cQ8oKfvxnpefKm9",
      ];
      let isPresent = false;
      this.selectedCarts.forEach((r) => {
        if (!isPresent) {
          isPresent = routes.includes(r.ticketCode);
        }
      });

      return isPresent;
    },

    completed() {
      console.log(this.showcreateForm);
      let isFilled = true;
      Object.values(this.sections).forEach((rec) => {
        rec.forEach((item) => {
          if (!item.data && item.t != "daylist1") {
            isFilled = false;
          }
        });
      });

      if (!Object.keys(this.sections).length) {
        return false;
      }

      return isFilled;
    },

    validEmail() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(this.emailAddress);
    },
    tellPno() {
      return (
        (this.telephoneNumber || "").match(/\d/g) &&
        (this.telephoneNumber || "").trim().length >= 10
      );
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidPno() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  watch: {
    selectedCarts(list) {
      const w = {};
      this.sections = JSON.parse(JSON.stringify(this.sections || {}));

      list.forEach((r) => {
        if (!w[r.ticketCode]) {
          w[r.ticketCode] = [];
        }
        for (let e = 0; e < r.tttcount; e++) {
          w[r.ticketCode].push({ ...r });
        }
      });

      if (this.sections.length) {
        Object.keys(w).forEach((h) => {
          let present = false;

          this.sections.forEach((rr) => {
            if (rr[0].ticketCode === h) {
              present = true;
            }
          });

          if (!present) {
            this.sections.push(JSON.parse(JSON.stringify(w[h])));
          }
        });

        this.sections.forEach((rr, i) => {
          Object.keys(w[rr[0].ticketCode]).forEach((wx) => {
            if (!this.sections[i][wx]) {
              this.sections[i].push(w[rr[0].ticketCode][wx]);
            }
          });
        });

        this.sections = [...this.sections];
      } else {
        this.sections = Object.values(w);
      }

      console.log(this.sections);
    },
  },
  metaInfo: {
    title: "MYLE - Epic Howard Alumni & Friends 100th Homecoming Celebration",
    meta: [
      {
        name: "description",
        content:
          "MYLE - Epic Howard Alumni & Friends 100th Homecoming Celebration",
      },

      {
        name: "keywords",
        content:
          "MYLE - Epic Howard Alumni & Friends 100th Homecoming Celebration",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/epic-howard-alumni-friends-100th-homecoming-celebration",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
