<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/aff-bg.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->

      <!-- Information -->
      <section id="download" class="white-bg ptb-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2 class="mt-4">
                  Are you ready to take your events, business or marketing
                  capabilities to the next level???
                </h2>
              </div>
            </div>
          </div>

          <h6 class="mb-3 mt-5 font-bold">
            Join us during MYLE Office Hours and Learn how to:
          </h6>
          <span class="font-weight-bold selfBullet">• </span>Post your public or
          private events on MYLE<br />
          <span class="font-weight-bold selfBullet">• </span>Promote your events
          on MYLE<br />
          <span class="font-weight-bold selfBullet">• </span>Sell tickets on
          MYLE<br />
          <span class="font-weight-bold selfBullet">• </span>Engage your
          audience on MYLE<br />
          <span class="font-weight-bold selfBullet">• </span>Generate revenue
          through MYLE<br />
          <span class="font-weight-bold selfBullet">• </span>Analyze MYLE data
          insights to better understand your consumers.<br />
          <span class="font-weight-bold selfBullet">• </span>Identify the key
          differentiators between MYLE and other entertainment platforms<br />
          <span class="font-weight-bold selfBullet">• </span>Use MYLE to
          positively impact underprivileged youth in your community<br />

          <h6 class="mb-3 mt-3 font-italic">
            We're excited to share more about the World's Most Dynamic
            Entertainment Platform.<br /><br />
            JOIN US DURING<br />
            MYLE OFFICE HOURS<br /><br />

            Tuesday, June 11th @ 12:00pm<br />
            Wednesday, June 12th @ 06:00pm<br /><br />

            Tuesday, June 18th @ 12:00pm<br />
            Wednesday, June 19th @ 06:00pm<br /><br />

            Tuesday, July 2nd @ 12:00pm<br /><br />

            Tuesday, July 9th @ 12:00pm<br />
            Wednesday, July 10th @ 06:00pm<br /><br />

            Tuesday, July 16th @ 12:00pm<br />
            Wednesday, July 17th @ 06:00pm<br /><br />

            Tuesday, July 23rd @ 12:00pm<br />
            Wednesday, July 24th @ 06:00pm<br /><br />

            Tuesday, July 30th @ 12:00pm<br />
            Wednesday, July 31st @ 06:00pm<br /><br />
          </h6>
        </div>
      </section>
      <hr />
      <!-- Information -->

      <!-- Register forms starts -->
      <div>
        <form>
          <div class="container px-5">
            <div class="row">
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
                <img
                  class="download-img"
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/myle_clean_logo.png"
                  style="width: 60%; max-width: 60%; height: auto"
                />
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h4>Registration Form</h4>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Name*</label>
                    <input
                      class="form-control"
                      placeholder="Name"
                      type="text"
                      required
                      v-model="payload.name"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Email*</label>
                    <input
                      class="form-control"
                      placeholder="Email"
                      type="email"
                      required
                      v-model="payload.email"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Company/Organization*</label>
                    <input
                      class="form-control"
                      placeholder="If not affiliate with a company, type N/A"
                      type="text"
                      required
                      v-model="payload.company"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Role*</label>
                    <select class="form-control" v-model="payload.role">
                      <option
                        class="form-control"
                        v-bind:key="role + 'b'"
                        v-for="role in roles"
                        :value="role"
                      >
                        {{ role }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Date & Time*</label>
                    <select class="form-control" v-model="payload.slot">
                      <br /><br />

                      <option
                        class="form-control"
                        value="Tuesday, June 11th @ 12:00pm"
                      >
                        Tuesday, June 11th @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, June 12th @ 06:00pm"
                      >
                        Wednesday, June 12th @ 06:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, June 18th @ 12:00pm"
                      >
                        Tuesday, June 18th @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, June 19th @ 06:00pm"
                      >
                        Wednesday, June 19th @ 06:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, July 2nd @ 12:00pm"
                      >
                        Tuesday, July 2nd @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, July 9th @ 12:00pm"
                      >
                        Tuesday, July 9th @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, July 10th @ 06:00pm"
                      >
                        Wednesday, July 10th @ 06:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, July 16th @ 12:00pm"
                      >
                        Tuesday, July 16th @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, July 17th @ 06:00pm"
                      >
                        Wednesday, July 17th @ 06:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, July 23rd @ 12:00pm"
                      >
                        Tuesday, July 23rd @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, July 24th @ 06:00pm"
                      >
                        Wednesday, July 24th @ 06:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Tuesday, July 30th @ 12:00pm"
                      >
                        Tuesday, July 30th @ 12:00pm
                      </option>

                      <option
                        class="form-control"
                        value="Wednesday, July 31st @ 06:00pm"
                      >
                        Wednesday, July 31st @ 06:00pm
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label>Have you downloaded or signed up for MYLE?*</label
                    ><br />
                    <input
                      type="radio"
                      name="appdownload"
                      value="1"
                      v-model="payload.appDownload"
                    />
                    Yes
                    <input
                      type="radio"
                      name="appdownload"
                      value="0"
                      v-model="payload.appDownload"
                    />
                    No
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <input type="checkbox" v-model="payload.receiveEmail" />
                    Yes! I want to receive emails from MYLE to find things to
                    do, places to go and food to eat. I understand I can
                    unsubscribe at anytime
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-center my-3">
                    <button
                      type="button"
                      @click="register()"
                      :disabled="
                        !payload.name.trim().length ||
                        !payload.company.trim().length ||
                        !payload.role.trim().length ||
                        !invalidemail ||
                        isSubmitted
                      "
                      class="btn solid-btn m-auto"
                      style="pointer-events: all; cursor: pointer"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Register forms ends -->
    </div>
    <br />
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h3 class="text-center text-white">Fun is waiting for you.</h3>
            <h2 class="text-center text-white">Download or Sign-up Today!</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  name: "TrainingComponent",
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },
  computed: {
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.payload.email
      );
    },
  },
  methods: {
    register: function () {
      const payloadToPush = {
        name: this.payload.name,
        email: this.payload.email.toLowerCase(),
        role: this.payload.role,
        organization: this.payload.company,
        app_downloaded: Number(this.payload.appDownload),
        subscribed: this.payload.receiveEmail ? 1 : 0,
        slot: this.payload.slot,
      };
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/training_session_requested",
          payloadToPush
        )
        .then((response) => {
          vm.isSubmitted = false;
          if (
            response.data &&
            response.data.Message &&
            response.data.Status == "Success"
          ) {
            vm.$toast.success("Request submitted", { timeout: 4000 });
            vm.payload = {
              role: "Convention/Tourism Representative",
              name: "",
              email: "",
              company: "",
              appDownload: 1,
              receiveEmail: false,
              slot: "Tuesday, June 11th @ 12:00pm",
            };
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.success("Something went wrong", { timeout: 4000 });
        });
    },
  },
  data() {
    return {
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      isSubmitted: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      payload: {
        role: "Convention/Tourism Representative",
        name: "",
        email: "",
        company: "",
        appDownload: 1,
        receiveEmail: false,
        slot: "Tuesday, June 11th @ 12:00pm",
      },
      roles: [
        "Convention/Tourism Representative",
        "DJ",
        "Event Goer",
        "Event Planner",
        "Faith Based Representative",
        "Hotel Representative",
        "Influencer",
        "Investor",
        "Lifestyle Critic/Blogger/Writer",
        "Promoter",
        "Venue Owner",
        "Social Organization Representative",
        "Other",
      ],
    };
  },

  metaInfo: {
    title: "MYLE - Office Hours",

    meta: [
      {
        name: "description",
        content: "MYLE - Office Hours",
      },

      {
        name: "keywords",
        content: "MYLE - Office Hours",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/office-hours",
      },
    ],
  },
};
</script>

<style>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
</style>
