<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold"
                  >{{
                    labelbytag[TicketsResult[selected].ticketCode] ||
                    "Per Person Price"
                  }}:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold"
                  >{{
                    labelbytag[TicketsResult[selected].ticketCode] ||
                    "Per Person Price"
                  }}:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketType !== 'DONATION'
              "
            >
              <label class="mt-3">{{
                labelByName[TicketsResult[selected].ticketCode] ||
                "Number of Ticket(s)"
              }}</label>
              <select class="form-control" v-model="tickcount" @change="change">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3 mt-2"
              v-if="
                TicketsResult[selected] &&
                donationOptions[TicketsResult[selected].ticketCode]
              "
            >
              <!-- test -->
              <label class="mt-2">Select Donation Type</label>
              <select class="form-control" v-model="donationPid">
                <option
                  class="form-control"
                  v-bind:key="idx + 'donation'"
                  v-for="(count, idx) in donationOptions[
                    TicketsResult[selected].ticketCode
                  ]"
                  :value="count.priceId"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>
            <!-- test -->

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                (TicketsResult[selected].discount ||
                  optionsSpeaker.includes(TicketsResult[selected].ticketCode))
              "
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                optionsNew.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="mt-3">Name of Institution</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Name of Institution"
                required
                v-model="nameOfInstitution"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                students.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="mt-3">Advisor</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Advisor"
                required
                v-model="advisor"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                optionsSpeaker.includes(TicketsResult[selected].ticketCode)
              "
            >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Name of Affiliation"
                required
                v-model="affiliation"
              />
            </div>

            <hr
              class="w-100 p-2"
              v-if="students.includes(TicketsResult[selected].ticketCode)"
            />
            <div
              class="row m-auto pb-4"
              v-bind:key="'name2' + indval"
              v-for="indval in totalCount(studentlist)"
            >
              <div :class="isstd ? 'col-md-3' : 'col-md-4'">
                <label class="mt-3">{{
                  TicketsResult[selected].ticketCode != "tk_6NlgPngUHXpQASh" &&
                  TicketsResult[selected].ticketCode != "tk_AHkBb0SWk6MiiX1"
                    ? "Student " + (indval + 1) + " Name"
                    : "Faculty " + (indval + 1) + " Name"
                }}</label>
                <input
                  type="text"
                  v-bind:key="'name12' + indval"
                  v-model="studentlist['name' + indval]"
                  class="form-control"
                  @keyup="vchange = !vchange"
                />
              </div>

              <div :class="isstd ? 'col-md-3' : 'col-md-4'">
                <label class="mt-3">{{
                  TicketsResult[selected].ticketCode != "tk_6NlgPngUHXpQASh" &&
                  TicketsResult[selected].ticketCode != "tk_AHkBb0SWk6MiiX1"
                    ? "Student " + (indval + 1) + " Email"
                    : "Faculty " + (indval + 1) + " Email"
                }}</label>
                <input
                  type="text"
                  v-bind:key="'emailtest' + indval"
                  v-model="studentlist['email' + indval]"
                  class="form-control"
                  @keyup="vchange = !vchange"
                />
              </div>

              <div :class="isstd ? 'col-md-3' : 'col-md-4'">
                <label class="mt-3">{{
                  TicketsResult[selected].ticketCode != "tk_6NlgPngUHXpQASh" &&
                  TicketsResult[selected].ticketCode != "tk_AHkBb0SWk6MiiX1"
                    ? "Student " + (indval + 1) + " T-Shirt Size"
                    : "Faculty " + (indval + 1) + " T-Shirt Size"
                }}</label>
                <select
                  class="form-control"
                  v-bind:key="'tshirt' + indval"
                  v-model="studentlist['tshirt' + indval]"
                  @change="vchange = !vchange"
                >
                  <option
                    class="form-control"
                    v-bind:key="count + 'b'"
                    v-for="count in sizes"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>

              <div v-if="isstd" :class="isstd ? 'col-md-3' : 'col-md-4'">
                <label class="mt-3">Chosen participation</label>
                <select
                  class="form-control"
                  v-bind:key="'participation' + indval"
                  v-model="studentlist['part' + indval]"
                  @change="vchange = !vchange"
                >
                  <option
                    class="form-control"
                    v-bind:key="count + 'b1'"
                    v-for="count in partList"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0"
              v-if="option1Tickets.includes(TicketsResult[selected].ticketCode)"
            >
              <br />
              <hr class="w-100 p-2" />
              <br />
              <div class="col-md-6">
                <label class="mt-2">Institution Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Institution Name"
                  v-model="iname"
                />
              </div>
              <div class="col-md-6">
                <label class="mt-2">{{
                  allowTextField[TicketsResult[selected].ticketCode]
                    ? "Primary Contact Name"
                    : labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  v-if="!allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  :placeholder="
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="cname"
                ></textarea>
                <input
                  type="text"
                  v-if="allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  placeholder="Primary Contact Name"
                  v-model="cname"
                />
              </div>
              <div
                class="col-md-6"
                v-if="
                  TicketsResult[selected].ticketCode != 'tk_vxGMmLyrqzHfeJW'
                "
              >
                <label class="mt-2">{{
                  emailByLabel[TicketsResult[selected].ticketCode] ||
                  labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  class="form-control mb-3"
                  :placeholder="
                    emailByLabel[TicketsResult[selected].ticketCode] ||
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="emaillist"
                ></textarea>
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0"
              v-if="option2Tickets.includes(TicketsResult[selected].ticketCode)"
            >
              <br />
              <hr class="w-100 p-2" />
              <br />
              <div class="col-md-6">
                <label class="mt-2">
                  {{
                    TicketsResult[selected].ticketCode == "tk_ZnG2CFRtGKhTvTJ"
                      ? "Name of Institution"
                      : "Name of Company"
                  }}</label
                >
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Name of Company"
                  v-model="iname"
                />
              </div>
              <div class="col-md-6">
                <label class="mt-2">{{
                  allowTextField[TicketsResult[selected].ticketCode]
                    ? "Primary Contact Name"
                    : labelByContactUs[TicketsResult[selected].ticketCode]
                }}</label>
                <textarea
                  v-if="!allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  :placeholder="
                    labelByContactUsPlaceholder[
                      TicketsResult[selected].ticketCode
                    ]
                  "
                  v-model="cname"
                ></textarea>
                <input
                  type="text"
                  v-if="allowTextField[TicketsResult[selected].ticketCode]"
                  class="form-control mb-3"
                  placeholder="Primary Contact Name"
                  v-model="cname"
                />
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (option1Tickets.includes(
                    TicketsResult[selected].ticketCode
                  ) &&
                  TicketsResult[selected].ticketCode != 'tk_vxGMmLyrqzHfeJW'
                    ? !listOfEmialsValid
                    : false) ||
                  (option1Tickets.includes(TicketsResult[selected].ticketCode)
                    ? !cname.trim().length || !iname.trim().length
                    : false) ||
                  (option2Tickets.includes(TicketsResult[selected].ticketCode)
                    ? !cname.trim().length || !iname.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  (optionsNew.includes(TicketsResult[selected].ticketCode)
                    ? !nameOfInstitution.trim().length
                    : false) ||
                  (students.includes(TicketsResult[selected].ticketCode)
                    ? !advisor.trim().length
                    : false) ||
                  (optionsSpeaker.includes(TicketsResult[selected].ticketCode)
                    ? !affiliation.trim().length
                    : false) ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  //   !this.reservedMeal.trim().length ||
                  (donationOptions[TicketsResult[selected].ticketCode]
                    ? !donationPid
                    : false) ||
                  (students.includes(TicketsResult[selected].ticketCode)
                    ? !isvalidData
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/NAAAHP_HEADER.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                32nd Annual NAAAHP Conference
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p class="lead-heading text-center">
              The 32nd Annual Conference of National Association of African
              American Honors Programs (NAAAHP)
            </p>

            <p class="lead-heading text-center" style="font-size: 20px">
              November 1- 4, 2023 @ Noon<br />

              <a
                href="https://maps.app.goo.gl/9PhHm4YxFxzp58jn7"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hilton Baton Rouge Capitol Center
              </a>
            </p>
          </div>
          <hr />
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center mb-2">
                    <h4
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 26px;
                      "
                    >
                      All sales are final.<br />
                      No refunds!!!
                    </h4>
                  </div>
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 32px;
                      "
                    >
                      Ticket Options
                    </h3>
                    <br />
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 28px;
                      "
                    >
                      (PAY BY CARD)
                    </h3>

                    <br />
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      Scroll down for Invoice Options
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in nonsponser(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode]
                            ? tkList[obj.ticketCode].messages
                            : []"
                        >
                          - {{ msgs }}
                        </p>
                        <!-- <p>
                                <b class="font-weight-bold">Price:</b>
                                ${{ obj.price }}
                              </p>
                              <p
                                v-bind:key="linek"
                                v-for="(line, linek) in obj.description"
                              >
                                {{ line }}
                              </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Purchase Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center mt-5">
                    <h3
                      class="w-auto d-inline-block mb-2 mt-4"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 32px;
                      "
                    >
                      PAY BY INVOICE
                    </h3>
                  </div>

                  <div
                    class="d-flex mt-2 flex-row flex-wrap justify-content-center"
                  >
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in byinvoices(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode]
                            ? tkList[obj.ticketCode].messages
                            : []"
                        >
                          - {{ msgs }}
                        </p>

                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Reserve Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center mt-5">
                    <h3
                      class="w-auto d-inline-block mb-2 mt-4"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 32px;
                      "
                    >
                      Sponsorships
                    </h3>
                  </div>

                  <div
                    class="d-flex mt-2 flex-row flex-wrap justify-content-center"
                  >
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in sponser(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode]
                            ? tkList[obj.ticketCode].messages
                            : []"
                        >
                          - {{ msgs }}
                        </p>
                        <!-- <p>
                                <b class="font-weight-bold">Price:</b>
                                ${{ obj.price }}
                              </p>
                              <p
                                v-bind:key="linek"
                                v-for="(line, linek) in obj.description"
                              >
                                {{ line }}
                              </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Purchase Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Theme: Belonging, Becoming and Being: The Elevation of the
                Academy and Humanity
                <br /><br />
                The Purpose of the National Association of African American
                Honors Programs:
                <br /><br />
                The objective of this Association shall be the promotion and
                advancement of honors and similar educational programs in
                Historically Black Colleges and Universities (HBCU), and in
                Predominately Black Colleges and Universities (PBCU), as well as
                institutions which provide honors education for African-
                American students.
                <br /><br />
                The specific goals of the Association include: <br />
                1. To develop, enhance, and support honors programs in all
                Historically/Predominately Black Institutions. <br />
                2. To stimulate and encourage community service and leadership
                as one of the high aims of education. <br />
                3. To sponsor and encourage scholarly research that address
                admission and retention of talented students, as well other
                challenges confronting honors education in Historically Black
                Colleges and Universities. <br />
                4. To advocate the funding of honors programs by federal and
                state agencies as well as by private philanthropic foundations
                and organizations. <br />
                5. To facilitate the enrollment of African-American students
                into graduate and professional schools. <br />
                6. To promote a curriculum that fosters a life-long disciplined
                approach to knowledge and scholarship through inquiry and
                exploration. <br />
                7. To develop an undergraduate educational environment that
                promotes scholarship, knowledge, and an appreciation of
                African-American culture as a mirror for understanding other
                great world cultures.<br />
                <br />
                The Conference will highlight a variety of competitive
                activities which include oratorical, poster and platform
                presentations on specified topics, Model African United Nation
                Solutions, Debates, Quiz Bowl and social responsibility
                competitions, as well as allow students the opportunity to
                receive monetary scholarship awards.
                <br /><br />
                This is an opportunity for like minded scholars to network share
                their knowledge with peers globally.
                <br /><br />
                Click the Buy Tickets button to Register or Donate Today!
                <br /><br />
                If you would like to pay by check or money order, please follow
                the instructions below and remit payment by October 28th, 2022.
                <br /><br />
                Donations and Sponsorships will be accepted through Nov. 13th.
                <br /><br />
                Send payment to the address listed below<br />
                National Association of African American Honors Programs <br />
                Attn: Deadra James Mackie, President. <br />
                P.O. Box 10046<br />
                Baton Rouge, LA 70813<br />
                Make checks payable to: National Association of African American
                Honors Programs<br /><br />
                If there are questions, please call 225-772-3401. <br /><br />
                Please provide the following information with your payment to
                ensure accurate mail/e-mail correspondence.<br />
                Name: Deadra James Mackie, NAAAHP President <br />
                Institution: Southern University and A&M College <br />
                Mailing address: Dolores Spikes Honors College, P.O. Box 10046,
                Baton Rouge, LA 70813 <br />
                Contact person: Deadra James Mackie <br />
                Phone: 225-772-3401<br />
                Email: Deadra.Mackie@sus.edu <br />
                <br />
                NAAAHP’s Federal Employer Identification Number is 46-114285
              </p>

              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                HOTEL RESERVATIONS
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://book.passkey.com/e/50552966"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hilton Baton Rouge Capitol Center</a
                >
                <br />
                201 Lafayette St.

                <br />
                Baton Rouge, LA
              </p>
            </div>

            <!-- <div
                    class="row w-100 m-0 mt-5 text-center promo-section MYLE-green"
                  >
                    <div class="col-lg-12 col-md-12">
                      <div class="mt-3">
                        <div class="section-heading mb-3">
                          <h5 class="text-center text-white">NupeKoming</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <video-embed
                    css="embed-responsive-16by9"
                    src="https://www.youtube.com/watch?v=hDHke06JMeE&ab_channel=KASKMERE11"
                  ></video-embed> -->
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- <hr />
                  <section class="download-section ptb-50 background-img bg-white">
                    <div class="container">
                      <div class="row justify-content-between">
                        <div
                          class="col-md-4 d-flex align-items-center justify-content-center"
                        >
                          <div class="download-img">
                            <img
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/STADIUM_LOGO.png"
                              alt="download"
                              class="img-fluid mobImg"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="download-content">
                            <h3 class="ml-5">Bottle Service</h3>

                            <ul class="lead ml-5">
                              <li>Endeavor Rum</li>
                              <li>Endeavor Gin</li>
                              <li>Endeavor Vodka</li>
                              <li>Bulleit Bourbon</li>
                              <li>Crown Royal Bourbon</li>
                              <li>Espolon Blanco</li>
                              <li>Espolon Reposado</li>
                            </ul>
                            <ul class="lead ml-5 mt-3">
                              <li>Includes Mixers</li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="download-content">
                            <h3 class="ml-5">Menu</h3>

                            <ul class="lead ml-5">
                              <li>Caesar Salad</li>
                              <li>Side Salad</li>
                            </ul>
                            <ul class="lead ml-5 mt-2">
                              <li>OMG CAULIFLOWER</li>
                              <li>Mozzarella Sticks</li>
                              <li>Street Corn</li>
                              <li>Creekside Fries</li>
                              <li>NBA JAM</li>
                              <li>The Greatest</li>
                              <li>Chicken n Waffle</li>
                            </ul>
                            <ul class="lead ml-5 mt-2">
                              <li>Boneless Wings</li>
                              <li>Traditional Wings</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <hr /> -->

      <!-- <div class="container mt-5">
                <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
                  <div class="col-lg-12 col-md-12">
                    <div class="mt-3">
                      <div class="section-heading mb-3">
                        <h5 class="text-center text-white">NupeKoming</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <video-embed
                  css="embed-responsive-16by9"
                  src="https://www.youtube.com/shorts/hDHke06JMeE"
                ></video-embed>
              </div> -->

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";
import confirmationPopup from "../../components/confirmationPopup";
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      showsuccess: false,
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        // "4 Extra Large - 4XL",
        // "5 Extra Large - 5XL",
      ],
      vchange: false,
      byinvoice: [
        "tk_AHkBb0SWk6MiiX1",
        "tk_4oE5yxXIKkDjlph",
        "tk_GNpuIsIYOgjlzFx",
      ],
      students: [
        "tk_KmLYM45Zp4U2TiJ",
        "tk_ty674yKkNF6fOrj",
        "tk_6NlgPngUHXpQASh",
        "tk_GNpuIsIYOgjlzFx",
        "tk_4oE5yxXIKkDjlph",
        "tk_AHkBb0SWk6MiiX1",
        "tk_8TARrHrJxjw2nMg",
      ],
      studentlist: {
        name0: "",
        email0: "",
        tshirt0: "",
        part0: "",
      },
      partList: [
        "None",
        "Talent",
        "Platform Presenter",
        "Poster Presenter",
        "Model UN",
        "Quiz Bowl",
        "Debate",
        "Other",
      ],
      sponsersList: [
        "tk_15sJxUIKTbs3DTC",
        "tk_Uqmc785788zeDOI",
        "tk_D1ErzBtTSrU6w5J",
        "tk_h52GRDkRLFwEnEX",
        "tk_NuLi7i9mebU84ZR",
      ],
      nameOfInstitution: "",
      advisor: "",
      optionsSpeaker: ["tk_FkQaZlj1OrENdMN"],
      affiliation: "",
      optionsNew: [
        "tk_RUaKQyEnDilTqdg",
        // "tk_2k3HugGKG70xziq",
        "tk_CTUU7Lh4WgjKjme",
        "tk_ROFuXueQZiEqJUy",
        "tk_HiD81F8JO94M1FO",
        "tk_KmLYM45Zp4U2TiJ",
        "tk_ty674yKkNF6fOrj",
        "tk_6NlgPngUHXpQASh",
        "tk_GNpuIsIYOgjlzFx",
        "tk_4oE5yxXIKkDjlph",
        "tk_AHkBb0SWk6MiiX1",
      ],
      emaillist: "",
      labelbytag: {
        tk_vxGMmLyrqzHfeJW: "Per Member Price",
      },
      emailByLabel: {
        tk_Zz22IlJECxUJxIy: "Name(s) / Email Address(es)",
        tk_yCvq4I1ConAwFAZ: "Name(s) / Email Address(es)",
      },
      emailByLabelPlaceholder: {
        tk_Zz22IlJECxUJxIy:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",

        tk_yCvq4I1ConAwFAZ:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
      },
      allowTextField: {
        tk_vxGMmLyrqzHfeJW: true,
        tk_OaxszhomSDxidDV: true,
        tk_VJ6ZO7ZyXdAeTQf: true,
        tk_dNTjtNVelWNoxi9: true,
        tk_OXiBerVQCYic7mB: true,
        tk_Zz22IlJECxUJxIy: true,
        tk_yCvq4I1ConAwFAZ: true,
        tk_QlNaa6V4ExQ3XzC: true,
        // tk_ruS5HPgk5nx3gal: true,
        tk_ZnG2CFRtGKhTvTJ: true,
      },
      labelByContactUs: {
        tk_vxGMmLyrqzHfeJW: "Primary Contact Name",
        tk_OaxszhomSDxidDV: "Student Name(s) / Email Address(es)",
        tk_VJ6ZO7ZyXdAeTQf: "Student Name(s) / Email Address(es)",
        tk_dNTjtNVelWNoxi9: "Faculty / Staff Name(s) / Email Address(es)",
        tk_OXiBerVQCYic7mB: "Faculty / Staff Name(s) / Email Address(es)",
        tk_Zz22IlJECxUJxIy: "Primary Contact Name",
        tk_yCvq4I1ConAwFAZ: "Primary Contact Name",
        tk_QlNaa6V4ExQ3XzC: "Primary Contact Name",
        // tk_ruS5HPgk5nx3gal: "Primary Contact Name",
        tk_ZnG2CFRtGKhTvTJ: "Primary Contact Name",
      },
      labelByContactUsPlaceholder: {
        tk_vxGMmLyrqzHfeJW: "Primary Contact Name",
        tk_OaxszhomSDxidDV:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_VJ6ZO7ZyXdAeTQf:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_dNTjtNVelWNoxi9:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_OXiBerVQCYic7mB:
          "Use comma to separate attendees Name(s), Email Address(es) John Johnson, john.johnson@email.com",
        tk_Zz22IlJECxUJxIy: "Primary Contact Name",
        tk_yCvq4I1ConAwFAZ: "Primary Contact Name",
        tk_QlNaa6V4ExQ3XzC: "Primary Contact Name",
        // tk_ruS5HPgk5nx3gal: "Primary Contact Name",
        tk_ZnG2CFRtGKhTvTJ: "Primary Contact Name",
      },
      labelByName: {
        tk_vxGMmLyrqzHfeJW: "Number of Memberships",
        tk_OaxszhomSDxidDV: "Number of Students",
        tk_VJ6ZO7ZyXdAeTQf: "Number of Students",
        tk_dNTjtNVelWNoxi9: "Number of Faculty/Staff",
        tk_OXiBerVQCYic7mB: "Number of Faculty/Staff",
      },
      iname: "",
      cname: "",
      option1Tickets: [
        "tk_vxGMmLyrqzHfeJW",
        "tk_OaxszhomSDxidDV",
        "tk_VJ6ZO7ZyXdAeTQf",
        "tk_dNTjtNVelWNoxi9",
        "tk_OXiBerVQCYic7mB",
        "tk_Zz22IlJECxUJxIy",
        "tk_yCvq4I1ConAwFAZ",
      ],
      option2Tickets: [
        "tk_QlNaa6V4ExQ3XzC",
        // "tk_ruS5HPgk5nx3gal",
        "tk_ZnG2CFRtGKhTvTJ",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reservedMeal: "",
      donationPid: "",
      donationOptions: {
        tk_vD7aQFuhNKVw8Bm: [
          {
            priceId: "price_1LeMYND0vTZ4QB9cBJJQRn5u",
            name: "Gold - $500",
          },
          {
            priceId: "price_1LeMYND0vTZ4QB9cKuD37llT",
            name: "Silver - $250",
          },
          {
            priceId: "price_1LeMYND0vTZ4QB9cTxgdAmpS",
            name: "Bronze - $100",
          },
        ],
      },
      reserveFormAccept: false,
      tkList: {
        tk_KmLYM45Zp4U2TiJ: {
          messages: ["Ticket Price: $250"],
        },

        tk_ty674yKkNF6fOrj: {
          messages: ["Ticket Price: $250"],
        },

        tk_6NlgPngUHXpQASh: {
          messages: ["Ticket Price: $300"],
        },
        tk_GNpuIsIYOgjlzFx: {
          messages: ["Ticket Price: $250"],
        },
        tk_4oE5yxXIKkDjlph: {
          messages: ["Ticket Price: $250"],
        },
        tk_AHkBb0SWk6MiiX1: {
          messages: ["Ticket Price: $300"],
        },
        tk_8TARrHrJxjw2nMg: {
          messages: ["Ticket Price: $50"],
        },

        tk_RUaKQyEnDilTqdg: {
          messages: ["Ticket Price: $200"],
        },

        // tk_2k3HugGKG70xziq: {
        //   messages: ["Ending on October 15, 2023 ", "Ticket Price: $699"],
        // },

        tk_CTUU7Lh4WgjKjme: {
          messages: ["Ticket Price: $899"],
        },

        tk_ROFuXueQZiEqJUy: {
          messages: ["Ticket Price: $400"],
        },

        tk_HiD81F8JO94M1FO: {
          messages: ["Ticket Price: $250"],
        },

        tk_FkQaZlj1OrENdMN: {
          messages: ["Ticket Price: $250"],
        },

        tk_NuLi7i9mebU84ZR: {
          messages: ["Ticket Price: $10000"],
        },

        tk_h52GRDkRLFwEnEX: {
          messages: ["Ticket Price: $15000"],
        },

        tk_D1ErzBtTSrU6w5J: {
          messages: ["Ticket Price: $20000"],
        },

        tk_Uqmc785788zeDOI: {
          messages: ["Ticket Price: $30000"],
        },

        tk_15sJxUIKTbs3DTC: {
          messages: ["Ticket Price: $50000"],
        },
      },

      priceIds: {
        tk_KmLYM45Zp4U2TiJ: "price_1NxUrSD0vTZ4QB9cLXRUxGdQ",
        tk_ty674yKkNF6fOrj: "price_1NyDRRD0vTZ4QB9c2gfueTEy",
        tk_6NlgPngUHXpQASh: "price_1NxUrWD0vTZ4QB9cRnrVtLdk",
        tk_GNpuIsIYOgjlzFx: "price_1NyDTfD0vTZ4QB9cCdnRgGF2",
        tk_4oE5yxXIKkDjlph: "price_1NyDTgD0vTZ4QB9cH7qNobKX",
        tk_AHkBb0SWk6MiiX1: "price_1NyDTgD0vTZ4QB9cMS80CUd2",
        tk_8TARrHrJxjw2nMg: "price_1NxUpeD0vTZ4QB9chdJU4Xr2",
        tk_RUaKQyEnDilTqdg: "price_1NxUptD0vTZ4QB9cVCvmTXLr",
        // tk_2k3HugGKG70xziq: "price_1NxUqwD0vTZ4QB9cG5txF7rG",
        tk_CTUU7Lh4WgjKjme: "price_1NxUq2D0vTZ4QB9cxjRMb2SM",
        tk_ROFuXueQZiEqJUy: "price_1NxUqHD0vTZ4QB9cT067TMMI",
        tk_HiD81F8JO94M1FO: "price_1NxV3ZD0vTZ4QB9c10n66kcm",
        tk_FkQaZlj1OrENdMN: "price_1NxV53D0vTZ4QB9cQxMMAFbI",
        tk_NuLi7i9mebU84ZR: "price_1NxUr6D0vTZ4QB9cW0eeBUnE",
        tk_h52GRDkRLFwEnEX: "price_1NxUpyD0vTZ4QB9cL0zGm1YQ",
        tk_D1ErzBtTSrU6w5J: "price_1NxUrMD0vTZ4QB9cmrI0INbY",
        tk_Uqmc785788zeDOI: "price_1NxUrHD0vTZ4QB9cKP7PgQdk",
        tk_15sJxUIKTbs3DTC: "price_1NxUrDD0vTZ4QB9cZ2gYDnkk",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        mealName: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_12.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_13.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_14.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_15.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_16.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_17.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_18.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_19.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_21.jpg",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    totalCount(list) {
      if (
        this.TicketsResult[this.selected] &&
        this.students.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        const tmp = Object.keys(list).filter((e) => e.includes("name")).length;
        return tmp > 0 ? [...Array(tmp).keys()] : [];
      }

      return [];
    },
    countChange() {
      const tmp = { ...this.studentlist };
      this.studentlist = {};

      if (this.tickcount > 0) {
        for (let i = 0; i < this.tickcount; i++) {
          this.studentlist["name" + i] = tmp["name" + i] || "";
          this.studentlist["email" + i] = tmp["email" + i] || "";
          this.studentlist["tshirt" + i] =
            tmp["tshirt" + i] || "Extra Small - XS";
          if (
            this.TicketsResult[this.selected].ticketCode !=
              "tk_6NlgPngUHXpQASh" &&
            this.TicketsResult[this.selected].ticketCode != "tk_AHkBb0SWk6MiiX1"
          ) {
            this.studentlist["part" + i] = tmp["part" + i] || "None";
          }
        }
      }

      this.vchange = false;
    },
    sponser(res) {
      return res.filter((e) => this.sponsersList.includes(e.ticketCode));
    },
    byinvoices(res) {
      return res.filter((e) => this.byinvoice.includes(e.ticketCode));
    },
    nonsponser(res) {
      return res.filter(
        (e) =>
          !this.sponsersList.includes(e.ticketCode) &&
          !this.byinvoice.includes(e.ticketCode)
      );
    },
    change: function (e) {
      if (e.target.value && Number(e.target.value) == 1) {
        this.redeemData = null;
        this.redeemPriceId = "";
        this.donationPid = null;
        this.redeempromo = "";
      }

      this.countChange(e);
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].availableTickets;
          const apr =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.ticketForm.ticketCount = 1;
      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (code) {
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";
      this.donationPid = null;
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.nameOfInstitution = "";
      this.advisor = "";
      this.affiliation = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";
      this.iname = "";
      this.cname = "";
      this.emaillist = "";
      this.studentlist = {
        name0: "",
        email0: "",
        tshirt0: "Extra Small - XS",
        part0: "None",
      };

      if (
        this.TicketsResult[this.selected].ticketCode == "tk_6NlgPngUHXpQASh" ||
        this.TicketsResult[this.selected].ticketCode == "tk_AHkBb0SWk6MiiX1"
      ) {
        delete this.studentlist["part0"];
      }

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          (this.TicketsResult[vm.selected].discount ||
            this.optionsSpeaker.includes(
              this.TicketsResult[this.selected].ticketCode
            )) &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (this.optionsNew.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.institution = this.nameOfInstitution;
      }

      if (vm.students.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        const nlist = {};
        const elist = {};
        const tlist = {};
        const plist = {};
        Object.keys(this.studentlist).forEach((e) => {
          if (
            vm.TicketsResult[vm.selected].ticketCode == "tk_KmLYM45Zp4U2TiJ" ||
            vm.TicketsResult[vm.selected].ticketCode == "tk_ty674yKkNF6fOrj" ||
            vm.TicketsResult[vm.selected].ticketCode == "tk_GNpuIsIYOgjlzFx" ||
            vm.TicketsResult[vm.selected].ticketCode == "tk_4oE5yxXIKkDjlph"
          ) {
            if (e.includes("name")) {
              nlist["Student_" + (Object.keys(nlist).length + 1) + "_name"] =
                this.studentlist[e];
            } else if (e.includes("email")) {
              elist["Student_" + (Object.keys(elist).length + 1) + "_email"] =
                this.studentlist[e].toLowerCase();
            } else if (e.includes("tshirt")) {
              tlist["Student_" + (Object.keys(tlist).length + 1) + "_tshirt"] =
                this.studentlist[e];
            } else if (e.includes("part")) {
              plist[
                "Student_" + (Object.keys(plist).length + 1) + "_participation"
              ] = this.studentlist[e];
            }
          }

          if (
            vm.TicketsResult[vm.selected].ticketCode == "tk_6NlgPngUHXpQASh" ||
            vm.TicketsResult[vm.selected].ticketCode == "tk_AHkBb0SWk6MiiX1"
          ) {
            if (e.includes("name")) {
              nlist["Faculty_" + (Object.keys(nlist).length + 1) + "_name"] =
                this.studentlist[e];
            } else if (e.includes("email")) {
              elist["Faculty_" + (Object.keys(elist).length + 1) + "_email"] =
                this.studentlist[e].toLowerCase();
            } else if (e.includes("tshirt")) {
              tlist["Faculty_" + (Object.keys(tlist).length + 1) + "_tshirt"] =
                this.studentlist[e];
            }
          }
        });

        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.advisor = this.advisor;
        payloadToPush.extraInfo = {
          ...payloadToPush.extraInfo,
          ...nlist,
          ...elist,
          ...tlist,
          ...plist,
        };
      }

      if (
        this.optionsSpeaker.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.affiliation = this.affiliation;
      }

      if (vm.donationOptions[vm.TicketsResult[vm.selected].ticketCode]) {
        payloadToPush.priceId = vm.donationPid;
      }

      if (
        this.option1Tickets.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.institute_name = this.iname;
        payloadToPush.extraInfo.contact_name = this.cname;
      }

      if (
        this.option2Tickets.includes(vm.TicketsResult[vm.selected].ticketCode)
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.vendor_company = this.iname;
        payloadToPush.extraInfo.vendor_contact_name = this.cname;
      }

      if (
        this.option1Tickets.includes(
          vm.TicketsResult[vm.selected].ticketCode
        ) &&
        vm.TicketsResult[vm.selected].ticketCode != "tk_vxGMmLyrqzHfeJW"
      ) {
        if (!payloadToPush.extraInfo) {
          payloadToPush.extraInfo = {};
        }
        payloadToPush.extraInfo.contact_email = this.emaillist;
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            //  vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.nameOfInstitution = "";
            vm.advisor = "";
            vm.affiliation = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;

            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.nameOfInstitution = "";
            vm.advisor = "";
            vm.affiliation = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_2nd_NAAAHP_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_KmLYM45Zp4U2TiJ: null,
            tk_ty674yKkNF6fOrj: null,
            tk_6NlgPngUHXpQASh: null,
            tk_GNpuIsIYOgjlzFx: null,
            tk_4oE5yxXIKkDjlph: null,
            tk_AHkBb0SWk6MiiX1: null,
            tk_8TARrHrJxjw2nMg: null,
            tk_RUaKQyEnDilTqdg: null,
            // tk_2k3HugGKG70xziq: null,
            tk_CTUU7Lh4WgjKjme: null,
            tk_ROFuXueQZiEqJUy: null,
            tk_HiD81F8JO94M1FO: null,
            tk_FkQaZlj1OrENdMN: null,
            tk_NuLi7i9mebU84ZR: null,
            tk_h52GRDkRLFwEnEX: null,
            tk_D1ErzBtTSrU6w5J: null,
            tk_Uqmc785788zeDOI: null,
            tk_15sJxUIKTbs3DTC: null,
          };

          vm.TicketsResult.forEach((tr) => {
            if (tr.ticketCode in objtmp) {
              objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
            }
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    isstd() {
      return (
        this.TicketsResult[this.selected].ticketCode != "tk_6NlgPngUHXpQASh" &&
        this.TicketsResult[this.selected].ticketCode != "tk_AHkBb0SWk6MiiX1"
      );
    },
    isvalidData() {
      let isValid = true;
      console.log("dsds");
      Object.keys(this.studentlist).forEach((e) => {
        if (!e.includes("email") && this.studentlist[e].trim().length < 1) {
          isValid = false;
        } else if (e.includes("email")) {
          const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!re.test(this.studentlist[e].trim())) {
            isValid = false;
          }
        }
      });

      return (this.vchange || !this.vchange) && isValid;
    },
    listOfEmialsValid() {
      return this.emaillist.trim().split(",").join("").length;
      /*
        const arr = this.emaillist.trim().split(',');
        let valid = true;
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

        if (this.emaillist.length <= 0) {
          valid = false
        }

        arr.forEach(em => {
          if (!re.test(em.trim())) {
            valid = false
          }
        })

        return valid */
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 32nd Annual NAAAHP Conference",

    meta: [
      {
        name: "description",
        content: "MYLE - 32nd Annual NAAAHP Conference",
      },

      {
        name: "keywords",
        content: "MYLE - 32nd Annual NAAAHP Conference",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/32nd-annual-naaahp-conference",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

textarea {
  resize: none;
}
</style>
