<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <!-- <div class="row">
          <div class="col-md-12">
            <nav-bar />
          </div>
        </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TuskegeeUniversityHomecomingCollection_V2.jpg"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                font-size: 2.4rem;
                color: #a32035;
              "
            >
              Tuskegee University 100th Homecoming <br />
              Shows
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            06 <br />
            November
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://web.myle.com/eid/w21xdgIdoaX4rC" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Tuskegee University Homecoming Concert Starring GloRilla & Veeze
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Wednesday, November 06, 2024 <br />
              07:00 PM - 11:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://web.myle.com/eid/w21xdgIdoaX4rC"
                  class="btn solid-btn-tuskegee mb-1 text-center"
                  target="_self"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.1em;
                    font-weight: bold;
                  "
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://web.myle.com/eid/w21xdgIdoaX4rC" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TuskegeeUniversityHomecomingConcert_V1.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            09 <br />
            November
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://web.myle.com/eid/yM1Do3KiH7w0te" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Tuskegee University Homecoming Stepshow
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              Saturday, November 09, 2024<br />
              06:30 PM - 10:30 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://web.myle.com/eid/yM1Do3KiH7w0te"
                  class="btn solid-btn-tuskegee mb-1 text-center"
                  target="_self"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.1em;
                    font-weight: bold;
                  "
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://web.myle.com/eid/yM1Do3KiH7w0te" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TuskegeeUniversityHomecomingStepshow_V1.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br /><br /><br />
    </div>

    <div class="row w-100 promo-section m-0">
      <div class="col-lg-12 col-md-12">
        <div class="">
          <div class="section-heading">
            <h2
              class="text-center"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    // NavBar,
    // SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Tuskegee University 100th Homecoming Shows",

    meta: [
      {
        name: "description",
        content: "MYLE - Tuskegee University 100th Homecoming Shows",
      },

      {
        name: "keywords",
        content: "MYLE - Tuskegee University 100th Homecoming Shows",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/tuskegee-university-100th-homecoming-shows",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
