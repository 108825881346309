<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_pregame_collection.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 40px;
              "
            >
              The Official OSU vs. UGA<br />
              CFP Pre-Game Parties
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <a
            href="https://myle.com/the-official-osu-vs-uga-pre-game-day-party"
            target="_self"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/pregame_Day_Party.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>

            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              The Official OSU vs. UGA Pre-Game "Day Party"
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 16px;
              "
            >
              December, 30th 2022, 02:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/the-official-osu-vs-uga-pre-game-day-party"
                  class="btn solid-btn mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>
        <div
          class="col-md-6 col-lg-6 justify-content-center align-items-center"
        >
          <a
            href="https://myle.com/the-official-osu-vs-uga-pre-game-night-party"
            target="_self"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/pregame_night_Party.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>

            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              The Official OSU vs. UGA Pre-Game "Night Party"
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 16px;
              "
            >
              December, 30th 2022, 10:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/the-official-osu-vs-uga-pre-game-night-party"
                  class="btn solid-btn mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>

      <!-- <div class="row">
            <div class="col-md-6 col-lg-6 align-items-center">
              <a
                href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                target="_blank" rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>
    
                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  For The Kings | Juneteenth + Father's Day Brunch
                </p>
                <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Sun, Jun 19, 12:00 PM
                </p>
              </a>
            </div>
          </div> -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - The Official OSU vs. UGA Pre-Game Party",

    meta: [
      {
        name: "description",
        content: "The Official OSU vs. UGA Pre-Game Party",
      },

      {
        name: "keywords",
        content: "The Official OSU vs. UGA Pre-Game Party",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/the-official-osu-vs-uga-pre-game-party",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
