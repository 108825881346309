<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3 class="w-auto d-inline-block">
                {{
                  $route.query.checkout == "success"
                    ? "Successful Purchased"
                    : "Oops!"
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              {{
                $route.query.checkout == "success"
                  ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                  : "Something went wrong with the payment. Please try again."
              }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <br />
              <button
                type="button"
                @click="$refs.confirmmodal.close()"
                class="btn solid-btn my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  width: 150px;
                  margin-top: 11px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Ticket Purchase Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Purchaser Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">{{
                [...section2].includes(TicketsResult[selected].ticketCode)
                  ? "Number of Sponsorship(s)"
                  : "Number of Ticket(s)"
              }}</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                        <div
                          class="row col-md-12 mt-4"
                          v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                        >
                          <div class="col-md-6">
                            <label class="">Do you have a promo code?</label>

                            <input
                              type="text"
                              v-model="redeempromo"
                              class="form-control"
                              placeholder="Promo code"
                            />
                          </div>

                          <div class="col-md-4">
                            <button
                              type="button"
                              @click="redeem()"
                              :disabled="!redeempromo.trim().length"
                              class="btn solid-btn btn-sm my-auto"
                              id="reserve"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                padding: 7px;
                                margin-top: 11px !important;
                              "
                            >
                              Redeem
                            </button>
                          </div>
                        </div> -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && !section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efName"
                  class="form-control"
                  placeholder="Primary Contact First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="elName"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eEmail"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="emNumber"
                  class="form-control"
                  placeholder="Mobile Number"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eTitle"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eRefferedBy"
                  class="form-control"
                  placeholder="Referred By: (if applicable)"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false &&
                [...section2].includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="business_organization_name"
                  class="form-control"
                  placeholder="Business / Organization"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efatherName"
                  class="form-control"
                  placeholder="Father/Father Figure First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efEmail"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div
                class="col-md-6 pb-4"
                v-bind:key="'xxx' + indval"
                v-for="indval in agecount[TicketsResult[selected].ticketCode]"
              >
                <input
                  type="number"
                  min="1"
                  max="50"
                  v-model="age['age' + indval]"
                  class="form-control"
                  :placeholder="
                    agecount[TicketsResult[selected].ticketCode].length == 1
                      ? 'Child Age'
                      : agePlaceholders[indval - 1]
                  "
                  @keyup="vchange = !vchange"
                />
              </div>
            </div>
            <hr class="w-100 p-2" />
            <div class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4">
              <div class="col-md-6 mb-2">
                <label class="mt-2">Contact Name *</label>
                <input
                  type="text"
                  v-model="nContactName"
                  class="form-control"
                  placeholder="Contact Name"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Email Address *</label>
                <input
                  type="text"
                  v-model="nEmailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="mt-2">Street Address *</label>
                <input
                  type="text"
                  v-model="nStreetAddress"
                  class="form-control"
                  placeholder="Street Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">City *</label>
                <input
                  type="text"
                  v-model="nCity"
                  class="form-control"
                  placeholder="City"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Select State *</label>
                <select
                  placeholder="State"
                  class="form-control"
                  v-model="nState"
                >
                  <option
                    v-for="s in states"
                    v-bind:key="s"
                    :value="s['States in USA']"
                  >
                    {{ s["States in USA"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Zip Code *</label>
                <input
                  type="text"
                  v-model="nZip"
                  class="form-control"
                  placeholder="Zip Code"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="mt-2">Select District *</label>
                <select
                  placeholder="District"
                  class="form-control"
                  v-model="nDistrict"
                >
                  <option
                    v-for="s in district"
                    v-bind:key="s"
                    :value="
                      s['Dr']['Janiece Jackson, Lindop School District 92']
                    "
                  >
                    {{ s["Dr"]["Janiece Jackson, Lindop School District 92"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mb-2"></div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  !nContactName.trim().length ||
                  !nStreetAddress.trim().length ||
                  !nCity.trim().length ||
                  !nState.trim().length ||
                  !nZip.trim().length ||
                  !nDistrict.trim().length ||
                  !nEmailAddress.trim().length ||
                  !checkInvalidEmail(nEmailAddress)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_InvestinginourFutureScholarshipGala.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                "Investing in our Future" Scholarship Gala
              </h1>

              <!-- <h
                        class="text-center text-white"
                        style="
                          font-family: 'Quicksand', sans-serif;
                          font-weight: 500;
                          font-size: 18px;
                        "
                      >
                        Saturday, November 19th
                      </h1> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h1
                      class="text-center"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 22px;
                        color: red;
                      "
                    >
                      The purchase of tickets and sponsorships will close on
                      Monday, Feb. 27th at 5pm CT.
                    </h1>
                    <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section1Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Section 2 -->
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">SPONSOR TICKETS</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section2Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                UNCF has partnered with the Superintendent Commission on the
                Study of Diversity and Demographics to provide scholarships to
                Commission high school seniors.
                <br /><br />
                The Superintendents' Commission is a premier educational
                organization composed of elementary and secondary
                superintendents and school districts of the Suburban Cook and
                Collar Counties dedicated to providing quality educational
                opportunities for all students.
                <br /><br />
                They believe that partnering with businesses and institutions of
                higher learning provides support for educational initiatives
                that are beneficial to the school community.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      nContactName: "",
      nEmailAddress: "",
      nStreetAddress: "",
      nState: "Alabama",
      nZip: "",
      nCity: "",
      nDistrict: "Dr. Michelle Smith, Berwyn School District 98",
      states: [
        { "States in USA": "Alabama" },
        { "States in USA": "Alaska" },
        { "States in USA": "Arizona" },
        { "States in USA": "Arkansas" },
        { "States in USA": "Califonria" },
        { "States in USA": "Colorado" },
        { "States in USA": "Connecticut" },
        { "States in USA": "Delaware" },
        { "States in USA": "Dictrict of Columbia" },
        { "States in USA": "Florida" },
        { "States in USA": "Georgia" },
        { "States in USA": "Hawaii" },
        { "States in USA": "Idaho" },
        { "States in USA": "Illinois" },
        { "States in USA": "Indiana" },
        { "States in USA": "Iowa" },
        { "States in USA": "Kansas" },
        { "States in USA": "Kentucky" },
        { "States in USA": "Louisiana" },
        { "States in USA": "Maine" },
        { "States in USA": "Maryland" },
        { "States in USA": "Massachusetts" },
        { "States in USA": "Michigan" },
        { "States in USA": "Minnesota" },
        { "States in USA": "Mississippi" },
        { "States in USA": "Missouri" },
        { "States in USA": "Montana" },
        { "States in USA": "Nebraska" },
        { "States in USA": "Nevada" },
        { "States in USA": "New Hampshire" },
        { "States in USA": "New Jersey" },
        { "States in USA": "New Mexico" },
        { "States in USA": "New York" },
        { "States in USA": "North Carolina" },
        { "States in USA": "North Dakota" },
        { "States in USA": "Ohio" },
        { "States in USA": "Oklahoma" },
        { "States in USA": "Oregon" },
        { "States in USA": "Pennsylvania" },
        { "States in USA": "Rhode Island" },
        { "States in USA": "South Carolina" },
        { "States in USA": "South Dakota" },
        { "States in USA": "Tennessee" },
        { "States in USA": "Texas" },
        { "States in USA": "Utah" },
        { "States in USA": "Vermont" },
        { "States in USA": "Virginia" },
        { "States in USA": "Washington" },
        { "States in USA": "West Virginia" },
        { "States in USA": "Wisconsin" },
        { "States in USA": "Wyoming" },
      ],
      district: [
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "No District Affiliation",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "ECHO Joint Agreement - Dr. Sandra Thomas, Echo",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "Southland COLLEGE PREP CHARTER School - Dr. Blondean Davis, Southland College Prep Charter",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "SPEED Joint Agreement - Dr. Tina Halliman, SPEED",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 92 - Dr. Janiece Jackson, Lindop",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 98 - Dr. Michelle Smith, Berwyn",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 111 - Dr. Genevra Walters, Kankakee",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 143.5 - Dr. Anthony Edison, Posen Robbins",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 144 - Dr. K. Patterson, Prairie Hills",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 147 - Dr. Jerry Jordan, Harvey Dixmoor",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 148 - Kevin Nohelty, Dolton Riverdale",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 149 - Dr. Maureen White, Dolton",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 150 - Michele Morris, South Holland",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 152 - Dr. Margaret Longo, Harvey",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 152.5 - Dr. Kenneth Spells, East Hazel Crest",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 156 - Dr. Anita Rice, Lincoln",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 157 - Dr. Dwayne Evans, Calument City",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 159 - Dr. Tiffany Brunson, Elementary",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 160 - Dr. Duane Meighan, Country Club Hills",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 162 - Dr. Blondean Davis, Matteson",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 163 - Dr. Caletha White, Park Forest",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 167 - Bethany Lindsay, Brookwood",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 168 - Dr. Donna Simpson, Leak Sauk Village",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 169 - Dr. Gregory Jackson, Ford Heights",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 171 - Dr. Erika Millhouse, Sunnybrook",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 172 - Dr. Kim Nalls, Sandridge",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 205 - Dr. Nathaniel Cunningham, Thornton HS",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 206 - Dr. Lenell Navarre, Bloom Township",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 209 - Dr. James L. Henderson, Proviso HS",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 215 - Dr. Sophia Jones-Redmond, Thornton Fractional HS",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 227 - Dr. Johnnie Thomas, Rich Township HS",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 228 - Dr. Brad Sakora, Breman HS",
          },
        },
        {
          Dr: {
            "Janiece Jackson, Lindop School District 92":
              "District 233 - Dr. Scott Wakeley, Homewood Flossmoor HS",
          },
        },
      ],
      agePlaceholders: [
        "First Child Age",
        "Second Child Age",
        "Third Child Age",
        "Fourth Child Age",
      ],
      vchange: true,
      validSection: true,
      efName: "",
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {
        tk_U1IGqa6dzMCTjln: [1],
        tk_eVzbCBqzSuFPfd5: [1, 2],
        tk_g19EFw7kOMyfuL8: [1, 2, 3],
        tk_rFyeu7irKUhlI1U: [1, 2, 3, 4],
      },
      section1: ["tk_vmLQZZ4y4BMnEFU"],
      section2: [
        "tk_ZkmAupdCSQ0HAh0",
        "tk_doPV1MtXA3gnsfL",
        "tk_b2p4mFyAT2LzHnz",
        "tk_e5X3T92J3tITEoD",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_vmLQZZ4y4BMnEFU: {
          messages: [
            "Ticket Price: $100.00",
            "General Admission to Scholarship Gala",
          ],
        },

        tk_ZkmAupdCSQ0HAh0: {
          messages: ["Ticket Price: $1000.00", "1 Reserved Table of 10"],
        },

        tk_doPV1MtXA3gnsfL: {
          messages: [
            "Ticket Price: $2500.00",
            "1 Reserved Table of 10",
            "Prominent Company Logo Listing",
          ],
        },

        tk_b2p4mFyAT2LzHnz: {
          messages: [
            "Ticket Price: $5000.00",
            "Recognition and Introduction",
            "1 Reserved Table of 10",
            "Prominent Company Logo Listing",
            "VIP Reception w/Superintendent Commission",
          ],
        },

        tk_e5X3T92J3tITEoD: {
          messages: [
            "Ticket Price: $10000.00",
            "Recognition and Introduction during program with live remarks",
            "1 Premium Reserved Table of 10",
            "Recognition at Gala",
            "Premier Company Logo Listing",
            "VIP Reception w/Superintendent Commission",
          ],
        },
      },

      priceIds: {
        tk_vmLQZZ4y4BMnEFU: "price_1MRo3tD0vTZ4QB9cJWZuSDsh",
        tk_ZkmAupdCSQ0HAh0: "price_1MSISCD0vTZ4QB9cxzK0rKCm",
        tk_doPV1MtXA3gnsfL: "price_1MSIRvD0vTZ4QB9cQv22roou",
        tk_b2p4mFyAT2LzHnz: "price_1MSIR7D0vTZ4QB9cHXZTWn6n",
        tk_e5X3T92J3tITEoD: "price_1MSIQHD0vTZ4QB9cjMV3kZx8",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },
  methods: {
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    section2Filter(list) {
      return list.filter((l) => this.section2.includes(l.ticketCode));
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (code) {
      this.validSection = true;
      this.fullname = "";
      this.email = "";
      this.efName = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.nContactName = "";
      this.nEmailAddress = "";
      this.nStreetAddress = "";
      this.nState = "Alabama";
      this.nZip = "";
      this.nCity = "";
      this.nDistrict = "No District Affiliation";

      if (code == "RZ44zOQ66InQgZ") {
        this.age["age1"] = "";
      } else if (code == "tk_eVzbCBqzSuFPfd5") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_g19EFw7kOMyfuL8") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_rFyeu7irKUhlI1U") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      if (
        [...this.section2].includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        payloadToPush.extraInfo = {
          /*  firstName: this.efName.trim(),
          lastName: this.elName.trim(),
          title: this.eTitle.trim(),
          RefferedBy: this.eRefferedBy.trim(),
          email: this.eEmail.toLowerCase(),
          phoneNumber: this.emNumber,
          business_organization_name: this.business_organization_name, */
          contact_name: this.nContactName,
          contact_email: this.nEmailAddress.toLowerCase(),
          address_street: this.nStreetAddress,
          address_city: this.nCity,
          address_state: this.nState,
          address_zip: this.nZip,
          address_district: this.nDistrict,
        };
      } else if (
        this.section1.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          contact_name: this.nContactName,
          contact_email: this.nEmailAddress.toLowerCase(),
          address_street: this.nStreetAddress,
          address_city: this.nCity,
          address_state: this.nState,
          address_zip: this.nZip,
          address_district: this.nDistrict,
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_investing_in_our_future_scholarship_gala_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_vmLQZZ4y4BMnEFU: null,
            tk_ZkmAupdCSQ0HAh0: null,
            tk_doPV1MtXA3gnsfL: null,
            tk_b2p4mFyAT2LzHnz: null,
            tk_e5X3T92J3tITEoD: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      // this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    checkSection() {
      if (
        (this.vchange || !this.vchange) &&
        [...this.section2].includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        return (
          this.efName.trim().length <= 0 ||
          this.elName.trim().length <= 0 ||
          this.eTitle.trim().length <= 0 ||
          this.business_organization_name.trim().length <= 0 ||
          !this.checkInvalidEmail(this.eEmail) ||
          !this.invalidNum(this.emNumber)
        );
      } else if (
        (this.vchange || !this.vchange) &&
        this.section1.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.efatherName.trim().length <= 0 ||
          this.efEmail.trim().length <= 0 ||
          this.ePhoneNumber.trim().length <= 0 ||
          Object.keys(this.age).filter((a) => this.age[a] <= 0).length > 0
        );
      }

      return false;
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Investing in our Future Scholarship Gala",

    meta: [
      {
        name: "description",
        content: "MYLE | Investing in our Future Scholarship Gala",
      },

      {
        name: "keywords",
        content: "MYLE | Investing in our Future Scholarship Gala",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/investing-in-our-future-scholarship-gala",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
