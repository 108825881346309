<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    title:
      "MYLE - Discover Popular Local Events, Things To Do, Places To Go and Food To Eat on MYLE",

    meta: [
      {
        name: "description",
        content:
          "Our mission is to create an exceptional experience that brings people together with all-in-one entertainment  marketing and event sales solution. Copy 2. Find things To Do, Places To Go and Food To Eat on MYLE. Create an event, sell tickets and promote your event to wider network of businnesses and individuals. Find out how. ",
      },

      {
        name: "keywords",
        content: "event curation, sell tickets online, discover event near you",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/",
      },
    ],
  },
};
</script>

<!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API = Tawk_API || {},
  Tawk_LoadStart = new Date();
(function () {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/6581ac2a70c9f2407f8175ac/1hi18h22h";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();

window.fbAsyncInit = function () {
  FB.init({
    appId: "1021595281271254",
    cookie: true,
    xfbml: true,
    version: "v20.0",
  });

  FB.AppEvents.logPageView();
};

(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");
</script>

<!--End of Tawk.to Script-->

<!--Start of Tawk.to Script-->
<!-- <script type="text/javascript">
var Tawk_API = Tawk_API || {},
  Tawk_LoadStart = new Date();
(function () {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/657b632770c9f2407f7fe078/1hhkvml17";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();
</script> -->
<!--End of Tawk.to Script-->
