<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Kappa_Collection_Page_Banner_CustomPage.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 45px;
              "
            >
              Kappa Conclave 2023
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            20 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/tampa-kulture-bus-tour" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Tampa Kulture Bus Tour
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 20th 2023, 9:45 AM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/tampa-kulture-bus-tour"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/tampa-kulture-bus-tour" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Kulture_Tour.jpg"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            20 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/all-access-party-packages" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              All Access Party Packages
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 20th 2023, 8:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/all-access-party-packages"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/all-access-party-packages" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyerr_All_Access_Party_Package.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            20 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/krimson-takeover-party" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Krimson Takeover Party
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 20th 2023, 8:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/krimson-takeover-party"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/krimson-takeover-party" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Krimson_Takeover_IHQ.jpg"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            21 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/kreme-kulture-jam" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Kreme Kulture Jam
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 21st 2023, 8:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/kreme-kulture-jam"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/kreme-kulture-jam" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Kulture_Jame_IHQ.jpg"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            22 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/family-outing" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              Family Outing
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 22nd 2023, 12:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/family-outing"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/family-outing" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Family_Outing.jpg"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            22 <br />
            July
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/the-kulmination-the-grand-polemarch-closed-ball"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #ac1e2e;
              "
            >
              The Kulmination – The Grand Polemarch’s Closed Ball
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: #ac1e2e;
              "
            >
              July, 22nd 2023, 8:00 PM
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/the-kulmination-the-grand-polemarch-closed-ball"
                  class="btn solid-btn-crimson mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/the-kulmination-the-grand-polemarch-closed-ball"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyre_Closed_BallIHQ.jpg"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />

      <p
        class="text-center lead-heading mb-3"
        style="
          font-family: 'Quicksand', sans-serif;
          font-weight: 700;
          font-size: 18px;
        "
      >
        All social event ticket related matters will be handled by the Tampa
        Alumni Chapter. For inquiries, email
        <a href="mailto:kappaconclave@myle.com"> kappaconclave@myle.com.</a>
      </p>

      <hr class="test" style="background: #ac1e2e" />
      <br />

      <div class="row w-100 m-0 promo-section MYLE-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center text-white">
                DOWNLOAD MYLE TODAY TO GET STARTED
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
              <h3 class="text-center text-white mt-3">
                We look forward to seeing you soon at the “Klave on the Bay.”
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-lg-3 col-md-3">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
        <div class="col-lg-9 col-md-9">
          <p
            class="mt-0 mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 24px;
              background-color: #ac1e2e;
              color: white;
            "
          >
            Get ready for The 86th Kappa Konclave with MYLE
          </p>

          <p
            class="mb-2 ml-4"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 18px;
            "
          >
            • Find all of the “Konclave on the Bay” events in MYLE
            <br />
            • Buy your event tickets with MYLE
            <br />
            • Keep all of your digital tickets in MYLE for easy and fast
            check-in
            <br />
            • Help the local chapter earn referral points – use referral code
            “TampaAlumniChapter” during account set-up
            <br />
            • Receive important updates before and during the event
            <br />
            • Get around Tampa seamlessly using the MYLE app for transportation
            options including Waze/Apple Maps/Google Maps/Uber and Lyft –
            integrated into MYLE mobile.
            <br />
            • Find other fun things to do in Tampa or on the way!
            <br />
          </p>
        </div>
      </div>

      <!-- <div class="row">
          <div class="col-md-6 col-lg-6 align-items-center">
            <a
              href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
              target="_blank" rel="noopener noreferrer"
            >
              <div class="">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
  
              <p
                class="text-center lead-heading ml-3 mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                For The Kings | Juneteenth + Father's Day Brunch
              </p>
              <p
                class="text-center lead-heading ml-3 mb-3"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 300;
                  font-size: 16px;
                "
              >
                Sun, Jun 19, 12:00 PM
              </p>
            </a>
          </div>
        </div> -->
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Kappa Conclave 2023",

    meta: [
      {
        name: "description",
        content: "Kappa Conclave",
      },

      {
        name: "keywords",
        content: "Kappa Conclave",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/kappa-conclave-2023",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
