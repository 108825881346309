<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="confirmmodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3 class="w-auto d-inline-block">
                {{
                  $route.query.checkout == "success"
                    ? "Successful Purchased"
                    : "Oops!"
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              {{
                $route.query.checkout == "success"
                  ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                  : "Something went wrong with the payment. Please try again."
              }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <br />
              <button
                type="button"
                @click="$refs.confirmmodal.close()"
                class="btn solid-btn my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  width: 150px;
                  margin-top: 11px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price || 0
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-crimson btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  // (promosAllow.includes(TicketsResult[selected].ticketCode)
                  //   ? !redeemData
                  //   : false) ||
                  (TicketsResult[selected].discount
                    ? // &&
                      // TicketsResult[selected].ticketCode == 'tk_qsSj5fnRek75qJE'
                      !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_family_outing_header_banner.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: #ac1e2e">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                Family Outing
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                July 22, 2023
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/7MXVUqGC8huPrjcB6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Riverfront Park - 1001 N. Blvd</a
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div class="section-heading mb-2 mt-5">
        <div class="text-center">
          <a
            href="https://myle.com/kappa-conclave-2023"
            class="btn solid-btn-crimson mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Conclave Events
          </a>

          <a
            href="https://kappaconclave2023.com/86th-events"
            class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Conclave Website</a
          >
          <a
            href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/TAC%2086th%20GCM%20FAQ.pdf"
            class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs</a
          >
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <p
                    class="text-center lead-heading"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 22px;
                    "
                  >
                    Registered Brothers
                  </p>

                  <p
                    class="text-center lead-heading mb-5"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 18px;
                    "
                  >
                    Guest tickets for the Family Outing should be purchased via
                    the
                    <a
                      href="https://myle.com/kappa-conclave-registered-2023"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Registered Brothers Portal</a
                    >. The tickets purchased in the Portal include a food
                    voucher.

                    <!-- Guest tickets for the Family Outing will not be available
                    through MYLE until June 1, 2023. Prior to that, tickets
                    should continue to be purchased at the IHQ Conclave –
                    <a href="https://kappaconclave2023.com" target="_blank" rel="noopener noreferrer">
                      Manage Registration</a
                    >
                    site. -->
                  </p>

                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in TicketsResult"
                    >
                      <div class="card-header MYLE-crimson white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-crimson"
                            id="reserve"
                            @click="openmodel(objk)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content mt-1">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <b
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 20x;
                  "
                  >General Admission Adult ticket</b
                >
                allows access to main event with live entertainment. Included as
                part of Registration for the members of Kappa Alpha Psi
                Fraternity, Inc. Food and beverages will be available for
                purchase.

                <br /><br />
                <b
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 20x;
                  "
                  >General Admission Child ticket</b
                >
                allows access to main event & children’s play cove and the live
                entertainment. Food and beverages will be available for
                purchase.
                <!--
                <b
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 20x;
                  "
                  >Guest w/Registered Brother ticket</b
                >
                allows access to main event and includes a meal ticket along
                with the live entertainment. Registered Brothers may click this

                <a
                  href="https://myle.com/kappa-conclave-registered-2023"
                  target="_blank" rel="noopener noreferrer"
                >
                  link</a
                >
                to access (1) complimentary ticket and purchase additional guest
                tickets. <br /><br />

                <b
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 20x;
                  "
                  >Child w/Registered Brother ticket</b
                >
                allows access to main event & children’s play cove and includes
                a meal ticket and the live entertainment.
              </p> -->
              </p>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <!-- The Black Food Truck Festival is a family-oriented event
                    featuring the finest food trucks in the region in Charleston,
                    South Carolina. Our mission is to make an economic and social
                    impact on the community by showcasing and investing in area
                    Black-owned businesses.
                    <br />
                    <br />
                    The Black Food Truck Festival is the ultimate family reunion
                    featuring kid-friendly activities in a safe, secure environment.
                    Expect to experience a range of different cuisines while
                    enjoying performances by local bands and musicians.
                    <br />
                    <br />
                    Our goal is to create an atmosphere of unity, love, and positive
                    vibes. Come celebrate with us and have a great time while
                    creating a memorable experience!
                    <br />
                    <br /> -->

                Get ready to experience the ultimate Family Outing hosted by
                Nephew Tommy and co-hosted by Deanne King, Anchor/Reporter for
                WFLA News Channel 8. On Saturday, July 22, 2023, from 12:00pm –
                5:00 pm, head to the beautiful Riverfront Park on the
                Hillsborough River, just a short walk from the host hotels
                situated along Tampa’s scenic Riverwalk.

                <br />
                <br />

                With something for everyone, the Family Outing Experience
                promises live musical performances, The Stompdown Kappa Edition
                Step & Stroll showcase, entertainment from top-notch performers
                and a tantalizing array of food truck options to keep your
                family's taste buds happy. But the fun doesn't stop there. Your
                little ones can bounce around in our inflatable Kid Zone and
                cool off in Riverfront Park's Splash Pad and Play Cove,
                specially designed with younger kids in mind.

                <br />
                <br />

                We also want to prioritize our health, so prepare to engage in
                our onsite health screenings sponsored by Moffitt Cancer Center
                and Aetna.

                <br />
                <br />

                And for our VIP members and senior guests, the air-conditioned
                Tampa River Center is all yours to explore. It's the perfect
                getaway for family bonding, and we can't wait to see you there!
                Purchase your tickets today and visit our event
                <a
                  href="https://kappaconclave2023.com/family-outing-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website</a
                >

                periodically for more exciting announcements:

                <!-- The 86th GCM Family Outing – one of the signature events hosted
                by Tampa (FL) Alumni Chapter – will provide the ultimate family
                experience.
                <br />
                <br />
                It will be held at Riverfront Park on Saturday, July 22, 2023,
                from 12:00 – 5:00 pm. Riverfront Park is on the Hillsborough
                River and walking distance from the host hotels along Tampa’s
                beautiful Riverwalk.
                <br />
                <br />
                This Family Outing Experience will have something for the entire
                family. There will be live entertainment provided by marquee
                talent. Beyond the entertainment and variety of food options,
                Riverfront Park provides a Splash Pad and Play Cove for the
                youngest members of our families.
                <br />
                <br />
                The Tampa River Center will be available to our VIP and Senior
                guests. -->
                <!-- <br /><br />
                *Ticket Price includes processing fees of 6.9%+.60 -->
              </p>
            </div>

            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/kappa-conclave-2023"
                  class="btn solid-btn-crimson mb-1 ml-3 mr-3 text-center"
                  target="_self"
                  >Conclave Events
                </a>

                <a
                  href="https://kappaconclave2023.com/86th-events"
                  class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Conclave Website</a
                >
                <a
                  href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/TAC%2086th%20GCM%20FAQ.pdf"
                  class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQs</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div class="row w-100 m-0 promo-section MYLE-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              DOWNLOAD MYLE TODAY TO GET STARTED
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
            <h3 class="text-center text-white mt-3">
              We look forward to seeing you soon at the “Klave on the Bay.”
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mt-0 mb-2"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
            background-color: #ac1e2e;
            color: white;
          "
        >
          Get ready for The 86th Kappa Konclave with MYLE
        </p>

        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          • Find all of the “Konclave on the Bay” events in MYLE
          <br />
          • Buy your event tickets with MYLE
          <br />
          • Keep all of your digital tickets in MYLE for easy and fast check-in
          <br />
          • Help the local chapter earn referral points – use referral code
          “TampaAlumniChapter” during account set-up
          <br />
          • Receive important updates before and during the event
          <br />
          • Get around Tampa seamlessly using the MYLE app for transportation
          options including Waze/Apple Maps/Google Maps/Uber and Lyft –
          integrated into MYLE mobile.
          <br />
          • Find other fun things to do in Tampa or on the way!
          <br />
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";

import confirmationPopup from "../../components/confirmationPopup";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      promosAllow: [
        "tk_2e3AyNE7EKP79X6",
        "tk_3bntFuZt8MM35UU",
        "tk_arEt00MtX38bKhl",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_PMcbht00yq9kwWu: {
          messages: [
            "Ticket Price: $35.00",
            "Processing fee of $3.99",
            "Sales Tax of $2.53",
          ],
        },

        tk_lFK5wIIpa3syj0X: {
          messages: [
            "Ticket Price: $10.00",
            "Processing fee of $2.31",
            "Sales Tax of $0.80",
          ],
        },

        // tk_QuGZwO03TydIF4k: {
        //   messages: [
        //     "Ticket Price: $35.00",
        //     "Processing fee of $3.02",
        //     "Sales Tax of $2.47",
        //   ],
        // },

        // tk_d6G7RFWDx035gEj: {
        //   messages: [
        //     "Ticket Price: $20.00",
        //     "Processing fee of $1.98",
        //     "Sales Tax of $1.43",
        //   ],
        // },
      },

      priceIds: {
        tk_PMcbht00yq9kwWu: "price_1Mv2bdD0vTZ4QB9cZbehSdee",
        tk_lFK5wIIpa3syj0X: "price_1Mv2bPD0vTZ4QB9cewyn7xXI",
        // tk_QuGZwO03TydIF4k: "price_1MDvfQD0vTZ4QB9cW5or6gzE",
        // tk_d6G7RFWDx035gEj: "price_1MDvgaD0vTZ4QB9cgtrTgymX",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    closeModal() {
      this.$refs.confirmmodal12.close();
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchFamilyOutingontheRiverwalk_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_PMcbht00yq9kwWu: null,
            tk_lFK5wIIpa3syj0X: null,
            // tk_QuGZwO03TydIF4k: null,
            // tk_d6G7RFWDx035gEj: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Family Outing",

    meta: [
      {
        name: "description",
        content: "MYLE | Family Outing",
      },

      {
        name: "keywords",
        content: "MYLE | Family Outing",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/family-outing",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
