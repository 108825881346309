<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>

              <p>
                <b class="font-weight-bold">Ticket Price:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].price
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Service Fees:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].service
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Sales Tax:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].sales
                    : "0"
                }}
              </p>

              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mt-3">
              <label class="">Phone</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="xxx-xxx-xxxx"
                required
                v-model="phone"
              />
            </div>

            <!-- <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_vgVuFpgQ9TrlPAb'
              "
            >
              <label class="mt-3">Meal Selection</label>
              <select class="form-control" v-model="mealSelection">
                <option
                  class="form-control"
                  v-bind:key="m + 'b'"
                  v-for="m in meals"
                  :value="m"
                >
                  {{ m }}
                </option>
              </select>
            </div> -->

            <div
              class="container-fluid row mt-3"
              style="padding: 0; margin-left: 1px !important"
              v-bind:key="gli + 'list'"
              v-for="gli in glistArr"
            >
              <div
                class="col-md-6 mb-1"
                v-if="
                  tickcount > 0 && TicketsResult[selected]
                  //&& TicketsResult[selected].ticketCode == 'tk_vgVuFpgQ9TrlPAb'
                "
              >
                <input
                  type="text"
                  v-bind:key="gli + 'fname'"
                  v-if="glists[gli]"
                  :value="glists[gli].name"
                  v-on:keyup="guestchange($event, 'name', gli)"
                  class="form-control mb-3"
                  placeholder="Guest Name"
                  required
                />
              </div>
              <!-- <div
                class="col-md-6 mb-1"
                v-if="
                  tickcount > 0 && TicketsResult[selected]
                  //&& TicketsResult[selected].ticketCode == 'tk_vgVuFpgQ9TrlPAb'
                "
              >
                <input
                  type="text"
                  v-if="glists[gli]"
                  :value="glists[gli].email"
                  v-bind:key="gli + 'email'"
                  class="form-control mb-3"
                  v-on:keyup="guestchange($event, 'email', gli)"
                  placeholder="Guest Email Address"
                />
              </div> -->
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  !invalidphone ||
                  !phone.trim().length ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (tickcount > 1 && TicketsResult[selected]
                    ? // && TicketsResult[selected].ticketCode == 'tk_vgVuFpgQ9TrlPAb'
                      !isvalid
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Bubbles_Bags.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                WCEO Presents Bubbles & Bags
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                September 16, 2023 11:30am - 1:30pm
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/qv5wmBszNBtufnwW9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Mitchell Hall, CSCC
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              It's Time to Claim Your Joy!
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Join The Women's CEO In Celebrating 10 Years of Service
                <br />
                Equipping, Elevating & Empowering
                <br />
                Aspiring Women of Color
                <br /><br />

                Bubbles & Bags Includes:<br />
                Champagne Brunch | Music | Vendors | Inspirational Speakers<br /><br />

                It's Time to Claim Your Joy!<br />
                Be Bolder, Go Bigger & Love Better!

                <br /><br />
                We are thrilled to announce an exciting new sponsor

                <br /><br />
                You are the first to know that every attendee will have a chance
                to win a brand new Gucci handbag 👜 in our event raffle!

                <br /><br />
                🔎 Plus, back by popular demand, the "What's in Your Bag" game
                with opportunities to win fabulous gift certificates! And new
                this year...there will be an exclusive WIYB for event alumni
                only. (Hint: get there on time!)

                <br /><br />
                Support Your Sister Entrepreneurs and Businesswomen
                <br /><br />
                This fun celebration with champagne, a delicious brunch, great
                music, local vendors, and a 360 photo booth will be one you
                won't want to miss!
                <br /><br />
                September 16, 2023<br />
                11:30am - 1:30pm<br />
                Mitchell Hall @ Columbus State Community College
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                Tickets Options
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="py-5">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block mb-2"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 30px;
                  "
                >
                  Vendor Sign-up
                </h3>
              </div>
              <div class="d-flex flex-row flex-wrap justify-content-center">
                <div
                  class="card mx-4 mt-4"
                  style="
                    max-width: 400px !important;
                    border-radius: 10px;
                    overflow: hidden;
                  "
                  v-bind:key="objk"
                  v-for="(obj, objk) in vendorTickets(TicketsResult)"
                >
                  <div class="card-header MYLE-green white">
                    {{ obj.ticketName }}
                  </div>
                  <div
                    class="card-body"
                    style="display: flex; flex-direction: column"
                  >
                    <p
                      v-bind:key="w"
                      v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                    >
                      - {{ msgs }}
                    </p>
                    <div class="text-center" style="margin-top: auto">
                      <p>
                        <b class="font-weight-bold">Total Price: </b>${{
                          obj.price.toFixed(2)
                        }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p>
                      <button
                        :disabled="
                          !obj.availableTickets ||
                          obj.status == 'EXPIRED' ||
                          obj.status == 'COMING_SOON'
                        "
                        v-bind:class="{
                          'bg-disable':
                            obj.status == 'EXPIRED' ||
                            !obj.availableTickets ||
                            obj.status == 'COMING_SOON',
                        }"
                        class="btn solid-btn"
                        id="reserve"
                        @click="openmodel(obj)"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-size: 18px;
                        "
                      >
                        {{
                          obj.status == "EXPIRED" || !obj.availableTickets
                            ? "Sold Out"
                            : obj.status == "COMING_SOON"
                            ? "Coming Soon"
                            : "Reserve Now"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      <!-- <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="py-5">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block mb-2"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 30px;
                  "
                >
                  Sponsorships
                </h3>
              </div>
              <div class="d-flex flex-row flex-wrap justify-content-center">
                <div
                  class="card mx-4 mt-4"
                  style="
                    max-width: 400px !important;
                    border-radius: 10px;
                    overflow: hidden;
                  "
                  v-bind:key="objk"
                  v-for="(obj, objk) in sponsorshipsTickets(TicketsResult)"
                >
                  <div class="card-header MYLE-green white">
                    {{ obj.ticketName }}
                  </div>
                  <div
                    class="card-body"
                    style="display: flex; flex-direction: column"
                  >
                    <p
                      v-bind:key="w"
                      v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                    >
                      - {{ msgs }}
                    </p>
                    <div class="text-center" style="margin-top: auto">
                      <p>
                        <b class="font-weight-bold">Total Price: </b>${{
                          obj.price.toFixed(2)
                        }}
                      </p>
                      <p
                        v-bind:key="linek"
                        v-for="(line, linek) in obj.description"
                      >
                        {{ line }}
                      </p>
                      <button
                        :disabled="
                          !obj.availableTickets ||
                          obj.status == 'EXPIRED' ||
                          obj.status == 'COMING_SOON'
                        "
                        v-bind:class="{
                          'bg-disable':
                            obj.status == 'EXPIRED' ||
                            !obj.availableTickets ||
                            obj.status == 'COMING_SOON',
                        }"
                        class="btn solid-btn"
                        id="reserve"
                        @click="openmodel(obj)"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-size: 18px;
                        "
                      >
                        {{
                          obj.status == "EXPIRED" || !obj.availableTickets
                            ? "Sold Out"
                            : obj.status == "COMING_SOON"
                            ? "Coming Soon"
                            : "Reserve Now"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      <!-- <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="download-content">
          <p
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 18px;
            "
          >
            For your convenience, we have partnered with MYLE as our ticket
            provider.

            <br />
            <br />
            MYLE - Make Your Life Entertaining is an Event and Entertainment
            platform.

            <br />
            <br />

            Be sure to download the MYLE Events App via
            <a href="https://www.myle.com" target="_blank" rel="noopener noreferrer"> www.myle.com</a>
            or in your iOS or Google Play Store under MYLE Events.

            <br />
            <br />
            The MYLE App provides seamless transportation options including
            Waze/Apple Maps/Google Maps/Uber and Lyft which are all integrated
            into the App for your traveling convenience.

            <br />
            <br />

            Note: Your tickets will be available on the app in the
            Accounts/Tickets section after purchase.

            <br />
            We look forward to seeing you soon.
          </p>
        </div>
      </div> -->
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      isvalid: false,
      glists: {},
      mealSelection: "Blackened Salmon",
      meals: [
        "Blackened Salmon",
        "Braised Short Rib",
        "Whole Roast Baby Cauliflower",
      ],
      bname: "",
      addressInfo: "",
      pnumber: "",
      bonded: "No",
      insured: "No",
      licensed: "No",
      license: "",
      required_power_needs: "No",
      ageRangesList: [
        "Under 21",
        "21-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
      ],
      ageRange: "Under 21",
      phone: "",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_0borQPnLZuv7JH0",
        "tk_fND60szIjZmxIFE",
        "tk_gX6YUIO7UQlxEsC",
      ],

      vendorData: [],

      sponsorshipsData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,

      tkListData: {
        tk_0borQPnLZuv7JH0: {
          price: "$75",
          service: "$6.67",
          sales: "$0",
        },

        tk_fND60szIjZmxIFE: {
          price: "$108.35",
          service: "$8.91",
          sales: "$0",
        },

        tk_gX6YUIO7UQlxEsC: {
          price: "$60",
          service: "$0",
          sales: "$0",
        },
      },

      tkList: {
        tk_0borQPnLZuv7JH0: {
          messages: ["Ticket Price: $75", "Processing fee of $6.67"],
        },

        tk_fND60szIjZmxIFE: {
          messages: ["Ticket Price: $108.35", "Processing fee of $8.91"],
        },

        tk_gX6YUIO7UQlxEsC: {
          messages: ["Ticket Price: $60", "Processing fee of $0"],
        },
      },

      priceIds: {
        tk_0borQPnLZuv7JH0: "price_1NPoVrD0vTZ4QB9clKE1zBh8",
        tk_fND60szIjZmxIFE: "price_1NOpM6D0vTZ4QB9cehRClBDF",
        tk_gX6YUIO7UQlxEsC: "price_1NLoRpD0vTZ4QB9cROvsDkD7",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },

  methods: {
    checkoverall() {
      this.isvalid = true;
      //   let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      Object.keys(this.glists).forEach((d1) => {
        const glistRecord = this.glists[d1];
        if (!glistRecord.name || glistRecord.name.trim().length < 1) {
          this.isvalid = false;
        }
      });
    },
    guestchange(evt, key, idx) {
      if (!this.glists[idx]) {
        this.glists[idx] = {};
      }

      this.glists[idx][key] = evt.target.value;
      this.checkoverall();
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    vendorTickets(list) {
      return list.filter((t) => this.vendorData.includes(t.ticketCode));
    },

    sponsorshipsTickets(list) {
      return list.filter((t) => this.sponsorshipsData.includes(t.ticketCode));
    },

    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      this.glists = {};
      this.tickcount = 1;
      this.isvalid = false;

      for (let i = 0; i < 1; i++) {
        this.glists[i] = {
          name: "",
          email: "",
        };
      }
      this.isvalid = false;
      this.license = "";
      this.licensed = "No";
      this.insured = "No";
      this.bonded = "No";
      this.bname = "";
      this.pnumber = "";
      this.addressInfo = "";

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      this.reserveFormAccept = false;
      this.phone = "";
      this.fullname = "";
      this.email = "";
      this.isSubmitted = false;
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          phone: this.phone,
        },
      };

      if (
        vm.tickcount > 1 &&
        vm.TicketsResult[vm.selected]
        // && vm.TicketsResult[vm.selected].ticketCode == "tk_vgVuFpgQ9TrlPAb"
      ) {
        payloadToPush.extraInfo = {
          Guest: [
            ...Object.keys({ ...this.glists }).map((v) => this.glists[v].name),
            this.fullname,
          ],
          phone: this.phone,
        };

        payloadToPush.additionalInfo = {
          type: "Guest",
          value: [
            ...Object.keys({ ...this.glists }).map((v) => this.glists[v].name),
            this.fullname,
          ],
        };
      }
      //   else {
      //     if (
      //       vm.TicketsResult[vm.selected] &&
      //       vm.TicketsResult[vm.selected].ticketCode == "tk_vgVuFpgQ9TrlPAb"
      //     ) {
      //       payloadToPush.extraInfo = {
      //         meals: this.mealSelection,
      //       };
      //     }
      //   }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            // vm.ageRange = "Under 21";
            vm.phone = "";
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          vm.license = "";
          vm.licensed = "No";
          vm.insured = "No";
          vm.bonded = "No";
          vm.bname = "";
          vm.pnumber = "";
          vm.addressInfo = "";

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchWCEOPresentsBubblesBags_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_0borQPnLZuv7JH0: null,
            tk_fND60szIjZmxIFE: null,
            tk_gX6YUIO7UQlxEsC: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  watch: {
    tickcount(n) {
      this.glists = {};
      this.isvalid = false;

      for (let i = 0; i < n - 1; i++) {
        this.glists[i] = {
          name: "",
          email: "",
        };
      }
    },
  },
  computed: {
    glistArr() {
      const filteredRec = Array.from(Array(this.tickcount - 1).keys());
      return this.tickcount < 2 ? [] : filteredRec;
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invalidpnumber() {
      return (
        (this.pnumber || "").match(/\d/g) &&
        (this.pnumber || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | WCEO Presents Bubbles & Bags",

    meta: [
      {
        name: "description",
        content: "MYLE | WCEO Presents Bubbles & Bags",
      },

      {
        name: "keywords",
        content: "MYLE | WCEO Presents Bubbles & Bags",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/wceo-presents-bubbles-&-bags",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
