<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="countChange()"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>

            <hr
              class="w-100 p-2"
              v-if="
                TicketsResult[selected] &&
                section1.includes(TicketsResult[selected].ticketCode)
              "
            />
            <div
              class="row"
              v-if="
                TicketsResult[selected] &&
                section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6 mb-2">
                <label class="">Title</label>
                <input
                  type="text"
                  v-model="ctitle"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Company Name</label>
                <input
                  type="text"
                  v-model="cname"
                  class="form-control"
                  placeholder="Company Name"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Contact Number</label>
                <input
                  type="text"
                  v-model="telephoneNumber"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Gender</label>
                <select class="form-control" v-model="gender">
                  <option
                    class="form-control"
                    v-bind:key="count + 'b'"
                    v-for="count in ['Male', 'Female', 'Nonbinary and Agender']"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Age Group</label>
                <select class="form-control" v-model="ageGroup">
                  <option
                    class="form-control"
                    v-bind:key="count + 'b'"
                    v-for="count in ['18-25', '26-40', '41-60', '60+']"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (section1.includes(TicketsResult[selected].ticketCode)
                    ? !tellPno
                    : false)
                "
                class="btn solid-btn-SWA m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Register Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthFiretalkTVLivestream.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #304cb2"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                Firetalk TV Livestream
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                Saturday, June 08, 2024 <br />
                12:00pm - 01:00pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <!-- <a
                    href="https://maps.app.goo.gl/nKrGEpLMHrNLdSax7"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: white"
                  >
                    Morrow Center
                  </a> -->
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content mt-2">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Firetalk TV Episode 3<br />
                Men’s Health: A State Of Urgency<br />
                Live on FireTalk TV YouTube channel

                <br /><br />
                Q&A with Firetalk TV team and Dr. Ademola Ademola
                <br /><br />

                Topics Covered:<br />

                • For Most Men, Their First Encounter With The Doctor Is In The
                E. R.<br />

                • Black Men Lack Trust In Doctors<br />

                • Fear Dominates Our Health<br />
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Livestream via YouTube
              </p>

              <a
                href="https://youtube.com/@platinummenshealth3576?si=iM84vyj6Z7_HoFrE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="align-middle"
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_youtube_logo.jpg"
                  style="width: 10%; max-width: 10%; height: auto"
                />
              </a>
              <hr />
            </div>

            <div class="row justify-content-center">
              <div
                class="col-8 col-md-6 justify-content-center d-flex align-items-center"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/FAMHS-Firetalk-TV-Flyer-Small.png"
                    alt=""
                    class="w-100 img-fluid"
                  />
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 35px;
                      "
                    >
                      Register
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section1Filter(TicketsResult)"
                    >
                      <div
                        class="card-header MYLE-green white text-center"
                        style="background-color: #304cb2"
                      >
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <!-- <p
                            v-bind:key="w"
                            v-for="(msgs, w) in filteredMessages(obj)"
                          >
                            - {{ msgs }}
                          </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-SWA"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "RSVP"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div
              class="row align-items-center justify-content-between test"
              style="margin: auto; margin-top: 20px"
            >
              <div class="col-md-12">
                <div class="section-heading mb-2">
                  <div class="text-center">
                    <a
                      href="https://myle.com/black-mens-health-collection"
                      class="btn solid-btn-SWA mb-1 text-center"
                      target="_self"
                      >Back To Main Page</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background-color: #304cb2"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      nameOfBusiness: "",
      address: "",
      contactPerson: "",
      telephoneNumber: "",
      cname: "",
      ctitle: "",
      gender: "Male",
      ageGroup: "18-25",
      emailAddress: "",
      showsuccess: false,
      states: [
        { "States in USA": "Alabama" },
        { "States in USA": "Alaska" },
        { "States in USA": "Arizona" },
        { "States in USA": "Arkansas" },
        { "States in USA": "Califonria" },
        { "States in USA": "Colorado" },
        { "States in USA": "Connecticut" },
        { "States in USA": "Delaware" },
        { "States in USA": "Dictrict of Columbia" },
        { "States in USA": "Florida" },
        { "States in USA": "Georgia" },
        { "States in USA": "Hawaii" },
        { "States in USA": "Idaho" },
        { "States in USA": "Illinois" },
        { "States in USA": "Indiana" },
        { "States in USA": "Iowa" },
        { "States in USA": "Kansas" },
        { "States in USA": "Kentucky" },
        { "States in USA": "Louisiana" },
        { "States in USA": "Maine" },
        { "States in USA": "Maryland" },
        { "States in USA": "Massachusetts" },
        { "States in USA": "Michigan" },
        { "States in USA": "Minnesota" },
        { "States in USA": "Mississippi" },
        { "States in USA": "Missouri" },
        { "States in USA": "Montana" },
        { "States in USA": "Nebraska" },
        { "States in USA": "Nevada" },
        { "States in USA": "New Hampshire" },
        { "States in USA": "New Jersey" },
        { "States in USA": "New Mexico" },
        { "States in USA": "New York" },
        { "States in USA": "North Carolina" },
        { "States in USA": "North Dakota" },
        { "States in USA": "Ohio" },
        { "States in USA": "Oklahoma" },
        { "States in USA": "Oregon" },
        { "States in USA": "Pennsylvania" },
        { "States in USA": "Rhode Island" },
        { "States in USA": "South Carolina" },
        { "States in USA": "South Dakota" },
        { "States in USA": "Tennessee" },
        { "States in USA": "Texas" },
        { "States in USA": "Utah" },
        { "States in USA": "Vermont" },
        { "States in USA": "Virginia" },
        { "States in USA": "Washington" },
        { "States in USA": "West Virginia" },
        { "States in USA": "Wisconsin" },
        { "States in USA": "Wyoming" },
      ],
      district: [
        {
          Dr: {
            superintendent: "No District Affiliation",
          },
        },
        {
          Dr: {
            superintendent: "Dr. Sandra Thomas, Echo Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Tina Halliman, SPEED Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "District 92 - Dr. Janiece Jackson, Lindop School",
          },
        },

        {
          Dr: {
            superintendent: "District 98 - Dr. Michelle Smith, Berwyn School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 111 - Dr. Genevra Walters, Kankakee School",
          },
        },

        {
          Dr: {
            superintendent: "District 125 - Dr. Lisa Cole, Atwood Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 132 - Dr. Marcus Alexander, Calumet School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 143.5 - Dr. Anthony Edison, Posen Robbins School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 144 - Dr. Denise Julius, Dr. Terry O'brien, Prairie Hills School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 147 - Dr. Jerry Jordan, Dr. Creg Williams, Harvey Dixmoor School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 148 - Kevin Nohelty, Dolton Riverdale School",
          },
        },

        {
          Dr: {
            superintendent: "District 149 - Dr. Maureen White, Dolton School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 150 - Jerome Farrell, South Holland School",
          },
        },

        {
          Dr: {
            superintendent: "District 152 - Dr. Lela Bridges, Harvey School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 152.5 - Dr. Kenneth Spells, East Hazel Crest School",
          },
        },

        {
          Dr: {
            superintendent: "District 156 - Dr. Anita Rice, Lincoln School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 157 - Dr. Dwayne Evans, Calumet City School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 159 - Dr. Tiffany Brunson, Elementary School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 160 - Dr. Duane Meighan, Country Club Hills School",
          },
        },

        {
          Dr: {
            superintendent: "District 162 - Dr. Blondean Davis, Matteson/SCP",
          },
        },

        {
          Dr: {
            superintendent:
              "District 163 - Dr. Joyce Carmine, Park Forest School",
          },
        },

        {
          Dr: {
            superintendent: "District 167 - Bethany Lindsay, Brookwood School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 168 - Dr. Donna Simpson Leak, Sauk Village School",
          },
        },

        {
          Dr: {
            superintendent: "District 169 - Dr. Gregory Jackson, Ford Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 171 - Dr. Erika Millhouse, Sunnybrook School",
          },
        },

        {
          Dr: {
            superintendent: "District 172 - Dr. Kim Nalls, Sandridge School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 205 - Dr. Nathaniel Cunningham, Thornton HS",
          },
        },

        {
          Dr: {
            superintendent: "District 206 - Dr. Lenell Navarre, Bloom Township",
          },
        },

        {
          Dr: {
            superintendent: "District 209 - Dr. Bessie Karvelas, Proviso HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 215 - Dr. John M. Robinzine, Thornton Fractional HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 227 - Dr. Johnnie Thomas, Rich Township HS",
          },
        },

        {
          Dr: {
            superintendent: "District 228 - Dr. Brad Sakora, Breman HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 233 - Dr. Scott Wakeley, Homewood Flossmoor HS",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Cynthia Broughton",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Eric King",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Gloria Davis",
          },
        },

        {
          Dr: {
            superintendent: "Dr. John Sawyer",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Louise Coleman",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Mable Alfred",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Sheila Harrison-Williams",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Willie Mack",
          },
        },
        {
          Dr: {
            superintendent: "Kay Giles",
          },
        },

        {
          Dr: {
            superintendent: "Ms. Lisa Cole Hide",
          },
        },

        {
          Dr: {
            superintendent: "Phylistine Murphy",
          },
        },

        {
          Dr: {
            superintendent: "Theodis Swopes",
          },
        },
      ],
      agePlaceholders: [
        "First Child Age",
        "Second Child Age",
        "Third Child Age",
        "Fourth Child Age",
      ],
      vchange: true,
      validSection: true,
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {},
      section2: [],
      section1: [
        "tk_lfUh0FLvjMutYac",
        //"tk_1vSHotbf5pebo4j"
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_lfUh0FLvjMutYac: {
          messages: ["Ticket Price: $0"],
        },

        // tk_1vSHotbf5pebo4j: {
        //   messages: ["Ticket Price: $0"],
        // },
      },

      priceIds: {
        tk_lfUh0FLvjMutYac: "price_1PLUt2D0vTZ4QB9csFCxdQXX",
        // tk_1vSHotbf5pebo4j: "price_1PIu6ED0vTZ4QB9chP1OUUvb",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
  },
  methods: {
    totalCount(list) {
      if (this.TicketsResult[this.selected]) {
        const tmp = Object.keys(list).length;
        return tmp > 1 ? [...Array(tmp - 1).keys()] : [];
      }

      return [];
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    section2Filter(list) {
      return list.filter((l) => this.section2.includes(l.ticketCode));
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (code) {
      this.validSection = true;
      this.nameOfBusiness = "";
      this.address = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.cname = "";
      this.ctitle = "";
      this.gender = "Male";
      this.ageGroup = "18-25";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;

      if (code == "RZ44zOQ66InQgZ") {
        this.age["age1"] = "";
      } else if (code == "tk_eVzbCBqzSuFPfd5") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_g19EFw7kOMyfuL8") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_rFyeu7irKUhlI1U") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (
        !payloadToPush.extraInfo &&
        this.section1.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {};
        payloadToPush.extraInfo["company_name"] = this.cname;
        payloadToPush.extraInfo["title"] = this.ctitle;
        payloadToPush.extraInfo["contact_number"] = this.telephoneNumber;
        payloadToPush.extraInfo["gender"] = this.gender;
        payloadToPush.extraInfo["age_group"] = this.ageGroup;
      }
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";

          setTimeout(() => {
            vm.showsuccess = true;
            vm.$refs.confirmmodal.open();
          }, 1000);
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";

          this.showsuccess = false;
          setTimeout(() => {
            this.$refs.confirmmodal.open();
          }, 1000);
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredBlackMenHealthFiretalkTVLivestreamTickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_lfUh0FLvjMutYac: null,
            // tk_1vSHotbf5pebo4j: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      //    id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      // this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    validEmail() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(this.emailAddress);
    },
    tellPno() {
      return (
        (this.telephoneNumber || "").match(/\d/g) &&
        (this.telephoneNumber || "").trim().length >= 10
      );
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidPno() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Firetalk TV Livestream",

    meta: [
      {
        name: "description",
        content: "MYLE - Firetalk TV Livestream",
      },

      {
        name: "keywords",
        content: "MYLE - Firetalk TV Livestream",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/firetalk-tv-livestream",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
