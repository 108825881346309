import { render, staticRenderFns } from "./doubleList.vue?vue&type=template&id=417aec48&scoped=true"
import script from "./doubleList.vue?vue&type=script&lang=js"
export * from "./doubleList.vue?vue&type=script&lang=js"
import style0 from "./doubleList.vue?vue&type=style&index=0&id=417aec48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417aec48",
  null
  
)

export default component.exports