<template>
  <div
    style="
      background: url('https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/breeze_visa_background_V3.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      min-height: 100vh;
    "
  >
    <br />
    <br />
    <img
      class="theader"
      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/topHeader_V1.png"
    />

    <div class="layer3">
      <img
        class="side2"
        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/rightPattern_V2.png"
      />

      <img
        class="side1"
        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/leftPattern_V2.png"
      />
      <div class="layer2">
        <div class="layer1">
          <BreezeVISAContact
            class=""
            style="height: calc(100vh - 113px); overflow-y: scroll; width: 101%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
import imggg from "../../../public/img/test.png";
import imggg1 from "../../../public/img/g12.png";
import BreezeVISAContact from "./BreezeVISAVenue.vue";

// defining component state
export default {
  data() {
    return {
      imgg: null,
      imgg1: null,
    };
  },
  components: {
    BreezeVISAContact,
  },
  methods: {},
  created() {},
  mounted() {
    this.imgg = imggg;
    this.imgg1 = imggg1;
  },
};
</script>

<style scoped>
.layer1 {
  width: calc(100%);
  border: 20px solid rgb(240, 81, 118);
  border-top-right-radius: 500px;
  border-top-left-radius: 500px;
  overflow: hidden;
  border-bottom: none;
}

.layer2 {
  border: 20px solid #1d75bb;
  width: calc(100%);
  border-top-right-radius: 528px;
  border-top-left-radius: 528px;
  border-bottom: none;
}

.layer3 {
  border: 20px solid #19315c;
  width: calc(100% - 100px);
  border-top-right-radius: 550px;
  border-top-left-radius: 550px;
  margin: auto;
  background-image: linear-gradient(0deg, #fedab7, #ffffff) !important;
  max-width: 545px;
  border-bottom: none;
  position: absolute;
  z-index: 9999999;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.side1 {
  max-width: 100%;
  max-height: 15rem;
  position: absolute;
  bottom: 0px;
  right: calc(100% - 4rem);
  z-index: 11;
  pointer-events: none;
}

.side2 {
  max-width: 100%;
  max-height: 15rem;
  position: absolute;
  bottom: 0px;
  left: calc(100% - 4rem);
  z-index: 11;
  pointer-events: none;
}

@media only screen and (max-width: 550px) {
  .side1 {
    max-width: 100%;
    max-height: 11.5rem;
    position: absolute;
    bottom: 0px;
    right: calc(100% - 4rem);
    z-index: 11;
    pointer-events: none;
  }

  .side2 {
    max-width: 100%;
    max-height: 11.5rem;
    position: absolute;
    bottom: 0px;
    left: calc(100% - 4rem);
    z-index: 11;
    pointer-events: none;
  }
}

@media only screen and (max-width: 500px) {
  .layer1 {
    width: calc(100%);
    border: 15px solid rgb(240, 81, 118);
    border-top-right-radius: 500px;
    border-top-left-radius: 500px;
    overflow: hidden;
    border-bottom: none;
  }

  .layer2 {
    border: 15px solid #1d75bb;
    width: calc(100%);
    border-top-right-radius: 528px;
    border-top-left-radius: 528px;
    border-bottom: none;
  }

  .layer3 {
    border: 15px solid #19315c;
    width: calc(100% - 50px);
    border-top-right-radius: 550px;
    border-top-left-radius: 550px;
    margin: auto;
    background-image: linear-gradient(0deg, #fedab7, #ffffff) !important;
    max-width: 850px;
    border-bottom: none;
  }
}

.theader {
  width: calc(100% - 100px);
  position: absolute;
  max-width: 650px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  position: absolute;
  z-index: 9;
  pointer-events: none;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 750px) {
  .theader {
    width: calc(100%);
    position: absolute;
    max-width: 650px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
    pointer-events: none;
    top: 0;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}
</style>
