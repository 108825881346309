import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vue-multiselect/dist/vue-multiselect.min.css";
// ------------ css ----------
import "./assets/css/bootstrap.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import AnimateCSS from "animate.css";
import VueScrollTo from "vue-scrollto";
import VueYoutube from "vue-youtube";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Plugin } from "vue-responsive-video-background-player";
import Embed from "v-video-embed";
import VueCoreVideoPlayer from "vue-core-video-player";
import VueGtag from "vue-gtag";

import jQuery from "jquery";
window.$ = jQuery;

import VueFriendlyIframe from "vue-friendly-iframe";

Vue.use(VueFriendlyIframe);

import VueMeta from "vue-meta";
// Vue.use(VueMeta);
Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});

Vue.use(VueScrollTo);
Vue.use(VueYoutube);
Vue.use(AnimateCSS);
Vue.use(Plugin);
Vue.use(Embed);
Vue.use(Embed);
Vue.use(VueCoreVideoPlayer);

import VueThermometer from "./components/VueThermometer.vue";
Vue.use(VueThermometer);

import NuModal from "nu-modal-vue";
Vue.use(NuModal);
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;

import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: "150764351",
  router,
});

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-JWPZM602DP",
      params: {},
    },
  },
  router
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
