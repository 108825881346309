<template src="./SWAVisaCardVerifyFailed.html"></template>

<script>
// Vuex Imports
// Scss Imports
import "./SWAVisaCardVerifyFailed.scss";

export default {
  name: "SWAVisaCardVerifyFailed",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {
    changeFavicon(url) {
      let favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "icon");
        favicon.setAttribute("type", "image/png");
        document.head.appendChild(favicon);
      }
      favicon.setAttribute("href", url);
    },
  },
  created() {},
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/SWA_favicon.svg");
  },

  metaInfo: {
    title: "The Southwest® Landing Lounge",
    meta: [
      {
        name: "description",
        content: "The Southwest® Landing Lounge",
      },

      {
        name: "keywords",
        content: "The Southwest® Landing Lounge",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/the-southwest-landing-lounge-card-validation-failed",
      },
    ],
  },
};
</script>
