<template>
  <div id="features" class="feature-section ptb-100 white-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-heading text-center mb-5">
            <h2>Free to Use and Free to Post</h2>
            <!-- <p>
                            *Subscribe and unlock more benefits tarting at just $19.99/mo. (that’s less than $1 a day!)
                        </p> -->
          </div>
        </div>
      </div>

      <div class="row row-grid align-items-center">
        <div class="col-lg-4">
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-7 rounded-circle">
                <span class="ti-pencil-alt"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Post</h5>
              <p class="mb-0">
                Create and post your own private event and share it with friends
                or make it public
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-8 rounded-circle">
                <span class="ti-list"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>List</h5>
              <p class="mb-0">
                List your venue or event for free and amplify for just pennies
                more
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-9 rounded-circle">
                <span class="ti-bell"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Subscribe</h5>
              <p class="mb-0">
                Unlock more benefits starting at just $19.99/month (that’s less
                than $1 a day!)
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-7 rounded-circle">
                <span class="ti-share"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Share</h5>
              <p class="mb-0">
                Share events to your social channels to invite friends
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="position-relative">
            <img
              alt="Image placeholder"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/center-phone.png"
              class="img-center img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-8 rounded-circle">
                <span class="ti-location-pin"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Locate</h5>
              <p class="mb-0">
                Dynamic map interface to show what is happening near you or
                where you want to go
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-9 rounded-circle">
                <span class="ti-star"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Review</h5>
              <p class="mb-0">
                Share your opinion and read reviews left by other MYLERS
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start mb-5">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-7 rounded-circle">
                <span class="ti-signal"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>AMPLIFY for just $2.99/event</h5>
              <p class="mb-0">
                Get more exposure for your event for just $2.99/event
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <div class="pr-4">
              <div class="icon icon-shape icon-color-8 rounded-circle">
                <span class="ti-light-bulb"></span>
              </div>
            </div>
            <div class="icon-text">
              <h5>Remember</h5>
              <p class="mb-0">
                Set a reminder for an event or put it on your calendar with one
                tap
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center d-flex pt-100">
        <div class="col-md-12 justify-content-center">
          <h2 class="text-center">
            DOWNLOAD OR REGISTER NOW
            <br />
            FIND THOUSANDS OF EVENTS!!!
          </h2>
          <div
            class="download-btn mt-4 animate__animated animate__fadeIn animate__delay-2s d-flex justify-content-center"
          >
            <!-- <a
              href="https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
              class="btn solid-btn mr-3"
              ><span class="ti-apple"></span> App Store</a
            >
            &nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
              class="btn solid-btn mr-3"
              ><span class="ti-android"></span> Google Play</a
            >
            &nbsp;
            <a
              href="https://web.myle.com"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="ti-world"></span> MYLE Web</a
            > -->

            <a
              href="https://web.myle.com/post-event"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Create An Event</a
            >

            <a
              href="https://web.myle.com/login"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Register/Login</a
            >

            <a
              href="https://myle.com/app/MYLEMARKERS"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Get The App
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
};
</script>
