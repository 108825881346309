<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                7 Surprising Places to Eat in Tennessee
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 500"
            >
              Foodies: Follow us to some of the best eateries in The Volunteer
              State. We’re stopping at the Knoxville food truck park, the home
              of Nashville Hot Chicken, and two secret bars (if you can find
              them). No trip would be complete without settling down at
              Chattanooga's “best rib joint,” but we haven’t forgotten our vegan
              friends - there are at least three venues with plant-based menus.
            </p>

            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 500"
            >
              Word to the wise: don't try to lasso a fish in Tennessee. It’s
              illegal to use anything other than a rod, reel and hook to fish.
            </p>

            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 700"
            >
              MYLE is a social activity and event-based smart entertainment
              guide designed to Make Your Life Entertaining. Quickly find events
              nearby and when you travel to hear live music, take the kids out,
              dine and drink with friends, catch a show, and more.
              <a href="#download"> Get the MYLE app</a>
              today and Just MYLE It!
              <br />
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- MANUAL PURCHASE -->
      <section class="">
        <div class="container">
          <div
            class="row justify-content-center align-items-center text-center mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Fun is waiting for you.
                </h3>

                <h4
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="section-heading text-center">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Knoxville
            </h2>
          </div>
          <hr />

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 1.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  1.
                  <a
                    href="http://olibeaoldcity.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OliBea</a
                  >
                  gets its name from the owner’s children, Oliver and Beatrice.
                  Everything is fresh and local, from duck or chicken eggs to
                  sourdough bread. Owner Jeffrey keeps it simple; Olibea serves
                  breakfast and brunch from 7 am to 1 pm.
                  <a
                    href="https://roadfood.com/restaurants/olibea-memorable-breakfast/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Roadfood reviewer Michael Stern</a
                  >
                  raved about the daily special "pork cheek tacos (topped with a
                  brilliantly sunny duck egg)” The restaurant cafe also serves
                  vegan tofu chorizo and vegan tempeh bacon.
                </p>

                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/OliBea/bHYb6lKGakxO9f"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OliBea</a
                  >
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  After your meal, you must
                  <a
                    href="https://oldcityknoxville.org/visit/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit The Old City</a
                  >
                  to learn about its dark past, filled with stories of crime and
                  murder. It once held the region’s highest density of saloons
                  and Knoxville's red-light district. Now it hosts
                  <a
                    href="https://oldcityknoxville.org/dollyfest/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dollyfest</a
                  >. We can’t even…
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 2.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  2.
                  <a
                    href="https://www.knoxfoodpark.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Central Filling Station</a
                  >
                  is a “family-friendly, dog-friendly neighborhood hangout
                  featuring a daily rotation of the city’s best food trucks and
                  craft beverages,” just keep Doggo on the leash. Their on-site
                  bar serves a carefully curated selection of craft beers,
                  ciders, seltzers, and non-alcoholic beverages. Check out their
                  Facebook and Instagram for drink specials and local brews.
                  Most food trucks can accommodate a variety of dietary
                  preferences & restrictions. Brunch and Browse Sundays feature
                  vendors and art for you to graze while you're hanging out at
                  the park.
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Central%20Filling%20Station/koYYNTJk1B271W"
              target="_blank"
              rel="noopener noreferrer"
            >
              Central Filling Station</a
            >
          </p>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 3.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  3.
                  <a
                    href="https://scruffycity.com/bernadettes-cocktails/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bernadette's Cocktails</a
                  >
                  – Officially Bernadette’s Crystal Gardens, this rooftop bar is
                  just one of Scruffy City’s five Knoxville venues. If your
                  visit falls on a rainy day, try one of their jewel-colored
                  cocktails at the interior Amethyst Bar or Quartz Lounge. Full
                  of gemstone wall art (for your selfie), light fixtures, and
                  sparkling bar-top inlay, we can’t do it justice here. Take the
                  <a
                    href="https://www.knoxnews.com/picture-gallery/entertainment/2021/02/16/take-exclusive-look-inside-bernadettes-crystal-gardens-downtown-knoxville/6745257002/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    knoxnews photo tour</a
                  >
                  of this three-level drinks and gems experience owned by the
                  founding members of
                  <a
                    href="https://scruffycity.com/keepknoxvillescruffy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Scruffy City</a
                  >
                  to support all that is local in Knoxville.
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Scruffy%20City%20Bernadette%E2%80%99s%20Crystal%20Gardens/uRMJW17XzqnX1j"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bernadette's Crystal Gardens</a
            >
          </p>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 4.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  4.
                  <a
                    href="https://www.assemblyfoodhall.com/bars-and-eateries/?category=&dietary-need="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Assembly Food Hall</a
                  >
                  is the largest food hall in the nation! Assembly Hall has it
                  all: menus influenced by global and local cultures, craft
                  drinks, breathtaking rooftop vistas, and soaring live music.
                  It's a foodie experience unlike any other. Spend an hour or
                  all day in one of 10 craft bars, 22 unique eateries, nine
                  full-service theme bars, two full-service restaurants and
                  three live performance stages.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Don’t miss the rooftop for live music, sports viewing parties,
                  and dancing. Take in views of iconic Lower Broadway featuring
                  Honky Tonks, the Ryman Auditorium and Bridgestone Arena. Tip:
                  Like to use cash? Use cash to buy a Hall Pass. It’s a
                  rechargeable gift card accepted at 20+ eateries and bars at
                  Assembly Food Hall.
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Assembly%20Food%20Hall/XzHzYpkEDbOAjM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Assembly Food Hall</a
            >
          </p>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 5.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 6.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  BONUS Nashville’s Secret Bars
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://www.noelle-nashville.com/experiences/hidden-bar/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hidden Bar in Downtown Nashville</a
                  >
                  is Noelle’s most closely guarded secret. You'll find a bar
                  underneath boutique hotel, Noelle. Discover the entry at
                  Makeready Libations & Liberation's rear door. Turn left when
                  you reach the two-way mirrors at the end of the hall. Walk
                  through the Storage Closet. Not sure if you’re in the right
                  place? Search for the red light. Bring an open mind and an
                  active imagination.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://redphonebooth.com/nashville/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Red Phone Booth</a
                  >
                  introduces its 1920s speakeasy theme to Nashville at 136 Rosa
                  Parks Blvd. Visitors must get a unique phone number from a
                  member, concierge, or friend to enter. This Prohibition Era
                  experience includes pizza made from a Neapolitan recipe dating
                  back to the 1600s, produced with quality ingredients from
                  Campania, Italy.
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Red%20Phone%20Booth/QEWZjDQ3D5I2aQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Red Phone Booth</a
            >
          </p>

          <div class="section-heading text-center mt-5">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Chattanooga
            </h2>
          </div>
          <hr />

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 7.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  5.
                  <a
                    href="https://www.feedtableandtavern.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FEED Co. Table & Tavern</a
                  >
                  offers fantastic cuisine and beverages in a fun atmosphere on
                  Chattanooga's southside. Co-owners Miguel Morales of 1885
                  Grill and Dustin Choate of Tremont Tavern teamed together in
                  August 2015 to establish a community hub that combines gourmet
                  dining with casual comfort. Morales and Choate wanted to build
                  a communal meeting place for ages 1 to 99.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Their farm-to-table cuisine is made with ingredients from
                  regional farmers and businesses, including Mountain Breeze
                  Farm's (Northeast Alabama) wagyu steaks, free-range chicken
                  from Forward Thinking Farms (Athens, Georgia), and locally
                  grown veggies.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Morales and Choate played on the building's historical roots
                  when naming the restaurant, deciding to keep
                  <a
                    href="https://cityscopemag.com/city-scope/old-brand-new-business/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chattanooga Seed-Feed Supply Company’s</a
                  >
                  original industrial roll doors, exposed brick, and heart pine
                  floors. “The thought was, why not switch it around to be a
                  feed company of sorts for people?
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Feed%20Co%20Table%20&%20Tavern/8NjtcyOe7yesiI"
              target="_blank"
              rel="noopener noreferrer"
            >
              FEED Co. Table & Tavern</a
            >
          </p>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 8.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennesseeBlog/Tennessee_Picture 9.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  6.
                  <a
                    href="https://www.sugarsribs.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sugar's Ribs</a
                  >
                  (<a
                    href="https://www.instagram.com/p/CLcIXgSn_pQ/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    For All BBQ Purists! Voted best BBQ in Chattanooga!</a
                  >
                  ) This is how folks cooked spareribs (not baby back ribs) in
                  the old South - crunchy on the outside and slow-smoked for
                  softness. They have that rich taste purists seek; no boiled
                  ribs or pork butt with a thick sweet sauce here. Sugar's Ribs
                  caters to the most ardent BBQ fans serving meat “the same way
                  that you would cook in your own backyard. We're more like an
                  old-fashioned roadside barbecue joint,” but with a better
                  view. Owners Lawton and Karen Haygood kept the former Pancake
                  Man site on the edge of Missionary Ridge to take advantage of
                  one of Chattanooga's most stunning vistas.
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Sugar%E2%80%99s%20Ribs/862VA1zWzh0mwg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sugar's Ribs</a
            >
          </p>

          <div class="row mt-3 justify-content-center align-items-center">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  BONUS Plant-based and cruelty-free,
                  <a
                    href="https://yourlocalseitanist.square.site/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Your Local Seitanist</a
                  >
                  is Chattanooga’s first vegan deli specializing in comfort
                  foods and baked goods. Get a load of this
                  <a
                    href="https://scontent-lax3-2.cdninstagram.com/v/t51.29350-15/276956726_1203671896706191_482482240810586653_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8ae9d6&_nc_ohc=WVJUBqMwyoEAX-snBwL&_nc_ht=scontent-lax3-2.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-fPdbsgci7QHB3hZVHwxvnGEt-GkFtfkUDaizvcH2xuQ&oe=623DD1E7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    breakfast sammy!</a
                  >
                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  BONUS Chattanooga is home to the original MoonPie. If you
                  can’t find them in town, buy online:
                  <a
                    href="https://moonpie.com/find"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Where To Buy MoonPies</a
                  >
                  and consider attending the annual
                  <a
                    href="https://bellbucklechamber.com/rc-cola-moonpie-festival/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RC Cola – MoonPie Festival</a
                  >.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/MoonPie/jDBm9RC2UFsvoE"
              target="_blank"
              rel="noopener noreferrer"
            >
              MoonPie</a
            >
          </p>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/RC%20Cola-MoonPie%20Festival/vmqYAC0zvGsFH3"
              target="_blank"
              rel="noopener noreferrer"
            >
              RC Cola-MoonPie Festival</a
            >
          </p>

          <div class="row mt-4">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Are we there yet? YES! Next week in Atlanta, Georgia, we’ll
                  take you on a tiny door tour and show you to the best
                  antiques, consignments, and thrifting. Now, all we have to do
                  is decide where to brunch! Join us for 15 Unique Things To Do,
                  Places to Go, and Food to Eat in Atlanta.

                  <br />
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div class="row mt-5">
            <div class="col-md-2 col-lg-2 col-sm-2">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/maureen.png"
                  alt="Saturday Event"
                  style="
                    max-width: 60%;
                    max-height: 60%;
                    width: 60%;
                    height: 60%;
                  "
                />
              </div>
            </div>

            <div
              class="col-md-10 col-lg-10 col-sm-10 align-items-center justify-content-center"
            >
              <div class="align-items-center justify-content-center">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  MAUREEN FRIEND is a freelance writer and visual content
                  creator passionate about seeing you exceed your business goals
                  and shamelessly promoting you and your work; have phone, will
                  travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "7 Surprising Places to Eat in Tennessee",

    meta: [
      {
        name: "description",
        content:
          "Foodies: Follow us to some of the best eateries in The Volunteer State. We’re stopping at the Knoxville food truck park, the home of Nashville Hot Chicken, and two secret bars (if you can find them). No trip would be complete without settling down at Chattanooga's “best rib joint,” but we haven’t forgotten our vegan friends - there are at least three venues with plant-based menus.",
      },

      {
        name: "keywords",
        content:
          "Foodies: Follow us to some of the best eateries in The Volunteer State. We’re stopping at the Knoxville food truck park, the home of Nashville Hot Chicken, and two secret bars (if you can find them). No trip would be complete without settling down at Chattanooga's “best rib joint,” but we haven’t forgotten our vegan friends - there are at least three venues with plant-based menus.",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/tennessee",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
