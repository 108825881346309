<template>
  <footer class="footer-section">
    <!--footer top start-->
    <cookie-law theme="dark-lime">
      <div slot="message">
        This website uses cookies in order to offer you the most relevant
        information. Please accept cookies for optimal performance.
        <router-link to="Cookies" class="themeLiteGreen"
          >Click here for more information</router-link
        >
      </div>
    </cookie-law>
    <div class="footer-top py-5 background-img-2 MYLE-green bg-footer-myle">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-3 mb-3 mb-lg-0">
            <div class="footer-nav-wrap text-white">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.svg"
                alt="footer logo"
                width="120"
                class="img-fluid mb-3"
              />
              <div class="social-list-wrap">
                <ul class="social-list list-inline list-unstyled">
                  <li class="list-inline-item">
                    <a
                      href="https://www.facebook.com/MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Facebook"
                      ><span class="ti-facebook"></span
                    ></a>
                  </li>
                  &nbsp;
                  <li class="list-inline-item">
                    <a
                      href="https://twitter.com/MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Twitter"
                      ><span class="ti-twitter"></span
                    ></a>
                  </li>
                  &nbsp;

                  <li class="list-inline-item">
                    <a
                      href="https://www.instagram.com/mylemarkers/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                      ><span class="ti-instagram"></span
                    ></a>
                  </li>

                  <li class="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/myle/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="LinkedIn"
                      ><span class="ti-linkedin"></span
                    ></a>
                  </li>
                  &nbsp;
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
            <div class="footer-nav-wrap text-white">
              <h5 class="mb-3 text-white">Others Links</h5>
              <ul class="list-unstyled">
                <li class="mb-2">
                  <a href="/office-hours">Office Hours</a>
                </li>
                <li class="mb-2">
                  <a href="/faqs">Frequently Asked Questions </a>
                </li>
                <li class="mb-2">
                  <a href="/myle-ticketing">Ticketing</a>
                </li>
                <li class="mb-2"><a href="/#features">Features</a></li>
                <li class="mb-2"><a href="/#contact">Contact Us</a></li>
                <li class="mb-2">
                  <a href="/orlando-launch">Orlando Launch</a>
                </li>
                <!-- <li class="mb-2"><a href="#">Pricing</a></li> -->
                <li class="mb-2">
                  <router-link to="privacy" v-scroll-to="'#PrivacyPolicy'"
                    >Privacy Policy</router-link
                  >
                </li>
                <li class="mb-2">
                  <router-link to="terms" v-scroll-to="'#Terms&Conditions'">
                    Terms and Conditions</router-link
                  >
                </li>
                <li class="mb-2">
                  <router-link to="cookies" v-scroll-to="'#Cookies'"
                    >Cookies Policy</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
            <div class="footer-nav-wrap text-white">
              <h5 class="mb-3 text-white">Support</h5>
              <ul class="list-unstyled support-list">
                <li class="mb-2 d-flex align-items-center">
                  <span class="ti-email mr-2"></span
                  ><a href="mailto:hello@myle.com"> hello@myle.com</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="footer-nav-wrap text-white">
              <h5 class="mb-3 text-white">Location</h5>
              175 S. 3rd Street, Suite 200
              <br />Columbus, Ohio 43215
              <a
                href="https://goo.gl/maps/HAhdvQ3cVbTrs6fp9"
                target="_blank"
                rel="noopener noreferrer"
                ><img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/map-new.png"
                  alt="location map"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer top end-->

    <!--footer copyright start-->
    <div class="footer-bottom gray-light-bg pt-4 pb-4">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-6 col-lg-5">
            <p class="copyright-text pb-0 mb-0">
              Copyrights © 2014-2024 All rights reserved by
              <a href="#">MYLE</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--footer copyright end-->
  </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "SiteFooter",
  components: { CookieLaw },
};
</script>

<style scoped lang="scss">
.bg-footer-myle {
  background: url("https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/footer-myle-bg.jpg")
    no-repeat center top / cover !important;
}
</style>
