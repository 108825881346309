<template>
  <div>
    <nav-bar />
    <div class="main">
      <section
        class="hero-section background-img"
        style="
          background: url('https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/video-fb.jpg')
            no-repeat center center / cover;
        "
      >
        <div class="video-section-wrap">
          <div class="background-video-overly pt-70">
            <div id="heroPlayer">
              <video-background
                :muted="true"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/OrlandoLaunchRecap.mp4"
                poster="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/video-fb.jpg"
                style="max-height: 100%; height: 100vh"
              >
              </video-background>
            </div>

            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-8 col-lg-8">
                  <div
                    class="hero-content-left text-white mt-5 animate__animated animate__fadeIn animate__delay-1s ptb-50"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h2 class="text-center text-white">THANK YOU ORLANDO</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="position: relative">
        <div class="section-heading text-center mt-3">
          <p>
            <span class="lead d-block">
              ORLANDO…We would like to THANK YOU for your participation during
              the MYLE Orlando Launch activities last week. We had an amazing
              time and are extremely excited about the level of support and
              energy that we received from you.</span
            ><br />
            <span class="lead d-block"
              >We hope that you enjoy the recap videos and photos below.</span
            >
          </p>
        </div>
        <div class="section-heading text-center mt-5">
          <h2>Orlando Lunch and Learn</h2>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6 col-lg-6 d-flex align-items-center">
            <div class="mt-5">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/friday.png"
                alt="Friday Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 rotateDiv d-flex justify-content-center"
          >
            <div class="mt-5">
              <h5 style="line-height: 25px">
                The MYLE Orlando Lunch and Learn was held at The LAB Orlando
                (1016 W. Church St.) on Friday, November 5th.
              </h5>
              <p>
                During this event, we conducted a live demo and shared free and
                effective ways to:
                <br />
                <span class="ml-1"
                  ><span class="font-weight-bold selfBullet">•</span> Promote
                  your events or business with MYLE</span
                ><br />
                <span class="ml-1"
                  ><span class="font-weight-bold selfBullet">•</span> Expand
                  your marketing efforts</span
                ><br />
                <span class="ml-1"
                  ><span class="font-weight-bold selfBullet">•</span> Capture
                  comprehensive customer data insights to help you engage the
                  right audience </span
                ><br /><br />
                MYLE - Make Your Life Entertaining is the World’s Most Dynamic
                social entertainment discovery and promotion platform.
                <br /><br />
                We look forward to having you use MYLE as an all-new way to
                engage visitors, residents and Make Your Life Entertaining!
              </p>
              <div
                class="download-btn text-center animate__animated animate__fadeIn mb-5"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn solid-btn mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn solid-btn mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn solid-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">Recap of Lunch and Learn</h5>
              </div>
            </div>
          </div>
        </div>
        <video-embed
          css="embed-responsive-16by9"
          src="https://www.youtube.com/watch?v=q2io8WvsnzM"
        ></video-embed>

        <!-- <section
          class="video-promo ptb-50 background-img banner-1-bg background-img-2 MYLE-green bg-footer-myle mt-5"
          style="border: 1px solid #eff2f7; border-radius: 1rem ;"
        >
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="video-promo-content mt-4 text-center">
                <a
                  href="#"
                  @click="showYouTubeModal"
                  class="popup-youtube video-play-icon d-inline-block"
                  ><span class="ti-control-play"></span
                ></a>
                <h5 class="mt-4 text-white">
                  Watch Recap of the Lunch and Learn
                </h5>
              </div>
            </div>
          </div>
          <YTModal
            url="https://www.youtube.com/watch?v=q2io8WvsnzM"
            v-if="showModal"
            @close="showModal = false"
          >
            Watch Recap of the Lunch and Learn
          </YTModal>
        </section> -->

        <div
          class="row w-100 m-0 mt-5 text-center promo-section MYLE-lite-green"
        >
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">
                  Memories of Lunch and Learn
                </h5>
              </div>
            </div>
          </div>
        </div>
        <section class="">
          <vue-flux
            :options="vfOptions"
            :images="vfLaunchLearnImages"
            :transitions="vfTransitions"
            ref="slider"
          >
            <template v-slot:preloader>
              <flux-preloader />
            </template>
            <template v-slot:controls>
              <flux-controls />
            </template>
          </vue-flux>
        </section>

        <div class="section-heading text-center mt-5">
          <h2 class="">Orlando Reception and Launch Party</h2>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6 col-lg-6 d-flex align-items-center mb-5">
            <div class="mt-5">
              <h5 style="line-height: 25px">
                The MYLE Orlando Reception and Launch Party was held at Dovecote
                Restaurant/Courtyard390 (390 N. Orange Ave. Suite 110) on
                Saturday, November 6th.
              </h5>
              <p>
                During this very special MYLE Launch Event, we introduced the
                world’s most dynamic entertainment platform to Orlando!
                <br /><br />
                This event consisted of a networking reception and party
                including food, drinks, live music, live art, Orlando Socialites
                and a night of entertainment, MYLE STYLE!
                <br /><br />
                From the “red carpet” experience, to the DoveCote inspired
                3-course dinner and energetic performance by Nathan Mitchell and
                band to the sounds of DJ P Funk. It was a night to remember.
                <br />
              </p>

              <div
                class="download-btn text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn solid-btn mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn solid-btn mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn solid-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 d-flex justify-content-center align-items-center"
          >
            <div class="mt-5">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/sat.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
        </div>

        <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">Recap of Launch Party</h5>
              </div>
            </div>
          </div>
        </div>

        <video-embed
          css="embed-responsive-16by9"
          src="https://www.youtube.com/watch?v=wpWNREz8iqM"
        ></video-embed>

        <!-- <section
          class="video-promo ptb-50 background-img banner-1-bg background-img-2 MYLE-green bg-footer-myle mt-5"
          style="border: 1px solid #eff2f7; border-radius: 1rem ;"
        >
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="video-promo-content mt-4 text-center">
                

                <a
                  href="#"
                  @click="showYouTubeModal"
                  class="popup-youtube video-play-icon d-inline-block"
                  ><span class="ti-control-play"></span
                ></a>
                <h5 class="mt-4 text-white">
                  Watch Recap of the Launch Party
                </h5>
              </div>
            </div>
          </div>
          <YTModal
            url="https://www.youtube.com/watch?v=wpWNREz8iqM"
            v-if="showModal"
            @close="showModal = false"
          >
            Watch Recap of the Launch Party
          </YTModal>
        </section> -->

        <div
          class="row w-100 m-0 mt-5 text-center promo-section MYLE-lite-green"
        >
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">Memories of Launch Party</h5>
              </div>
            </div>
          </div>
        </div>
        <section class="">
          <vue-flux
            :options="vfOptions"
            :images="vfLaunchEventsImages"
            :transitions="vfTransitions"
            ref="slider"
          >
            <template v-slot:preloader>
              <flux-preloader />
            </template>
            <template v-slot:controls>
              <flux-controls />
            </template>
          </vue-flux>
        </section>

        <div class="section-heading text-center mt-5">
          <h2>ON TO NEW BEGINNINGS</h2>
          <p>
            <span class="lead d-block">
              We look forward to working with you in the very near future to
              help build the MYLE brand in Orlando and to truly help Make Your
              Life Entertaining. </span
            ><br />
            <span class="lead d-block"
              >Be sure to spread the word with family, friends, coworkers and
              visitors. Oh yeah, if you host an event, we’d all love for you to
              post your event.
            </span>
            <br />
            <span class="lead d-block"
              >Just remember, when looking for Things to do, Places to go and
              Food to eat… #justMYLEit.
            </span>
          </p>
        </div>
      </div>

      <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2 class="text-center text-white">
                Download today and try it for yourself
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn google-play-btn mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn google-play-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
// import YTModal from "../../components/YTModal";
import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
    // YTModal,
    VueFlux,
    FluxPreloader,
    FluxControls,
  },
  data: function () {
    return {
      vfOptions: {
        autoplay: true,
      },
      vfLaunchLearnImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2124.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2126.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2132.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2137.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2138.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2144.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2145.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2147.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2151.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2154.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2161.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2165.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2167.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2169.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2172.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2177.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2182.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2185.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2189.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2193.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2196.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2199.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2206.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2207.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2217.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2219.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2224.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2226.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2227.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2229.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2232.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2235.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2236.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2240.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2246.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2247.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2249.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2251.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2254.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2260.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2265.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2267.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2270.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2276.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2278.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2282.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2285.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2288.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2290.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2291.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2295.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2299.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2313.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2321.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2323.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2328.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2335.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2337.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2338.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2344.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2351.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2356.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2361.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2366.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2373.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2374.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2380.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2388.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2398.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2410.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2418.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2420.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2423.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2424.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2430.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2435.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2446.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2462.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2464.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2468.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2471.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2473.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2482.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2486.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Lunch%20and%20Learn/Deon%20Cooper%20-%20LYK_2487.jpg",
      ],
      vfLaunchEventsImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3222.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3223.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3227.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3237.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3239.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3241.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3245.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3250.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3252.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3256.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3260.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3264.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3269.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3270.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3275.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3278.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3279.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3288.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3291.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3296.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3300.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3303.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3306.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3309.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3311.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3316.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3317.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3320.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3324.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3330.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3333.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3337.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3342.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3344.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3347.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3351.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3357.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3363.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3368.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3373.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3374.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3378.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3381.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3384.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3388.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3390.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3392.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3398.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3399.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3403.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3406.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3407.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3411.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3413.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3418.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3421.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3425.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3429.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3433.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3438.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3442.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3445.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3447.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3451.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3458.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3463.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3466.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3470.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3474.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3477.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3479.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3483.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3486.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3488.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3494.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3498.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3503.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3504.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3508.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3510.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3514.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3516.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3519.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3522.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3526.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3532.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3536.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3541.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3546.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3549.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3553.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3557.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3569.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3571.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3575.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3579.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3582.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3585.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3593.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3598.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3599.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3601.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3605.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3611.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3615.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3619.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3623.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3626.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3629.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3630.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3638.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3643.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3645.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3649.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3652.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3653.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3656.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3659.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3664.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3669.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3675.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3680.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3684.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3688.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3690.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3693.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3697.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3701.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3704.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3705.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3712.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3714.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/MYLE%20Launch%20Weekend/Launch%20Event/Deon%20-%20LYK_3719.jpg",
      ],
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],

      showModal: false,
    };
  },
  methods: {
    showYouTubeModal: function (e) {
      e.preventDefault();
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
.container {
  position: absolute;
}
.bg-footer-myle {
  background: url("https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/footer-myle-bg.jpg")
    no-repeat center top / cover !important;
}
</style>
