<template>
  <section class="download-section pt-50 background-img gray-light-bg">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-8">
          <div class="download-content">
            <h2>
              Unlimited discoveries of events and experiences around the globe
            </h2>
            <p class="lead">Get There – Order a rideshare right from the app</p>

            <div class="download-btn">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uber.png"
                class="img-fluid w-25 mr-5"
                alt="screenshots"
              />
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/lyft.png"
                class="img-fluid w-13"
                alt="screenshots"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="download-img">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/uberhand.png"
              alt="download"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Download",
};
</script>
