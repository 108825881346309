<template>
  <div style="background-color: black">
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <!-- <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BandoReunion_V6.jpg"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 800;
                font-size: 3rem;
                color: white;
              "
            >
              Welcome<br />
              Reuniting Warriors Gathering 2024
            </p>
          </div>

          <div
            style="background-color: lightgrey; height: 0.1rem"
            class="mt-5"
          ></div>

          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: white;
            "
          >
            12 <br />
            Oct
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/reuniting-warriors-gathering-2024"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: white;
              "
            >
              Reuniting Warriors Gathering - 2024
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: white;
              "
            >
              October 12, 2024<br />
              3:00pm - 6:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/reuniting-warriors-gathering-2024"
                  class="btn solid-btn-BandoReunion mb-1 text-center"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 1.2rem;
                  "
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/reuniting-warriors-gathering-2024"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BandoReunion_V6.jpg"
                alt="Saturday Event"
                class="w-60 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>
      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: white;
            "
          >
            12 <br />
            Oct
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <!-- <a
            href="https://myle.com/reuniting-warriors-gathering-2024-souvenir-program"
            target="_self"
          > -->
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 500;
              font-size: 24px;
              color: white;
            "
          >
            Souvenir Program
          </p>
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 300;
              font-size: 18px;
              color: white;
            "
          >
            October 12, 2024<br />
            3:00pm - 6:00pm
          </p>
          <div class="section-heading mb-2">
            <div class="text-center">
              <a
                href="https://myle.com/reuniting-warriors-gathering-2024-souvenir-program"
                class="btn solid-btn-BandoReunion mb-1 text-center bg-disable"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  font-size: 1.2rem;
                "
                target="_self"
                :disabled="true"
                >Sold Out
              </a>
            </div>
          </div>
          <!-- </a> -->
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <!-- <a
            href="https://myle.com/reuniting-warriors-gathering-2024-souvenir-program"
            target="_self"
          > -->
          <div class="justify-content-center d-flex">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BandoReunion_V6.jpg"
              alt="Saturday Event"
              class="w-60 img-fluid"
            />
          </div>
          <!-- </a> -->
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 300;
              font-size: 22px;
              color: white;
            "
          >
            12 <br />
            Oct
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/reuniting-warriors-gathering-2024-donations"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: white;
              "
            >
              Fundraiser: “Boxing For Books”
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: white;
              "
            >
              October 12, 2024
              <br />
              3:00pm - 6:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/reuniting-warriors-gathering-2024-donations"
                  class="btn solid-btn-BandoReunion mb-1 text-center"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: 700;
                    font-size: 1.2rem;
                  "
                  target="_self"
                  >Donate Now
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/reuniting-warriors-gathering-2024-souvenir-program"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Sam_Jones_Headshot.png"
                alt="Saturday Event"
                class="w-50 img-fluid"
              />
            </div>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: white;
              "
            >
              Sayagyi / Boxing coach
            </p>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <br />
      <br />
      <div class="row" style="margin: auto; background: black">
        <div class="col-md-2 col-md-2"></div>
        <div class="col-md-8 col-md-8">
          <div class="text-center">
            <video-embed
              css="embed-responsive-16by9"
              src="https://www.youtube.com/watch?v=1zwnDpLqi-o"
            ></video-embed>
          </div>
        </div>
        <div class="col-md-2 col-md-2"></div>
      </div>

      <br />
    </div>

    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12 mt-5">
        <div class="mt-3">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 500;
                font-size: 15px;
                color: white;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
    };
  },
  components: {
    // NavBar,
    // SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Reuniting Warriors Gathering 2024 - Collection",

    meta: [
      {
        name: "description",
        content: "MYLE - Reuniting Warriors Gathering 2024 - Collection",
      },

      {
        name: "keywords",
        content: "MYLE - Reuniting Warriors Gathering 2024 - Collection",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/reuniting-warriors-gathering-2024-collection",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
