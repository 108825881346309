<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Number of Section(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  background: #d49b0d;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected].ticketCode == 'tk_ktWG5PPojiUDOA0'
                    ? !redeemData
                    : false) ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  background: #d49b0d;
                "
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_The_Bison_Rattler_Boogie_Friday.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #d49b0d"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: black;
                "
              >
                The Bison Rattler Boogie @ Opium Lounge
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                Friday December 15, 2023 <br />
                9:00pm - 3:00am
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                <a
                  href="https://maps.app.goo.gl/GoEXgz2FB4ibPP8W9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: black"
                >
                  Opium Atlanta - 119 Central Ave SW, Atlanta, GA
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Welcome to the official Bison and Rattler Alumni & Friends
                celebration powered by Too Tony Salano, Shan D Ridley, Alan
                Williams, Alondeia Williams, LaKeem Dwight and Don Cheatham.

                <br /><br />

                Soundscapes will be provided by DJ Premonition and DJ Spoon with
                HU and FAMU Homecoming energy.

                <br /><br />

                Food, spirits and table reservations are available. From Bloody
                Beta to Beta Nu, from Alpha Chapter to the BADST, from Bloody Xi
                to Alpha Xi, from the Mother Pearl to the Mighty Melodic, this
                HU/FAMUly Homecoming style event is for YOU!!!!!!

                <br /><br />

                <span class="bolded">Event Details:</span><br />
                📅 DATE: Friday December 15, 2023<br />
                ⏰ Time: 9pm-3am<br />
                📍 Location: Opium Atlanta - 119 Central Ave SW, Atlanta, GA

                <br />
                🚗 Valet Parking Available <br />
                🎵 Soundscapes by DJ Spoon & DJ Premonition Howard Alumni DJs<br />

                <br />
                ⚡️ Secure your section now for this EPIC event! Limited
                sections available, so get yours today. ⚡️
                <br />
                <br />

                <span class="bolded">GENERAL ADMISSION PRICING </span>
                <br />

                Complimentary Admission - First 25 people get in Free before
                9:30pm<br />
                Bison Early Bird - $18.67 (Limited Offer)<br />
                Rattler Early Bird - $18.87 (Limited Offer)<br />
                General Admission - $20 (Limited Offer)<br />
                Day of Event - $30
                <br />
                <br />

                <span class="bolded">
                  GENERAL SECTION PRICING (*Requires Bottle Purchase)
                </span>
                <br />
                Premium Sections - Seats 3 ($140 + 1 bottle Min)<br />
                Premium Sections - Seats 4 ($185 + 1 bottle Min)<br />
                Premium Sections - Seats 5 ($230 + 1 bottle Min)<br />
                Premium Sections - Seats 8 ($365 + 1 bottle Min)<br />
                Premium Sections - Seats 10 ($450 + 2 bottle Min)
                <br />
                <br />

                <span class="bolded"
                  >DAY OF EVENT SECTION PRICING (*Requires Bottle Purchase)
                </span>
                <br />
                Premium Sections - Seats 3 ($155 + 1 bottle Min)<br />
                Premium Sections - Seats 4 ($205 + 1 bottle Min)<br />
                Premium Sections - Seats 5 ($255 + 1 bottle Min)<br />
                Premium Sections - Seats 8 ($405 + 1 bottle Min)<br />
                Premium Sections - Seats 10 ($500 + 2 bottle Min)
                <br />
                <br />

                <span class="bolded"
                  >VIP SECTIONS (One (1) Bottle Included)</span
                >
                <br />
                Bison Rattler VIP 1 (Sections S7/S9) - 20 Guest (2000 + 1 Comp.
                Bottle)<br />
                Bison Rattler VIP 2 (Sections S8/S11) - 20 Guest (2000 + 1 Comp.
                Bottle)
                <br />
                <br />

                <span class="bolded">Bottle Pricing:</span> General Bottle
                Pricing - $250, Dusse - $300, 1942 - $800
                <br />
                <span class="bolded">Important:</span> Reserved sections must be
                occupied by a member of the party by 11:30pm to avoid forfeiting
                the reservation.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section ends -->

      <div class="section-heading text-center mt-5">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          Seating Map
        </h2>
      </div>

      <!-- Map starts -->
      <div
        id="d2"
        style="max-width: 650px; margin: auto; margin-top: 50px"
      ></div>
      <!-- Map ends -->

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #c07f00;
                      color: #c07f00;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Available For Purchase
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 32px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="background: #d49b0d; color: black"
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                        background: #d49b0d;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Sold Out"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <br />
        <div class="section-heading text-center">
          <h2
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 32px;
              color: black;
            "
          >
            Bottle List
          </h2>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-8 col-md-6 justify-content-center d-flex align-items-center"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bottle_list_Saloon_Bottle.jpg"
                alt="Slow Jam Social Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
        </div> -->

      <!-- <br />
        <br />
        <div class="section-heading text-center">
          <h2
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 32px;
              color: black;
            "
          >
            The Skegee Ball & Parlay Homecoming Soiree
          </h2>
        </div> -->

      <!-- <div class="row justify-content-center">
          <div
            class="col-8 col-md-6 justify-content-center d-flex align-items-center"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_TheSkegeeBallParlayHomecomingSoiree.jpeg"
                alt="Slow Jam Social Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
        </div> -->
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #d49b0d">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center">Download today and try it for yourself</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mock_BisonRattlerBoogieFriday.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          For a seamless ticketing and check-in experience, we've partnered with
          MYLE - Make Your Life Entertaining! MYLE is an “HBCU Alum-Owned"
          all-in-one AI-based event and entertainment platform.

          <br />
          <br />

          Download the MYLE Events App via
          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or find it in your iOS or Google Play Store under MYLE Events. Don't
          forget to use referral code “TonySalanoEventures" during account
          set-up!

          <br />
          <br />

          The MYLE App offers convenient transportation options, integrating
          Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your one-stop
          solution for a hassle-free travel experience.

          <br />
          <br />
          Note: Your tickets will be available on the MYLE App in the
          Account>Tickets section after purchase. You can easily access them via
          email (check spam if not received) or upload them to your phone wallet
          for quick access into the event.

          <br />
          <br />
          🌟 We can't wait to see you 🌟

          <br />
          <br />
          Parking: In addition to valet, parking is available:
          <br />
          - Open street parking<br />
          - Underground Parking Garage<br />
          - Adjacent Parking Lots (with attendants)
          <br /><br />

          Note: Once you download the MYLE app, quickly find the event with any
          of these hashtags:

          <br />
          #BisonRattlerBoogie #CelebrationBowl23 #Bison #Rattler
          #TonySalanoEventures <br />
          #JustMYLEit #MakeYourLifeEntertaining #MYLE

          <br />
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../components/confirmationPopup";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      metaTags: [],
      metaTitle: "",
      showsuccess: false,
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        // "tk_DU3RpfgmyQspvzz",
        // "tk_fuaUwDXbQDEAIuL",
        // "tk_QQvYLoX0u90cO16",
        // "tk_Sd3ucMOF4lhscD6",
        "tk_zuJN3GA70AUcbVL",
        "tk_4xWCRci51BL5ilj",
        "tk_ru7jl4fxp6Ziki3",
        "tk_0xbZd5QDoX7eiCY",
        "tk_xaokCJ9HOoRmtA5",
        "tk_CDzzfigcfmwlypT",
        "tk_rY7Goed1LyC5kJx",
        "tk_lvmwXs58ytfxa5A",
        // "tk_UhPXWFeX5aVmSWa",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_DU3RpfgmyQspvzz: {
        //   messages: ["Ticket Price: $0"],
        // },

        // tk_Sd3ucMOF4lhscD6: {
        //   messages: ["Ticket Price: $20", "Processing fee of $2.98"],
        // },

        tk_zuJN3GA70AUcbVL: {
          messages: ["Ticket Price: $30", "Processing fee of $3.65"],
        },

        // tk_fuaUwDXbQDEAIuL: {
        //   messages: ["Ticket Price: $18.67", "Processing fee of $2.89"],
        // },

        // tk_QQvYLoX0u90cO16: {
        //   messages: ["Ticket Price: $18.87", "Processing fee of $2.91"],
        // },

        tk_4xWCRci51BL5ilj: {
          messages: ["Ticket Price: $140", "Processing fee of $11.03"],
        },

        tk_ru7jl4fxp6Ziki3: {
          messages: ["Ticket Price: $185", "Processing fee of $14.05"],
        },

        tk_0xbZd5QDoX7eiCY: {
          messages: ["Ticket Price: $230", "Processing fee of $17.06"],
        },

        tk_xaokCJ9HOoRmtA5: {
          messages: ["Ticket Price: $365", "Processing fee of $26.12"],
        },

        tk_CDzzfigcfmwlypT: {
          messages: ["Ticket Price: $450", "Processing fee of $31.82"],
        },

        tk_rY7Goed1LyC5kJx: {
          messages: ["Ticket Price: $2000", "Processing fee of $135.78"],
        },

        tk_lvmwXs58ytfxa5A: {
          messages: ["Ticket Price: $2000", "Processing fee of $135.78"],
        },
        // tk_UhPXWFeX5aVmSWa: {
        //   messages: ["Ticket Price: $2000", "Processing fee of $135.78"],
        // },
      },

      priceIds: {
        // tk_DU3RpfgmyQspvzz: "price_1OGrFED0vTZ4QB9cNzwS53YP",
        // tk_fuaUwDXbQDEAIuL: "price_1OGrFFD0vTZ4QB9ckaMhh4B3",
        // tk_QQvYLoX0u90cO16: "price_1OGrFFD0vTZ4QB9cW0AKbfNY",
        // tk_Sd3ucMOF4lhscD6: "price_1OGrEYD0vTZ4QB9cRcJJAkPP",
        tk_zuJN3GA70AUcbVL: "price_1ONgQoD0vTZ4QB9cwDa0CKGz",
        tk_4xWCRci51BL5ilj: "price_1OGr9OD0vTZ4QB9cxggdfvJb",
        tk_ru7jl4fxp6Ziki3: "price_1OGr9ND0vTZ4QB9cJV8mbwhv",
        tk_0xbZd5QDoX7eiCY: "price_1OGr9OD0vTZ4QB9cH8nBksKF",
        tk_xaokCJ9HOoRmtA5: "price_1OGr5QD0vTZ4QB9cAeYPQP6W",
        tk_CDzzfigcfmwlypT: "price_1OGr9ND0vTZ4QB9cP2TmJhM3",
        tk_rY7Goed1LyC5kJx: "price_1OGr5ND0vTZ4QB9c292AvnEj",
        tk_lvmwXs58ytfxa5A: "price_1OGr5MD0vTZ4QB9cghby6aB4",
        // tk_UhPXWFeX5aVmSWa: "price_1OGr9MD0vTZ4QB9cuJWR9RdK",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    getMetaTagsInfo() {
      const vm = this;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/fetch_meta_info",
        data: {
          eid: "zC6kJyNBrL9ARR",
          src: "WEB_CUSTOM",
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.metaTags = re.data.Result;
            const titleTags = vm.metaTags.find((tag) => tag.name == "name");
            vm.metaTitle = titleTags.content;
          }
        })
        .catch((error) => {
          console.error("There is an error!", error);
        });
    },

    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    drawseatsd2(list1) {
      // console.log(list1);
      const dd = {};
      list1.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          const ww = l1.table.split(" ");
          if (l.ticketCode == "tk_lvmwXs58ytfxa5A") {
            dd["S8"] = true;
            dd["S11"] = true;
          } else if (l.ticketCode == "tk_ktWG5PPojiUDOA0") {
            dd["S10"] = true;
          } else if (l.ticketCode == "tk_rY7Goed1LyC5kJx") {
            dd["S7"] = true;
            dd["S9"] = true;
          } else if (l1.table == "Section " + ww[ww.length - 1]) {
            dd[ww[ww.length - 1]] = true;
          }
        });
      });

      // console.log(Object.keys(dd));

      const svg = window.d3
        .select("#d2")
        .append("svg")
        .attr("width", "650px")
        .attr("height", "900px");

      const list = [
        [
          null,
          null,
          null,
          null,
          null,
          {
            label: "27",
            width: 50,
            height: 40,
          },
        ],
        [
          null,
          null,
          {
            label: "30",
            width: 50,
            height: 40,
          },
          {
            label: "29",
            width: 50,
            height: 40,
          },
          {
            label: "28",
            width: 50,
            height: 40,
          },
          null,
          null,
          {
            label: "26",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "25",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          null,
          {
            label: "21",
            width: 80,
            height: 50,
          },
          {
            label: "22",
            width: 80,
            height: 50,
          },
          {
            label: "23",
            width: 80,
            height: 50,
          },
          {
            label: "24",
            width: 80,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          {
            label: "17",
            width: 50,
            height: 50,
          },
          {
            label: "18",
            width: 50,
            height: 50,
          },
          {
            label: "19",
            width: 50,
            height: 50,
          },
          {
            label: "20",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          {
            label: "16",
            width: 55,
            height: 50,
            dist: 20,
          },
          {
            label: "31",
            width: 40,
            height: 70,
          },
          {
            label: "S11",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "S8",
            width: 70,
            height: 50,
          },
          {
            label: "S6",
            width: 70,
            height: 50,
          },
          {
            label: "S2",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          {
            label: "15",
            width: 55,
            height: 50,
            dist: 20,
          },
          null,
          null,
          {
            label: "S10",
            width: 40,
            height: 70,
          },
          {
            label: "S5",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          {
            label: "14",
            width: 55,
            height: 50,
            dist: 20,
          },
          {
            label: "32",
            width: 40,
            height: 70,
          },
          {
            label: "S9",
            width: 40,
            height: 70,
          },
          {
            label: "S7",
            width: 80,
            height: 50,
          },
          {
            label: "S4",
            width: 80,
            height: 50,
            dist: 10,
          },
          {
            label: "S1",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          {
            label: "11B",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "11",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "12",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "13",
            width: 40,
            height: 70,
            dist: 10,
          },
        ],
        [
          {
            label: "3",
            width: 40,
            height: 40,
            dist: 20,
          },
          {
            label: "4",
            width: 40,
            height: 40,
            dist: 20,
          },
          null,
          null,
          {
            label: "10",
            width: 70,
            height: 50,
            dist: 20,
          },
          {
            label: "8",
            width: 70,
            height: 50,
            dist: 10,
          },
          {
            label: "6",
            width: 50,
            height: 50,
            dist: 10,
          },
        ],
        [
          {
            label: "2",
            width: 40,
            height: 40,
            dist: 20,
          },
          {
            label: "1",
            width: 40,
            height: 40,
            dist: 20,
          },
          null,
          null,
          {
            label: "9",
            width: 70,
            height: 50,
            dist: 20,
          },
          {
            label: "7",
            width: 70,
            height: 50,
            dist: 10,
          },
          {
            label: "5",
            width: 50,
            height: 50,
            dist: 10,
          },
        ],
      ];

      let x = 0;
      let y = 20;
      // console.log("fdfdf");
      list.forEach((row) => {
        row.forEach((item) => {
          if (item) {
            svg
              .append("rect")
              .attr("width", item.width)
              .attr("height", item.height)
              .attr("fill", dd[item.label] ? "#d49b0d" : "#d3d3d3")
              .attr("stroke", "black")
              .attr(
                "transform",
                "translate(" +
                  (x +
                    (item.label == "16" || item.label == "15" ? 35 : 0) -
                    (item.x || 0)) +
                  "," +
                  (y -
                    (item.label == "16" || item.label == "15" ? 15 : 0) -
                    (item.y || 0)) +
                  ") rotate(" +
                  (item.label == "16" || item.label == "15" ? 45 : 0) +
                  ")"
              );

            svg
              .append("text")
              .attr(
                "x",
                x +
                  (item.width == 40 && item.label.length >= 2
                    ? item.label.length >= 3
                      ? item.width - 33
                      : item.width / 4
                    : item.width / 2.5)
              )
              .attr("y", y + item.height / 1.7)
              .text(item.label)
              .attr("fill", "none")
              .attr("stroke", "black");
          }

          x +=
            (item ? item.width : 50) + 10 + (item && item.dist ? item.dist : 0);
          // console.log(item, x);
        });

        x = 0;
        y += 80;
      });
    },
    uniq(list) {
      const e = {};

      list.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      // this.availSeating = Object.keys(e)[0];
      return Object.keys(e);
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[10],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd21(list1) {
      // console.log(list1);
      const dd = {};
      list1.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          const ww = l1.table.split(" ");
          // console.log(l1.table);

          if (l.ticketCode == "tk_kdIUic8KCPmAYV9") {
            if (
              ww[ww.length - 1] == "6" ||
              ww[ww.length - 1] == "7" ||
              ww[ww.length - 1] == "8" ||
              ww[ww.length - 1] == "9"
            ) {
              dd[ww[ww.length - 1] + "A"] = true;
              dd[ww[ww.length - 1] + "B"] = true;
            } else {
              dd[ww[ww.length - 1]] = true;
            }
          } else if (l.ticketCode == "tk_Bw66u6airWxDtYt") {
            dd["17"] = true;
            dd["18"] = true;
            dd["19"] = true;
          } else if (l.ticketCode == "tk_KO35I2TSUFCh1NN") {
            dd["S1"] = true;
            dd["S2"] = true;
          } else if (l.ticketCode == "tk_nIMETmnv52qI71L") {
            dd["S3"] = true;
            dd["S4"] = true;
          } else if (l1.table == "Section " + ww[ww.length - 1]) {
            dd[ww[ww.length - 1]] = true;
          }
        });
      });

      // console.log(Object.keys(dd));
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "650px")
        .attr("height", "500px");
      const list = [
        [
          {
            label: "Bar",
            width: 80,
            height: 40,
          },
          {
            label: "1",
            width: 50,
            height: 50,
          },
          {
            label: "2",
            width: 50,
            height: 50,
          },
          {
            label: "Floor",
            width: 80,
            height: 40,
          },
          {
            label: "3",
            width: 50,
            height: 50,
          },
          {
            label: "4",
            width: 50,
            height: 50,
          },
          null,
          null,
          {
            label: "S3",
            width: 50,
            height: 50,
            x: 20,
          },
          {
            label: "DJ",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "Stage",
            width: 80,
            height: 30,
          },
          {
            label: "S4",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "S2",
            width: 50,
            height: 50,
          },
          null,
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7B",
            width: 50,
            height: 50,
          },
          {
            label: "8B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "11",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "Stage",
            width: 80,
            height: 50,
          },
          {
            label: "5",
            width: 20,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7A",
            width: 50,
            height: 50,
          },
          {
            label: "8A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "10",
            width: 50,
            height: 50,
          },
          {
            label: "12",
            width: 50,
            height: 50,
            y: 15,
          },
          {
            label: "13",
            width: 50,
            height: 50,
            y: -5,
          },
        ],

        [
          {
            label: "S1",
            width: 50,
            height: 50,
          },
          null,
          null,
          null,
          null,
          {
            label: "VIP",
            width: 80,
            height: 50,
          },
          null,
          null,
          null,
          {
            label: "Floor",
            width: 80,
            height: 50,
          },
        ],

        [
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "17",
            width: 50,
            height: 50,
            y: 10,
          },
          {
            label: "14",
            width: 50,
            height: 50,
          },
          {
            label: "15",
            width: 50,
            height: 50,
          },
          {
            label: "16",
            width: 80,
            height: 50,
          },
        ],

        [
          null,
          null,
          {
            label: "Owner",
            width: 90,
            height: 50,
          },
          {
            label: "19",
            width: 50,
            height: 50,
          },
          {
            label: "18",
            width: 50,
            height: 50,
          },
          {
            label: "17",
            width: 50,
            height: 50,
          },
        ],
      ];

      let x = 0;
      let y = 20;
      list.forEach((row) => {
        row.forEach((item) => {
          if (item) {
            svg
              .append("rect")
              .attr("width", item.width)
              .attr("height", item.height)
              .attr(
                "transform",
                "translate(" +
                  (x +
                    (item.label == "S2" || item.label == "S1" ? 35 : 0) -
                    (item.x || 0)) +
                  "," +
                  (y -
                    (item.label == "S2" || item.label == "S1" ? 15 : 0) -
                    (item.y || 0)) +
                  ") rotate(" +
                  (item.label == "S2" || item.label == "S1" ? 45 : 0) +
                  ")"
              )
              //  .attr("x", x - (item.x || 0))
              //  .attr("y", y - (item.y || 0))
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#d49b0d"
                    : "none"
                  : item.fill
              )
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#d49b0d"
                    : ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                        item.label
                      )
                    ? "none"
                    : "lightgrey"
                  : item.fill
              )
              .attr(
                "stroke",
                ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                  item.label
                )
                  ? "none"
                  : "black"
              );
            //      .style("transform", (item.label == 'S2' || item.label == 'S1') ? "translate(50deg, 50deg) rotate(45deg)" : "none");
            svg
              .append("text")
              .attr(
                "x",
                x +
                  (item.label == "S2" || item.label == "S1" ? 10 : 0) -
                  (item.x || 0) +
                  item.width / 3
              )
              .attr("y", y - (item.y || 0) + item.height / 1.7)
              .text(item.label)
              .attr("fill", "none")
              .attr("stroke", "black");
          }

          x += (item ? item.width : 50) + 10;
        });

        x = 0;
        y += 60;
      });
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      // console.log("wwwwwwww  === ");
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }
      this.redeemData = null;
      this.redeemPriceId = "";
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";

      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      const e = {};

      this.TicketsResult[idx].availableSeating.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      this.availSeating = Object.keys(e)[0];

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating && vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_CV9FypVc9e4SYFI") {
        const addionalSelection = [];
        this.selectionList.forEach((r) => {
          addionalSelection.push(r.alias);
        });
        payloadToPush.totalTickets = addionalSelection.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: addionalSelection,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredTheBisonRattlerBoogieFridayOpiumLounge_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            // tk_DU3RpfgmyQspvzz: null,
            // tk_fuaUwDXbQDEAIuL: null,
            // tk_QQvYLoX0u90cO16: null,
            // tk_Sd3ucMOF4lhscD6: null,
            tk_zuJN3GA70AUcbVL: null,
            tk_4xWCRci51BL5ilj: null,
            tk_ru7jl4fxp6Ziki3: null,
            tk_0xbZd5QDoX7eiCY: null,
            tk_xaokCJ9HOoRmtA5: null,
            tk_CDzzfigcfmwlypT: null,
            tk_rY7Goed1LyC5kJx: null,
            tk_lvmwXs58ytfxa5A: null,
            tk_UhPXWFeX5aVmSWa: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          // console.log(vm.eventTicketResult);
          setTimeout(() => {
            vm.drawseatsd2(vm.eventTicketResult);
          }, 1000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  beforeMount() {
    // this.getMetaTagsInfo();
  },
  mounted() {
    this.fetchAllTickets();
    //    const id = localStorage.getItem("refsId");
    if (
      //    id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      //      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  metaInfo() {
    console.log("HERE");
    return {
      title: this.metaTitle,
      meta: this.metaTags,
    };
  },

  // metaInfo: {
  // title: "MYLE - The Bison Rattler Boogie @ Opium Lounge",
  // meta: [
  //   {
  //     name: "description",
  //     content: "MYLE - The Bison Rattler Boogie @ Opium Lounge",
  //   },
  //   {
  //     name: "keywords",
  //     content: "MYLE - The Bison Rattler Boogie @ Opium Lounge",
  //   },
  //   {
  //     name: "copyright",
  //     content: "MYLE",
  //   },
  //   {
  //     name: "url",
  //     content: "https://myle.com/the-bison-rattler-boogie-at-opium-lounge",
  //   },
  // ],
  // },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d2 {
    overflow-x: hidden;
  }
}
</style>
