<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p>
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p>
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlanta-launch_header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                MYLE is going to Atlanta - Join us on the trip!
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 700"
            >
              MYLE is taking a virtual road trip from our hometown in Columbus,
              Ohio to our newest MYLE city – Atlanta, Georgia. We’re
              highlighting some of the best Things to Do, Places to Go, and Food
              to Eat as we travel through Ohio, Kentucky, Tennessee, and finally
              arrive in Atlanta. Each state has a blog highlighting unique and
              hidden gems for you to experience. Enjoy the trip!
              <br />
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- MANUAL PURCHASE -->
      <section
        id="download"
        style="
          background-image: url('https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/glass.png');
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        "
      >
        <div
          class="w-100"
          style="
            position: absolute;
            height: 100%;
            display: block;
            background-image: url('https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/road.png');
            background-size: cover;
            background-repeat: no-repeat;
          "
        ></div>
        <div class="container">
          <div class="row pt-5 pb-5">
            <div class="col-md-3 col-lg-3 col-sm-6 col-5">
              <div style="height: 150px; text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/columbus_state.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-6 col-7">
              <div class="">
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  We start our
                  <b class="font-weight-semibold">Road Trip to Atlanta</b> in
                  our hometown of Columbus, Ohio (C-Bus) then head off to
                  Cincinnati on our way! We'll enjoy the best of the Buckeye
                  state on the way.
                </h3>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/columbus-cincinnati"
                    class="btn google-play-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Do More</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row pt-5">
            <div class="col-md-3 col-lg-3 col-sm-6 col-5">
              <div style="height: 150px; text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/columbus_state.png"
                  alt="Saturday Event"
                  class="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-6 col-7">
              <div>
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  We start our
                  <b class="font-weight-semibold">Road Trip to Atlanta</b> in
                  our hometown of Columbus, Ohio (C-Bus) then head off to
                  Cincinnati on our way! We'll enjoy the best of the Buckeye
                  state on the way.
                </h3>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com"
                    class="btn google-play-btn"
                    target="_blank" rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Do More</a
                  >
                </div>
              </div>
            </div>
          </div> -->

          <div class="row mt-5">
            <div class="col-md-9 col-lg-9 col-sm-6 col-7 mb-5">
              <div class="">
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  We’re stopping in Kentucky, the Bluegrass state to sip
                  bourbon, shop in quaint handmade craft shops and have an ice
                  cream cone.
                </h3>

                <h7
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  But don't put it your back pocket (it's illegal in Kentucky to
                  have an ice cream cone in your back pocket - who knew?
                </h7>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn mt-3"
                >
                  <a
                    href="https://myle.com/kentucky"
                    class="btn google-play-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Go More</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-6 col-5">
              <div style="height: 150px; text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bluegrass_state.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <!--
          <div class="row center-aligned mt-5">
            <div
              class="col-md-9 col-lg-9 col-sm-6 col-7 d-flex align-items-center"
            >
              <div>
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  We’re stopping in Kentucky, the Bluegrass state to sip
                  bourbon, shop in quaint handmade craft shops and have an ice
                  cream cone.
                </h3>

                <h7
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  But don't put it your back pocket (it's illegal in Kentucky to
                  have an ice cream cone in your back pocket - who knew?
                </h7>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn mt-3"
                >
                  <a
                    href="https://myle.com"
                    class="btn google-play-btn"
                    target="_blank" rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Go More</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-lg-3 col-sm-6 col-5 d-flex justify-content-center align-items-center"
            >
              <div class="mt-n5">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bluegrass_state.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div> -->

          <div
            class="row justify-content-center align-items-center text-center mt-5 mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Fun is waiting for you.
                </h3>

                <h4
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn google-play-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn google-play-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn google-play-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-5">
            <div class="col-md-3 col-lg-3 col-sm-6 col-5">
              <div style="height: center; text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennessee_state.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-6 col-7 mb-5">
              <div class="">
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Tennessee is our next stop - Beautiful mountains, friendly
                  folks and the birth place of Moon Pies!
                </h3>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/tennessee"
                    class="btn google-play-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Eat More</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row center-aligned mt-5 pt-3">
            <div
              class="col-md-3 col-lg-3 col-sm-6 col-5 d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/tennessee_state.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
            <div
              class="col-md-9 col-lg-9 col-sm-6 col-7 d-flex align-items-center"
            >
              <div>
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Tennessee is our next stop - Beautiful mountains, friendly
                  folks and the birth place of Moon Pies!
                </h3>
                <div
                  class="download-btn text-center animate__animated animate__fadeIn mt-3"
                >
                  <a
                    href="https://myle.com/road-trip-blog"
                    class="btn google-play-btn"
                    target="_blank" rel="noopener noreferrer"
                    style="font-size: large"
                    ><span class=""></span> Eat More</a
                  >
                </div>
              </div>
            </div>
          </div> -->

          <div class="row pb-5">
            <div class="col-md-9 col-lg-9 col-sm-6 col-7">
              <div class="">
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  The Peach State and Atlanta, Georgia is our destination. Here
                  is where we will introduce MYLE to the jewel of the South!<br /><br />
                  MYLE is hosting special events in Atlanta, April 1-4 – learn
                  more about where we will be and how you can get involved.
                </h3>

                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://myle.com/atlanta"
                    class="btn google-play-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Explore More</a
                  >
                  <a
                    href="https://myle.com/atlanta"
                    class="btn google-play-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Atlanta Events</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-6 col-5">
              <div style="height: 150px; text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlanta_state.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <!--
          <div class="row center-aligned mt-5">
            <div
              class="col-md-9 col-lg-9 col-sm-6 col-7 d-flex align-items-center mb-5"
            >
              <div>
                <h3
                  class="text-white"
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  The Peach State and Atlanta, Georgia is our destination. Here
                  is where we will introduce MYLE to the jewel of the South!<br /><br />
                  MYLE is hosting special events in Atlanta, April 1-4 – learn
                  more about where we will be and how you can get involved.
                </h3>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn pb-5"
                >
                  <a
                    href=" https://myle.com/road-trip-blog"
                    class="btn google-play-btn mr-3"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Explore More</a
                  >
                  <a
                    href="https://myle.com/road-trip-blog"
                    class="btn google-play-btn mr-3"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Atlanta Events</a
                  >
                </div>

              </div>
            </div>

            <div
              class="col-md-3 col-lg-3 col-sm-6 col-5 d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlanta_state.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>-->
        </div>

        <br />
        <div class="section-heading text-center mt-5">
          <h2 class="mb-0 text-white"># Just MYLE It</h2>

          <div class="row center-aligned">
            <div
              class="col-md-9 col-lg-9 col-sm-9 col-9 d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/hashtagMYLE.png"
                  alt="Saturday Event"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="row align-items-center justify-content-between test"
          style="margin: auto; margin-top: 50px"
        >
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                Want your business to be part of MYLE’s Road Trip to Atlanta?
                Reach out at
                <a
                  href="mailto:hello@myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@myle.com</a
                >
                and let us know where you are and what you have to offer!
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn pb-5"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId: this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get("https://myle-19701.uc.r.appspot.com/api/fetch_tickets")
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.eventTicketResult = ticketsResult.data.Result.filter(
              (r) => r.availableTickets > 0
            );
            vm.TicketsResult = vm.eventTicketResult;
          }

          const objtmp = {
            // tk_rJtGVRCIkpsvNvy: null,
            tk_ZXJDjJ3rKdR9wnT: null,
            tk_tQHtXbD8qjRbUlq: null,
            tk_KL1Qk8jLXTDphGW: null,
          };
          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Discover Places To Go In Atlanta | MYLE",

    meta: [
      {
        name: "description",
        content:
          "Looking for free events in Atlanta? Whether you are new in town or a local, MYLE will find something for you.",
      },

      {
        name: "keywords",
        content:
          "atlanta events | atlanta museum of arts | atlanta downtownn events",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/atlanta-launch",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
