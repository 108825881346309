<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>

              <p>
                <b class="font-weight-bold">Ticket Price:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].price
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Service Fees:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].service
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Sales Tax:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].sales
                    : "0"
                }}
              </p>

              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Name of Father</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Name of Father"
                required
                v-model="fatherName"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Name of Children</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Name of Children"
                required
                v-model="childrenName"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Number of Children</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Number of Children"
                required
                v-model="noOfChildren"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Number of Adult guests</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Number of Adult guests"
                required
                v-model="noOfadultguests"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Name of Adult guests</label>
              <textarea
                class="form-control mb-3"
                placeholder="Name of Adult guests"
                required
                v-model="nameOfAdultguests"
              ></textarea>
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Phone Number</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Phone Number"
                required
                v-model="pnumber"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Zip Code</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Zip Code"
                required
                v-model="zcode"
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? !invalidpnumber
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? pnumber.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? nameOfAdultguests.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? noOfadultguests.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? noOfChildren.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? childrenName.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? fatherName.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? zcode.trim().length < 4
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_5th_Annual_Fatherhood_Expo.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                5th Annual Fatherhood Expo
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Friday, June 16th - 5:00pm thru<br />
                Sunday, June 18th - 7:00pm
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/RJ3FgydTxkdkpW97A"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Huntington Park
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              The Fatherhood Expo showcases programs, products and services that
              equip fathers in creating the best possible parenting environment.
              Taking place over Father’s Day weekend, the Fatherhood expo is a
              3-day event complimented by an online fatherhood resources
              marketplace.
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                The Fatherhood Expo is the largest event in the Midwest
                celebrating and equipping Fathers. The Fatherhood Expo spans
                over three days of family friendly father focused celebrations,
                activities and resources during Father's Day weekend. The event
                encourages and equips fathers and father-figures to strengthen
                themselves and their families. The Expo serves as the perfect
                platform for businesses, faith-based organizations and community
                agencies to connect with the Midwest's robust communities of
                engaged fathers. These entities will promote and provide
                programs, products and services that help fathers create the
                best possible parenting environment for their children.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      vlist: ["tk_z9fQaP2uUN0ubNo", "tk_ndQFwAwPhYRba5a"],
      fatherName: "",
      childrenName: "",
      noOfChildren: "",
      noOfadultguests: "",
      nameOfAdultguests: "",
      pnumber: "",
      zcode: "",
      required_power_needs: "No",
      ageRangesList: [
        "Under 21",
        "21-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
      ],
      ageRange: "Under 21",
      phone: "",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_z9fQaP2uUN0ubNo",
        "tk_ndQFwAwPhYRba5a",
        "tk_mR4abJhwFAjgiZk",
        "tk_ADF9iteH4bt3FR5",
        "tk_eXb8p5MrH5hWhJY",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,

      tkListData: {
        tk_z9fQaP2uUN0ubNo: {
          price: "$5000",
          service: "$0",
          sales: "$0",
        },
        tk_ndQFwAwPhYRba5a: {
          price: "$2500",
          service: "$0",
          sales: "$0",
        },
        tk_mR4abJhwFAjgiZk: {
          price: "$1500",
          service: "$0",
          sales: "$0",
        },

        tk_ADF9iteH4bt3FR5: {
          price: "$1000",
          service: "$0",
          sales: "$0",
        },

        tk_eXb8p5MrH5hWhJY: {
          price: "$250",
          service: "$0",
          sales: "$0",
        },
      },

      tkList: {
        tk_z9fQaP2uUN0ubNo: {
          messages: ["Ticket Price: $5000"],
        },

        tk_ndQFwAwPhYRba5a: {
          messages: ["Ticket Price: $2500"],
        },

        tk_mR4abJhwFAjgiZk: {
          messages: ["Ticket Price: $1500"],
        },

        tk_ADF9iteH4bt3FR5: {
          messages: ["Ticket Price: $1000"],
        },

        tk_eXb8p5MrH5hWhJY: {
          messages: ["Ticket Price: $250"],
        },
      },

      priceIds: {
        tk_z9fQaP2uUN0ubNo: "price_1N7DOwD0vTZ4QB9c4JLHj2Xq",
        tk_ndQFwAwPhYRba5a: "price_1N7DOxD0vTZ4QB9cTcONEzh3",
        tk_mR4abJhwFAjgiZk: "price_1N7DOxD0vTZ4QB9ckb8wKrzq",
        tk_ADF9iteH4bt3FR5: "price_1N7DOyD0vTZ4QB9cDoGIgkkd",
        tk_eXb8p5MrH5hWhJY: "price_1N7DOyD0vTZ4QB9cjpnbTTuX",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.fatherName = "";
      this.childrenName = "";
      this.noOfChildren = "";
      this.noOfadultguests = "";
      this.nameOfAdultguests = "";
      this.pnumber = "";
      this.zcode = "";

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      this.reserveFormAccept = false;
      this.fullname = "";
      this.email = "";
      this.isSubmitted = false;
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (this.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo = {
          name_of_father: vm.fatherName,
          name_of_children: vm.childrenName,
          number_of_children: vm.noOfChildren,
          number_of_adult_guests: vm.noOfadultguests,
          name_of_adult_guests: vm.nameOfAdultguests,
          phone_number: vm.pnumber,
          zip_code: vm.zcode,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          vm.fatherName = "";
          vm.childrenName = "";
          vm.noOfChildren = "";
          vm.noOfadultguests = "";
          vm.nameOfAdultguests = "";
          vm.pnumber = "";
          vm.zcode = "";

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsored5thAnnualFatherhoodExpoEventTicketsEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_z9fQaP2uUN0ubNo: null,
            tk_ndQFwAwPhYRba5a: null,
            tk_mR4abJhwFAjgiZk: null,
            tk_ADF9iteH4bt3FR5: null,
            tk_eXb8p5MrH5hWhJY: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invalidpnumber() {
      return (
        (this.pnumber || "").match(/\d/g) &&
        (this.pnumber || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 5th Annual Fatherhood Expo",

    meta: [
      {
        name: "description",
        content: "MYLE - 5th Annual Fatherhood Expo",
      },

      {
        name: "keywords",
        content: "MYLE - 5th Annual Fatherhood Expo",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/5th-annual-fatherhood-expo",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
