<template>
  <div>
    <nav-bar />
    <div class="main">
      <section class="ptb-100">
        <div class="container">
          <div class="row mt45Tab">
            <div class="col-md-5 col-lg-5">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ambassador.png"
                alt="client logo"
                class="hideImg w-100"
              />
            </div>

            <div class="col-md-7 col-lg-7">
              <div
                class="col-12 pb-3 message-box d-done"
                v-bind:class="{
                  'd-none': !isSuccess && !hasError,
                  'd-block': isSuccess || hasError,
                }"
              >
                <div
                  class="alert"
                  v-bind:class="{
                    'alert-danger': hasError,
                    'alert-success': isSuccess,
                  }"
                >
                  {{ alertText }}
                </div>
              </div>
              <div class="">
                <h1 class="">MYLE Ambassador</h1>
                <form
                  v-on:submit="submit"
                  id="businessForm"
                  class="business-form ptb-50"
                >
                  <!-- <h5>Reach us quickly</h5> -->
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="Fname"
                          v-model="Fname"
                          placeholder="First Name"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="Lname"
                          v-model="Lname"
                          placeholder="Last Name"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="phone"
                          value=""
                          class="form-control"
                          v-model="phone"
                          placeholder="Phone"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          v-model="email"
                          placeholder="Email"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="city"
                          value=""
                          class="form-control"
                          v-model="city"
                          placeholder="City"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="state"
                          value=""
                          class="form-control"
                          v-model="state"
                          placeholder="State"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="zipcode"
                          value=""
                          class="form-control"
                          v-model="zipcode"
                          placeholder="Zipcode"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="mb-1">
                        Choose (all that apply) ways you would like to assist
                        MYLE as a MYLE Ambassador
                      </p>
                      <div class="form-group">
                        <input
                          type="checkbox"
                          id="Help1"
                          value="Help set-up for events"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help1"
                          >Help set-up for events</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help0"
                          value="Post Events to MYLE"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help0"
                          >Post Events to MYLE</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help2"
                          value="Help staff events by engaging with guests"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help2"
                          >Help staff events by engaging with guests</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help3"
                          value="Help clean-up after events"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help3"
                          >Help clean-up after events</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help4"
                          value="Administrative/Office Help"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help4"
                          >Administrative/Office Help</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help6"
                          value="Help distribute Marketing Materials around the community"
                          v-model="checkVals"
                        />
                        <label class="ml-2" for="Help6"
                          >Help distribute Marketing Materials around the
                          community</label
                        ><br />
                        <input
                          type="checkbox"
                          id="Help5"
                          value="Others"
                          v-model="showOtherText"
                        />
                        <label class="ml-2" for="Help5"
                          >Others (Please Describe)</label
                        ><br />
                        <div class="form-group">
                          <textarea
                            name="OtherText"
                            v-if="showOtherText"
                            v-model="OtherText"
                            class="form-control mt-2"
                            rows="3"
                            cols="25"
                            placeholder="Please describe..."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <textarea
                          name="Hearmessage"
                          v-model="Hearmessage"
                          class="form-control"
                          rows="1"
                          cols="25"
                          placeholder="How did you hear about the opportunity to be a MYLE Ambassador"
                          required="required"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          type="checkbox"
                          id="database"
                          value="database"
                          v-model="database"
                        />
                        <label class="ml-2" for="database"
                          >May we add you to our MYLE Email Database?</label
                        ><br />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 mt-3">
                      <button
                        type="submit"
                        class="btn solid-btn"
                        id="btnContactUs"
                        style="pointer-events: all; cursor: pointer"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <p class="form-message"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import axios from "axios";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
  data() {
    return {
      showOtherText: false,
      Fname: "",
      Lname: "",
      phone: "",
      email: "",
      city: "",
      state: "",
      zipcode: "",
      checkVals: [],
      OtherText: "",
      database: "",
      Hearmessage: "",
      alertText: "",
      hasError: false,
      isSuccess: false,
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();

      const volunteerFormData = {
        firstName: this.Fname,
        lastName: this.Lname,
        phone: this.phone,
        email: this.email.toLowerCase(),
        city: this.city,
        state: this.state,
        zipcode: this.zipcode,
        otherMessage: this.OtherText,
        addToList: Boolean(this.database),
        // addToList: !!this.database,
        hearAboutMessage: this.Hearmessage,
        assistOptions: this.checkVals,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/contact_volunteer_request",
          volunteerFormData
        )
        .then(() => {
          this.Fname = "";
          this.Lname = "";
          this.phone = "";
          this.email = "";
          this.city = "";
          this.state = "";
          this.zipcode = "";
          this.OtherText = "";
          this.Hearmessage = "";
          this.checkVals = [];
          this.database = "";
          this.showOtherText = false;
          this.alertText =
            "Thank you! A MYLE Team Member will be in touch shortly to arrange your volunteering opportunity!";
          this.hasError = false;
          this.isSuccess = true;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.alertText = "Something went wrong. Please try again!";
          this.isSuccess = false;
          this.hasError = true;
        });
    },
    mounted() {
      this.alertText = "";
      this.hasError = false;
      this.isSuccess = false;
    },
  },
};
</script>
