<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="TicketsResult[selected] && TicketsResult[selected].price"
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="TicketsResult[selected] && TicketsResult[selected].price"
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="reservedMeal = ''"
              >
                <option
                  class="form-control"
                  v-bind:key="idx + 'b'"
                  v-for="(count, idx) in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName == 'KLHESF Donation'
              "
            >
              <!-- test -->
              <label
                class="mt-2"
                v-if="
                  TicketsResult[selected].ticketCode == 'tk_x8SbsaK9plNID8q'
                "
                >Select Donation Type</label
              >
              <select
                v-if="
                  TicketsResult[selected].ticketCode == 'tk_x8SbsaK9plNID8q'
                "
                class="form-control"
                v-model="donationPid"
              >
                <option
                  class="form-control"
                  v-bind:key="idx + 'donation'"
                  v-for="(count, idx) in donationOptions"
                  :value="count.priceId"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3"
              v-if="
                tickcount == 1 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName == 'General Seating'
              "
            >
              <label class="mt-3">Meal Selection</label>
              <select class="form-control" v-model="reservedMeal">
                <option
                  class="form-control"
                  v-bind:key="idx + 'mealname'"
                  v-for="(reservedMName, idx) in mealList"
                  :value="reservedMName"
                >
                  {{ reservedMName }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3"
              v-if="
                tickcount !== 1 ||
                (TicketsResult[selected] &&
                  TicketsResult[selected].ticketName == 'Premium Seating')
              "
            >
              <label class="mt-3">Meal Selection</label>
              <textarea
                class="form-control"
                v-model="reservedMeal"
                rows="3"
              ></textarea>
              <!-- <input type="text" class="form-control" v-model="reservedMeal" /> -->
              <p style="font-size: 12px">
                Please provide meal selection for each ticket:<br />
                Ticket 1 - Lemon roasted chicken breast<br />
                Ticket 2 - Pan seared salmon<br />
                Ticket 3 - (Vegetarian) Rigatoni w/vegetable Bolognese<br />
                Ticket 4 - Lemon roasted chicken breast<br /><br />
                Note: All entrees will be served with a 3 cheese risotto and
                seasonal vegetables in herb jus
              </p>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected].ticketCode == 'tk_x8SbsaK9plNID8q'
                    ? !reservedMeal.trim().length
                    : false) ||
                  (TicketsResult[selected].ticketCode == 'tk_x8SbsaK9plNID8q'
                    ? !donationPid
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/alumni-chapter-header.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="ffont-family: 'Dosis', sans-serif; font-weight: 500"
              >
                Columbus (OH) Alumni Chapter of ΚΑΨ - Black & White Scholarship
                Fundraiser
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p class="lead-heading text-center">
              The Kenneth L. Howard Scholarship Endowment Committee of The
              Alumni Chapter of Kappa Alpha Psi Fraternity, Inc. present this
              special event- The Black & White Scholarship Fundraiser.
            </p>
          </div>
          <hr />
        </div>
        <div class="col-md-12">
          <div class="download-content">
            <p class="text-center">
              Our special guest for the evening will be former NFL Player, E.J.
              Junior. He will address our audience on the importance of “Twin
              21st Century Coping Skills for High Achieving Males:
              Self-awareness and Cultural Competency” on February 26, 2022 at
              this black tie gala in downtown Columbus, OH.<br /><br />
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/hakeem jeffries.jpeg"
                style="width: 30%; max-width: 30%; height: auto"
              /><br />
              Congressman Hakeem Jeffries <br />
              U.S. representative, NY District 8<br /><br />
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/NFL_Player_E_J_ Junior.jpg"
                style="width: 30%; max-width: 30%; height: auto"
              /><br />
              E.J. Junior <br />
              Former NFL Player<br /><br />
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="download-content">
            <p class="text-center">
              Mr. E.J. Junior is a former University of Alabama football player,
              former NFL 1st round pick, 2x Pro Bowler, College Football Hall of
              Fame Inductee and former Head Football Coach at Central State
              University.<br /><br />
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="download-content">
            <h6>Hotel Accommodations</h6>
            <p class="">
              There are 2 ways to secure overnight accommodations for the
              event.<br />
              1. By booking online through the following link:
              <a href="https://book.passkey.com/go/9084f256"
                >https://book.passkey.com/go/9084f256</a
              ><br />
              OR <br />
              2. By calling Renaissance Enhanced Group Reservations at
              877-901-6632. Guests will be asked for Passkey Reservations and
              request the Kappa Alpha Psi Fraternity, Inc. 22nd Scholarship
              Black & White Event.
            </p>
          </div>
          <hr />
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center mb-2">
              <h4 class="w-auto d-inline-block mb-2">
                All sales are final. No refunds!!!
              </h4>
            </div>
            <div class="text-center mb-2">
              <h8 class="w-auto d-inline-block mb-2">
                <b
                  ><i
                    >Strict dress code - Black or White formal wear or Black and
                    White formal attire.</i
                  ></b
                >
              </h8>
            </div>
            <br />
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk + 'ticketresult'"
                v-for="(obj, objk) in TicketsResult"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w + 'messages'"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                    <b class="font-weight-bold">Price:</b>
                    ${{ obj.price }}
                  </p>
                  <p
                    v-bind:key="linek + 'description'"
                    v-for="(line, linek) in obj.description"
                  >
                    {{ line }}
                  </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price || "Varies" }}
                    </p>
                    <p
                      v-bind:key="idx + 'des'"
                      v-for="(line, idx) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets || obj.status == 'EXPIRED'
                      "
                      class="btn solid-btn"
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' || !obj.availableTickets,
                      }"
                      id="reserve"
                      @click="openmodel(objk)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="download" class="white-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-center">
                <h6 class="">
                  A portion of the proceeds from the sale of the tickets for the
                  Black & White Scholarship Fundraiser support Extra MYLE
                  International, providing arts, culture, travel, and
                  entertainment experiences to underprivileged youth.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- MANUAL PURCHASE -->
      <section id="download" class="white-bg ptb-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2 class="mt-4">Other Payment Options</h2>
                <p class="lead">
                  We are also accepting Cash App, Paypal, Zelle and Venmo for
                  ticket payments
                </p>

                <div class="row equal">
                  <div
                    class="col-md-3 col-lg-3"
                    style="
                      background-image: linear-gradient(
                        136deg,
                        #145143 0%,
                        #0c2f1f 100%
                      );
                    "
                  >
                    <a
                      href="https://cash.app/$MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class="">
                            <img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_cash_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                            />
                          </span>
                        </div>
                        <h3 class="text-white bold">Cash App</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://www.paypal.me/myletickets?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_paypal_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Paypal</h3>
                        <p class="text-white lead">tickets@myle.com</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3" @click="openZelleModel">
                    <div
                      class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                    >
                      <div class="circle-icon mb-3">
                        <span class=""
                          ><img
                            class="align-middle"
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_zelle_icon.png"
                            style="width: 15%; max-width: 15%; height: auto"
                        /></span>
                      </div>
                      <h3 class="text-white">Zelle</h3>
                      <p class="text-white lead">tickets@myle.com</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://venmo.com/code?user_id=3449043321620189533"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_venmo_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Venmo</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h6 class="mb-3 mt-5 font-bold">
            If using Cash App, Paypal, Zelle or Venmo, please follow these
            steps:
          </h6>
          <span class="font-weight-bold selfBullet">•</span> Send payment (Total
          Price) to Cash App, Paypal, Zelle or Venmo account shared above<br />
          <span class="font-weight-bold selfBullet">•</span> Take a screenshot
          of your payment confirmation<br />

          <span class="font-weight-bold selfBullet">•</span> Complete the ticket
          confirmation section below, click choose file and submit with the
          screenshot as your receipt confirmation.<br />
          <span class="font-weight-bold selfBullet">•</span> Our team will
          process your transaction and you will receive an email confirmation
          with your tickets.

          <h6 class="mb-3 mt-3 font-italic">
            Note: Tickets for Cash App, Paypal, Zelle and Venmo transactions are
            processed by our team between 9am and 7pm EST daily. You may submit
            at any time.
          </h6>
        </div>
      </section>
      <hr />

      <!-- Form SECTION -->
      <section>
        <form>
          <div class="container mt-5">
            <!-- heading -->
            <div class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <h3 class="w-auto d-inline-block">Ticket Confirmation</h3>
                </div>
              </div>
            </div>
            <!-- heading -->

            <!-- form -->
            <div class="row my-3">
              <div class="col-md-4">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="ticketForm.name"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="ticketForm.email"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Phone No.</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone"
                  v-model="ticketForm.phone"
                  required
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-6">
                <label>Ticket Options</label>
                <select
                  class="form-control"
                  v-model="ticketForm.ticketName"
                  @change="onChangeTicket($event)"
                >
                  <option
                    class="form-control"
                    v-bind:key="idx + 'a'"
                    v-for="(count, idx) in eventTicketResult.filter(
                      (e) => e.status != 'EXPIRED' && e.availableTickets
                    )"
                    :value="count.ticketName"
                  >
                    {{ count.ticketName }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>No. of attendees</label>
                <select
                  class="form-control"
                  v-model="ticketForm.ticketCount"
                  @change="resetMealName()"
                >
                  <option
                    class="form-control"
                    v-bind:key="idx + 'dropdown'"
                    v-for="(count, idx) in countsListShow"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="row my-3"
              v-if="ticketForm.ticketName != 'KLHESF Donation'"
            >
              <div
                class="col-md-6"
                v-if="
                  ticketForm.ticketCount == 1 &&
                  ticketForm.ticketName == 'General Seating'
                "
              >
                <label>Meal Selection </label>
                <p style="font-size: 12px">({{ mealSubtitle }})</p>
                <select class="form-control" v-model="ticketForm.mealName">
                  <option
                    class="form-control"
                    v-bind:key="mName + 'b'"
                    v-for="mName in mealList"
                    :value="mName"
                  >
                    {{ mName }}
                  </option>
                </select>
              </div>

              <div
                class="col-md-6"
                v-if="
                  ticketForm.ticketCount !== 1 ||
                  ticketForm.ticketName == 'Premium Seating'
                "
              >
                <label class="mt-3">Meal Selection</label>
                <textarea
                  class="form-control"
                  @input="ticketForm.mealName = $event.target.value"
                  rows="3"
                ></textarea>

                <p style="font-size: 12px">
                  Please provide meal selection for each ticket:<br />
                  Ticket 1 - Lemon roasted chicken breast<br />
                  Ticket 2 - Pan seared salmon<br />
                  Ticket 3 - (Vegetarian) Rigatoni w/vegetable Bolognese<br />
                  Ticket 4 - Lemon roasted chicken breast<br /><br />
                  Note: All entrees will be served with a 3 cheese risotto and
                  seasonal vegetables in herb jus
                </p>
              </div>
            </div>

            <div class="row" v-if="false">
              <div class="col-md-6">
                <!-- test -->
                <label
                  class="mt-2"
                  v-if="ticketForm.ticketName == 'KLHESF Donation'"
                  >Select Donation Type</label
                >
                <select
                  v-if="ticketForm.ticketName == 'KLHESF Donation'"
                  class="form-control"
                  v-model="ticketForm.donationPid"
                >
                  <option
                    class="form-control"
                    v-bind:key="count.name + 'b'"
                    v-for="count in donationOptions"
                    :value="count.priceId"
                  >
                    {{ count.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12">
                <label class="mr-3">Receipt</label>
                <input
                  name="image"
                  type="file"
                  id="fileName"
                  accept=".jpg,.jpeg,.png"
                  @change="validateFileType($event)"
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12 d-flex justify-content-left">
                <label class="mr-3">Pay Method:</label>
                <div class="payment-method mx-2">
                  <input
                    type="radio"
                    value="Paypal"
                    v-model="ticketForm.method"
                    id="paypal"
                  />
                  <label class="ml-1" for="paypal">Paypal</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="zelle"
                    type="radio"
                    value="Zelle"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="zelle">Zelle</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="venmo"
                    type="radio"
                    value="Venmo"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="venmo">Venmo</label>
                </div>
                <div class="payment-method">
                  <input
                    type="radio"
                    value="Cash App"
                    v-model="ticketForm.method"
                    id="cashup"
                  />
                  <label class="ml-1" for="cashup">Cash App</label>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 mb-4">
                <input type="checkbox" v-model="ticketForm.accept" />
                I understand that all sales are final. No refunds. I have read
                and agree with the COVID-19 protocols and MYLE
                <a
                  href="https://myle.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions.</a
                >
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  @click="payfornow()"
                  :disabled="isFormValid"
                  class="btn solid-btn m-auto"
                  style="pointer-events: all; cursor: pointer"
                >
                  Reserve
                </button>
              </div>
            </div>
            <h6 class="mb-5 mt-5 font-italic">
              Reminder: Tickets for Cash App, Paypal, Zelle and Venmo
              transactions are processed by our team between 9am and 7pm EST
              daily. Once your payment submission is confirmed, you will receive
              your tickets via email from the MYLE Ticket Manager account.
            </h6>
          </div>
        </form>
      </section>
      <!-- Form SECTION -->

      <!-- <hr />
      <section class="download-section ptb-50 background-img bg-white">
        <div class="container">
          <div class="row justify-content-between">
            <div
              class="col-md-4 d-flex align-items-center justify-content-center"
            >
              <div class="download-img">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/STADIUM_LOGO.png"
                  alt="download"
                  class="img-fluid mobImg"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="download-content">
                <h3 class="ml-5">Bottle Service</h3>

                <ul class="lead ml-5">
                  <li>Endeavor Rum</li>
                  <li>Endeavor Gin</li>
                  <li>Endeavor Vodka</li>
                  <li>Bulleit Bourbon</li>
                  <li>Crown Royal Bourbon</li>
                  <li>Espolon Blanco</li>
                  <li>Espolon Reposado</li>
                </ul>
                <ul class="lead ml-5 mt-3">
                  <li>Includes Mixers</li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="download-content">
                <h3 class="ml-5">Menu</h3>

                <ul class="lead ml-5">
                  <li>Caesar Salad</li>
                  <li>Side Salad</li>
                </ul>
                <ul class="lead ml-5 mt-2">
                  <li>OMG CAULIFLOWER</li>
                  <li>Mozzarella Sticks</li>
                  <li>Street Corn</li>
                  <li>Creekside Fries</li>
                  <li>NBA JAM</li>
                  <li>The Greatest</li>
                  <li>Chicken n Waffle</li>
                </ul>
                <ul class="lead ml-5 mt-2">
                  <li>Boneless Wings</li>
                  <li>Traditional Wings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr /> -->

      <div class="row justify-content-center">
        <div class="col-md-8">
          <div
            class="row w-100 m-0 mt-5 text-center promo-section MYLE-lite-green"
          >
            <div class="col-lg-12 col-md-12">
              <div class="mt-3">
                <div class="section-heading mb-3">
                  <h5 class="text-center text-white">Gallery</h5>
                </div>
              </div>
            </div>
          </div>
          <section class="correctZindex mb-4">
            <vue-flux
              :options="vfOptions"
              :images="vfEventImgs"
              :transitions="vfTransitions"
              ref="slider"
              style=""
            >
              <template v-slot:preloader>
                <flux-preloader />
              </template>
              <template v-slot:controls>
                <flux-controls />
              </template>
            </vue-flux>
          </section>
        </div>
      </div>

      <!-- <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div> -->

      <!-- <br />
      <div class="section-heading text-center">
        <h2 class="">1st Annual Sneaker Jam Flyers</h2>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 col-lg-6 d-flex align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/sneaker-jam-1.jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
        <div
          class="col-md-6 col-lg-6 d-flex justify-content-center align-items-center"
        >
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/sneaker-jam-2.jpg"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
      </div> -->

      <br />
      <div class="container">
        <div class="section-heading text-center">
          <h2 class="">COVID-19 Protocols</h2>
        </div>
        <hr />
        In an effort to proactively follow health guidelines and the most
        effective safety precautions, the following Covid-19 protocols have been
        established for attendance at the 2022 KLHESF Black Tie event on
        February 26, 2022.<br /><br />
        <span class="font-weight-bold selfBullet">•</span> Patrons are required
        to sanitize their hands <b>before</b> entering the event venue.
        <br />
        <span class="font-weight-bold selfBullet">•</span> Patrons will be
        required to confirm vaccination before purchasing their ticket for the
        event and provide proof of vaccination or completed vaccination prior to
        entering the event.<br />
        <span class="font-weight-bold selfBullet">•</span> Entities and
        individuals purchasing a table will be required confirm and submit
        vaccination status for all table attendees. Forward a list to event
        chairman P.R.West, Sr. at Mr.P.R.West@gmail.com before February 19,
        2022. Individuals at reserved group tables who are not on the reserved
        listing will have the additional requirement of showing proof of
        vaccination or completed vaccination card before entry to the event.
        <b
          >Attendees without proof of vaccination status will be denied entry at
          the door.</b
        ><br />
        <span class="font-weight-bold selfBullet">•</span> Patrons will have
        their temperature taken at the door. If a patron has a temperature of
        100.4 or higher, he or she will be asked to voluntarily take (in an
        adjacent area/room to the event venue at the site) a Covid Rapid Test.
        <b
          >If the patron refuses to take the Covid Rapid test, he/she will be
          refused entry at the door.</b
        ><br />
        <span class="font-weight-bold selfBullet">•</span> Black or white mask
        (to match the dress code) will be provided for all guests attending the
        2022 KLHESF Black Tie event. Masks are to be warn at all times except
        when actively eating and drinking or when presenters are speaking.<br /><br />
        These measures have been voted on and approved by the KLHESF Black &
        White Committee for the safety of all patrons and the overall well-being
        of all attendees. Thank you for your patience, adherence, and
        cooperation in advance!<br />
        For additional information provided by the
        <b>Centers for Disease Control and Prevention</b>, please visit
        <a
          href="https://www.cdc.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.cdc.gov/</a
        >
      </div>
    </div>
    <br />
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      donationOptions: [
        {
          priceId: "price_1KNEFyD0vTZ4QB9c1lqa4f86",
          name: "Gold - $520.10",
        },
        {
          priceId: "price_1KNEK5D0vTZ4QB9cE1AqtDCG",
          name: "Silver - $260.35",
        },
        {
          priceId: "price_1KNEFxD0vTZ4QB9cBo9JBd92",
          name: "Bronze - $104.50",
        },
      ],
      tkList: {
        tk_tSCSaOksxALRvKB: {
          messages: [
            "Individual Seat",
            "Ticket Price: $100",
            "Processing fee of $4.5",
          ],
        },
        tk_x8SbsaK9plNID8q: {
          messages: ["Gold: $520.10", "Silver: $260.35", "Bronze: $104.50"],
        },
        tk_m3dUrrwMkXhiyQU: {
          messages: [
            "Group Seats (Table of 8)",
            "Ticket Price: $1000",
            "Processing fee of $39.60",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        tk_tSCSaOksxALRvKB: "price_1KHc9JD0vTZ4QB9c3V2e2Shy",
        tk_m3dUrrwMkXhiyQU: "price_1KHcAsD0vTZ4QB9c6fBu0SWZ",
        tk_x8SbsaK9plNID8q: "-1",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      mealSubtitle:
        "All entrees will be served with a 3 cheese risotto and seasonal vegetables in herb jus",
      mealList: [
        "Lemon roasted chicken breast",
        "Pan seared salmon",
        "(Vegetarian) Rigatoni w/vegetable Bolognese",
      ],
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        mealName: "Lemon roasted chicken breast",
        ticketName: "General Seating",
        ticketId: "",
        method: "Paypal",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      donationPid: "",
      reservedMeal: "Lemon roasted chicken breast",
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_0.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_1.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_2.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_3.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_4.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/photosfortheblackandwhite/KLHESF_5.jpg",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    VueFlux,
    FluxPreloader,
    FluxControls,
    StripeCheckout,
  },
  methods: {
    resetMealName: function () {
      this.ticketForm.mealName = "";
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];
      this.ticketForm.mealName = "";

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;

      if (this.ticketForm.method) {
        const test = this.ticketForm.method;
        this.ticketForm.method = "";
        this.ticketForm.method = test;
      }
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
      this.tickcount = 1;
      if (
        this.TicketsResult[this.selected] &&
        this.TicketsResult[this.selected].ticketName == "Premium Seating"
      ) {
        this.reservedMeal = "";
      } else {
        this.reservedMeal = "Lemon roasted chicken breast";
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].ticketCode == "tk_x8SbsaK9plNID8q"
            ? this.donationPid
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo:
          vm.TicketsResult[vm.selected].ticketName != "KLHESF Donation"
            ? {
                type: "Meal Selection",
                value: this.reservedMeal,
              }
            : null,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */
      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_alumni_chapter_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.eventTicketResult = ticketsResult.data.Result;
            vm.TicketsResult = vm.eventTicketResult;
          }

          const objtmp = {
            tk_tSCSaOksxALRvKB: null,
            tk_m3dUrrwMkXhiyQU: null,
            // test
            tk_x8SbsaK9plNID8q: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          vm.onChangeTicket({ target: { value: "General Seating" } });
          vm.ticketForm.ticketCount = 1;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      if (vm.ticketForm.ticketName != "KLHESF Donation") {
        bodyFormData.append(
          "additionalInfo",
          JSON.stringify({
            type: "Meal Selection",
            value: this.ticketForm.mealName,
          })
        );
      } else {
        //    bodyFormData.append("additionalInfo", null);
      }

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "Paypal",
            accept: false,
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "Paypal",
            accept: false,
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.ticketForm.name.trim().length ||
        !this.ticketForm.ticketName.trim().length ||
        !this.ticketForm.ticketCount === 0 ||
        !this.invalidTicketEmail ||
        !this.invalidTicketPhone ||
        !this.ticketForm.accept ||
        !this.ticketForm.file ||
        (this.ticketForm.ticketName != "KLHESF Donation"
          ? !this.ticketForm.mealName.trim().length
          : false) ||
        this.isSubmitted
      );
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
