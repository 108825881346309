<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentucky_header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                8 Unique Kentucky Places to Go
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 500"
            >
              MYLE is on the road to Atlanta! Ride with us through Kentucky’s
              unique Places to Go – Take a bourbon tour, learn the history of
              Black Horsemen, and stay in a castle! Just be careful not to break
              the ice cream law (see below). We plan to land in Lexington on
              Saint Patrick’s Day for the annual “Blessing of the Keg.”
            </p>

            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 700"
            >
              MYLE is a social activity and event-based smart entertainment
              guide designed to Make Your Life Entertaining. Quickly find events
              nearby and when you travel to hear live music, take the kids out,
              dine and drink with friends, catch a show, and more.
              <a href="#download"> Get the MYLE app</a>
              today and Just MYLE It!
              <br />
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- MANUAL PURCHASE -->
      <section class="">
        <div class="container">
          <div
            class="row justify-content-center align-items-center text-center mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Fun is waiting for you.
                </h3>

                <h4
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 1.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  1. Lexington -
                  <a
                    href="https://sqecial.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SQecial Media</a
                  >

                  is an independent bookstore and gift shop that sells a wide
                  variety of hard-to-find books and curiosities curated for
                  everyone from the hedge or metaphysic to Buddhist. With
                  locally and globally-inspired wares alike, you’re sure to find
                  something new with each visit. Find out more on the MYLE web
                  or mobile app.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Bonus snack at
                  <a
                    href="https://www.crankandboom.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Crank & Boom Craft Ice Cream</a
                  >, which started out as a dessert option in the back of Toa
                  Green's Thai Orchid Cafe. It has since grown into a business
                  with two scoop shops, 40+ employees, and national recognition.
                  Their goal: spread joy, laughter, and compassion through its
                  FEED Backpack initiative and Small Batch Club program, which
                  have earned more than $56,000 for local charities since 2020.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://restaurantguru.com/Crank-and-Boom-Lexington"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Crank & Boom Ice Cream Lounge in Lexington</a
                  >
                  features live music and a modern, romantic environment.
                  Dine-in: Horse thieves beware - It’s illegal to carry ice
                  cream in your back pocket in Kentucky. You won’t get away with
                  leading your neighbor’s horse astray with your hidden treat.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 2.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 3.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  2. Hodgenville -
                  <a
                    href="https://www.nps.gov/abli/index.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abraham Lincoln Birthplace National Historical Park (US
                    National Park Service)</a
                  >
                  Free self-guided tour. Walk the path to the Sinking Springs
                  and climb the 56 steps (one for each year of Lincoln's life)
                  to the first Lincoln memorial. Dedicated on the 100
                  anniversary of his birth in 1909 and designed by John Russell
                  Pope, this pink granite and marble Beaux-Arts building houses
                  a replica of the Lincoln family’s log cabin.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 4.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 5.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 6.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  3. Williamstown-
                  <a
                    href="https://arkencounter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ark Encounter and Creation Museum</a
                  >
                  Life-size exhibits and insights into how Noah’s ark could have
                  handled all those animals. Two new attractions will open at
                  the complex in 2022: a new $1.3 million Stargazer Planetarium
                  along with a high-tech exhibit on the so-called "ape-men," and
                  a virtual reality experience comes online with the "Truth
                  Traveler."
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 7.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div
            class="row mt-2 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 8.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Bonus:
                  <a
                    href="https://www.derbymuseum.org/blackheritage.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Black Heritage in Racing Collection</a
                  >
                  at Louisville’s

                  <a
                    href="https://www.derbymuseum.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kentucky Derby Museum</a
                  >

                  . Two dedicated exhibits relate the proud, overlooked history
                  of black riders and trainers in the “<a
                    href="https://www.derbymuseum.org/bhirexperience.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Proud of My Calling</a
                  >
                  ” performance and through the reprised Black Heritage in
                  Racing Exhibit.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 9.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  4. Maysville -
                  <a
                    href="https://www.kygmc.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kentucky Gateway Museum Center</a
                  >
                  - (includes Bourbon trail history) is a regional history
                  museum housing The Kathleen Savage Browning Miniatures
                  Collection of 1/12-scale replicas of houses, furniture,
                  clothing, art, and people. Explore more than 4,000 regional
                  craft items and look into your heritage at The Genealogical &
                  Historical Research Library. Take the Old Pogue Experience
                  bourbon tasting in the Limestone Building dedicated to the
                  history of bourbon production in Maysville.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  5. Morehead -
                  <a
                    href="https://www.moreheadstate.edu/kfac/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kentucky Folk Art Museum</a
                  >
                  - Kentucky's Folk Art Center has almost 1,400 pieces of
                  self-taught art. The collection's works are rotated in the
                  center's first-floor gallery featuring folk art, fine art,
                  textiles, photography, and historical information. KFAC has
                  one of the most fantastic gift shops in the region. The pieces
                  on show are a monument to the average man's worries, courage,
                  and convictions.
                  <a
                    href="https://www.youtube.com/watch?v=UVFCY38cAPI&list=PLk2q0CCu6vKGF1s59uUvaldNcLtUp4ByC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KFAC Collection Conversations Ep. 1: Mark Anthony
                    Mulligan</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 10.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  6. Bowling Green -
                  <a
                    href="https://www.corvettemuseum.org/learn/about-the-museum/#mission"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    National Corvette Museum</a
                  >
                  - Imagine driving one of 80 antique cars sitting outside a
                  barbershop, gas station, or racetrack from the 1950s and
                  1960s! Among the treasures on display are pristine examples of
                  historic automobiles, prototypes that never made it into
                  production, and modern engineering and design marvels. Shop
                  for fashion, collectibles, home decor, and gifts at the
                  Corvette Store, then replenish your energy at the cafe, which
                  is decorated in the style of the 1950s. Sign up for the
                  <a
                    href="https://www.corvettemuseum.org/national-corvette-museum-offers-new-racing-experience/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Corvette Experience</a
                  >
                  - choose one of three model simulators and hit the virtual
                  road!
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 11.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  7. Ashland -
                  <a
                    href="https://henryclay.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Henry Clay Estate</a
                  >
                  - Ashland is a must-see for everyone interested in Kentucky,
                  the US, Civil War, and architecture. Tour the 18-room
                  Federate-style mansion's superbly preserved original
                  furnishings and grounds. Learn how historians use evidence to
                  uncover enslaved men, women, and children's stories. “Listen
                  to the voices of those who resisted bondage and honor the
                  memory of those who were forced to labor through
                  <a
                    href="https://henryclay.org/enslaved-people-at-ashland/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    On the Traces: Slavery at Ashland.</a
                  >”
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 12.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  8.
                  <a
                    href="https://www.thekentuckycastle.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kentucky Castle</a
                  >
                  Real estate developer Rex Martin and his wife Caroline Bogaert
                  Martin built the castle in 1969, inspired by architecture they
                  had seen in Europe. Initially, it included 7 bedrooms, 15
                  bathrooms, a driveway fountain, and a tennis court. When the
                  Martins divorced in 1975, the abandoned castle became a
                  tourist attraction and oddity. Now providing 14 guest
                  accommodations, The Kentucky Castle offers a wonderfully
                  unique lodging, farm-to-table dining and spa experience.

                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kentuckyBlog/Blog-Picture 13.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3 justify-content-center align-items-center">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  Bonus -
                  <a
                    href="https://lexingtonstpatsparade.org/festival/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Saint Patrick’s Day Parade & Festival, Lexington</a
                  >
                  - Kentuckians mark spring with this festival produced by
                  Lexington Parks & Recreation with the help of local businesses
                  and cultural organizations. A small group of Lexingtonians,
                  led by William Enright, founded the Bluegrass Irish Society in
                  1980, which organized the city's first St. Patrick's Parade
                  that year. Local musicians and dancers perform. The free
                  festival features artisan, craft, and food vendors.
                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  Saturday, March 19, 2022, from 11 a.m. to 5 p.m. <br />
                  At 11 a.m., the Moondance Amphitheater hosts the annual
                  “Blessing of the Keg.”
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Where else would you want to be on Saint Patrick’s Day? Are
                  you as excited as we are and feeling ready for the next stop
                  on our Road Trip to Atlanta? See you in Tennessee next week
                  where we’ll unwrap 7 delicious surprising snacks, drinks and
                  meals from general stores, rooftop bars and food trucks. Can
                  you guess which snack we’ll take you to? Hint: In other
                  countries it’s called the “wagon wheel.”

                  <br />
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div class="row mt-5">
            <div class="col-md-2 col-lg-2 col-sm-2">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/maureen.png"
                  alt="Saturday Event"
                  style="
                    max-width: 60%;
                    max-height: 60%;
                    width: 60%;
                    height: 60%;
                  "
                />
              </div>
            </div>

            <div
              class="col-md-10 col-lg-10 col-sm-10 align-items-center justify-content-center"
            >
              <div class="align-items-center justify-content-center">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  MAUREEN FRIEND is a freelance writer and visual content
                  creator passionate about seeing you exceed your business goals
                  and shamelessly promoting you and your work; have phone, will
                  travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "8 Best Fun and Unique Places to Visit in Kentucky ",

    meta: [
      {
        name: "description",
        content:
          "Travel Kentucky to find cool and unique vacation spots, explore sightseeing and travel destinations, and fun things to do. Learn the history of black horsemen and stay in a castle.",
      },

      {
        name: "keywords",
        content:
          "places to go in kentucky for the weekend | places to see in Kentucky | Places to visit in Kentucky for families | best places to visit in Kentucky during summer | Fun cities in Kentucky",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/kentucky",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
