<template>
  <div style="background-color: black">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Donation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Donation Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Amount:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Amount: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Amount: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_yfT2rMpbdhrWJNg'
              "
            >
              <label class="mt-3">Number of Donation(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_bMMncT7WBaGEmz0'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_ZNDRjDCAnOGEjAn'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq1(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div class="row col-md-12">
              <div class="col-md-6 mt-2">
                <label class="">Phone Number</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Phone Number"
                  v-model="pnumber"
                />
              </div>

              <div class="col-md-6 mt-2">
                <label class="">Mailing Address</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Mailing Address"
                  v-model="maddress"
                />
              </div>

              <div class="col-md-6">
                <label class="">Billing Address</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Billing Address"
                  v-model="baddress"
                />
              </div>

              <div class="col-md-6">
                <label class="">Company</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Company"
                  v-model="company"
                />
              </div>
            </div>
            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_yfT2rMpbdhrWJNg'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-2">
              <input type="checkbox" v-model="followup" />
              Would like a follow-up to make donation a recurring payment
            </div>

            <div class="col-md-12 mt-2" v-if="isDonation">
              <input type="checkbox" v-model="isDonationPay" />
              Donate Anonymously
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !maddress.trim().length ||
                  !baddress.trim().length ||
                  !invalidPhoneNum ||
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_yfT2rMpbdhrWJNg'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Donate now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BandoReunion_V6.jpg"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->

      <!-- banner section starts -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: red"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Arial', sans-serif;
                  font-weight: 700;
                  color: black;
                "
              >
                Boxing for Books
                <br />
                Fundraiser
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- banner section ends -->
      <br />

      <!-- Description section ends -->

      <!-- <div
                    id="d2"
                    style="max-width: 900px; margin: auto; height: auto; margin-top: 50px"
                  ></div> -->

      <!-- <div
                    class="row align-items-center justify-content-center test"
                    style="margin: auto; margin-top: 20px"
                  >
                    <div
                      class="col-md-3 align-items-center justify-content-center"
                      style="display: flex"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  background: #0ea293;
                                  color: #0ea293;
                                  width: 20px;
                                  height: 20px;
                                  margin-right: 3px;
                                "
                              ></div>
                            </td>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  font-family: 'Quicksand', sans-serif;
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                              >
                                Scholarship Gala-Individual
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
      
                    <div
                      class="col-md-3 align-items-center justify-content-center"
                      style="display: flex"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  background: #a19140;
                                  color: black;
                                  width: 20px;
                                  height: 20px;
                                  margin-right: 3px;
                                "
                              ></div>
                            </td>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  font-family: 'Quicksand', sans-serif;
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                              >
                                Reserved Seating (Full Table - Seats 10)
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
      
                    <div
                      class="col-md-3 align-items-center justify-content-center"
                      style="display: flex"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  background: #c07f00;
                                  color: #c07f00;
                                  width: 20px;
                                  height: 20px;
                                  margin-right: 3px;
                                "
                              ></div>
                            </td>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  font-family: 'Quicksand', sans-serif;
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                              >
                                Reserved Seating (Half Table - Seats 5)
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
      
                    <div
                      class="col-md-3 align-items-center justify-content-center"
                      style="display: flex"
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  background: lightgrey;
                                  color: lightgrey;
                                  width: 20px;
                                  height: 20px;
                                  margin-right: 3px;
                                "
                              ></div>
                            </td>
                            <td style="vertical-align: middle">
                              <div
                                style="
                                  font-family: 'Quicksand', sans-serif;
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                              >
                                Reserved
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> -->

      <div class="row">
        <div class="col-md-4">
          <div class="section-heading text-center">
            <h2
              class=""
              style="
                font-family: 'Arial', sans-serif;
                font-weight: 700;
                font-size: 2.3em;
                color: white;
              "
            >
              Scan QR Code To Donate
            </h2>
          </div>

          <div class="row text-center justify-content-center">
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/qr_code_BandoReunion_V2.png"
                alt="Slow Jam Social Event"
                class="img-fluid"
                style="width: 100%; max-width: 100%"
              />
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <vueThermometer
            :value="thermoResult"
            :min="0"
            scale="k"
            :max="30"
            :options="options"
            customClass="marginclass"
          />

          <br />
        </div>
        <div class="col-md-4" style="text-align: center">
          <h3
            class="w-auto d-inline-block mb-2"
            v-if="salesRecords.length >= 0"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 700;
              font-size: 2.3em;
              color: white;
              margin: auto;
              text-align: center;
              width: 100% !important;
            "
          >
            Latest Donations
          </h3>

          <h3
            class="w-auto d-inline-block mb-2 mt-3"
            v-if="salesRecords.length <= 0"
            style="
              font-family: 'Arial', sans-serif;
              font-weight: 600;
              font-size: 1.8em;
              color: white;
              margin: auto;
              text-align: center;
              width: 100% !important;
            "
          >
            No Donations Yet
          </h3>

          <marquee
            width="500px"
            height="550px"
            direction="down"
            scrollamount="10"
          >
            <span
              style="
                text-align: center;
                display: block;
                font-family: 'Arial', sans-serif;
                font-weight: 600;
                font-size: 1.8em;
                color: white;
              "
              class="fullName"
              v-bind:key="objk"
              v-for="(obj, objk) in salesRecords"
            >
              {{ obj.user.fullName + " -" }}
              <span class="amount">{{ " $" + obj.totalPaid }} </span
              ><br /><br />
            </span>
          </marquee>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import vueThermometer from "../../../components/VueThermometer.vue";

// tslint-disable no-mixed-spaces-and-tabs
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      pnumber: "",
      maddress: "",
      baddress: "",
      company: "",
      followup: false,
      isDonation: false,
      isDonationPay: false,
      intID: null,
      salesRecords: [],
      thermoResult: 0,
      options: {
        text: {
          color: "white",
          fontSize: 14,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#21bf78",
          backgroundColor: "black",
          frameColor: "white",
          ticks: 12,
          ticksEnabled: true,
          tickColor: "white",
          tickWidth: "1",
        },
        layout: {
          height: 600,
          width: 140,
        },
      },
      selectionList: [],
      dict: {},
      dictlist: {},
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      availSeating: "",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_CoHMzgGR158saAH",
        "tk_Z8a9fe56BHx9bHr",
        "tk_tlZEJ3dMf2U4WsJ",
        "tk_uSBo0TmAenxf2CP",
        "tk_M2iH8xEoWy9DGWO",
        "tk_tAf85haTa6yuouR",
        "tk_VVhdyaMxvqd4z0K",
        "tk_rTolQ7vpgNhGYWt",
        "tk_EtX0chUOgRB9FY5",
        "tk_S3V709sEL88Mcx4",
        "tk_d0GTCEWbGLuAua7",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_CoHMzgGR158saAH: {
          messages: [],
          // messages: ["Donation Amount: $15"],
        },

        tk_Z8a9fe56BHx9bHr: {
          messages: [],
          // messages: ["Donation Amount: $25"],
        },

        tk_tlZEJ3dMf2U4WsJ: {
          messages: [],
          // messages: ["Donation Amount: $50"],
        },

        tk_uSBo0TmAenxf2CP: {
          messages: [],
          // messages: ["Donation Amount: $75"],
        },

        tk_M2iH8xEoWy9DGWO: {
          messages: [],
          // messages: ["Donation Amount: $100"],
        },

        tk_tAf85haTa6yuouR: {
          messages: [],
          // messages: ["Donation Amount: $200"],
        },

        tk_VVhdyaMxvqd4z0K: {
          messages: [],
          // messages: ["Donation Amount: $350"],
        },

        tk_rTolQ7vpgNhGYWt: {
          messages: [],
          // messages: ["Donation Amount: $500"],
        },

        tk_EtX0chUOgRB9FY5: {
          messages: [],
          // messages: ["Donation Amount: $1000"],
        },

        tk_S3V709sEL88Mcx4: {
          messages: [],
          // messages: ["Donation Amount: $2500"],
        },

        tk_d0GTCEWbGLuAua7: {
          messages: [],
          // messages: ["Donation Amount: $5000"],
        },
      },

      priceIds: {
        tk_CoHMzgGR158saAH: "price_1OC1pCD0vTZ4QB9c1PfbOepx",
        tk_Z8a9fe56BHx9bHr: "price_1O5GnOD0vTZ4QB9cbW0nTHg9",
        tk_tlZEJ3dMf2U4WsJ: "price_1O5GnOD0vTZ4QB9c8w8gvtkI",
        tk_uSBo0TmAenxf2CP: "price_1O5GnPD0vTZ4QB9cJqoWwP3T",
        tk_M2iH8xEoWy9DGWO: "price_1O5GnPD0vTZ4QB9cEenRkBZH",
        tk_tAf85haTa6yuouR: "price_1O5GnQD0vTZ4QB9crmlRRSL4",
        tk_VVhdyaMxvqd4z0K: "price_1O5GnQD0vTZ4QB9ceKuB6Tq5",
        tk_rTolQ7vpgNhGYWt: "price_1O5GnRD0vTZ4QB9cupl23NLE",
        tk_EtX0chUOgRB9FY5: "price_1O5GnRD0vTZ4QB9cvfAc4K4q",
        tk_S3V709sEL88Mcx4: "price_1O5GnRD0vTZ4QB9c8gnDIQr1",
        tk_d0GTCEWbGLuAua7: "price_1OC1pCD0vTZ4QB9ckKOvyQol",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    // NavBar,
    VueElementLoading,
    StripeCheckout,
    vueThermometer,
  },
  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const bykeyMap = {};
        list.forEach((v) => {
          bykeyMap[v.table] = true;
        });

        return Object.keys(bykeyMap);
      }

      return [];
    },
    uniq1(list) {
      if (list && list.length > 0) {
        const bykeyMap = [];
        list.forEach((v) => {
          if (
            v.seat.split("Seat ")[1] == "1" ||
            v.seat.split("Seat ")[1] == "6"
          ) {
            bykeyMap.push(
              v.table +
                " " +
                "Seat " +
                (v.seat.split("Seat ")[1] == "1" ? "1 to 5" : "6 to 10")
            );
          }
        });

        return bykeyMap;
      }

      return [];
    },
    datad2(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };

      const zx = [];
      const zobj = {
        0: {
          texts: [1, 2, 3, 4, 5],
          x: 70,
          y: 100,
          count: 5,
        },
        1: {
          texts: [11, 10, 9, 8, 7, 6],
          x: 140,
          y: 70,
          count: 6,
        },
        2: {
          texts: [12, 13, 14, 15, 16, 17],
          x: 210,
          y: 100,
          count: 6,
        },
        3: {
          texts: [22, 21, 20, 19, 18],
          x: 280,
          y: 120,
          count: 5,
        },
        4: {
          texts: [23, 24, 25, 26, 27],
          x: 350,
          y: 150,
          count: 5,
        },
        5: {
          texts: [30, 29, 28],
          x: 420,
          y: 280,
          count: 3,
        },
        6: {
          texts: [31, 32, 33],
          x: 490,
          y: 280,
          count: 3,
        },
        7: {
          texts: [38, 37, 36, 35, 34],
          x: 560,
          y: 150,
          count: 5,
        },
        8: {
          texts: [39, 40, 41, 42, 43],
          x: 630,
          y: 120,
          count: 5,
        },
        9: {
          texts: [49, 48, 47, 46, 45, 44],
          x: 700,
          y: 100,
          count: 6,
        },
        10: {
          texts: [50, 51, 52, 53, 54, 55],
          x: 770,
          y: 70,
          count: 6,
        },
        11: {
          texts: [61, 60, 59, 58, 57, 56],
          x: 840,
          y: 100,
          count: 6,
        },
      };
      for (let j = 0; j < 12; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x,
            y: zobj[j].y + k * 80,
            val: seat[10],
            distance: 10,
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }

      let recs = [];
      this.dict = {};
      this.dictlist = {};

      listdata.forEach((r) => {
        r.availableSeating.forEach((r1) => {
          if (r.ticketCode == "tk_yfT2rMpbdhrWJNg") {
            this.dictlist[r1.table.split("Table ")[1]] = "#0EA293";
          }

          if (r.ticketCode == "tk_bMMncT7WBaGEmz0") {
            this.dictlist[r1.table.split("Table ")[1]] = "#a19140";
          }

          if (r.ticketCode == "tk_ZNDRjDCAnOGEjAn") {
            this.dictlist[r1.table.split("Table ")[1]] = "#C07F00";
          }
        });

        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord) - 1);
      });

      return [...zx];
    },
    drawseatsd2(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "900px")
        .attr("height", "555px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;

        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? vm.dictlist[v.text] || "pink"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 130)
        .attr("height", 120)
        .attr("x", 400)
        .attr("y", 125)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 425)
        .attr("y", 195)
        .text("Dance Floor")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 130)
        .attr("height", 115)
        .attr("x", 400)
        .attr("y", 10)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 445)
        .attr("y", 70)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.selectionList = [];
      this.redeemPriceId = "";
      this.isDonationPay = false;
      this.isDonation = false;
      this.email = "";
      this.reserveFormAccept = false;
      this.$refs.modal.open();
      this.selected = idx;
      this.pnumber = "";
      this.maddress = "";
      this.baddress = "";
      this.company = "";
      this.followup = false;

      const rr = this.individualTick(this.TicketsResult);
      rr.forEach((s) => {
        if (s.ticketCode == this.TicketsResult[this.selected].ticketCode) {
          this.isDonation = true;
        }
      });

      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_yfT2rMpbdhrWJNg"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_bMMncT7WBaGEmz0"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_ZNDRjDCAnOGEjAn"
      ) {
        //  this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
        this.availSeating = this.uniq1(
          this.TicketsResult[idx].availableSeating
        )[0];
      } else {
        this.availSeating = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    fetchStatus() {
      const req = "https://myle-19701.uc.r.appspot.com/api/event_total_sales";
      const self = this;
      //self.thermoResult = 0;
      axios
        .post(req, {
          eventId: "2qkkYFnZtnfPkK",
          userId: "",
        })
        .then((res) => {
          if (res && res.data && res.data.Result) {
            self.thermoResult =
              res.data.Result.totalSales > 0
                ? res.data.Result.totalSales / 1000
                : 0;
          }
        });
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      payloadToPush.extraInfo = payloadToPush.extraInfo || {};
      payloadToPush.extraInfo["phone_number"] = this.pnumber;
      payloadToPush.extraInfo["mailing_address"] = this.maddress;
      payloadToPush.extraInfo["billing_address"] = this.baddress;
      payloadToPush.extraInfo["company"] = this.company;
      payloadToPush.extraInfo["follow_up"] = this.followup;

      if (this.isDonation) {
        payloadToPush.extraInfo = payloadToPush.extraInfo || {};
        payloadToPush.extraInfo["anonymous_donation"] = this.isDonationPay;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_yfT2rMpbdhrWJNg") {
        const selectedRecs = [];
        this.selectionList.forEach((r) => {
          selectedRecs.push(r.alias);
        });
        payloadToPush.totalTickets = selectedRecs.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: selectedRecs,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_ZNDRjDCAnOGEjAn") {
        const selectedRecList = [];
        if (this.availSeating.endsWith("1 to 5")) {
          const initalval = this.availSeating.split("Seat 1 to 5")[0];
          selectedRecList.push(initalval + "- Seat 1");
          selectedRecList.push(initalval + "- Seat 2");
          selectedRecList.push(initalval + "- Seat 3");
          selectedRecList.push(initalval + "- Seat 4");
          selectedRecList.push(initalval + "- Seat 5");
        } else if (this.availSeating.endsWith("6 to 10")) {
          const initalval = this.availSeating.split("Seat 6 to 10")[0];
          selectedRecList.push(initalval + "- Seat 6");
          selectedRecList.push(initalval + "- Seat 7");
          selectedRecList.push(initalval + "- Seat 8");
          selectedRecList.push(initalval + "- Seat 9");
          selectedRecList.push(initalval + "- Seat 10");
        }
        //  payloadToPush.totalTickets = selectedRecList.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: selectedRecList,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;

            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchLastSales: function () {
      const vm = this;
      const self = this;
      // self.thermoResult = 0;
      axios
        .post("https://myle-19701.uc.r.appspot.com/api/event_total_sales", {
          eventId: "2qkkYFnZtnfPkK",
          userId: "",
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.Result &&
            res.data.Result.lastOrders &&
            res.data.Result.lastOrders.length > 0
          ) {
            vm.salesRecords = res.data.Result.lastOrders;
          }

          if (res && res.data && res.data.Result) {
            self.thermoResult =
              res.data.Result.totalSales > 0
                ? res.data.Result.totalSales / 1000
                : 0;
          }
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredColumbusUrbanLeagueEmpowermentDay_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_CoHMzgGR158saAH: null,
            tk_Z8a9fe56BHx9bHr: null,
            tk_tlZEJ3dMf2U4WsJ: null,
            tk_uSBo0TmAenxf2CP: null,
            tk_M2iH8xEoWy9DGWO: null,
            tk_tAf85haTa6yuouR: null,
            tk_VVhdyaMxvqd4z0K: null,
            tk_rTolQ7vpgNhGYWt: null,
            tk_EtX0chUOgRB9FY5: null,
            tk_S3V709sEL88Mcx4: null,
            tk_d0GTCEWbGLuAua7: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          vm.drawseatsd2(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  destroyed() {
    clearInterval(this.intID);
  },
  mounted() {
    this.fetchStatus();
    this.fetchLastSales();
    this.intID = setInterval(() => {
      this.fetchLastSales();
      this.fetchStatus();
    }, 30000);
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidPhoneNum() {
      return (
        (this.pnumber || "").match(/\d/g) &&
        (this.pnumber || "").trim().length >= 10
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Reuniting Warriors Gathering 2024 - Donations",
    meta: [
      {
        name: "description",
        content: "MYLE - Reuniting Warriors Gathering 2024 - Donations",
      },

      {
        name: "keywords",
        content: "MYLE - Reuniting Warriors Gathering 2024 - Donations",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/reuniting-warriors-gathering-2024-donations-widget",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 922px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 922.1px) {
  #d2 {
    overflow-x: hidden;
  }
}

.cred {
  background: #c07f00 !important;
  border: 1px solid #c07f00 !important;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.cgold {
  background: #a19140;
  border: 1px solid #a19140 !important;
}

/* The animation code */
@keyframes example {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

/* The element to apply the animation to */
.texts {
  animation-name: example;
  animation-duration: 2s;
  display: block;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  color: rgb(20, 81, 67);
  animation-fill-mode: backwards;
}

.fullwidth {
  width: 100%;
}

.amount {
  font-weight: bold;
}

.fullName {
  font-weight: bold;

  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 24px;
}
</style>
