<template>
  <div>
    <nav-bar />
    <!-- text -->
    <div style="margin-top: 80px" class="px-5 mx-auto">
      <p class="text-center">
        MYLE (MAKE YOUR LIFE ENTERTAINING) TERMS & CONDITIONS
      </p>
      <p class="mt-5">Last Revision Date: January 13, 2022</p>
      <p class="font-weight-bold">
        YOUR USE OF THIS SITE IS CONDITIONED UPON YOUR ACCEPTANCE OF THESE
        TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR USE THIS
        SITE. <br />
        WE RESERVE THE RIGHT TO UPDATE THESE TERMS AT ANY TIME BY POSTING AN
        UPDATED COPY OF THE TERMS TO THE SITE AND YOU WAIVE THE RIGHT TO NOTICE
        OF ANY SPECIFIC CHANGES TO THESE TERMS. BY CONTINUING TO ACCESS AND USE
        THE SITE AFTER THESE TERMS ARE UPDATED, YOU AGREE TO THE UPDATED TERMS.
      </p>

      <p>
        This website and any MYLE mobile applications (collectively, the "Site")
        are operated by Make Your Life Entertaining, Inc., d/b/a MYLE App, a
        Delaware corporation having a principal place of business at 175 S. 3rd
        Street, Suite 200, Columbus, Ohio 43215 (“MYLE,” "we,” or “us”). These
        Terms & Conditions (these “Terms”) govern your use of the Site and any
        purchases made through this Site. By accessing or using this Site, you
        agree to these Terms and to our Privacy Policy viewable at
        <a href="https://www.myle.com/privacy"> Privacy Policy</a>. If you do
        not agree to these Terms, do not access or use this Site. If you have
        any questions about these Terms, please contact us at
        <a href="#"> hello@myle.com</a>.
      </p>

      <div>
        <h4>Privacy</h4>
        <p>
          Use of this Site is subject to our Privacy Policy, which is accessible
          here:
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.myle.com/privacy"
            >Privacy Policy</a
          >. ("Privacy Policy"), and our Privacy Policy is incorporated into,
          and forms an integral part of, these Terms. Our Privacy Policy
          describes how we process personal information, including the types of
          personal information that we collect, the purposes for which we use
          it, the types of third parties with whom we share it, and any rights
          you may have with respect to such personal information. The Privacy
          Policy also sets forth the types and categories of personal
          information you are permitted to provide MYLE, and your
          responsibilities and obligations with respect to such personal
          information. Please review our Privacy Policy carefully.
        </p>
      </div>

      <div>
        <h4>Use of Site</h4>
        <p>
          The Site is designed for use by individuals, event hosts or promoter
          (collectively, “Promoters”), and MYLE marketing affiliates (each a
          “MYLE Affiliate”) (all of which are collectively be referred to as
          “Users” or each as a “User” or “you”).
        </p>
        <p>
          A User may not modify, copy, distribute, transmit, display, perform,
          reproduce, publish, license, create derivative works from, transfer,
          or sell any of the content or communications featured or displayed on
          the Site ("Content"), including without limitation text, graphics,
          photographs, images, moving images, sound, illustrations, information,
          software, products or services and the arrangements thereof, except as
          expressly permitted hereunder. Each User acknowledges that any
          unauthorized use of any Content could cause irreparable harm to us and
          agrees that in the event of any such unauthorized use, we will be
          entitled to an injunction in addition to any other remedies available
          at law or in equity. Subject to any expressly stated restrictions or
          limitations on the Site relating to specific material, a User may
          electronically copy and/or print hard copy portions of Content solely
          for its own noncommercial use, or to place an order with us. Any other
          use of Content, including the modification, reproduction,
          distribution, republication, display or transmission of any Content,
          without our prior written permission is strictly prohibited.
        </p>
        <p>
          As a User, you agree not to collect and/or use any product
          descriptions, images, listings or prices except for your own personal,
          noncommercial use or to place an order with us.
        </p>
        <p>
          You agree that you will not reproduce, duplicate, copy, sell, resell
          or exploit for any commercial purposes, any portion of the Site or use
          or access to the Site, including without limitation any "scraping" or
          using any robot, spider or other automated means. Running or
          displaying this Site or any information or material displayed on this
          Site in frames, via "in-line" linking or through similar means on
          another Web site without our prior written permission is prohibited.
          Any permitted links to this Web site must comply with all applicable
          laws, rule and regulations. You may not use any meta tags or other
          "hidden text" utilizing our name or any of our trademarks, or those of
          any manufacturer of products featured on this Site, without express
          written permission. Additionally, you agree that you will not: (a)
          take any action that, in our sole discretion, imposes, or may impose
          an unreasonable or disproportionately large load on our
          infrastructure; (b) interfere or attempt to interfere with the proper
          working of the Site or any activities conducted on the Site; or (c)
          bypass any measures we may use to prevent or restrict access to the
          Site.
        </p>
        <p>
          All trademarks, service marks, and trade names (collectively the
          “Marks”) that appear on this Site are proprietary to MYLE, or the
          other respective owners of such Marks. You may not display or
          reproduce the Marks in any manner without the prior written consent of
          MYLE, and you may not remove or otherwise modify in any manner any
          trademark notices from any content offered or received through the
          Site.
        </p>
        <p>
          Notwithstanding the foregoing, Promoters and MYLE Affiliates may use
          the Site and Content solely for purposes of marketing Events (as
          hereinafter defined) or to promote use of the Site by others provided
          that all Marks remain the property of MYLE and may be used strictly in
          accordance with the foregoing purpose only. All use of the Site by
          MYLE Affiliates is subject to the terms of set forth at
          <a
            href="https://myle.com/affiliate"
            target="_blank"
            rel="noopener noreferrer"
          >
            MYLE - Affiliate</a
          >. Each Promoter grants MYLE the right to use its name and logo, and
          any trademarks, service marks, or trade names that its includes in any
          Posting (as hereinafter defined).
        </p>
      </div>

      <div>
        <h4>Accounts and Registration</h4>
        <p>If you choose to create a MYLE account (“Account”), you must:</p>
        <ul>
          <li>
            Provide us with your name, a valid e-mail address, and such other
            information as we may require from time to time; and
          </li>
          <li>Create a username and password.</li>
        </ul>
        <p>
          <br />
          You represent and warrant that the information you provide to us upon
          creating an Account will at all times be true, accurate, current, and
          complete. Your Account is personal to you, and you may not share your
          account information with, or allow access to your account by, any
          third party. You are solely responsible for maintaining the
          confidentiality of your password(s) and for restricting access to your
          computer, and for all activities that occur under your Account or
          password. You will be solely responsible for the losses incurred by us
          and others due to any unauthorized use of your account. You agree to
          notify us immediately of any unauthorized use of your Account and/or
          password(s) and any other breach of security relating to the Site. We
          reserve the right to terminate any Account at any time in our sole
          discretion, including without limitation for any failure to comply
          with these Terms, any fraud or abuse, or any misrepresentation that
          you or anyone using your Account may make to us.
        </p>
      </div>

      <div>
        <h4>POSTING CONTENT</h4>
        <p>
          These Terms govern the Posting of Content by any User and sets forth
          our rights with respect to any text, photograph, video, post,
          communication, music, audio/sound recording, artwork, ratings, reviews
          or other materials or information you post or submit to the Site
          (“Post” or "Posting"). By Posting to the Site, you agree to the
          following:
        </p>
        <ul>
          <li>
            your Post is original, accurate, created by you, and not an
            impersonation or infringement of the rights of any other individual
            or entity;
          </li>
          <li>
            you are over the age of majority and have all necessary rights to
            Post the Content;
          </li>
          <li>
            your Post is not false, misleading, offensive, profane, vulgar,
            obscene, derogatory, defamatory, libelous, slanderous, or otherwise
            inappropriate;
          </li>
          <li>
            your Post does not contain content which infringes any third party's
            copyright, patent, trademark, trade secret, right of publicity,
            right of privacy, moral rights, and/or any other applicable personal
            or proprietary rights;
          </li>
          <li>
            your Post does not contain content which violates any law, statute,
            ordinance or regulation or content that engages in, encourages,
            advocates, or provides instructions for conduct that would
            constitute a criminal or civil offense under any law, statute,
            ordinance or regulation;
          </li>
          <li>
            your Post does not contain or promote material or content that is,
            or may reasonably be considered to be, hate speech, whether directed
            at an individual or group, or that is otherwise unlawfully
            threatening or unlawfully harassing to any individual, partnership,
            corporation, or political body;
          </li>
          <li>
            your Post does not contain personally identifiable information about
            an identified or reasonably identifiable individual or private
            information (including sensitive information) without obtaining
            prior written consent from that individual to Post such information
            with MYLE;
          </li>
          <li>
            your Post does not contain programming or materials containing any
            Trojan horses, viruses, worms, harmful code, or other potentially
            damaging information, programs, or file; and
          </li>
          <li>
            MYLE’s use of your Post does not violate these Terms and will not
            cause injury to any person or entity.
          </li>
        </ul>
        <p>
          <br />MYLE does not and cannot review all Posts and is not in any
          manner responsible for any Posts made by our Users. MYLE will have no
          obligation to publish any Post. MYLE reserves the right to block or
          remove any Post and/or any User at any time in its sole and absolute
          discretion and in furtherance of our Code of Conduct. Except for any
          personally identifiable information we may collect from you under the
          guidelines established in our Privacy Policy, any Posting will be
          considered non- confidential and non-proprietary. MYLE will have no
          confidentiality obligations with respect to the Submissions.
        </p>
        <p>By making a Post, you grant to MYLE:</p>
        <ul>
          <li>
            a non-exclusive, worldwide, perpetual, irrevocable, fully paid-up,
            royalty-free, fully sublicenseable and transferable right and
            license to access, use, reproduce, modify, adapt, publish,
            translate, create derivative works of, distribute, display, and
            perform such Post(s), in whole or in part, in any media, format or
            technology, whether now known or hereafter discovered, and in any
            manner including, but not limited to, all promotional, public
            viewing and/or commenting (whether on MYLE's web pages or on third
            party web pages), advertising, marketing, merchandising, publicity,
            and commercial uses and ancillary uses thereof, without any further
            notice or payment to or permission needed from you (except where
            prohibited by law);
          </li>
          <li>
            an unrestricted, worldwide, perpetual, irrevocable, fully paid-up,
            royalty-free, fully sublicensable and transferable right and license
            to use your name, nickname, user name, biographical information,
            vocal recordings of you, and/or any illustrations, photographic or
            video graphic clips, portraits, likenesses or pictures of you or any
            other indicia of your right of publicity rights (collectively "Your
            Personal Content") as contained in your Post(s), in composite or
            distorted form or as otherwise incorporated into other creative
            works of authorship, in any media, format or technology, whether now
            known or hereafter discovered, and in any manner including, but not
            limited to, all promotional, public viewing and/or commenting
            (whether on MYLE's web pages or on third party web pages),
            advertising, marketing, merchandising, publicity, and commercial
            uses and ancillary uses thereof, without any further notice or
            payment to or permission needed from you (except where prohibited by
            law).
          </li>
        </ul>
        <p>
          <br />You also agree to waive any applicable moral rights as contained
          in your Post(s) for any of the proposed uses listed above.
        </p>
        <p>
          By Posting, you agree to release, hold harmless and indemnify on
          behalf of yourself and your successors, assigns and representatives,
          MYLE and each of its respective officers, directors and employees (the
          “MYLE Parties”) from and against any and all claims, suits, actions,
          demands, liabilities and damages of any kind whatsoever arising out of
          or in connection with the use of such Post, including, without
          limitation, any and all claims for false advertising, copyright
          infringement, invasion of privacy, violation of the right of publicity
          or of moral rights, and/or defamation. Without limiting the foregoing,
          in no event will you be entitled to, and you waive any right to,
          enjoin, restrain or interfere with use of the Post or Your Personal
          Content embodied in such Post or the exploitation of any of the MYLE's
          rights as noted above.
        </p>
        <p>
          You acknowledge that you may not terminate or rescind the grants of
          rights and licenses and/or the releases contained in this Agreement at
          a later time. MYLE does not guarantee that you will have any
          opportunity to edit or delete your Submission. You acknowledge that
          you are solely responsible for the contents of any Submission(s).
        </p>
        <p>
          Finally, we appreciate hearing from the public and welcome your
          comments regarding the Site and Content. If you send us any creative
          ideas, suggestions, inventions, or materials (“Creative Ideas”), you
          agree that we will (a) own, exclusively, all now known or later
          discovered rights to the Creative Ideas; (b) not be subject to any
          obligation of confidentiality and will not be liable for any use or
          disclosure of any Creative Ideas; and (c) be entitled to unrestricted
          use of the Creative Ideas for any purpose whatsoever, commercial or
          otherwise, without compensation to you or any other person.
        </p>
      </div>

      <div>
        <h4>Responsibilities of Promoters</h4>

        <p>
          As a social event and social connection platform, we permit Promoters
          to Post events to the Site (each an “Event”). As a condition to
          posting its Event, Promoter agrees that all information posted to the
          Site will be accurate, complete, reliable and current. Promoter will
          promptly update or remove any inaccurate, incomplete or misleading
          information about its Event of if the Event is cancelled or changed.
          Promoter agrees it is entirely responsible and liable for the content
          of its Postings to the Site (including to the extent it directs MYLE
          to make the Posting on its behalf). To the fullest extent permissible
          by law, Promoter agrees to indemnify and hold the MYLE Parties
          harmless for any and all damages and third-party claims arising or
          related to Promoter’s Event and/or Postings including, but not limited
          to, refund requests, third-party claim of personal injury or death,
          property damage (real or personal), or other damages related to the
          Event.
        </p>
      </div>

      <div>
        <h4>Making a Purchase</h4>
        <p>
          MYLE strives to ensure that all events, products or items are
          described as accurately as possible on the Site. However, we do not
          warrant that the descriptions or other Content on this Site is
          accurate, complete, reliable, current, or error-free. MYLE reserves
          the right to update all times, dates, locations, agendas, content,
          specifications, and prices of Events, products and services described
          or depicted on the Site at any time. All purchases on the Site are
          made subject to the foregoing.
        </p>

        <p>
          The price for all tickets available for purchase through the Site will
          be displayed on the Site. You agree to pay all charges, including
          without limitation any applicable taxes and shipping, handling and all
          processing charges, if you make a purchase. If you make a purchase,
          you will be asked to supply certain information, including your
          billing address and credit card or other payment information. You
          agree that all information that you provide to us will be accurate,
          current and complete. We will charge your credit card when your item
          is purchased. All monetary transactions on the Site take place in U.S.
          dollars.
        </p>

        <p>
          If you are a Promoter, you agree that we will charge you all
          processing charges and fees on ticket sales as the same are set forth
          on the Site at the time you Post (or request us to Post) the Event. We
          will deduct all applicable processing fees from the amounts remitted
          to you.
        </p>

        <p>
          All ticket sales through the Site are for personal use and not for
          commercial resale. We may in our discretion refuse and/or cancel any
          order that we believe may lead to the prohibited commercial resale or
          redistribution of the tickets purchased from us. Accessing this Site
          from territories where its contents are unlawful is prohibited. It is
          your responsibility to ascertain and obey all applicable laws and
          regulations regarding your use of the event tickets. By purchasing an
          event ticket, you agree that you will comply with all rules, policies
          and procedures of the concert venue or event host (“Venue”), and that
          all such rules and policies are incorporated into these Terms by
          reference. It is your responsibility to familiarize yourself with the
          rules of the applicable Venue prior to making a purchase.
        </p>

        <p>
          We reserve the right to refuse to process or complete any transaction,
          and to cancel any transaction, in our sole discretion.
        </p>
        <p>
          The Site currently uses Stripe to process all direct credit card
          payments. Stripe accepts payments through various credit cards,
          including, but not limited to: Visa, MasterCard, American Express and
          Discover, as detailed on the applicable payment screen. The Site also
          accepts payments via PayPal, CashApp, Zelle and Venmo.
        </p>
      </div>

      <div>
        <h4>Returns, Refunds and Cancellations</h4>
        <p>
          All Content on the Site is provided without warranty of any kind and
          all ticket sales are final and non-refundable. Ticket sales are
          exclusively subject to the refund or cancellation policy of the
          Promoter. Please contact the Promoter with any type of request for a
          refund, cancellation, or exchange of your event tickets. MYLE does not
          issue refunds for any reason.
        </p>
      </div>

      <div>
        <h4>Subscriptions</h4>
        <p>
          Continuous Subscriptions. WHEN YOU REGISTER FOR A SUBSCRIPTION, YOU
          EXPRESSLY ACKNOWLEDGE AND AGREE THAT (A) MYLE (OR OUR THIRD PARTY
          PAYMENT PROCESSOR) IS AUTHORIZED TO CHARGE YOU MONTHLY FOR YOUR
          SUBSCRIPTION (IN ADDITION TO ANY APPLICABLE TAXES AND OTHER CHARGES)
          FOR AS LONG AS YOUR SUBSCRIPTION CONTINUES, AND (B) YOUR SUBSCRIPTION
          IS CONTINUOUS UNTIL YOU CANCEL IT OR WE SUSPEND OR STOP PROVIDING
          ACCESS TO THE SITES IN ACCORDANCE WITH THESE TERMS. YOU MAY CANCEL
          YOUR SUBSCRIPTION AT ANY TIME, SUBJECT TO THE TERMS SET FORTH ON OUR
          SITE AND HEREIN.
        </p>

        <p>
          Cancellation Policy. IN ORDER TO CANCEL YOUR SUBSCRIPTION, YOU MUST DO
          SO AT LEAST TEN (10) DAYS PRIOR TO YOUR NEXT BILLING DATE WHICH IS
          SHOWN ON THE MY ACCOUNT PAGE.TO CANCEL YOU MUST EITHER EMAIL US AT
          <a href="#"> hello@myle.com</a>, OR LOG INTO YOUR ACCOUNT AND SELECT
          CANCEL MY SUBSCIPTION. ANY CANCELLATION RECEIVED WITH LESS THAN TEN
          (10) DAYS UNTIL THE NEXT BILLING DATE WILL NOT BE EFFECTIVE UNTIL THE
          FOLLOWING BILLING PERIOD AND YOU WILL BE RESPONSIBLE FOR ALL CHARGES
          (INCLUDING ANY APPLICABLE TAXES AND OTHER CHARGES) INCURRED PRIOR TO
          THE CANCELLATION OF YOUR SUBSCRIPTION.
        </p>

        <p>
          Discounted and Free Trials: From time to time, to the extent legally
          permitted, we may offer discounted and/or free trials of certain
          subscription products for specified periods of time without payment or
          with discounted payment. If we offer you a discounted or free trial,
          the specific terms of that trial will be provided in the marketing
          materials describing the particular trial or at registration.
        </p>

        <p>
          ONCE YOUR DISCOUNTED OR FREE TRIAL ENDS, WE (OR OUR THIRD PARTY
          PAYMENT PROCESSOR) WILL BEGIN BILLING YOUR DESIGNATED PAYMENT METHOD
          ON A RECURRING BASIS AT THE THEN APPLICABLE PRICE FOR YOUR
          SUBSCRIPTION (PLUS ANY APPLICABLE TAXES AND OTHER CHARGES) FOR AS LONG
          AS YOUR SUBSCRIPTION CONTINUES, UNLESS YOU CANCEL YOUR SUBSCRIPTION
          PRIOR TO THE END OF YOUR FREE OR DISCOUNTED TRIAL. INSTRUCTIONS FOR
          CANCELING YOUR SUBSCRIPTION ARE AS DESCRIBED ABOVE. PLEASE NOTE THAT
          YOU WILL NOT RECEIVE A NOTICE FROM US THAT YOUR DISCOUNTED OR FREE
          TRIAL HAS ENDED OR THAT THE PAID PORTION OF YOUR SUBSCRIPTION HAS
          BEGUN. WE RESERVE THE RIGHT TO MODIFY OR TERMINATE DISCOUNTED AND/OR
          TRIALS AT ANY TIME, WITHOUT NOTICE AND IN OUR SOLE DISCRETION
        </p>
      </div>

      <div>
        <h4>Discount Codes, Promotions</h4>
        <p>
          From time to time in our sole discretion, we may offer “discount
          codes,” “promotional codes,” “promo codes,” or “offer codes” through a
          variety of promotional activities and communications (collectively
          referred to herein as “offer codes”) that are redeemable towards a
          purchase on the Site. Such offer codes may be subject to certain
          exclusions or other restrictions as determined and communicated by us.
          Only valid offer codes provided or promoted by MYLE will be honored at
          checkout. Codes supplied or promoted by third-parties unauthorized by
          us will be invalid. Each offer code provided by us is non-transferable
          and valid for single use on the Site. Offer codes cannot be redeemed
          for cash or any cash equivalent; no substitutions or credits allowed.
          Expiry dates may apply to each offer code. Offer codes are void if
          copied, transferred, sold, exchanged or expired, and where prohibited.
          Requests not complying with all offer code requirements will not be
          honored.
        </p>

        <p>
          Sponsors. From time to time, we may also engage spokespeople,
          influencers, bloggers, or other individuals or entities who have been
          compensated or incentivized to speak on behalf of MYLE. When you
          receive an offer code via a third-party source, such as a television
          or radio show host, please note that such individuals may have been
          compensated by us for their statements.
        </p>
      </div>

      <div>
        <h4>Code of Conduct</h4>
        <p>
          By accessing or using any Site or any other feature provided through
          our Site, including but not limited to, when Posting to the Site, you
          agree to abide by the following standards of conduct. You agree that
          you will not, and will not authorize or facilitate any attempt by
          another person or entity to:
        </p>

        <ul>
          <li>Use the Site in breach of these Terms;</li>
          <li>
            Reproduce, duplicate, copy, sell, resell or exploit for any
            commercial purposes, any portion of any Site or use or access to the
            Site;
          </li>
          <li>
            Harass, threaten, stalk or intentionally embarrass or cause distress
            to another person or entity;
          </li>
          <li>Impersonate another person or entity;</li>
          <li>
            Promote, solicit, or participate in any multilevel marketing or
            pyramid schemes;
          </li>
          <li>
            Solicit personally identifiable information from or exploit any
            individual under eighteen (18) years of age;
          </li>
          <li>
            Engage in disruptive activity such as sending multiple messages in
            an effort to monopolize the forum or posting unrelated to a forum’s
            designated topic or theme;
          </li>
          <li>
            Introduce viruses, worms, Trojan horses, harmful code, or any
            software or other materials that contain a component harmful to the
            website;
          </li>
          <li>
            Gain unauthorized access to any computer system or nonpublic portion
            of the Sites or interfere with or disrupt the Sites, servers, or
            networks connected to the Sites;
          </li>
          <li>
            Invade the privacy of any person, including posting personally
            identifying or otherwise private or sensitive information about a
            person without their consent or harvesting personally identifiable
            information about the Sites’ users;
          </li>
          <li>
            Use the Site in an illegal way or to commit an illegal act in
            relation to the Site or that otherwise results in fines, penalties,
            and other liability; or access the Site from a jurisdiction where it
            is illegal or unauthorized.
          </li>
        </ul>

        <p>
          <br />MYLE cannot and does not assure that other Users are or will be
          compliant with the foregoing Code of Conduct or any other provisions
          of these Terms, and, as between you and us, you hereby assume all risk
          of harm or injury (physical or mental) resulting from any such lack of
          compliance.
        </p>
      </div>

      <div>
        <h4>Links to Other Sites</h4>
        <p>
          The Site may include links to third party websites and applications.
          You are responsible for evaluating whether you want to access or use
          them. We are not responsible for and do not endorse any features,
          content, advertising, products, events or other materials on other
          websites or applications. You assume all risk and we disclaim all
          liability arising from your use of them
        </p>
      </div>

      <div>
        <h4>DMCA</h4>
        <p>
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under U.S. copyright law. If you
          believe in good faith that materials posted on the Site infringe your
          copyright, you (or your agent) may send us a “Notification of Claimed
          Infringement” requesting that the material be removed, or access to it
          blocked. Notices must comply with the DMCA and be sent to Make Your
          Life Entertaining, Inc., 175 S. 3rd Street, Suite 200, Columbus, Ohio
          43215.
        </p>
      </div>

      <div>
        <h4>DISCLAIMER</h4>
        <p>
          Released Parties Defined. “Released Parties” include MYLE, and its
          affiliates, and each of their officers, employees, directors, agents,
          partners, and licensors.
        </p>

        <p class="font-weight-bold">
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE SITE IS
          AT YOUR SOLE RISK, AND THE SITE AND ANY CONTENT, PRODUCTS OR SERVICES
          ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND THE RELEASED
          PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO EVENTS,
          PRODUCTS OR SERVICES LISTED ON THE SITE, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i)
          THE SITE WILL MEET YOUR REQUIREMENTS, (ii) THE SITE WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE CONTENT
          OBTAINED FROM THE SITE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY
          OF ANY PRODUCTS, EVENTS, OR SERVICES AVAILABLE ON OR THROUGH THE SITE
          WILL MEET YOUR EXPECTATIONS, OR (v) ANY ERRORS IN THE SITE WILL BE
          CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
          THROUGH THE USE OF THE SITE IS ACCESSED AT YOUR OWN DISCRETION AND
          RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER SYSTEM OR MOBILE DEVICE THAT RESULTS FROM THE USE OF ANY SUCH
          MATERIAL.
        </p>
      </div>

      <div>
        <h4>Limited Liability</h4>

        <p class="font-weight-bold">
          TO THE FULLEST EXTENT PERMISSIBLE BY APPLIABLE LAW, YOU EXPRESSLY
          UNDERSTAND AND AGREE THAT THE RELEASED PARTIES WILL NOT BE LIABLE TO
          YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
          EXEMPLARY DAMAGES ARISING OUT OF USE OF THE SITE OR ANY CONTENT POSTED
          THEREON, OR OTHERWISE RESULTING FROM THE PURCHASE OF TICKETS OR
          ATTENDANCE AT ANY EVENT POSTED ON THE SITE. THE FOREGOING INCLUDES BUT
          IS NOT LIMITED TO (i) ANY TYPE OF PHYSICAL, MENTAL OR REPUTATIONAL
          INJURY OR DEATH, (ii) PERSONAL OR REAL PROPERTY DAMAGES; (iii) THE USE
          OR INABILITY TO USE THE SITE; (iv) THE COST OF PROCUREMENT OF
          SUBSTITUTE GOODS OR SERVICES; (v) UNAUTHORIZED ACCESS TO OR ALTERATION
          OF YOUR TRANSMISSIONS OR DATA; (vi) STATEMENTS OR CONDUCT OF ANY USER
          OR THIRD PARTY ON THE SITE; (vii) YOUR RELIANCE ON CONTENT; OR (viii)
          ANY OTHER MATTER RELATING TO YOUR USE OF THE SITE OR ITS CONTENT. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
          LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH
          MAY NOT APPLY TO YOU. TO THE FULLEST EXTENT PERMISSIBLE BY APPLIABLE
          LAW, THE RELEASED PARTIES’ MAXIMUM LIABILITY TO YOU ARISING OUT OF OR
          IN CONNECTION WITH THE SITE OR YOUR USE OF ANY CONTENT, PRODUCTS OR
          SERVICES PROCURED THROUGH THE SITE, REGARDLESS OF THE CAUSE OF ACTION
          (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL
          NOT EXCEED $10 USD.
        </p>

        <p>
          You agree to defend, indemnify, and hold harmless the Released Parties
          from and against any claims, actions, or demands, including without
          limitation reasonable legal and accounting fees, alleging or resulting
          from (i) your use of or reliance on any Content, (ii) your breach of
          these Terms or the Code of Conduct. We will provide notice to you
          promptly of any such claim, suit, or proceeding.
        </p>
      </div>

      <div>
        <h4>Conflict Resolution</h4>

        <p>
          These Terms and the relationship between you and us will be governed
          by the laws of the State of Ohio without regard to its conflict of law
          provisions. You agree to submit to the personal and exclusive
          arbitration of any disputes relating to your use of the Site under the
          rules of the American Arbitration Association on a single plaintiff
          basis. Any such arbitration will be conducted in Columbus, Ohio. You
          agree to submit to the exclusive jurisdiction of arbiters in this
          forum and agree to waive all claims of personal or subject matter
          jurisdiction, to the extent permissible. You also acknowledge and
          understand that, with respect to any dispute arising out of or
          relating to your use of the Site:
        </p>

        <ul>
          <li>YOU AGREE TO ARBITRATION.</li>
          <li>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY.</li>
          <li>
            YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A
            PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY,
            OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY
            LAWSUIT INVOLVING ANY SUCH DISPUTE.
          </li>
          <li>
            YOU MUST FILE ANY CLAIM WITHIN ONE (1) YEAR AFTER SUCH CLAIM AROSE
            OR IT IS FOREVER BARRED.
          </li>
        </ul>
      </div>

      <div>
        <h4>Termination</h4>
        <p>
          Notwithstanding any of these Terms, we reserve the right, without
          notice or attendant liability, and in our sole discretion, to
          terminate your license to use this Site, and to block or prevent
          future access to and use of this Site for any reason or no reason.
          Upon termination, these Terms will still apply.
        </p>
      </div>

      <div>
        <h4>Waiver</h4>
        <p>
          Our failure to partially or fully exercise any rights or our waiver of
          any breach of these Terms by user shall not prevent our subsequent
          exercise of such right or be deemed a waiver by us of any subsequent
          breach by you of the same or any other term of these Terms. Our rights
          and remedies under these Terms and any other applicable agreement
          between you and us shall be cumulative, and the exercise of any such
          right or remedy shall not limit our right to exercise any other right
          or remedy.
        </p>
      </div>

      <div>
        <h4>Severability</h4>
        <p>
          If any of these Terms is be deemed invalid, void, or for any reason
          unenforceable, that term will be severed and will not affect the
          validity and enforceability of any remaining term or condition.
        </p>
      </div>

      <div>
        <h4>Changes to These Terms</h4>

        <p>
          MYLE reserves the right to change or modify these Terms, in whole or
          in part, at any time in its sole discretion upon notice to you by
          electronic means, including by posting such information and materials
          online at the Site. Any changes or modifications will be effective
          immediately upon posting the revisions to the Site, and you waive any
          right you may have to receive specific notice of such changes or
          modifications. Your continued use of this Site following any such
          changes confirms your acceptance of these Terms and such changes or
          modifications. If you do not agree to these Terms, you must stop
          accessing and using the Site.
        </p>
      </div>

      <div>
        <h4>Entire Agreement</h4>
        <p>
          These Terms, including any other terms or policies linked to from
          within these Terms constitute the entire agreement between you and us
          with respect to this Site and supersede all prior or contemporaneous
          communications and proposals, whether electronic, oral or written with
          respect to this Site, except as specifically set forth in these Terms.
          A printed version of these Terms and of any notice given in electronic
          form will be admissible in judicial or administrative proceedings
          based upon or relating to these Terms to the same extent and subject
          to the same conditions as other contracts. Any rights not expressly
          granted herein are reserved.
        </p>
      </div>

      <div>
        <h4>Contact Us</h4>
        <p>
          If these Terms do not address your question, or if you would like to
          report a violation of our Code of Conduct, please contact us as
          follows:
        </p>
        <p><b>E-mail:</b><a href="#"> hello@myle.com</a>.</p>
        <p>
          <b>Mail:</b> Make Your Life Entertaining, Inc., 175 S. 3rd Street,
          Suite 200, Columbus, Ohio 43215.
        </p>
      </div>
    </div>
    <!-- text -->

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
<style scoped>
.heading {
  font-size: 0.75rem;
  font-weight: bold;
}

li {
  list-style-type: disc;
  margin-left: 2rem;
}
</style>
