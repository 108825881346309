<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>

              <p>
                <b class="font-weight-bold">Ticket Price:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].price
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Service Fees:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].service
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Sales Tax:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].sales
                    : "0"
                }}
              </p>

              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Business Name</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business Name"
                required
                v-model="bname"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Address</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Address"
                required
                v-model="addressInfo"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">Phone Number</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="xxx-xxx-xxxx"
                required
                v-model="pnumber"
              />
            </div>
            <!-- <div
              class="col-md-6 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3">License</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="License"
                required
                v-model="license"
              />
            </div> -->
            <div
              class="col-md-12 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3 mr-3">Bonded</label>
              <input type="radio" value="Yes" v-model="bonded" id="h" />
              <label for="h" class="mr-2 p-1">Yes</label>
              <input type="radio" value="No" v-model="bonded" id="h" />
              <label class="p-1" for="h">No</label>
            </div>
            <div
              class="col-md-12 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3 mr-3">Insured</label>
              <input type="radio" value="Yes" v-model="insured" id="h1" />
              <label for="h" class="mr-2 p-1">Yes</label>
              <input type="radio" value="No" v-model="insured" id="h1" />
              <label class="p-1" for="h">No</label>
            </div>
            <div
              class="col-md-12 mb-1"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="mt-3 mr-3">Licensed</label>
              <input type="radio" value="Yes" v-model="licensed" id="h2" />
              <label for="h" class="mr-2 p-1">Yes</label>
              <input type="radio" value="No" v-model="licensed" id="h2" />
              <label class="p-1" for="h">No</label>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!-- <div class="col-md-6 mt-3">
                <label class="">Phone</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="xxx-xxx-xxxx"
                  required
                  v-model="phone"
                />
              </div> -->

            <!-- <div class="col-md-6 mt-3">
                <label class="">Age Range</label>
                <select class="form-control" v-model="ageRange">
                  <option
                    class="form-control"
                    v-bind:key="ageval + 'b'"
                    v-for="ageval in ageRangesList"
                    :value="ageval"
                  >
                    {{ ageval }}
                  </option>
                </select>
              </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? !invalidpnumber
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? pnumber.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? bname.trim().length < 1
                    : false) ||
                  (vlist.includes(TicketsResult[selected].ticketCode)
                    ? addressInfo.trim().length < 1
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Veterans Car_Show.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                Veterans Car Show
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                September 2, 2023 5:00pm
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/sQfB5sMuJZESusnR7"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Space Coast Convention Center
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              The Veterans Car Show Event at Cocoa, Florida is a fantastic way
              to honor our veterans while enjoying some of the most beautiful
              and unique cars on the road. Whether you're a car enthusiast or
              simply looking for a fun family outing, this event is not to be
              missed.
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                The Veterans Car Show Event located at the Space Coast
                Convention Center, Cocoa, Florida is an annual event that
                celebrates and honors the service of our nation's veterans while
                showcasing a variety of classic and modern cars. The event
                features a wide variety of vehicles, including classic cars,
                muscle cars, sports cars,and custom vehicles, all owned and
                maintained by car enthusiasts and veterans. Attendees will have
                the opportunity to view these beautiful vehicles up close, speak
                with the owners and learn about the history and design of each
                car.
                <br />
                <br />
                There are also raffles and auctions to raise money for various
                veterans' charities. The location of the event, Cocoa, Florida,
                adds to the attraction, as it is a beautiful coastal town that
                offers plenty of opportunities for fun and relaxation, such as
                fishing, and shopping.
                <br />
                <br />
                Overall, the Veterans Car Show Event at Cocoa, Florida is a
                fantastic way to honor our veterans while enjoying some of the
                most beautiful and unique cars on the road. Whether you're a car
                enthusiast or simply looking for a fun family outing, this event
                is not to be missed.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                General Public Tickets
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                Vendor Sign-up
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in vendorTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                Sponsorships
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in sponsorshipsTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-2 mt-1">
        <div class="text-center">
          <a
            href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/VCS23_Sponsorship.pdf"
            class="btn solid-btn mb-1 text-center"
            target="_self"
            >Sponsorship Deck
          </a>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-6 col-md">
          <div class="text-center">
            <a
              href="https://communityfundraising.woundedwarriorproject.org/index.cfm?fuseaction=donorDrive.personalCampaign&participantID=12849"
              class=""
              target="_self"
            >
              <div class="mt-4">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Wounded_Warrior_Project.png"
                  alt="Wounded Warrior Project img"
                  class="img-fluid"
                />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="download-content">
          <p
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 18px;
            "
          >
            For your convenience, we have partnered with MYLE as our ticket
            provider.

            <br />
            <br />
            MYLE - Make Your Life Entertaining is an Event and Entertainment
            platform.

            <br />
            <br />

            Be sure to download the MYLE Events App via
            <a
              href="https://www.myle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.myle.com</a
            >
            or in your iOS or Google Play Store under MYLE Events and be sure to
            use referral code “Purepromotions” during account set-up.

            <br />
            <br />
            The MYLE App provides seamless transportation options including
            Waze/Apple Maps/Google Maps/Uber and Lyft which are all integrated
            into the App for your traveling convenience.

            <br />
            <br />

            Note: Your tickets will be available on the app in the
            Accounts/Tickets section after purchase.

            <br />
            We look forward to seeing you soon.
          </p>
        </div>
      </div>
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      vlist: ["tk_z8SakRTBlduJSjk", "tk_wgiivByfpDViGvi", "tk_av5KXgDqPzTloQs"],
      bname: "",
      addressInfo: "",
      pnumber: "",
      bonded: "No",
      insured: "No",
      licensed: "No",
      license: "",
      required_power_needs: "No",
      ageRangesList: [
        "Under 21",
        "21-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
      ],
      ageRange: "Under 21",
      phone: "",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: ["tk_0tGoCZ9hOm5ZOl5"],

      vendorData: [
        "tk_z8SakRTBlduJSjk",
        "tk_wgiivByfpDViGvi",
        "tk_av5KXgDqPzTloQs",
        "tk_q0vQSH8IrT3tzbc",
      ],

      sponsorshipsData: [
        "tk_Li4bwoI3eWQNMSd",
        "tk_9KRCGEuhACRxoSp",
        "tk_9amFtEhWIhNZq7c",
      ],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,

      tkListData: {
        tk_0tGoCZ9hOm5ZOl5: {
          price: "$10",
          service: "$2.31",
          sales: "$0.8",
        },

        tk_z8SakRTBlduJSjk: {
          price: "$175",
          service: "$13.38",
          sales: "$12.24",
        },

        tk_wgiivByfpDViGvi: {
          price: "$250",
          service: "$18.41",
          sales: "$17.45",
        },

        tk_av5KXgDqPzTloQs: {
          price: "$250",
          service: "$18.41",
          sales: "$17.45",
        },

        tk_q0vQSH8IrT3tzbc: {
          price: "$350",
          service: "$25.11",
          sales: "$24.38",
        },

        tk_Li4bwoI3eWQNMSd: {
          price: "$35000",
          service: "$0",
          sales: "$0",
        },

        tk_9KRCGEuhACRxoSp: {
          price: "$12000",
          service: "$0",
          sales: "$0",
        },

        tk_9amFtEhWIhNZq7c: {
          price: "$3000",
          service: "$0",
          sales: "$0",
        },
      },

      tkList: {
        tk_0tGoCZ9hOm5ZOl5: {
          messages: [
            "Ticket Price: $10",
            "Processing fee of $2.31",
            "Sales Tax of $0.8",
            "Children 12 & Under (Free)",
          ],
        },

        tk_z8SakRTBlduJSjk: {
          messages: [
            "Ticket Price: $175",
            "Processing fee of $13.38",
            "Sales Tax of $12.24",
          ],
        },

        tk_wgiivByfpDViGvi: {
          messages: [
            "Ticket Price: $250",
            "Processing fee of $18.41",
            "Sales Tax of $17.45",
          ],
        },

        tk_av5KXgDqPzTloQs: {
          messages: [
            "Ticket Price: $250",
            "Processing fee of $18.41",
            "Sales Tax of $17.45",
          ],
        },

        tk_q0vQSH8IrT3tzbc: {
          messages: [
            "Ticket Price: $350",
            "Processing fee of $25.11",
            "Sales Tax of $24.38",
          ],
        },

        tk_Li4bwoI3eWQNMSd: {
          messages: ["Ticket Price: $35,000"],
        },

        tk_9KRCGEuhACRxoSp: {
          messages: ["Ticket Price: $12,000"],
        },

        tk_9amFtEhWIhNZq7c: {
          messages: ["Ticket Price: $3,000"],
        },
      },

      priceIds: {
        tk_0tGoCZ9hOm5ZOl5: "price_1NAXTvD0vTZ4QB9clZkdl1Xa",
        tk_z8SakRTBlduJSjk: "price_1NAXXDD0vTZ4QB9cOjxZMMdB",
        tk_wgiivByfpDViGvi: "price_1NAXYgD0vTZ4QB9c3ASdU43y",
        tk_av5KXgDqPzTloQs: "price_1NAtJoD0vTZ4QB9cqtuBphah",
        tk_q0vQSH8IrT3tzbc: "price_1NAtHHD0vTZ4QB9ckrOGkrmg",
        tk_Li4bwoI3eWQNMSd: "price_1NAsz4D0vTZ4QB9cEGPXhlnd",
        tk_9KRCGEuhACRxoSp: "price_1NAsxxD0vTZ4QB9cerPnjoyU",
        tk_9amFtEhWIhNZq7c: "price_1NAsM4D0vTZ4QB9cDhXj8zfq",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },

    vendorTickets(list) {
      return list.filter((t) => this.vendorData.includes(t.ticketCode));
    },

    sponsorshipsTickets(list) {
      return list.filter((t) => this.sponsorshipsData.includes(t.ticketCode));
    },

    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.license = "";
      this.licensed = "No";
      this.insured = "No";
      this.bonded = "No";
      this.bname = "";
      this.pnumber = "";
      this.addressInfo = "";

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      this.reserveFormAccept = false;
      //   this.phone = "";
      //   this.ageRange = "Under 21";
      this.fullname = "";
      this.email = "";
      this.isSubmitted = false;
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email,
        // extraInfo: {
        //   agerange: this.ageRange,
        //   phone: this.phone,
        // },
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (this.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo = {
          license: vm.license,
          licensed: vm.licensed,
          insured: vm.insured,
          bonded: vm.bonded,
          business_name: vm.bname,
          phone: vm.pnumber,
          address: vm.addressInfo,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            // vm.ageRange = "Under 21";
            // vm.phone = "";
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          vm.license = "";
          vm.licensed = "No";
          vm.insured = "No";
          vm.bonded = "No";
          vm.bname = "";
          vm.pnumber = "";
          vm.addressInfo = "";

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredVeteransCarShow_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_0tGoCZ9hOm5ZOl5: null,
            tk_z8SakRTBlduJSjk: null,
            tk_wgiivByfpDViGvi: null,
            tk_av5KXgDqPzTloQs: null,
            tk_q0vQSH8IrT3tzbc: null,
            tk_Li4bwoI3eWQNMSd: null,
            tk_9KRCGEuhACRxoSp: null,
            tk_9amFtEhWIhNZq7c: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invalidpnumber() {
      return (
        (this.pnumber || "").match(/\d/g) &&
        (this.pnumber || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Veterans Car Show",

    meta: [
      {
        name: "description",
        content: "MYLE - Veterans Car Show",
      },

      {
        name: "keywords",
        content: "MYLE - Veterans Car Show",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/veterans-car-show",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
