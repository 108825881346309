<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/columbus_cincinnati.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                15 Novel Columbus and Cincinnati Things to Do
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 500"
            >
              MYLE is taking a virtual road trip from our hometown in Columbus,
              Ohio to our newest MYLE city – Atlanta, Georgia. We’re
              highlighting some of the best Things to Do, Places to Go, and Food
              to Eat as we travel through Ohio, Kentucky, Tennessee, and finally
              arrive in Atlanta. Each state has a blog highlighting unique and
              hidden gems for you to experience.
              <a href="#download"> Download the free app</a>
              , and enjoy the trip!
              <br /><br />
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- MANUAL PURCHASE -->
      <section class="ptb-50">
        <div class="container">
          <div class="section-heading text-center">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Columbus
            </h2>
          </div>
          <hr />

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 1.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  1.
                  <a
                    href="https://cosi.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    COSI</a
                  >, The Center of Science and Industry - Find your superpower
                  in this collection of comics and costumes, including Luke
                  Cage's bullet hole-ridden hoodie from the 2016 movie by the
                  same name. The show runs through Sept. 5, 2022. Find out more
                  on
                  <a
                    href="https://web.myle.com/e/COSI%20-%20Center%20of%20Science%20and%20Industry:%20Marvel%20Universe%20Exhibit/Ak1goF3fyvSfeZ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MYLE</a
                  >.

                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  2.
                  <a
                    href="https://www.viralselfiemuseum.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Viral Selfie Museum</a
                  >
                  Tour 20+ art installations and keep your phone handy at
                  Columbus' first Selfie Museum! The self-serve photography
                  studio features nearly 20 art installation rooms.
                  <a
                    href="https://columbusunderground.com/viral-selfie-museum-now-open-in-university-district-tm1/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Owner Danielle White</a
                  >

                  said she wanted to bring a sense of camaraderie back to the
                  community. "The pandemic has robbed us of so much, including
                  at times, our smiles," she said.
                  <a
                    href="https://web.myle.com/e/Viral%20selfie/TRvCYbOsLBkMeS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Plan your visit on MYLE</a
                  >
                  <b>#blackowned</b>

                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 2.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  3.
                  <a
                    href="https://otherworldohio.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Otherworld Immersive Art</a
                  >
                  Immerse yourself in 40 rooms filled with large-scale
                  interactive art, mixed-reality playgrounds, and secret
                  passageways - interact with a world of science fiction and
                  fantasy.

                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  4.
                  <a
                    href="https://shadowboxlive.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shadowbox Live</a
                  >
                  sketch comedy/music/meta media - America’s largest resident
                  ensemble theatre company loved for its fresh, original
                  performances where the cast doubles as servers in their
                  cabaret. Experience a concept show playing on the Live Main
                  Stage or Up-Front space where "diversity through the power of
                  art, makes a world more humane."
                  <a
                    href="https://web.myle.com/e/Shadowbox%20Live/GnxG2qKcbQFkdb"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more on MYLE about Shadowbox Live.</a
                  >

                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 3.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  5.
                  <a
                    href="https://northmarket.org/location/downtown-market/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    North Market Downtown</a
                  >
                  and Short North Neighborhood - This year-round market is also
                  a business incubator. Jeni Britton Bauer of

                  <a
                    href="https://jenis.com/scoop-shops/#columbus"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jeni's Splendid Ice Creams</a
                  >
                  started crafting her luscious treats at the market in 2002.
                  Other Columbus restaurants have branched out to the market
                  with additional locations, like Stauf's Coffee Roasters,
                  Pistacia Vera, and Momo Ghar. Walk from the public market to
                  discover free outdoor selfie opps.

                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  6. Drinks While You’re Out:
                  <a
                    href="https://web.myle.com/e/Two%20Truths%20/kCxv8P3rnFfbUv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Two Truths</a
                  >

                  - Carefully curated cocktail menu and atmosphere named for an

                  <a
                    href="https://www.2truths.com/copy-of-home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Egyptian life-after-death tale</a
                  >. Tell us, did the place feel right to "Imbibe and Vibe?"
                  <b>#blackownedbusiness</b>
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Fun is waiting for you.
                </h3>

                <h4
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="section-heading text-center">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Cincinnati
            </h2>
          </div>
          <hr />

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 4.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  7.
                  <a
                    href="https://web.myle.com/e/Cincinnati%20Museum%20Center/QiseKw3EXOaCzw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cincinnati Museum Center</a
                  >

                  - you could spend all day in this restored art deco train
                  station with multiple museums under one roof. Operating out of
                  the

                  <a
                    href="https://en.wikipedia.org/wiki/Cincinnati_Union_Terminal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cincinnati Union Terminal</a
                  >

                  in the

                  <a
                    href="https://en.wikipedia.org/wiki/Queensgate,_Cincinnati,_Ohio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Queensgate</a
                  >

                  neighborhood, it also houses a theater, a library, and a
                  symphonic pipe organ. Check out the

                  <a
                    href="https://www.cincymuseum.org/special-exhibits/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    traveling exhibitions</a
                  >.

                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  8.
                  <a
                    href="https://junglejims.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jungle Jim's</a
                  >
                  International Supermarket - visit this destination shopping
                  place known for having one of the largest wine collections in
                  the country. There are various animatronic shows inside,
                  including an Elvis Presley-singing lion and "rock band" made
                  of General Mills cereal mascots from Pizza Time Theatre.
                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  9.
                  <a
                    href="https://www.shakeitrecords.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shake It Records</a
                  >
                  'Name speaks for itself! Browse the vinyl on the Exclusives
                  page to find signed editions or unique colored variants, then
                  pick up in-person to join an
                  <a
                    href="https://www.facebook.com/shakeitrecords/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    event</a
                  >. Find out more on the

                  <a
                    href="https://web.myle.com/e/Shake%20it%20Records/FytdoAsrKIGeIs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MYLE web</a
                  >

                  or mobile app. <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 5.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  10.
                  <a
                    href="https://web.myle.com/e/American%20Sign%20Museum%20/qWcEk8TTtcC5Ho"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    American Sign Museum</a
                  >
                  Try taking in 20,000 square feet of American history. The
                  museum has gold leaf pre-electric signs, early electric signs,
                  beautiful art-deco neon, and modern plastic-faced signs.
                  Expect to spend at least an hour learning about this
                  "ubiquitous but unnoticed industry."

                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 6.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 7.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  11.
                  <a
                    href="https://thattokyobar.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tokyo Kitty - Karaoke</a
                  >
                  is a reservation-only sip and sing secret spot priced by the
                  hour. Save your space online to experience karaoke in the
                  heart of Downtown.

                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  12.
                  <a
                    href="https://web.myle.com/e/American%20Sign%20Museum%20/qWcEk8TTtcC5Ho"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Findlay Market</a
                  >
                  - Walk this farmers market open year-round on Saturdays and
                  Sundays in the Shed. Their
                  <a
                    href="https://www.instagram.com/findlaymarket/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Insta page</a
                  >
                  reps the public market side of the business with 50 vendors.
                  Open Tuesday - Sunday all year. Read up on their
                  <a
                    href="https://www.findlaymarket.org/history"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    history</a
                  >
                  the way.
                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  13. Go Eat:
                  <a
                    href="https://web.myle.com/e/Empress%20Chili/vN8fzxaqXjBZEC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Empress Chili</a
                  >
                  is the oldest chili shop in Cincinnati. In the early 1900s,
                  Cincinnati saw an influx of Greek and Macedonian immigrants.
                  Brothers Tom and John Kiradjieff opened Empress Chili Parlor
                  in 1922. They took a “diner-ized version of a Greek stew
                  seasoned with Mediterranean spices and gave it a familiar
                  handle: chili.” Most people say it’s like a pasta sauce and
                  spiced-meat hot dog topping you find in other cities. <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  14.
                  <a
                    href="https://www.visitkingsisland.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kings Island</a
                  >
                  is a 364-acre amusement and water park northeast of Cincinnati
                  in Mason, Ohio. Legend mentions a small cemetery in the corner
                  of Kings Island Amusement Park's guest parking lot, which is a
                  hotbed for eerie activity. While there is no evidence to prove
                  the park paved over any graves, cemetery records suggest the
                  possibility. <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  15.
                  <a
                    href="https://web.myle.com/e/Cincinnati%20Zoo%20And%20Botanical%20Gardens/oL9m9RQJ5AyQlN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cincinnati Zoo</a
                  >
                  – hang with kangaroos or march with penguins in the
                  Australian- themed habitat.
                  <a
                    href="https://www.youtube.com/watch?v=mEyKfxGafS0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dornette Kanga' Klimb Aerial Adventure Course</a
                  >
                  activities range from strolling across an elevated bridge to
                  challenging balance and climbing skills tests. It's in Roo
                  Valley, and climbers can actually see kangaroos down below.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohioBlog/Blog-Picture 8.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <b> BONUS </b> Unique Lodging Opportunity - Rest or Stay at
                  the
                  <a
                    href="https://www.graduatehotels.com/cincinnati/?gclid=Cj0KCQiAmeKQBhDvARIsAHJ7mF59Jlrs9gxjPQAyArlYJ9Gvd2yIuAssMlULGDYKMV61O2wlC0MpYgYaArU1EALw_wcB"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Graduate Hotel</a
                  >
                  which is one giant pop-culture experience. You're even able to
                  scan pictures of the artwork from their app on your phone to
                  get more details about different pieces. Don't just take our
                  word for it – watch the
                  <a
                    href="https://www.youtube.com/clip/UgkxpAeXYIEIL9i01XCHuGoC_Sh-jyGZmWz5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    video walk-through</a
                  >.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  We hope our listicle literally whet your appetite and stirred
                  up your wanderlust. Are you as excited as we are and feeling
                  ready for the Road Trip to Atlanta?
                  <a href="#download"> Download the MYLE</a>
                  app and Make Your Life Entertaining! Check back with us next
                  week when we’ll travel through Kentucky, the Bluegrass State –
                  take a bourbon tour, learn the history of Black Horsemen, and
                  stay in a castle!

                  <br />
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div class="row mt-5">
            <div class="col-md-2 col-lg-2 col-sm-2">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/maureen.png"
                  alt="Saturday Event"
                  style="
                    max-width: 60%;
                    max-height: 60%;
                    width: 60%;
                    height: 60%;
                  "
                />
              </div>
            </div>

            <div
              class="col-md-10 col-lg-10 col-sm-10 align-items-center justify-content-center"
            >
              <div class="align-items-center justify-content-center">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  MAUREEN FRIEND is a freelance writer and visual content
                  creator passionate about seeing you exceed your business goals
                  and shamelessly promoting you and your work; have phone, will
                  travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Find Things To Do and Great Food To EAT In Cincinnatti OHIO | MYLE",

    meta: [
      {
        name: "description",
        content:
          "Discover popular local events in Cincinnati and get more event recommendations that fuel your passion  Find events that fuel your passions in MYLE App.",
      },

      {
        name: "keywords",
        content:
          "cincinnatti events | event cincinnatti today | events cincinnatti this weekend | downtown cincinnatti events today | cincinatti ohio events | cincinnatti art museum",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/columbus-cincinnati",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
