<template>
  <section
    class="client-section pt-100"
    v-bind:class="isGray ? 'gray-light-bg' : 'white-bg'"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-heading text-center mb-5">
            <h2>Our Partners</h2>
            <p>
              <span class="lead d-block">
                Thank You to Our Strategic Partners for Your Support!</span
              ><br />
              <span>
                At MYLE we believe that we are better together and that working
                with partners is the best way to create winning collaborations
                that benefit all parties. We create customised partnership
                packages that reflect the goals of each partner and create
                meaningful value. To learn more about MYLE partnership
                opportunities - contact us!
                <a class="themeLiteGreen" href="mailto:hello@myle.com"
                  >hello@myle.com</a
                ></span
              ><br />
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-12">
          <carousel
            :items="1"
            :nav="false"
            :autoplay="true"
            :dots="true"
            :loop="true"
            :margin="15"
            slideTransition="linear"
            :autoplayTimeout="4000"
            :autoplaySpeed="false"
            :smartSpeed="6000"
            :responsive="{
              0: { items: 2 },
              500: { items: 3 },
              600: { items: 4 },
              800: { items: 5 },
              1200: { items: 6 },
            }"
            class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
          >
            <div class="item single-client">
              <a
                href="https://www.cypclub.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/cypclub.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div>
            <div class="item single-client">
              <a
                href="https://www.connectednetworkinggroup.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/connected.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div>
            <div class="item single-client">
              <a
                href="https://www.columbusmakesart.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/artcolumbus.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div>
            <!-- <div class="item single-client">
              <a href="https://www.cohatch.com" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/cohatch.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div> -->
            <div class="item single-client">
              <a
                href="https://cosi.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/cosi.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div>
            <div class="item single-client">
              <a
                href="https://www.nphccolumbus.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ohio.png"
                  alt="client logo"
                  class="client-img"
                />
              </a>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Customer",
  components: { carousel },
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
