<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-3"
              v-if="TicketsResult[selected].ticketType !== 'DONATION'"
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="countChanged"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3 mt-2"
              v-if="
                TicketsResult[selected] &&
                donationOptions[TicketsResult[selected].ticketCode]
              "
            >
              <!-- test -->
              <label class="mt-2">Select Donation Type</label>
              <select class="form-control" v-model="donationPid">
                <option
                  class="form-control"
                  v-bind:key="idx + 'donation'"
                  v-for="(count, idx) in donationOptions[
                    TicketsResult[selected].ticketCode
                  ]"
                  :value="count.priceId"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>
            <!-- test -->

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 mb-3"
              v-if="
                false &&
                TicketsResult[selected] &&
                !exclude.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="mt-3">Dinner Selection</label>
              <textarea
                class="form-control"
                placeholder="Seared Atlantic Salmon, Beef Short Ribs or Chef’s choice Vegetarian Plate"
                @input="reservedMeal = $event.target.value"
                rows="3"
              ></textarea>
            </div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                  <div
                    class="row col-md-12 mt-4"
                    v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                  >
                    <div class="col-md-6">
                      <label class="">Do you have a promo code?</label>

                      <input
                        type="text"
                        v-model="redeempromo"
                        class="form-control"
                        placeholder="Promo code"
                      />
                    </div>

                    <div class="col-md-4">
                      <button
                        type="button"
                        @click="redeem()"
                        :disabled="!redeempromo.trim().length"
                        class="btn solid-btn btn-sm my-auto"
                        id="reserve"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          padding: 7px;
                          margin-top: 11px !important;
                        "
                      >
                        Redeem
                      </button>
                    </div>
                  </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (!exclude.includes(TicketsResult[selected].ticketCode)
                    ? false
                    : false) ||
                  (donationOptions[TicketsResult[selected].ticketCode]
                    ? !donationPid
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Gamma_Epsilon_chapter_of_Kappa_Alpha_Psi_75th.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: #ac1e2e">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                Gamma Epsilon Alumni Association Presents
                <br />
                The Gamma Epsilon 75th Chapter Anniversary Celebration
              </h1>
              <!-- <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a href="https://goo.gl/maps/AdKYFrjosjqFv2FD7" target="_blank" rel="noopener noreferrer">
                  The Exchange Park Fairgrounds. 9850 Hwy 78, Ladson, SC.</a
                >
              </h1> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center mb-2">
                    <h4 class="w-auto d-inline-block mb-2">
                      All sales are final.<br />
                      No refunds!!!
                    </h4>
                  </div>

                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in otherTickets(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>

                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>

                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Reserve Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="text-center mb-2">
                    <h4
                      class="w-auto d-inline-block mb-2 mt-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      Group Sales: If purchasing 10 or more tickets, use promo
                      code “thebestfestival”and receive a 10% discount off of
                      each ticket.
                    </h4>
                  </div> -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="py-2">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">Donations</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in donationList(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>

                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>

                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Reserve Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                The Gamma Epsilon chapter of Kappa Alpha Psi presents the 75th
                Anniversary Celebration Tuskegee University
                <br />
                <br />
                Register for the GE 75th Anniversary Celebration being held
                during Tuskegee University's 2023 Homecoming weekend, Friday
                October 13, 2023 thru Sunday October 15, 2023.
                <br />
                <br />
                <span class="bolded"> FRIDAY </span> <br />
                The weekend celebration will consist of a formal Black and White
                banquet on Friday Oct 13th at The Robert Trent Jones Marriott in
                Auburn. The attire for this event is Black and White which will
                be strictly adhered to by all Brothers.
                <br />
                <br />

                Evening Activities <br />
                5:00 - 6:45pm &nbsp;&nbsp;&nbsp; Kappa Cocktail and Reception
                area
                <br />
                7 – 8:45pm &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Door
                Opening/Seating
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome
                (GE Polemarch and GEAA President)
                <br />
                9 – 10pm
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Formal Program
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                History of GE
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                Polemarch stories
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                Recognition of Special Award
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                Toast to GE 75-year Legacy
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                Closing Remarks (GE Polemarch and GEAA President) <br />
                10 – 12am &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Music, Socialize and Dancing
                <br />
                12am
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Program Ends
                <br />
                <span class="bolded">
                  Note: After Party at the Steps (Pics/Music/Fellowship)
                </span>

                <br />
                <br />
                Location: Auburn Marriott Opelika Resort & Spa at Grand National
                3700 Robert Trent Jones Trail, Opelika, Alabama 36801.
                <br /><br />
                For reservations, call
                <a href="tel:8002289290">800-228-9290 </a>
                <a href="tel:3347419292">334-741-9292</a>
                <br /><br />
                For additional assistance at the Robert Trent Jones Marriott,
                contact Taylor Blaine at
                <a href="tel:3347372130">334.737.2130</a>.
                <br />
                <br />
                <span class="bolded"> SATURDAY </span> <br />
                Registration includes a Tuskegee University Homecoming football
                game ticket (1) on Saturday October 14th at 1:00pm.
                <br /><br />
                Registered Brothers and Registered Brothers w/paid Guest(1) will
                also gain access to the Nupes Homekoming Tailgate tent.
                <br />
                <br />
                <span class="bolded"> SUNDAY </span> <br />
                On Sunday there will be a Farewell brunch at the Host
                <a
                  href="https://www.marriott.com/en-us/hotels/csgab-auburn-marriott-opelika-resort-and-spa-at-grand-national/overview/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hotel</a
                >
                (The Robert Trent Jones Marriott in Auburn) from 9:30 am – 11:00
                am.
                <br />
                <br />
                <span class="bolded"> GENERAL INFORMATION </span> <br />
                Registration packets can be picked up on Friday (Oct. 13th) On
                the Yard from 12:00 pm – 3:00 pm. T-shirt and keepsake provided
                for each registered brother.
                <br />
                <br />
                Note: Brothers MUST be a member in Good Standings with GEAA to
                receive the GEAA Paid Member Registration rate for the 75th
                Anniversary.
                <br />
                <br />
                If not a member of GEAA, select the Non-GEAA Member rate or your
                registration will be refunded and you will incur the processing
                and service fees associated with your transaction from MYLE. You
                will need to re-register after your GEAA membership is obtained.
                <br />
                <br />
                To acquire GEAA membership prior to the 75th Anniversary
                Registration, contact Stephen Sealey at
                <a href="tel:3142838056">314.283.8056</a> or email
                <a href="mailto:stephen.sealey62@gmail.com"
                  >stephen.sealey62@gmail.com</a
                >
                <br />
                <br />
                Registration cost will progressively increase according to the
                following calendar schedule, so register TODAY and SAVE!!!
                <br />
                <br />

                New pricing will be reflected on 9.1.2023

                <!-- <span class="bolded"> Early Registration </span> <br />
                March 1, 2023 thru April 30th, 2023<br />
                GEAA Paid Members Cost: $300<br />
                GEAA Member Banquet - Guest $100 Registration (For
                Banquet/Tailgate Only)<br />
                Non-GEAA Members Cost: $350<br />
                Non-GEAA Member Banquet - Guest $100 Registration (For
                Banquet/Tailgate Only)<br />
                Banquet Guest Only (Non-Fraternity Members) - $100
                <br />
                <br />
                <span class="bolded"> General Registration </span> <br />
                May 1, 2023 to August 31, 2023<br />
                GEAA Paid Members Cost: $350<br />
                GEAA Member Banquet - Guest $100 (For Banquet/Tailgate Only)<br />
                Non-GEAA Members Cost: $400<br />
                Non-GEAA Member Banquet - Guest $100 Registration (For
                Banquet/Tailgate Only)<br />
                Banquet Guest Only (Non-Fraternity Members) - $100
                <br />
                <br />
                <span class="bolded"> Late Registration </span> <br />
                Sept 1, 2023 to October 13, 2023<br />
                GEAA Paid Members Cost: $400<br />
                GEAA Member Banquet - Guest $100 (For Banquet/Tailgate Only)<br />
                Non-GEAA Members Cost: $450<br />
                Non-GEAA Member Banquet - Guest $100 Registration (For
                Banquet/Tailgate Only)<br />
                Banquet Guest Only (Non-Fraternity Members) - $100
                <br />
                <br />
                <span class="bolded"> Banquet Guest Only: </span> <br />
                Individual access to the Formal Black and White banquet on
                Friday, Oct 13th<br />
                Non-Fraternity Members Only
                <br />
                <br />
                <span class="bolded"> Tailgate Tickets: </span> <br />
                GEAA Paid Members - $75 <br />
                Non-GEAA Members/Guest - $100-->
                <br />
                <br />

                Unable to attend? Please contribute via the donation options.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import confirmationPopup from "../../components/confirmationPopup";
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      showsuccess: false,
      excludeNames: [
        "Community Sponsorship",
        "Diamond Sponsorship",
        "24 Karat Sponsorship",
        "Platinum Sponsorship",
        "KLHESF Donation",
      ],
      exclude: [],
      reservedMeal: "",
      donationPid: "",
      donationOptions: {
        // tk_vD7aQFuhNKVw8Bm: [
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cBJJQRn5u",
        //     name: "Gold - $500",
        //   },
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cKuD37llT",
        //     name: "Silver - $250",
        //   },
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cTxgdAmpS",
        //     name: "Bronze - $100",
        //   },
        // ],
      },
      donationTickets: [
        "tk_vNJxYOG9Xknm7Gr",
        "tk_6iRXGSfokLAsIhh",
        "tk_DbUp7nFVYWFfkUf",
      ],
      ticketoptions: [
        // "tk_PtbSGiF4olbSnUb",
        // "tk_7H8qNXwg2LR0asL",
        // "tk_w6YSlu47Ze6rstl",
        "tk_IehXLHWE6ji8xKX",
        "tk_zI47NgYa0jIAfiU",
        // "tk_43K0J403k2RLpQL",
        // "tk_4moPfy73iezZbZk",
        "tk_PTjsf8gYTaVgIFa",
        // "tk_htdebBSMfYWc7BH",
        // "tk_6DieBzVaYQKO5hA",
        // "tk_PCTih7U8kvKgBzv",
        "tk_xED99Mqo64gnxiQ",

        "tk_xKFt7KCP4UZXIfr",

        // "tk_Tmy7gjtXd2VFZCj",
        // "tk_zsFc8eDPcD5YJyD",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_6DieBzVaYQKO5hA: {
        //   messages: [
        // "Banquet Ticket (1)",
        // "Game Ticket  (1)",
        // "Tailgate Ticket (1)",
        //     "Ticket Price: $300",
        //     "Processing fee of $21.76",
        //   ],
        // },

        // tk_htdebBSMfYWc7BH: {
        //   messages: ["Ticket Price: $400", "Processing fee of $28.47"],
        // },

        // tk_43K0J403k2RLpQL: {
        //   messages: [
        // "Banquet Ticket (1)",
        // "Game Ticket  (1)",
        // "Tailgate Ticket (1)",
        //     "Ticket Price: $350",
        //     "Processing fee of $25.11",
        //   ],
        // },

        // tk_4moPfy73iezZbZk: {
        //   messages: ["Ticket Price: $450", "Processing fee of $31.82"],
        // },

        tk_IehXLHWE6ji8xKX: {
          messages: [
            "Banquet Ticket (1)",
            "Game Ticket  (1)",
            "Tailgate Ticket (1)",
            "Ticket Price: $350",
            "Processing fee of $25.11",
          ],
        },

        // tk_w6YSlu47Ze6rstl: {
        //   messages: ["Ticket Price: $450", "Processing fee of $31.82"],
        // },

        // tk_7H8qNXwg2LR0asL: {
        //   messages: ["Ticket Price: $500", "Processing fee of $35.17"],
        // },

        // tk_PtbSGiF4olbSnUb: {
        //   messages: ["Ticket Price: $700", "Processing fee of $48.59"],
        // },

        tk_DbUp7nFVYWFfkUf: {
          messages: ["Ticket Price: $25"],
        },

        tk_6iRXGSfokLAsIhh: {
          messages: ["Ticket Price: $50"],
        },

        tk_vNJxYOG9Xknm7Gr: {
          messages: ["Ticket Price: $75"],
        },

        // tk_PCTih7U8kvKgBzv: {
        //   messages: ["Ticket Price: $100", "Processing fee of $8.35"],
        // },

        tk_xED99Mqo64gnxiQ: {
          messages: [
            "Banquet Ticket (1)",
            "Game Ticket  (1)",
            "Tailgate Ticket (1)",
            "Ticket Price: $400",
            "Processing fee of $28.47",
          ],
        },

        tk_xKFt7KCP4UZXIfr: {
          messages: [
            "Banquet Ticket (1)",
            "Tailgate Ticket (1)",
            "Ticket Price: $100",
            "Processing fee of $8.35",
          ],
        },

        // tk_Tmy7gjtXd2VFZCj: {
        //   messages: ["Ticket Price: $500", "Processing fee of $35.17"],
        // },

        tk_zI47NgYa0jIAfiU: {
          messages: ["Ticket Price: $75", "Processing fee of $6.67"],
        },

        tk_PTjsf8gYTaVgIFa: {
          messages: ["Ticket Price: $100", "Processing fee of $8.35"],
        },

        // tk_zsFc8eDPcD5YJyD: {
        //   messages: ["Ticket Price: $200", "Processing fee of $15.05"],
        // },
      },

      priceIds: {
        // tk_6DieBzVaYQKO5hA: "price_1MkyZ9D0vTZ4QB9cnlt6PAl3",
        // tk_htdebBSMfYWc7BH: "price_1MkyXED0vTZ4QB9ca7ZaaPUW",
        // tk_43K0J403k2RLpQL: "price_1Ml1H5D0vTZ4QB9cTkE8ypoR",
        // tk_4moPfy73iezZbZk: "price_1Ml1H5D0vTZ4QB9czSDql3ci",
        tk_IehXLHWE6ji8xKX: "price_1N3AOzD0vTZ4QB9cknDPOQYF",
        // tk_w6YSlu47Ze6rstl: "price_1N3AigD0vTZ4QB9cP5qup7qe",
        // tk_7H8qNXwg2LR0asL: "price_1MkyUeD0vTZ4QB9cnDPWLl1q",
        // tk_PtbSGiF4olbSnUb: "price_1MkyTqD0vTZ4QB9cxltHAKv8",
        tk_DbUp7nFVYWFfkUf: "price_1NqQjqD0vTZ4QB9cR4A4vIYG",
        tk_6iRXGSfokLAsIhh: "price_1NqQklD0vTZ4QB9cH25CPSwq",
        tk_vNJxYOG9Xknm7Gr: "price_1NrfsjD0vTZ4QB9ctL90Xkmc",
        // tk_PCTih7U8kvKgBzv: "price_1Mm6SfD0vTZ4QB9ckcWK3sjM",
        tk_xED99Mqo64gnxiQ: "price_1N3ANTD0vTZ4QB9cLHGd7AhK",
        tk_xKFt7KCP4UZXIfr: "price_1N3AZoD0vTZ4QB9cWFFcsRV0",
        // tk_Tmy7gjtXd2VFZCj: "price_1N3AbuD0vTZ4QB9c9CziZAuQ",
        tk_zI47NgYa0jIAfiU: "price_1Ml1EsD0vTZ4QB9cjefQF5R1",
        tk_PTjsf8gYTaVgIFa: "price_1Ml1H6D0vTZ4QB9cXK8nak6x",
        // tk_zsFc8eDPcD5YJyD: "price_1Mm6SfD0vTZ4QB9clomftftA",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        mealName: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_12.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_13.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_14.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_15.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_16.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_17.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_18.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_19.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_21.jpg",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    otherTickets(list) {
      return list.filter((t) => this.ticketoptions.includes(t.ticketCode));
    },
    donationList(list) {
      return list.filter((t) => this.donationTickets.includes(t.ticketCode));
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    countChanged() {
      if (this.tickcount < 10) {
        //  this.redeemData = null;
        //  this.redeemPriceId = "";
      }
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].availableTickets;
          const apr =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.ticketForm.ticketCount = 1;
      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.redeempromo = "";
      this.redeemPriceId = "";
      this.donationPid = null;
      this.fullname = "";
      this.email = "";
      const groupticks = [];
      this.tickcount = groupticks.includes(this.TicketsResult[idx].ticketCode)
        ? 10
        : 1;

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = groupticks.includes(this.TicketsResult[idx].ticketCode) ? 9 : 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          promo_code: this.redeempromo,
        }
      };

      if (vm.donationOptions[vm.TicketsResult[vm.selected].ticketCode]) {
        payloadToPush.priceId = vm.donationPid;
      }

      if (this.exclude.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        delete payloadToPush["additionalInfo"];
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_IehXLHWE6ji8xKX") {
        payloadToPush.additionalInfo = {
          type: "Type",
          value: ["Banquet", "NupeKoming Tailgate - GEAA Paid Member"],
        };
      }

      if (
        vm.TicketsResult[vm.selected].ticketCode == "tk_xED99Mqo64gnxiQ" ||
        vm.TicketsResult[vm.selected].ticketCode == "tk_xKFt7KCP4UZXIfr"
      ) {
        payloadToPush.additionalInfo = {
          type: "Type",
          value: ["Banquet", "NupeKoming Tailgate - Non-GEAA Member"],
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            //  vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;

            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_gamma-epsilon-chapter-of-kappa-alpha-psi-75th_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            // tk_6DieBzVaYQKO5hA: null,
            // tk_htdebBSMfYWc7BH: null,
            // tk_43K0J403k2RLpQL: null,
            // tk_4moPfy73iezZbZk: null,
            tk_IehXLHWE6ji8xKX: null,
            // tk_w6YSlu47Ze6rstl: null,
            // tk_7H8qNXwg2LR0asL: null,
            // tk_PtbSGiF4olbSnUb: null,
            tk_DbUp7nFVYWFfkUf: null,
            tk_6iRXGSfokLAsIhh: null,
            tk_vNJxYOG9Xknm7Gr: null,
            // tk_PCTih7U8kvKgBzv: null,
            tk_xED99Mqo64gnxiQ: null,
            tk_xKFt7KCP4UZXIfr: null,

            // tk_Tmy7gjtXd2VFZCj: null,
            tk_zI47NgYa0jIAfiU: null,

            tk_PTjsf8gYTaVgIFa: null,

            // tk_zsFc8eDPcD5YJyD: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      if (!this.excludeNames.includes(this.ticketForm.ticketName)) {
        bodyFormData.append(
          "additionalInfo",
          JSON.stringify({
            type: "Dinner Selection",
            value: this.ticketForm.mealName,
          })
        );
      }

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      //  this.$toast.success("Please check your provided email for tickets.", {
      //    timeout: 8000,
      //  });

      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      //  this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      this.showsuccess = false;
      localStorage.removeItem("refsId");
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Gamma Epsilon chapter of Kappa Alpha Psi 75th",

    meta: [
      {
        name: "description",
        content: "MYLE - Gamma Epsilon chapter of Kappa Alpha Psi 75th",
      },

      {
        name: "keywords",
        content: "MYLE - Gamma Epsilon chapter of Kappa Alpha Psi 75th",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/gamma-epsilon-chapter-of-kappa-alpha-psi-75th-anniversary",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

textarea {
  resize: none;
}

.bolded {
  font-weight: bold;
}
</style>
