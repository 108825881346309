<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Full Name
              </label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Email Address
              </label>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Age Range</label
              >
              <select class="form-control" v-model="ageRange">
                <option
                  class="form-control"
                  v-bind:key="ageval + 'b'"
                  v-for="ageval in ageRangesList"
                  :value="ageval"
                >
                  {{ ageval }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >How did you hear about this event</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder=""
                v-model="hearAbout"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Zp9izBaReeI9K6t' &&
                TicketsResult[selected].ticketCode !== 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in (TicketsResult[selected] || {})
                    .availableSeating"
                  :value="seating.alias"
                >
                  {{ seating.alias }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-Saints btn-sm my-auto shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-Saints m-auto mr-2 shimmer"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: #ffffff"
    >
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_SaintsandStarsBigGameWeekendWatchParty_V4.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #baa168"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center mb-0"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: white;
                "
              >
                Saints and Stars Big Game Weekend - Watch Party and Tailgate
              </h1>

              <h1
                class="text-center mt-3"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                Sunday, February 09, 2025 <br />
                04:00 PM - 11:00 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  color: white;
                "
              >
                <a
                  href="https://maps.app.goo.gl/DD2Tds2psa6GgHRw5"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Port Orleans Brewery<br />
                  4124 Tchoupitoulas St, New Orleans, LA 70115
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: black"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="text-center mb-0"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1.6em;
                "
              >
                Join Us for the Ultimate Big Game Experience!
              </p>

              <p
                class="text-center mb-0"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 500;
                  font-size: 1.6em;
                "
              >
                Get ready for a

                <span style="font-weight: 700"
                  >Big Game watch party like no other!</span
                >
              </p>

              <p
                class="mt-5"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-weight: 700"
                  >• Iconic Louisiana Cuisine:</span
                >
                Indulge in a chef-prepared buffet featuring all your favorite
                Big Game appetizers.
                <br />
                <span style="font-weight: 700"
                  >• Craft Cocktails & Open Bar:</span
                >
                Sip premium drinks crafted by top mixologists, with
                complimentary options included.
                <br />
                <span style="font-weight: 700">• Exclusive VIP Sections:</span>
                with soft seating for ultimate comfort and two massive LED
                screen walls for an immersive viewing experience.
              </p>

              <p
                class=""
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                Don’t have tickets to the big game? We’ve got you covered! Cheer
                for your team, enjoy the commercials, and watch the star-studded
                halftime show in comfort and style in the heart of New Orleans,
                just minutes away from the Superdome! Cheer for your team, enjoy
                the commercials, and watch the star-studded halftime show in
                style!
              </p>

              <div class="row justify-content-center mt-3">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-2"
                        style="
                          font-family: 'Montserrat', sans-serif;
                          font-size: 2.6em;
                          color: black;
                        "
                      >
                        Individual Tickets
                      </h3>
                    </div>
                    <br />
                    <div
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualTick(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white"
                          style="background-color: #293d58"
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in (tkList[obj.ticketCode] || [])
                              .messages"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p>
                              <b class="font-weight-bold">💵 Total Price: </b
                              >${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-Saints shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sales Ended"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Reserve Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tickets Section starts -->
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-2"
                        style="
                          font-family: 'Montserrat', sans-serif;
                          font-size: 2.6em;
                          color: black;
                        "
                      >
                        Group Tickets
                      </h3>
                    </div>
                    <div
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in groupTickets(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white"
                          style="background-color: #293d58"
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p>
                              <b class="font-weight-bold">💵 Total Price: </b
                              >${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-Saints shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Reserve Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_SaintsandStarsBigGameWeekendWatchParty_V1.png"
                      style="border-radius: 3%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <p
                class="mt-0 mb-5"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-size: 1.4em; font-weight: 700">
                  Why Attend?
                </span>

                <br />

                <span style="font-weight: 500">
                  • Enjoy New Orleans’ vibrant spirit while making a positive
                  impact.
                  <br />
                  • Support T.I.P.S. in their mission to empower youth and
                  families through access to sports and entertainment.
                </span>

                <br />
                <br />
                <span style="font-size: 1.4em; font-weight: 700; color: black">
                  Tickets Are Limited – Book Now!
                </span>

                <br />

                <span style="font-weight: 500">
                  Don’t miss this unforgettable night of football, food, and
                  philanthropy.
                </span>
                <br />
                <br />
                <span style="font-weight: 500; text-decoration: underline">
                  Reserve Your Spot Today
                </span>
              </p>

              <hr />

              <p
                class="mt-4"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 400;
                  font-size: 1.3em;
                "
              >
                <span style="font-size: 1.6em; font-weight: 700">
                  Celebrate with Purpose
                </span>

                <br />
                <br />
              </p>

              <div class="row mt-0">
                <div class="col-md-2 text-center">
                  <a
                    href="https://www.instagram.com/4tips_philly"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: white"
                  >
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_TIPS_Logo_V3.png"
                      style="border-radius: 3%; width: 75%"
                      class="text-center"
                    />
                  </a>
                </div>
                <div class="col-md-10">
                  <p
                    class="mt-0"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                      font-size: 1.2em;
                    "
                  >
                    <span style="font-weight: 500">
                      This isn’t just a party—it’s a way to give back. A portion
                      of proceeds supports
                      <span style="font-weight: 700"
                        >The International People’s Storehouse (T.I.P.S.)</span
                      >, a nonprofit dedicated to helping underserved
                      communities by providing essential resources like food,
                      hygiene items, and emergency services.
                    </span>
                  </p>
                </div>
              </div>

              <hr />

              <div class="mt-5 text-center">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-size: 2.6em;
                      color: black;
                    "
                  >
                    Sponsors and Partners
                  </h3>
                </div>

                <div class="row text-center mt-0">
                  <div class="col-md-12 text-center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_Sponsor_SaintsandStarsBigGameWeekendWatchParty_V2.png"
                      style="border-radius: 3%; width: 100%"
                      class="text-center"
                    />
                  </div>
                </div>

                <br />

                <p
                  class="mt-0 text-center"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 1.2em;
                  "
                >
                  <span style="font-weight: 500">
                    For information about becoming a sponsor, VIP packages or
                    general questions contact us at
                    <a
                      href="https://saintsandStars@nkmconsulting.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SaintsandStars@nkmconsulting.org
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-0">
      <div class="col-md-12">
        <p
          class="text-center"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 1.1em;
            color: black;
          "
        >
          Reserve your ticket today to secure your spot
        </p>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <p
          class="text-center"
          style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 1.1em;
            color: black;
          "
        >
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_icon_location.png"
            style="border-radius: 3%; width: 4%"
          />
          <br />

          <a
            href="https://maps.app.goo.gl/aD1GvShLGyqyL6Vy8"
            target="_blank"
            rel="noopener noreferrer"
            style="color: black"
          >
            <span
              style="font-family: 'Montserrat', sans-serif; font-weight: 500"
              >Port Orleans Brewery </span
            ><br />
            4124 Tchoupitoulas St, New Orleans, LA 70115, United States
            <br />

            <br />
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_location_SaintsandStarsBigGameWeekendWatchParty_V1.png"
              style="border-radius: 3%; width: 70%"
            />
          </a>
        </p>
      </div>
      <div class="col-md-3"></div>
    </div>

    <!-- <div class="text-center">
        <h3
          class="w-auto"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 40px;
            color: white;
          "
        >
          Watch The Video
        </h3>
      </div> -->
    <!-- <br />
      <div class="row" style="margin: auto; background: black">
        <div class="col-md-2 col-md-2"></div>
        <div class="col-md-8 col-md-8">
          <div class="text-center">
            <video-embed
              css="embed-responsive-16by9"
              src="https://www.youtube.com/watch?v=nmVB02laCc8"
            ></video-embed>
          </div>
        </div>
  
        <div class="col-md-2 col-md-2"></div>
      </div>
      <br />
      <br />
      <br /> -->

    <!-- <br />
    <section class="container">
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS_V1.png"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>

        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
    </section>
    <br /> -->

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #baa168"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      ageRangesList: ["21-29", "30-39", "40-49", "50+"],
      ageRange: "21-29",
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_SzHsHUUIOoiEmyc",
        "tk_ipatLUzrTqB8O10",
        "tk_14OZ9Ln2Whqpl9v",
      ],

      groupData: ["tk_aXnQCJOJ2XCAfde", "tk_KCGdgxI9A1OyRZs"],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_SzHsHUUIOoiEmyc: {
          messages: [
            " 🎟️ Includes General Admission for event date for one guest",
            " 🍸 2 hr Open Bar Access to Sponsored Specialty Drinks",
            " 🦐 2hr Access to Open Buffet",
            "Ticket Price: $175",
            "Processing fee of $13.38",
          ],
        },
        tk_ipatLUzrTqB8O10: {
          messages: [
            " 🎟️ Includes General Admission for event date for one guest",
            " 🍸 2 hr Open Bar Access to Sponsored Specialty Drinks",
            " 🦐 2hr Access to Open Buffet",
            " 🛋️ Access Designated Seating Areas and Reserved Tables (first come first serve basis)",
            "Ticket Price: $200",
            "Processing fee of $15.05",
          ],
        },
        tk_14OZ9Ln2Whqpl9v: {
          messages: [
            " 🎟️ Includes General Admission for event date for one guest",
            " 🍸 2 hr Open Bar Access to Sponsored Specialty Drinks, Premium Drink Options",
            " 🦐 2hr Access to Open Buffet",
            " 🛋️ Access To VIP designated area with Upgraded Seating Areas and large scale LED screens (first come first serve basis)",
            "Ticket Price: $250",
            "Processing fee of $18.41",
          ],
        },
        tk_aXnQCJOJ2XCAfde: {
          messages: [
            " 🎟️ Admission for up to 4 people",
            " 🛋️ Includes 1 table area to accommodate 4 people",
            " 🍸 Bottle Service (1 bottle of choice) and Unlimited Food for 4 hours duration",
            " 🛋️ Access To Reserved Soft Seating Area with LED screens, Close viewing proximity to TVs throughout the designated VIP area",
            "Ticket Price: $2000",
            "Processing fee of $135.78",
          ],
        },
        tk_KCGdgxI9A1OyRZs: {
          messages: [
            " 🎟️ Admission for up to 6 people",
            " 🛋️ Includes 1 table area to accommodate 6 people",
            " 🍸 Host/Concierge Service during the game, Bottle Service (includes 2 bottles) and Unlimited (4 hours duration), Personal TV for your section",
            " 🛋️ Access To Reserved Soft Seating Area with LED screens, Personal TV for your section",
            "Ticket Price: $2500",
            "Processing fee of $169.32",
          ],
        },
      },

      priceIds: {
        tk_SzHsHUUIOoiEmyc: "price_1QMP3xD0vTZ4QB9ctgItPf5X",
        tk_ipatLUzrTqB8O10: "price_1QMP3xD0vTZ4QB9c6MFAJKxx",
        tk_14OZ9Ln2Whqpl9v: "price_1QMP3yD0vTZ4QB9cmIpnm9hz",
        tk_aXnQCJOJ2XCAfde: "price_1QMP3yD0vTZ4QB9c3bLGWjkM",
        tk_KCGdgxI9A1OyRZs: "price_1QMP3zD0vTZ4QB9c2CrAADIs",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      hearAbout: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);
        console.log("--------");
        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? v.text == "1" || v.text == "9"
                  ? "#0ea293"
                  : "#a19140"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 300)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 306.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 40)
        .attr("height", 40)
        .attr("y", 100)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("y", 125)
        .attr("x", 2.5)
        .text("Band")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }

      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_NzrhJLxmsLhNLaD"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      }
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      payloadToPush.extraInfo = {
        hearAbout: this.hearAbout,
        ageRange: this.ageRange,
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.ageRange = "21-29";
            vm.fullname = "";
            vm.email = "";
            vm.hearAbout = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "MW3UPSd9MuULOU",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_SzHsHUUIOoiEmyc: null,
            tk_ipatLUzrTqB8O10: null,
            tk_14OZ9Ln2Whqpl9v: null,
            tk_aXnQCJOJ2XCAfde: null,
            tk_KCGdgxI9A1OyRZs: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          // vm.drawseatsd1(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "MYLE | Saints and Stars Big Game Weekend - Watch Party and Tailgate",
    meta: [
      {
        name: "description",
        content:
          "MYLE | Saints and Stars Big Game Weekend - Watch Party and Tailgate",
      },

      {
        name: "keywords",
        content:
          "MYLE | Saints and Stars Big Game Weekend - Watch Party and Tailgate",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/saints-and-stars-big-game-weekend-watch-party",
      },
    ],
  },
};
</script>

<style scoped>
.velmld-parent {
  background: #ffffff !important;
}

#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}
</style>
