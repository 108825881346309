<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <!-- <div
                class="col-md-6"
                v-if="TicketsResult[selected] && TicketsResult[selected].discount"
              > -->
            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>
            <!-- </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  ((TicketsResult[selected].discount &&
                    TicketsResult[selected].ticketCode ==
                      'tk_csxxDb3rPpK7o7u') ||
                  TicketsResult[selected].ticketCode == 'tk_cgDbER9j9AhRSRK' ||
                  TicketsResult[selected].ticketCode == 'tk_z7DoDKpH2DaTKF6'
                    ? !redeempromo.trim().length || !redeemData
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_2nd_kappa_wine_sip.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                The 27th Annual Kappa Wine Sip
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              THE KAPPA WINE SIP IS BACK!!!!
            </p>

            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 25px;
              "
            >
              You’re cordially invited to join the Columbus (OH) Chapter of
              Kappa Alpha Psi for the 27th Annual Kappa Wine Sip @ Columbus’
              Dynamic Science Museum, COSI.
            </p>

            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 25px;
              "
            >
              COSI - 333 West Broad Street, Columbus OH
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Please join Columbus Kappa Foundation and Columbus (OH) Chapter
                of Kappa Alpha Psi for the 27th annual Wine Sip on May 20th at
                COSI - Center of Science and Industry from 7pm to 1am.

                <br />
                <br />

                This annual signature event consists of a Cosmo/cocktail
                experience that supports our scholarship fund assisting local
                high school students with plans to attend colleges and
                universities around the country.

                <br />
                <br />

                At this year's Kappa Wine Sip, we will honor 3 Community Impact
                Champions including Adelphi Bank and provide an enjoyable
                experience hosted by Teddy the Comedian Williams.

                <br />
                <br />
                The evening is comprised of delightful dining, cocktails, wine
                tasting, dancing and VIP designations.
                <br />
                <br />

                Entertainment:
                <br />
                Live Music performed by the Columbus Ohio Hotboyz <br />
                Just Vince and the fellas out of Cincinnati <br />
                Music provided by the areas top DJ's including "DJ Quickmode"
                Dante Lee, "Mr. Reynolds" Carlton Reynolds, and Dommy "Huge
                Deal" Styles - Dominic Chatman from 98.9
                <br />
                <br />
                Experiences:<br />

                A courtyard reprieve with Pipes and Pleasures and Midwest
                Cigars, special host Shejay Divine from 98.9 completing an
                exclusive evening experience at one of Columbus' most recognized
                exhibition centers, COSI.

                <br />
                <br />
                The VIP experience will include designated seating, two wine
                tasting allowances, 3 cocktail beverage allowance, upgraded
                dining options, band, DJ, dignitary presentation, access to the
                courtyard cigar reprieve, swag bag, and champagne toast to
                commemorate the evening.

                <br />
                <br />
                Again, the Kappa Wine Sip experience continues on May 20th, 2023
                from 7pm to 1am.

                <br />
                <br />

                Join us and support our scholarship and community initiatives
                including the annual Turkey Drive and Toy Giveaway.

                <br />
                <br />
                Spread the word and get your tickets today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">
                INDIVIDUAL TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price:</b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">GROUP TICKET OPTIONS</h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in groupTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price:</b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      individualsData: [
        // "tk_yd49jnihYkud2XD",
        // "tk_aVwwH0UjcQ9RywE",

        "tk_cQfB8grCDQJPsBn",
        "tk_Xn9osGnScY91lAp",

        "tk_cgDbER9j9AhRSRK",
        // "tk_Cds4YNPdEPTasQy",

        "tk_7Jvcg2K7Yu1iowx",

        "tk_csxxDb3rPpK7o7u",
        "tk_z7DoDKpH2DaTKF6",
        // "tk_kEvUZLjMbkc0YJW",
      ],
      groupData: [
        "tk_YX9ed8iQhQLzC1X",
        "tk_TjM0N1LS9TCD8Ms",
        "tk_5wiEkQ2TxofJR9v",
        "tk_W7ENL2SzKW83ClO",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_yd49jnihYkud2XD: {
        //   messages: [
        //     "Ticket Price: $19.11",
        //     "Processing fee of $2.92",
        //     "Limited Time Only",
        //   ],
        // },

        // tk_kEvUZLjMbkc0YJW: {
        //   messages: [
        //     "Ticket Price: $40",
        //     "Processing fee of $4.32",
        //     "Limited Time Only",
        //   ],
        // },
        // tk_Cds4YNPdEPTasQy: {
        //   messages: ["Ticket Price: $50", "Processing fee of $4.99"],
        // },

        tk_7Jvcg2K7Yu1iowx: {
          messages: ["Ticket Price: $60", "Processing fee of $5.66"],
        },

        tk_csxxDb3rPpK7o7u: {
          messages: ["Ticket Price: $40", "Processing fee of $4.32"],
        },

        tk_z7DoDKpH2DaTKF6: {
          messages: ["Ticket Price: $950", "Processing fee of $65.36"],
        },

        // tk_aVwwH0UjcQ9RywE: {
        //   messages: ["Ticket Price: $125", "Processing fee of $10.03"],
        // },

        tk_cQfB8grCDQJPsBn: {
          messages: ["Ticket Price: $150", "Processing fee of $11.7"],
        },

        tk_Xn9osGnScY91lAp: {
          messages: ["Ticket Price: $100", "Processing fee of $8.35"],
        },

        tk_cgDbER9j9AhRSRK: {
          messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        },

        tk_TjM0N1LS9TCD8Ms: {
          messages: [
            "Ticket Price: $450",
            "Processing fee of $31.82",
            "Limited Time Only",
          ],
        },

        tk_YX9ed8iQhQLzC1X: {
          messages: [
            "Ticket Price: $900",
            "Processing fee of $62",
            "Limited Time Only",
          ],
        },

        tk_W7ENL2SzKW83ClO: {
          messages: ["Ticket Price: $500", "Processing fee of $35.17"],
        },

        tk_5wiEkQ2TxofJR9v: {
          messages: ["Ticket Price: $1000", "Processing fee of $68.71"],
        },
      },

      priceIds: {
        // tk_yd49jnihYkud2XD: "price_1MpW91D0vTZ4QB9cdklV2xjD",
        // tk_kEvUZLjMbkc0YJW: "price_1MliyQD0vTZ4QB9cPTx27nZR",
        // tk_Cds4YNPdEPTasQy: "price_1MliyQD0vTZ4QB9cRC0CN5ly",

        tk_7Jvcg2K7Yu1iowx: "price_1N9rnlD0vTZ4QB9c09A3ti33",

        tk_csxxDb3rPpK7o7u: "price_1N371mD0vTZ4QB9cRS9ODiyg",
        tk_z7DoDKpH2DaTKF6: "price_1N4ROiD0vTZ4QB9cDGvI3zLv",
        // tk_aVwwH0UjcQ9RywE: "price_1N7OuOD0vTZ4QB9cpZCroNWd",

        tk_cQfB8grCDQJPsBn: "price_1N9rnlD0vTZ4QB9cF97xAvez",

        tk_Xn9osGnScY91lAp: "price_1N9rhLD0vTZ4QB9c11H37RSa",

        tk_cgDbER9j9AhRSRK: "price_1N371mD0vTZ4QB9cekchz15Y",
        tk_TjM0N1LS9TCD8Ms: "price_1MpWDwD0vTZ4QB9c8gkiwTHZ",
        tk_YX9ed8iQhQLzC1X: "price_1MpWDxD0vTZ4QB9cs3f8smFS",
        tk_W7ENL2SzKW83ClO: "price_1MliyRD0vTZ4QB9ctRp3i0sa",
        tk_5wiEkQ2TxofJR9v: "price_1MliySD0vTZ4QB9cgmur44Wc",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_2nd_annual_kappa_wine_sip_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            // tk_yd49jnihYkud2XD: null,
            // tk_kEvUZLjMbkc0YJW: null,
            // tk_Cds4YNPdEPTasQy: null,
            tk_7Jvcg2K7Yu1iowx: null,
            tk_csxxDb3rPpK7o7u: null,
            tk_z7DoDKpH2DaTKF6: null,
            // tk_aVwwH0UjcQ9RywE: null,
            tk_cQfB8grCDQJPsBn: null,
            tk_Xn9osGnScY91lAp: null,
            tk_cgDbER9j9AhRSRK: null,
            tk_TjM0N1LS9TCD8Ms: null,
            tk_YX9ed8iQhQLzC1X: null,
            tk_W7ENL2SzKW83ClO: null,
            tk_5wiEkQ2TxofJR9v: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email);
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - The 27th Annual Kappa Wine Sip",

    meta: [
      {
        name: "description",
        content: "MYLE - The 27th Annual Kappa Wine Sip",
      },

      {
        name: "keywords",
        content: "MYLE - The 27th Annual Kappa Wine Sip",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/the-27th-annual-kappa-wine-sip",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
