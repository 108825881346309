<template src="./Success.html"></template>

<script>
  // Vuex Imports
  import { mapActions, mapState } from 'vuex';
  // Component Imports
  import VueElementLoading from 'vue-element-loading';
  // Scss Imports
  import "./Success.scss";

  export default {
    name: 'Success',
    data() {
      return {
        hidePage: false,
        isLoading: false,
        loadStyle: {
          color: '#ffffff',
          'font-weight': 'bold',
          'letter-spacing': '1rem',
          'font-size': '18px'
        },
      }
    },
    components: {
      VueElementLoading
    },
    computed: {
      ...mapState('events', ['eventStatus', 'event', 'events', 'comments', 'categories', 'services']),
    },
    watch: {
      eventStatus: function(Status) {
        this.isLoading = Status;
      }
    },
    methods: {
      ...mapActions('events', ['getEventDetails', 'event']),
      fetchEvent() {
        const id = this.$route.params.id;

        if (id === "none") {
          return;
        }

        if (id) {
          // start loader
          this.isLoading = true;

          // creating params to fetch specific event
          const params = {
            eventCode: id,
            userId: ""
          }

          // check if user id exists
          if (this.$CURRENT_USER.getUserId()) {
            params.userId = this.$CURRENT_USER.getUserId();
          }

          // make a fetch call
          this.getEventDetails(params);
        }
      },
      explore() {
        this.$router.push('/the-southwest-landing-lounge-contact-us');
      }
    },
    created() {
      if (this.$route.params.status == 'canceled') {
        this.$router.push({
          name: 'error',
          params: {
            id: this.$route.params.id
          }
        });
      } else {
        this.hidePage = true;
      }
    },
    mounted() {
      if (this.$route.params.status == 'canceled') {
        return;
      } else {
        this.fetchEvent();
      }
    }
  }
</script>
