<template>
  <div>
    <nav-bar />
    <!-- text -->
    <div style="margin-top: 80px" class="px-5 mx-auto">
      <p class="text-center font-weight-bold">
        MYLE Change Management Policy (Information Security)
      </p>

      <p>
        The Change Management Strategy for Information Security at MYLE is
        designed to ensure that changes to the organization's information
        security environment are implemented in a controlled and risk-aware
        manner. As an entertainment software and data analytics company handling
        sensitive customer data, it is vital to prioritize information security
        and protect both company and customer interests.
        <br />
        <br />
        This strategy aims to reduce the risk of security breaches, maintain
        data integrity, and enhance the overall cybersecurity posture of MYLE.
      </p>

      <div>
        <h4>Governance and Leadership:</h4>
        <p>
          Establish a dedicated Information Security Change Management Committee
          consisting of representatives from various departments, including IT,
          security, operations, and business units. This committee will oversee
          all information security-related changes and ensure alignment with
          business objectives and security policies.
        </p>
      </div>

      <div>
        <h4>Risk Assessment and Impact Analysis:</h4>
        <p>
          Before implementing any information security changes, conduct thorough
          risk assessments and impact analyses. Evaluate the potential risks
          associated with each change and determine the potential impact on
          data, systems, operations, and customer trust.
        </p>
      </div>

      <div>
        <h4>Change Approval and Authorization:</h4>
        <p>
          Implement a formal change approval process that requires documented
          authorization from relevant stakeholders, including the Information
          Security Change Management Committee, before implementing any
          security-related changes.
        </p>
      </div>

      <div>
        <h4>Communication and Stakeholder Engagement:</h4>
        <p>
          Effective communication is crucial during change implementation.
          Engage relevant stakeholders, including employees, customers, and
          third-party vendors, to raise awareness about upcoming security
          changes and their potential impacts.
        </p>
      </div>

      <div>
        <h4>Employee Training and Awareness:</h4>

        <p>
          Provide comprehensive training to employees on the importance of
          information security and the expected behavior during change
          initiatives. Regularly conduct awareness programs to keep staff
          informed about emerging security threats and best practices.
        </p>
      </div>

      <div>
        <h4>Change Planning and Documentation:</h4>

        <p>
          Develop detailed change plans that outline the scope, objectives,
          resources required, and timelines for each information security
          change. Maintain comprehensive documentation of all changes, including
          risk assessments, approvals, and outcomes.
        </p>
      </div>

      <div>
        <h4>Testing and Validation:</h4>

        <p>
          Conduct thorough testing and validation of information security
          changes in a controlled environment before rolling them out to the
          production environment. This will help identify and address any
          potential issues before they impact the organization.
        </p>
      </div>

      <div>
        <h4>Rollback and Contingency Plans:</h4>

        <p>
          Create rollback plans to revert changes if unexpected issues or
          security risks arise during implementation. Additionally, develop
          contingency plans to handle unforeseen events and minimize
          disruptions.
        </p>
      </div>

      <div>
        <h4>Post-Implementation Review:</h4>

        <p>
          After implementing information security changes, conduct
          post-implementation reviews to assess the effectiveness of the changes
          and gather feedback from stakeholders. Use this feedback to improve
          future change management processes.
        </p>
      </div>

      <div>
        <h4>Continuous Improvement:</h4>

        <p>
          Promote a culture of continuous improvement in information security
          practices. Regularly review and update the change management strategy
          to adapt to evolving security threats and industry best practices.
        </p>
      </div>

      <div>
        <h4>Compliance and Auditing:</h4>
        <p>
          Ensure that all information security changes comply with relevant
          regulations, industry standards, and internal policies. Conduct
          regular audits to assess the effectiveness and compliance of the
          change management process.
        </p>

        <p>
          By implementing this Change Management Strategy for Information
          Security, MYLE can effectively mitigate security risks, respond to
          emerging threats, and safeguard customer data, ultimately enhancing
          the trust and confidence of clients and customers in the company's
          services.
        </p>
      </div>
    </div>

    <!-- text -->

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
<style scoped>
.heading {
  font-size: 0.75rem;
  font-weight: bold;
}

li {
  list-style-type: disc;
  margin-left: 2rem;
}
</style>
