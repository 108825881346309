<template>
  <div
    v-bind:class="overlay_classes"
    v-show="is_open"
    v-on:click="_onOverlayClick"
  >
    <div v-bind:class="modal_classes">
      <div class="nu-modal__close" v-on:click="close" v-if="!hideCloseButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewbox="0 0 24 24"
        >
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            fill="#292c34"
          ></path>
        </svg>
      </div>
      <div class="nu-modal__title" v-if="has_title">
        <template v-if="has_title">
          <!-- <h2 v-if="title" v-html="title"></h2> -->
        </template>
      </div>
      <div class="nu-modal__content" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myleModal",
  componentName: "NuModal",
  emits: ["open", "close"],
  props: {
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  mounted() {
    document.addEventListener("keyup", this._onDocumentKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this._onDocumentKeyup);
  },
  data() {
    return {
      visible: false,
      is_open: false,
      tabs: [],
    };
  },
  computed: {
    has_title() {
      return this.title || this.$slots.title;
    },
    has_content() {
      return this.$slots.default;
    },
    overlay_classes() {
      return [
        "nu-modal__overlay",
        "nu-modal__clickable",
        {
          "is-visible": this.visible,
        },
      ];
    },
    modal_classes() {
      return [
        "myle-modal",
        {
          "has-title": this.has_title,
          "has-content": this.has_content,
          "is-visible": this.visible,
        },
      ];
    },
  },
  methods: {
    open() {
      this.is_open = true;
      setTimeout(() => (this.visible = true), 30);
      this.$emit("open");
      const body_elem = document.querySelector("body");
      body_elem.classList.toggle("nu-modal_open");
    },
    close() {
      this.visible = false;
      setTimeout(() => (this.is_open = false), 300);
      this.$emit("close");
      const body_elem = document.querySelector("body");
      body_elem.classList.toggle("nu-modal_open");
    },
    _onOverlayClick(event) {
      if (
        !event.target.classList ||
        event.target.classList.contains("nu-modal__clickable")
      ) {
        //	this.close()
      }
    },
    _onDocumentKeyup(event) {
      if (event.keyCode == 27) {
        this.close();
      }
    },
  },
};
</script>
<style>
.nu-modal_open {
  overflow: hidden;
}
.nu-modal__overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  z-index: 9001;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate3D(0, 0, 0);
  -webkit-perspective: 500px;
}
.nu-modal__overlay.is-visible {
  opacity: 1;
}
.myle-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 16px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 800px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
.myle-modal.is-visible {
  transform: translateX(-50%);
  opacity: 1;
}
.nu-modal__close {
  position: absolute;
  cursor: pointer;
  color: #222c38;
  width: 24px;
  height: 24px;
  transition: opacity 0.3s;
  right: 15px;
  top: 15px;
  z-index: 1;
  border-radius: 50%;
}
.nu-modal__close:hover {
  opacity: 0.4;
}
.nu-modal__close svg {
  width: 24px;
  height: 24px;
}
.nu-modal__title {
  border-bottom: 1px solid #eaeaea;
  padding: 15px 64px 15px 30px;
  text-align: left;
}
.nu-modal__title h2 {
  font-weight: 500;
  font-size: 22px;
  margin: 0;
}
.nu-modal__content {
  display: block;
  padding: 15px 30px;
  line-height: 1.5;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .nu-modal__content {
    padding: 24px 15px;
  }
  .myle-modal {
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    transform: scale(0.9);
    margin: 0;
  }
  .myle-modal.is-visible {
    transform: none;
  }
}
</style>
