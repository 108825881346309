<template>
  <section id="about" class="about-us ptb-100 white-bg">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6">
          <div class="about-content-left section-heading">
            <h2>One Smart App</h2>
            <p>
              “Make Your Life Entertaining,” is a free<br />
              entertainment app for you to explore<br />
              and discover:
            </p>

            <div class="single-feature mb-4">
              <div class="d-flex align-items-center mb-2">
                <span class="rounded mr-3 icon icon-color-7">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_diamond.svg"
                    class="img-fluid"
                    alt="gem"
                  />
                </span>
                <h5 class="mb-0">Hidden Gems</h5>
              </div>
            </div>
            <div class="single-feature mb-4">
              <div class="d-flex align-items-center mb-2">
                <span class="rounded mr-3 icon icon-color-8">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_compass.svg"
                    class="img-fluid"
                    alt="location"
                  />
                </span>
                <h5 class="mb-0">Out of the way Places</h5>
              </div>
            </div>

            <div class="single-feature mb-4">
              <div class="d-flex align-items-center mb-2">
                <span class="rounded mr-3 icon icon-color-9">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_photo.svg"
                    class="img-fluid"
                    alt="location"
                  />
                </span>
                <h5 class="mb-0">All-so-sharable Photo Ops</h5>
              </div>
              <p class="mt-4">
                Whether you are exploring your city or when you<br />
                hit the road. MYLE is your go-to app to things to do,<br />
                places to go and food to eat
              </p>
            </div>

            <!-- <a href="#features" class="btn solid-btn mt-2">Explore More</a> -->

            <div class="download-btn">
              <a href="#features" class="btn solid-btn"
                ><span class=""></span> Explore More</a
              >

              <a
                href="https://myle.com/app/MYLEMARKERS"
                target="_blank"
                rel="noopener noreferrer"
                class="btn solid-btn ml-2"
                ><span class=""></span> Get The App</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="about-content-right">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mobile-1.png"
              alt="about us"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>
