<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green mt-5">
        <div class="col-lg-12 col-md-12 mt-3">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 45px;
                "
              >
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 ml-3 mr-3"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading mb-4 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 30px;
              "
            >
              General MYLE FAQ’s<br />
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: What is the MYLE Platform?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              MYLE, which means “Make Your Life Entertaining,” is a very dynamic
              social activity and entertainment discovery platform. MYLE will
              help you quickly find things to do, places to go, and food to eat
              - in a very simplistic and user-friendly manner, whether it is in
              your neighborhood or when you travel. MYLE is also an
              entertainment marketplace where event creators can post and
              promote events, sell tickets and capture user engagement insights.
              MYLE is available for all smartphones, tablets, and desktop.
            </p>

            <div
              class="row justify-content-center align-items-center text-center mb-5"
            >
              <div
                class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center"
              >
                <h4
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>
                <div class="">
                  <div
                    class="download-btn text-center animate__animated animate__fadeIn"
                  >
                    <a
                      href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                      class="btn solid-btn mr-3"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><span class="ti-apple"></span> App Store</a
                    >
                    <a
                      href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                      class="btn solid-btn mr-3"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><span class="ti-android"></span> Google Play</a
                    >
                    <a
                      href="https://web.myle.com"
                      class="btn solid-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><span class="ti-world"></span> MYLE Web</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: How much does it cost to use MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            MYLE is free to use. You can discover events and experiences all
            across the US in 20 different categories - all for free. If you plan
            on hosting a private or public event, you can post it on MYLE for
            free!

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: What categories are included on MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            ● Arts & Culture <br />
            ● Campus Activities <br />
            ● Charity and Community <br />
            ● Comedy <br />
            ● Family Fun <br />
            ● Festivals and Fun <br />
            ● Food & Beverage <br />
            ● Health & Beauty <br />
            ● Lifestyle <br />
            ● Miscellaneous <br />
            ● Music <br />
            ● Networking and Meetups <br />
            ● Nightlife and Social <br />
            ● Outdoors <br />
            ● Science/Tech <br />
            ● Spirituality <br />
            ● Sports <br />
            ● Theater/Performance <br />
            ● Travel & Tours <br />
            ● Virtual/Online Events

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: What are the other benefits of using MYLE to discover
            entertainment?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            ● Interactive map feature to find events near and far <br />
            ● View videos to learn more about the event <br />
            ● Find your way to events easily and safely with MYLE transportation
            features including Uber/Lyft as well as walking and driving
            directions by connecting with your favorite map app. <br />
            ● Engage with event hosts - ask questions/leave comments and
            reviews<br />
            ● Share events with friends <br />
            ● Set a reminder so you don’t miss any of the fun <br />
            ● Bookmark events for quick reference and sharing with friends
            <br />
            ● COVID safety indicators so you can “know before you go”

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: How do I list my event or business on MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            All you need to do is create an account then login and hit the “+”
            symbol on the Navigation Bar to list your event/location. You will
            need to have a minimum of one photo for your listing. You can add
            two more photos and a :10 video clip for FREE.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: How do I get more exposure for my event or business on MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            We’re glad you asked! MYLE gives you the opportunity to promote your
            business for just $2.99 for a push notification that will reach
            MYLERS (platform users) within a 5-mile radius. You can also
            purchase a Mogul subscription that will open up more benefits, such
            as inclusion in “Featured Events”, a section banner, upgrade to a
            :60 video and a push notification radius increase to 50-miles. All
            this for either $19/month or $199/year, recurring!

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: Can I sell tickets to my event on MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Definitely! MYLE has the capability for event hosts, promoters and
            planners, or businesses to sell tickets through the MYLE platform.
            See our ticketing page for details.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 20px;
            "
          >
            Q: Why should I use MYLE to sell my tickets?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            MYLE is the most comprehensive event and entertainment platform on
            the market that also allows you to sell tickets and promote your
            event at the same time. Our seamless ticketing process, event
            amplification, video preview and COVID Safety Indicator features
            provide a simple process for you as well as attendees to get an
            overview of your event and complete their buying process in one
            place.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: Are there other benefits to selling tickets through MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            ● Guests can access their tickets under their account section on
            MYLE allowing for a frictionless and time-saving experience when
            entering events. <br />
            ● Guests can order a rideshare right in the app to get to and from
            your event easily and safely. The app also includes the ability to
            get walking and driving directions as well. <br />
            ● Guests can engage with you and your event through the comment
            feature <br />
            ● Guests can share events with their friends, view videos, leave
            reviews, build itineraries and set reminders, so they won’t miss
            your event.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: How much does it cost to sell tickets on MYLE?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            MYLE’s ticketing fees are 3.7% + 1.30 per ticket, plus 2.9% + .30
            per ticket for handling (which is done through Stripe). Event hosts
            have the option to pass along fees, cover the MYLE ticketing fees or
            the entire fee, by ticket type. There is no charge for free events.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: How does MYLE support the community?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Extra MYLE - Up to 5% of the proceeds that are earned by MYLE from a
            paid ticketed event or upgraded “Mogul” subscription account will be
            donated to our Non-Profit organization, Extra MYLE International.
            <br /><br />
            Extra MYLE was established to expose and educate underprivileged
            youth with Entertainment, Arts and Travel experiences that can have
            a positive impact on their lives. We believe that these experiences
            may impact their mindset, which may change their lives so that they
            may change the world. By using MYLE to sell your tickets and promote
            your events, you are making this a reality and you are Going The
            Extra MYLE.

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Q: Does MYLE have an affiliate program?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Yes!

            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 26px;
            "
          >
            For businesses/organizations/event creators that sell tickets<br />
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            You have the ability to generate additional revenue through our
            Affiliate/Referral program when you sell tickets through MYLE. You
            may invite your attendees to either scan your QR code upon
            purchase/entry or to click your referral link. You will receive 50
            MYLE Referral points for each sign-up that may be redeemed for cash
            when established goals are achieved. This is an easy way to earn
            additional income while also increasing your audience size for
            current and future events.
            <br /><br />
            <i>Note: To ensure you receive referral points, </i>
            <br />
            1. Have users click your QR code/Link to download the app and <br />
            2. Click your QR code/Link again to access the sign-up page with
            <br />
            <br />
            To personalize your QR code, tap on the code and edit it to your
            code of choice. For more information regarding our
            affiliate/referral program, click
            <a
              href="https://myle.com/affiliate"
              target="_blank"
              rel="noopener noreferrer"
            >
              here</a
            >.
            <br />
          </p>

          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 26px;
            "
          >
            For Individuals/Influencers<br />
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            You can also earn extra cash by referrals to MYLE. Each time you
            refer a friend and they sign up for MYLE, you will receive points.
            Once you reach a minimum threshold of points, you will start
            receiving cash for your referrals. If any of your referrals spend
            money in the app, you will also get a share from their purchase.

            <br />
          </p>

          <hr />

          <p
            class="lead-heading mt-4 mb-4 text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            Ticket FAQ’s for Event Promoters<br />
          </p>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Payout Process
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I receive my payout for tickets sold?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              After setting up your ticketed event, visit MYLE Manager via
              <a
                href="https://manager.myle.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                manager.myle.com</a
              >. Log in to MYLE Manager with the same credentials used for your
              account. Upon login to MYLE Manager, tap the Get Payment button.
              You will immediately receive an email with payout instructions and
              how to submit your account information to receive your payout(s).

              <br />
              Payout options include Paypal, Zelle or ACH Bank Wire.

              <br />Note: Transfer fees will be deducted from the final payout,
              when applicable.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: When do I receive my payout for tickets sold?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              We will initiate your payout process within three business days
              after your event date. You will receive an initial payout of 80%
              and within five business days, you will receive the balance minus
              any chargebacks. This payout schedule allows time for processing
              and securing all funds from your event.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Payment Confirmations
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I view attendees who purchase tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              You will be able to access your dashboard via MYLE Manager and
              view ticket purchase details for each event. You will also receive
              a confirmation email immediately after each ticket is purchased
              for your event. Confirmations will be sent to the email
              established when setting up your MYLE account. If you would like
              to add an additional email address to receive confirmations,
              please send your request via email to
              <a href="mailto:hello@myle.com"> hello@myle.com</a>. A member of
              our support team will follow-up within one (1) business day.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Ticket Changes
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I make edits to my event or tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              You may edit your event or ticket information by going to the
              account section on MYLE, via the mobile app or desktop version.
              Click on your event and make your edits.
              <br />
              Note: Once a ticket has been purchased for a particular ticket
              type, you will not be able to change the price for that ticket
              type. However, you may add a new ticket type and price.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Checking in attendees
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I check-in my attendees during the event?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              You will have the ability to check-in attendees with the MYLE
              scanner on your mobile device. Once you complete the ticket set-up
              and post your event, you will notice a scan button on the explore
              screen. You may use this scanner to verify attendees. To add
              access for additional scanners, please provide their email
              addresses to <a href="mailto:hello@myle.com"> hello@myle.com</a>,
              at least one (1) business day prior to your event. All individuals
              who will be scanning, must have the MYLE App downloaded on their
              phones or tablet.

              <br />
              Note: We will continue enhancing our MYLE Manager App which will
              provide you with admin access to view your attendees, transaction
              information, check-in attendees, review data insights and more.

              <br />
            </p>
          </div>

          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Emailing Attendees
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: How do I email my attendees?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              You will receive real-time attendee confirmation emails that
              include purchaser email information. Additionally, you will have
              the ability to export your ticket purchaser details into a csv
              file allowing you to easily import or copy/paste into an email
              client.

              <br />
              Note: We are developing an email client which will allow you to
              engage and email your attendees directly through MYLE.
            </p>
          </div>
          <div class="download-content mt-5">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 28px;
              "
            >
              Getting Help
            </p>
            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Q: Who do I contact if I have a question?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Please send inquiries to
              <a href="mailto:hello@myle.com"> hello@myle.com</a>. A member of
              our support team will follow up within one (1) business day. If
              you would like to learn more or ask specific questions, please
              join us during MYLE Office Hours on Tuesdays or Wednesdays. You
              may register
              <a
                href="https://myle.com/office-hours"
                target="_blank"
                rel="noopener noreferrer"
              >
                here</a
              >.
              <br />
            </p>
          </div>

          <hr />

          <div class="download-content mt-4">
            <p
              class="lead-heading mb-4 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 30px;
              "
            >
              Ticket FAQ’s for Event Guests
            </p>
            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: Where Can I Find My Tickets?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              There are two options for getting your event tickets. We highly
              recommend downloading the MYLE app where you can find your tickets
              in your account under “Tickets” which is on the top banner under
              your account. The other option is to find the confirmation email
              send from MYLE Ticket manager which will have an attached pdf of
              your ticket.
              <br />
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: What does a ticket look like?
            </p>

            <div class="row">
              <div class="col-md-3 col-lg-3 align-items-center"></div>

              <div class="col-md-6 col-lg-6 align-items-center mb-5">
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/sample_ticket.png"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>
              </div>
              <div class="col-md-3 col-lg-3 align-items-center"></div>
            </div>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: Once it has been scanned, what happens?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              When you arrive at the event, access your ticket page on the MYLE
              app. The host will scan your ticket. Once the ticket has been
              scanned, it cannot be scanned again. The host will inform you of
              any event access (in/out) privileges and what proof you would need
              to show such as a wristband, hand-stamp, etc.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: What happens if someone has used the QR code on my ticket?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Each ticket has a unique QR code, that is assigned only to your
              ticket. As long as you haven’t shared your ticket with anyone else
              or it was lost or stolen, the QR code should be active and ready
              to be accepted. If you have challenges, see the event host.
            </p>

            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Q: If I purchased a ticket does that mean I already have a MYLE
              account?
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              When you purchased a ticket, you were assigned a MYLE ID number to
              be associated with your purchase. We don’t automatically create an
              account for you but having the MYLE ID will speed up the process
              when you set up your MYLE account. You can use the ID or the email
              address you used to purchase your ticket as your username. The app
              will have you set up a password and add a few additional pieces of
              information and then you will be set!
            </p>

            <div class="download-content">
              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: What is my Username?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                Your username is the email you used to purchase your tickets.
                You can modify it after you create a MYLE account.
                <br />
              </p>
            </div>

            <div class="download-content">
              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: I received an email receipt but no tickets - where can I find
                them?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                You have two options to find your tickets:

                <br />
                1. Look for the email sent from MYLE Ticket Manager
                (tickets@myle.com) with the subject line “Ticket Purchase
                Confirmation” - it will have arrived minutes after you purchased
                your tickets. The ticket is attached as a pdf to the email.
                Still no luck??? Check your SPAM Folder.
                <br />
                2. MYLE App - once you download the MYLE app and set up your
                account, you can find your tickets under your profile/tickets.
                This is the most secure and recommended option for keeping your
                tickets handy and safe.
                <br />
              </p>
            </div>

            <div class="download-content">
              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: I received my tickets but no receipt - where can I find it?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                If it is a free event, there will not be an emailed receipt
                since no funds were transferred. If it is a paid event, you will
                receive an email from
                <a href="mailto:tickets@myle.com"> tickets@myle.com</a>.
                <br />
              </p>
            </div>

            <div class="download-content">
              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: Are my tickets attached to the email the same as in the app?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                Yes, they are the same ticket. The tickets on the MYLE app are
                digital. Both the pdf and digital version have the same QR code.
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: What happens if my ticket is lost?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                We highly recommend you create a MYLE account and then you can
                access the ticket through either the MYLE mobile app or desktop
                (for scanning or easy printing). Here are instructions on how to
                download the app and set up your account.
              </p>

              <div
                class="row justify-content-center align-items-center text-center mb-5"
              >
                <div
                  class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center"
                >
                  <h4
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 100;
                    "
                  >
                    Download or Sign-up Today!
                  </h4>
                  <div class="">
                    <div
                      class="download-btn text-center animate__animated animate__fadeIn"
                    >
                      <a
                        href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-apple"></span> App Store</a
                      >
                      <a
                        href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-android"></span> Google Play</a
                      >
                      <a
                        href="https://web.myle.com"
                        class="btn solid-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-world"></span> MYLE Web</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content">
              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: Why do I have to pay an extra fee to buy my tickets through
                MYLE?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                We charge 3.7% + $1.30 on each ticket as a handling fee which
                covers MYLE’s system and administrative costs, plus 2.9% + $0.30
                is charged by Stripe which handles the transfer of funds.
                <br />
                You’ll be glad to know that a portion of each fee collected by
                MYLE (out of the 3.7% + $1.30) will go to fund entertainment
                experiences for underserved youth and their families as part of
                MYLE’s non-profit, Extra MYLE International. So when you buy a
                ticket through MYLE you are helping to support the community.
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: How do I Access My Tickets on MYLE?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                1. Download the MYLE app <br />
                2. Set-up your account, your MYLE username is [Username] - Your
                username is case sensitive<br />
                3. Create a password and login<br />
                4. Tap on your account icon <br />
                5. Toggle to Tickets <br />
                6. Tap on event and you will see your tickets<br />
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: Why should I download the MYLE app?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                To Get to the Fun Faster! No more worries about finding an email
                or attached ticket, it will be in the app waiting for you. This
                will make the check-in process at the event faster and smoother,
                so you can get to the fun faster!
                <br /><br />
                Here are some more reasons to download MYLE:
                <br /><br />
                ● Use MYLE’s map feature to find other fun things to do near the
                location of your event<br />
                ● Order a rideshare to/from the event with Lyft or Uber - right
                out of the app (walking and driving directions available too)<br />
                ● Check-out the COVID-safety indicators to be prepared before
                you go<br />
                ● Leave comments for other users or the host - ask questions,
                leave a review or feedback<br />
                ● Share the event with friends<br />
                ● Discover more things to do and places to go (not to mention
                food to eat) on the MYLE platform<br />

                <br />

                Learn more about the MYLE features
                <a
                  href="https://myle.com/#features"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here</a
                >
                .
                <br />
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: What if I can’t attend the event - will I get a refund?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                Tickets sold through MYLE are by third party event organizers
                who sell and manage their tickets through MYLE.
                <br />
                All ticket sales through MYLE are final. No refunds will be
                available. You may be able to transfer your ticket to another
                user with permission from the event organizer.
                <br />
                Reach out to the Event Host through the MYLE app to inquire
                about options to transfer your ticket if you cannot attend the
                event.
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: How do I contact the Event Organizer?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                The best and easiest way to contact the event organizer is to
                reply to your ticket confirmation email and we will ensure the
                host is informed of your inquiry. Please allow 1-2 business days
                for a response.
              </p>

              <p
                class="lead-heading mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Q: Who do I contact if I need help?
              </p>

              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                ● MYLE Ticket FAQ<br />
                ● <a href="mailto:hello@myle.com"> Hello@myle.com</a><br />
                ● MYLE
                <a
                  href="https://myle.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us</a
                >
                Form
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Frequently Asked Questions",

    meta: [
      {
        name: "description",
        content: "MYLE - Frequently Asked Questions",
      },

      {
        name: "keywords",
        content: "MYLE - Frequently Asked Questions",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}
</style>
