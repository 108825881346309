var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('nav-bar')],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_c('br'),_vm._m(5),_c('div',{staticClass:"row align-items-center justify-content-between test mt-5",staticStyle:{"margin":"auto"}},[_c('video-embed',{attrs:{"css":"embed-responsive-16by9","src":"https://youtu.be/fqKX-YY2X8M?si=lx3T15EzZRq-kpxy"}})],1)]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12",attrs:{"id":"banner"}},[_c('img',{staticClass:"align-middle",staticStyle:{"width":"100%","max-width":"100%","height":"auto"},attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_100_black_men_2nd.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between test",staticStyle:{"margin":"auto","margin-top":"20px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading text-center mb-1",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"800","font-size":"45px"}},[_vm._v(" Welcome"),_c('br'),_vm._v(" 2024 Scholarship Gala Weekend ")])]),_c('hr'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading mb-5 text-center",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"22px"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 10 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/scholarship-gala-benefitting-the-leadership-academy","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Scholarship Gala Benefitting the Leadership Academy ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 10, 2024, 6:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/scholarship-gala-benefitting-the-leadership-academy","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/scholarship-gala-benefitting-the-leadership-academy","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Scholarship_Gala_2nd.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 11 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Jazz Brunch ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 11, 2024, 11:00am ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/jazz-brunch","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_jazz_brunch_2nd.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 10 - 11 "),_c('br'),_vm._v(" Aug ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/souvenir-program-booklet","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"black"}},[_vm._v(" Souvenir Program Booklet ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"black"}},[_vm._v(" August 10 - 11, 2024, 06:00pm - 03:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen mb-1 text-center",attrs:{"href":"https://myle.com/souvenir-program-booklet","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/souvenir-program-booklet","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-60 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_100_black_men_2nd.png","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-heading mb-2 p-3",staticStyle:{"background":"#121211"}},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-100blackmen-inverted mb-1 ml-3 mr-3 text-center",attrs:{"href":"https://myle.com/scholarship-gala-benefitting-the-leadership-academy-donations","target":"_self"}},[_vm._v("Donations")]),_c('a',{staticClass:"btn solid-btn-100blackmen-inverted ml-3 mr-3 mb-1 text-center",attrs:{"href":"https://myle.com/scholarship-gala-benefitting-the-leadership-academy-silent-auction","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Silent Auction")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 m-0 promo-section"},[_c('div',{staticClass:"col-lg-12 col-md-12 mt-5"},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"section-heading mb-5"},[_c('h2',{staticClass:"text-center",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"15px"}},[_vm._v(" Powered by "),_c('a',{attrs:{"target":"_blank","href":"https://myle.com/"}},[_vm._v("MYLE")])])])])])])
}]

export { render, staticRenderFns }