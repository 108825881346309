<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <!-- <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p> -->
              <!-- <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p> -->
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Full Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Enter your full name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Email Address</label
              >
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Graduating Class</label
              >
              <select class="form-control" v-model="classYear">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    '1944',
                    '1945',
                    '1946',
                    '1947',
                    '1948',
                    '1949',
                    '1950',
                    '1951',
                    '1952',
                    '1953',
                    '1954',
                    '1955',
                    '1956',
                    '1957',
                    '1958',
                    '1959',
                    '1960',
                    '1961',
                    '1962',
                    '1963',
                    '1964',
                    '1965',
                    '1966',
                    '1967',
                    '1968',
                    '1969',
                    '1970',
                    '1971',
                    '1972',
                    '1973',
                    '1974',
                    '1975',
                    '1976',
                    '1977',
                    '1978',
                    '1979',
                    '1980',
                    '1981',
                    '1982',
                    '1983',
                    '1984',
                    '1985',
                    '1986',
                    '1987',
                    '1988',
                    '1989',
                    '1990',
                    '1991',
                    '1992',
                    '1993',
                    '1994',
                    '1995',
                    '1996',
                    '1997',
                    '1998',
                    '1999',
                    '2000',
                    '2001',
                    '2002',
                    '2003',
                    '2004',
                    '2005',
                    '2006',
                    '2007',
                    '2008',
                    '2009',
                    '2010',
                    '2011',
                    '2012',
                    '2013',
                    '2014',
                    '2015',
                    '2016',
                    '2017',
                    '2018',
                    '2019',
                    '2020',
                    '2021',
                    '2022',
                    '2023',
                    '2024',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Zp9izBaReeI9K6t' &&
                TicketsResult[selected].ticketCode !== 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in (TicketsResult[selected] || {})
                    .availableSeating"
                  :value="seating.alias"
                >
                  {{ seating.alias }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-tuskegee m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: white"
    >
      <!-- Navbar section starts -->
      <!-- <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TU100thHomecomingAlumniBrunch_V1.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #a32035; border-top: 1px solid #eba900"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #eba900;
                "
              >
                TU 100th Homecoming Alumni Brunch
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: #eba900;
                "
              >
                Saturday, November 09, 2024, <br />
                11:00 AM - 12:30 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://maps.app.goo.gl/wggWAB7pmrhqnPzG9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #eba900"
                >
                  Logan Hall Lawn Alumni Tent
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: white"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  color: black;
                "
              >
                TU Alumni Brunch
              </p>

              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                Come join the reunion classes and other alumni as we celebrate
                homecoming with brunch after the parade.

                <br />
                <br />
                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Event Details:
                </span>

                <br />

                <span
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  📅 Date: Saturday, November 9, 2024
                  <br />
                  ⏰ Time: 11:00 am – 12:30 pm
                  <br />
                  📍 Location: White Alumni Tent on Logan Hall Lawn
                  <br />
                  🎵 DJ Big Ted from Detroit
                  <br />
                  💵 Ticket Price: $50.00
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div
              class="text-center"
              style="background: #a32035; color: #eba900"
            >
              <h3
                class="w-auto d-inline-block pt-3 pb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  background: #a32035;
                  color: #eba900;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <br />
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                  box-shadow: 4px 4px #a32035;
                  color: black;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header white"
                  style="
                    background: #a32035;
                    color: #eba900;
                    width: 310px !important;
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.12em;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                    style="
                      color: #000000;
                      font-family: 'nunito', sans-serif;
                      font-weight: 500;
                      font-size: 1.1em;
                    "
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p
                      style="
                        color: #000000;
                        font-family: 'nunito', sans-serif;
                        font-weight: 800;
                        font-size: 1.3em;
                        margin-top: auto;
                      "
                    >
                      Total Price: ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-tuskegee shimmer"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                        font-weight: bold;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sales Ended"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-5 mt-2">
        <div class="text-center">
          <a
            href="https://myle.com/tu-100th-homecoming-alumni-events"
            class="btn solid-btn-tuskegee text-center"
            target="_self"
            style="
              pointer-events: all;
              cursor: pointer;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              font-size: 1.1em;
              font-weight: bold;
            "
            >Back To Main Page
          </a>
        </div>
      </div>
      <br />
    </div>

    <section class="container align-items-center text-center">
      <div class="row align-items-center">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo_TU.png"
              alt="Saturday Event"
              class="w-75 img-fluid align-items-center"
            />
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
    </section>

    <div class="row w-100 promo-section m-0">
      <div class="col-lg-12 col-md-12">
        <div class="">
          <div class="section-heading">
            <h2
              class="text-center"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../components/confirmationPopup";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      classYear: "1944",
      showsuccess: false,
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: ["tk_lLJt65CNiCBSuat"],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_lLJt65CNiCBSuat: {
          messages: ["Ticket Price: $50", "Processing fee of $4.99"],
        },
      },

      priceIds: {
        tk_lLJt65CNiCBSuat: "price_1Q78J0D0vTZ4QB9cLTPrRHj4",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    // NavBar,
    // SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },

    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },

    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);
        console.log("--------");
        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? v.text == "1" || v.text == "9"
                  ? "#0ea293"
                  : "#a19140"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 300)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 306.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 40)
        .attr("height", 40)
        .attr("y", 100)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("y", 125)
        .attr("x", 2.5)
        .text("Band")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }

      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_NzrhJLxmsLhNLaD"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      }
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.classYear = "1944";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          classYear: this.classYear,
        },
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "ITRYscQmxtvbAX",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_lLJt65CNiCBSuat: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          // vm.drawseatsd1(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");

    console.log(this.$route.query.checkout);

    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - TU 100th Homecoming Alumni Brunch",
    meta: [
      {
        name: "description",
        content: "MYLE - TU 100th Homecoming Alumni Brunch",
      },

      {
        name: "keywords",
        content: "MYLE - TU 100th Homecoming Alumni Brunch",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/tu-100th-homecoming-alumni-brunch",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}
</style>
