<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/CSU_Stroll_Event_MYLE_Heade_Banner.PNG"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 45px;
              "
            >
              Central State SpringFest 2023
            </p>
          </div>
          <hr />

          <div class="row mt-5 mb-5">
            <div class="col-md-6 col-lg-6 align-items-center">
              <a
                href="https://myle.com/csu-greek-stroll-off"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/CSU_Stroll_Event_MYLE_Heade_Banner.PNG"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  CSU Greek Stroll Off
                </p>
                <!-- <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Tuesday, Oct 04, 2022
                  <br />
                  07:00pm
                </p> -->
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/csu-greek-stroll-off"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-6 justify-content-center align-items-center"
            >
              <a
                href="https://myle.com/csu-greek-stroll-off-greeks-only"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/CSU_Stroll_greek_only_Event_MYLE_Heade_Banner.jpeg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  CSU Greek Stroll Off (Greeks Only)
                </p>
                <!-- <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Friday, Oct 07, 2022
                  <br />
                  8:00pm
                </p> -->
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/csu-greek-stroll-off-greeks-only"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3"></div>
            <div
              class="col-md-6 col-lg-6 mt-3 justify-content-center align-items-center mt-5"
            >
              <a
                href="https://myle.com/springfest-silent-headphone-party"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Springfest_Silent_Headphone_Party_header.jpeg"
                    alt="Saturday Event"
                    class="w-100 img-fluid"
                  />
                </div>

                <p
                  class="text-center lead-heading ml-3 mb-0"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                  "
                >
                  Springfest Silent Headphone Party
                </p>
                <!-- <p
                  class="text-center lead-heading ml-3 mb-3"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                  "
                >
                  Saturday Oct 8 , 2022
                  <br />
                  07:00pm
                </p> -->
              </a>

              <div class="download-content mr-5 ml-5">
                <div
                  class="download-btn text-center animate__animated animate__fadeIn"
                >
                  <a
                    href="https://myle.com/springfest-silent-headphone-party"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Purchase</a
                  >
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 mt-3"></div>
          </div>
        </div>
      </div>

      <!-- <div class="container">
          <div class="row w-100 m-0 text-center promo-section MYLE-green">
            <div class="col-lg-12 col-md-12">
              <div class="mt-3">
                <div class="section-heading mb-3">
                  <h5 class="text-center text-white">
                    Black Food Truck Festival 2022 Promo
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <video-embed
            css="embed-responsive-16by9"
            src="https://youtu.be/u5KyJIuWw3s"
          ></video-embed>
        </div> -->

      <!-- <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
              <div class="col-lg-12 col-md-12">
                <div class="mt-3">
                  <div class="section-heading mb-3">
                    <h5 class="text-center text-white">
                      Fearless Fund VC Summit Events
                    </h5>
                  </div>
                </div>
              </div>
            </div> -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Central State University’s official Springfest 2023
                <br /><br />
                For your convenience, we have partnered with MYLE as our ticket
                provider.<br />
                Be sure to download the MYLE Events App by clicking the
                appropriate App Store button below.<br />
                Use referral code "CentralState" during account set-up to easily
                access your tickets in the account/tickets section on the app
                once you complete your purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
              <div class="col-md-6 col-lg-6 align-items-center">
                <a
                  href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
      
                  <p
                    class="text-center lead-heading ml-3 mb-0"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 18px;
                    "
                  >
                    For The Kings | Juneteenth + Father's Day Brunch
                  </p>
                  <p
                    class="text-center lead-heading ml-3 mb-3"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    Sun, Jun 19, 12:00 PM
                  </p>
                </a>
              </div>
            </div> -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE | Central State SpringFest 2023",

    meta: [
      {
        name: "description",
        content: "MYLE | Central State SpringFest 2023",
      },

      {
        name: "keywords",
        content: "MYLE | Central State SpringFest 2023",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/central-state-spring-fest-2023",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
