<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>

              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketName &&
                  requiredMsg[
                    TicketsResult[selected].ticketName.split('Seats ')[1]
                  ]
                "
              >
                <b class="font-weight-bold">Required Purchase:</b>
                {{
                  requiredMsg[
                    TicketsResult[selected].ticketName.split("Seats ")[1]
                  ]
                }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Full Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Email Address</label
              >
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_N6UiRjYPVYrTywO') ||
                TicketsResult[selected].ticketCode == 'tk_SYYW4UXeJO9T1oN'
              "
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Available Tables</label
              >
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  background: #61d3d1;
                "
              >
                Redeem Code
              </button>
            </div>
          </div>
          <!-- <hr />

          <div class="row">
            <div
              class="col-md-4 mt-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName &&
                TicketsResult[selected].ticketCode !== 'tk_SFzmA04xZfJayyD'
              "
            >
              <label
                class="font-weight-bold mr-4"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Reserve Bottle Options:</label
              >
            </div>
            <div
              class="col-md-8 mt-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName &&
                TicketsResult[selected].ticketCode !== 'tk_SFzmA04xZfJayyD'
              "
            >
              <input
                type="radio"
                v-model="bottleoption"
                id="Liquor"
                value="Liquor"
              />
              <label
                for="Liquor"
                class="mr-5 ml-1"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Liquor</label
              >

              <input
                type="radio"
                v-model="bottleoption"
                id="Wine"
                value="Wine"
              />
              <label
                for="Wine"
                class="mr-3 ml-1"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Wine</label
              >
            </div>
          </div> -->

          <div class="row">
            <!--  <div
              class="col-md-12 mt-4"
              v-if="
                bottleoption === 'Liquor' &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName &&
                itemMap[
                  TicketsResult[selected].ticketName.split('Seats ')[1]
                ] &&
                TicketsResult[selected].ticketCode !== 'tk_SFzmA04xZfJayyD'
              "
            >
              <doublelist
                v-if="
                  bottleoption === 'Liquor' &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketName &&
                  itemMap[TicketsResult[selected].ticketName.split('Seats ')[1]]
                "
                @listtopush="listtopush"
                :list="loptions"
                :limit="
                  itemMap[TicketsResult[selected].ticketName.split('Seats ')[1]]
                    .liquor
                "
              />
            </div>

            <div
              class="col-md-12 mt-4"
              v-if="
                bottleoption === 'Wine' &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName &&
                itemMap[
                  TicketsResult[selected].ticketName.split('Seats ')[1]
                ] &&
                TicketsResult[selected].ticketCode !== 'tk_SFzmA04xZfJayyD'
              "
            >
              <doublelist
                @listtopush="listtopush"
                :list="woptions"
                v-if="
                  bottleoption === 'Wine' &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketName &&
                  itemMap[TicketsResult[selected].ticketName.split('Seats ')[1]]
                "
                :limit="
                  itemMap[TicketsResult[selected].ticketName.split('Seats ')[1]]
                    .wine
                "
              />
            </div>

            <div
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketName &&
                TicketsResult[selected].ticketCode !== 'tk_SFzmA04xZfJayyD'
              "
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                font-size: 15px;
                color: black;
              "
            >
              Bottle selections will be charged by the venue at the event.
            </div> -->

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 16px;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  background: #61d3d1;
                "
              >
                Buy Now
              </button>
            </div>

            <!-- ||
                  (TicketsResult[selected].ticketCode == 'tk_ktWG5PPojiUDOA0'
                    ? !redeemData
                    : false) ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
                    ? selectionList.length < 1
                    : false) ||
                  (TicketsResult[selected].ticketCode !== 'tk_SkKkwp0wxHYQdoy'
                    ? bottleoption === 'Liquor'
                      ? selectionList.length <
                        itemMap[
                          TicketsResult[selected].ticketName.split('Seats ')[1]
                        ].liquor
                      : selectionList.length <
                        itemMap[
                          TicketsResult[selected].ticketName.split('Seats ')[1]
                        ].wine
                    : false) -->
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Big_Blue_and_HU_Homecoming_Post_Game_Soiree_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #61d3d1; border-top: 1px solid white"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                Big Blue and HU Homecoming Post-Game Soiree
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: white;
                "
              >
                Saturday, October 19, 2024, <br />
                9 PM - 3 AM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: white;
                "
              ></h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                <a
                  href="https://maps.app.goo.gl/mzXx7g5r5VoeQaABA"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  1101 17th Street
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.6em;
                  color: rgb(34, 33, 33);
                  font-weight: 500;
                "
              >
                Big Blue comes to Howard University Homecoming, Featuring DJ
                Blinks of HU with DJ Spoon of HU and DJ C-LO of Tenn State
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section ends -->

      <div class="section-heading text-center mt-5">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          Seating Map
        </h2>
      </div>

      <!-- Map starts -->
      <div
        id="d2"
        style="
          max-width: 800px;
          margin: auto;
          margin-top: 50px;
          overflow-x: scroll;
        "
      ></div>
      <!-- Map ends -->

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #61d3d1;
                      color: #61d3d1;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Available For Purchase
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mt-5"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.4em;
                "
              >
                TICKET OPTIONS
              </h3>
              <!-- <br />
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.8em;
                  color: red;
                "
              >
                NO REFUNDS
              </h3> -->
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="background: #61d3d1; color: black"
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                        background: #61d3d1;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <br />
              <div class="section-heading text-center">
                <h2
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 32px;
                    color: black;
                  "
                >
                  Bottle List
                </h2>
              </div>
              <div class="row justify-content-center">
                <div
                  class="col-8 col-md-6 justify-content-center d-flex align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bottle_list_Saloon_Bottle.jpg"
                      alt="Slow Jam Social Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div> -->

      <!-- <br />
              <br />
              <div class="section-heading text-center">
                <h2
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 32px;
                    color: black;
                  "
                >
                  The Skegee Ball & Parlay Homecoming Soiree
                </h2>
              </div> -->

      <!-- <div class="row justify-content-center">
                <div
                  class="col-8 col-md-6 justify-content-center d-flex align-items-center"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_TheSkegeeBallParlayHomecomingSoiree.jpeg"
                      alt="Slow Jam Social Event"
                      class="w-100 img-fluid"
                    />
                  </div>
                </div>
              </div> -->
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #61d3d1">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: #ffffff">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../components/confirmationPopup";
// import doublelist from "./doublelist.vue";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      seatsVal: {
        11: "Seats 8",
        12: "Seats 8",
        13: "Seats 8",
        101: "Seats 4",
        103: "Seats 4",
        105: "Seats 6",
        107: "Seats 6",
        71: "Seats 8",
        72: "Seats 8",
        73: "Seats 8",

        21: "Seats 8",
        22: "Seats 8",
        23: "Seats 8",
        24: "Seats 8",

        31: "Seats 10",
        32: "Seats 4",
        33: "Seats 10",
        34: "Seats 10",
        35: "Seats 4",
        61: "Seats 4",
        63: "Seats 4",
        62: "Seats 6",
        64: "Seats 6",
        36: "Seats 10",
      },
      requiredMsg: {
        4: "Min. 1 bottle of liquor, or 2 bottles of Wine",
        5: "Min. 1 bottle of liquor, or 2 bottles of Wine",
        8: "Min. 2 bottles of liquor, or 4 bottles of Wine",
        10: "Min. 2 bottles of liquor, or 4 bottles of Wine",
        12: "Min. 3 bottles of liquor, or 6 bottles of Wine",
      },

      bottleoption: "Liquor",
      loptions: [
        {
          alias: "Hennessy - $300",
        },
        {
          alias: "Dusse - $300",
        },
        {
          alias: "Jack Daniels - $300",
        },
        {
          alias: "Jack Daniels Honey - $300",
        },
        {
          alias: "Makers Mark - $300",
        },
        {
          alias: "Crown Royal - $300",
        },
        {
          alias: "Crown Apple - $300",
        },
        {
          alias: "Tito's - $300",
        },
        {
          alias: "Grey Goose $300",
        },
        {
          alias: "All flavor Cirocs $300",
        },
        {
          alias: "1800 $300",
        },
        {
          alias: "Patron $300",
        },
        {
          alias: "Don Julio Silver/Blanco $300",
        },
        {
          alias: "Casamigos Silver/Blanco $300",
        },
        {
          alias: "Don Julio Reposado $400",
        },
        {
          alias: "Casamigos Reposado $400",
        },
      ],
      woptions: [
        {
          alias: "Pinot Noir - $200",
        },
        {
          alias: "Cabernet Sauvignon - $200",
        },
        {
          alias: "Pinot Grigio - $200",
        },
        {
          alias: "Chardonnay - $200",
        },
        {
          alias: "Sauvignon Blanc - $100",
        },
        {
          alias: "Moet Rose - $300",
        },
        {
          alias: "Prosecco - $200",
        },
      ],
      itemMap: {
        4: {
          liquor: 1,
          wine: 2,
        },
        5: {
          liquor: 1,
          wine: 2,
        },
        8: {
          liquor: 2,
          wine: 4,
        },
        10: {
          liquor: 2,
          wine: 4,
        },
        12: {
          liquor: 3,
          wine: 6,
        },
      },
      metaTags: [],
      metaTitle: "",
      showsuccess: false,
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        // "tk_SFzmA04xZfJayyD",
        // "tk_qs4a9qamuE8V1lc",
        "tk_SYYW4UXeJO9T1oN",
        "tk_N6UiRjYPVYrTywO",
        "tk_iL8i8FFBj8m3wBP",
        "tk_lYdYfyl2UaysdMT",
        "tk_SCbHQ7VsQTW3QsK",
        "tk_eZ4sJ4RXqidRonx",
        "tk_hlXXPeibf9c4NXJ",
        "tk_E8XwIJchpbPmQ9G",
        "tk_UX5dE38p00h4BXC",
        "tk_mdOnbujVv0EGwOd",
        "tk_fYT9lGgia2yXyk0",
        "tk_drcqB55g0RQXCRU",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_SFzmA04xZfJayyD: {
        //   messages: ["Ticket Price: $20", "Processing fee of $2.98"],
        // },
        tk_SYYW4UXeJO9T1oN: {
          messages: ["Ticket Price: $30", "Processing fee of $3.65"],
        },
        tk_N6UiRjYPVYrTywO: {
          messages: ["Ticket Price: $40", "Processing fee of $4.32"],
        },
        tk_iL8i8FFBj8m3wBP: {
          messages: ["Ticket Price: $160", "Processing fee of $12.37"],
        },
        tk_lYdYfyl2UaysdMT: {
          messages: ["Ticket Price: $120", "Processing fee of $9.69"],
        },
        tk_SCbHQ7VsQTW3QsK: {
          messages: ["Ticket Price: $80", "Processing fee of $7"],
        },
        tk_eZ4sJ4RXqidRonx: {
          messages: ["Ticket Price: $160", "Processing fee of $12.37"],
        },
        tk_hlXXPeibf9c4NXJ: {
          messages: ["Ticket Price: $80", "Processing fee of $7"],
        },
        tk_E8XwIJchpbPmQ9G: {
          messages: ["Ticket Price: $120", "Processing fee of $9.69"],
        },
        tk_UX5dE38p00h4BXC: {
          messages: ["Ticket Price: $160", "Processing fee of $12.37"],
        },
        tk_mdOnbujVv0EGwOd: {
          messages: ["Ticket Price: $40", "Processing fee of $4.32"],
        },
        tk_fYT9lGgia2yXyk0: {
          messages: ["Ticket Price: $80", "Processing fee of $7"],
        },
        tk_drcqB55g0RQXCRU: {
          messages: ["Ticket Price: $200", "Processing fee of $15.05"],
        },
      },

      priceIds: {
        // tk_SFzmA04xZfJayyD: "price_1PqbKAD0vTZ4QB9cLvyVihn1",
        tk_SYYW4UXeJO9T1oN: "price_1PvoGpD0vTZ4QB9c6x0Ir0KO",
        tk_N6UiRjYPVYrTywO: "price_1QBilzD0vTZ4QB9cppD6PLBP",
        tk_iL8i8FFBj8m3wBP: "price_1PtI5TD0vTZ4QB9cGK40iRdp",
        tk_lYdYfyl2UaysdMT: "price_1PtI9GD0vTZ4QB9ct24TMmEw",
        tk_SCbHQ7VsQTW3QsK: "price_1PtIBVD0vTZ4QB9cifrnZ5e5",
        tk_eZ4sJ4RXqidRonx: "price_1PtIEwD0vTZ4QB9cLQBwSUY7",
        tk_hlXXPeibf9c4NXJ: "price_1PtIS2D0vTZ4QB9c1EIRgVUj",
        tk_E8XwIJchpbPmQ9G: "price_1PtIS3D0vTZ4QB9cv07Wyhky",
        tk_UX5dE38p00h4BXC: "price_1PtIaPD0vTZ4QB9cpg76rvKU",
        tk_mdOnbujVv0EGwOd: "price_1PtIdSD0vTZ4QB9cYjaj9akb",
        tk_fYT9lGgia2yXyk0: "price_1PtIdSD0vTZ4QB9cRyIKb1Zy",
        tk_drcqB55g0RQXCRU: "price_1PtIdTD0vTZ4QB9cQUeMmItG",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
    // doublelist,
  },

  methods: {
    datamapper1(respdata) {
      console.log(respdata);
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };

      const texts1 = [
        [[21], [22], [23], [24], [25], [26]],
        [
          [31, 41],
          [32, 42],
          [33, 43],
          [34, 44],
          [35, 45],
          [36, 46],
        ],
        [[61], [62], [63], [64]],
        [[""], [""], [""], [""]],
        [[71], [72], [73], [74]],
        [[""], [""], [""], [""]],
        [[13, 12, 11, 107, 105, 103, 101]],
      ];
      const distances = [
        [[0], [0], [0], [0], [0], [0]],
        [
          [200, 230],
          [200, 230],
          [200, 230],
          [200, 230],
          [200, 230],
          [200, 230],
        ],
        [[400], [400], [400], [400]],
        [[550], [550], [550], [550]],
        [[600], [600], [600], [600]],
        [[650], [650], [650], [650]],
        [[100, 150, 200, 450, 500, 550, 600]],
      ];

      const nodes = [];

      for (let y = 1; y <= texts1.length; y++) {
        for (let i = 0; i < texts1[y - 1].length; i++) {
          for (let j = 0; j < texts1[y - 1][i].length; j++) {
            nodes.push({
              x: 50 + distances[y - 1][i][j],
              y: 90 * i + (y == 7 ? 580 : 0),
              val: seat[8],
              distance: 0,
              ydistance: 0,
              selected: false,
              text: texts1[y - 1][i][j],
              height: 60,
              width: y == 7 ? 40 : 30,
              id: texts1[y - 1][i][j],
            });
          }
        }
      }

      return nodes;
    },
    drawseatsInfo(listdata) {
      const dd = {};

      listdata.forEach((rec) => {
        rec.availableSeating.forEach((r) => {
          const e = r.table.split(" ");
          dd[e[1]] = true;

          if (e[1] == 31 || e[1] == 41) {
            dd[31] = true;
            dd[41] = true;
          }

          if (e[1] == 33 || e[1] == 43) {
            dd[33] = true;
            dd[43] = true;
          }

          if (e[1] == 34 || e[1] == 44) {
            dd[34] = true;
            dd[44] = true;
          }

          if (e[1] == 36 || e[1] == 46) {
            dd[36] = true;
            dd[46] = true;
          }
        });
      });
      console.log(dd);
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "800px")
        .attr("height", "600px");
      this.datamapper1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance + 30;

        if (this.seatsVal[v.text]) {
          svg
            .append("text")
            .attr({
              x: originX,
              y: originY - 13,
              "font-size": 14,
              stroke: "black",
              fill: v.text ? "none" : "black",
            })
            .text(this.seatsVal[v.text]);
        }

        svg
          .append("rect")
          .attr({
            x: originX,
            y: originY,
            width: v.width,
            height: v.height,
            stroke: v.fill ? "black" : "white",
          })
          .attr(
            "fill",
            v.text.length <= 0 ? "black" : dd[v.id] ? "#61d3d1" : "#d3d3d3"
          )
          .attr("name", v.id);

        svg
          .append("text")
          .attr({
            x: originX + ((v.text < 10 || v.text.length) < 2 ? 12 : 6),
            y: originY + (v.height === 30 ? 21 : 31),
            "font-size": 15,
            stroke: !v.fill ? "black" : "none",
          })
          .text(v.text);
      });

      svg.append("rect").attr({
        x: 0,
        y: 0,
        width: 30,
        height: 520,
        stroke: "black",
        fill: "black",
      });

      svg.append("rect").attr({
        x: 350,
        y: 0,
        width: 30,
        height: 530,
        stroke: "black",
        fill: "black",
      });

      svg.append("rect").attr({
        x: 400,
        y: 560,
        width: 300,
        height: 5,
        stroke: "black",
        fill: "black",
      });

      svg.append("rect").attr({
        x: 0,
        y: 560,
        width: 300,
        height: 5,
        stroke: "black",
        fill: "black",
      });

      svg
        .append("text")
        .attr({
          x: 0,
          y: 540,
          "font-size": 15,
          stroke: "black",
        })
        .text("Bar");

      svg
        .append("text")
        .attr({
          x: 350,
          y: 550,
          "font-size": 15,
          stroke: "black",
        })
        .text("DJ Booth");
    },
    datamapper() {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        ["", 2, 3, 3, 4, 5],
        [19, 18, 16, 14],
        [17, 15, 13],
        [12, 6],
        [11, 7],
        [8],
        [10, 9],
      ];
      const distances = [
        [10, 5, 15, 10, 20, 15],
        [120, 170, 150, 130],
        [180, 180, 180],
        [240, 335],
        [240, 335],
        [415],
        [330, 325],
      ];

      const heights = [
        [30, 30, 30, 30, 30, 30],
        [30, 30, 30, 30],
        [30, 30, 30],
        [50, 50],
        [50, 50],
        [50],
        [30, 30],
      ];
      const nodes = [];
      for (let y = 1; y <= distances.length; y++) {
        for (let i = 1; i <= distances[y - 1].length; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1][i - 1],
              y: 50 * y + ((y == 4 && i == 1) || (y == 5 && i == 1) ? 20 : 0),
              val: seat[8],
              distance: i * 10,
              ydistance: y * 5,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
              height: heights[y - 1][i - 1],
            });
          }
        }
      }

      return nodes;
    },
    // datamapper1(respdata) {
    //   console.log(respdata);
    //   const seat = {
    //     1: 1200,
    //     2: 600,
    //     3: 400,
    //     4: 300,
    //     5: 240,
    //     6: 200,
    //     7: 171.4285714,
    //     8: 150,
    //     9: 133.33333,
    //     10: 120,
    //     11: 109.1,
    //   };
    //   const texts = [
    //     [
    //       ["A", 5, "B"],
    //       ["", 6, ""],
    //       ["", 7, ""],
    //       ["A", 8, "B"],
    //     ],
    //     [
    //       ["", 1, ""],
    //       ["", 2, ""],
    //       ["", 3, ""],
    //       ["", 4, ""],
    //     ],
    //   ];

    //   const distances = [
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //       [310, 340, 370],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //       [310, 340, 370],
    //     ],
    //   ];

    //   const texts1 = [
    //     [
    //       ["A", 31, "B"],
    //       ["", 32, ""],
    //       ["A", 33, "B"],
    //     ],
    //     [
    //       ["", 28, ""],
    //       ["", 29, ""],
    //       ["", 30, ""],
    //     ],
    //     [
    //       ["A", 25, "B"],
    //       ["", 26, ""],
    //       ["A", 27, "B"],
    //     ],
    //     [[24], ["A", 20, "B"]],
    //     [[23], ["", 19, ""]],
    //     [[22], ["A", 18, "B"]],
    //     [[21]],
    //     [
    //       ["A", 15, "B"],
    //       ["", 16, ""],
    //       ["A", 17, "B"],
    //     ],
    //     [
    //       ["", 9, ""],
    //       ["", 10, ""],
    //       ["", 11, ""],
    //     ],
    //     [
    //       ["A", 12, "B"],
    //       ["", 13, ""],
    //       ["A", 14, "B"],
    //     ],
    //   ];

    //   const distances1 = [
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [110, 140, 190],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [110, 140, 190],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [110, 140, 190],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [110, 140, 190],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //     [
    //       [0, 30, 60],
    //       [100, 130, 160],
    //       [210, 240, 270],
    //     ],
    //   ];

    //   const nodes = [];
    //   for (let y = 1; y <= texts.length; y++) {
    //     for (let i = 0; i < texts[y - 1].length; i++) {
    //       for (let j = 0; j < texts[y - 1][i].length; j++) {
    //         nodes.push({
    //           x: 50 + distances[y - 1][i][j],
    //           y: 450 + 70 * y,
    //           val: seat[8],
    //           distance: 0,
    //           ydistance: y * 5,
    //           selected: false,
    //           text: texts[y - 1][i][j],
    //           fill: j == 1 ? "black" : null,
    //           height: 30,
    //           id:
    //             texts[y - 1][i][j] == "A" || texts[y - 1][i][j] == "B"
    //               ? texts[y - 1][i][1] + texts[y - 1][i][j]
    //               : texts[y - 1][i][j] == ""
    //               ? texts[y - 1][i][1]
    //               : texts[y - 1][i].length == 1
    //               ? texts[y - 1][i][0]
    //               : "",
    //         });
    //       }
    //     }
    //   }

    //   for (let y = 1; y <= texts1.length; y++) {
    //     for (let i = 0; i < texts1[y - 1].length; i++) {
    //       for (let j = 0; j < texts1[y - 1][i].length; j++) {
    //         nodes.push({
    //           x:
    //             600 +
    //             distances1[y - 1][i][j] +
    //             (texts1[y - 1][i].length == 1 ? 30 : 0),
    //           y: 70 * y,
    //           val: seat[8],
    //           distance: 0,
    //           ydistance: y * 5,
    //           selected: false,
    //           fill: j == 1 ? "black" : null,
    //           text: texts1[y - 1][i][j],
    //           height: 30,
    //           id:
    //             texts1[y - 1][i][j] == "A" || texts1[y - 1][i][j] == "B"
    //               ? texts1[y - 1][i][1] + texts1[y - 1][i][j]
    //               : texts1[y - 1][i][j] == ""
    //               ? texts1[y - 1][i][1]
    //               : texts1[y - 1][i].length == 1
    //               ? texts1[y - 1][i][0]
    //               : "",
    //         });
    //       }
    //     }
    //   }

    //   return nodes;
    // },
    // drawseatsInfo(listdata) {
    //   const dd = {};

    //   listdata.forEach((rec) => {
    //     rec.availableSeating.forEach((r) => {
    //       const e = r.table.split(" ");
    //       dd[e[1]] = true;
    //     });
    //   });
    //   console.log(dd);
    //   const svg = window.d3
    //     .select("div#d2")
    //     .append("svg")
    //     .attr("width", "1000px")
    //     .attr("height", "850px");
    //   this.datamapper1(listdata).forEach((v) => {
    //     const originX = v.x + v.distance;
    //     const originY = v.y + v.ydistance;

    //     if (this.seatsVal[v.text]) {
    //       svg
    //         .append("text")
    //         .attr({
    //           x: originX - 10,
    //           y: originY - 15,
    //           "font-size": 15,
    //           stroke: "black",
    //         })
    //         .text(this.seatsVal[v.text]);
    //     }

    //     svg
    //       .append("rect")
    //       .attr({
    //         x: originX,
    //         y: originY,
    //         width: 30,
    //         height: 30,
    //         fill: v.selected ? "yellow" : "none",
    //         stroke: v.fill ? "black" : "white",
    //       })
    //       .attr("fill", !v.fill ? (dd[v.id] ? "#61d3d1" : "#d3d3d3") : v.fill)
    //       .attr("name", v.id);

    //     svg
    //       .append("text")
    //       .attr({
    //         x: originX + ((v.text < 10 || v.text.length) < 2 ? 12 : 6),
    //         y: originY + (v.height === 30 ? 21 : 31),
    //         "font-size": 15,
    //         stroke: !v.fill ? "black" : "white",
    //       })
    //       .text(v.text);
    //   });

    //   svg.append("rect").attr({
    //     x: 120,
    //     y: 650,
    //     width: 120,
    //     height: 30,
    //     stroke: "black",
    //     fill: "white",
    //   });

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 165,
    //       y: 670,
    //       "font-size": 15,
    //       stroke: "black",
    //     })
    //     .text("Bar");

    //   svg.append("rect").attr({
    //     x: 610,
    //     y: 370,
    //     width: 60,
    //     height: 100,
    //     stroke: "black",
    //     fill: "white",
    //   });

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 630,
    //       y: 420,
    //       "font-size": 15,
    //       stroke: "black",
    //     })
    //     .text("Bar");

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 155,
    //       y: 740,
    //       "font-size": 35,
    //       stroke: "black",
    //     })
    //     .text("ALIBI");

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 170,
    //       y: 800,
    //       "font-size": 15,
    //       stroke: "black",
    //     })
    //     .text("OPIUM → ");

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 385,
    //       y: -590,
    //       "font-size": 15,
    //       stroke: "black",
    //     })
    //     .attr("transform", "rotate(90)")
    //     .text("DJ BOOTH");

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 370,
    //       y: -680,
    //       "font-size": 35,
    //       stroke: "black",
    //     })
    //     .attr("transform", "rotate(90)")
    //     .text("OPIUM");

    //   svg.append("rect").attr({
    //     x: 510,
    //     y: 50,
    //     width: 10,
    //     height: 710,
    //     stroke: "black",
    //     fill: "black",
    //   });

    //   svg
    //     .append("text")
    //     .attr({
    //       x: 540,
    //       y: -30,
    //       "font-size": 15,
    //       stroke: "black",
    //     })
    //     .attr("transform", "rotate(90)")
    //     .text("DJ BOOTH");
    // },
    getMetaTagsInfo() {
      const vm = this;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/fetch_meta_info",
        data: {
          eid: "zC6kJyNBrL9ARR",
          src: "WEB_CUSTOM",
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.metaTags = re.data.Result;
            const titleTags = vm.metaTags.find((tag) => tag.name == "name");
            vm.metaTitle = titleTags.content;
          }
        })
        .catch((error) => {
          console.error("There is an error!", error);
        });
    },

    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    drawseatsd2(list1) {
      // console.log(list1);
      const dd = {};
      list1.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          const ww = l1.table.split(" ");
          if (l.ticketCode == "tk_lvmwXs58ytfxa5A") {
            dd["S8"] = true;
            dd["S11"] = true;
          } else if (l.ticketCode == "tk_ktWG5PPojiUDOA0") {
            dd["S10"] = true;
          } else if (l.ticketCode == "tk_rY7Goed1LyC5kJx") {
            dd["S7"] = true;
            dd["S9"] = true;
          } else if (l1.table == "Section " + ww[ww.length - 1]) {
            dd[ww[ww.length - 1]] = true;
          }
        });
      });

      // console.log(Object.keys(dd));

      const svg = window.d3
        .select("#d2")
        .append("svg")
        .attr("width", "650px")
        .attr("height", "900px");

      const list = [
        [
          null,
          null,
          null,
          null,
          null,
          {
            label: "27",
            width: 50,
            height: 40,
          },
        ],
        [
          null,
          null,
          {
            label: "30",
            width: 50,
            height: 40,
          },
          {
            label: "29",
            width: 50,
            height: 40,
          },
          {
            label: "28",
            width: 50,
            height: 40,
          },
          null,
          null,
          {
            label: "26",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "25",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          null,
          {
            label: "21",
            width: 80,
            height: 50,
          },
          {
            label: "22",
            width: 80,
            height: 50,
          },
          {
            label: "23",
            width: 80,
            height: 50,
          },
          {
            label: "24",
            width: 80,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          {
            label: "17",
            width: 50,
            height: 50,
          },
          {
            label: "18",
            width: 50,
            height: 50,
          },
          {
            label: "19",
            width: 50,
            height: 50,
          },
          {
            label: "20",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          {
            label: "16",
            width: 55,
            height: 50,
            dist: 20,
          },
          {
            label: "31",
            width: 40,
            height: 70,
          },
          {
            label: "S11",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "S8",
            width: 70,
            height: 50,
          },
          {
            label: "S6",
            width: 70,
            height: 50,
          },
          {
            label: "S2",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          {
            label: "15",
            width: 55,
            height: 50,
            dist: 20,
          },
          null,
          null,
          {
            label: "S10",
            width: 40,
            height: 70,
          },
          {
            label: "S5",
            width: 50,
            height: 70,
          },
        ],
        [
          null,
          {
            label: "14",
            width: 55,
            height: 50,
            dist: 20,
          },
          {
            label: "32",
            width: 40,
            height: 70,
          },
          {
            label: "S9",
            width: 40,
            height: 70,
          },
          {
            label: "S7",
            width: 80,
            height: 50,
          },
          {
            label: "S4",
            width: 80,
            height: 50,
            dist: 10,
          },
          {
            label: "S1",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          {
            label: "11B",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "11",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "12",
            width: 40,
            height: 70,
            dist: 20,
          },
          {
            label: "13",
            width: 40,
            height: 70,
            dist: 10,
          },
        ],
        [
          {
            label: "3",
            width: 40,
            height: 40,
            dist: 20,
          },
          {
            label: "4",
            width: 40,
            height: 40,
            dist: 20,
          },
          null,
          null,
          {
            label: "10",
            width: 70,
            height: 50,
            dist: 20,
          },
          {
            label: "8",
            width: 70,
            height: 50,
            dist: 10,
          },
          {
            label: "6",
            width: 50,
            height: 50,
            dist: 10,
          },
        ],
        [
          {
            label: "2",
            width: 40,
            height: 40,
            dist: 20,
          },
          {
            label: "1",
            width: 40,
            height: 40,
            dist: 20,
          },
          null,
          null,
          {
            label: "9",
            width: 70,
            height: 50,
            dist: 20,
          },
          {
            label: "7",
            width: 70,
            height: 50,
            dist: 10,
          },
          {
            label: "5",
            width: 50,
            height: 50,
            dist: 10,
          },
        ],
      ];

      let x = 0;
      let y = 20;
      // console.log("fdfdf");
      list.forEach((row) => {
        row.forEach((item) => {
          if (item) {
            svg
              .append("rect")
              .attr("width", item.width)
              .attr("height", item.height)
              .attr("fill", dd[item.label] ? "#61d3d1" : "#d3d3d3")
              .attr("stroke", "black")
              .attr(
                "transform",
                "translate(" +
                  (x +
                    (item.label == "16" || item.label == "15" ? 35 : 0) -
                    (item.x || 0)) +
                  "," +
                  (y -
                    (item.label == "16" || item.label == "15" ? 15 : 0) -
                    (item.y || 0)) +
                  ") rotate(" +
                  (item.label == "16" || item.label == "15" ? 45 : 0) +
                  ")"
              );

            svg
              .append("text")
              .attr(
                "x",
                x +
                  (item.width == 40 && item.label.length >= 2
                    ? item.label.length >= 3
                      ? item.width - 33
                      : item.width / 4
                    : item.width / 2.5)
              )
              .attr("y", y + item.height / 1.7)
              .text(item.label)
              .attr("fill", "none")
              .attr("stroke", "black");
          }

          x +=
            (item ? item.width : 50) + 10 + (item && item.dist ? item.dist : 0);
          // console.log(item, x);
        });

        x = 0;
        y += 80;
      });
    },
    uniq(list) {
      const e = {};

      list.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });

      return Object.keys(e);
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[10],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd21(list1) {
      // console.log(list1);
      const dd = {};
      list1.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          const ww = l1.table.split(" ");
          // console.log(l1.table);

          if (l.ticketCode == "tk_kdIUic8KCPmAYV9") {
            if (
              ww[ww.length - 1] == "6" ||
              ww[ww.length - 1] == "7" ||
              ww[ww.length - 1] == "8" ||
              ww[ww.length - 1] == "9"
            ) {
              dd[ww[ww.length - 1] + "A"] = true;
              dd[ww[ww.length - 1] + "B"] = true;
            } else {
              dd[ww[ww.length - 1]] = true;
            }
          } else if (l.ticketCode == "tk_Bw66u6airWxDtYt") {
            dd["17"] = true;
            dd["18"] = true;
            dd["19"] = true;
          } else if (l.ticketCode == "tk_KO35I2TSUFCh1NN") {
            dd["S1"] = true;
            dd["S2"] = true;
          } else if (l.ticketCode == "tk_nIMETmnv52qI71L") {
            dd["S3"] = true;
            dd["S4"] = true;
          } else if (l1.table == "Section " + ww[ww.length - 1]) {
            dd[ww[ww.length - 1]] = true;
          }
        });
      });

      // console.log(Object.keys(dd));
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "650px")
        .attr("height", "500px");
      const list = [
        [
          {
            label: "Bar",
            width: 80,
            height: 40,
          },
          {
            label: "1",
            width: 50,
            height: 50,
          },
          {
            label: "2",
            width: 50,
            height: 50,
          },
          {
            label: "Floor",
            width: 80,
            height: 40,
          },
          {
            label: "3",
            width: 50,
            height: 50,
          },
          {
            label: "4",
            width: 50,
            height: 50,
          },
          null,
          null,
          {
            label: "S3",
            width: 50,
            height: 50,
            x: 20,
          },
          {
            label: "DJ",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "Stage",
            width: 80,
            height: 30,
          },
          {
            label: "S4",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "S2",
            width: 50,
            height: 50,
          },
          null,
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7B",
            width: 50,
            height: 50,
          },
          {
            label: "8B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "11",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "Stage",
            width: 80,
            height: 50,
          },
          {
            label: "5",
            width: 20,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7A",
            width: 50,
            height: 50,
          },
          {
            label: "8A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "10",
            width: 50,
            height: 50,
          },
          {
            label: "12",
            width: 50,
            height: 50,
            y: 15,
          },
          {
            label: "13",
            width: 50,
            height: 50,
            y: -5,
          },
        ],

        [
          {
            label: "S1",
            width: 50,
            height: 50,
          },
          null,
          null,
          null,
          null,
          {
            label: "VIP",
            width: 80,
            height: 50,
          },
          null,
          null,
          null,
          {
            label: "Floor",
            width: 80,
            height: 50,
          },
        ],

        [
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "17",
            width: 50,
            height: 50,
            y: 10,
          },
          {
            label: "14",
            width: 50,
            height: 50,
          },
          {
            label: "15",
            width: 50,
            height: 50,
          },
          {
            label: "16",
            width: 80,
            height: 50,
          },
        ],

        [
          null,
          null,
          {
            label: "Owner",
            width: 90,
            height: 50,
          },
          {
            label: "19",
            width: 50,
            height: 50,
          },
          {
            label: "18",
            width: 50,
            height: 50,
          },
          {
            label: "17",
            width: 50,
            height: 50,
          },
        ],
      ];

      let x = 0;
      let y = 20;
      list.forEach((row) => {
        row.forEach((item) => {
          if (item) {
            svg
              .append("rect")
              .attr("width", item.width)
              .attr("height", item.height)
              .attr(
                "transform",
                "translate(" +
                  (x +
                    (item.label == "S2" || item.label == "S1" ? 35 : 0) -
                    (item.x || 0)) +
                  "," +
                  (y -
                    (item.label == "S2" || item.label == "S1" ? 15 : 0) -
                    (item.y || 0)) +
                  ") rotate(" +
                  (item.label == "S2" || item.label == "S1" ? 45 : 0) +
                  ")"
              )
              //  .attr("x", x - (item.x || 0))
              //  .attr("y", y - (item.y || 0))
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#61d3d1"
                    : "none"
                  : item.fill
              )
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#61d3d1"
                    : ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                        item.label
                      )
                    ? "none"
                    : "lightgrey"
                  : item.fill
              )
              .attr(
                "stroke",
                ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                  item.label
                )
                  ? "none"
                  : "black"
              );
            //      .style("transform", (item.label == 'S2' || item.label == 'S1') ? "translate(50deg, 50deg) rotate(45deg)" : "none");
            svg
              .append("text")
              .attr(
                "x",
                x +
                  (item.label == "S2" || item.label == "S1" ? 10 : 0) -
                  (item.x || 0) +
                  item.width / 3
              )
              .attr("y", y - (item.y || 0) + item.height / 1.7)
              .text(item.label)
              .attr("fill", "none")
              .attr("stroke", "black");
          }

          x += (item ? item.width : 50) + 10;
        });

        x = 0;
        y += 60;
      });
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      // console.log("wwwwwwww  === ");
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }
      this.redeemData = null;
      this.redeemPriceId = "";
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";

      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      const e = {};

      this.TicketsResult[idx].availableSeating.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      this.availSeating = Object.keys(e)[0];

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating && vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: [...this.TicketsResult[this.selected].availableSeating]
            .filter((r) => r.alias.includes(this.availSeating))
            .map((r) => r.alias),
        };
      }
      console.log("dsdsfsdgfg");

      if (
        this.TicketsResult[this.selected].ticketCode !== "tk_N6UiRjYPVYrTywO" &&
        this.TicketsResult[this.selected].ticketCode !== "tk_SYYW4UXeJO9T1oN"
      ) {
        payloadToPush.extraInfo = {
          [this.bottleoption]: [...this.selectionList].map((s) => s.alias),
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");

      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "JqfgYNJayDp91s",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            // tk_SFzmA04xZfJayyD: null,
            // tk_qs4a9qamuE8V1lc: null,
            tk_SYYW4UXeJO9T1oN: null,
            tk_N6UiRjYPVYrTywO: null,
            tk_iL8i8FFBj8m3wBP: null,
            tk_lYdYfyl2UaysdMT: null,
            tk_SCbHQ7VsQTW3QsK: null,
            tk_eZ4sJ4RXqidRonx: null,
            tk_hlXXPeibf9c4NXJ: null,
            tk_E8XwIJchpbPmQ9G: null,
            tk_UX5dE38p00h4BXC: null,
            tk_mdOnbujVv0EGwOd: null,
            tk_fYT9lGgia2yXyk0: null,
            tk_drcqB55g0RQXCRU: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          // console.log(vm.eventTicketResult);
          setTimeout(() => {
            vm.drawseatsInfo(vm.eventTicketResult);
          }, 1000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  //   metaInfo() {
  //     console.log("HERE");
  //     return {
  //       title: this.metaTitle,
  //       meta: this.metaTags,
  //     };
  //   },

  metaInfo: {
    title: "MYLE - Big Blue and HU Homecoming Post-Game Soiree",

    meta: [
      {
        name: "description",
        content: "MYLE - Big Blue and HU Homecoming Post-Game Soiree",
      },

      {
        name: "keywords",
        content: "MYLE - Big Blue and HU Homecoming Post-Game Soiree",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/big-blue-and-hu-homecoming-post-game-soiree",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
#d2 {
  overflow-x: scroll;
}
</style>
