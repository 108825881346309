var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('nav-bar')],1)]),_vm._m(0),_vm._m(1),_c('br'),_c('br'),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_vm._m(5),_c('br'),_c('br'),_vm._m(6),_c('br'),_vm._m(7)]),_c('site-footer',{attrs:{"show-subscribe":true}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12",attrs:{"id":"banner"}},[_c('img',{staticClass:"align-middle",staticStyle:{"width":"100%","max-width":"100%","height":"auto","margin-top":"4.6rem"},attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_StateOfBlackMensHealth.jpeg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between test",staticStyle:{"margin":"auto","margin-top":"20px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading text-center mb-1",staticStyle:{"font-family":"'Times New Roman'","font-weight":"300","font-size":"50px"}},[_vm._v(" MEN's HEALTH SUMMIT ")])]),_c('hr'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"18px"}},[_vm._v(" In honor of this year's Men's Health Month, Platinum Men’s Health is proud to announce its collaboration with Raise Your Glass Promotion to present the inaugural Fully Alive Men’s Health Summit. Thanks to our presenting sponsor, Florida Blue, this groundbreaking series will shine a spotlight on men’s health and well-being, offering valuable insights, resources, and inspiration to men everywhere. This community initiative is aimed at not only the men, but just as importantly, to the women who can support the men in their lives and help them live longer, healthier lives. We want to engage and educate all women (wives, mothers, sisters, aunties, daughters, nieces, granddaughters and friends). "),_c('br'),_c('br'),_vm._v(" The Fully Alive Men’s Health Summit will explore a wide range of topics, including physical health, mental wellness, nutrition, fitness, and more. Through engaging presentations, and panel testimonials, attendees will gain valuable knowledge and practical strategies for living their healthiest, most fulfilling lives. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/mens-health-brunch","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthMensHealthBrunch.png","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 29 "),_c('br'),_vm._v(" June ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{staticStyle:{"color":"black"},attrs:{"href":"https://myle.com/mens-health-brunch","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px"}},[_vm._v(" Men's Health Brunch ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px"}},[_vm._v(" Saturday, June 29, 2024"),_c('br'),_vm._v(" 11:00am - 02:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-SWA mb-1 text-center",attrs:{"href":"https://myle.com/mens-health-brunch","target":"_self"}},[_vm._v("Register ")])])])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/EventHeader-01-StateBlackMensHealth-titleOnly.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 01 "),_c('br'),_vm._v(" June ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{staticStyle:{"color":"black"},attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px"}},[_vm._v(" State of Black Men's Health with Dr. Adewale ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px"}},[_vm._v(" Saturday, June 01, 2024"),_c('br'),_vm._v(" 11:30am - 12:30pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-SWA mb-1 text-center",attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_vm._v("Watch the Replay ")])])])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthFiretalkTVLivestream.jpeg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 08 "),_c('br'),_vm._v(" June ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{staticStyle:{"color":"black"},attrs:{"href":"https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px"}},[_vm._v(" Firetalk TV Livestream ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px"}},[_vm._v(" Saturday, June 08, 2024"),_c('br'),_vm._v(" 12:00pm - 01:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-SWA mb-1 text-center",attrs:{"href":"https://youtu.be/RfIdqIjyi74?si=Cqnqi2IEqv-r69Sp","target":"_self"}},[_vm._v("Watch the Replay ")])])])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthYouAreWhatYouEatLivestream.jpeg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 15 "),_c('br'),_vm._v(" June ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{staticStyle:{"color":"black"},attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px"}},[_vm._v(" You are What you Eat Livestream ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px"}},[_vm._v(" Saturday, June 15, 2024"),_c('br'),_vm._v(" 01:00pm - 02:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-SWA mb-1 text-center",attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_vm._v("Watch the Replay ")])])])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenHealthFunctionalMobilityForMen.jpeg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 22 "),_c('br'),_vm._v(" June ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{staticStyle:{"color":"black"},attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px"}},[_vm._v(" Functional Mobility for Men ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px"}},[_vm._v(" Saturday, June 22, 2024"),_c('br'),_vm._v(" 12:00pm - 01:00pm ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-SWA mb-1 text-center",attrs:{"href":"https://www.youtube.com/@platinummenshealth3576","target":"_self"}},[_vm._v("Watch the Replay ")])])])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 m-0 promo-section MYLE-green",staticStyle:{"background-color":"#304cb2"}},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"section-heading mb-5"},[_c('h2',{staticClass:"text-center text-white"},[_vm._v(" DOWNLOAD MYLE TODAY TO GET STARTED ")]),_c('div',{staticClass:"download-btn mt-4 text-center animate__animated animate__fadeIn"},[_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-apple"}),_vm._v(" App Store")]),_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":"https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-android"}),_vm._v(" Google Play")]),_c('a',{staticClass:"btn google-play-btn",attrs:{"href":"https://web.myle.com","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-world"}),_vm._v(" MYLE Web")])])])])])])
}]

export { render, staticRenderFns }