<template>
  <div>
    <!-- model 1 -->
    <nu-modal ref="modal1" title="">
      <form v-on:submit="submit">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <h3
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 28px !important;
                "
              >
                Let Us Know Your Interest
              </h3>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="Fname"
                  v-model="modal1.Fname"
                  placeholder="First Name"
                  required="required"
                />
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="Lname"
                  v-model="modal1.Lname"
                  placeholder="Last Name"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  name="phone"
                  value=""
                  class="form-control"
                  v-model="modal1.phone"
                  placeholder="Phone"
                  required="required"
                />
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="modal1.email"
                  placeholder="Email"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  name="company"
                  value=""
                  class="form-control"
                  v-model="modal1.city"
                  placeholder="Company"
                  required="required"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p
                class="mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 19px !important;
                "
              >
                Choose (all that apply) ways you would like to get assistance
                from MYLE
              </p>

              <div class="form-group">
                <div class="row">
                  <div
                    class="col-md-12 mb-1"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                    "
                  >
                    <input
                      type="checkbox"
                      id="Help1"
                      style="margin-top: 7px"
                      value="Listing my business or event on MYLE"
                      v-model="modal1.checkVals"
                    />
                    <label
                      class="ml-2"
                      for="Help1"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 16px !important;
                      "
                      >Listing my business or event on MYLE
                    </label>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-md-12 mb-1"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                    "
                  >
                    <input
                      type="checkbox"
                      id="Help0"
                      style="margin-top: 7px"
                      value="Learning more about MYLE features/Booking a Demo of MYLE"
                      v-model="modal1.checkVals"
                    />
                    <label
                      class="ml-2"
                      for="Help0"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 16px !important;
                      "
                      >Learning more about MYLE features/Booking a Demo of
                      MYLE</label
                    >
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-md-12 mb-1"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                    "
                  >
                    <input
                      type="checkbox"
                      id="Help2"
                      style="margin-top: 7px"
                      value="Selling tickets through MYLE"
                      v-model="modal1.checkVals"
                    />
                    <label
                      class="ml-2"
                      for="Help2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 16px !important;
                      "
                      >Selling tickets through MYLE</label
                    >
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-md-12 mb-1"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                    "
                  >
                    <input
                      type="checkbox"
                      id="Help3"
                      style="margin-top: 7px"
                      value="Earning money through MYLE"
                      v-model="modal1.checkVals"
                    />
                    <label
                      class="ml-2"
                      for="Help3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 16px !important;
                      "
                      >Earning money through MYLE</label
                    >
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-md-12 mb-1"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                    "
                  >
                    <input
                      type="checkbox"
                      id="Help4"
                      style="margin-top: 7px"
                      value="Supporting underserved youth with Extra MYLE"
                      v-model="modal1.checkVals"
                    />
                    <label
                      class="ml-2"
                      for="Help4"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 16px !important;
                      "
                      >Supporting underserved youth with Extra MYLE</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-sm-12 mt-3 text-center">
              <button
                type="submit"
                class="btn solid-btn"
                id="btnContactUs"
                style="pointer-events: all; cursor: pointer"
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- model 1 -->

    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row">
        <div class="col-md-12" id="banner">
          <div class="container">
            <div class="row">
              <div
                class="col-md-9 col-lg-9 align-items-center"
                style="
                  position: absolute;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 14vw;
                  left: 0;
                  right: 0;
                  top: 0px;
                  text-align: center;
                "
              >
                <div
                  class="hero-content-left text-white text-center contain-top"
                >
                  <h1
                    class="text-white"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 400;
                      font-size: 6vw !important;
                    "
                  >
                    <span class="">MYLE + Ticketing</span>
                  </h1>
                  <p
                    class=""
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 2vw !important;
                    "
                  >
                    MYLE offers a robust suite of entertainment experience
                    discovery features to promote your event, sell tickets, and
                    connect with your guests.
                  </p>

                  <a
                    href="https://myle.com/office-hours"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn google-play-btn"
                    ><span class=""></span> Book a Demo</a
                  >
                </div>
              </div>
            </div>
          </div>

          <img
            class="align-middle height-val"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_hero_image.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              filter: brightness(50%);
              min-height: 300px !important;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                The MYLE Difference - A Winning Combination for All
              </h1>
              <h4
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                MYLE's social activity and entertainment marketplace is designed
                for small businesses, event creators, and entrepreneurs.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test mt-5"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="container">
            <div class="download-content">
              <div class="row mb-5">
                <div class="col-md-9 col-lg-9 col-sm-9 mb-5">
                  <div class="">
                    <h1
                      class="pb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 45px;
                      "
                    >
                      Get Started for Free
                    </h1>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      You can get started in the MYLE app today, for FREE!
                      Sign-up or download MYLE and set up your events or
                      business listing at no cost. Each event or business
                      listing can be placed into three categories of your choice
                      (out of 20!).
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        href="https://web.myle.com/post-event"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn"
                        style="font-size: large; color: white"
                        ><span class=""></span>Create an Event</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-3">
                  <div style="height: center; text-align: center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Event_promotion_icon.png"
                      alt="Saturday Event"
                      style="max-width: 100%; max-height: 100%"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-5 mt-5">
                <div class="col-md-3 col-lg-3 col-sm-6">
                  <div style="height: center; text-align: center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Ticketing_fees_icon.svg"
                      alt="Saturday Event"
                      style="max-width: 100%; max-height: 100%"
                    />
                  </div>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-6 mb-5">
                  <div class="">
                    <h1
                      class="pb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 45px;
                      "
                    >
                      Budget-Friendly Ticketing Fees
                    </h1>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      MYLE’s ticketing fees are set at the competitive rate of
                      3.7% + $1.30 (per ticket) plus Stripe Processing Fee of
                      2.9% + .30 (per transaction) for handling. Event hosts
                      have the option to pass along fees, cover the MYLE
                      ticketing fees or the entire fee, by ticket type. There is
                      no charge for free events.
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        href="https://web.myle.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn"
                        style="font-size: large; color: white"
                        ><span class=""></span> Sell Tickets</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-5 mt-5">
                <div class="col-md-9 col-lg-9 col-sm-6 mb-5">
                  <div class="">
                    <h1
                      class="pb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 45px;
                      "
                    >
                      Make More Money
                    </h1>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      As a MYLE Profit Partner, you can earn a “Revenue Share”
                      bonus payout when MYLE exceeds key revenue thresholds from
                      service fees generated by your event. Or become a MYLE
                      Affiliate and earn additional revenue by inviting
                      customers to download the MYLE app using your unique
                      affiliate code. Earn points when they sign up, make in-app
                      purchases, and more—points turn into cash once the minimum
                      threshold is met.
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        class="btn solid-btn"
                        href="https://myle.com/profit-partner"
                        target="_blank"
                        rel="noopener noreferrer"
                        style="font-size: large"
                        ><span class=""></span> MYLE Profit Partner</a
                      >

                      <a
                        class="btn solid-btn ml-3"
                        href="https://myle.com/affiliate"
                        target="_blank"
                        rel="noopener noreferrer"
                        style="font-size: large"
                        ><span class=""></span> MYLE Affiliate</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-6">
                  <div style="height: center; text-align: center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Ticketing_Money_icon.svg"
                      alt="Saturday Event"
                      style="max-width: 100%; max-height: 100%"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-5 mt-5">
                <div class="col-md-3 col-lg-3 col-sm-6">
                  <div style="height: center; text-align: center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/How_to_icon.svg"
                      alt="Saturday Event"
                      style="max-width: 65%; max-height: 65%"
                    />
                  </div>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-6 mb-5">
                  <div class="">
                    <h1
                      class="pb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 45px;
                      "
                    >
                      Set-Up Guides to Help You Get Started
                    </h1>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      We’ve created some simple how-to guides to help you get
                      started on your MYLE journey of event promotion and
                      selling tickets. Available guides: <br />
                      How to create an account <br />
                      How to post an event, sell tickets, and get exposure with
                      MYLE <br />
                      How to update an event <br />
                      How to refer MYLE to others (and start earning $) <br />
                      <br />
                      Have questions? Check-out our comprehensive
                      <a
                        href="https://myle.com/faqs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQ’s</a
                      >
                      or email us at
                      <a href="mailto:hello@myle.com"> hello@myle.com</a>
                    </h3>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      Web application guides: <br />
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        href="https://drive.google.com/file/d/1pJSTY90-itpAsPpUe1ZkQQKyfT5J2OQR/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Create an Account</a
                      >

                      <a
                        href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/How-to-post-an-event-on-MYLE.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Post an Event</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1HnK7MrpnLbtHuegLZ5mXh02wM8VCtq36/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Post an Event with Ticket</a
                      >

                      <a
                        href="https://drive.google.com/file/d/16Gcdc3l0lUk3kowifjkKbUhOZZsVhBQD/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Explore Events</a
                      >

                      <a
                        href="https://drive.google.com/file/d/10n6z6DodRkifNnLDTdGNbg7MVCTx8Tr6/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Share an Event</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1SUOHJH6VgqM735gT6lGXMPBhPdvdet2f/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mb-2"
                        style="font-size: large"
                        ><span class=""></span> Promote/Amplify an Event</a
                      >
                    </div>
                    <br />
                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      Mobile application guides: <br />
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        href="https://drive.google.com/file/d/1L85c7EkWrchICbW5jygl3v5w4wcjLfiz/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Create an Account</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1qNHIrnOl1K1JR2z2rejWihGSg2YALxjx/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Post an Event</a
                      >

                      <a
                        href="https://drive.google.com/file/d/13kdli8REUuRLMIBh70i5aQXu3gyGeKwE/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Update an Event</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1Fb6Et2-8ssQs30TwRbF4HNXir-9dpmRi/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> Promote Your Event</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1KgtL5TVtvWIdfp1KrHm_x9J17Ou7pb_9/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mr-3 mb-2"
                        style="font-size: large"
                        ><span class=""></span> How to Refer and Earn</a
                      >

                      <a
                        href="https://drive.google.com/file/d/1xqccYosU5BHRAekkwzePp_36BK-ymnLy/view"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn mb-2"
                        style="font-size: large"
                        ><span class=""></span> How to Scan Attendees</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-5 mt-5">
                <div class="col-md-9 col-lg-9 col-sm-6 mb-5">
                  <div class="">
                    <h1
                      class="pb-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 45px;
                      "
                    >
                      Support Underserved Youth
                    </h1>

                    <h3
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 24px;
                      "
                    >
                      A portion of MYLE's ticket fees are donated to MYLE's
                      non-profit, Extra MYLE International. EMI enables unserved
                      youth and families to have meaningful entertainment-based
                      experiences. Every time you sell a ticket through MYLE,
                      you're going the Extra MYLE to make a difference in
                      someone's life.
                    </h3>

                    <div class="download-btn text-center">
                      <a
                        href="https://myle.com/extramyle"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn solid-btn"
                        style="font-size: large; color: white"
                        ><span class=""></span> Learn More</a
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-6">
                  <div style="height: center; text-align: center">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/extraMyle.png"
                      alt="Saturday Event"
                      style="max-width: 65%; max-height: 65%"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="container">
                  <div class="py-5">
                    <div class="text-center mb-3">
                      <h4
                        class="w-auto d-inline-block text-center"
                        style="
                          font-family: 'Quicksand', sans-serif;
                          font-weight: 700;
                          font-size: 45px;
                        "
                      >
                        Ready to Start Promoting Your Event?
                      </h4>
                    </div>
                    <div class="download-btn text-center">
                      <!-- <a
                        href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-apple"></span> App Store</a
                      >
                      <a
                        href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-android"></span> Google Play</a
                      >
                      <a
                        href="https://web.myle.com"
                        class="btn solid-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class="ti-world"></span> MYLE Web</a
                      > -->

                      <a
                        href="https://web.myle.com/post-event"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class=""></span> Create An Event</a
                      >

                      <a
                        href="https://web.myle.com/login"
                        class="btn solid-btn mr-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class=""></span> Register/Login</a
                      >

                      <a
                        href="https://myle.com/app/MYLEMARKERS"
                        class="btn solid-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><span class=""></span> Get The App
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="container">
                  <div class="py-5">
                    <div class="row justify-content-center">
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_black-owned-business.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Black-Owned Business
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_smart-insights.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Smart Insights
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_comprehensive-toolkit.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Comprehensive Toolkit
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_flexible-ticketing.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Flexible Ticketing
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_easy-guest-check-in.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Easy Guest Check-In
                          </h4>
                        </div>
                      </div>

                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_admin-tools.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Admin Tools
                          </h4>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6">
                        <div style="height: center; text-align: center">
                          <img
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ticketing_icon_personalized-training.svg"
                            alt="Saturday Event"
                            style="max-width: 65%; max-height: 65%"
                          />
                          <br />
                          <h4
                            class="w-auto d-inline-block text-center"
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 700;
                              font-size: 22px;
                            "
                          >
                            Personalized Training
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div class="download-btn mt-4 text-center">
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import VueElementLoading from "vue-element-loading";
import axios from "axios";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      modal1: {
        showOtherText: false,
        Fname: "",
        Lname: "",
        phone: "",
        email: "",
        company: "",
        checkVals: [],
      },
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
  },

  methods: {
    invalidEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    },
    submit: function (e) {
      e.preventDefault();
      this.isSubmitted = true;
      const assistanceFormData = {
        firstName: this.modal1.Fname,
        lastName: this.modal1.Lname,
        phone: this.modal1.phone,
        email: this.modal1.email.toLowerCase(),
        company: this.modal1.company,
        assistanceReasons: this.modal1.checkVals,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/assistance_request",
          assistanceFormData
        )
        .then((response) => {
          this.closeModal1();
          this.$toast.success(response.data.Message, { timeout: 4000 });
          this.modal1.Fname = "";
          this.modal1.Lname = "";
          this.modal1.phone = "";
          this.modal1.email = "";
          this.modal1.company = "";
          this.modal1.checkVals = [];
          this.isSubmitted = false;
        })
        .catch((error) => {
          this.isSubmitted = false;
          console.error("There was an error!", error);
          this.$toast.error("There was an error!", { timeout: 4000 });
        });
    },
    openModal1() {
      this.$refs.modal1.open();
    },
    closeModal1() {
      this.$refs.modal1.close();
    },
  },

  mounted() {
    setTimeout(() => {
      this.openModal1();
    }, 5000);
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE + Ticketing",

    meta: [
      {
        name: "description",
        content: "MYLE - MYLE + Ticketing",
      },

      {
        name: "keywords",
        content: "MYLE - MYLE + Ticketing",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/myle-ticketing",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#modelButton:hover {
  color: #104233 !important;
}

@media (max-width: 510px) {
  .contain-top {
    margin-top: 60px;
  }
}

@media (max-width: 1300px) {
  .height-val {
    margin-top: 4.6vw;
  }
}
</style>
