<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_ben.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                BEN - Survey
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-6">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Full Name</label
          >

          <input
            type="text"
            v-model="fullName"
            class="form-control"
            placeholder=""
          />
        </div>

        <div class="col-md-6">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Email</label
          >

          <input
            type="text"
            v-model="email"
            class="form-control"
            placeholder=""
          />
        </div>

        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Which Event You Attended?</label
          >
          <br />
          <multiselect
            v-model="selectopt"
            :options="listoptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select"
            label="label"
            track-by="id"
            :preselect-first="true"
          >
          </multiselect>
        </div>

        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Did you attend virtually or in-person?</label
          >
          <br />
          <input
            type="radio"
            id="attend_op1"
            name="apply31"
            value="In-Person"
            v-model="attendType"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="attend_op1"
            >In-Person</label
          ><br />
          <input
            type="radio"
            id="attend_op2"
            name="apply31"
            value="Virtual"
            v-model="attendType"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="attend_op2"
            >Virtual</label
          >
          <br />
        </div>

        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Did you create an account on the MYLE App?</label
          >
          <br />
          <input
            type="radio"
            id="attend_op1"
            name="apply311"
            value="Yes"
            v-model="have_created"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="attend1_op1"
            >Yes</label
          ><br />
          <input
            type="radio"
            id="attend_op2"
            name="apply311"
            value="No"
            v-model="have_created"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="attend_op2"
            >No</label
          >
        </div>

        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Did you learn something?</label
          >
          <br />
          <input
            type="radio"
            id="op2"
            name="apply20"
            value="Strongly Agree"
            v-model="learnSomething"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply20"
            value="Agree"
            v-model="learnSomething"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply20"
            value="Neither Agree nor Disagree"
            v-model="learnSomething"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply20"
            value="Strongly Disagree"
            v-model="learnSomething"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply20"
            value="Disagree"
            v-model="learnSomething"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>
        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >What did you learn?</label
          >

          <input type="text" v-model="whatlearn" class="form-control" />
        </div>

        <div class="col-md-12 mt-4">
          <label
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Do you think what you learned is valuable information?</label
          >
          <br />
          <input
            type="radio"
            id="op2"
            name="apply330"
            value="Strongly Agree"
            v-model="valueInformation"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply330"
            value="Agree"
            v-model="valueInformation"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply330"
            value="Neither Agree nor Disagree"
            v-model="valueInformation"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply330"
            value="Strongly Disagree"
            v-model="valueInformation"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply330"
            value="Disagree"
            v-model="valueInformation"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>

        <div class="col-md-12 mt-4">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Why was it valuable?</label
          >

          <input
            type="text"
            v-model="whyvalue"
            class="form-control"
            placeholder=""
          />
        </div>

        <div class="col-md-12 mt-4">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Can you apply what you learned to your personal or professional
            career?</label
          ><br />
          <input
            type="radio"
            id="op3"
            name="apply0"
            value="Strongly Agree"
            v-model="professional_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply0"
            value="Agree"
            v-model="professional_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply0"
            value="Neither Agree nor Disagree"
            v-model="professional_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply0"
            value="Strongly Disagree"
            v-model="professional_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply0"
            value="Disagree"
            v-model="professional_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>

        <div class="col-md-12 mt-4">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            >Would you attend more events like this?</label
          ><br />
          <input
            type="radio"
            id="op3"
            name="apply1"
            value="Strongly Agree"
            v-model="attend_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply1"
            value="Agree"
            v-model="attend_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply1"
            value="Neither Agree nor Disagree"
            v-model="attend_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply1"
            value="Strongly Disagree"
            v-model="attend_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply1"
            value="Disagree"
            v-model="attend_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>

        <div class="col-md-12 mt-4">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            class=""
            >Would you encourage others to attend more BEN events?</label
          ><br />
          <input
            type="radio"
            id="op3"
            name="apply2"
            value="Strongly Agree"
            v-model="encourage_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply2"
            value="Agree"
            v-model="encourage_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply2"
            value="Neither Agree nor Disagree"
            v-model="encourage_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply2"
            value="Strongly Disagree"
            v-model="encourage_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply2"
            value="Disagree"
            v-model="encourage_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>

        <div class="col-md-12 mt-4">
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
            class=""
            >Has this BEN event positively impacted your Amazon employee
            experience? </label
          ><br />
          <input
            type="radio"
            id="op3"
            name="apply3"
            value="Strongly Agree"
            v-model="amazon_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op3"
            >Strongly Agree</label
          ><br />
          <input
            type="radio"
            id="op2"
            name="apply3"
            value="Agree"
            v-model="amazon_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op2"
            >Agree</label
          ><br />
          <input
            type="radio"
            id="op1"
            name="apply3"
            value="Neither Agree nor Disagree"
            v-model="amazon_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="op1"
            >Neither Agree nor Disagree</label
          ><br />
          <input
            type="radio"
            id="html"
            name="apply3"
            value="Strongly Disagree"
            v-model="amazon_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="html"
            >Strongly Disagree</label
          ><br />
          <input
            type="radio"
            id="css"
            name="apply3"
            value="Disagree"
            v-model="amazon_employee"
          />&nbsp;&nbsp;
          <label
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
            for="css"
            >Disagree</label
          ><br />
        </div>
      </div>

      <div class="col-md-12 text-center mt-4">
        <button
          type="submit"
          @click="paynow()"
          :disabled="
            selectopt.length < 1 ||
            !whyvalue.trim().length ||
            !valueInformation.trim().length ||
            !whatlearn.trim().length ||
            !validEmail ||
            !fullName.trim().length ||
            !whyvalue.trim().length
          "
          class="btn solid-btn m-auto mr-2"
          id="reserve"
          style="pointer-events: all; cursor: pointer"
        >
          Submit Your's Answers
        </button>
      </div>
      <br />

      <br />
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class=""
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 18px;
            "
          >
            Please also join us in shaping the future by completing the Core and
            Common Metrics survey, which is crucial for Amazon's strategic
            insights and development. Your input is invaluable in driving
            meaningful change and innovation across our organization. Let's make
            our voices heard together for a brighter tomorrow.
          </p>

          <div class="text-center">
            <a
              href="https://pulse.aws/survey/EOKDZYFT?p=0"
              class="btn solid-btn text-center"
              target="_blank"
              rel="noopener noreferrer"
              >Core and Common Metrics Survey
            </a>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Multiselect from "vue-multiselect";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      have_created: "Yes",
      listoptions: [
        {
          label: "BNA2 Site Tour & Leadership Panel",
          id: "9P7aPezk5ssAJ8",
        },
        {
          label: "Welcome To Nashville Mixer",
          id: "k8TZacS2ntsxoB",
        },
        {
          label: "National Museum of African American Music Tour",
          id: "yfBRtWlehiJ9Av",
        },
        {
          label: "Music City Showcase",
          id: "5d9ahRZKFdvPmf",
        },
        {
          label: "Black Wellness Rally",
          id: "9U2vyfaQ7PiuWR",
        },
      ],
      selectopt: "",
      fullName: "",
      email: "",
      attendType: "In-Person",
      learnSomething: "Strongly Agree",
      whyvalue: "",
      valueInformation: "Strongly Agree",
      whatlearn: "",
      professional_employee: "Strongly Agree",
      attend_employee: "Strongly Agree",
      encourage_employee: "Strongly Agree",
      amazon_employee: "Strongly Agree",
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    Multiselect,
  },
  methods: {
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      console.log("fdfdfd");
      const vm = this;
      vm.isSubmitted = true;
      let rr = [];
      this.selectopt.forEach((ee) => {
        rr.push(ee.id);
      });
      this.selectopt = [];

      const payloadToPush = {
        name: this.fullName,
        email: this.email,
        eventId: rr.join(","),
        isLearnt: vm.learnSomething,
        isValueableLearnt: vm.valueInformation,
        whatIsLearnt: vm.whatlearn,
        have_account_created: vm.have_created,
        whyIsValueableLearnt: vm.whyvalue,
        applyLearnt: vm.professional_employee,
        attendMoreLikeCurrentEvent: vm.attend_employee,
        encourageToAttendMoreLikeCurrentEvent: vm.encourage_employee,
        impactOnExperience: vm.amazon_employee,
        attendType: vm.attendType,
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/ben-survey",
          payloadToPush
        )
        .then((res) => {
          if (res && res.data && res.data.Message) {
            vm.$toast.success(res.data.Message, { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.$toast.error("Something went wrong", { timeout: 4000 });
        })
        .finally(() => {
          vm.fullName = "";
          vm.whyvalue = "";
          vm.email = "";
          vm.attendType = "In-Person";
          vm.learnSomething = "Strongly Agree";
          vm.valueInformation = "Strongly Agree";
          vm.whatlearn = "";
          vm.professional_employee = "Strongly Agree";
          vm.attend_employee = "Strongly Agree";
          vm.encourage_employee = "Strongly Agree";
          vm.amazon_employee = "Strongly Agree";
          vm.isSubmitted = false;
        });
    },
  },
  computed: {
    validEmail() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(this.email);
    },
  },

  metaInfo: {
    title: "MYLE - BEN Survey",

    meta: [
      {
        name: "description",
        content: "MYLE - BEN Survey",
      },

      {
        name: "keywords",
        content: "MYLE - BEN Survey",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/ben-survey",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
