<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlanta_blog_header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                15 Unique Atlanta Things to Do, Places to Go, and Food to Eat
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 500"
            >
              We're here! Atlanta has it all, from independently owned shops to
              public art; self-guided tours on the Civil Rights Trail to a
              deliciously curated global food tour; a street-level search for
              tiny doors to unparalleled roop-top views. ALT has it all. You
              could spend days sampling soul food and trying all the fried
              chicken. Tell us if The Busy Bee Cafe still owns the right to
              “best friend chicken in #ATL.” And go ahead, eat that fried
              chicken with your hands because eating it with a fork in this part
              of Georgia is a fineable offense.
            </p>

            <p
              class="lead-heading text-center"
              style="font-family: 'Quicksand', sans-serif; font-weight: 700"
            >
              MYLE is a social activity and event-based smart entertainment
              guide designed to Make Your Life Entertaining. Quickly find events
              nearby and when you travel to hear live music, take the kids out,
              dine and drink with friends, catch a show, and more.
              <a href="#download"> Get the MYLE app</a>
              today and Just MYLE It!
              <br />
            </p>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- MANUAL PURCHASE -->
      <section class="">
        <div class="container">
          <div
            class="row justify-content-center align-items-center text-center mb-5"
          >
            <div
              class="col-md-12 col-lg-12 col-sm-12 col-12 align-items-center mb-5"
            >
              <div class="mt-5">
                <h3
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Fun is waiting for you.
                </h3>

                <h4
                  style="font-family: 'Quicksand', sans-serif; font-weight: 100"
                >
                  Download or Sign-up Today!
                </h4>

                <div
                  class="download-btn mt-4 text-center animate__animated animate__fadeIn"
                >
                  <a
                    href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-apple"></span> App Store</a
                  >
                  <a
                    href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                    class="btn solid-btn mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-android"></span> Google Play</a
                  >
                  <a
                    href="https://web.myle.com"
                    class="btn solid-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span class="ti-world"></span> MYLE Web</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="section-heading text-center">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Things to Do
            </h2>
          </div>
          <hr />

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 1.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  1.
                  <a
                    href="http://www.antiquerow.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chamblee Antique Row</a
                  >
                  is a collection of antique and thrift stores. Some shops are
                  cluttered like your grandmother's attic, while others are
                  minimalist. Prices range from affordable to what you'd expect
                  antiques to run. Most shops are only a few feet apart, so park
                  around the sidewalk searching for that perfect home item or
                  traveling back in time.
                </p>

                <p
                  class="text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  "Even if you're not looking for it - I'm talking to you -
                  vintage schoolhouse desk<br />
                  to a bellow coffee table - it's here, and you'll want it
                  more<br />
                  than what you originally came here for.”
                </p>

                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Chamblee's%20Antique%20Row/Cfv9M5ZB1PztFh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chamblee's Antique Row</a
                  >
                </p>

                <p
                  class="mt-5"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  2.
                  <a
                    href="https://tinydoorsatl.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiny Doors ATL</a
                  >
                  “ is an Atlanta art project bringing big wonder to tiny
                  spaces." The doors were designed to inspire awe in people of
                  all ages. You naturally want to know if the doors open.
                  Currently, only Grant Park's Door #10E opens. Free entry; take
                  a mini-door-to-door tour by biking or walking between
                  locations. Tiny Doors ATL's name is on every door they build
                  and maintain.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 2.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 22px;
                  "
                >
                  <a
                    href="https://www.youtube.com/watch?v=gAq84Ruzhls&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Unlocking the secrets behind Tiny Doors ATL</a
                  >
                </p>

                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Tiny%20Doors%20ATL/HpSxBKMG7RAiOa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tiny Doors ATL</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  3.
                  <a
                    href="https://yellowriverwildlifesanctuary.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Yellow River Wildlife Sanctuary</a
                  >
                  - “Benefiting Animals - Educating Humans” Yellow River
                  Wildlife Sanctuary's talented curators, keepers, staff, and
                  volunteers promote wildlife appreciation, providing
                  enrichment, food, and care for the animals. A two-acre fenced
                  space with hills, trees and a watering hole simulates black
                  bears’ natural north Georgia mountain habitat. <br /><br />
                  The petting zoo has alpacas, lambs, pigs, a steer, and a
                  donkey named Donkey, complete with a Western saloon and
                  general store for goat treats. If you visit in early summer,
                  you’ll see deer mothers and babies nestle together in safe
                  areas away from the trail. Keep your phone handy for fantastic
                  photo opportunities.
                  <br /><br />
                  Learn about the transformation from game ranch to zoo:
                  <a
                    href="https://www.ajc.com/blog/radiotvtalk/family-gambles-life-savings-rehabbing-lilburn-newly-reopened-yellow-river-wildlife-sanctuary/NLrh05FKbOm1Rirmj4ztsI/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Family gambles life savings rehabbing Lilburn's newly
                    reopened Yellow River Wildlife Sanctuary</a
                  >.
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Yellow%20River%20Wildlife%20Sanctuary/elEdEJrpFEJbJY"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Yellow River Wildlife Sanctuary</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mb-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 3.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 4.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div
            class="row mb-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 5.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  2.
                  <a
                    href="https://trapmusicmuseum.us/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Trap Music Museum</a
                  >
                  <a
                    href="https://www.instagram.com/explore/tags/blackowned/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #blackowned</a
                  >
                  - T.I. and the Grand Hustle crew have made their own escape
                  room. The original plan was to decorate an abandoned bungalow
                  in town with street couches. Then they decided that simply
                  making customers feel trapped wasn't enough. They wanted to
                  explain the trap and the music it spawned. The Grand Hustle
                  team members tried to pin down the first hip-hop reference
                  they could find for "trap," research that layed the foundation
                  for Trap Music Museum. As a result, "Escape the Trap" is now
                  part of the Trap Music Museum.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 6.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 7.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Trap%20Music%20Museum/jbsMEmvGGu3i4F"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Trap Music Museum</a
                  >
                </p>

                <p
                  class="mb-5"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  BONUS
                  <a
                    href="https://discoveratlanta.com/atl-unguided/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Atlanta Black Travel Guide & Resources | ATL Unguided</a
                  >
                  Atlanta is home to many historic churches and is proud to have
                  the most HBCUs, popular black-owned restaurants, trendy
                  lounges, and legendary art galleries. Atlanta has a deep,
                  progressive, and ever-changing heritage where excellence and
                  pride are encouraged. But Black people still have to look for
                  places where they can feel comfortable just being themselves.
                  Here is a resource for finding those places.
                  <a
                    href="https://discoveratlanta.com/atl-unguided/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discover ATL Unguided.</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 8.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  3.
                  <a
                    href="https://discoveratlanta.com/things-to-do/arts-culture/fox-theatre/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fabulous Fox Theatre in Atlanta</a
                  >
                  - Take in a show at one of the city's most iconic landmarks,
                  committed to making performance art accessible to everyone.
                  See more than 10 locations inside the building on a 60-minute
                  tour led by Fox employees who provide intimate firsthand
                  details about their theatre’s remarkable story, giving guests
                  an unprecedented look at Atlanta’s favorite theatre.
                  <a
                    href="https://www.foxtheatre.org/events"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Events | Fox Theatre</a
                  >
                  - event calendar
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Fox%20Theater/HtIEj0GiVYhSfs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fox Theater</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  BONUS
                  <a
                    href="https://www.lipsatl.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lips</a
                  >
                  - An upscale theme restaurant that offers "The Ultimate in
                  Drag Dining with Drag Queen wait staff and on-stage
                  performances during your dining experience." Locals celebrate
                  birthdays, bridal showers, and their fabulous selves. Pay a
                  small fee to join the show on-stage and leave with photos with
                  the ladies!
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://www.facebook.com/watch/?v=909191229708189&ref=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://fb.watch/c2cCwufPlC/</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="section-heading text-center mt-5">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Places to Go
            </h2>
          </div>
          <hr />

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  4.
                  <a
                    href="https://www.youtube.com/watch?v=GbJWxdWsDCw&feature=youtu.be
"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Little Five Points | Atlanta Neighborhoods
                  </a>
                  - At Little Five, a five-point intersection met at the corner
                  of Euclid and Moreland. Those streets split in four directions
                  (like a cross). They included a fictitious fifth street called
                  Seminole Avenue, which was recently converted into an extended
                  sidewalk area next to the pizza shop.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Little%20Five%20Points/qbHMtQphK6gGVu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Little Five Points
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 9.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://criminalrecords-102796.square.site/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Criminal Records
                  </a>
                  is a family-owned and operated independent record store in
                  Little Five Points, Atlanta, Georgia (you can tweet them to
                  request a new release). Browse vinyl records, C.D.s, comics,
                  graphic novels, and toys. Criminal Records buys used records,
                  C.D.s, and tapes. In-store performances and weekly comic book
                  deliveries make Criminal Records a must-see for music and
                  comic book fans.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Criminal%20Records%20Special%20Stuff%20Shop/s7SvXTbJWsE90q"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Criminal Records Special Stuff Shop
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 10.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://www.thejunkmansdaughter.com/index.cfm?"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Junkman's Daughter</a
                  >
                  has been on Moreland Avenue in Little Five Points for 30 years
                  with a back tobacco shop and steampunk and Betsey
                  Johnson-inspired clothing. The eclectic store has everything
                  from incense and props to art by local artists. Local tattoo
                  artist Watson Atkinso, who once had his parlor in what is now
                  the store's storage closet, painted the store's sign - a
                  massive UFO-inspired mural.
                  <a
                    href="https://www.facebook.com/Junkmans-Daughters-Brother-210427785666413/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Junkman's Daughter's Brother</a
                  >
                  in Athens, GA, was recently voted one of America’s Best
                  Independent Stores.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/The%20junkman%E2%80%99s%20Daughter/J9mkankZIKa41v"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Junkman
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-9 col-lg-9 col-sm-9 col-9">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 11.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  5.
                  <a
                    href="https://www.poncecitymarket.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ponce City Market</a
                  >
                  Spend your day at the nation's 4th largest city market, built
                  in the 1920s, the Ponce de Leon Amusement Park site. Play
                  mini-golf, boardwalk games, amusement rides, and seasonal ice
                  skating on The Roof. Stay in one of the high-end rentals, then
                  indulge in Atlanta’s best brunch 9 Mile Station.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Since you’re already there, try
                  <a
                    href="https://secretatlanta.co/vegan-burger-cocktail-bar/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Slutty Vegan Opens 'Bar Theater' Cocktail Spot In Ponce City
                    Market - Secret Atlanta</a
                  >
                  cocktails served in fire extinguishers, gas cans, Ferris
                  wheels, or bank safes, and come with musical sound bites. Bar
                  Vegan also serves a “Shaken not Slurred” mocktail menu. Guests
                  are encouraged to take photographs.
                  <a
                    href="https://www.instagram.com/explore/tags/blackowned/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #blackowned</a
                  >
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Ponce%20City%20Market/RmzDfkGxmbAQ7m"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ponce City Market</a
            >
          </p>

          <div
            class="row mt-5 justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 12.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  6.
                  <a
                    href="https://www.apexmuseum.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    APEX Museum
                  </a>
                  - the oldest Black history museum in Atlanta; features black
                  inventors; interactive; Henry Box Brown story; the door of no
                  return; Sweet Auburn neighborhood near various
                  African-American museums, businesses and historic sites;
                  listed as a site on the U. S. Civil Rights Trail.
                  <a
                    href="https://civilrightstrail.com/attraction/apex-museum/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    APEX Museum – US Civil Rights Trail
                  </a>
                </p>
              </div>
            </div>
          </div>

          <p
            class="justify-content-center align-items-center text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            <a
              href="https://web.myle.com/e/Apex%20Museum/3mngH3gxJawrnM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apex Museum</a
            >
          </p>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  7.
                  <a
                    href="https://www.atlasobscura.com/places/dolls-head-trail-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Doll's Head Trail
                  </a>
                  is an urban forest in Constitution Lakes Park overseen by
                  DeKalb County Parks & Recreation. DeKalb County purchased the
                  property in 2003, then paved trails and installed boardwalks
                  around the ponds. Local carpenter Joe Slaton built the trail
                  from discarded doll parts and other site trash. Since then,
                  visitors have helped keep the trail clean by adding their own
                  found art. The works include beheaded dolls, truck part
                  tableaus, and Georgia clay bricks from the former South River
                  Brick Company.
                </p>
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  All items must be "found" within the Park. Leaving items on
                  the trail is not advised because "Litter makes the angels
                  cry." the sign says.
                </p>
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  See the
                  <a
                    href="https://www.facebook.com/media/set/?set=a.849553965193145&type=3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    found objects gallery
                  </a>
                  on their Facebook page.
                  <a
                    href="https://web.myle.com/e/Dolls%20Head%20Trail%20/MLiukreSJmVc1z"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Doll’s Head Trail
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 13.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 14.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Dolls%20Head%20Trail%20/MLiukreSJmVc1z"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Doll’s Head Trail
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  8.
                  <a
                    href="https://www.walkindeadharalson.com/filmed-in-haralson/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Filmed in Haralson - Walking Dead Haralson Tours & Events!
                  </a>
                  - one hour south of Atlanta, TWD fans get a complete film set
                  experience on their most popular tour of a private filming
                  backlot used in 14 movies and 17 scenes of The Walking Dead in
                  Seasons 2 through 5. Select a replica weapon from Walking Dead
                  Armory to assist you in your travels on an interactive guided
                  tour. Choose from multiple tour types and add activities such
                  as dressing like your favorite character, transforming into a
                  walker, or being ambushed by walkers when you least expect it.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 22px;
                  "
                >
                  <a
                    href="https://www.youtube.com/watch?v=W1e33qU5clM&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Walking Dead Tour: Senoia, Georgia
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/The%20Walking%20Dead%20Tours/Y621HBeeS8Mup9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Walking Dead Tour
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  BONUS
                  <a
                    href="https://discoveratlanta.com/things-to-do/krog-street-tunnel/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Krog Street Tunnel
                  </a>
                  Graffiti Street Art Site Between Inman Park and Cabbagetown
                  neighborhoods
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Free Art! Local artists fill the tunnel with vibrant artwork
                  that inspires, educates, and entertains. Create a picture of
                  your own to showcase your art to the city. This colorful
                  tunnel is perfect for your Insta, Tik Tok, or artsy engagement
                  photos. Expect a photo or music video shoot during your visit.
                  Find local shows, music, live events, festival dates, and race
                  locations.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 15.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Krog%20Street%20Tunnel/GpYjH5ivFgoOK6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Krog Street Tunnel
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="section-heading text-center mt-5">
            <h2 style="font-family: 'Quicksand', sans-serif; font-weight: 500">
              Food to Eat
            </h2>
          </div>
          <hr />

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  9.
                  <a
                    href="https://thegardenroomatlanta.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Garden Room
                  </a>
                  is a relaxed and stylish bar and restaurant known for indoor
                  floral displays blended with a 3,000-square-foot solarium, a
                  glass-and-steel vaulted roof, a tree sculpture, and sliding
                  glass windows open onto the plaza.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Enjoy a craft cocktail or new wine while perusing the
                  chef-driven small plates menu. Seafood lover? The King Crab &
                  Cherry Bomb with spiced aioli or Fruits of the Sea Platter
                  features oysters. The Hot Mess is a tequila-based cocktail
                  with peach and lime. Send Noods cocktail features Grey Goose
                  vodka, Chambord, lemon, flowers, and color-changing glitter
                  bombs.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 16.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/The%20Garden%20Room%20Atlanta%20/hjGkk7JWyqCWGl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Garden Room
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  10.
                  <a
                    href="https://www.chick-fil-a.com/locations/ga/the-dwarf-house"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Dwarf House
                  </a>
                  opened on May 26, 1946. Truett Cathy and brother Ben Cathy
                  opened the
                  <a
                    href="https://www.youtube.com/watch?v=tGV6M_nzg6s"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dwarf Grill in Hapeville
                  </a>

                  , Georgia, in a converted house with only 10 stools and 4
                  booths. The cozy 24-hour diner served steaks, hamburgers, and
                  other short-order favorites. Truett later invented the
                  Chick-fil-A® chicken sandwich by experimenting with
                  ingredients and inviting customers to test recipes until he
                  settled on the signature recipe in 1964. Today's customers
                  will recognize the iconic little red door, the dwarf mural and
                  the original 14 stools in the newly refurbished building. What
                  began as a small dream grew to over 2,000 restaurants across
                  the U.S.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 17.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Chick%20Fil%20A.%20Dwarf%20House/hUdVmMZ8wHBUnd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fayetteville Dwarf House
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  11.
                  <a
                    href="https://www.thebusybeecafe.com/the-bee"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Busy Bee Cafe
                  </a>
                  - “THE SOUTH ON A PLATE - EST. 1947”
                  <a
                    href="https://www.instagram.com/explore/tags/blackowned/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #blackowned
                  </a>
                  James Beard America’s Classics winner honoring independent and
                  family-owned restaurants that are more than a decade old and
                  exhibit “timeless appeal” for the communities they serve.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  On Hunter Street (now Martin Luther King Jr. Drive), Lucy
                  Jackson opened The Busy Bee Cafe, famous for its fried chicken
                  and ham hocks. Her Soul Food followed the "meat and three"
                  rule with fried chicken, country fried steak, meatloaf or beef
                  tips served with two to three starchy vegetable sides such as
                  bacon-soaked collards, lima beans or mac and cheese. By the
                  1960s, Martin Luther King Jr., Ralph David Abernathy, Hosea
                  Williams, Andrew Young, and Joseph Lowery were regulars at
                  Jackson's Fried Chicken.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  The Busy Bee continues to serve hundreds of plates of
                  Jackson's fried chicken, which is marinated for 12 hours and
                  hand-breaded before being fried. Try ham hocks and fried
                  catfish, with hearty side dishes like collard greens, golden
                  cornbread, and creamy mac and cheese (yes, it's still a
                  vegetable). Voted Best Fried Chicken in #ATL
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 18.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-6 col-6">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 19.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/The%20Busy%20Bee%20Cafe/2MeFD4z4BKbfHq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Busy Bee Cafe
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  12.
                  <a
                    href="https://www.theconsulateatlanta.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Consulate Atlanta
                  </a>
                  - James Beard Grant recipient, 2021 This gorgeous global
                  restaurant in Midtown Atlanta does something truly unique and
                  special. This restaurant with a travel theme has a swanky
                  feel: dark colors. Art, antiques, and Mid-Century modern
                  glamor are all housed under one roof.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  Every three months, a guest spins a globe, and the country
                  becomes the cuisine for the next three months. There are two
                  menus: traditional and global-themed tapas and craft
                  cocktails. Travel the world for dinner in a chic Mid-Century
                  Modern mansion fit for James Bond and located in the heart of
                  Atlanta's Midtown district.
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 20.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/The%20Consulate/fkamu4NHuoVWcN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Consulate
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  13.
                  <a
                    href="https://bigbangrestaurant.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Big Bang Pizza
                  </a>
                  - “the 1st Restaurant served by robots in America and the
                  coolest concept pizza restaurant in Atlanta, with a tasty menu
                  and atmosphere to make you feel out of this world,” It’s an
                  experience: a pet-friendly dining room and patio, huge parking
                  lot and photo opt murals and a variety of scratch-made New
                  York-style pizzas, appetizers – hot wings, melted mozzarella
                  knots, melted sticks, garlic breadsticks, and salads. Big Bang
                  also offers 8-inch toasted subs with their Parmesan and Philly
                  Sub options.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 22px;
                  "
                >
                  <a
                    href="https://www.youtube.com/watch?v=mjyk07yn3X4&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Robot Amy serving pizzas at Big Bang Pizza in Atlanta
                  </a>
                </p>

                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Big%20Bang%20Pizza%20/4jdu1ASseHFJgw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Big Bang Pizza
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  BONUS
                  <a
                    href="https://garnetgalscoffeeshop.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Garnet Gal's Coffee Shop & Bakery
                  </a>
                  is an “organically minded coffee shop with the added benefit
                  of freshly milled grains and beans. I mill my own flour at
                  home and want to share the unique tastes, textures, and health
                  benefits with my customers.”
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class="justify-content-center align-items-center text-center"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  <a
                    href="https://web.myle.com/e/Garnet%20Gal%E2%80%99s%20Coffee%20Shop%20&%20Bakery/9EDk1M0UR0g4BM"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Garnet Gal’s Coffee Shop & Bakery
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row justify-content-center align-items-center text-center"
          >
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlantaBlog/atlanta_Picture 21.png"
                  alt="Saturday Event"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
          </div>

          <div class="row mt-5 justify-content-center align-items-center">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 16px;
                  "
                >
                  We are delighted to have shown you novel things to do, unique
                  places to go, and surprising places to eat. Stay tuned to the
                  blog page for our Atlanta memories. Thanks for joining the
                  Road Trip to Atlanta!
                  <br />
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div class="row mt-5">
            <div class="col-md-2 col-lg-2 col-sm-2">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/maureen.png"
                  alt="Saturday Event"
                  style="
                    max-width: 60%;
                    max-height: 60%;
                    width: 60%;
                    height: 60%;
                  "
                />
              </div>
            </div>

            <div
              class="col-md-10 col-lg-10 col-sm-10 align-items-center justify-content-center"
            >
              <div class="align-items-center justify-content-center">
                <p
                  class=""
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                  "
                >
                  MAUREEN FRIEND is a freelance writer and visual content
                  creator passionate about seeing you exceed your business goals
                  and shamelessly promoting you and your work; have phone, will
                  travel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "15 Unique Atlanta Things to Do, Places to Go, and Food to Eat ",

    meta: [
      {
        name: "description",
        content:
          "We're here! Atlanta has it all, from independently owned shops to public art; self-guided tours on the Civil Rights Trail to a deliciously curated global food tour; a street-level search for tiny doors to unparalleled roop-top views. ALT has it all. You could spend days sampling soul food and trying all the fried chicken. Tell us if The Busy Bee Cafe still owns the right to “best friend chicken in #ATL.” And go ahead, eat that fried chicken with your hands because eating it with a fork in this part of Georgia is a fineable offense",
      },

      {
        name: "keywords",
        content:
          "We're here! Atlanta has it all, from independently owned shops to public art; self-guided tours on the Civil Rights Trail to a deliciously curated global food tour; a street-level search for tiny doors to unparalleled roop-top views. ALT has it all. You could spend days sampling soul food and trying all the fried chicken. Tell us if The Busy Bee Cafe still owns the right to “best friend chicken in #ATL.” And go ahead, eat that fried chicken with your hands because eating it with a fork in this part of Georgia is a fineable offense",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/atlanta",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
