<template>
  <div>
    <nav-bar />
    <div class="main">
      <section class="ptb-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10 justify-content-center">
              <div class="">
                <h1 class="">Privacy Policy</h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Last updated: July 23, 2021</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >This Privacy Policy describes Our policies and procedures
                    on the collection, use and disclosure of Your information
                    when You use the Service and tells You about Your privacy
                    rights and how the law protects You.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Interpretation and Definitions</span
                  >
                </h1>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Interpretation</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Definitions</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For the purposes of this Privacy Policy:</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Account</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means a unique account created for You to access our
                    Service or parts of our Service.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Affiliate</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means an entity that controls, is controlled by or is
                    under common control with a party, where &quot;control&quot;
                    means ownership of 50% or more of the shares, equity
                    interest or other securities entitled to vote for election
                    of directors or other managing authority.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Application</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means the software program provided by the Company
                    downloaded by You on any electronic device, named MYLE</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Business</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >, for the purpose of the CCPA (California Consumer Privacy
                    Act), refers to the Company as the legal entity that
                    collects Consumers' personal information and determines the
                    purposes and means of the processing of Consumers' personal
                    information, or on behalf of which such information is
                    collected and that alone, or jointly with others, determines
                    the purposes and means of the processing of consumers'
                    personal information, that does business in the State of
                    California.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Company</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;(referred to as either &quot;the Company&quot;,
                    &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                    Agreement) refers to MYLE, LLC, 175 S. 3rd Street, Suite
                    200, Columbus, OH 43215.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For the purpose of the GDPR, the Company is the Data
                    Controller.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Consumer</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >, for the purpose of the CCPA (California Consumer Privacy
                    Act), means a natural person who is a California resident. A
                    resident, as defined in the law, includes (1) every
                    individual who is in the USA for other than a temporary or
                    transitory purpose, and (2) every individual who is
                    domiciled in the USA who is outside the USA for a temporary
                    or transitory purpose.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Cookies</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;are small files that are placed on Your computer,
                    mobile device or any other device by a website, containing
                    the details of Your browsing history on that website among
                    its many uses.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Country</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;refers to: Ohio, United States</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Data Controller</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >, for the purposes of the GDPR (General Data Protection
                    Regulation), refers to the Company as the legal person which
                    alone or jointly with others determines the purposes and
                    means of the processing of Personal Data.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Device</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means any device that can access the Service such as
                    a computer, a cellphone or a digital tablet.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Do Not Track</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;(DNT) is a concept that has been promoted by US
                    regulatory authorities, in particular the U.S. Federal Trade
                    Commission (FTC), for the Internet industry to develop and
                    implement a mechanism for allowing internet users to control
                    the tracking of their online activities across
                    websites.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Facebook Fan Page</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;is a public profile named MYLEMarkers specifically
                    created by the Company on the Facebook social network,
                    accessible from&nbsp;<a
                      href="https://www.facebook.com/MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/MYLEMARKERS</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Personal Data</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;is any information that relates to an identified or
                    identifiable individual.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For the purposes for GDPR, Personal Data means any
                    information relating to You such as a name, an
                    identification number, location data, online identifier or
                    to one or more factors specific to the physical,
                    physiological, genetic, mental, economic, cultural or social
                    identity.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For the purposes of the CCPA, Personal Data means any
                    information that identifies, relates to, describes or is
                    capable of being associated with, or could reasonably be
                    linked, directly or indirectly, with You.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Sale</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >, for the purpose of the CCPA (California Consumer Privacy
                    Act), means selling, renting, releasing, disclosing,
                    disseminating, making available, transferring, or otherwise
                    communicating orally, in writing, or by electronic or other
                    means, a Consumer's personal information to another business
                    or a third party for monetary or other valuable
                    consideration.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Service</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;refers to the Application or the Website or
                    both.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Service Provider</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means any natural or legal person who processes the
                    data on behalf of the Company. It refers to third-party
                    companies or individuals employed by the Company to
                    facilitate the Service, to provide the Service on behalf of
                    the Company, to perform services related to the Service or
                    to assist the Company in analyzing how the Service is used.
                    For the purpose of the GDPR, Service Providers are
                    considered Data Processors.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Third-party Social Media Service</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;refers to any website or any social network website
                    through which a User can log in or create an account to use
                    the Service.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Usage Data</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;refers to data collected automatically, either
                    generated by the use of the Service or from the Service
                    infrastructure itself (for example, the duration of a page
                    visit).</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Website</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;refers to MYLE - Make Your Life Entertaining,
                    accessible from&nbsp;<a
                      href="https://www.termsfeed.com/live/www.myle.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444">www.myle.com</span></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;means the individual accessing or using the Service,
                    or the company, or other legal entity on behalf of which
                    such individual is accessing or using the Service, as
                    applicable.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Under GDPR (General Data Protection Regulation), You can be
                    referred to as the Data Subject or as the User as you are
                    the individual using the Service.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Collecting and Using Your Personal Data</span
                  >
                </h1>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Types of Data Collected</span
                  >
                </h2>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Personal Data</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Email address</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >First name and last name</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Phone number</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Address, State, Province, ZIP/Postal code, City</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Usage Data</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Usage Data</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Usage Data is collected automatically when using the
                    Service.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic
                    data.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Information from Third-Party Social Media Services</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company allows You to create an account and log in to
                    use the Service through the following Third-party Social
                    Media Services:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Google</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Facebook</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Twitter</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If You decide to register through or otherwise grant us
                    access to a Third-Party Social Media Service, We may collect
                    Personal data that is already associated with Your
                    Third-Party Social Media Service's account, such as Your
                    name, Your email address, Your activities or Your contact
                    list associated with that account.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You may also have the option of sharing additional
                    information with the Company through Your Third-Party Social
                    Media Service's account. If You choose to provide such
                    information and Personal Data, during registration or
                    otherwise, You are giving the Company permission to use,
                    share, and store it in a manner consistent with this Privacy
                    Policy.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Information Collected while Using the Application</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >While using Our Application, in order to provide features
                    of Our Application, We may collect, with Your prior
                    permission:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Information regarding your location</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Information from your Device's phone book (contacts
                    list)</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Pictures and other information from your Device's camera
                    and photo library</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We use this information to provide features of Our Service,
                    to improve and customize Our Service. The information may be
                    uploaded to the Company's servers and/or a Service
                    Provider's server or it may be simply stored on Your
                    device.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can enable or disable access to this information at any
                    time, through Your Device settings.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Tracking Technologies and Cookies</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Cookies or Browser Cookies.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;A cookie is a small file placed on Your Device. You
                    can instruct Your browser to refuse all Cookies or to
                    indicate when a Cookie is being sent. However, if You do not
                    accept Cookies, You may not be able to use some parts of our
                    Service. Unless you have adjusted Your browser setting so
                    that it will refuse Cookies, our Service may use
                    Cookies.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Flash Cookies.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Certain features of our Service may use local stored
                    objects (or Flash Cookies) to collect and store information
                    about Your preferences or Your activity on our Service.
                    Flash Cookies are not managed by the same browser settings
                    as those used for Browser Cookies. For more information on
                    how You can delete Flash Cookies, please read &quot;Where
                    can I change the settings for disabling, or deleting local
                    shared objects?&quot; available at&nbsp;<a
                      href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Web Beacons.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Certain sections of our Service and our emails may
                    contain small electronic files known as web beacons (also
                    referred to as clear gifs, pixel tags, and single-pixel
                    gifs) that permit the Company, for example, to count users
                    who have visited those pages or opened an email and for
                    other related website statistics (for example, recording the
                    popularity of a certain section and verifying system and
                    server integrity).</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Cookies can be &quot;Persistent&quot; or
                    &quot;Session&quot; Cookies. Persistent Cookies remain on
                    Your personal computer or mobile device when You go offline,
                    while Session Cookies are deleted as soon as You close Your
                    web browser. You can learn more about cookies here:&nbsp;<a
                      href="https://www.termsfeed.com/blog/cookies/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >All About Cookies by TermsFeed</span
                      ></a
                    >.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We use both Session and Persistent Cookies for the purposes
                    set out below:</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Necessary / Essential Cookies</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Type: Session Cookies</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Administered by: Us</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Purpose: These Cookies are essential to provide You with
                    services available through the Website and to enable You to
                    use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Cookies Policy / Notice Acceptance Cookies</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Type: Persistent Cookies</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Administered by: Us</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Purpose: These Cookies identify if users have accepted the
                    use of cookies on the Website.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Functionality Cookies</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Type: Persistent Cookies</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Administered by: Us</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Purpose: These Cookies allow us to remember choices You
                    make when You use the Website, such as remembering your
                    login details or language preference. The purpose of these
                    Cookies is to provide You with a more personal experience
                    and to avoid You having to re-enter your preferences every
                    time You use the Website.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Tracking and Performance Cookies</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Type: Persistent Cookies</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Administered by: Third-Parties</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Purpose: These Cookies are used to track information about
                    traffic to the Website and how users use the Website. The
                    information gathered via these Cookies may directly or
                    indirectly identify you as an individual visitor. This is
                    because the information collected is typically linked to a
                    pseudonymous identifier associated with the device you use
                    to access the Website. We may also use these Cookies to test
                    new pages, features or new functionality of the Website to
                    see how our users react to them.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Targeting and Advertising Cookies</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Type: Persistent Cookies</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Administered by: Third-Parties</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Purpose: These Cookies track your browsing habits to enable
                    Us to show advertising which is more likely to be of
                    interest to You. These Cookies use information about your
                    browsing history to group You with other users who have
                    similar interests. Based on that information, and with Our
                    permission, third party advertisers can place Cookies to
                    enable them to show adverts which We think will be relevant
                    to your interests while You are on third party
                    websites.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy
                    or the Cookies section of our Privacy Policy.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Use of Your Personal Data</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company may use Personal Data for the following
                    purposes:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To provide and maintain our Service</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >, including to monitor the usage of our Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To manage Your Account:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;to manage Your registration as a user of the Service.
                    The Personal Data You provide can give You access to
                    different functionalities of the Service that are available
                    to You as a registered user.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >For the performance of a contract:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;the development, compliance and undertaking of the
                    purchase contract for the products, items or services You
                    have purchased or of any other contract with Us through the
                    Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To contact You:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;To contact You by email, telephone calls, SMS, or
                    other equivalent forms of electronic communication, such as
                    a mobile application's push notifications regarding updates
                    or informative communications related to the
                    functionalities, products or contracted services, including
                    the security updates, when necessary or reasonable for their
                    implementation.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To provide You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;with news, special offers and general information
                    about other goods, services and events which we offer that
                    are similar to those that you have already purchased or
                    enquired about unless You have opted not to receive such
                    information.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To manage Your requests:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;To attend and manage Your requests to Us.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >To deliver targeted advertising to You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >: We may use Your information to develop and display
                    content and advertising (and work with third-party vendors
                    who do so) tailored to Your interests and/or location and to
                    measure its effectiveness.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >For business transfers:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We may use Your information to evaluate or conduct a
                    merger, divestiture, restructuring, reorganization,
                    dissolution, or other sale or transfer of some or all of Our
                    assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which Personal Data
                    held by Us about our Service users is among the assets
                    transferred.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >For other purposes</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >: We may use Your information for other purposes, such as
                    data analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate
                    and improve our Service, products, services, marketing and
                    your experience.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may share Your personal information in the following
                    situations:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >With Service Providers:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We may share Your personal information with Service
                    Providers to monitor and analyze the use of our Service, to
                    show advertisements to You to help support and maintain Our
                    Service, to advertise on third party websites to You after
                    You visited our Service, for payment processing, to contact
                    You.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >For business transfers:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale
                    of Company assets, financing, or acquisition of all or a
                    portion of Our business to another company.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >With Affiliates:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We may share Your information with Our affiliates, in
                    which case we will require those affiliates to honor this
                    Privacy Policy. Affiliates include Our parent company and
                    any other subsidiaries, joint venture partners or other
                    companies that We control or that are under common control
                    with Us.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >With business partners:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We may share Your information with Our business
                    partners to offer You certain products, services or
                    promotions.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >With other users:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;when You share personal information or otherwise
                    interact in the public areas with other users, such
                    information may be viewed by all users and may be publicly
                    distributed outside. If You interact with other users or
                    register through a Third-Party Social Media Service, Your
                    contacts on the Third-Party Social Media Service may see
                    Your name, profile, pictures and description of Your
                    activity. Similarly, other users will be able to view
                    descriptions of Your activity, communicate with You and view
                    Your profile.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >With Your consent</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >: We may disclose Your personal information for any other
                    purpose with Your consent.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Retention of Your Personal Data</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Transfer of Your Personal Data</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Disclosure of Your Personal Data</span
                  >
                </h2>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Business Transactions</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If the Company is involved in a merger, acquisition or
                    asset sale, Your Personal Data may be transferred. We will
                    provide notice before Your Personal Data is transferred and
                    becomes subject to a different Privacy Policy.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Law enforcement</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Other legal requirements</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Comply with a legal obligation</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Protect and defend the rights or property of the
                    Company</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Prevent or investigate possible wrongdoing in connection
                    with the Service</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Protect the personal safety of Users of the Service or the
                    public</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Protect against legal liability</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Security of Your Personal Data</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute
                    security.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Detailed Information on the Processing of Your Personal
                    Data</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Service Providers We use may have access to Your
                    Personal Data. These third-party vendors collect, store,
                    use, process and transfer information about Your activity on
                    Our Service in accordance with their Privacy Policies.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Analytics</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use third-party Service providers to monitor and
                    analyze the use of our Service.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Google Analytics</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Google Analytics is a web analytics service offered by
                    Google that tracks and reports website traffic. Google uses
                    the data collected to track and monitor the use of our
                    Service. This data is shared with other Google services.
                    Google may use the collected data to contextualize and
                    personalize the ads of its own advertising network.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out of having made your activity on the Service
                    available to Google Analytics by installing the Google
                    Analytics opt-out browser add-on. The add-on prevents the
                    Google Analytics JavaScript (ga.js, analytics.js and dc.js)
                    from sharing information with Google Analytics about visits
                    activity.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You may opt-out of certain Google Analytics features
                    through your mobile device settings, such as your device
                    advertising settings or by following the instructions
                    provided by Google in their Privacy Policy:&nbsp;<a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web
                    page:&nbsp;<a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Firebase</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Firebase is an analytics service provided by Google
                    Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You may opt-out of certain Firebase features through your
                    mobile device settings, such as your device advertising
                    settings or by following the instructions provided by Google
                    in their Privacy Policy:&nbsp;<a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We also encourage you to review the Google's policy for
                    safeguarding your data:&nbsp;<a
                      href="https://support.google.com/analytics/answer/6004245"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://support.google.com/analytics/answer/6004245</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on what type of information Firebase
                    collects, please visit the How Google uses data when you use
                    our partners' sites or apps webpage:&nbsp;<a
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/technologies/partner-sites</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Flurry Analytics</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Flurry Analytics service is provided by Yahoo! Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out from Flurry Analytics service to prevent
                    Flurry Analytics from using and sharing your information by
                    visiting the Flurry's Opt-out page:&nbsp;<a
                      href="https://developer.yahoo.com/flurry/end-user-opt-out/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://developer.yahoo.com/flurry/end-user-opt-out/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices and policies
                    of Yahoo!, please visit their Privacy Policy page:&nbsp;<a
                      href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Mixpanel</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Mixpanel is provided by Mixpanel Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can prevent Mixpanel from using your information for
                    analytics purposes by opting-out. To opt-out of Mixpanel
                    service, please visit this page:&nbsp;<a
                      href="https://mixpanel.com/optout/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://mixpanel.com/optout/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on what type of information Mixpanel
                    collects, please visit the Terms of Use page of
                    Mixpanel:&nbsp;<a
                      href="https://mixpanel.com/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://mixpanel.com/terms/</span
                      ></a
                    ></span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Advertising</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use Service Providers to show advertisements to You
                    to help support and maintain Our Service.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >AdMob by Google</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >AdMob by Google is provided by Google Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out from the AdMob by Google service by
                    following the instructions described by Google:&nbsp;<a
                      href="https://support.google.com/ads/answer/2662922?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://support.google.com/ads/answer/2662922?hl=en</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on how Google uses the collected
                    information, please visit the &quot;How Google uses data
                    when you use our partners' sites or app&quot; page:&nbsp;<a
                      href="https://policies.google.com/technologies/partner-sites"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/technologies/partner-sites</span
                      ></a
                    >&nbsp;or visit the Privacy Policy of Google:&nbsp;<a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Email Marketing</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use Your Personal Data to contact You with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to You. You may opt-out
                    of receiving any, or all, of these communications from Us by
                    following the unsubscribe link or instructions provided in
                    any email We send or by contacting Us.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use Email Marketing Service Providers to manage and
                    send emails to You.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Mailchimp</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Mailchimp is an email marketing sending service provided by
                    The Rocket Science Group LLC.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices of Mailchimp,
                    please visit their Privacy policy:&nbsp;<a
                      href="https://mailchimp.com/legal/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://mailchimp.com/legal/privacy/</span
                      ></a
                    ></span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Payments</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may provide paid products and/or services within the
                    Service. In that case, we may use third-party services for
                    payment processing (e.g. payment processors).</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We will not store or collect Your payment card details.
                    That information is provided directly to Our third-party
                    payment processors whose use of Your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Apple Store In-App Payments</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Their Privacy Policy can be viewed at&nbsp;<a
                      href="https://www.apple.com/legal/privacy/en-ww/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.apple.com/legal/privacy/en-ww/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Google Play In-App Payments</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Their Privacy Policy can be viewed at&nbsp;<a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.google.com/policies/privacy/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Stripe</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Their Privacy Policy can be viewed at&nbsp;<a
                      href="https://stripe.com/us/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://stripe.com/us/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >PayPal</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Their Privacy Policy can be viewed at&nbsp;<a
                      href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.paypal.com/webapps/mpp/ua/privacy-full</span
                      ></a
                    ></span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Behavioral Remarketing</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company uses remarketing services to advertise to You
                    after You accessed or visited our Service. We and Our
                    third-party vendors use cookies and non-cookie technologies
                    to help Us recognize Your Device and understand how You use
                    our Service so that We can improve our Service to reflect
                    Your interests and serve You advertisements that are likely
                    to be of more interest to You.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >These third-party vendors collect, store, use, process and
                    transfer information about Your activity on Our Service in
                    accordance with their Privacy Policies and to enable Us
                    to:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Measure and analyze traffic and browsing activity on Our
                    Service</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Show advertisements for our products and/or services to You
                    on third-party websites or apps</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Measure and analyze the performance of Our advertising
                    campaigns</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Some of these third-party vendors may use non-cookie
                    technologies that may not be impacted by browser settings
                    that block cookies. Your browser may not permit You to block
                    such technologies. You can use the following third-party
                    tools to decline the collection and use of information for
                    the purpose of serving You interest-based advertising:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The NAI's opt-out platform:&nbsp;<a
                      href="http://www.networkadvertising.org/choices/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.networkadvertising.org/choices/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The EDAA's opt-out platform&nbsp;<a
                      href="http://www.youronlinechoices.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.youronlinechoices.com/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The DAA's opt-out platform:&nbsp;<a
                      href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://optout.aboutads.info/?c=2&amp;lang=EN</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You may opt-out of all personalized advertising by enabling
                    privacy features on Your mobile device such as Limit Ad
                    Tracking (iOS) and Opt Out of Ads Personalization (Android).
                    See Your mobile device Help system for more
                    information.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may share information, such as hashed email addresses
                    (if available) or other online identifiers collected on Our
                    Service with these third-party vendors. This allows Our
                    third-party vendors to recognize and deliver You ads across
                    devices and browsers. To read more about the technologies
                    used by these third-party vendors and their cross-device
                    capabilities please refer to the Privacy Policy of each
                    vendor listed below.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The third-party vendors We use are:</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Google Ads (AdWords)</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Google Ads (AdWords) remarketing service is provided by
                    Google Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out of Google Analytics for Display Advertising
                    and customise the Google Display Network ads by visiting the
                    Google Ads Settings page:&nbsp;<a
                      href="http://www.google.com/settings/ads"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.google.com/settings/ads</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Google also recommends installing the Google Analytics
                    Opt-out Browser Add-on -&nbsp;<a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://tools.google.com/dlpage/gaoptout</span
                      ></a
                    >&nbsp;- for your web browser. Google Analytics Opt-out
                    Browser Add-on provides visitors with the ability to prevent
                    their data from being collected and used by Google
                    Analytics.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web
                    page:&nbsp;<a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://policies.google.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Twitter</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Twitter remarketing service is provided by Twitter
                    Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out from Twitter's interest-based ads by
                    following their instructions:&nbsp;<a
                      href="https://support.twitter.com/articles/20170405"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://support.twitter.com/articles/20170405</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can learn more about the privacy practices and policies
                    of Twitter by visiting their Privacy Policy page:&nbsp;<a
                      href="https://twitter.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://twitter.com/privacy</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Facebook</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Facebook remarketing service is provided by Facebook
                    Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can learn more about interest-based advertising from
                    Facebook by visiting this page:&nbsp;<a
                      href="https://www.facebook.com/help/516147308587266"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/help/516147308587266</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >To opt-out from Facebook's interest-based ads, follow these
                    instructions from Facebook:&nbsp;<a
                      href="https://www.facebook.com/help/568137493302217"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/help/568137493302217</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Facebook adheres to the Self-Regulatory Principles for
                    Online Behavioural Advertising established by the Digital
                    Advertising Alliance. You can also opt-out from Facebook and
                    other participating companies through the Digital
                    Advertising Alliance in the USA&nbsp;<a
                      href="http://www.aboutads.info/choices/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.aboutads.info/choices/</span
                      ></a
                    >, the Digital Advertising Alliance of Canada in
                    Canada&nbsp;<a
                      href="http://youradchoices.ca/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://youradchoices.ca/</span
                      ></a
                    >&nbsp;or the European Interactive Digital Advertising
                    Alliance in Europe&nbsp;<a
                      href="http://www.youronlinechoices.eu/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.youronlinechoices.eu/</span
                      ></a
                    >, or opt-out using your mobile device settings.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices of Facebook,
                    please visit Facebook's Data Policy:&nbsp;<a
                      href="https://www.facebook.com/privacy/explanation"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/privacy/explanation</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Pinterest</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Pinterest remarketing service is provided by Pinterest
                    Inc.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt-out from Pinterest's interest-based ads by
                    enabling the &quot;Do Not Track&quot; functionality of your
                    web browser or by following Pinterest instructions:&nbsp;<a
                      href="http://help.pinterest.com/en/articles/personalization-and-data"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://help.pinterest.com/en/articles/personalization-and-data</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can learn more about the privacy practices and policies
                    of Pinterest by visiting their Privacy Policy page:&nbsp;<a
                      href="https://about.pinterest.com/en/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://about.pinterest.com/en/privacy-policy</span
                      ></a
                    ></span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >GDPR Privacy</span
                  >
                </h1>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Legal Basis for Processing Personal Data under GDPR</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may process Personal Data under the following
                    conditions:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Consent:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have given Your consent for processing Personal
                    Data for one or more specific purposes.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Performance of a contract:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Provision of Personal Data is necessary for the
                    performance of an agreement with You and/or for any
                    pre-contractual obligations thereof.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Legal obligations:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Processing Personal Data is necessary for compliance
                    with a legal obligation to which the Company is
                    subject.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Vital interests:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Processing Personal Data is necessary in order to
                    protect Your vital interests or of another natural
                    person.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Public interests:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Processing Personal Data is related to a task that is
                    carried out in the public interest or in the exercise of
                    official authority vested in the Company.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Legitimate interests:</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Processing Personal Data is necessary for the
                    purposes of the legitimate interests pursued by the
                    Company.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >In any case, the Company will gladly help to clarify the
                    specific legal basis that applies to the processing, and in
                    particular whether the provision of Personal Data is a
                    statutory or contractual requirement, or a requirement
                    necessary to enter into a contract.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Your Rights under the GDPR</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company undertakes to respect the confidentiality of
                    Your Personal Data and to guarantee You can exercise Your
                    rights.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You have the right under this Privacy Policy, and by law if
                    You are within the EU, to:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Request access to Your Personal Data.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;The right to access, update or delete the information
                    We have on You. Whenever made possible, you can access,
                    update or request deletion of Your Personal Data directly
                    within Your account settings section. If you are unable to
                    perform these actions yourself, please contact Us to assist
                    You. This also enables You to receive a copy of the Personal
                    Data We hold about You.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Request correction of the Personal Data that We hold
                      about You.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to have any incomplete or
                    inaccurate information We hold about You corrected.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Object to processing of Your Personal Data.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;This right exists where We are relying on a
                    legitimate interest as the legal basis for Our processing
                    and there is something about Your particular situation,
                    which makes You want to object to our processing of Your
                    Personal Data on this ground. You also have the right to
                    object where We are processing Your Personal Data for direct
                    marketing purposes.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Request erasure of Your Personal Data.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to ask Us to delete or remove
                    Personal Data when there is no good reason for Us to
                    continue processing it.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Request the transfer of Your Personal Data.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;We will provide to You, or to a third-party You have
                    chosen, Your Personal Data in a structured, commonly used,
                    machine-readable format. Please note that this right only
                    applies to automated information which You initially
                    provided consent for Us to use or where We used the
                    information to perform a contract with You.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Withdraw Your consent.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to withdraw Your consent on using
                    your Personal Data. If You withdraw Your consent, We may not
                    be able to provide You with access to certain specific
                    functionalities of the Service.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Exercising of Your GDPR Data Protection Rights</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You may exercise Your rights of access, rectification,
                    cancellation and opposition by contacting Us. Please note
                    that we may ask You to verify Your identity before
                    responding to such requests. If You make a request, We will
                    try our best to respond to You as soon as possible.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You have the right to complain to a Data Protection
                    Authority about Our collection and use of Your Personal
                    Data. For more information, if You are in the European
                    Economic Area (EEA), please contact Your local data
                    protection authority in the EEA.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Facebook Fan Page</span
                  >
                </h1>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Data Controller for the Facebook Fan Page</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company is the Data Controller of Your Personal Data
                    collected while using the Service. As operator of the
                    Facebook Fan Page&nbsp;<a
                      href="https://www.facebook.com/MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/MYLEMARKERS</span
                      ></a
                    >, the Company and the operator of the social network
                    Facebook are Joint Controllers.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Company has entered into agreements with Facebook that
                    define the terms for use of the Facebook Fan Page, among
                    other things. These terms are mostly based on the Facebook
                    Terms of Service:&nbsp;<a
                      href="https://www.facebook.com/terms.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/terms.php</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Visit the Facebook Privacy Policy&nbsp;<a
                      href="https://www.facebook.com/policy.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/policy.php</span
                      ></a
                    >&nbsp;for more information about how Facebook manages
                    Personal data or contact Facebook online, or by mail:
                    Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road,
                    Menlo Park, CA 94025, United States.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Facebook Insights</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We use the Facebook Insights function in connection with
                    the operation of the Facebook Fan Page and on the basis of
                    the GDPR, in order to obtain anonymized statistical data
                    about Our users.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For this purpose, Facebook places a Cookie on the device of
                    the user visiting Our Facebook Fan Page. Each Cookie
                    contains a unique identifier code and remains active for a
                    period of two years, except when it is deleted before the
                    end of this period.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Facebook receives, records and processes the information
                    stored in the Cookie, especially when the user visits the
                    Facebook services, services that are provided by other
                    members of the Facebook Fan Page and services by other
                    companies that use Facebook services.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For more information on the privacy practices of Facebook,
                    please visit Facebook Privacy Policy here:&nbsp;<a
                      href="https://www.facebook.com/privacy/explanation"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >https://www.facebook.com/privacy/explanation</span
                      ></a
                    ></span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >CCPA Privacy</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >This privacy notice section for California residents
                    supplements the information contained in Our Privacy Policy
                    and it applies solely to all visitors, users, and others who
                    reside in the State of California.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Categories of Personal Information Collected</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We collect information that identifies, relates to,
                    describes, references, is capable of being associated with,
                    or could reasonably be linked, directly or indirectly, with
                    a particular Consumer or Device. The following is a list of
                    categories of personal information which we may collect or
                    may have been collected from California residents within the
                    last twelve (12) months.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Please note that the categories and examples provided in
                    the list below are those defined in the CCPA. This does not
                    mean that all examples of that category of personal
                    information were in fact collected by Us, but reflects our
                    good faith belief to the best of our knowledge that some of
                    that information from the applicable category may be and may
                    have been collected. For example, certain categories of
                    personal information would only be collected if You provided
                    such personal information directly to Us.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category A: Identifiers.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: A real name, alias, postal address, unique
                    personal identifier, online identifier, Internet Protocol
                    address, email address, account name, driver's license
                    number, passport number, or other similar identifiers.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: Yes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category B: Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code §
                      1798.80(e)).</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: A name, signature, Social Security number,
                    physical characteristics or description, address, telephone
                    number, passport number, driver's license or state
                    identification card number, insurance policy number,
                    education, employment, employment history, bank account
                    number, credit card number, debit card number, or any other
                    financial information, medical information, or health
                    insurance information. Some personal information included in
                    this category may overlap with other categories.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: Yes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category C: Protected classification characteristics
                      under California or federal law.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Age (40 years or older), race, color, ancestry,
                    national origin, citizenship, religion or creed, marital
                    status, medical condition, physical or mental disability,
                    sex (including gender, gender identity, gender expression,
                    pregnancy or childbirth and related medical conditions),
                    sexual orientation, veteran or military status, genetic
                    information (including familial genetic information).</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category D: Commercial information.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Records and history of products or services
                    purchased or considered.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: Yes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category E: Biometric information.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Genetic, physiological, behavioral, and
                    biological characteristics, or activity patterns used to
                    extract a template or other identifier or identifying
                    information, such as, fingerprints, faceprints, and
                    voiceprints, iris or retina scans, keystroke, gait, or other
                    physical patterns, and sleep, health, or exercise
                    data.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category F: Internet or other similar network
                      activity.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Interaction with our Service or
                    advertisement.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: Yes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category G: Geolocation data.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Approximate physical location.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: Yes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category H: Sensory data.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Audio, electronic, visual, thermal, olfactory, or
                    similar information.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category I: Professional or employment-related
                      information.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Current or past job history or performance
                    evaluations.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category J: Non-public education information (per the
                      Family Educational Rights and Privacy Act (20 U.S.C.
                      Section 1232g, 34 C.F.R. Part 99)).</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Education records directly related to a student
                    maintained by an educational institution or party acting on
                    its behalf, such as grades, transcripts, class lists,
                    student schedules, student identification codes, student
                    financial information, or student disciplinary
                    records.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Category K: Inferences drawn from other personal
                      information.</span
                    ></strong
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Examples: Profile reflecting a person's preferences,
                    characteristics, psychological trends, predispositions,
                    behavior, attitudes, intelligence, abilities, and
                    aptitudes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 22.5pt;
                    line-height: 200%;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Collected: No.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Under CCPA, personal information does not include:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Publicly available information from government
                    records</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Deidentified or aggregated consumer information</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Information excluded from the CCPA's scope, such as:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA)
                    and the California Confidentiality of Medical Information
                    Act (CMIA) or clinical trial data</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Personal Information covered by certain sector-specific
                    privacy laws, including the Fair Credit Reporting Act
                    (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
                    Financial Information Privacy Act (FIPA), and the Driver's
                    Privacy Protection Act of 1994</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Sources of Personal Information</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We obtain the categories of personal information listed
                    above from the following categories of sources:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Directly from You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >. For example, from the forms You complete on our Service,
                    preferences You express or provide through our Service, or
                    from Your purchases on our Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Indirectly from You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >. For example, from observing Your activity on our
                    Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >Automatically from You</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >. For example, through cookies We or our Service Providers
                    set on Your Device as You navigate through our
                    Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >From Service Providers</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >. For example, third-party vendors to monitor and analyze
                    the use of our Service, third-party vendors to provide
                    advertising on our Service, third-party vendors to deliver
                    targeted advertising to You, third-party vendors for payment
                    processing, or other third-party vendors that We use to
                    provide the Service to You.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Use of Personal Information for Business Purposes or
                    Commercial Purposes</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use or disclose personal information We collect for
                    &quot;business purposes&quot; or &quot;commercial
                    purposes&quot; (as defined under the CCPA), which may
                    include the following examples:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >To operate our Service and provide You with our
                    Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >To provide You with support and to respond to Your
                    inquiries, including to investigate and address Your
                    concerns and monitor and improve our Service.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >To fulfill or meet the reason You provided the information.
                    For example, if You share Your contact information to ask a
                    question about our Service, We will use that personal
                    information to respond to Your inquiry. If You provide Your
                    personal information to purchase a product or service, We
                    will use that information to process Your payment and
                    facilitate delivery.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >To respond to law enforcement requests and as required by
                    applicable law, court order, or governmental
                    regulations.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >As described to You when collecting Your personal
                    information or as otherwise set forth in the CCPA.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >For internal administrative and auditing purposes.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >To detect security incidents and protect against malicious,
                    deceptive, fraudulent or illegal activity, including, when
                    necessary, to prosecute those responsible for such
                    activities.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Please note that the examples provided above are
                    illustrative and not intended to be exhaustive. For more
                    details on how we use this information, please refer to the
                    &quot;Use of Your Personal Data&quot; section.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If We decide to collect additional categories of personal
                    information or use the personal information We collected for
                    materially different, unrelated, or incompatible purposes We
                    will update this Privacy Policy.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Disclosure of Personal Information for Business Purposes or
                    Commercial Purposes</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may use or disclose and may have used or disclosed in
                    the last twelve (12) months the following categories of
                    personal information for business or commercial
                    purposes:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category A: Identifiers</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. Civ. Code §
                    1798.80(e))</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category D: Commercial information</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category F: Internet or other similar network
                    activity</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category G: Geolocation data</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Please note that the categories listed above are those
                    defined in the CCPA. This does not mean that all examples of
                    that category of personal information were in fact
                    disclosed, but reflects our good faith belief to the best of
                    our knowledge that some of that information from the
                    applicable category may be and may have been
                    disclosed.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >When We disclose personal information for a business
                    purpose or a commercial purpose, We enter a contract that
                    describes the purpose and requires the recipient to both
                    keep that personal information confidential and not use it
                    for any purpose except performing the contract.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Sale of Personal Information</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >As defined in the CCPA, &quot;sell&quot; and
                    &quot;sale&quot; mean selling, renting, releasing,
                    disclosing, disseminating, making available, transferring,
                    or otherwise communicating orally, in writing, or by
                    electronic or other means, a consumer's personal information
                    by the business to a third party for valuable consideration.
                    This means that We may have received some kind of benefit in
                    return for sharing personal information, but not necessarily
                    a monetary benefit.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Please note that the categories listed below are those
                    defined in the CCPA. This does not mean that all examples of
                    that category of personal information were in fact sold, but
                    reflects our good faith belief to the best of our knowledge
                    that some of that information from the applicable category
                    may be and may have been shared for value in return.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may sell and may have sold in the last twelve (12)
                    months the following categories of personal
                    information:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category A: Identifiers</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. Civ. Code §
                    1798.80(e))</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category D: Commercial information</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category F: Internet or other similar network
                    activity</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Category G: Geolocation data</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Share of Personal Information</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may share Your personal information identified in the
                    above categories with the following categories of third
                    parties:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Service Providers</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Payment processors</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Our affiliates</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Our business partners</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Third party vendors to whom You or Your agents authorize Us
                    to disclose Your personal information in connection with
                    products or services We provide to You</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Sale of Personal Information of Minors Under 16 Years of
                    Age</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We do not knowingly collect personal information from
                    minors under the age of 16 through our Service, although
                    certain third party websites that we link to may do so.
                    These third-party websites have their own terms of use and
                    privacy policies and we encourage parents and legal
                    guardians to monitor their children's Internet usage and
                    instruct their children to never provide information on
                    other websites without their permission.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We do not sell the personal information of Consumers We
                    actually know are less than 16 years of age, unless We
                    receive affirmative authorization (the &quot;right to
                    opt-in&quot;) from either the Consumer who is between 13 and
                    16 years of age, or the parent or guardian of a Consumer
                    less than 13 years of age. Consumers who opt-in to the sale
                    of personal information may opt-out of future sales at any
                    time. To exercise the right to opt-out, You (or Your
                    authorized representative) may submit a request to Us by
                    contacting Us.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If You have reason to believe that a child under the age of
                    13 (or 16) has provided Us with personal information, please
                    contact Us with sufficient detail to enable Us to delete
                    that information.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Your Rights under the CCPA</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The CCPA provides California residents with specific rights
                    regarding their personal information. If You are a resident
                    of California, You have the following rights:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >The right to notice.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to be notified which categories of
                    Personal Data are being collected and the purposes for which
                    the Personal Data is being used.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >The right to request.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;Under CCPA, You have the right to request that We
                    disclose information to You about Our collection, use, sale,
                    disclosure for business purposes and share of personal
                    information. Once We receive and confirm Your request, We
                    will disclose to You:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The categories of personal information We collected about
                    You</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The categories of sources for the personal information We
                    collected about You</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Our business or commercial purpose for collecting or
                    selling that personal information</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The categories of third parties with whom We share that
                    personal information</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The specific pieces of personal information We collected
                    about You</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >If we sold Your personal information or disclosed Your
                    personal information for a business purpose, We will
                    disclose to You:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 67.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The categories of personal information categories
                    sold</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 67.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The categories of personal information categories
                    disclosed</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >The right to say no to the sale of Personal Data
                      (opt-out).</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to direct Us to not sell Your
                    personal information. To submit an opt-out request please
                    contact Us.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >The right to delete Personal Data.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right to request the deletion of Your
                    Personal Data, subject to certain exceptions. Once We
                    receive and confirm Your request, We will delete (and direct
                    Our Service Providers to delete) Your personal information
                    from our records, unless an exception applies. We may deny
                    Your deletion request if retaining the information is
                    necessary for Us or Our Service Providers to:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Complete the transaction for which We collected the
                    personal information, provide a good or service that You
                    requested, take actions reasonably anticipated within the
                    context of our ongoing business relationship with You, or
                    otherwise perform our contract with You.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegal activity, or prosecute
                    those responsible for such activities.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Debug products to identify and repair errors that impair
                    existing intended functionality.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Exercise free speech, ensure the right of another consumer
                    to exercise their free speech rights, or exercise another
                    right provided for by law.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Comply with the California Electronic Communications
                    Privacy Act (Cal. Penal Code § 1546 et. seq.).</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Engage in public or peer-reviewed scientific, historical,
                    or statistical research in the public interest that adheres
                    to all other applicable ethics and privacy laws, when the
                    information's deletion may likely render impossible or
                    seriously impair the research's achievement, if You
                    previously provided informed consent.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Enable solely internal uses that are reasonably aligned
                    with consumer expectations based on Your relationship with
                    Us.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Comply with a legal obligation.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Make other internal and lawful uses of that information
                    that are compatible with the context in which You provided
                    it.</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><strong style="box-sizing: border-box"
                    ><span style="font-family: 'helvetica neue'; color: #444444"
                      >The right not to be discriminated against.</span
                    ></strong
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&nbsp;You have the right not to be discriminated against
                    for exercising any of Your consumer's rights, including
                    by:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Denying goods or services to You</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Charging different prices or rates for goods or services,
                    including the use of discounts or other benefits or imposing
                    penalties</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Providing a different level or quality of goods or services
                    to You</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 45pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Suggesting that You will receive a different price or rate
                    for goods or services or a different level or quality of
                    goods or services</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Exercising Your CCPA Data Protection Rights</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >In order to exercise any of Your rights under the CCPA, and
                    if You are a California resident, You can contact Us:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >By email:&nbsp;admin1@myle.com</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Only You, or a person registered with the California
                    Secretary of State that You authorize to act on Your behalf,
                    may make a verifiable request related to Your personal
                    information.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Your request to Us must:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Provide sufficient information that allows Us to reasonably
                    verify You are the person about whom We collected personal
                    information or an authorized representative</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Describe Your request with sufficient detail that allows Us
                    to properly understand, evaluate, and respond to it</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We cannot respond to Your request or provide You with the
                    required information if We cannot:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >Verify Your identity or authority to make the request</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >And confirm that the personal information relates to
                    You</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We will disclose and deliver the required information free
                    of charge within 45 days of receiving Your verifiable
                    request. The time period to provide the required information
                    may be extended once by an additional 45 days when
                    reasonable necessary and with prior notice.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Any disclosures We provide will only cover the 12-month
                    period preceding the verifiable request's receipt.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >For data portability requests, We will select a format to
                    provide Your personal information that is readily useable
                    and should allow You to transmit the information from one
                    entity to another entity without hindrance.</span
                  >
                </p>

                <h2
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.65rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Do Not Sell My Personal Information</span
                  >
                </h2>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You have the right to opt-out of the sale of Your personal
                    information. Once We receive and confirm a verifiable
                    consumer request from You, we will stop selling Your
                    personal information. To exercise Your right to opt-out,
                    please contact Us.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The Service Providers we partner with (for example, our
                    analytics or advertising partners) may use technology on the
                    Service that sells personal information as defined by the
                    CCPA law. If you wish to opt out of the use of Your personal
                    information for interest-based advertising purposes and
                    these potential sales as defined under CCPA law, you may do
                    so by following the instructions below.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Please note that any opt out is specific to the browser You
                    use. You may need to opt out on every browser that You
                    use.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Website</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can opt out of receiving ads that are personalized as
                    served by our Service Providers by following our
                    instructions presented on the Service:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The NAI's opt-out platform:&nbsp;<a
                      href="http://www.networkadvertising.org/choices/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.networkadvertising.org/choices/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The EDAA's opt-out platform&nbsp;<a
                      href="http://www.youronlinechoices.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://www.youronlinechoices.com/</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >The DAA's opt-out platform:&nbsp;<a
                      href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="
                        box-sizing: border-box;
                        transition: color 0.3s ease 0s;
                      "
                      ><span style="color: #444444"
                        >http://optout.aboutads.info/?c=2&amp;lang=EN</span
                      ></a
                    ></span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >The opt out will place a cookie on Your computer that is
                    unique to the browser You use to opt out. If you change
                    browsers or delete the cookies saved by your browser, You
                    will need to opt out again.</span
                  >
                </p>

                <h3
                  style="
                    margin-top: 15pt;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1.35rem;
                    line-height: inherit;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Mobile Devices</span
                  >
                </h3>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Your mobile device may give You the ability to opt out of
                    the use of information about the apps You use in order to
                    serve You ads that are targeted to Your interests:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out
                    of Ads Personalization&quot; on Android devices</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >&quot;Limit Ad Tracking&quot; on iOS devices</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You can also stop the collection of location information
                    from Your mobile device by changing the preferences on Your
                    mobile device.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >&quot;Do Not Track&quot; Policy as Required by California
                    Online Privacy Protection Act (CalOPPA)</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Our Service does not respond to Do Not Track signals.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >However, some third party websites do keep track of Your
                    browsing activities. If You are visiting such websites, You
                    can set Your preferences in Your web browser to inform
                    websites that You do not want to be tracked. You can enable
                    or disable DNT by visiting the preferences or settings page
                    of Your web browser.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Children's Privacy</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Your California Privacy Rights (California's Shine the
                    Light law)</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Under California Civil Code Section 1798 (California's
                    Shine the Light law), California residents with an
                    established business relationship with us can request
                    information once a year about sharing their Personal Data
                    with third parties for the third parties' direct marketing
                    purposes.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If you'd like to request more information under the
                    California Shine the Light law, and if You are a California
                    resident, You can contact Us using the contact information
                    provided below.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >California Privacy Rights for Minor Users (California
                    Business and Professions Code Section 22581)</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >California Business and Professions Code section 22581
                    allow California residents under the age of 18 who are
                    registered users of online sites, services or applications
                    to request and obtain removal of content or information they
                    have publicly posted.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >To request removal of such data, and if You are a
                    California resident, You can contact Us using the contact
                    information provided below, and include the email address
                    associated with Your account.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Be aware that Your request does not guarantee complete or
                    comprehensive removal of content or information posted
                    online and that the law may not permit or require removal in
                    certain circumstances.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Links to Other Websites</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >Our Service may contain links to other websites that are
                    not operated by Us. If You click on a third party link, You
                    will be directed to that third party's site. We strongly
                    advise You to review the Privacy Policy of every site You
                    visit.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We have no control over and assume no responsibility for
                    the content, privacy policies or practices of any third
                    party sites or services.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Changes to this Privacy Policy</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the &quot;Last updated&quot; date at the top of this
                    Privacy Policy.</span
                  >
                </p>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.</span
                  >
                </p>

                <h1
                  style="
                    margin-top: 18.75pt;
                    margin-right: 0cm;
                    margin-bottom: 11.25pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 2.125rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: black"
                    >Contact Us</span
                  >
                </h1>

                <p
                  style="
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 7.5pt;
                    margin-left: 0cm;
                    background: white;
                    box-sizing: border-box;
                    font-size: 1rem;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    orphans: 2;
                    text-align: start;
                    widows: 2;
                    -webkit-text-stroke-width: 0px;
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                    word-spacing: 0px;
                  "
                >
                  <span style="font-family: 'helvetica neue'; color: #444444"
                    >If you have any questions about this Privacy Policy, You
                    can contact us:</span
                  >
                </p>

                <p
                  class="MsoNormal"
                  style="
                    margin-left: 22.5pt;
                    text-indent: -18pt;
                    line-height: 200%;
                    background: white;
                  "
                >
                  <span
                    style="
                      font-size: 10pt;
                      line-height: 200%;
                      font-family: Symbol;
                      color: #444444;
                    "
                    >·<span style="font: 7pt 'Times New Roman'"
                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span
                  ><span dir="LTR"></span
                  ><span style="font-family: 'helvetica neue'; color: #444444"
                    >By email:&nbsp;admin1@myle.com</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
