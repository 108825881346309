<template src="./Success.html"></template>

<script>
  // Component Imports
  import VueElementLoading from 'vue-element-loading';
  // Scss Imports
  import "./Success.scss";

  export default {
    name: 'successModal',
    data() {
      return {
        hidePage: false,
        isLoading: false,
        loadStyle: {
          color: '#ffffff',
          'font-weight': 'bold',
          'letter-spacing': '1rem',
          'font-size': '18px'
        },
      }
    },
    components: {
      VueElementLoading
    },
    methods: {
      explore() {
        this.$emit('closemodal', true)
      }
    },
    created() {
      if (this.$route.params.status == 'canceled') {
        this.$router.push({
          name: 'error',
          params: {
            id: this.$route.params.id
          }
        });
      } else {
        this.hidePage = true;
      }
    },
    mounted() {
      if (this.$route.params.status == 'canceled') {
        return;
      }
    }
  }
</script>
