<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <!-- <div
                  class="col-md-6"
                  v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                > -->
            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-crimson btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>
            <!-- </div> -->

            <div
              class="col-md-6 mt-3"
              v-if="vlist.includes(TicketsResult[selected].ticketCode)"
            >
              <label class="">Sizes</label>
              <select class="form-control" v-model="selectedSizes">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sizes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/NCR_Header_Banner.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: #ac1e2e">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  background-color: #ac1e2e;
                  color: white;
                "
              >
                58th North Central Region Conference
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 28px;
              "
            >
              The Columbus Tuskegee Alumni Club is hosting the 58th North
              Central Region Conference in Columbus, Ohio - The Capitol City!
            </p>

            <p
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Friday, August 4 2023 (11am-7pm) <br />
              Saturday, August 5, 2023 (9am-11pm)<br />
              Location: Embassy Suites by Hilton Columbus Airport <br />
              2886 Airport Drive
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <span
                  class="bolded"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 22px;
                  "
                  >Key Events:</span
                >
                <br /><br />
                Opening Session - Friday, August 4, 2023 @ 2pm
                <br />
                Tuskegee Information Sharing Panel - Saturday, August 5, @
                9:30am
                <br />
                George W. Carver Luncheon - Saturday, August 5, @ 11:30am
                <br />
                Crimson & Gold Banquet - Saturday, August 5, 2023 @ 7:30pm
              </p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                While we will be busy taking care of important alumni business,
                we will still take time to enjoy our guest speakers, the event
                space, a jazz trio (Copacetic | JazzColumbus.com ), an excursion
                to Crossroads Plaza (owned by the Tuskegee Alumni Housing
                Foundation), each other, and conclude the conference with the
                Crimson & Gold Banquet. The conference will be informative,
                inspiring, engaging, and fun!
                <br />
                <br />
                <span
                  class="bolded"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 700;
                    font-size: 22px;
                  "
                  >Theme:</span
                >
                Remembering our Greatness and Celebrating our Excellence
                <br />
                <br />
                Conference Purpose: To be educational, Tuskegee University
                information sharing, collaborative, and a scholarship fundraiser
                <br />
                <br />
                <!--
                <br />
                <br />
                Hotel Accommodations <br />
                Embassy Suites by Hilton Columbus Airport <br />
                2886 Airport Drive <br />
                Columbus, OH 43219 <br />
                Phone:
                <a href="tel:+16145360500"> 614-536-0500</a>
                <br />
                <br />
                For Group Rate - Click this
                <a
                  href="https://www.hilton.com/en/attend-my-event/cmhates-90m-0d1fdf9b-7a38-418f-ac34-c56baf1e87fb/"
                  target="_blank" rel="noopener noreferrer"
                >
                  link</a
                >
                to reserve your room -->
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 32px;
                "
              >
                State of Tuskegee University
              </h3>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Rolundus Rice Image.jpg"
                style="
                  width: 20%;
                  max-width: 20%;
                  height: auto;
                  border: 5px solid #000;
                "
              /><br />
              Rolundus R. Rice, Ph.D.<br />
              Chief Operations Officer<br />
              Office of the President <br /><br />
            </p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 32px;
                "
              >
                Tuskegee University Information Sharing Panelist
              </h3>
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
        <div class="col-md-4">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/S.Keith Hargrove Image.jpg"
                style="
                  width: 40%;
                  max-width: 40%;
                  height: auto;
                  border: 5px solid #000;
                "
              /><br />
              S. Keith Hargrove, Ph.D., MBA<br />
              Provost & Sr. Vice President for Academic Affairs<br />
              Office of the Provost<br />
            </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Courtney Griffin Image.jpeg"
                style="
                  width: 40%;
                  max-width: 40%;
                  height: auto;
                  border: 5px solid #000;
                "
              /><br />
              Dr. Courtney Griffin<br />
              Director of Admissions and Recruiting<br /><br />
            </p>
          </div>
        </div>

        <div class="col-md-4">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Phillip Howard Image.jpg"
                style="
                  width: 40%;
                  max-width: 40%;
                  height: auto;
                  border: 5px solid #000;
                "
              /><br />
              Phillip Howard <br />
              Vice President for Advancement<br /><br />
            </p>
          </div>
        </div>

        <br /><br />

        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 32px;
                "
              >
                Guest Speakers
              </h3>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Austin J Williams.jpg"
                style="width: 20%; max-width: 20%; height: auto"
              /><br />
              LUNCHEON SPEAKER<br />
              CEO of Good-Natured Life <br />
              Author, & DifferenceMaker<br />
              Leader Expert
              <br /><br />
            </p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 28px;
                "
              >
                ABOUT AUSTIN J. FRANKLIN
              </h3>
            </div>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <div class="col-md-12">
          <div class="download-content">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Austin J. Franklin is the CEO and Co-founder of Good-Natured Life.
              The company was established in 2015 when he was 20 years old and a
              junior at Hampden-Sydney College. The mission of this educational
              company is to spread kindness, friendliness, and patience (KFP)
              and to uplift minds across the world.
              <br /><br />

              Franklin completed Harvard Business School's prestigious pre-MBA
              program. Also, he received a Master of Business Administration
              (MBA) from Rollins College's Crummer Graduate School of Business.

              <br /><br />
              Austin Franklin has had the opportunity to bring the 'information
              and inspiration' to thousands of people throughout the U.S.A., the
              island of Curaçao, and Africa. Also, he has been invited to
              conduct leadership trainings for numerous executives and
              leadership teams including those in healthcare, education,
              transportation, hospitality, law, construction, and government.

              <br /><br />
              Franklin is a nationally acclaimed keynote speaker and consultant.
              Additionally, he is the author of the following works - Wake,
              Rise, and Smile and The DifferenceMaker Leader.

              <br /><br />
              He has served as a three-time commencement speaker before the age
              of 25, named one of Florida Black Expo's Top 20 Under 40, and was
              named as a "Leader Under 40 Changing Lives as a Community Pearls
              Award recipient. Franklin is a member of Alpha Phi Alpha
              Fraternity, Leadership Jacksonville - Next Generation, and the
              World Economic Forum's Global Shapers Community. He currently
              serves as an Advisory Board Member with AMIKids in Clay County,
              Florida and the Johnson Family YMCA.

              <br /><br />
              Franklin has been a guest on several radio and television shows.
              Also, he has appeared in CEOWORLD Magazine, Yahoo Finance, Voyage
              ATL, and Medium's Authority Magazine.

              <br /><br />

              Furthermore, his interview with NPR's StoryCorps podcast is
              archived at the Congress in Washington, D.C.
            </p>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Kenneth L. Parker Image.jpeg"
                style="
                  width: 30%;
                  max-width: 30%;
                  height: auto;
                  border: 5px solid #000;
                "
              /><br />
              BANQUET SPEAKER<br />
              Mr. Kenneth L. Parker<br />
              U.S. Department of Justice<br />
              US Attorney, Southern District of Ohio<br />
            </p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 28px;
                "
              >
                ABOUT KENNETH L. PARKER
              </h3>
            </div>
          </div>
        </div>
        <br /><br />
        <br /><br />
        <div class="col-md-12">
          <div class="download-content">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Kenneth L. Parker was sworn in on November 23, 2021, as the 45th
              United States Attorney for the Southern District of Ohio.
              President Joseph Biden nominated Parker on September 28, 2021, and
              the United States Senate unanimously approved his confirmation on
              November 19, 2021.

              <br /><br />

              Parker was appointed an Assistant United States Attorney in 1999,
              designated to the Organized Crime Drug Enforcement Task Force
              Division of the Office. He would eventually be named the
              District’s Criminal Chief, serving from 2011 through 2019. Prior
              to being named as the Criminal Chief, Parker served as Chief of
              the Organized Crime Drug Enforcement Task Force from 2010-2011.
              Parker served also as a judicial law clerk for the late Honorable
              S. Arthur Spiegel, United States District Court for the Southern
              District of Ohio, and as an Adjunct Professor at the University of
              Cincinnati College of Law.

              <br /><br />
              Parker is a lifelong resident of Cincinnati, Ohio. He graduated
              from Tuskegee University in 1994 and earned his law degree from
              Indiana University School of Law (Bloomington) in 1997.

              <br /><br />
              As the United States Attorney, Parker is the chief federal law
              enforcement officer for the Southern District of Ohio, serving
              more than five million residents in 48 of the 88 counties in Ohio.
              Parker oversees the investigation and litigation of all criminal
              and civil cases brought on behalf of the United States in the
              Southern District of Ohio as well as those matters that may need
              to be defended on behalf of the nation. Parker supervises an
              office of approximately 65 Assistant United States Attorneys and
              60 support staff personnel, in three offices located in
              Cincinnati, Dayton and Columbus. Those cases include violations
              involving narcotics, public corruption, civil rights, financial,
              cybercrime, firearms and gun violence, healthcare fraud, child
              exploitation, national security threats, immigration, human
              trafficking and tax crimes.

              <br /><br />
              Throughout his career, Parker has always believed in maintaining a
              strong connection to the community. Prior to its phasing out,
              Parker assisted with the Department of Justice’s Weed and Seed
              efforts, which was a residentially driven initiative to focus on
              and address community issues such as substance abuse, drug
              trafficking and repeat violent offenders while simultaneously
              revitalizing the areas through prevention and intervention
              strategies. The Office, the Federal Executive Board of Greater
              Cincinnati as well as the City of Cincinnati recognized him for
              his tireless dedication to the program.

              <br /><br />

              Parker has always dedicated a substantial amount of his free time
              to speak to and mentor youth in the community, encouraging them to
              stay away from drugs and other issues that cause problems. He
              firmly believes that crime prevention is an essential part of
              enforcement. More specifically, Parker often makes presses that
              “an ounce of prevention is worth a pound of cure” when it comes to
              serving as a federal prosecutor.

              <br /><br />
              Parker has served as president of the Cincinnati-Northern Kentucky
              Chapter of the Federal Bar Association and the president of the
              Black Lawyers Association of Cincinnati. He is a proud alumnus of
              the Summer Work Experience in Law program (SWEL), where he would
              later serve as Chair of its Board. The SWEL program seeks to
              increase the number of African Americans in the legal profession.
              During his service as Board Chair, Parker worked to expand the
              program from Cincinnati to the cities of Hamilton, Dayton and
              Columbus, Ohio and Louisville, Kentucky.

              <br /><br />

              Parker resides in Cincinnati with his wife and twin daughters.
            </p>
          </div>
        </div>

        <br /><br />
        <br /><br />

        <div class="col-md-12">
          <div class="download-content">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 20px;
              "
            >
              Conference Registration Information
            </p>

            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Registration Fee includes:<br />
              ● General Admission<br />
              ● George W. Carver Luncheon<br />
              ● Crimson & Gold Banquet<br />
              ● T-Shirt (select size during registration)<br />
            </p>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
              <br />
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                "
              >
                EARLY BIRD TICKET PRICES END ON JUNE 30TH, 2023
              </h3>
              <br /><br />
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                GENERAL REGISTRATION PRICES BEGIN ON JULY 1ST, 2023
                <br />
                Individual Registration - $250, Registration + Luncheon Guest -
                $295<br />
                Registration + Banquet Guest - $305, Registration + Luncheon &
                Banquet Guest - $350<br />
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-crimson white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-crimson"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                NCR Conference T-Shirt
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in shirtTick(TicketsResult)"
              >
                <div class="card-header MYLE-crimson white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-crimson"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                VENDOR TABLE
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in vendorTableoptions(TicketsResult)"
              >
                <div class="card-header MYLE-crimson white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-crimson"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                DONATIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in groupTickets(TicketsResult)"
              >
                <div class="card-header MYLE-crimson white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-crimson"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center mt-3">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                You will have the option to increase the donation quantity to
                increase your contribution amount.
              </h3>
            </div>

            <div class="text-center mt-3">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                "
              >
                *Direct Donations are tax-deductible. Donation letters will be
                provided for donations above $250.
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 20px;
              "
            >
              Hotel Accommodations
            </p>

            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Embassy Suites by Hilton Columbus Airport<br />
              2886 Airport Drive<br />
              Columbus, OH 43219, USA<br />
              Phone: <a href="tel:+18003622779">1-800-EMBASSY </a><br />
              Group Code: CES90M<br />
              For Group Rate - Click this
              <a
                href="https://www.hilton.com/en/attend-my-event/cmhates-90m-0d1fdf9b-7a38-418f-ac34-c56baf1e87fb/"
              >
                link</a
              >
              to reserve your room
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/north_central_mocks.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mt-0 mb-2"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
          "
        >
          Get ready for The 58th North Central Region Conference with MYLE
        </p>

        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          For your convenience, we have partnered with MYLE as our ticket
          provider. MYLE - Make Your Life Entertaining is a Skegee Alumni
          Co-Owned Event and Entertainment platform. Learn more today:
          <br /><br />
          • Buy your event tickets with MYLE
          <br />
          • Tickets are available on the app in the Accounts/Tickets section for
          easy access including Apple Wallet
          <br />
          • Help the Columbus Alumni Chapter earn referral points – use referral
          code “CTAC” during account set-up
          <br />
          • Receive important updates before and during the event
          <br />
          • Get around Columbus seamlessly using the MYLE app for transportation
          options including Waze/Apple Maps/Google Maps/Uber and Lyft –
          integrated into MYLE mobile.
          <br />
          • Find other fun things to do in Columbus or on the way!
          <br />
          <br />
          We look forward to seeing you soon.
          <br />
          #JustMYLEit and #makeyourlifeentertaining
          <br />
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      vlist: [
        "tk_LcUjVGN9qYd08B0",
        // "tk_V7kuKHhM6v38ux2",
        "tk_CtmrkLHrAQbkgfp",
        "tk_K5zP2qgWAO9j8ko",
        "tk_R6PGlE1zizwGS30",
        "tk_qZ88cndFObCg3iQ",
        // "tk_2dnRXWJg7oegCas",
      ],
      vendorlist: ["tk_WYo4VS5DGSY6RJX"],
      shirtTicketslist: [
        // "tk_V7kuKHhM6v38ux2",
        "tk_LcUjVGN9qYd08B0",
      ],
      individualsData: [
        // "tk_2dnRXWJg7oegCas",
        "tk_CtmrkLHrAQbkgfp",
        "tk_K5zP2qgWAO9j8ko",
        "tk_R6PGlE1zizwGS30",
        "tk_qZ88cndFObCg3iQ",
        "tk_285BCkp2dr1cKU9",
        "tk_xQJNRMjF5wcIm9b",
      ],
      groupData: [
        "tk_zhaWBw9IEU40iff",
        "tk_4LB3cYmd9yGNcRD",
        "tk_1vv5pXeRZ4UTVEv",
        "tk_sl32qmPag6SiPJU",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_2dnRXWJg7oegCas: {
        //   messages: [
        //     "General Admission",
        //     "George W. Carver Luncheon",
        //     "Crimson & Gold Banquet",
        //     "T-Shirt",
        //     "Ticket Price: $200",
        //   ],
        // },

        tk_CtmrkLHrAQbkgfp: {
          messages: [
            "General Admission",
            "George W. Carver Luncheon",
            "Crimson & Gold Banquet",
            "T-Shirt",
            "Ticket Price: $250",
          ],
        },

        tk_K5zP2qgWAO9j8ko: {
          messages: [
            "General Admission",
            "George W. Carver Luncheon + 1",
            "Crimson & Gold Banquet",
            "T-Shirt",
            "Ticket Price: $295",
          ],
        },

        tk_R6PGlE1zizwGS30: {
          messages: [
            "General Admission",
            "George W. Carver Luncheon",
            "Crimson & Gold Banquet + 1",
            "T-Shirt",
            "Ticket Price: $305",
          ],
        },

        tk_qZ88cndFObCg3iQ: {
          messages: [
            "General Admission",
            "George W. Carver Luncheon + 1",
            "Crimson & Gold Banquet + 1",
            "T-Shirt",
            "Ticket Price: $350",
          ],
        },

        tk_285BCkp2dr1cKU9: {
          messages: ["Luncheon Only", "Guest Ticket", "Ticket Price: $45"],
        },

        tk_xQJNRMjF5wcIm9b: {
          messages: ["Banquet Only", "Guest Ticket", "Ticket Price: $55"],
        },

        tk_zhaWBw9IEU40iff: {
          messages: ["Ticket Price: $50"],
        },

        tk_4LB3cYmd9yGNcRD: {
          messages: ["Ticket Price: $250"],
        },

        tk_1vv5pXeRZ4UTVEv: {
          messages: ["Ticket Price: $750"],
        },

        tk_sl32qmPag6SiPJU: {
          messages: ["Ticket Price: $1000"],
        },

        tk_WYo4VS5DGSY6RJX: {
          messages: ["Ticket Price: $200"],
        },

        // tk_V7kuKHhM6v38ux2: {
        //   messages: ["Ticket Price: $20"],
        // },

        tk_LcUjVGN9qYd08B0: {
          messages: ["Ticket Price: $20"],
        },
      },

      priceIds: {
        // tk_2dnRXWJg7oegCas: "price_1MotRrD0vTZ4QB9cADiVBJAa",
        tk_CtmrkLHrAQbkgfp: "price_1MotS6D0vTZ4QB9cnTsXXFra",
        tk_K5zP2qgWAO9j8ko: "price_1Mt9wDD0vTZ4QB9cl79ced5B",
        tk_R6PGlE1zizwGS30: "price_1Mt9zaD0vTZ4QB9cb7p1oZNl",
        tk_qZ88cndFObCg3iQ: "price_1Mt9zPD0vTZ4QB9cjvae8wmq",
        tk_285BCkp2dr1cKU9: "price_1MotUVD0vTZ4QB9cUywtlkd1",
        tk_xQJNRMjF5wcIm9b: "price_1MotjLD0vTZ4QB9cix5qdYCi",
        tk_zhaWBw9IEU40iff: "price_1MsYmdD0vTZ4QB9cmCMB2uWE",
        tk_4LB3cYmd9yGNcRD: "price_1MsYnpD0vTZ4QB9clpXNDxKL",
        tk_1vv5pXeRZ4UTVEv: "price_1MsYqeD0vTZ4QB9c6H9oE1dj",
        tk_sl32qmPag6SiPJU: "price_1MsYxbD0vTZ4QB9cAgB94tkR",
        tk_WYo4VS5DGSY6RJX: "price_1MotjoD0vTZ4QB9cHMp97fY9",
        // tk_V7kuKHhM6v38ux2: "price_1MottND0vTZ4QB9c12GmX1Yg",
        tk_LcUjVGN9qYd08B0: "price_1MsYxcD0vTZ4QB9cqbW6n2pr",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    shirtTick(list) {
      return list.filter((t) => this.shirtTicketslist.includes(t.ticketCode));
    },
    vendorTableoptions(list) {
      return list.filter((t) => this.vendorlist.includes(t.ticketCode));
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };
      if (vm.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo = {
          shirtSize: this.selectedSizes,
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_NorthCentralRegionConference_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            // tk_2dnRXWJg7oegCas: null,
            tk_CtmrkLHrAQbkgfp: null,
            tk_K5zP2qgWAO9j8ko: null,
            tk_R6PGlE1zizwGS30: null,
            tk_qZ88cndFObCg3iQ: null,
            tk_285BCkp2dr1cKU9: null,
            tk_xQJNRMjF5wcIm9b: null,
            tk_zhaWBw9IEU40iff: null,
            tk_4LB3cYmd9yGNcRD: null,
            tk_1vv5pXeRZ4UTVEv: null,
            tk_WYo4VS5DGSY6RJX: null,
            // tk_V7kuKHhM6v38ux2: null,
            tk_LcUjVGN9qYd08B0: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 58th North Central Region Conference",

    meta: [
      {
        name: "description",
        content: "MYLE - 58th North Central Region Conference",
      },

      {
        name: "keywords",
        content: "MYLE - 58th North Central Region Conference",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/58th-north-central-region-conference",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
