<template>
  <section id="business" class="promo-section MYLE-lite-green">
    <div class="container p-5">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="section-heading">
            <h2 class="text-center text-white">MYLE AMBASSADOR APPLICATION</h2>
            <p class="lead text-center mt-3 mb-6 text-white">
              We are actively seeking MYLE advocates. Are you<br />interested in
              becoming a MYLE Ambassador? Click the button below and submit the
              application form.
            </p>
            <div class="text-center">
              <a href="/ambassador" class="btn solid-btn">Apply Now</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "KeyFeatures"
};
</script>
