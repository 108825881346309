<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_100_black_men_2nd.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 45px;
              "
            >
              Welcome<br />
              2024 Scholarship Gala Weekend
            </p>
          </div>

          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            10 <br />
            Aug
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy"
            target="_self"
          >
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Scholarship Gala Benefitting the Leadership Academy
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              August 10, 2024, 6:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy"
                  class="btn solid-btn-100blackmen mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a
            href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy"
            target="_self"
          >
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Scholarship_Gala_2nd.jpg"
                alt="Saturday Event"
                class="w-60 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>
      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            11 <br />
            Aug
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/jazz-brunch" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Jazz Brunch
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              August 11, 2024, 11:00am
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/jazz-brunch"
                  class="btn solid-btn-100blackmen mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/jazz-brunch" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_jazz_brunch_2nd.jpg"
                alt="Saturday Event"
                class="w-60 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />

      <div class="row">
        <div class="col-md-1 col-lg-1 align-items-center"></div>
        <div class="col-md-2 col-lg-2 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 300;
              font-size: 22px;
            "
          >
            10 - 11 <br />
            Aug
          </p>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/souvenir-program-booklet" target="_self">
            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: black;
              "
            >
              Souvenir Program Booklet
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 18px;
                color: black;
              "
            >
              August 10 - 11, 2024, 06:00pm - 03:00pm
            </p>
            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/souvenir-program-booklet"
                  class="btn solid-btn-100blackmen mb-1 text-center"
                  target="_self"
                  >Get Tickets
                </a>
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-4 col-lg-4 align-items-center">
          <a href="https://myle.com/souvenir-program-booklet" target="_self">
            <div class="justify-content-center d-flex">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_100_black_men_2nd.png"
                alt="Saturday Event"
                class="w-60 img-fluid"
              />
            </div>
          </a>
        </div>

        <div class="col-md-1 col-lg-1 align-items-center"></div>
      </div>

      <br />
      <br />
      <br />

      <div class="section-heading mb-2 p-3" style="background: #121211">
        <div class="text-center">
          <a
            href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy-donations"
            class="btn solid-btn-100blackmen-inverted mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Donations</a
          >

          <a
            href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy-silent-auction"
            class="btn solid-btn-100blackmen-inverted ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Silent Auction</a
          >
        </div>
      </div>

      <!-- <div class="row">
        <div class="container">
          <div class="row w-100 m-0 text-center promo-section MYLE-green">
            <div class="col-lg-12 col-md-12">
              <div class="mt-3">
                <div class="section-heading mb-3">
                  <h5 class="text-center text-white">
                    2023 Scholarship Gala Weekend
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <video-embed
            css="embed-responsive-16by9"
            src="https://youtu.be/3-LfkpFV2JE"
          ></video-embed>
        </div>
      </div> -->

      <!-- <div class="section-heading mb-2 mt-5">
        <div class="text-center">
          <a
            href="https://myle.com/scholarship-gala-donations"
            class="btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Donations</a
          >

          <a
            href="https://myle.com/scholarship-gala-silent-auction"
            class="btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Silent Auction</a
          >
        </div> -->
      <!-- </div> -->

      <div
        class="row align-items-center justify-content-between test mt-5"
        style="margin: auto"
      >
        <video-embed
          css="embed-responsive-16by9"
          src="https://youtu.be/fqKX-YY2X8M?si=lx3T15EzZRq-kpxy"
        ></video-embed>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12 mt-5">
        <div class="mt-3">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
              <div class="col-md-6 col-lg-6 align-items-center">
                <a
                  href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div class="">
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                      alt="Saturday Event"
                      class="w-100 img-fluid"
                    />
                  </div>
  
                  <p
                    class="text-center lead-heading ml-3 mb-0"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 18px;
                    "
                  >
                    For The Kings | Juneteenth + Father's Day Brunch
                  </p>
                  <p
                    class="text-center lead-heading ml-3 mb-3"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 300;
                      font-size: 16px;
                    "
                  >
                    Sun, Jun 19, 12:00 PM
                  </p>
                </a>
              </div>
            </div> -->
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
    };
  },
  components: {
    // NavBar,
    // SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - 2024 Scholarship Gala Weekend",

    meta: [
      {
        name: "description",
        content: "MYLE - 2024 Scholarship Gala Weekend",
      },

      {
        name: "keywords",
        content: "MYLE - 2024 Scholarship Gala Weekend",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/2024-scholarship-gala-weekend",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
