<template>
  <section class="download-section ptb-50 background-img gray-light-bg">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-8">
          <div class="download-content">
            <h2>MYLE + Ticketing</h2>
            <p class="lead">
              MYLE has a ticketing solution that helps you sell more tickets,
              get more exposure, and generate more revenue.
            </p>

            <a href="https://myle.com/myle-ticketing" class="btn solid-btn mt-2"
              >Learn More</a
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="download-img">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/sample_ticket.png"
              alt="ticket"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Download",
};
</script>
