<template>
  <div>
    <nav-bar />
    <!-- text -->
    <div style="margin-top: 80px" class="px-5 mx-auto">
      <p class="text-center font-weight-bold">
        Make Your Life Entertaining, Inc. (MYLE)
        <br />
        Data Integrity and Governance Policy
      </p>

      <h4>Introduction</h4>
      <p>
        This Data Integrity and Governance Policy outlines the principles and
        guidelines for managing data at Make Your Life Entertaining, Inc.
        (MYLE). It is designed to ensure data security, integrity,
        accessibility, and compliance with relevant data protection regulations.
        All employees, contractors, and third-party vendors who handle data on
        behalf of MYLE must adhere to this policy.
      </p>

      <div>
        <h3>Data Governance</h3>
        <h4>2.1 Data Governance Framework</h4>
        <p>
          MYLE will establish a data governance framework with clearly defined
          roles and responsibilities for data management. The Data Governance
          Board will oversee data-related decisions, policies, and processes.
        </p>
      </div>

      <div>
        <h4>2.2 Data Policies</h4>
        <p>
          MYLE will develop comprehensive data policies that cover data
          collection, storage, access, sharing, retention, and disposal. These
          policies will be reviewed regularly to ensure they align with best
          practices and relevant regulations.
        </p>
      </div>

      <div>
        <h4>2.3 Data Privacy and Consent</h4>
        <p>
          MYLE will comply with data protection regulations, such as GDPR, CCPA,
          and other applicable laws. Explicit consent will be obtained from
          customers for data processing and sharing, and customers will have the
          right to access, correct, and delete their data.
        </p>
      </div>

      <div>
        <h4>2.4 Data Access and Permissions</h4>
        <p>
          Access to data will be granted based on the principle of least
          privilege. Role-based access controls (RBAC) will be implemented to
          manage permissions effectively, and access to sensitive data will be
          restricted to authorized personnel only.
        </p>
      </div>

      <div>
        <h4>2.5 Employee Responsibilities</h4>

        <p>
          All employees will be educated about data governance policies and
          their roles in maintaining data integrity and security. Employees must
          undergo regular data management training to stay informed about best
          practices and regulatory changes.
        </p>
      </div>

      <div>
        <h4>2.6 External Vendor Management</h4>

        <p>
          Third-party vendors handling data on behalf of MYLE must adhere to
          similar data protection and security standards. Contractual agreements
          with external partners will include provisions for data protection and
          compliance.
        </p>
      </div>

      <div>
        <h3>Data Collection and Storage</h3>

        <h4>3.1 Data Types</h4>

        <p>
          MYLE will clearly identify the types of data collected, including
          customer information, event details, ticket sales, and analytics data.
          The data classification system will distinguish between different
          levels of sensitivity.
        </p>
      </div>

      <div>
        <h4>3.2 Secure Data Storage</h4>

        <p>
          MYLE will implement secure and reliable data storage systems that
          comply with industry standards for data protection. Cloud-based
          solutions with encryption and access controls will be considered for
          data storage.
        </p>
      </div>

      <div>
        <h4>3.3 Data Quality and Validation</h4>

        <p>
          Data quality standards will be established to ensure the accuracy and
          reliability of information. Data validation processes will be
          implemented to ensure incoming data is complete and consistent.
        </p>
      </div>

      <div>
        <h3>Data Security</h3>

        <h4>4.1 Robust Security Measures</h4>

        <p>
          MYLE will implement robust security measures to protect data from
          unauthorized access, breaches, and cyber-attacks. Encryption will be
          used for sensitive data, and strong password policies will be
          enforced.
        </p>
      </div>

      <div>
        <h4>4.2 Multi-factor Authentication</h4>
        <p>
          Multi-factor authentication will be required for accessing critical
          systems to enhance security.
        </p>
      </div>

      <div>
        <h4>4.3 Security Audits and Vulnerability Assessments</h4>
        <p>
          Regular security audits and vulnerability assessments will be
          conducted to identify and address potential security risks.
        </p>
      </div>

      <div>
        <h3>Data Retention and Disposal</h3>

        <h4>5.1 Data Retention Periods</h4>

        <p>
          Data retention periods will be defined based on legal requirements and
          business needs. Data will not be retained for longer than necessary.
        </p>
      </div>

      <div>
        <h4>5.2 Regular Data Review</h4>
        <p>
          Regular data reviews will be conducted to identify and delete data
          that is no longer required, reducing the risk of data breaches.
        </p>
      </div>

      <div>
        <h3>Backup and Disaster Recovery</h3>

        <h4>6.1 Data Backup</h4>

        <p>
          Regular data backups will be performed to ensure data can be recovered
          in case of hardware failure, natural disasters, or other emergencies.
        </p>
      </div>

      <div>
        <h4>6.2 Disaster Recovery Plan</h4>
        <p>
          A comprehensive disaster recovery plan will be created and updated
          regularly to minimize data loss and downtime in the event of a
          disaster.
        </p>
      </div>

      <div>
        <h3>Data Analytics and Reporting</h3>

        <h4>7.1 Leveraging Data Analytics</h4>

        <p>
          Data analytics tools will be used to gain valuable insights from the
          collected data, enhancing MYLE's event pages and ticketing services.
        </p>
      </div>

      <div>
        <h4>7.2 Monitoring KPIs</h4>
        <p>
          Reports and dashboards will be created to monitor key performance
          indicators (KPIs) and track the success of events and ticketing
          services.
        </p>
      </div>

      <div>
        <h3>Compliance and Review</h3>

        <h4>8.1 Regular Auditing</h4>

        <p>
          Regular audits of data management practices will be conducted to
          identify areas for improvement and ensure ongoing compliance with data
          regulations and internal policies.
        </p>
      </div>

      <div>
        <h4>8.2 Policy Review</h4>
        <p>
          This Data Integrity and Governance Policy will be reviewed
          periodically to ensure it remains up-to-date and effective.
        </p>

        <p>
          By adhering to this Data Integrity and Governance Policy, MYLE aims to
          safeguard customer data, optimize data analytics processes, and
          maintain a high level of trust with its clients.
        </p>
      </div>
    </div>

    <!-- text -->

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
<style scoped>
.heading {
  font-size: 0.75rem;
  font-weight: bold;
}

li {
  list-style-type: disc;
  margin-left: 2rem;
}
</style>
