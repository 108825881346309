<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_GEAA_BI_ANNUAL_KAPPA_RETREAT.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 40px;
              "
            >
              GEAA BI-ANNUAL KAPPA RETREAT
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 20px;
                "
              >
                The Gamma Epsilon Alumni Association celebrate our 2022
                Bi-Annual Kappa Retreat in Atlanta, Ga from July 22nd thru July
                24th, 2022.
                <br />

                We welcome you to join us for a weekend of fellowship and
                fraternizing.
                <br />
                Family and Friends are welcome to all public events.
              </p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading text-center mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 30px;
                "
              >
                Schedule of Activities
              </p>
              <hr />
              <br />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container">
          <p
            class="lead-heading text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Thursday Activity -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Welcome to Atlanta Nupe Fellowship
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            6pm-12pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            The Monticello
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href=" https://myle.com/event.php?id=SuK88vnFmxARha"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/Welcome%20To%20Atlanta%20Nupe%20Fellowship/SuK88vnFmxARha"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Friday Activities -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Golf Tournament
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            8am-1pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            City Club Marietta
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href=" https://myle.com/event.php?id=grcj6ZzT5suH4Q"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Golf%20Tournament/grcj6ZzT5suH4Q"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Business Meeting & Fraternizer
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            6pm-Until
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Registration @ 5pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Private Event - Passcode will be provided to access details
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=H7LTCxlu3UMHT7"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Business%20Mtg%20and%20Fraternizer/H7LTCxlu3UMHT7"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Saturday Activities -
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Retreat Cookout
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            11am-5pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Private Event - Passcode will be provided to access details
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=gdgukDLvDqrk0z"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Cookout/gdgukDLvDqrk0z"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Celebration After Party
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            8:11pm-12:11am
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Spice House - Midtown
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=BAe0gPETHzgXm1"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Celebration%20After%20Party/BAe0gPETHzgXm1"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Sunday Activity -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Farewell Breakfast
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            9am-12pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Renaissance Hotel
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=BPnUirCMTwRKr4"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/Farewell%20Breakfast/BPnUirCMTwRKr4"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
      <hr />
      <div class="row mt-5">
        <div class="container">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <h6
              class="mb-3 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 18px;
              "
            >
              DOWNLOAD THE MYLE APP TO ACCESS ALL EVENTS:
            </h6>

            <h6
              class="mt-4 mb-2 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              How to Set Up MYLE Account
            </h6>

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >1. Visit the iOS or Google Play Store or Tap buttons below </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >2. Search MYLE Events (you will see events curated for you) </h8
            ><br />
            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >3. Download the app </h8
            ><br />
            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              4. Tap Create Account at the bottom of the page </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              5. Create New MYLE Account (remember your credentials)</h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              6. Referral Code - Enter GEAA </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              7. When the OTP code is sent to your email, check your spam folder
              if necessary </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              8. Once your account is set up, search GEAA for non-private
              functions. </h8
            ><br /><br />

            <h6
              class="mt-4 mb-2 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Note: A private event passcode will be provided through the
              GroupMe chat. Also, you will receive a custom link that will
              provide you with access to all retreat activities, which are
              available on the MYLE App.
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="d2"></div> -->
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      dict: {},
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  methods: {
    datad2() {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const zx = [];
      const rowss = [
        "X",
        "W",
        "V",
        "U",
        "T",
        "S",
        "R",
        "Q",
        "P",
        "O",
        "N",
        "M",
        "L",
        "K",
        "J",
        "H",
        "G",
        "F",
        "E",
      ];
      const rowss1 = ["L", "K", "J", "H", "G", "F", "E"];
      const rowss2 = ["D", "C", "B", "A", "G", "F", "E"];
      // red top 200 seats
      let x = 350;
      let xy = 0;
      for (let j = 0; j < 12; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([8, 22, 36, 50].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: i + 1,
            y: j * 15 + xy,
            type: "rect",
            fill: "red",
            stroke: "white",
          });
        }
      }
      // red left 100
      x = 100;
      xy = 200;

      for (let j = 0; j < 21; j++) {
        x = 0;
        for (let i = 0; i < 30; i++) {
          if ([8, 22, 36, 50].includes(i)) {
            zx.push({
              y: (i + 1) * 15 + x + xy,
              width: 15,
              height: 15,
              text: rowss[j],
              x: j * 15,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 20;
          }
          zx.push({
            y: (i + 1) * 15 + x + xy,
            width: 15,
            height: 15,
            text: i + 1,
            x: j * 15,
            type: "rect",
            fill: "red",
            stroke: "white",
          });
        }
      }

      // cyan/magenta Top 200
      x = 300;
      xy = 200;
      let skip = [
        [1, 2, 3],
        [1, 2, 3, 54, 55, 56],
        [1, 2, 3, 4, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 52, 53, 54, 55, 56],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56,
        ],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56,
        ],
      ];
      for (let j = 0; j < 7; j++) {
        x = 330;
        for (let i = 0; i < 56; i++) {
          if ([8, 21, 35, 49].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss1[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              y: j * 15 + xy,
              type: "rect",
              fill: i + 1 > 21 ? "magenta" : "cyan",
              stroke: "white",
            });
          }
        }
      }

      // cyan Top 200
      xy = 320;
      skip = [
        [1, 2, 3, 4, 5, 6, 49, 50, 51, 52, 43, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 54, 55, 56, 49, 50, 51, 52, 53, 43, 54, 55, 56],
        [1, 2, 53, 54, 55, 44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56],
        [1, 2, 3, 4, 43, 44, 45, 46, 47, 48, 49, 50, 51, 53, 52, 54, 55, 56],
      ];
      for (let j = 0; j < 4; j++) {
        x = 330;
        for (let i = 0; i < 56; i++) {
          if ([9, 21, 35, 47].includes(i) && j != 2 && j != 3) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss2[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }

          if ([9, 21, 35, 38].includes(i) && (j == 2 || j == 3)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10 + 15 * (i == 38 ? 9 : 0);
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              y: j * 15 + xy,
              type: "rect",
              fill: "cyan",
              stroke: "white",
            });
          }
        }
      }

      /// upper body ends

      /// lower body starts
      // cyan bottom 200
      xy = 820;
      skip = [
        [1, 2, 3, 4, 43, 44, 45, 46, 47, 48, 49, 50, 51, 53, 52, 54, 55, 56],
        [1, 2, 53, 54, 55, 44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 54, 55, 56, 49, 50, 51, 52, 53, 43, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 49, 50, 51, 52, 43, 53, 54, 55, 56],
      ];
      for (let j = 0; j < 4; j++) {
        x = 330;
        for (let i = 0; i < 56; i++) {
          if ([9, 21, 35, 47].includes(i) && j != 0 && j != 1) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }

          if ([9, 21, 35, 38].includes(i) && (j == 0 || j == 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss2[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10 + 15 * (i == 38 ? 9 : 0);
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              y: j * 15 + xy,
              type: "rect",
              fill: "cyan",
              stroke: "white",
            });
          }
        }
      }

      // cyan/magenta Top 200
      x = 300;
      xy = 900;
      skip = [
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56,
        ],
        [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 50, 51, 52, 53, 54, 55, 56,
        ],
        [1, 2, 3, 4, 5, 6, 7, 8, 52, 53, 54, 55, 56],
        [1, 2, 3, 4, 5, 6, 7, 8, 53, 54, 55, 56],
        [1, 2, 3, 4, 53, 54, 55, 56],
        [1, 2, 3, 54, 55, 56],
        [1, 2, 3],
      ];
      for (let j = 0; j < 7; j++) {
        x = 330;
        for (let i = 0; i < 56; i++) {
          if ([8, 21, 35, 49].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss1[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          if (!skip[j].includes(i + 1)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: i + 1,
              y: j * 15 + xy,
              type: "rect",
              fill: i + 1 > 21 ? "magenta" : "cyan",
              stroke: "white",
            });
          }
        }
      }

      // red bottom 400 seats
      x = 350;
      xy = 1020;
      for (let j = 0; j < 12; j++) {
        x = 350;
        for (let i = 0; i < 56; i++) {
          if ([8, 22, 36, 50].includes(i)) {
            zx.push({
              x: (i + 1) * 15 + x,
              width: 15,
              height: 15,
              text: rowss[j],
              y: j * 15 + xy,
              type: "rect",
              fill: "white",
              stroke: "white",
            });
            x += 10;
          }
          zx.push({
            x: (i + 1) * 15 + x,
            width: 15,
            height: 15,
            text: i + 1,
            y: j * 15 + xy,
            type: "rect",
            fill: "red",
            stroke: "white",
          });
        }
      }

      const zobj = {
        0: {
          texts: [1, 2, 3, 4, 5, 6, 7],
          x: 100,
          y: 1000,
          count: 7,
        },
        1: {
          texts: [1, 2, 3, 4, 5, 6, 7],
          x: 100,
          y: 1080,
          count: 7,
        },
        2: {
          texts: [1, 2, 3, 4, 5, 6, 7],
          x: 100,
          y: 1160,
          count: 7,
        },
        3: {
          texts: [1, 2, 3, 4, 5, 6, 7],
          x: 100,
          y: 1240,
          count: 7,
        },
      };
      for (let j = 0; j < 4; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x * (k + 1) + 300,
            y: zobj[j].y - 550,
            val: seat[8],
            distance: 10,
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }

      return [...zx];
    },
    drawseatsd2(listdata) {
      const vm = this;
      vm.dict = {};
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "2000px")
        .attr("height", "2000px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        if (v.type != "rect") {
          const originX = v.x + v.distance;
          const originY = v.y + v.ydistance;
          svg.append("circle").attr({
            cx: originX,
            cy: originY,
            r: innerCircleRadius,
            fill: v.selected ? "yellow" : "none",
            stroke: "black",
          });

          svg
            .append("text")
            .attr({
              x:
                originX -
                (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
              y:
                originY +
                (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
              "font-size": 15,
            })
            .text(v.text);

          for (let i = 0; i < 1200; i += v.val) {
            const chairOriginX1 =
              originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
            const chairOriginY1 =
              originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
            const chairOriginX =
              originX + (outerCircleRadius + 5) * Math.sin(i);
            const chairOriginY =
              originY - (outerCircleRadius + 5) * Math.cos(i);

            svg.append("circle").attr({
              cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
              cy: chairOriginY,
              r: 4,
              fill:
                vm.dict[v.text] &&
                vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                  ? vm.dictlist[v.text] || "pink"
                  : "lightgrey",
            });

            svg
              .append("text")
              .attr({
                x: chairOriginX1,
                y: chairOriginY1,
                stroke: "black",
                "font-size": 10,
              })
              .text(() => {
                return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
              });
          }
        }

        if (v.type == "rect") {
          svg.append("rect").attr({
            x: v.x,
            y: v.y,
            width: v.width,
            height: v.height,
            stroke: v.stroke,
            fill: v.fill,
          });

          svg
            .append("text")
            .attr({
              x: v.x + 3,
              y: v.y + 11,
              "font-size": 10,
            })
            .text(v.text);
        }
      });
    },
  },
  // created() {},
  mounted() {
    this.drawseatsd2([]);
  },
  // computed: {},

  metaInfo: {
    title: "MYLE - GEAA BI-ANNUAL KAPPA RETREAT",

    meta: [
      {
        name: "description",
        content: "GEAA BI-ANNUAL KAPPA RETREAT",
      },

      {
        name: "keywords",
        content: "GEAA BI-ANNUAL KAPPA RETREAT",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/geaa-bi-annual-kappa-retreat",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
