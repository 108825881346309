<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/vc_fest_edu_day_header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 45px;
              "
            >
              Fearless Fund VC Summit
            </p>
          </div>
          <hr />

          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5 text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Fearless Fund is investing 1 MILLION DOLLARS in Women of Color
                Businesses!
                <br />
                Additionally, we are investing in our community with information
                on the ins and outs of venture capital.
              </p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Our mission is to pour into our under-represented founders,
                creators, and innovators with a blueprint for success in
                entrepreneurship, generational wealth, and investing.
                <br />
                <br />
                Attendees will experience action-packed enrichment including
                informative panels, keynotes, and presentations from industry
                leaders, celebrities, multi-million dollar brands, and Fortune
                500 companies.
                <br />
                <br />
                The Fearless Fund Pitch Day attracts the top WOC Founders across
                the nation for a premiere showcase of promising startups
                pitching for a chance at an investment in their business.
                <br />
                <br />
                This day will consist of multiple rounds of pitches across CPG
                (Consumer Packaged Goods) and tech companies co-founded/founded
                by women-of-color entrepreneurs.
                <br />
                <br />
                This event truly is "Where Venture Meets Culture!" You may meet
                your best friend, business partner, co-founder, or investor in
                the room. You may awaken the breakthrough that you need for your
                business. Don't regret missing this!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row w-100 m-0 text-center promo-section MYLE-green">
          <div class="col-lg-12 col-md-12">
            <div class="mt-3">
              <div class="section-heading mb-3">
                <h5 class="text-center text-white">
                  Fearless Fund VC Summit 2022 Promo
                </h5>
              </div>
            </div>
          </div>
        </div>
        <video-embed
          css="embed-responsive-16by9"
          src="https://youtu.be/j41_yFOx1q0"
        ></video-embed>
      </div>

      <!-- <div class="row w-100 m-0 mt-5 text-center promo-section MYLE-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-3">
            <div class="section-heading mb-3">
              <h5 class="text-center text-white">
                Fearless Fund VC Summit Events
              </h5>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row mt-5">
        <div class="col-md-6 col-lg-6 align-items-center">
          <a
            href="https://myle.com/fearless-fund-vc-summit-education-day"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/vc_fest_edu_day_1_header.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>

            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Fearless Fund VC Summit - Education Day
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 16px;
              "
            >
              Thursday, Aug 25, 2022
            </p>
          </a>

          <div class="download-content mr-5 ml-5">
            <p
              class="lead-heading mb-3 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Get ready to learn the ins & outs of <br />VC, business,
              entrepreneurship, and fundraising with networking opportunities,
              grants, & giveaways!
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              This day is dedicated to educating the community about the ins and
              outs of venture capital. Our mission is to pour into our
              under-represented founders, creators, and innovators with a
              blueprint for success in entrepreneurship, generational wealth,
              and investing.
            </p>

            <div
              class="download-btn text-center animate__animated animate__fadeIn"
            >
              <a
                href="https://myle.com/fearless-fund-vc-summit-education-day"
                class="btn solid-btn"
                target="_blank"
                rel="noopener noreferrer"
                >Learn More</a
              >
            </div>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-6 justify-content-center align-items-center"
        >
          <a
            href=" https://myle.com/fearless-fund-vc-summit-pitch-day"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/vc_fest_edu_day_2_header.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>

            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Fearless Fund VC Summit - Pitch Day (Viewing)
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 16px;
              "
            >
              Friday, Aug 26, 2022
            </p>
          </a>

          <div class="download-content mr-5 ml-5">
            <p
              class="lead-heading mb-3 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 20px;
              "
            >
              Fearless Fund is investing<br />
              <b>1 MILLION DOLLARS</b><br />
              in Women of Color Businesses!
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              This day will consist of multiple rounds of pitches across CPG
              (Consumer Package Goods) and tech companies co-founded/founded by
              women-of-color entrepreneurs. The Fearless Fund Pitch Day attracts
              the top WOC Founders across the nation for a premiere showcase of
              promising startups pitching for a chance at an investment.
            </p>

            <div
              class="download-btn text-center animate__animated animate__fadeIn"
            >
              <a
                href="https://myle.com/fearless-fund-vc-summit-pitch-day"
                class="btn solid-btn"
                target="_blank"
                rel="noopener noreferrer"
                >Learn More</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading text-center mt-4">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 28px;
          "
        >
          Fearless Fund VC Summit
        </h2>
      </div>
      <hr />
      <div class="row">
        <div
          class="col-md-3 col-lg-3 d-flex align-items-center text-center"
        ></div>

        <div class="col-md-6 col-lg-6 d-flex align-items-center text-center">
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/vc_fest_flyer.png"
              alt="Saturday Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
        <div
          class="col-md-3 col-lg-3 d-flex align-items-center text-center"
        ></div>
      </div>

      <!-- <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <a
            href=" https://myle.com/for-the-kings-juneteenth-father-day-brunch"
            target="_blank" rel="noopener noreferrer"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/the-art-of-brunch.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>

            <p
              class="text-center lead-heading ml-3 mb-0"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              For The Kings | Juneteenth + Father's Day Brunch
            </p>
            <p
              class="text-center lead-heading ml-3 mb-3"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 300;
                font-size: 16px;
              "
            >
              Sun, Jun 19, 12:00 PM
            </p>
          </a>
        </div>
      </div> -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - Fearless Fund VC Summit",

    meta: [
      {
        name: "description",
        content: "Fearless Fund VC Summit",
      },

      {
        name: "keywords",
        content: "Fearless Fund VC Summit",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/fearless-fund-vc-summit",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
