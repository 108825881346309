<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green mt-5">
        <div class="col-lg-12 col-md-12 mt-3">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                MYLE $500 Sweepstakes Terms and Conditions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 24px;
              "
            >
              By participating in the sweepstakes you are agreeing to all of the
              following terms and conditions, which are subject to change
              without notification at MYLE’s sole discretion.
            </p>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Sweepstakes Outline:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Make Your Life Entertaining, Inc. (MYLE) is conducting a $500
              sweepstakes (sweepstakes) for promotional purposes to be promoted
              through e-mail, social media – both paid and non-paid, website and
              other vehicles as MYLE sees fit.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Timeframe:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              The sweepstakes will be open for participation between 12:01 a.m.,
              Wednesday April 13 and 11:59 p.m. Saturday, April 30, 2022. All
              time constraints are in US Eastern time zone.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Eligibility:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Participants must be 18 years or older and a citizen and resident
              of the United States.
              <br />
              To enter and be eligible to win the MYLE $500 Sweepstakes
              [sweepstakes], the following criteria have to be satisfied by the
              participant who will be randomly drawn from all eligible
              participants on Monday, May 2 at 4:30 p.m. Eastern Time:
              <br />
              <br />
              1. The participant has downloaded the MYLE app
              <br />
              2. The participant has created an account in MYLE
              <br />
              3. The MYLE account is active at the time of the drawing on May 2
              <br />
              4. The participant has opened the app and gone to the MYLE $500
              Sweepstakes Event and click on the sentiment button to “like, love
              or lose” the event and leave a comment.
              <br />
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Winner Selection Procedure:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              The winner of the Sweepstakes will be drawn at random from all
              participants who have complied with the outlined steps to become
              eligible. Each participant will be assigned a number beginning
              with 1 through the number of the final eligible participant as of
              the cut-off time and date. A random number generator will be used
              to select the number of the winner.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Winner Notification:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Selected winner will be notified via the email address provided as
              part of the MYLE app registration process. If an email is not
              provided or undeliverable, that winner will be disqualified and
              another winner will be selected using the same random selection
              process as outlined in this document.
              <br />
              Three (3) attempts will be made within a 7-day period to contact
              the selected winner. If after three attempts or 7-days has elapsed
              with no response, the selected winner will be disqualified and
              another winner will be selected using the same random selection
              process as outlined in this document.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Prize Fulfillment:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Upon successful contact has been made with the winner,
              arrangements will be made for funds transfer to the winner via
              their preferred cash transfer method – PayPal, Venmo or Zelle.
              Payment will be made within seven (7) business days of
              confirmation of transfer method. Any fees associated with funds
              transfer will be the responsibility of the winner.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Winner’s Responsibilities:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Provide MYLE with full name, address, phone number and email
              address for the appropriate tax forms. The winner is responsible
              for any taxes that would be levied against the prize.
            </p>
          </div>

          <div class="download-content">
            <p
              class="lead-heading mt-4 mb-2"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 22px;
              "
            >
              Other Terms:
            </p>

            <p
              class="lead-heading"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              No purchase necessary, void where prohibited.
              <br />
              <br />
              Sweepstakes may be entered via US Mail providing name, email
              address, phone number, city, state, zip code and sweepstakes name.
              Must be received by April 30 to be eligible. Mailed entries should
              be sent to MYLE – Sweepstakes, 175 S. 3rd Street, Suite 200,
              Columbus, Ohio 43215.
              <br />
              <br />
              All decisions as to the sweepstakes terms and conditions are
              subject to change with out notice or notification at the sole
              discretion of MYLE, Inc.
              <br />
              <br />
              Participants who provided comments release the rights to MYLE for
              MYLE’s reproduction, sharing and use in print, electronic or other
              media as they see fit without any compensation or notification
              required to the participant.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-12 text-center mt-4 mb-4">
        <h4
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
          "
        >
          Questions?
          <a href="mailto:hello@myle.com"> hello@myle.com</a>
        </h4>
      </div>

      <!-- Description section ends -->
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green" id="download">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
  },

  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Terms to Win $500 | MYLE",

    meta: [
      {
        name: "description",
        content: "Terms & Condition to WIN $500",
      },

      {
        name: "keywords",
        content: "Terms & Condition to WIN $500",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/winning-terms",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}
</style>
