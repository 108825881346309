<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_yfT2rMpbdhrWJNg'
              "
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_bMMncT7WBaGEmz0'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_ZNDRjDCAnOGEjAn'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq1(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_yfT2rMpbdhrWJNg'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_yfT2rMpbdhrWJNg'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn-100blackmen m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Scholarship_Gala.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #a19140"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: black;
                "
              >
                Scholarship Gala benefitting The Leadership Academy
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Saturday, August 12, 2023, <br />
                6:00pm - 10:00pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/kf49TUjDF71D52QA9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: black"
                >
                  InterContinental Buckhead Atlanta, an IHG Hotel
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                100 Black Men of DeKalb County, Inc, Scholarship Gala is an
                annual fundraising event supporting the Leadership Academy and
                additional programs offered by the chapter to DeKalb County
                residents and surrounding communities. The event will showcase
                the 2023 senior graduates of the Leadership Academy and their
                engagement in the program and future plans. The chapter also
                will honor leaders in the community driving impact and change.
                Musical entertainment will conclude the night to celebrate the
                occasion.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <div
        id="d2"
        style="max-width: 900px; margin: auto; height: auto; margin-top: 50px"
      ></div>

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #0ea293;
                      color: #0ea293;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Scholarship Gala-Individual
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #a19140;
                      color: black;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved Seating (Full Table - Seats 10)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #c07f00;
                      color: #c07f00;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved Seating (Half Table - Seats 5)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="
                    background: #a19140;
                    color: black;
                    width: 310px !important;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                        cgreen: obj.ticketCode == 'tk_yfT2rMpbdhrWJNg',
                        cred: obj.ticketCode == 'tk_ZNDRjDCAnOGEjAn',
                        cgold: obj.ticketCode == 'tk_bMMncT7WBaGEmz0',
                      }"
                      class="btn solid-btn-100blackmen"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="download-content">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 32px;
                "
              >
                Award Recipients
              </h3>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/100bmod_RDB_Headshot.jpg"
                style="width: 20%; max-width: 20%; height: auto"
              /><br />
              Rodney Bullard<br />
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="download-content">
          <div class="text-center">
            <h3
              class="w-auto d-inline-block mb-2 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 28px;
              "
            >
              ABOUT RODNEY BULLARD
            </h3>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Rodney is the current CEO of The Same House, a public benefit
                corporation. The Same House is a community-driven movement
                addressing the social and economic challenges facing our world
                today. Our mission is rooted in the values and aims of the
                Beloved Community and the uplifting words of Congressman Lewis.
                We bring together all people to help bridge divides, practice
                compassion, and collectively tackle our communities’ greatest
                challenges and needs – particularly around economic opportunity
                and social connection.
                <br /><br />
                Rodney previously served as Vice President of Global Corporate
                Social Responsibility at Chick-fil-A, Inc., and Executive
                Director of the Chick-fil-A Foundation, where he started and
                scaled the company’s corporate social responsibility,
                environmental, community engagement, and philanthropic
                functions. With experience in marketing, real estate, customer
                service, and ESG, Rodney led Chick-fil-A’s efforts to revitalize
                the Westside of Atlanta, the Beloved Benefit
                (www.belovedbenefit.org) aimed at bridging social and economic
                division, created the True-Inspiration Awards, and inspired
                several successful public, private partnerships touching the
                lives of over 30 Million people annually.
                <br /><br />
                Rodney serves on several national and local boards of directors
                to include the Ameris Bank, the United States Air Force Academy
                Athletic Corporation, the National Defense Industry Association,
                and the Georgia Hispanic Chamber of Commerce.
                <br /><br />
                Before Chick-fil-A, Rodney served as an Assistant United States
                Attorney prosecuting complex criminal cases working with
                agencies such as the U.S. Secret Service, FBI, DEA, and Customs
                and Border Control.
                <br /><br />
                Rodney was selected as a White House Fellow, the nation’s most
                prestigious public-service Fellowship. As a White House Fellow,
                Rodney was placed at NASA working directly for the NASA
                Administrator.
                <br /><br />
                A proud veteran, Rodney served in the United States Air Force
                Judge Advocate General Corps, eventually working at the Pentagon
                as a Congressional Legislative Liaison in the Office of the
                Secretary of the Air Force.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-5">
          <div class="download-content">
            <p class="text-center">
              <img
                class=""
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/100bmod_Tiffany_Burns_headshot.JPG"
                style="width: 20%; max-width: 20%; height: auto"
              /><br />
              Tiffany Burns
              <br />
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="download-content">
          <div class="text-center">
            <h3
              class="w-auto d-inline-block mb-2 text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 28px;
              "
            >
              ABOUT TIFFANY BURNS
            </h3>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Tiffany Burns is a Senior Partner and Managing Partner of
                McKinsey’s Atlanta Office. She has experience leading
                performance transformations in the Retail and Consumer sectors.
                Tiffany helped to shape McKinsey’s perspective on Future of the
                Consumer / Retail experience given innovation in digital and
                automation. This future customer journey in the Modern Retail
                Collective a live store environment at the Mall of America.
                <br /><br />
                Beyond her client service, Tiffany helped to lead the creation
                of the McKinsey’s 10 Actions as the global Sponsor, which is a
                portfolio of initiatives and efforts to improve racial equity
                within the firm and around the world. McKinsey’s 10 Commitments
                focused on building capability of black executives, translating
                insights into practice tools to enable clients to advance Black
                economic empowerment, supplier diversity efforts, and standing
                up place-based transformation efforts to drive racial equity
                efforts across pillars (housing, wealth creation, education,
                healthcare). Most recently, Tiffany helped to architect the Next
                1B program focused on increasing capability and growth
                acceleration for Black founders to build $1B businesses.
                <br /><br />
                Tiffany is an active member of the Atlanta philanthropic
                community, serves on the board of the Woodruff Arts Center,
                Leadership Atlanta, and as the cofounder of the Rising Seniors
                Foundation. She has a BS in industrial engineering from the
                Georgia Institute of Technology, an MBA from Harvard Business
                School, a J.D. from Harvard Law School, and Master’s in Public
                Policy from the Kennedy School of Government at Harvard.

                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="container">
          <div class="row w-100 m-0 text-center promo-section MYLE-green">
            <div class="col-lg-12 col-md-12">
              <div class="mt-3">
                <div class="section-heading mb-3">
                  <h5 class="text-center text-white">
                    2023 Scholarship Gala Weekend
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <video-embed
            css="embed-responsive-16by9"
            src="https://youtu.be/3-LfkpFV2JE"
          ></video-embed>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test mt-5"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                Hotel Accommodations
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                InterContinental Buckhead Atlanta<br />
                3315 Peachtree Rd NE<br />
                Atlanta, GA 30326<br />
                Click
                <a
                  href="https://book.passkey.com/go/100BlackMenofDekalbGala"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here</a
                >

                to reserve your room
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-2">
        <div class="text-center">
          <a
            href="https://myle.com/100-black-men-of-dekalb-county"
            class="btn solid-btn-100blackmen mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Back To Main Page</a
          >

          <a
            href="https://myle.com/scholarship-gala-donations"
            class="btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Donations</a
          >

          <a
            href="https://myle.com/scholarship-gala-silent-auction"
            class="btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Silent Auction</a
          >
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #a19140">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center">Download today and try it for yourself</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/NavBar";
import SiteFooter from "../../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Doublelist from "./doubleList.vue";

// tslint-disable no-mixed-spaces-and-tabs
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
      selectionList: [],
      dict: {},
      dictlist: {},
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      availSeating: "",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_yfT2rMpbdhrWJNg",
        "tk_bMMncT7WBaGEmz0",
        "tk_ZNDRjDCAnOGEjAn",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_yfT2rMpbdhrWJNg: {
          messages: ["Ticket Price: $150"],
        },

        tk_bMMncT7WBaGEmz0: {
          messages: ["Ticket Price: $1250"],
        },

        tk_ZNDRjDCAnOGEjAn: {
          messages: ["Ticket Price: $725"],
        },
      },

      priceIds: {
        tk_yfT2rMpbdhrWJNg: "price_1N7noFD0vTZ4QB9ctcO5grjT",
        tk_bMMncT7WBaGEmz0: "price_1N7nniD0vTZ4QB9c5KQfeGT0",
        tk_ZNDRjDCAnOGEjAn: "price_1N7nnpD0vTZ4QB9c7iBayQjj",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    Doublelist,
    StripeCheckout,
  },
  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    uniq1(list) {
      if (list && list.length > 0) {
        const uniqValues = [];
        list.forEach((v) => {
          if (
            v.seat.split("Seat ")[1] == "1" ||
            v.seat.split("Seat ")[1] == "6"
          ) {
            uniqValues.push(
              v.table +
                " " +
                "Seat " +
                (v.seat.split("Seat ")[1] == "1" ? "1 to 5" : "6 to 10")
            );
          }
        });

        return uniqValues;
      }

      return [];
    },
    datad2(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };

      const zx = [];
      const zobj = {
        0: {
          texts: [1, 2, 3, 4, 5],
          x: 70,
          y: 100,
          count: 5,
        },
        1: {
          texts: [11, 10, 9, 8, 7, 6],
          x: 140,
          y: 70,
          count: 6,
        },
        2: {
          texts: [12, 13, 14, 15, 16, 17],
          x: 210,
          y: 100,
          count: 6,
        },
        3: {
          texts: [22, 21, 20, 19, 18],
          x: 280,
          y: 120,
          count: 5,
        },
        4: {
          texts: [23, 24, 25, 26, 27],
          x: 350,
          y: 150,
          count: 5,
        },
        5: {
          texts: [30, 29, 28],
          x: 420,
          y: 280,
          count: 3,
        },
        6: {
          texts: [31, 32, 33],
          x: 490,
          y: 280,
          count: 3,
        },
        7: {
          texts: [38, 37, 36, 35, 34],
          x: 560,
          y: 150,
          count: 5,
        },
        8: {
          texts: [39, 40, 41, 42, 43],
          x: 630,
          y: 120,
          count: 5,
        },
        9: {
          texts: [49, 48, 47, 46, 45, 44],
          x: 700,
          y: 100,
          count: 6,
        },
        10: {
          texts: [50, 51, 52, 53, 54, 55],
          x: 770,
          y: 70,
          count: 6,
        },
        11: {
          texts: [61, 60, 59, 58, 57, 56],
          x: 840,
          y: 100,
          count: 6,
        },
      };
      for (let j = 0; j < 12; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x,
            y: zobj[j].y + k * 80,
            val: seat[10],
            distance: 10,
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }

      let recs = [];
      this.dict = {};
      this.dictlist = {};

      listdata.forEach((r) => {
        r.availableSeating.forEach((r1) => {
          if (r.ticketCode == "tk_yfT2rMpbdhrWJNg") {
            this.dictlist[r1.table.split("Table ")[1]] = "#0EA293";
          }

          if (r.ticketCode == "tk_bMMncT7WBaGEmz0") {
            this.dictlist[r1.table.split("Table ")[1]] = "#a19140";
          }

          if (r.ticketCode == "tk_ZNDRjDCAnOGEjAn") {
            this.dictlist[r1.table.split("Table ")[1]] = "#C07F00";
          }
        });

        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord) - 1);
      });

      return [...zx];
    },
    drawseatsd2(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "900px")
        .attr("height", "555px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;

        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? vm.dictlist[v.text] || "pink"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 130)
        .attr("height", 120)
        .attr("x", 400)
        .attr("y", 125)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 425)
        .attr("y", 195)
        .text("Dance Floor")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 130)
        .attr("height", 115)
        .attr("x", 400)
        .attr("y", 10)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 445)
        .attr("y", 70)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.selectionList = [];
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_yfT2rMpbdhrWJNg"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_bMMncT7WBaGEmz0"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_ZNDRjDCAnOGEjAn"
      ) {
        //  this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
        this.availSeating = this.uniq1(
          this.TicketsResult[idx].availableSeating
        )[0];
      } else {
        this.availSeating = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_yfT2rMpbdhrWJNg") {
        const seatRecord = [];
        this.selectionList.forEach((r) => {
          seatRecord.push(r.alias);
        });
        payloadToPush.totalTickets = seatRecord.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatRecord,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_ZNDRjDCAnOGEjAn") {
        const seatsArr = [];
        if (this.availSeating.endsWith("1 to 5")) {
          const initalval = this.availSeating.split("Seat 1 to 5")[0];
          seatsArr.push(initalval + "- Seat 1");
          seatsArr.push(initalval + "- Seat 2");
          seatsArr.push(initalval + "- Seat 3");
          seatsArr.push(initalval + "- Seat 4");
          seatsArr.push(initalval + "- Seat 5");
        } else if (this.availSeating.endsWith("6 to 10")) {
          const initalval = this.availSeating.split("Seat 6 to 10")[0];
          seatsArr.push(initalval + "- Seat 6");
          seatsArr.push(initalval + "- Seat 7");
          seatsArr.push(initalval + "- Seat 8");
          seatsArr.push(initalval + "- Seat 9");
          seatsArr.push(initalval + "- Seat 10");
        }
        //  payloadToPush.totalTickets = seatsArr.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatsArr,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredScholarshipGalaEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_yfT2rMpbdhrWJNg: null,
            tk_bMMncT7WBaGEmz0: null,
            tk_ZNDRjDCAnOGEjAn: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          vm.drawseatsd2(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Scholarship Gala",

    meta: [
      {
        name: "description",
        content: "MYLE - Scholarship Gala",
      },

      {
        name: "keywords",
        content: "MYLE - Scholarship Gala",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/scholarship-gala",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 922px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 922.1px) {
  #d2 {
    overflow-x: hidden;
  }
}

.cred {
  background: #c07f00 !important;
  border: 1px solid #c07f00 !important;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.cgold {
  background: #a19140;
  border: 1px solid #a19140 !important;
}
</style>
