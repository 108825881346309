<template src="./Success.html"></template>

<script>
// Scss Imports
import "./Success.scss";

export default {
  name: "Success",
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closemodal", true);
    },
  },
};
</script>
