<template>
  <div style="background-color: #f1f0f3">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
              >
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  General Details
                </h3>
              </div>
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Full Name</label
              >
              <input
                type="text"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Email Address</label
              >
              <input
                type="email"
                class="form-control mb-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Phone (Optional)</label
              >
              <input
                type="text"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="Enter phone number"
                @keypress="isNumber()"
                v-model="phone"
              />
              <span
                v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*please fill valid phone number</span
              >
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                !ticketToShowAgainst.includes(
                  TicketsResult[selected].ticketCode
                )
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="tickcount"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1rem;
                  color: black;
                "
                >Remaining Ticket(s):
                {{ TicketsResult[selected].availableTickets }}</label
              >
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-KAPPAVEGAS btn-sm my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  background: #164489;
                "
              >
                Redeem Code
              </button>
            </div>
          </div>

          <hr />

          <div class="row">
            <div
              class="col-md-12 mt-3 mb-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K'
              "
            >
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  Which Kappa's table do you have a reservation for?
                </h3>
              </div>
            </div>

            <div
              class="col-md-6 mb-4"
              v-if="
                TicketsResult[selected] &&
                ticketToShowAgainst.includes(
                  TicketsResult[selected].ticketCode
                ) &&
                TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Table</label
              >
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="seatsListObj"
                @change="
                  () => {
                    this.seatCount = null;
                  }
                "
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ticketsMap"
                  :value="count"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-4"
              v-if="
                TicketsResult[selected] &&
                !ticketToShowAgainst.includes(
                  TicketsResult[selected].ticketCode
                ) &&
                TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K'
              "
            >
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="seatsListC"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in seatsList"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-4"
              v-if="
                TicketsResult[selected] &&
                ticketToShowAgainst.includes(
                  TicketsResult[selected].ticketCode
                ) &&
                TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Number of Seat(s)</label
              >
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="seatCount"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in seatsForTickets"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>

              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1rem;
                  color: black;
                "
                >Remaining Ticket(s): {{ seatscounts }}</label
              >
            </div>
          </div>

          <hr
            v-if="
              TicketsResult[selected] &&
              (TicketsResult[selected].ticketCode === 'tk_zUJ6Jr7vLwqHfWD' ||
                TicketsResult[selected].ticketCode === 'tk_WpO1v70bPo47pAi')
            "
          />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  What is your Greek Affiliation?
                </h3>
              </div>
            </div>

            <div class="col-md-6 mb-4">
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="greekAffiliationListC"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in greekAffiliationList"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  What chapter are you a member of?
                </h3>
              </div>
            </div>

            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="Enter chapter name"
                v-model="chapter"
                required
              />
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  Are you interested in sponsorship opportunities for future
                  Foundation events?
                </h3>
              </div>
            </div>

            <div class="col-md-6">
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="interested"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  Is this your first time attending this event?
                </h3>
              </div>
            </div>

            <div class="col-md-6">
              <select
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="firstTimeAttending"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  How many times have you attended this event before?
                </h3>
              </div>
            </div>

            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="How many times have you attended before?"
                v-model="attendedCount"
                required
              />
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  How did you hear about the event?
                </h3>
              </div>
            </div>

            <div class="col-md-12">
              <input
                type="text"
                class="form-control mb-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                placeholder="From where did you hear about us?"
                v-model="hearAboutUs"
                required
              />
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-md-12 mt-3 mb-2">
              <div class="">
                <h3
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 600;
                    font-size: 1.7em;
                    color: #202c5d;
                  "
                >
                  Address Details
                </h3>
              </div>
            </div>

            <div class="col-md-6 mb-1">
              <label
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Street Address</label
              >
              <input
                type="text"
                placeholder="Enter street address"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="streetAddress"
              />
              <span
                v-bind:class="{
                  'op-0': !(istouched && streetAddress.trim().length <= 0),
                }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*Street address is required</span
              >
            </div>
            <div class="col-md-6 mb-1">
              <label
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >City</label
              >
              <input
                type="text"
                placeholder="Enter city"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="city"
              />
              <span
                v-bind:class="{
                  'op-0': !(istouched && city.trim().length <= 0),
                }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*City is required</span
              >
            </div>
            <div class="col-md-6 mb-1">
              <label
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >State</label
              >
              <!-- <input
                  type="text"
                  placeholder="Enter state"
                  class="form-control"
                  v-model="state"
                /> -->

              <select
                placeholder="Select your state"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                v-model="state"
              >
                <option
                  v-for="s in states"
                  v-bind:key="s"
                  :value="s['States in USA']"
                >
                  {{ s["States in USA"] }}
                </option>
              </select>

              <span
                v-bind:class="{
                  'op-0': !(istouched && state.trim().length <= 0),
                }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*State is required</span
              >
            </div>
            <div class="col-md-6 mb-1">
              <label
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1rem;
                  color: black;
                "
                >Zip Code</label
              >
              <input
                type="text"
                placeholder="Enter zip code"
                class="form-control"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 300;
                  font-size: 1.1rem;
                  color: black;
                "
                @keypress="isNumber()"
                v-model="zip"
              />
              <span
                v-bind:class="{
                  'op-0': !(istouched && zip.trim().length <= 0),
                }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*Zipcode is required</span
              >
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 16px;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowV3()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  !city.trim().length ||
                  !zip.trim().length ||
                  !streetAddress.trim().length ||
                  !state.trim().length ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K' &&
                  ticketToShowAgainst.includes(
                    TicketsResult[selected].ticketCode
                  )
                    ? !seatsListC.trim().length
                    : false) ||
                  (TicketsResult[selected] &&
                  (TicketsResult[selected].ticketCode ===
                    'tk_unNfZdMzmbPS62K' ||
                    ticketToShowAgainst.includes(
                      TicketsResult[selected].ticketCode
                    ))
                    ? false
                    : !chapter.trim().length ||
                      !attendedCount.trim().length ||
                      !hearAboutUs.trim().length ||
                      !interested.trim().length ||
                      !firstTimeAttending.trim().length ||
                      !greekAffiliationListC.trim().length) ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode !== 'tk_unNfZdMzmbPS62K' &&
                  ticketToShowAgainst.includes(
                    TicketsResult[selected].ticketCode
                  )
                    ? !seatCount
                    : false) ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-KAPPAVEGAS m-auto mr-2"
                style="pointer-events: all; cursor: pointer"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_AnnualKappaVegasGalaFundraiser52nd_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
        <div style="background-color: #eacb7f; height: 3px; width: 100%"></div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #64231e"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: #eacb7f;
                "
              >
                52nd Annual Kappa Vegas Gala & Fundraiser
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                  color: #eacb7f;
                "
              >
                Friday March 07, 2025 <br />
                9:00pm - 2:00am
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                  color: #eacb7f;
                "
              >
                <a
                  href="https://maps.app.goo.gl/oFMxBJjcg5npPTCe9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #eacb7f"
                >
                  Martin's West
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #eacb7f; height: 3px; width: 100%"></div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.4em;
                  color: rgb(34, 33, 33);
                  font-weight: 500;
                "
              >
                Get ready for a night of glitz, glamour, and endless fun at the
                Kappa Vegas Fundraising Gala 2025! Join us on Friday, March 7,
                2025 at Martin's West for an unforgettable in-person event.

                <br /><br />

                Indulge in the ultimate party experience as we transform the
                venue into a dazzling Vegas-inspired wonderland. From the moment
                you step through the doors at Martin's West, you'll be
                transported into a world of excitement and entertainment.

                <br /><br />
                Immerse yourself in a night filled with live music, electrifying
                performances, and non-stop dancing. Our talented DJs will keep
                the beats pumping, ensuring you never leave the dance floor.
                Take a chance at the casino tables, where luck may just be on
                your side.

                <br /><br />

                Mingle with old friends and make new ones as you enjoy the
                vibrant atmosphere and share unforgettable moments.

                <br /><br />

                Don't miss out on the Kappa Vegas Fundraising Gala 2025! Mark
                your calendars and get ready for an evening of sheer excitement
                and endless memories. This is one event you won't want to miss!
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section ends -->

      <!-- <div class="section-heading text-center mt-5">
        <h2
          class=""
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            font-size: 2.5em;
            color: black;
          "
        >
          Seating Map
        </h2>
      </div> -->

      <!-- Map starts -->
      <!-- <div
        id="d2"
        style="
          max-width: 1100px;
          margin: auto;
          margin-top: 50px;
          overflow-x: scroll;
        "
      ></div> -->
      <!-- Map ends -->

      <!-- <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #164489;
                      color: #164489;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 400;
                      font-size: 1.1em;
                      color: black;
                    "
                  >
                    Available For Purchase
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 400;
                      font-size: 1.1em;
                      color: black;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <!-- <div class="text-center">
              <h3
                class="w-auto d-inline-block"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  color: black;
                "
              >
                TICKET OPTIONS
              </h3> -->
            <!-- <br />
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.8em;
                  color: #840229;
                "
              >
                NO REFUNDS
              </h3> -->
            <!-- </div> -->
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="
                    background: #64231e;
                    color: #eacb7f;
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.05em;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 500;
                      font-size: 1em;
                    "
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 800;
                        font-size: 1.1em;
                      "
                    >
                      Total Price: ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-KAPPAVEGAS"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <br />
                <div class="section-heading text-center">
                  <h2
                    class=""
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                      color: black;
                    "
                  >
                    Bottle List
                  </h2>
                </div>
                <div class="row justify-content-center">
                  <div
                    class="col-8 col-md-6 justify-content-center d-flex align-items-center"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bottle_list_Saloon_Bottle.jpg"
                        alt="Slow Jam Social Event"
                        class="w-100 img-fluid"
                      />
                    </div>
                  </div>
                </div> -->

      <!-- <br />
                <br />
                <div class="section-heading text-center">
                  <h2
                    class=""
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                      color: black;
                    "
                  >
                    The Skegee Ball & Parlay Homecoming Soiree
                  </h2>
                </div> -->

      <div class="row justify-content-center">
        <div
          class="col-8 col-md-6 justify-content-center d-flex align-items-center"
        >
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_AnnualKappaVegasGalaFundraiser52nd_V1.jpg"
              alt="Slow Jam Social Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #64231e">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 2.7em;
                color: rgb(34, 33, 33);
                font-weight: 700;
                color: #eacb7f;
              "
            >
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../components/confirmationPopup";
const listRecs = [
  {
    ticketId: "tk_XUFEMzNeeepXWrP",
    name: "Alexander Hodge",
    priceId: "price_1QR341D0vTZ4QB9cfmDl15bu",
  },
  {
    ticketId: "tk_jADA9OiELynfS3D",
    name: "Allen Bennett",
    priceId: "price_1QR342D0vTZ4QB9cU3Qk4SPn",
  },
  {
    ticketId: "tk_zmgp2ZsBgOPxr6a",
    name: "Alonzo Howard",
    priceId: "price_1QR342D0vTZ4QB9cGzOyHToo",
  },
  {
    ticketId: "tk_yqVbyp0uuRR7wFu",
    name: "Andre Thomas",
    priceId: "price_1QR343D0vTZ4QB9cZQPeR98t",
  },
  {
    ticketId: "tk_C9Asg8xQNY2Xa5u",
    name: "Art Varnado",
    priceId: "price_1QR343D0vTZ4QB9cxhxw5ABG",
  },
  {
    ticketId: "tk_CMW3aZDAlBvxFnd",
    name: "Arthur Brown",
    priceId: "price_1QR36VD0vTZ4QB9ci2z0wfkO",
  },
  {
    ticketId: "tk_m8cNvOcsRschN6E",
    name: "Brandon Hines",
    priceId: "price_1QR2ydD0vTZ4QB9ctBGhGrEq",
  },
  {
    ticketId: "tk_Fg7sLOSOtQV91qN",
    name: "Brandon Wylie",
    priceId: "price_1QR36WD0vTZ4QB9cyC7s2PNC",
  },
  {
    ticketId: "tk_3E4XAbZHgDONWQ6",
    name: "Carey Edwards",
    priceId: "price_1QR36WD0vTZ4QB9cWEh0rPsf",
  },
  {
    ticketId: "tk_fxm2xNCbirgZ9hX",
    name: "Charles Faison",
    priceId: "price_1QR36WD0vTZ4QB9cre5Eh7E7",
  },
  {
    ticketId: "tk_eAii9v0wZ6SrXXs",
    name: "Charles Harris",
    priceId: "price_1QR36XD0vTZ4QB9cUuL39j81",
  },
  {
    ticketId: "tk_WPtoXVMAZjU3PiZ",
    name: "Chris Hill",
    priceId: "price_1QR2zdD0vTZ4QB9c3SkO9UXC",
  },
  {
    ticketId: "tk_oalNzqigRx8YZvG",
    name: "Craig Sims",
    priceId: "price_1QR39gD0vTZ4QB9cGtP38O9c",
  },
  {
    ticketId: "tk_Xz8uXcerv5Kq0ai",
    name: "Darrick Estes",
    priceId: "price_1QR30AD0vTZ4QB9cpyFL4y1C",
  },
  {
    ticketId: "tk_Di2p8wbSRYrOsfF",
    name: "Darryl Day",
    priceId: "price_1QR39gD0vTZ4QB9ceBMUK7vG",
  },
  {
    ticketId: "tk_KnChR5Cc41SWuPZ",
    name: "Daryl Anderson",
    priceId: "price_1QR30JD0vTZ4QB9ccr0WBAzn",
  },
  {
    ticketId: "tk_VyslBr0ECnDm7u9",
    name: "Dedric Rogers",
    priceId: "price_1QR2ysD0vTZ4QB9cMuYSHIk1",
  },
  {
    ticketId: "tk_1WIxzfGV7ieYKOj",
    name: "Devron Dickens",
    priceId: "price_1QR39hD0vTZ4QB9cLAJJbt0G",
  },
  {
    ticketId: "tk_xug3rM8IUvCW7g7",
    name: "Don Ervin",
    priceId: "price_1QR39hD0vTZ4QB9cwtH4zbDU",
  },
  {
    ticketId: "tk_DIpPb3otRjSeZ6c",
    name: "Donald Chambers",
    priceId: "price_1QR39iD0vTZ4QB9cS2gjyEbG",
  },
  {
    ticketId: "tk_GpHMsVQaPBxfFbm",
    name: "Duane Elliott",
    priceId: "price_1QR2yiD0vTZ4QB9cuJ8aG2hu",
  },
  {
    ticketId: "tk_iKfYzAAH6gSFk4N",
    name: "Duane Jackson",
    priceId: "price_1QR39iD0vTZ4QB9cBPgwy8uD",
  },
  {
    ticketId: "tk_tVVGZgHHFDXPvyN",
    name: "Enyinna Anthony",
    priceId: "price_1QR2zSD0vTZ4QB9csBg0AsjX",
  },
  {
    ticketId: "tk_NesZEfS9AwMBzJd",
    name: "Everett Jordan",
    priceId: "price_1QR39iD0vTZ4QB9c2VG3Wplt",
  },
  {
    ticketId: "tk_TRemxaMWmhCKA7p",
    name: "Fleming James",
    priceId: "price_1QR30FD0vTZ4QB9cuyhhOdZn",
  },
  {
    ticketId: "tk_92nAHuv8Q1j27l8",
    name: "Floyd TaliaferroIII",
    priceId: "price_1QR3FZD0vTZ4QB9c7cPqOCk8",
  },
  {
    ticketId: "tk_DjweFKPufGOvWXd",
    name: "Gary Palmer",
    priceId: "price_1QR3FZD0vTZ4QB9ciRd9Kz3y",
  },
  {
    ticketId: "tk_3s95N8RWrsixRSi",
    name: "Godfrey Durham",
    priceId: "price_1QR3FZD0vTZ4QB9cvkXTYLka",
  },
  {
    ticketId: "tk_AR15Ywa2ZsYSijE",
    name: "Guy Bragg",
    priceId: "price_1QR3FaD0vTZ4QB9cVvLX7HOg",
  },
  {
    ticketId: "tk_IysNVsIxpP6xacX",
    name: "H Byron Matthews",
    priceId: "price_1QR3FaD0vTZ4QB9cef5NrHe4",
  },
  {
    ticketId: "tk_Tljcxe6SUKjiOyL",
    name: "Howard Tutman",
    priceId: "price_1QR3FbD0vTZ4QB9cEdAwbTYT",
  },
  {
    ticketId: "tk_i1ZWvuEmOINRR5K",
    name: "Ivan Bowser",
    priceId: "price_1QR3FbD0vTZ4QB9cKnq26DIK",
  },
  {
    ticketId: "tk_MssXo4KBI6eHbWc",
    name: "James Massey",
    priceId: "price_1QR303D0vTZ4QB9cqfrGGRWQ",
  },
  {
    ticketId: "tk_MWzq9TH5fL4Owkl",
    name: "Jason Bonardi",
    priceId: "price_1QR2zND0vTZ4QB9cRz42eS1J",
  },
  {
    ticketId: "tk_tH2e76iH5soJxM4",
    name: "Jesse Bennett",
    priceId: "price_1QR2zjD0vTZ4QB9cVmwtBWyW",
  },
  {
    ticketId: "tk_jgcJ2nYLh8AZq8k",
    name: "John McCargo",
    priceId: "price_1QR3FcD0vTZ4QB9cbkI1QBOM",
  },
  {
    ticketId: "tk_gLaFiTuQcADbqgN",
    name: "Joseph Mayzck",
    priceId: "price_1QR2yoD0vTZ4QB9ci2IoNc3e",
  },
  {
    ticketId: "tk_Tmupb05iiiARDLV",
    name: "Kai Nichelson",
    priceId: "price_1QR3FcD0vTZ4QB9c9ZS7RaNS",
  },
  {
    ticketId: "tk_NdB7GYQ78DX1PCQ",
    name: "Kevin Clark",
    priceId: "price_1QR3FdD0vTZ4QB9cHCrHqNHO",
  },
  {
    ticketId: "tk_e3D5iLWUdMftmT1",
    name: "Kirk Hemphill",
    priceId: "price_1QR2yRD0vTZ4QB9chAyn4YR6",
  },
  {
    ticketId: "tk_iDjmtamkO7iqEPE",
    name: "Kyle McNair",
    priceId: "price_1QR3FdD0vTZ4QB9cp7nsfULD",
  },
  {
    ticketId: "tk_L2jlThGYg7uMvWY",
    name: "Leonadus Plenty",
    priceId: "price_1QR3FdD0vTZ4QB9cfPOsit8s",
  },
  {
    ticketId: "tk_7ncTladX5Y3TttO",
    name: "Llyod Buckner",
    priceId: "price_1QR3FeD0vTZ4QB9cYIrLZyLi",
  },
  {
    ticketId: "tk_uAWKD8IdquKPq1M",
    name: "Mark Brown",
    priceId: "price_1QR2yXD0vTZ4QB9c7AtOd0wH",
  },
  {
    ticketId: "tk_4UhQQGCspzWa325",
    name: "Mark McDuffie",
    priceId: "price_1QR3OPD0vTZ4QB9cIor7ROLL",
  },
  {
    ticketId: "tk_1BKESFXeRW8G5SG",
    name: "Marvin Briscoe",
    priceId: "price_1QR3OQD0vTZ4QB9cKno1EZby",
  },
  {
    ticketId: "tk_e4sDATpQnDvzRmN",
    name: "Maurice Coleman",
    priceId: "price_1QR3ORD0vTZ4QB9cPCfTYLWv",
  },
  {
    ticketId: "tk_Lng1ZryAapbZB2B",
    name: "Milton Langley",
    priceId: "price_1QR3ORD0vTZ4QB9c1OGYnTxV",
  },
  {
    ticketId: "tk_fSS3MmDCV1F3ZSA",
    name: "Milton Mayo",
    priceId: "price_1QR3ORD0vTZ4QB9criFcDQOQ",
  },
  {
    ticketId: "tk_Tp2QL4Az1GQ9vtr",
    name: "Nathan Fletcher",
    priceId: "price_1QR2z0D0vTZ4QB9cgj1ogzIT",
  },
  {
    ticketId: "tk_ZUSGzeU7r23Si7X",
    name: "Norman Edwards",
    priceId: "price_1QR30XD0vTZ4QB9c2RZkdD3o",
  },
  {
    ticketId: "tk_tYkpf1MmeA5Amux",
    name: "Paul Winfield",
    priceId: "price_1QR3OSD0vTZ4QB9cGWNpMYJY",
  },
  {
    ticketId: "tk_qIzkX3B3NxqB5WG",
    name: "Quentin Davis",
    priceId: "price_1QR3OSD0vTZ4QB9cA8YghWJU",
  },
  {
    ticketId: "tk_5gXtQryBPLh16OA",
    name: "Quentin Tyson",
    priceId: "price_1QR2zCD0vTZ4QB9ctrmBXbXd",
  },
  {
    ticketId: "tk_jnHRh5t6pXQARyT",
    name: "Rashed Ragin",
    priceId: "price_1QR3OTD0vTZ4QB9cLKqmR5zT",
  },
  {
    ticketId: "tk_PvtQ9ge5KrTvbdx",
    name: "Raymond Teasdell",
    priceId: "price_1QR3OTD0vTZ4QB9c7DbKrn0S",
  },
  {
    ticketId: "tk_D97szgVjTjGzPtK",
    name: "Rick Moto",
    priceId: "price_1QR2zwD0vTZ4QB9cxgITePeF",
  },
  {
    ticketId: "tk_EnIvgnqubV0lsQw",
    name: "Robert Ammons",
    priceId: "price_1QR30SD0vTZ4QB9cVpVcaWbk",
  },
  {
    ticketId: "tk_15CnlmZTbdIAP4z",
    name: "Samuel Jackson",
    priceId: "price_1QR2zHD0vTZ4QB9cQ9WhI8f4",
  },
  {
    ticketId: "tk_gLUKaBY7FA66UwU",
    name: "Shawn Hart",
    priceId: "price_1QR3OUD0vTZ4QB9csyEvp9m8",
  },
  {
    ticketId: "tk_RvCjYkoakqQenSJ",
    name: "Steve Carrington",
    priceId: "price_1QR3OUD0vTZ4QB9cfW19sabK",
  },
  {
    ticketId: "tk_EX7KGjrzPlj78kt",
    name: "Steward Beckham",
    priceId: "price_1QR2z6D0vTZ4QB9c1zUcdxEt",
  },
  {
    ticketId: "tk_537NbgzvaCDPUhf",
    name: "Theodore Garrett Jr",
    priceId: "price_1QR3OVD0vTZ4QB9cpOucZmAd",
  },
  {
    ticketId: "tk_2kk1MVCF72uq9xV",
    name: "Thomas Hampton",
    priceId: "price_1QR2zsD0vTZ4QB9c9tj6RB2j",
  },
  {
    ticketId: "tk_gbsq9SGBECLhXsk",
    name: "Tony Clark",
    priceId: "price_1QR3OVD0vTZ4QB9cQwRioA1C",
  },
  {
    ticketId: "tk_4dMwka4b0oaIBis",
    name: "Tony Ferguson",
    priceId: "price_1QR2znD0vTZ4QB9c372vBbQR",
  },
  {
    ticketId: "tk_iY1qjUZ0mzYeVeu",
    name: "Turner Roscoe",
    priceId: "price_1QR3OVD0vTZ4QB9cWAPEkluR",
  },
  {
    ticketId: "tk_JD1j8cM5iQZGtax",
    name: "Walter Brown",
    priceId: "price_1QR30OD0vTZ4QB9ccH7xCgHy",
  },
  {
    ticketId: "tk_kT8bPdk46LYJy4A",
    name: "Wayne Pulliam",
    priceId: "price_1QR3OWD0vTZ4QB9cuBOPbOGb",
  },
  {
    ticketId: "tk_opHeCiuHbhft0V3",
    name: "Wil Giles",
    priceId: "price_1QR2zXD0vTZ4QB9cYjGbEqNJ",
  },
];

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      seatCount: null,
      ticketsMap: listRecs,
      seatsListObj: listRecs[0],
      ticksById: {},
      chapter: "",
      attendedCount: "",
      hearAboutUs: "",
      interested: "Yes",
      firstTimeAttending: "Yes",
      streetAddress: "",
      city: "",
      zip: "",
      state: "Alabama",
      istouched: false,
      greekAffiliationListC: "Alpha Phi Alpha",
      seatsListC: "Not Applicable",
      seatsList: [
        "Not Applicable",
        "Alexander Hodge",
        "Allen Bennett",
        "Alonzo Howard",
        "Andre Thomas",
        "Art Varnado",
        "Arthur Brown",
        "Brandon Hines",
        "Brandon Wylie",
        "Carey Edwards",
        "Charles Faison",
        "Charles Harris",
        "Chris Hill",
        "Craig Sims",
        "Darrick Estes",
        "Darryl Day",
        "Daryl Anderson",
        "Dedric Rogers",
        "Devron Dickens",
        "Don Ervin",
        "Donald Chambers",
        "Duane Elliott",
        "Duane Jackson",
        "Enyinna Anthony",
        "Everett Jordan",
        "Fleming James",
        "Floyd Taliaferroiii",
        "Gary Palmer",
        "Godfrey Durham",
        "Guy Bragg",
        "H Byron Matthews",
        "Howard Tutman",
        "Ivan Bowser",
        "James Massey",
        "Jason Bonardi",
        "Jesse Bennett",
        "John Mccargo",
        "Joseph Mayzck",
        "Kai Nichelson",
        "Kevin Clark",
        "Kirk Hemphill",
        "Kyle Mcnair",
        "Leonadus Plenty",
        "Llyod Buckner",
        "Mark Brown",
        "Mark Mcduffie",
        "Marvin Briscoe",
        "Maurice Coleman",
        "Milton Langley",
        "Milton Mayo",
        "Nathan Fletcher",
        "Norman Edwards",
        "Paul Winfield",
        "Quentin Davis",
        "Quentin Tyson",
        "Rashed Ragin",
        "Raymond Teasdell",
        "Rick Moto",
        "Robert Ammons",
        "Samuel Jackson",
        "Shawn Hart",
        "Steve Carrington",
        "Steward Beckham",
        "Theodore Garrett Jr",
        "Thomas Hampton",
        "Tony Clark",
        "Tony Ferguson",
        "Turner Roscoe",
        "Walter Brown",
        "Wayne Pulliam",
        "Wil Giles",
      ],
      greekAffiliationList: [
        "Alpha Phi Alpha",
        "Alpha Kappa Alpha",
        "Kappa Alpha Psi",
        "Omega Psi Phi",
        "Delta Sigma Theta",
        "Phi Beta Sigma",
        "Zeta Phi Beta",
        "Sigma Gamma Rho",
        "Iota Phi Theta",
      ],
      metaTags: [],
      metaTitle: "",
      showsuccess: false,
      selectionList: [],
      dict: [],
      availSeating: "",
      ticketToShowAgainst: ["tk_WpO1v70bPo47pAi"],
      individualsData: [
        "tk_zUJ6Jr7vLwqHfWD",
        "tk_unNfZdMzmbPS62K",
        "tk_WpO1v70bPo47pAi",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_zUJ6Jr7vLwqHfWD: {
          messages: ["Ticket Price: $175", "Processing fee of $13.38"],
        },

        tk_unNfZdMzmbPS62K: {
          messages: ["Ticket Price: $200", "Processing fee of $15.05"],
        },

        tk_WpO1v70bPo47pAi: {
          messages: ["Ticket Price: $200", "Processing fee of $15.05"],
        },
      },

      priceIds: {
        tk_zUJ6Jr7vLwqHfWD: "price_1QPw3aD0vTZ4QB9cBHlzPyQe",
        tk_unNfZdMzmbPS62K: "price_1QR2P1D0vTZ4QB9cQIuFbGoC",
        tk_WpO1v70bPo47pAi: "",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,

      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
      states: [
        { "States in USA": "Alabama" },
        { "States in USA": "Alaska" },
        { "States in USA": "Arizona" },
        { "States in USA": "Arkansas" },
        { "States in USA": "Califonria" },
        { "States in USA": "Colorado" },
        { "States in USA": "Connecticut" },
        { "States in USA": "Delaware" },
        { "States in USA": "Dictrict of Columbia" },
        { "States in USA": "Florida" },
        { "States in USA": "Georgia" },
        { "States in USA": "Hawaii" },
        { "States in USA": "Idaho" },
        { "States in USA": "Illinois" },
        { "States in USA": "Indiana" },
        { "States in USA": "Iowa" },
        { "States in USA": "Kansas" },
        { "States in USA": "Kentucky" },
        { "States in USA": "Louisiana" },
        { "States in USA": "Maine" },
        { "States in USA": "Maryland" },
        { "States in USA": "Massachusetts" },
        { "States in USA": "Michigan" },
        { "States in USA": "Minnesota" },
        { "States in USA": "Mississippi" },
        { "States in USA": "Missouri" },
        { "States in USA": "Montana" },
        { "States in USA": "Nebraska" },
        { "States in USA": "Nevada" },
        { "States in USA": "New Hampshire" },
        { "States in USA": "New Jersey" },
        { "States in USA": "New Mexico" },
        { "States in USA": "New York" },
        { "States in USA": "North Carolina" },
        { "States in USA": "North Dakota" },
        { "States in USA": "Ohio" },
        { "States in USA": "Oklahoma" },
        { "States in USA": "Oregon" },
        { "States in USA": "Pennsylvania" },
        { "States in USA": "Rhode Island" },
        { "States in USA": "South Carolina" },
        { "States in USA": "South Dakota" },
        { "States in USA": "Tennessee" },
        { "States in USA": "Texas" },
        { "States in USA": "Utah" },
        { "States in USA": "Vermont" },
        { "States in USA": "Virginia" },
        { "States in USA": "Washington" },
        { "States in USA": "West Virginia" },
        { "States in USA": "Wisconsin" },
        { "States in USA": "Wyoming" },
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      // console.log("wwwwwwww  === ");
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }
      this.redeemData = null;
      this.redeemPriceId = "";
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";

      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      const e = {};

      this.TicketsResult[idx].availableSeating.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      this.availSeating = Object.keys(e)[0];

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (
        vm.ticketToShowAgainst.includes(
          vm.TicketsResult[vm.selected].ticketCode
        )
      ) {
        payloadToPush.priceId = this.seatsListObj.priceId;
        payloadToPush.ticketId = this.seatsListObj.ticketId;
        payloadToPush.totalTickets = this.seatCount;
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_zUJ6Jr7vLwqHfWD") {
        payloadToPush.extraInfo = {
          table: this.seatsListC,
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_WpO1v70bPo47pAi") {
        payloadToPush.extraInfo = {
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_unNfZdMzmbPS62K") {
        payloadToPush.extraInfo = {
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");

      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.chapter = "";
            vm.attendedCount = "";
            vm.hearAboutUs = "";
            vm.interested = "Yes";
            vm.firstTimeAttending = "Yes";
            vm.streetAddress = "";
            vm.city = "";
            vm.zip = "";
            vm.state = "Alabama";
            vm.greekAffiliationListC = "Alpha Phi Alpha";
            vm.seatsListC = "Not Applicable";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },

    paynowV3: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;

      // const payloadToPush = {
      //   priceId:
      //     this.TicketsResult[vm.selected].discount &&
      //     this.redeemPriceId.trim().length
      //       ? this.redeemPriceId
      //       : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
      //   eventId: this.TicketsResult[vm.selected].eventId,
      //   ticketId: this.TicketsResult[vm.selected].ticketCode,
      //   totalTickets: this.tickcount,
      //   userName: this.fullname,
      //   userEmail: this.email.toLowerCase(),
      // };

      const payloadToPush = {
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        eventId: this.TicketsResult[vm.selected].eventId,
        origin: "WEB",
        tickets: [],
      };

      var reqTicket = {};

      reqTicket["ticketId"] = this.TicketsResult[vm.selected].ticketCode;
      reqTicket["priceId"] =
        this.TicketsResult[vm.selected].discount &&
        this.redeemPriceId.trim().length
          ? this.redeemPriceId
          : this.priceIds[this.TicketsResult[vm.selected].ticketCode];
      reqTicket["totalTickets"] = this.tickcount;
      reqTicket["extraInfo"] = {};

      if (
        vm.ticketToShowAgainst.includes(
          vm.TicketsResult[vm.selected].ticketCode
        )
      ) {
        reqTicket["priceId"] = this.seatsListObj.priceId;
        reqTicket["ticketId"] = this.seatsListObj.ticketId;
        reqTicket["totalTickets"] = this.seatCount;
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_zUJ6Jr7vLwqHfWD") {
        reqTicket["extraInfo"] = {
          table: this.seatsListC,
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_WpO1v70bPo47pAi") {
        reqTicket["extraInfo"] = {
          table: this.seatsListC,
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode === "tk_unNfZdMzmbPS62K") {
        reqTicket["extraInfo"] = {
          greekAffiliation: this.greekAffiliationListC,
          chapter: this.chapter,
          interested: this.interested,
          firstTimeAttending: this.firstTimeAttending,
          attendedCount: this.attendedCount,
          hearAboutUs: this.hearAboutUs,
          city: this.city,
          state: this.state,
          streetAddress: this.streetAddress,
          zip: this.zip,
          phone: this.phone,
        };
      }

      payloadToPush.tickets.push(reqTicket);

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "request_cart_tickets_v3"
          : "requested_free_tickets");

      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data.Status === "Error") {
            vm.isSubmitted = false;
            vm.reserveFormAccept = false;
            vm.$toast.error(re.data.Message, { timeout: 4000 });
          } else {
            if (re.data && re.data.Result && re.data.Result.sessionId) {
              vm.checkout.clientReferenceId = re.data.Result.sessionId;
              localStorage.setItem("refsId", vm.checkout.clientReferenceId);
              vm.$refs.checkoutRef.redirectToCheckout();
            } else if (
              vm.TicketsResult[vm.selected].ticketType === "FREE" ||
              (vm.redeemData && vm.redeemData.price === 0)
            ) {
              vm.$toast.success(re.data.Message, { timeout: 4000 });
              vm.$refs.modal.close();
              vm.reserveFormAccept = false;
              vm.fullname = "";
              vm.email = "";
              vm.chapter = "";
              vm.attendedCount = "";
              vm.hearAboutUs = "";
              vm.interested = "Yes";
              vm.firstTimeAttending = "Yes";
              vm.streetAddress = "";
              vm.city = "";
              vm.zip = "";
              vm.state = "Alabama";
              vm.greekAffiliationListC = "Alpha Phi Alpha";
              vm.seatsListC = "Not Applicable";
              vm.isSubmitted = false;
            }

            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          }
        })
        .catch((error) => {
          console.log(error);
          vm.$toast.error(error.response.data.Message, { timeout: 4000 });
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },

    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "FoBSFgHOayElzq",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
            vm.TicketsResult.forEach((r) => {
              vm.ticksById[r.ticketCode] = r;
            });
          }

          const objtmp = {
            tk_zUJ6Jr7vLwqHfWD: null,
            tk_unNfZdMzmbPS62K: null,
            tk_WpO1v70bPo47pAi: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          // console.log(vm.eventTicketResult);
          setTimeout(() => {
            vm.drawseatsInfo(vm.eventTicketResult);
          }, 1000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    seatsForTickets() {
      if (this.seatsListObj.ticketId) {
        const res = [
          ...Array(
            this.ticksById[this.seatsListObj.ticketId].availableTickets <
              this.ticksById[this.seatsListObj.ticketId].allowedPerRequest
              ? this.ticksById[this.seatsListObj.ticketId].availableTickets
              : this.ticksById[this.seatsListObj.ticketId].allowedPerRequest
          ).keys(),
        ].map((v) => v + 1);
        return res;
      }
      return [];
    },
    seatscounts() {
      if (this.seatsListObj.ticketId) {
        const res = this.TicketsResult.filter(
          (r) => r.ticketCode === this.seatsListObj.ticketId
        );

        if (res && res.length > 0) {
          return res[0].availableTickets;
        }
        return 0;
      }
      return 0;
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invaliduphone() {
      if (this.phone.length <= 0) {
        return true;
      }
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  //   metaInfo() {
  //     console.log("HERE");
  //     return {
  //       title: this.metaTitle,
  //       meta: this.metaTags,
  //     };
  //   },

  metaInfo: {
    title: "MYLE | 52nd Annual Kappa Vegas Gala & Fundraiser",
    meta: [
      {
        name: "description",
        content: "MYLE | 52nd Annual Kappa Vegas Gala & Fundraiser",
      },
      {
        name: "keywords",
        content: "MYLE | 52nd Annual Kappa Vegas Gala & Fundraiser",
      },
      {
        name: "copyright",
        content: "MYLE",
      },
      {
        name: "url",
        content: "https://myle.com/52nd-annual-kappa-vegas-gala-fundraiser",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
#d2 {
  overflow-x: scroll;
}
</style>
