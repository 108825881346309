<template src="./Error.html"></template>

<script>
  import VueElementLoading from 'vue-element-loading';
  // Scss Imports
  import "./Error.scss";

  export default {
    name: 'errorModal',
    data() {
      return {
        isLoading: false,
        loadStyle: {
          color: '#ffffff',
          'font-weight': 'bold',
          'letter-spacing': '1rem',
          'font-size': '18px'
        },
      }
    },
    components: {
      VueElementLoading
    },
    methods: {
      explore() {
        this.$emit('closemodal', true)
      }
    },
  }
</script>
