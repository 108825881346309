<template>
  <div>
    <nav-bar />
    <!-- text -->
    <div style="margin-top: 80px" class="px-5 mx-auto">
      <p class="text-center font-weight-bold">
        MYLE<br />
        Comprehensive Incident Response Plan
      </p>

      <p>
        MYLE has established a comprehensive incident response plan to handle
        data breaches, security incidents, and any other data-related
        emergencies. The incident response plan includes the following key
        elements:
      </p>

      <h4>1.1 Incident Identification and Reporting:</h4>
      <p>
        Clearly define how employees and relevant stakeholders should identify
        and report potential incidents. Establish a designated incident response
        team responsible for overseeing the response process.
      </p>

      <div>
        <h4>1.2 Incident Categorization and Severity Assessment:</h4>
        <p>
          Develop a categorization scheme to assess the severity of incidents
          based on impact and risk. This will help prioritize response efforts
          and allocate resources accordingly.
        </p>
      </div>

      <div>
        <h4>1.3 Incident Containment and Mitigation:</h4>
        <p>
          Establish procedures for containing and mitigating incidents to
          prevent further damage and limit the impact on data and systems. This
          may involve isolating affected systems and implementing temporary
          measures to protect data.
        </p>
      </div>

      <div>
        <h4>1.4 Forensic Preservation:</h4>
        <p>
          Enact protocols to preserve evidence and data related to the incident
          for forensic analysis. This may involve employing write-blocking
          tools, imaging affected systems, and maintaining proper chain of
          custody.
        </p>
      </div>

      <div>
        <h4>1.5 Investigation and Root Cause Analysis:</h4>
        <p>
          Conduct a thorough investigation to determine the root cause of the
          incident. Identify vulnerabilities and gaps in security to prevent
          similar incidents in the future.
        </p>
      </div>

      <div>
        <h4>1.6 Communication and Reporting:</h4>

        <p>
          Define communication channels and stakeholders who need to be informed
          about the incident. Prepare communication templates for internal and
          external audiences to ensure a consistent and timely response.
        </p>
      </div>

      <div>
        <h4>1.7 Recovery and Restoration:</h4>

        <p>
          Develop recovery procedures to restore affected systems and data to
          normal operation. Regularly test data restoration processes to ensure
          their effectiveness.
        </p>
      </div>

      <div>
        <h4>1.8 Documentation and Post-Incident Review:</h4>

        <p>
          Maintain detailed documentation of incident response activities for
          future reference and learning. Conduct post-incident reviews to assess
          the effectiveness of the response and identify areas for improvement.
        </p>
      </div>

      <div>
        <h3>Forensic Controls and Processes:</h3>

        <p>
          To support incident response and forensic analysis, MYLE will
          implement the following forensic controls and processes:
        </p>

        <h4>2.1 Logging and Monitoring:</h4>

        <p>
          Enable robust logging and monitoring mechanisms to capture detailed
          information about system activities and potential security events.
        </p>
      </div>

      <div>
        <h4>2.2 Chain of Custody:</h4>

        <p>
          Establish a secure chain of custody process to ensure the integrity
          and admissibility of digital evidence collected during investigations.
        </p>
      </div>

      <div>
        <h4>2.3 Data Capture and Preservation:</h4>

        <p>
          Employ data capture and preservation techniques that maintain the
          original state of evidence and prevent alteration during
          investigations.
        </p>
      </div>

      <div>
        <h4>2.4 Forensic Toolset:</h4>
        <p>
          Equip the incident response team with appropriate forensic tools to
          conduct investigations effectively and efficiently.
        </p>
      </div>

      <div>
        <h4>2.5 Forensic Expertise:</h4>
        <p>
          Ensure that the incident response team includes individuals with
          expertise in digital forensics to handle complex investigations.
        </p>
      </div>

      <div>
        <h3>Incident and Problem Management Processes:</h3>
        <p>
          MYLE has adopted the following incident and problem management
          processes to track and resolve data-related incidents and issues:
        </p>

        <h4>3.1 Incident Management:</h4>

        <p>
          Implement a structured incident management process to record, track,
          and resolve data-related incidents. Assign clear responsibilities and
          establish service level agreements (SLAs) for incident resolution.
        </p>
      </div>

      <div>
        <h4>3.2 Problem Management:</h4>
        <p>
          Set up problem management procedures to identify the root cause of
          recurring incidents and implement permanent solutions.
        </p>
      </div>

      <div>
        <h4>3.3 Escalation and Communication:</h4>

        <p>
          Establish escalation paths for incidents that require higher-level
          attention. Communication protocols will be defined to keep
          stakeholders informed about the status and progress of incident
          resolution.
        </p>
      </div>

      <div>
        <h3>
          Notification Process for Breaches and Integration with Client Systems:
        </h3>

        <h4>4.1 Breach Notification:</h4>
        <p>
          In the event of a data breach or security incident involving customer
          data, MYLE will adhere to applicable data protection laws and
          regulations. As part of the incident response plan, a notification
          process will be followed to inform affected individuals, regulatory
          authorities, and other relevant parties.
        </p>
      </div>

      <div>
        <h4>4.2 Integration with Client Systems:</h4>

        <p>
          If MYLE's services involve processing data on behalf of its clients,
          the company will establish integration protocols and contractual
          obligations for notifying clients in case of any security incidents or
          data breaches that may impact their data.
        </p>
      </div>

      <div>
        <p>
          Integration with client systems will include clear communication
          channels and response timelines to ensure prompt and transparent
          communication. Any incident involving client data will be handled with
          the utmost urgency and cooperation to maintain trust and protect
          client interests.
          <br />
          By implementing these information security measures, MYLE will
          proactively safeguard our data, respond effectively to incidents, and
          ensure compliance with data protection regulations while maintaining
          the trust of its clients and customers.
        </p>
      </div>
    </div>

    <!-- text -->

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
<style scoped>
.heading {
  font-size: 0.75rem;
  font-weight: bold;
}

li {
  list-style-type: disc;
  margin-left: 2rem;
}
</style>
