import { render, staticRenderFns } from "./TuxedosandTennisShoesAnnualGala.vue?vue&type=template&id=31be26d3&scoped=true"
import script from "./TuxedosandTennisShoesAnnualGala.vue?vue&type=script&lang=js"
export * from "./TuxedosandTennisShoesAnnualGala.vue?vue&type=script&lang=js"
import style0 from "./TuxedosandTennisShoesAnnualGala.vue?vue&type=style&index=0&id=31be26d3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31be26d3",
  null
  
)

export default component.exports