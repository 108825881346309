<template>
  <div style="background-color: #141414">
    <vue-element-loading
      :active="loader"
      text="Loading"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <div id="tickBtn" style="position: absolute">
      <div
        class="scroller btn solid-btn-GEAA100Inverted"
        v-if="selectedCarts.length > 0"
        @click="scrolltoOrder()"
      >
        View Cart
      </div>
    </div>

    <nu-modal id="ele21" ref="GEAAVerificationModal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="text-center">
              <span
                class="w-auto d-inline-block"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.2em;
                  color: #df0900;
                "
              >
                GEAA Member Verification
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-md-12 mb-4 text-center"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 600;
              font-size: 1.2em;
              color: black;
            "
          >
            Please enter your email address for verification purposes as a Paid
            GEAA Member.
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <input
              type="email"
              class="form-control mb-3"
              placeholder="Email"
              v-model="verifyEmailLink"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-center mt-4">
            <button
              @click="verifye()"
              :disabled="!verifyEmailLink.trim().length || !cverifyEmailLink"
              class="btn solid-btn-GEAA100Inverted m-auto mr-2"
              style="
                pointer-events: all;
                cursor: pointer;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                font-size: 1.2em;
                font-weight: bold;
              "
            >
              Verify Now
            </button>
          </div>
        </div>
      </div>
    </nu-modal>

    <nu-modal id="ele1" ref="modal" :hideCloseButton="showcreateForm">
      <div id="dataholder">
        <div class="container-fluid" v-if="showcreateForm">
          <div class="row">
            <div class="col-md-12 text-center">
              <h1
                class="w-auto d-inline-block"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 2.2em;
                  color: #df0900;
                "
              >
                Complete Meal Selection
              </h1>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <span
                class="w-auto d-inline-block text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.6em;
                  color: black;
                "
              >
                Tailgate Meal Options </span
              ><br />
              <span
                class="w-auto d-inline-block text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.1em;
                  color: #df0900;
                "
              >
                Select a Meal (Required Selection: 1) </span
              ><br />

              <select class="form-control mb-3" v-model="gaType">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in meals"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-12 mt-3">
              <span
                class="w-auto d-inline-block text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.6em;
                  color: black;
                "
              >
                Drink Options </span
              ><br />
              <span
                class="w-auto d-inline-block text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.1em;
                  color: #df0900;
                "
              >
                Select drinks of your choice (Required Selection: 2)
              </span>
              <!-- <DoubleList @listtopush="listtopush" :list="drinks" :limit="2" /> -->
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-4">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Liquors and Mixed Drinks</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Liquors and Mixed Drinks')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Liquors and Mixed Drinks"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Liquors and Mixed Drinks')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-4">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Beer</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Beer')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Beer"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Beer')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-4">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Wine & Premixed Drinks</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Wine & Premixed Drinks')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Wine & Premixed Drinks"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Wine & Premixed Drinks')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-5">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Juices</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Juices')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Juices"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Juices')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-5">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Soda</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Soda')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Soda"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Soda')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="row text-center mt-5">
                <div class="col-md-12">
                  <label
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1.3em;
                      color: black;
                    "
                    >Bottled Water</label
                  >
                  <div class="flex items-center" style="margin: auto">
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="subtractCount('Bottled Water')"
                    >
                      —
                    </button>
                    <span
                      class="text-center font-medium text-lg border w-16 rounded-lg py-2 px-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                      "
                      >{{ bidValue["Bottled Water"] }}</span
                    >
                    <button
                      type="button"
                      class="m-3 py-1 px-2 border-0"
                      style="
                        background-color: transparent;
                        font-size: 22px;
                        color: #df0900;
                      "
                      @click="addCount('Bottled Water')"
                    >
                      ＋
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-3"></div>

            <div class="col-md-3 mt-4 text-center">
              <input
                type="button"
                @click="saveNewForm()"
                class="btn solid-btn-GEAA100Inverted"
                value="Submit"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <div class="col-md-3 mt-4 text-center">
              <input
                type="button"
                @click="closenewForm()"
                class="btn solid-btn-GEAA100Inverted"
                value="Close"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>

        <div class="container-fluid" v-if="!showcreateForm">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  v-if="selectedGa === ''"
                >
                  Food and Beverages
                </span>

                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  v-if="selectedGa != ''"
                >
                  Registration Form
                </span>
              </div>
            </div>

            <div class="col-md-6 mt-4 mb-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                First Name
              </span>
              <input
                type="text"
                placeholder="Enter your first name"
                class="form-control"
                v-model="userFirstName"
              />
              <span
                v-if="isclicked && userFirstName.trim().length <= 0"
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: red;
                "
              >
                Please enter your first name
              </span>
            </div>
            <div class="col-md-6 mt-4 mb-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Last Name
              </span>
              <input
                type="text"
                placeholder="Enter your last name"
                class="form-control"
                v-model="userLastName"
              />
              <span
                v-if="isclicked && userLastName.trim().length <= 0"
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: red;
                "
              >
                Please enter your last name
              </span>
            </div>
            <div class="col-md-6 mt-4 mb-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Email Address
              </span>
              <input
                type="email"
                placeholder="Enter your email address"
                class="form-control"
                v-model="userEmail"
              />
              <span
                v-if="
                  isclicked && (userEmail.trim().length <= 0 || !invalidEmail)
                "
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: red;
                "
              >
                Please enter your email address
              </span>
            </div>

            <!-- <div class="col-md-6 mt-4 mb-3" v-if="isGuest">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Guest Type
              </span>

              <select class="form-control" v-model="ttype">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'Sons of Diggs',
                    'Sons and Daughters of Booker T.',
                    'Friends of GEAA',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div> -->

            <hr class="w-100 p-2" />

            <div class="col-md-12 mb-3">
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: #000000;
                  "
                  v-if="selectedGa === ''"
                >
                  Note: Meals include two (2) sides that you may select at the
                  event. Additional sides may be selected in advance and are
                  provided as A la carte options. Tap below to select.
                  <br />
                  <br />
                </span>

                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.6em;
                    color: #df0900;
                  "
                >
                  Instructions:
                </span>
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <div class="">
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: black;
                  "
                  v-if="selectedGa === ''"
                >
                  <span style="font-family: 'nunito', sans-serif">
                    1. Select Food and/orBeverages option(s) then add to cart
                    <br />
                    2. Repeat for each item <br />
                    3. Scroll down to checkout
                  </span>
                  <br />
                </p>
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: black;
                  "
                  v-if="selectedGa != '' && selectedGa == 'tk_0WLaBdaE56L65lz'"
                >
                  <span style="font-family: 'nunito', sans-serif">
                    1. Register by selecting GEAA Paid Members in the Ticket
                    Type field
                    <br />
                    2. Select Quantity (1) and Add To Cart.
                    <br />
                    3. Complete Meal Selection (Registration includes 1 Meal + 2
                    Drinks) and Submit
                    <br />
                    4. Your Registration Options will be added to your cart
                    after tapping Submit
                    <br />
                    5. For additional meals or beverages, go back to the Ticket
                    Type field and select Additional GEAA Member Meal if it is a
                    plate for YOU, Family Members/Guest, Additional Sides or
                    Drinks. Add to the Cart and continue until all selections
                    are made. Double check your cart before tapping the Purchase
                    Now button.
                    <br />
                    <br />
                    Note: Specific meal and drink options are ONLY required for
                    Registered Member Plates. All meals selected beyond the GEAA
                    Registration Meal are A la carte. Additional meals do not
                    include beverages. Beverages must be added to separately to
                    the cart.
                    <br />
                    <br />
                    The paid member registration and meal selections for
                    members, family and guest may only be completed once during
                    initial registration. For additional items after
                    registration, please use the Guest Option button near the
                    bottom of the main page.
                  </span>
                  <br />
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: black;
                  "
                  v-if="selectedGa != '' && selectedGa == 'tk_TG1cM3JiKLKTJjU'"
                >
                  <span style="font-family: 'nunito', sans-serif">
                    1. Register by selecting GEAA Non-Paid Members in the Ticket
                    Type field
                    <br />
                    2. Select Quantity (1) and Add To Cart.
                    <br />
                    3. Complete Meal Selection (Registration includes 1 Meal + 2
                    Drinks) and Submit
                    <br />
                    4. Your Registration Options will be added to your cart
                    after tapping Submit
                    <br />
                    5. For additional meals or beverages, go back to the Ticket
                    Type field and select Additional GEAA Member Meal if it is a
                    plate for YOU, Family Members/Guest, Additional Sides or
                    Drinks. Add to the Cart and continue until all selections
                    are made. Double check your cart before tapping the Purchase
                    Now button.
                    <br />
                    <br />
                    Note: Specific meal and drink options are ONLY required for
                    Registered Member Plates. All meals selected beyond the GEAA
                    Registration Meal are A la carte. Additional meals do not
                    include beverages. Beverages must be added to separately to
                    the cart.
                    <br />
                    <br />
                    The paid member registration and meal selections for
                    members, family and guest may only be completed once during
                    initial registration. For additional items after
                    registration, please use the Guest Option button near the
                    bottom of the main page.
                  </span>
                  <br />
                </p>
              </div>
            </div>

            <div class="col-md-12 mb-3 mt-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  v-if="selectedGa === ''"
                >
                  Select Option(s)
                </span>

                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  v-if="selectedGa != ''"
                >
                  Select Ticket(s)
                </span>
              </div>
            </div>

            <!-- Ticket Options -->
            <div class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                v-if="selectedGa === ''"
                >Food / Beverages</label
              >
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                v-if="selectedGa != ''"
                >Ticket Type</label
              >

              <select
                @change="dayChange('daylist')"
                class="form-control"
                v-model="daylist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(
                    completeList,
                    selectedGa === 'tk_0WLaBdaE56L65lz',
                    false,
                    selectedGa === ''
                  )"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Quantity</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist.length == 0 &&
                    daylist &&
                    initvalue[daylist.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option
                  disabled
                  v-if="daylist && initvalue[daylist.ticketCode] == 0"
                >
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center">
              <p class="mt-0 mb-2">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Price Per Item:</b
                >
                ${{
                  ttypelist && ttypelist.price
                    ? ttypelist.price.toFixed(2)
                    : "0.00"
                }}
              </p>

              <p class="mt-0 mb-2">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Fees:</b
                >
                ${{
                  ttypelist && ttypelist.fees
                    ? ttypelist.fees.toFixed(2)
                    : "0.00"
                }}
              </p>

              <p class="mt-0 mb-0">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Price:</b
                >
                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist')"
                class="btn solid-btn-GEAA100Inverted m-auto mr-2"
                :disabled="!daylist || !tickcount"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <div
              class="col-md-12 mt-1"
              v-if="nonDonations(sections).length > 0"
            >
              <hr class="w-100" />

              <div class="text-center">
                <span
                  class="ml-1 w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  >Complete Meal Selection</span
                >
              </div>
            </div>

            <div
              class="col-md-12 mt-4"
              v-bind:key="rec1.ticketCode"
              v-for="rec1 in nonDonations(sections)"
            >
              <label
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 900;
                  font-size: 1.3em;
                  color: #e28332;
                "
                >{{ rec1[0].ticketName }}</label
              >
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Member
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Status
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in rec1" v-bind:key="r">
                    <td
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.05em;
                        color: black;
                      "
                    >
                      <p>Member #{{ i + 1 }}</p>
                    </td>
                    <td
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.05em;
                        color: black;
                      "
                    >
                      <p>
                        {{
                          r.data && r.data.bidValue && isValid(r)
                            ? "Information Submitted"
                            : "Details Required"
                        }}
                      </p>
                    </td>
                    <td style="text-align: right; cursor: pointer">
                      <input
                        type="button"
                        @click="newForm(r)"
                        class="btn solid-btn-GEAA100Inverted m-auto mr-2"
                        value="Select Options"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1.2em;
                          font-weight: bold;
                        "
                      />
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 10px;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                      "
                    >
                      <p class="text-center">No Tickets</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Donation Options -->
            <div class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                >
                  Donate to Hospitality Fund
                </span>
              </div>
            </div>

            <div class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Donation Type</label
              >
              <select
                @change="dayChange('daylist1')"
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(
                    completeList,
                    false,
                    true,
                    false
                  )"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Donation(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [...tickcountlist1]"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    daylist1 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option
                  disabled
                  v-if="daylist1 && initvalue[daylist1.ticketCode] == 0"
                >
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center">
              <p class="mt-0 mb-2">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Donation Amount:</b
                >
                ${{
                  ttypelist1 && ttypelist1.price
                    ? ttypelist1.price.toFixed(2)
                    : "0.00"
                }}
              </p>

              <p class="mt-0 mb-2">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Fees:</b
                >
                ${{
                  ttypelist1 && ttypelist1.fees
                    ? ttypelist1.fees.toFixed(2)
                    : "0.00"
                }}
              </p>

              <p class="mt-0 mb-0">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-GEAA100Inverted m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>
            <!-- Ticket Options -->

            <hr class="w-100 p-2" />

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #df0900;
                  "
                  >Cart Details</span
                >
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Selected item(s)
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>
                        {{
                          r.daylist
                            ? r.daylist.ticketName
                            : r.daylist1.ticketName
                        }}
                      </p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>${{ r.price.toFixed(2) }}</p>
                    </td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          ❌</span
                        >
                      </p>
                    </td>
                  </tr>

                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 10px;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                      "
                    >
                      <p class="text-center">No Tickets</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="w-100" />

            <div
              id="ordersum"
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div
              class="col-md-12 mt-3"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: black;
              "
            >
              <input
                type="checkbox"
                v-model="reserveFormAcceptAboutAgeRestriction"
              />
              I accept that GEAA is not responsible for any injuries, including
              death, accidents, lost or stolen items, or damages incurred during
              or after the experience at Tuskegee University's 100th Homecoming
              celebration.
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowcustom()"
                class="btn solid-btn-GEAA100Inverted m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Purchase Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_GEAA100thCentennialHomecoming_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.7rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #fffde4"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 3em;
                  color: #df0900;
                "
              >
                <!-- Delta Ride For the Fight -->
                GEAA - Celebrating the 100th Year Centennial Homecoming
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                  color: #df0900;
                "
              >
                November 08 - 10, 2024
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.3em;
                "
              >
                <a
                  href="https://maps.app.goo.gl/7GKrcC6LuM8PE7NM9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #df0900"
                >
                  GEAA Tent City
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row justify-content-center mt-4">
              <div class="col-md-12">
                <div class="mt-2">
                  <div
                    class="row text-center align-items-center justify-content-between"
                  >
                    <div class="col-lg-12 col-md-12">
                      <div class="align-items-center justify-content-between">
                        <div class="align-items-center justify-content-between">
                          <!-- :disabled="true"
                            v-bind:class="{
                              'bg-disable': true,
                            }" -->
                          <button
                            type="submit"
                            @click="opencart('', true)"
                            class="btn solid-btn-GEAA100 m-auto"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            Food / Drink Options
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-1 col-md-1"></div>
              <div class="col-md-10 col-md-10">
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 2em;
                    font-weight: 700;
                    color: #f9fdef;
                  "
                >
                  Celebrating the 100th Year Centennial Homecoming for Mother
                  Tuskegee
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  On behalf of the Gamma Epsilon Alumni Association (GEAA), it
                  is our great pleasure to welcome you all to the 100th
                  Centennial Celebration of Tuskegee Homecomings. As a proud
                  non-profit organization, GEAA is honored to sponsor this
                  momentous occasion, celebrating a century of tradition, unity,
                  and excellence.

                  <br />
                  <br />

                  We are thrilled to welcome back all family, friends, and
                  alumni to join us in this grand celebration. The festivities
                  will commence at 12 PM on Friday, November 8, and will
                  continue through to 7 AM on Sunday, November 10.

                  <br />
                  <br />
                  Let us come together to honor our past, celebrate our present,
                  and look forward to a bright future. Thank you for being a
                  part of this historic event.

                  <br />
                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Event Details:</span
                  >
                  <br />
                  📅 Date: Friday, November 8 - 10, 2024
                  <br />
                  ⏰ Time: See the flyer below
                  <br />
                  📍 Location: GEAA Tent City, Cleve Abbott Memorial Stadium,
                  1200 West Old Montgomery Rd, Tuskegee, AL36088
                  <br />
                  👔 Dress Code: Tuskegee’s Finest to celebrate the 100th Year
                  Centennial Homecoming.
                  <br />
                  🥂 Food and Beverages: Available for purchase throughout
                  events.
                  <br />
                </p>

                <br />
                <div
                  class="row text-center"
                  style="margin: auto; background: transparent"
                >
                  <div class="col-md-1"></div>
                  <div class="col-md-10" style="z-index: 90">
                    <vue-flux
                      :options="vfOptions"
                      :images="vfEventImgs"
                      :transitions="vfTransitions"
                      ref="slider"
                      style=""
                    >
                      <template v-slot:preloader>
                        <flux-preloader />
                      </template>
                      <template v-slot:controls>
                        <flux-controls />
                      </template>
                    </vue-flux>
                  </div>
                  <div class="col-md-1 col-md-1"></div>
                </div>
                <hr style="background-color: #5b5b5b" />
                <br />

                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  A substantial portion of our sales proceeds will be allocated
                  to the Tuskegee-GEAA endowment, reflecting our ongoing
                  commitment to supporting Mother Tuskegee.
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Event Highlights:
                  </span>
                </p>

                <div class="row mt-2">
                  <div class="col-md-2 col-md-2"></div>

                  <div class="col-md-8 col-md-8">
                    <div class="text-center">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_GEAA100thCentennialHomecoming_V1.jpg"
                        class="w-100 img-fluid"
                        style="border-radius: 3%"
                      />
                    </div>
                  </div>

                  <div class="col-md-2 col-md-2"></div>
                </div>
                <br />

                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    GEAA Tailgate - Food & Beverages Info
                  </span>
                </p>
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; color: red"
                  >
                    FOOD ORDERS MUST BE COMPLETED BY MIDNIGHT ON 10/31.
                    <br />
                    WALK-UP MEALS WILL BE VERY LIMITED AND NOT GUARANTEED!
                  </span>
                </p>
                <br />
                <br />

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Tailgate MENU Options (Combos include two sides):
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • Smoked chicken + (2) sides & bread<br />
                    • Fried fish + (2) sides & bread <br />
                    • Ribs + (2) sides & bread<br />
                    • Sausage w/veggies + (1) side<br />
                    • Vegetarian Sandwich + (2) sides<br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Additional Sides - $5 additional
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • French fries<br />
                    • Cajun rice<br />
                    • Sweet corn<br />
                    • Green beans<br />
                    • BBQ beans<br />
                    • Mac & Cheese<br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Liquors and Mixed Drinks - $10
                  </span>

                  <br />
                  <span style="font-family: 'nunito', sans-serif">
                    • Vodka <br />
                    • Whiskey <br />
                    • Bourbon <br />
                    • Tequila <br />
                    • Extensive Mixed Drink Options <br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Beer - $5
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • Domestic<br />
                    • Import<br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Wine & Premixed Drinks - $5
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • Moscato<br />
                    • Sweet Red<br />
                    • Chardonnay<br />
                    • Margaritas<br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Juices – 2 for $4
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • Cranberry <br />
                    • Pineapple <br />
                    • Orange <br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Sodas – 2 for $4
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • Coke <br />
                    • Spirit <br />
                    • Ginger Ale <br />
                  </span>

                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Bottled Water – 2 for $3
                  </span>

                  <br /><br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    BYOB Table Set Up (Includes Ice and two chasers) - $7
                  </span>

                  <br />
                  <br />

                  <span
                    class="bolded"
                    style="font-family: 'nunito', sans-serif; font-size: 1.5em"
                  >
                    Important Notes:
                  </span>

                  <br />

                  <span style="font-family: 'nunito', sans-serif">
                    • FOOD ORDERS MUST BE SECURED BY 10/31. NO EXCEPTIONS <br />
                    • ARRIVE EARLY!!! ARRIVE EARLY!!!<br />
                    • PRE-PAID FOOD IS NOT GUARANTEED AFTER THE 3RD QUARTER.
                    <br />
                    •
                    <span
                      class="bolded"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 1.3em;
                      "
                    >
                      IF
                    </span>
                    EXCESS FOOD EXIST, PLATES WILL BE AVAILABLE FOR SALE AFTER
                    THE 3RD QUARTER<br />
                    • ALL SALES ARE FINAL. NO REFUNDS <br />
                    • GEAA is not responsible for any injuries, including death,
                    accidents, lost or stolen items, or damages incurred during
                    or after the experience at Tuskegee University's 100th
                    Homecoming celebration.
                  </span>
                </p>

                <br />
                <br />
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #f9fdef;
                  "
                >
                  A substantial portion of our sales proceeds will be allocated
                  to the Tuskegee-GEAA endowment, reflecting our ongoing
                  commitment to supporting Mother Tuskegee.
                </p>
              </div>
              <div class="col-md-1 col-md-1"></div>
            </div>

            <hr style="background-color: #5b5b5b" />

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="mt-2">
                  <div class="text-center">
                    <!-- <p
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 2.8em;
                        color: #df0900;
                      "
                    >
                      Registration
                    </p> -->

                    <p
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.8em;
                        color: #df0900;
                      "
                    >
                      REGISTRATION AND PRE-ORDERS HAVE ENDED!
                      <br />

                      Beverages will be available for purchase during the
                      tailgate on Saturday, November 9.
                    </p>
                  </div>

                  <div
                    class="row text-center align-items-center justify-content-between"
                  >
                    <div class="col-lg-12 col-md-12">
                      <div class="align-items-center justify-content-between">
                        <div
                          class="align-items-center justify-content-between mt-3 mb-3"
                        >
                          <button
                            :disabled="true"
                            v-bind:class="{
                              'bg-disable': true,
                            }"
                            type="submit"
                            @click="openGEAAVerify()"
                            class="btn solid-btn-GEAA100 m-auto"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            GEAA Paid Member
                          </button>

                          <h2
                            class="text-center mt-3"
                            style="
                              color: #df0900;
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 700;
                            "
                          >
                            OR
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="align-items-center justify-content-between">
                        <div
                          class="align-items-center justify-content-between mb-3"
                        >
                          <button
                            :disabled="true"
                            v-bind:class="{
                              'bg-disable': true,
                            }"
                            type="submit"
                            @click="opencart('tk_TG1cM3JiKLKTJjU', false)"
                            class="btn solid-btn-GEAA100 m-auto"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'nunito', sans-serif;
                              font-weight: 700;
                              font-size: 1.2em;
                              font-weight: bold;
                            "
                          >
                            GEAA Non-Paid Member
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-md-12">
              <div class="mt-2">
                <div class="text-center">
                  <p
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 2.8em;
                      color: #df0900;
                    "
                  >
                    Not a GEAA Member?
                  </p>
                </div>

                <div
                  class="row text-center align-items-center justify-content-between"
                >
                  <div class="col-lg-12 col-md-12">
                    <div class="align-items-center justify-content-between">
                      <div
                        class="align-items-center justify-content-between mt-3 mb-3"
                      >
                        <!-- :disabled="true"
                          v-bind:class="{
                            'bg-disable': true,
                          }" -->
                        <button
                          type="submit"
                          @click="opencart('', true)"
                          class="btn solid-btn-GEAA100 m-auto"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-family: 'nunito', sans-serif;
                            font-weight: 700;
                            font-size: 1.2em;
                            font-weight: bold;
                          "
                        >
                          Guest Options
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #fffde4"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-5">
            <div class="section-heading mb-5">
              <h2
                class="text-center"
                style="
                  color: #df0900;
                  font-family: 'nunito', sans-serif;
                  font-size: 2.7em;
                  font-weight: 700;
                "
              >
                Download today and try it for yourself
              </h2>
              <div
                class="download-btn mt-4 text-center animate__animated animate__fadeIn"
              >
                <a
                  href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                  class="btn solid-btn-GEAA100Inverted mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-apple"></span> App Store</a
                >
                <a
                  href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                  class="btn solid-btn-GEAA100Inverted mr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-android"></span> Google Play</a
                >
                <a
                  href="https://web.myle.com"
                  class="btn solid-btn-GEAA100Inverted"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><span class="ti-world"></span> MYLE Web</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mock_GEAA100thCentennialHomecoming.png"
          style="width: 100%; max-width: 100%; height: auto"
        /><br />
        <br />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 1.2em;
            color: white;
          "
        >
          🗣️ For a seamless registration, ticketing and check-in experience,
          we've partnered with MYLE - Make Your Life Entertaining! MYLE is a
          Skegee Alumni owned, D9 Owned and Women Led AI-based event and
          entertainment platform.

          <br />
          <br />

          Download the MYLE Events App via
          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or find it in your iOS or Google Play Store under MYLE Events. Don't
          forget to use referral code “GEAA" during account set-up!

          <br />
          <br />
          Note: Your tickets will be available on the MYLE App in the Account ->
          Tickets section after registration. You can easily access them via
          email (check spam if not received) or upload them to your phone wallet
          for quick access into the event.

          <br />
          <br />
          The MYLE App offers convenient transportation options, integrating
          Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your one-stop
          solution for a hassle-free travel experience.

          <br />
          <br />
          To host your events on MYLE, visit

          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or email

          <a href="mailto:hello@myle.com"> hello@myle.com</a> and be sure to
          mention GEAA!
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import "bootstrap/dist/css/bootstrap.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

import confirmationPopup from "../../components/confirmationPopup";
import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

export default {
  name: "newyear",
  data() {
    return {
      isGuest: false,
      // ttype: "Sons of Diggs",
      ttype: "",
      positions: {
        tk_0WLaBdaE56L65lz: null,
        tk_TG1cM3JiKLKTJjU: null,
        // additional
        tk_CfpG6t6j0wrpyXC: null,
        tk_GZUWql5I4SQpqH9: null,
        tk_BCntBZkLbCZ4pYb: null,
        tk_oHZAqCknKHzzcr3: null,
        tk_yuszTkBvirGQXsR: null,
        tk_VksQkUhugJ6DO4o: null,
        //family
        tk_dpxvxsabIwsbqLB: null,
        tk_U6rtblt6rJY8Asv: null,
        tk_LyInlXW7ddfHXNZ: null,
        tk_LMBcmtgwSIE9t7S: null,
        tk_GxOGEEqprerRHrz: null,
        //son of dig
        // tk_8PTghLAPLptrHqI: null,
        // // son and daughters
        // tk_lH1VfzNKHFMq5sy: null,
        // // friends
        // tk_f9hvvx0fmFdQyBk: null,

        //MEAL TICKS
        tk_UKE2YQZV0KFcVvD: null,
        tk_X5SmrXU1VKEgEor: null,
        tk_8caMd063A8UHAzp: null,
        tk_GR0enAst0r6nliL: null,
        tk_jLE1kIIZmwhEbNv: null,

        // Sides
        tk_IBbN6PLme7Xpuvp: null,
        tk_vkazVY56rbRODiy: null,
        tk_OAp3lDWJJzWj2MF: null,
        tk_jjclyZplPzVKRIn: null,
        tk_mOZvdy3lqN9Evx5: null,
        tk_KZoeTSN7gc6CB2S: null,
        tk_YHFazEe2VrpzkVG: null,
        // bevrages
        tk_moFsheIL67WgUor: null,
        tk_xvUaovYvsgCVdpy: null,
        tk_4VWrbZ7wKQVkk8w: null,
        tk_pMXJzwPMUfYmKvg: null,
        tk_cTsIsHPazjzloq2: null,
        tk_K7Wo2wRrFZxXIgz: null,
        tk_wQS8oUNZW4AtYWR: null,
        // donations
        tk_wci3aJDTelc8WJn: null,
        tk_x2NOdq0TNjQEYGS: null,
        tk_B4WR4P2s1D6j1DO: null,
        tk_Fj1WnFpUCf0Lu5q: null,
      },
      bidValue: {
        "Liquors and Mixed Drinks": 0,
        Beer: 0,
        "Wine & Premixed Drinks": 0,
        Juices: 0,
        Soda: 0,
        "Bottled Water": 0,
      },
      meals: [
        "Smoked chicken + (2) sides & bread",
        "Fried fish + (2) sides & bread",
        "Ribs + (2) sides & bread",
        "Sausage w/veggies + (1) side",
        "Vegetarian Sandwich + (2) sides",
      ],
      selectedGa: "",
      gaType: "",

      guestTickets: [
        // "tk_KZoeTSN7gc6CB2S",
        // "tk_mOZvdy3lqN9Evx5",
        // "tk_jjclyZplPzVKRIn",
        // "tk_OAp3lDWJJzWj2MF",
        // "tk_vkazVY56rbRODiy",
        // "tk_IBbN6PLme7Xpuvp",

        // "tk_UKE2YQZV0KFcVvD",
        // "tk_X5SmrXU1VKEgEor",
        // "tk_8caMd063A8UHAzp",
        // "tk_GR0enAst0r6nliL",
        // "tk_jLE1kIIZmwhEbNv",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ],

      ngaTickets: [
        "tk_TG1cM3JiKLKTJjU",
        "tk_KZoeTSN7gc6CB2S",
        "tk_mOZvdy3lqN9Evx5",
        "tk_jjclyZplPzVKRIn",
        "tk_OAp3lDWJJzWj2MF",
        "tk_vkazVY56rbRODiy",
        "tk_IBbN6PLme7Xpuvp",
        // "tk_8PTghLAPLptrHqI",
        // "tk_lH1VfzNKHFMq5sy",
        // "tk_f9hvvx0fmFdQyBk",
        "tk_GxOGEEqprerRHrz",
        "tk_LMBcmtgwSIE9t7S",
        "tk_LyInlXW7ddfHXNZ",
        "tk_U6rtblt6rJY8Asv",
        "tk_dpxvxsabIwsbqLB",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ],
      gaTickets: [
        // "tk_0WLaBdaE56L65lz",
        // "tk_KZoeTSN7gc6CB2S",
        // "tk_mOZvdy3lqN9Evx5",
        // "tk_jjclyZplPzVKRIn",
        // "tk_OAp3lDWJJzWj2MF",
        // "tk_vkazVY56rbRODiy",
        // "tk_IBbN6PLme7Xpuvp",
        // "tk_yuszTkBvirGQXsR",
        // "tk_oHZAqCknKHzzcr3",
        // "tk_BCntBZkLbCZ4pYb",
        // "tk_GZUWql5I4SQpqH9",
        // "tk_CfpG6t6j0wrpyXC",
        // "tk_GxOGEEqprerRHrz",
        // "tk_LMBcmtgwSIE9t7S",
        // "tk_LyInlXW7ddfHXNZ",
        // "tk_U6rtblt6rJY8Asv",
        // "tk_dpxvxsabIwsbqLB",

        // "tk_8PTghLAPLptrHqI",
        // "tk_lH1VfzNKHFMq5sy",
        // "tk_f9hvvx0fmFdQyBk",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ],
      drinks: [
        {
          alias: "Liquors and Mixed Drinks",
        },
        {
          alias: "Beer",
        },
        {
          alias: "Wine & Premixed Drinks",
        },
        {
          alias: "Juices",
        },
        {
          alias: "Soda",
        },
        {
          alias: "Bottled Water",
        },
      ],

      guestMaps: [
        {
          id: "tk_KZoeTSN7gc6CB2S",
          name: "Sides (French fries) - $5",
        },
        {
          id: "tk_mOZvdy3lqN9Evx5",
          name: "Sides (Cajun Rice) - $5",
        },
        {
          id: "tk_jjclyZplPzVKRIn",
          name: "Sides (Sweet Corn) - $5",
        },
        {
          id: "tk_OAp3lDWJJzWj2MF",
          name: "Sides (Green Beans) - $5",
        },
        {
          id: "tk_vkazVY56rbRODiy",
          name: "Sides (BBQ Beans) - $5",
        },
        {
          id: "tk_IBbN6PLme7Xpuvp",
          name: "Sides (Mac & Cheese) - $5",
        },
        // {
        //   id: "tk_8PTghLAPLptrHqI",
        //   name: "Sons of Diggs Meal - $25",
        // },
        // {
        //   id: "tk_lH1VfzNKHFMq5sy",
        //   name: "Sons and Daughters of Booker T. Meal - $25",
        // },
        // {
        //   id: "tk_f9hvvx0fmFdQyBk",
        //   name: "Friends of GEAA Meal - $25",
        // },
      ],

      ngaMaps: [
        {
          id: "tk_TG1cM3JiKLKTJjU",
          name: "GEAA Non-Paid Members Homecoming Tailgate Ticket",
        },
        {
          id: "tk_KZoeTSN7gc6CB2S",
          name: "Sides (French fries) - $5",
        },
        {
          id: "tk_mOZvdy3lqN9Evx5",
          name: "Sides (Cajun Rice) - $5",
        },
        {
          id: "tk_jjclyZplPzVKRIn",
          name: "Sides (Sweet Corn) - $5",
        },
        {
          id: "tk_OAp3lDWJJzWj2MF",
          name: "Sides (Green Beans) - $5",
        },
        {
          id: "tk_vkazVY56rbRODiy",
          name: "Sides (BBQ Beans) - $5",
        },
        {
          id: "tk_IBbN6PLme7Xpuvp",
          name: "Sides (Mac & Cheese) - $5",
        },
        // {
        //   id: "tk_8PTghLAPLptrHqI",
        //   name: "Sons of Diggs Meal - $25",
        // },
        // {
        //   id: "tk_lH1VfzNKHFMq5sy",
        //   name: "Sons and Daughters of Booker T. Meal - $25",
        // },
        // {
        //   id: "tk_f9hvvx0fmFdQyBk",
        //   name: "Friends of GEAA Meal - $25",
        // },
      ],
      gaMaps: [
        {
          id: "tk_0WLaBdaE56L65lz",
          name: "GEAA Paid Members Homecoming Tailgate Ticket",
        },
        {
          id: "tk_KZoeTSN7gc6CB2S",
          name: "Sides (French fries) - $5",
        },
        {
          id: "tk_mOZvdy3lqN9Evx5",
          name: "Sides (Cajun Rice) - $5",
        },
        {
          id: "tk_jjclyZplPzVKRIn",
          name: "Sides (Sweet Corn) - $5",
        },
        {
          id: "tk_OAp3lDWJJzWj2MF",
          name: "Sides (Green Beans) - $5",
        },
        {
          id: "tk_vkazVY56rbRODiy",
          name: "Sides (BBQ Beans) - $5",
        },
        {
          id: "tk_IBbN6PLme7Xpuvp",
          name: "Sides (Mac & Cheese) - $5",
        },
        {
          id: "tk_yuszTkBvirGQXsR",
          name: "GEAA Member Additional Meal (Smoked chicken + (2) sides & bread) - $20",
        },
        {
          id: "tk_oHZAqCknKHzzcr3",
          name: "GEAA Member Additional Meal (Fried fish + (2) sides & bread) - $20",
        },
        {
          id: "tk_BCntBZkLbCZ4pYb",
          name: "GEAA Member Additional Meal (Ribs + (2) sides & bread) - $20",
        },
        {
          id: "tk_GZUWql5I4SQpqH9",
          name: "GEAA Member Additional Meal (Sausage w/veggies + (1) side) - $20",
        },
        {
          id: "tk_CfpG6t6j0wrpyXC",
          name: "GEAA Member Additional Meal (Vegetarian Sandwich + (2) sides) - $20",
        },
        {
          id: "tk_GxOGEEqprerRHrz",
          name: "GEAA Member Family Meal (Smoked chicken + (2) sides & bread) - $25",
        },
        {
          id: "tk_LMBcmtgwSIE9t7S",
          name: "GEAA Member Family Meal (Fried fish + (2) sides & bread) - $25",
        },
        {
          id: "tk_LyInlXW7ddfHXNZ",
          name: "GEAA Member Family Meal (Ribs + (2) sides & bread) - $25",
        },
        {
          id: "tk_U6rtblt6rJY8Asv",
          name: "GEAA Member Family Meal (Sausage w/veggies + (1) side) - $25",
        },
        {
          id: "tk_dpxvxsabIwsbqLB",
          name: "GEAA Member Family Meal (Vegetarian Sandwich + (2) sides) - $25",
        },
      ],
      isclicked: false,
      dfullname: "",
      demail: "",
      dtickcount: 1,
      verifyEmailLink: "",
      priceedTicket: [],

      donations: [
        "tk_Fj1WnFpUCf0Lu5q",
        "tk_B4WR4P2s1D6j1DO",
        "tk_x2NOdq0TNjQEYGS",
        "tk_wci3aJDTelc8WJn",
      ],

      pricemap: {
        tk_0WLaBdaE56L65lz: "price_1PzlFWD0vTZ4QB9cUIfxuhNo",
        tk_TG1cM3JiKLKTJjU: "price_1PzlFWD0vTZ4QB9cPhENqngl",
        tk_KZoeTSN7gc6CB2S: "price_1Q1HtiD0vTZ4QB9cXQvqespv",
        tk_mOZvdy3lqN9Evx5: "price_1Q1HtjD0vTZ4QB9czpnWvmOH",
        tk_jjclyZplPzVKRIn: "price_1Q1HtjD0vTZ4QB9c3Du9a73p",
        tk_OAp3lDWJJzWj2MF: "price_1Q1HtkD0vTZ4QB9c7vIEcKdA",
        tk_vkazVY56rbRODiy: "price_1Q1HtkD0vTZ4QB9cF7GnKaze",
        tk_IBbN6PLme7Xpuvp: "price_1Q1HtlD0vTZ4QB9c1zt333Rg",
        tk_yuszTkBvirGQXsR: "price_1Q1I3sD0vTZ4QB9cC8xQVLte",
        tk_oHZAqCknKHzzcr3: "price_1Q1I3tD0vTZ4QB9cA0uFdRoM",
        tk_BCntBZkLbCZ4pYb: "price_1Q1I3tD0vTZ4QB9c5AMWZGtN",
        tk_GZUWql5I4SQpqH9: "price_1Q1I3tD0vTZ4QB9crZd5hD7z",
        tk_CfpG6t6j0wrpyXC: "price_1Q1I3uD0vTZ4QB9cmToDgtQR",
        tk_GxOGEEqprerRHrz: "price_1Q1KiyD0vTZ4QB9cZ5WRF1Jy",
        tk_LMBcmtgwSIE9t7S: "price_1Q1KiyD0vTZ4QB9cLo3An5Wy",
        tk_LyInlXW7ddfHXNZ: "price_1Q1KiyD0vTZ4QB9cDkzxHC5N",
        tk_U6rtblt6rJY8Asv: "price_1Q1KizD0vTZ4QB9c1hF1YEqJ",
        tk_dpxvxsabIwsbqLB: "price_1Q1KizD0vTZ4QB9cFJxE0zZo",
        // tk_8PTghLAPLptrHqI: "price_1PyfsnD0vTZ4QB9cnf4P6yQi",
        // tk_lH1VfzNKHFMq5sy: "price_1PyfsnD0vTZ4QB9ccSB2VAp3",
        // tk_f9hvvx0fmFdQyBk: "price_1PyfsoD0vTZ4QB9czOieWMVg",
        tk_Fj1WnFpUCf0Lu5q: "price_1PzlC8D0vTZ4QB9c3H1qSTq8",
        tk_B4WR4P2s1D6j1DO: "price_1PzlFXD0vTZ4QB9cYHAYTIoo",
        tk_x2NOdq0TNjQEYGS: "price_1PzlFXD0vTZ4QB9cg71dcZCT",
        tk_wci3aJDTelc8WJn: "price_1PzlFYD0vTZ4QB9caR1Z1DYs",
        tk_wQS8oUNZW4AtYWR: "price_1Pzlt4D0vTZ4QB9cByOKNISw",
        tk_K7Wo2wRrFZxXIgz: "price_1Pzlt4D0vTZ4QB9cJZY4A3FZ",
        tk_cTsIsHPazjzloq2: "price_1Pzlt5D0vTZ4QB9cQ66855fo",
        tk_pMXJzwPMUfYmKvg: "price_1Pzlt5D0vTZ4QB9cSjMu0fks",
        tk_4VWrbZ7wKQVkk8w: "price_1Pzlt5D0vTZ4QB9cynEYmftm",
        tk_xvUaovYvsgCVdpy: "price_1Pzlt6D0vTZ4QB9cGg8RXdwz",
        tk_UKE2YQZV0KFcVvD: "price_1Q3j0gD0vTZ4QB9cdUjbDfIK",
        tk_X5SmrXU1VKEgEor: "price_1Q3j0hD0vTZ4QB9cMT290b2S",
        tk_8caMd063A8UHAzp: "price_1Q3j0iD0vTZ4QB9cLHelcBSE",
        tk_GR0enAst0r6nliL: "price_1Q3j0iD0vTZ4QB9cdnJs8yjr",
        tk_jLE1kIIZmwhEbNv: "price_1Q3j0hD0vTZ4QB9cvnu45t5Q",
      },
      showcreateForm: false,
      eAllowBike: "Yes",
      ebodySize: "",
      eframeSize: "",
      showPrice: "No",
      dinnerGuest: "No",
      personCount: 1,
      eContactName: "",
      emedical: "",
      eContactPhone: "",
      eType: "Delta employee",
      estation: "AMS",
      ecountry: "the USA",
      eMerchandise: "Small",
      ownPedals: "Yes",
      tshirt: "No",
      tshirtSize: "Small",
      ownHelmet: "Yes",
      eAllowShow: "Yes",
      eShoeSize: "",
      sections: [],
      editRecord: null,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userGender: "Male",
      userDOB: "",
      userPhone: "",
      daylist: {},
      daylist1: {},
      loader: false,
      completeList: [],
      initvalue: {},
      action: "",
      actionValue: "",
      cntholder: {},
      selectedCarts: [],
      ttypelist: null,
      ttypelist1: null,
      reserveFormAccept: false,
      reserveFormAcceptAboutAgeRestriction: false,
      countsListShow: [],
      eventTicketResult: [],
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      tickcount: 1,
      tickcount1: 1,
      isSubmitted: false,
      tickcountlist: [],
      tickcountlist1: [],
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],

      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_1.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_2.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_3.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_4.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_5.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_6.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_7.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_8.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_9.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_10.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_11.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_12.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_13.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_15.jpg",

        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_16.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_17.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_18.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_19.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_20.png",

        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_21.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_22.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_23.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_24.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_25.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_26.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_27.JPG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_28.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_29.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_30.jpg",

        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_31.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_32.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_33.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_34.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/GEAA100thCentennialHomecoming_IMAGES/GEAA100thCentennialHomecoming_35.PNG",
      ],
    };
  },
  components: {
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
    NavBar,
    SiteFooter,
    VueFlux,
    FluxPreloader,
    FluxControls,
  },
  methods: {
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    isValid(item) {
      if (!item.data || !item.data.bidValue) {
        return false;
      }
      return Object.values(item.data.bidValue).reduce((a, b) => a + b, 0) == 2;
    },
    subtractCount(key) {
      let sum = 0;
      Object.values(this.bidValue).forEach((e) => {
        sum += e;
      });
      if (sum - 1 >= 0) {
        this.bidValue[key] -= 1;
      }
    },
    addCount(key) {
      let sum = 0;
      Object.values(this.bidValue).forEach((e) => {
        sum += e;
      });
      if (sum + 1 <= 2) {
        this.bidValue[key] += 1;
      }
    },
    completed() {
      let isFilled = true;

      if (this.selectedGa != "") {
        Object.values(this.sections).forEach((rec) => {
          rec.forEach((item) => {
            if (
              ["tk_0WLaBdaE56L65lz", "tk_TG1cM3JiKLKTJjU"].includes(
                item.ticketCode
              ) &&
              (!item.data ||
                (item.data.bidValue &&
                  Object.values(item.data.bidValue).reduce((a, b) => a + b, 0) <
                    2))
            ) {
              isFilled = false;
            }
          });
        });

        if (!this.isAllDonations()) {
          isFilled = false;
          Object.values(this.sections).forEach((rec) => {
            rec.forEach((item) => {
              if (
                ["tk_0WLaBdaE56L65lz", "tk_TG1cM3JiKLKTJjU"].includes(
                  item.ticketCode
                ) &&
                item.data &&
                item.data.bidValue &&
                Object.values(item.data.bidValue).reduce((a, b) => a + b, 0) ==
                  2
              ) {
                isFilled = true;
              }
            });
          });

          return isFilled;
        }

        if (!Object.keys(this.sections).length) {
          return false;
        }
      }
      return isFilled;
    },
    isAllDonations() {
      let isFilled = true;
      Object.values(this.sections).forEach((rec) => {
        rec.forEach((item) => {
          if (!this.donations.includes(item.ticketCode)) {
            isFilled = false;
          }
        });
      });

      if (!Object.keys(this.sections).length) {
        return false;
      }

      return isFilled;
    },
    nonDonations(list) {
      return list.filter((r) =>
        ["tk_0WLaBdaE56L65lz", "tk_TG1cM3JiKLKTJjU"].includes(r[0].ticketCode)
      );
    },
    fetchRouteTickets(list, isRoute, isDonation, isGuest) {
      const routes = [
        "tk_0WLaBdaE56L65lz",
        "tk_KZoeTSN7gc6CB2S",
        "tk_mOZvdy3lqN9Evx5",
        "tk_jjclyZplPzVKRIn",
        "tk_OAp3lDWJJzWj2MF",
        "tk_vkazVY56rbRODiy",
        "tk_IBbN6PLme7Xpuvp",
        "tk_yuszTkBvirGQXsR",
        "tk_oHZAqCknKHzzcr3",
        "tk_BCntBZkLbCZ4pYb",
        "tk_GZUWql5I4SQpqH9",
        "tk_CfpG6t6j0wrpyXC",
        "tk_GxOGEEqprerRHrz",
        "tk_LMBcmtgwSIE9t7S",
        "tk_LyInlXW7ddfHXNZ",
        "tk_U6rtblt6rJY8Asv",
        "tk_dpxvxsabIwsbqLB",

        // "tk_8PTghLAPLptrHqI",
        // "tk_lH1VfzNKHFMq5sy",
        // "tk_f9hvvx0fmFdQyBk",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ];
      const nonroute = [
        "tk_TG1cM3JiKLKTJjU",
        "tk_KZoeTSN7gc6CB2S",
        "tk_mOZvdy3lqN9Evx5",
        "tk_jjclyZplPzVKRIn",
        "tk_OAp3lDWJJzWj2MF",
        "tk_vkazVY56rbRODiy",
        "tk_IBbN6PLme7Xpuvp",
        // "tk_8PTghLAPLptrHqI",
        // "tk_lH1VfzNKHFMq5sy",
        // "tk_f9hvvx0fmFdQyBk",
        "tk_yuszTkBvirGQXsR",
        "tk_oHZAqCknKHzzcr3",
        "tk_BCntBZkLbCZ4pYb",
        "tk_GZUWql5I4SQpqH9",
        "tk_CfpG6t6j0wrpyXC",

        "tk_GxOGEEqprerRHrz",
        "tk_LMBcmtgwSIE9t7S",
        "tk_LyInlXW7ddfHXNZ",
        "tk_U6rtblt6rJY8Asv",
        "tk_dpxvxsabIwsbqLB",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ];

      const guestRoute = [
        // "tk_KZoeTSN7gc6CB2S",
        // "tk_mOZvdy3lqN9Evx5",
        // "tk_jjclyZplPzVKRIn",
        // "tk_OAp3lDWJJzWj2MF",
        // "tk_vkazVY56rbRODiy",
        // "tk_IBbN6PLme7Xpuvp",

        // "tk_UKE2YQZV0KFcVvD",
        // "tk_X5SmrXU1VKEgEor",
        // "tk_8caMd063A8UHAzp",
        // "tk_GR0enAst0r6nliL",
        // "tk_jLE1kIIZmwhEbNv",

        "tk_wQS8oUNZW4AtYWR",
        "tk_K7Wo2wRrFZxXIgz",
        "tk_cTsIsHPazjzloq2",
        "tk_pMXJzwPMUfYmKvg",
        "tk_4VWrbZ7wKQVkk8w",
        "tk_xvUaovYvsgCVdpy",
      ];

      const donationn = [
        "tk_Fj1WnFpUCf0Lu5q",
        "tk_B4WR4P2s1D6j1DO",
        "tk_x2NOdq0TNjQEYGS",
        "tk_wci3aJDTelc8WJn",
      ];

      if (isRoute) {
        return list.filter((r) => routes.includes(r.ticketCode));
      }

      if (isDonation) {
        return list.filter((r) => donationn.includes(r.ticketCode));
      }

      if (isGuest) {
        return list.filter((r) => guestRoute.includes(r.ticketCode));
      }

      return list.filter((r) => nonroute.includes(r.ticketCode));
    },
    scrolldiv() {
      const collection = document.getElementsByClassName(
        "nu-modal has-content is-visible"
      );
      console.log(collection);
      collection[0].scrollIntoView();
    },
    findPosition(obj) {
      var currenttop = 0;
      if (obj.offsetParent) {
        do {
          currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [currenttop];
      }
    },
    openGEAAVerify() {
      this.verifyEmailLink = "";
      this.$refs.GEAAVerificationModal.open();
    },
    opencart(id, isg) {
      this.isGuest = isg;
      // this.ttype = "Sons of Diggs";
      this.ttype = "";
      this.userFirstName = "";
      this.userLastName = "";
      this.userEmail = "";
      this.daylist = {};
      this.daylist1 = {};
      this.initvalue = {};
      this.ttypelist = null;
      this.ttypelist1 = null;
      this.cntholder = {};
      this.tickcount = 1;
      this.tickcount1 = 1;
      this.selectedCarts = [];
      this.tickcountlist = [];
      this.tickcountlist1 = [];
      this.sections = [];
      this.section = [];
      this.bidValue = {
        "Liquors and Mixed Drinks": 0,
        Beer: 0,
        "Wine & Premixed Drinks": 0,
        Juices: 0,
        Soda: 0,
        "Bottled Water": 0,
      };
      this.selectedGa = id;
      this.gaType = this.meals[0]; // id == 'tk_0WLaBdaE56L65lz' ? this.gaMaps[0] : this.ngaMaps[0];
      this.showcreateForm = false;
      this.eAllowBike = "Yes";
      this.ebodySize = "";
      this.eframeSize = "";
      this.showPrice = "No";
      this.dinnerGuest = "No";
      this.personCount = 1;
      this.eContactName = "";
      this.emedical = "";
      this.eContactPhone = "";
      this.eType = "Delta employee";
      this.estation = "AMS";
      this.ecountry = "the USA";
      this.eMerchandise = "Small";
      this.ownPedals = "Yes";
      this.tshirt = "No";
      this.tshirtSize = "Small";
      this.ownHelmet = "Yes";
      this.eAllowShow = "Yes";
      this.eShoeSize = "";
      this.editRecord = null;
      setTimeout(() => {
        this.dayChange("daylist");
        this.dayChange("daylist1");
      }, 0);

      this.$refs.GEAAVerificationModal.close();
      this.$refs.modal.open();
    },
    newForm(param) {
      if (param.data) {
        this.gaType = param.data.gaType;
        this.bidValue = param.data.bidValue;
      } else {
        this.gaType = this.meals[0]; // this.selectedGa == 'tk_0WLaBdaE56L65lz' ? this.gaMaps[0] : this.ngaMaps[0];
        this.bidValue = {
          "Liquors and Mixed Drinks": 0,
          Beer: 0,
          "Wine & Premixed Drinks": 0,
          Juices: 0,
          Soda: 0,
          "Bottled Water": 0,
        };
      }
      this.editRecord = param;
      this.showcreateForm = true;
      setTimeout(() => {
        this.scrolldiv();
      }, 100);
    },
    closenewForm() {
      this.showcreateForm = false;
    },
    saveNewForm() {
      this.editRecord.data = {};
      this.editRecord.data.gaType = this.gaType;
      this.editRecord.data.bidValue = this.bidValue;
      this.showcreateForm = false;
    },
    setValues(c, t) {
      this.cntholder[this[t].ticketCode] = c;
    },
    addtocart(t) {
      const rec = { ...this[t] };
      if (t == "daylist" && this.tickcount < 1) {
        return;
      }
      if (t == "daylist1" && this.tickcount1 < 1) {
        return;
      }
      if (rec && t == "daylist") {
        for (let c = 0; c < this.tickcount; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }
        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount,
          t
        );
      }

      if (rec && t == "daylist1") {
        for (let c = 0; c < this.tickcount1; c++) {
          rec.tttcount = 1;
          rec.daylist1 = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist1.ticketCode] || 0) + this.tickcount1,
          t
        );
        console.log("dsds", this.cntholder);
      }

      this.dayChange(t);
    },
    dayChange(t) {
      if (t == "daylist") {
        this.tickcount = 1;
        this.tickcountlist = [];
      } else {
        this.tickcount1 = 1;
        this.tickcountlist1 = [];
      }
      const w = this[t];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        if (!this.donations.includes(w.ticketCode)) {
          this.tickcountlist.push(k + 1);
        } else if (this.donations.includes(w.ticketCode)) {
          this.tickcountlist1.push(k + 1);
        }
      }
      if (t === "daylist") {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist.push(k1 + 1);
          }
        }

        if (this.tickcountlist.length == 0) {
          this.tickcount = 0;
        }
      } else {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist1.length;
          this.tickcountlist1 = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist1.push(k1 + 1);
          }
        }

        if (this.tickcountlist1.length == 0) {
          this.tickcount1 = 0;
        }
      }

      if (t == "daylist") {
        this.ttypelist = { ...this.daylist };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist.length;
        }
      } else {
        this.ttypelist1 = { ...this.daylist1 };
        if (!(this.ttypelist1.ticketCode in this.initvalue)) {
          this.initvalue[this.ttypelist1.ticketCode] =
            this.tickcountlist1.length;
        }
      }
      this.tickcountlist1 = [...(this.tickcountlist1 || [])];
      this.tickcountlist = [...(this.tickcountlist || [])];
      this.initvalue = { ...this.initvalue };
    },
    deleteRec(rec, i) {
      let tmpi = false;
      this.sections.forEach((r, qq) => {
        r.forEach((www, re) => {
          if (!tmpi && rec.ticketCode === this.sections[qq][re].ticketCode) {
            this.sections[qq][re].del = true;
            tmpi = true;
          }
        });
      });

      this.sections.forEach((r, qq) => {
        this.sections[qq] = this.sections[qq].filter((g) => !g.del);
      });

      this.sections = this.sections.filter((r) => r.length > 0);

      this.selectedCarts = this.selectedCarts.filter((x, it) => {
        if (it == i) {
          this[rec.t] = rec.daylist || rec.daylist1;
          this.setValues(
            (this.cntholder[(rec.daylist || rec.daylist1).ticketCode] || 0) -
              rec.tttcount,
            rec.t
          );
        }
        return it != i;
      });
      this.dayChange(rec.t);
    },
    paynowcustom() {
      this.isSubmitted = true;
      const vm = this;
      this.isclicked = true;

      if (
        !this.userFirstName.trim().length ||
        !this.userLastName.trim().length ||
        !this.userEmail.trim().length ||
        !this.invalidEmail
      ) {
        this.$toast.error("Please provide your complete details", {
          timeout: 4000,
        });
        return;
      }

      if (!this.selectedCarts.length) {
        this.$toast.error("Please add some ticket(s) in the cart.", {
          timeout: 4000,
        });
        return;
      }

      if (
        (!this.selectedCarts.length || !this.completed()) &&
        !this.isAllDonations()
      ) {
        this.$toast.error(
          "Please provide required details for GEAA member ticket",
          {
            timeout: 4000,
          }
        );
        return;
      }

      if (!this.reserveFormAccept) {
        this.$toast.error("Please accept terms and conditions.", {
          timeout: 4000,
        });
        return;
      }

      if (!this.reserveFormAcceptAboutAgeRestriction) {
        this.$toast.error("Please accept the GEAA disclaimer.", {
          timeout: 4000,
        });
        return;
      }
      this.loader = true;
      let rid = "";
      this.TicketsResult.forEach((e) => {
        // if (e.ticketCode == this.selectedGa) {
        rid = e.eventId;
        // }
      });
      const payloadData = {
        userName: this.userFirstName + " " + this.userLastName,
        userEmail: this.userEmail.toLowerCase(),
        userId: "",
        eventId: rid,
        origin: "WEB",
        tickets: [],
      };

      const recss = {};

      this.selectedCarts.forEach((re) => {
        if (re.daylist) {
          recss[re.daylist.ticketCode] = {
            priceId: this.pricemap[re.daylist.ticketCode],
            ticketId: re.daylist.ticketCode,
            totalTickets:
              (recss[re.daylist.ticketCode]
                ? recss[re.daylist.ticketCode].totalTickets
                : 0) + re.tttcount,
            additionalInfo: {},
            extraInfo: {
              guestType: this.ttype,
            },
          };
        } else {
          recss[re.daylist1.ticketCode] = {
            priceId: this.pricemap[re.daylist1.ticketCode],
            ticketId: re.daylist1.ticketCode,
            totalTickets:
              (recss[re.daylist1.ticketCode]
                ? recss[re.daylist1.ticketCode].totalTickets
                : 0) + re.tttcount,
            additionalInfo: {},
            extraInfo: {
              guestType: this.ttype,
            },
          };
        }

        this.sections.forEach((r, y) => {
          (this.sections[y] || []).forEach((qq) => {
            if (
              qq.data &&
              re.daylist &&
              qq.ticketCode === re.daylist.ticketCode
            ) {
              if (qq.data && qq.data.bidValue) {
                const drinks = [];
                Object.keys(qq.data.bidValue).forEach((ree) => {
                  for (let w = 0; w < qq.data.bidValue[ree]; w++) {
                    drinks.push(ree);
                  }
                });
                recss[re.daylist.ticketCode].extraInfo = {
                  meal: qq.data.gaType,
                  drink: drinks,
                };

                if (this.isGuest) {
                  recss[re.daylist.ticketCode].extraInfo["guestType"] =
                    this.ttype;
                }
              }
            }
          });
        });
      });
      this.selectedCarts.forEach((re) => {
        if (re.daylist) {
          if (!recss[re.daylist.ticketCode]) {
            recss[re.daylist.ticketCode] = {
              priceId: this.pricemap[re.daylist.ticketCode],
              ticketId: re.daylist.ticketCode,
              totalTickets:
                (recss[re.daylist.ticketCode]
                  ? recss[re.daylist.ticketCode].totalTickets
                  : 0) + re.tttcount,
              additionalInfo: {},
              extraInfo: {
                guestType: this.ttype,
              },
            };
          }
        }
        (this.sections || []).forEach((r, q) => {
          (this.sections[q] || []).forEach((qq) => {
            if (re.data && qq.data && qq.ticketCode === re.daylist.ticketCode) {
              const drinks = [];
              Object.keys(qq.data.bidValue).forEach((ree) => {
                for (let w = 0; w < qq.data.bidValue[ree]; w++) {
                  drinks.push(ree);
                }
              });
              recss[re.daylist.ticketCode].extraInfo = {
                meal: qq.data.gaType,
                drink: drinks,
              };

              if (this.isGuest) {
                recss[re.daylist.ticketCode].extraInfo["guestType"] =
                  this.ttype;
              }
            }
          });
        });
      });

      payloadData.tickets = Object.values(recss);
      console.log(payloadData);

      vm.isSubmitted = true;
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/request_cart_tickets",
          payloadData
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);

            vm.reserveFormAccept = false;
            vm.reserveFormAcceptAboutAgeRestriction = false;
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutAgeRestriction = false;
        })
        .finally(() => {
          vm.isSubmitted = false;
          vm.loader = false;
        });
    },
    fetchAllTickets() {
      const vm = this;
      this.loader = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "oBxerW1icG2AAD",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (ticketsResult && ticketsResult.data.Result) {
            ticketsResult.data.Result.forEach((e) => {
              if (e.ticketCode) {
                this.positions[e.ticketCode] = e;
              }
            });

            ticketsResult.data.Result = Object.values(this.positions).filter(
              (e) => !!e
            );

            vm.priceedTicket = (ticketsResult.data.Result.filter(
              (r) => r.ticketCode === "tk_abUzszRQvcC6hQF"
            ) || [])[0];
            console.log(vm.priceedTicket);
            vm.TicketsResult = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
            vm.completeList = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
          }
          vm.TicketsResult = vm.completeList;
          vm.eventTicketResult = vm.TicketsResult;
          vm.dayChange("daylist");
          vm.dayChange("daylist1");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    scrolltoOrder() {
      document
        .getElementById("ordersum")
        .scrollIntoView({ block: "end", behavior: "smooth" });
    },
    verifye: function () {
      const vm = this;

      const payloadData = {
        userEmail: this.verifyEmailLink.toLowerCase(),
      };

      vm.isSubmitted = true;
      vm.loader = true;
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/verify_registered_member_email",
          payloadData
        )
        .then(() => {
          vm.opencart("tk_0WLaBdaE56L65lz", false);
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.Message) {
            vm.$toast.error(e.response.data.Message, { timeout: 4000 });
          }
        })
        .finally(() => {
          setTimeout(() => {
            vm.isSubmitted = false;
            vm.loader = false;
          }, 1000);
        });
    },
  },
  computed: {
    invalidEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.userEmail
      );
    },
    dinvalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.demail
      );
    },
    cverifyEmailLink() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.verifyEmailLink
      );
    },
    invalidPhone() {
      return (
        (this.userPhone || "").match(/\d/g) &&
        (this.userPhone || "").trim().length >= 10
      );
    },
    invalidePhone() {
      return (
        (this.eContactPhone || "").match(/\d/g) &&
        (this.eContactPhone || "").trim().length >= 10
      );
    },
  },
  mounted() {
    document.getElementById("ele1").addEventListener("scroll", (e) => {
      if (e.target.scrollTop > 100) {
        document.getElementById("tickBtn").style.display = "none";
      } else {
        document.getElementById("tickBtn").style.display = "block";
      }
    });
    this.fetchAllTickets();
    setTimeout(() => {
      this.$watch(
        () => {
          return this.$refs.modal.is_open;
        },
        (val) => {
          if (val) {
            document.getElementById("tickBtn").style.display = "block";
          } else {
            document.getElementById("tickBtn").style.display = "none";
          }
        }
      );
    }, 2000);
    if (
      this.$route &&
      this.$route.params &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  watch: {
    selectedCarts(list) {
      const w = {};
      this.sections = JSON.parse(JSON.stringify(this.sections));

      list.forEach((r) => {
        if (!w[r.ticketCode]) {
          w[r.ticketCode] = [];
        }
        for (let e = 0; e < r.tttcount; e++) {
          w[r.ticketCode].push({ ...r });
        }
      });

      if (this.sections.length) {
        Object.keys(w).forEach((h) => {
          let present = false;

          this.sections.forEach((rr) => {
            if (rr[0].ticketCode === h) {
              present = true;
            }
          });

          if (!present) {
            this.sections.push(JSON.parse(JSON.stringify(w[h])));
          }
        });

        this.sections.forEach((rr, i) => {
          Object.keys(w[rr[0].ticketCode]).forEach((wx) => {
            if (!this.sections[i][wx]) {
              this.sections[i].push(w[rr[0].ticketCode][wx]);
            }
          });
        });

        this.sections = [...this.sections];
      } else {
        this.sections = Object.values(w);
      }

      console.log(this.sections);
    },
  },
  metaInfo: {
    title: "MYLE - GEAA - Celebrating the 100th Year Centennial Homecoming",

    meta: [
      {
        name: "description",
        content:
          "MYLE - GEAA - Celebrating the 100th Year Centennial Homecoming",
      },

      {
        name: "keywords",
        content:
          "MYLE - GEAA - Celebrating the 100th Year Centennial Homecoming",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/geaa-celebrating-the-100th-year-centennial-homecoming",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #df090091 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.bolded {
  font-weight: bold;
}

iframe {
  width: 100% !important;
}

.labels {
  font-family: "Arial", sans-serif;
  font-size: 1.1em;
  color: rgb(34, 33, 33);
}

.scroller {
  display: block;
  z-index: 9999;
  position: fixed;
  bottom: 70px;
  left: calc(50% - 45px);
  cursor: pointer;
}

#tickBtn {
  display: none;
}
</style>
