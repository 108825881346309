<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE' &&
                  TicketsResult[selected].ticketType != 'DONATION'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-3"
              v-if="TicketsResult[selected].ticketType !== 'DONATION'"
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select
                class="form-control"
                v-model="tickcount"
                @change="countChanged"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-3 mt-2"
              v-if="
                TicketsResult[selected] &&
                donationOptions[TicketsResult[selected].ticketCode]
              "
            >
              <!-- test -->
              <label class="mt-2">Select Donation Type</label>
              <select class="form-control" v-model="donationPid">
                <option
                  class="form-control"
                  v-bind:key="idx + 'donation'"
                  v-for="(count, idx) in donationOptions[
                    TicketsResult[selected].ticketCode
                  ]"
                  :value="count.priceId"
                >
                  {{ count.name }}
                </option>
              </select>
            </div>
            <!-- test -->

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 mb-3"
              v-if="
                false &&
                TicketsResult[selected] &&
                !exclude.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="mt-3">Dinner Selection</label>
              <textarea
                class="form-control"
                placeholder="Seared Atlantic Salmon, Beef Short Ribs or Chef’s choice Vegetarian Plate"
                @input="reservedMeal = $event.target.value"
                rows="3"
              ></textarea>
            </div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                  <div
                    class="row col-md-12 mt-4"
                    v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                  >
                    <div class="col-md-6">
                      <label class="">Do you have a promo code?</label>

                      <input
                        type="text"
                        v-model="redeempromo"
                        class="form-control"
                        placeholder="Promo code"
                      />
                    </div>

                    <div class="col-md-4">
                      <button
                        type="button"
                        @click="redeem()"
                        :disabled="!redeempromo.trim().length"
                        class="btn solid-btn btn-sm my-auto"
                        id="reserve"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          padding: 7px;
                          margin-top: 11px !important;
                        "
                      >
                        Redeem
                      </button>
                    </div>
                  </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                v-if="vipOnes.includes(TicketsResult[selected].ticketCode)"
                :disabled="
                  !invalidemail || isSubmitted || !tickcountlist.length
                "
                @click="upgradeToVip()"
                class="btn solid-btn m-auto mb-2"
                style="pointer-events: all; cursor: pointer"
              >
                Upgrade to VIP
              </button>
              <br />

              <button
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (!exclude.includes(TicketsResult[selected].ticketCode)
                    ? false
                    : false) ||
                  (donationOptions[TicketsResult[selected].ticketCode]
                    ? !donationPid
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  margin: 11px !important;
                "
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_2nd_bftf.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                Black Food Truck Festival
              </h1>
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/AdKYFrjosjqFv2FD7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Exchange Park Fairgrounds. 9850 Hwy 78, Ladson, SC.</a
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center mb-2">
                    <h4 class="w-auto d-inline-block mb-2">
                      All sales are final.<br />
                      No refunds!!!
                    </h4>
                  </div>
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 400px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in TicketsResult"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in (tkList[obj.ticketCode] || [])
                            .messages"
                        >
                          - {{ msgs }}
                        </p>
                        <!-- <p>
                          <b class="font-weight-bold">Price:</b>
                          ${{ obj.price }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p
                            v-if="
                              obj.ticketType != 'FREE' &&
                              obj.ticketType != 'DONATION'
                            "
                          >
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(objk)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : obj.ticketType == "DONATION"
                                ? "Donate Now"
                                : "Reserve Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-center mb-2">
                    <h4
                      class="w-auto d-inline-block mb-2 mt-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      <span class="bolded"> Group Sales: </span> If purchasing
                      10 or more tickets, use promo code “thebestfestival”and
                      receive a 10% discount off of each ticket.
                    </h4>

                    <h4
                      class="w-auto d-inline-block mb-2 mt-3"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      <span class="bolded"> For VIP Upgrades:</span>
                      If you purchased an Early Bird Ticket or General Admission
                      ticket and would like to upgrade to VIP, once you enter
                      your email address (used on your prior purchase), you will
                      be able to tap the UPGRADE TO VIP button and the price
                      will automatically adjust to the price difference for a
                      VIP Ticket.
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="section-heading mb-2">
              <div class="text-center">
                <a
                  href="https://myle.com/2nd-black-food-truck-festival"
                  class="btn solid-btn mb-1 ml-3 mr-3 text-center"
                  target="_self"
                  >Back To Main Page</a
                >

                <a
                  href="https://myle.com/2nd-black-food-truck-festival-day-1"
                  class="btn solid-btn ml-3 mr-3 mb-1 text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  >See Day 1 Details</a
                >

                <a
                  href="https://myle.com/2nd-black-food-truck-festival-day-2"
                  class="btn solid-btn ml-3 mr-3 mb-1 text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  >See Day 2 Details</a
                >
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">
                      THE VIP EXPERIENCE
                    </h3>
                  </div>

                  <div class="mb-2">
                    <p
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      The Spring ’23 Black Food Truck Festival will feature 2
                      VIP lounge areas in the Pavilion and the Container Bar
                      located in a separate section of the festival grounds.
                      Both will offer a serene and secluded atmosphere away from
                      the crowds.

                      <br /><br />
                      Standout features for both lounges include:
                      <br />
                      ● Early entry into the festival getting a head start on
                      trying out the delicious food offerings
                      <br />
                      ● Complimentary beverages and alcoholic drinks
                      <br />
                      ● Private bar where guests can enjoy premium beverages and
                      cocktails
                      <br />
                      ● Comfortable and stylish seating areas, with plush chairs
                      and sofas arranged in a way that promotes socializing and
                      mingling
                      <br />
                      ● For maximum comfort during the warm festival season, the
                      VIP lounge is shaded and cooled, creating a refreshing
                      oasis for guests.
                      <br />
                      ● Swag bags filled with goodies, adding an extra touch of
                      luxury to the VIP experience
                      <br />
                      ● Photography backdrops are also available for those who
                      want to capture the moment with a photo
                      <br />
                      ● NOT allowed to skip food truck lines at any time. VIP
                      fast pass access is ONLY for entry gate.
                      <br />
                      <br />
                      <span class="bolded"> Pavilion VIP Lounge: </span>
                      <br />
                      ● Private restrooms exclusively reserved for VIP guests
                      (Pavilion only)
                      <br />
                      ● A dance floor area where guests can let loose and have
                      some fun (Pavilion Only)
                      <br />
                      ● TV screens with live streams of the festival, allowing
                      guests to enjoy the festivities from the comfort of the
                      lounge
                      <br />
                      <br />
                      <span class="bolded"> Container Bar VIP Lounge: </span>
                      <br />
                      ● Private fenced-in area with rooftop seating area
                      <br />
                      ● Direct access to DJ area, hookah and cigar lounges
                      <br />
                      <br />

                      <span class="bolded"> HOW TO UPGRADE TO VIP </span>
                      <br />
                      To upgrade your Early Bird or General Admission ticket,
                      select the VIP option matching your prior ticket type,
                      enter your email address (used on your prior purchase),
                      tap the UPGRADE TO VIP button and the price will
                      automatically adjust to the price difference for a VIP
                      Ticket.

                      <br /><br />PLEASE NOTE: You MUST use the same email
                      address from your initial purchase. If you have any
                      questions, email
                      <a href="mailto:hello@myle.com"> hello@myle.com.</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row w-100 m-0 text-center promo-section MYLE-green">
                <div class="col-lg-12 col-md-12">
                  <div class="mt-3">
                    <div class="section-heading mb-3">
                      <h5 class="text-center text-white">
                        Black Food Truck Festival 2022 Recap
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <video-embed
                css="embed-responsive-16by9"
                src="https://youtu.be/o7v5GoZ9pIs"
              ></video-embed>
            </div>
            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                The Black Food Truck Festival is a family-oriented event
                featuring the finest food trucks in the region in Charleston,
                South Carolina. Our mission is to make an economic and social
                impact on the community by showcasing and investing in area
                Black-owned businesses.
                <br />
                <br />
                The Black Food Truck Festival is the ultimate family reunion
                featuring kid-friendly activities in a safe, secure environment.
                Expect to experience a range of different cuisines while
                enjoying performances by local bands and musicians.

                <br />
                <br />
                Our goal is to create an atmosphere of unity, love, and positive
                vibes. Come celebrate with us and have a great time while
                creating a memorable experience!
                <br />
                <br />

                <span class="bolded"> EVENT DETAILS: </span><br />
                <span class="bolded">Day 1:</span> SATURDAY, APRIL 22ND
                <br />
                <span class="bolded">Day 2:</span> SUNDAY, APRIL 23RD
                <br />
                <span class="bolded">TIME:</span> 11:00AM - 7:00PM
                <br />
                <span class="bolded">LOCATION:</span> The Exchange Park
                Fairgrounds. 9850 Hwy 78, Ladson, SC.
                <br />
                Food truck vendors will be available both days of the festival.
                <br />
                <br />
                <span class="bolded">ENTERTAINMENT:</span>
                <br />
                ● Host - Headkrack
                <br />
                ● Trap Jazz All Stars
                <br />
                ● The Red Sample
                <br />
                ● Stout
                <br />
                ● Mike Stone
                <br />
                <br />
                <span class="bolded">Note:</span> Download the
                <a href="https://www.myle.com"> MYLE App</a>
                for easy access to the event. Your ticket will auto-populate in
                the ticket section of the app for scanning upon access to the
                event and will save time.

                <br />
                <br />
                <span class="bolded"
                  >Use Referral Code: BlackFoodTruckFestival</span
                >
                <br />
                <br />
                The <a href="https://www.myle.com"> MYLE App</a> is available in
                your App Store under MYLE Events. Be sure to click the LOVE
                button on our event, leave comments and share with friends and
                family.
                <br />
                <br />
                We look forward to seeing you at the Black Food Truck Festival
                <br />
                <br />
                <span class="bolded"
                  >REFUND POLICY: ALL SALES ARE FINAL - NO REFUNDS - NO
                  EXCEPTIONS UNLESS EVENT IS CANCELLED!!!</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <section class="container">
        <h2
          class="mt-2 text-center"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 35px;
          "
        >
          Black Food Truck Festival Map
        </h2>

        <div class="row">
          <div class="col-md-3 col-lg-3 align-items-center"></div>

          <div class="col-md-6 col-lg-6 align-items-center">
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bftf_faqs.jpg"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
          <div class="col-md-3 col-lg-3 align-items-center"></div>
        </div>
      </section>

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import confirmationPopup from "../../components/confirmationPopup";
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      tmpTicket: null,
      vipOnes: [
        // "tk_WvtrmLsVcnkyv9f",
        // "tk_BiddUyw900uRtqw",
        "tk_M6GEZ4MQJEbgLKh",
      ],
      showsuccess: false,
      excludeNames: [
        "Community Sponsorship",
        "Diamond Sponsorship",
        "24 Karat Sponsorship",
        "Platinum Sponsorship",
        "KLHESF Donation",
      ],
      exclude: [],
      reservedMeal: "",
      donationPid: "",
      donationOptions: {
        // tk_vD7aQFuhNKVw8Bm: [
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cBJJQRn5u",
        //     name: "Gold - $500",
        //   },
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cKuD37llT",
        //     name: "Silver - $250",
        //   },
        //   {
        //     priceId: "price_1LeMYND0vTZ4QB9cTxgdAmpS",
        //     name: "Bronze - $100",
        //   },
        // ],
      },
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_GOpKmfhiRdpVzSa: {
        //   messages: ["Ticket Price: $15", "Processing fee of $2.64"],
        // },

        // tk_txh8hOKEPvMisRT: {
        //   messages: ["Ticket Price: $15", "Processing fee of $2.64"],
        // },

        // tk_SQ9NtuTj9hN4haH: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        // tk_gXmgcPj3HjoT5QM: {
        //   messages: ["Ticket Price: $15", "Processing fee of $2.64"],
        // },

        // tk_8A9AfTiJ7MI9tG4: {
        //   messages: ["Ticket Price: $15", "Processing fee of $2.64"],
        // },

        // tk_x918UJaJiqhVQfB: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        // tk_oj5KlsNQ5SbopmM: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        // tk_PEyJESdCJRgqAOW: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        // tk_tkBtfE1E1sMoyFP: {
        //   messages: ["Ticket Price: $45", "Processing fee of $4.66"],
        // },

        tk_WyE2K2WZoRQTtRB: {
          messages: ["Ticket Price: $55", "Processing fee of $5.33"],
        },

        // tk_C2kgKmbtPFtSIgP: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        // tk_ksGoGElKbcIpSJP: {
        //   messages: ["Ticket Price: $25", "Processing fee of $3.32"],
        // },

        tk_mvtwRgxoFfOWzmb: {
          messages: ["Ticket Price: $45", "Processing fee of $4.66"],
        },

        // tk_WvtrmLsVcnkyv9f: {
        //   messages: ["Ticket Price: $125", "Processing fee of $10.03"],
        // },

        // tk_BiddUyw900uRtqw: {
        //   messages: ["Ticket Price: $125", "Processing fee of $10.03"],
        // },

        tk_M6GEZ4MQJEbgLKh: {
          messages: ["Ticket Price: $225", "Processing fee of $16.73"],
        },
      },

      priceIds: {
        tk_07eKcmNmtRasmiL: "price_1Mn4XYD0vTZ4QB9cTSKIVCSi",
        tk_4pwlUFgC5Z1Io0D: "price_1Mn4Z3D0vTZ4QB9cxrds1QO7",
        tk_RWlcJlGhxStbZS7: "price_1Mn4Y4D0vTZ4QB9cCc3dHZPa",
        tk_mP4XHQi9uJBAaWS: "price_1Mn4ZGD0vTZ4QB9cd4IbfLG1",
        tk_Hy5b4F32jgWkdHd: "price_1Mn4apD0vTZ4QB9cECOmu2jj",
        tk_DhmvY88N0iHFnH9: "price_1Mn4bED0vTZ4QB9ccCYagMRf",
        // tk_GOpKmfhiRdpVzSa: "price_1MWpXcD0vTZ4QB9crhBFvFff",
        // tk_txh8hOKEPvMisRT: "price_1MWpa7D0vTZ4QB9cOGzzOAVE",
        // tk_SQ9NtuTj9hN4haH: "price_1MWpcmD0vTZ4QB9cOtU9TOfm",
        // tk_gXmgcPj3HjoT5QM: "price_1MWpf0D0vTZ4QB9cFnYUsVln",
        // tk_8A9AfTiJ7MI9tG4: "price_1MWpgXD0vTZ4QB9cOKFRqdk5",
        // tk_x918UJaJiqhVQfB: "price_1MWpiKD0vTZ4QB9cBib7pLEM",
        // tk_oj5KlsNQ5SbopmM: "price_1MgfHjD0vTZ4QB9cwC3aaPlX",
        // tk_PEyJESdCJRgqAOW: "price_1MgfJwD0vTZ4QB9cLTstqyzc",
        // tk_tkBtfE1E1sMoyFP: "price_1MgfxjD0vTZ4QB9cS1syu8eh",

        tk_WyE2K2WZoRQTtRB: "price_1Mrf0QD0vTZ4QB9cx23kJfEq",

        // tk_C2kgKmbtPFtSIgP: "price_1Mgg0jD0vTZ4QB9cWaekR0OD",
        // tk_ksGoGElKbcIpSJP: "price_1Mgg4YD0vTZ4QB9cBoKzilyS",
        tk_mvtwRgxoFfOWzmb: "price_1Mgg9fD0vTZ4QB9cK4Igfzww",
        // tk_WvtrmLsVcnkyv9f: "price_1Mn4V5D0vTZ4QB9cAwFHPl3f",
        // tk_BiddUyw900uRtqw: "price_1Mn4UrD0vTZ4QB9cI82nVlCe",
        tk_M6GEZ4MQJEbgLKh: "price_1Mn4VbD0vTZ4QB9cbEHuor7v",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        mealName: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_12.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_13.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_14.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_15.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_16.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_17.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_18.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_19.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/KappaWhiteBlack/2022B&W_21.jpg",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    upgradeToVip() {
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        userEmail: vm.email.toLowerCase(),
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/redeem_discounted_ticket",
          payloadToPush
        )
        .then((res) => {
          vm.isSubmitted = false;
          vm.TicketsResult.forEach((o, i) => {
            if (o.ticketCode == vm.TicketsResult[vm.selected].ticketCode) {
              vm.tmpTicket = { ...this.TicketsResult[vm.selected] };
              vm.tmpTicket.oldCode = vm.TicketsResult[vm.selected].ticketCode;
              vm.TicketsResult[i] = {
                ...vm.TicketsResult[i],
                ...res.data.Result,
                oldCode: vm.TicketsResult[vm.selected].ticketCode,
              };
            }
          });

          vm.TicketsResult = JSON.parse(JSON.stringify(vm.TicketsResult));
          vm.$toast.success(res.data.Message, { timeout: 4000 });
        })
        .catch((err) => {
          vm.isSubmitted = false;
          vm.$toast.error(err.response.data.Message, { timeout: 4000 });
        });
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    countChanged() {
      if (this.tickcount < 10) {
        //  this.redeemData = null;
        //  this.redeemPriceId = "";
      }
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].availableTickets;
          const apr =
            selectedTicket.ticketType === "DONATION"
              ? 1
              : this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.ticketForm.ticketCount = 1;
      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeempromo = "";
      this.redeemPriceId = "";
      this.donationPid = null;
      this.fullname = "";
      this.email = "";
      const groupticks = [
        "tk_mvtwRgxoFfOWzmb",
      ];
      this.tickcount = groupticks.includes(this.TicketsResult[idx].ticketCode)
        ? 10
        : 1;

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = groupticks.includes(this.TicketsResult[idx].ticketCode) ? 9 : 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          promo_code: this.redeempromo,
        }
      };

      if (vm.donationOptions[vm.TicketsResult[vm.selected].ticketCode]) {
        payloadToPush.priceId = vm.donationPid;
      }

      if (this.exclude.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        delete payloadToPush["additionalInfo"];
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            //  vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;

            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_2nd_black_food_truck_festival_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            // tk_GOpKmfhiRdpVzSa: null,
            // tk_txh8hOKEPvMisRT: null,
            // tk_SQ9NtuTj9hN4haH: null,
            // tk_gXmgcPj3HjoT5QM: null,
            // tk_8A9AfTiJ7MI9tG4: null,
            // tk_x918UJaJiqhVQfB: null,
            // tk_oj5KlsNQ5SbopmM: null,
            // tk_PEyJESdCJRgqAOW: null,
            // tk_tkBtfE1E1sMoyFP: null,
            tk_WyE2K2WZoRQTtRB: null,

            // tk_C2kgKmbtPFtSIgP: null,
            // tk_ksGoGElKbcIpSJP: null,
            tk_mvtwRgxoFfOWzmb: null,
            // tk_WvtrmLsVcnkyv9f: null,
            // tk_BiddUyw900uRtqw: null,
            tk_M6GEZ4MQJEbgLKh: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      if (!this.excludeNames.includes(this.ticketForm.ticketName)) {
        bodyFormData.append(
          "additionalInfo",
          JSON.stringify({
            type: "Dinner Selection",
            value: this.ticketForm.mealName,
          })
        );
      }

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const vm = this;

    setTimeout(() => {
      this.$watch(
        () => {
          return this.$refs.modal.is_open;
        },
        (val) => {
          if (!val) {
            vm.TicketsResult.forEach((o, i) => {
              if (vm.tmpTicket && o.oldCode == vm.tmpTicket.oldCode) {
                vm.TicketsResult[i] = vm.tmpTicket;
                vm.tmpTicket = null;
              }
            });

            vm.TicketsResult = JSON.parse(JSON.stringify(vm.TicketsResult));
          }
        }
      );
    }, 2000);

    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      //  this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      this.showsuccess = false;
      localStorage.removeItem("refsId");
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Black Food Truck Festival Two Day Festival Package",

    meta: [
      {
        name: "description",
        content: "MYLE - Black Food Truck Festival Two Day Festival Package",
      },

      {
        name: "keywords",
        content: "MYLE - Black Food Truck Festival Two Day Festival Package",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/2nd-black-food-truck-festival-two-day-festival-package",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

textarea {
  resize: none;
}

.bolded {
  font-weight: bold;
}
</style>
