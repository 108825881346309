<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/atlanta_skyline_image.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.2rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->

      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Fredoka', sans-serif; font-weight: 50"
              >
                MYLE SUNSET SESSIONS – Atlanta
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Information -->
      <section id="download" class="white-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2
                  class="mt-4"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 26px;
                  "
                >
                  MYLE is coming to Atlanta! Join our Sunset Session <br />
                  Thursday, March 31 from 6:00-8:00 p.m. <br />
                  Russell Innovation Center for Entrepreneurs
                </h2>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-9 col-lg-9 col-sm-9">
              <h6
                class="mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
              >
                RSVP Today for you and your guests to join us for this fun, free
                and entertaining event!
                <a href="#register" target="_self"> Register Now</a>
              </h6>
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Enjoy an ultimate charcuterie board, music, and door
                prizes!</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Rub shoulders with other Atlanta business owners,
                influencers, and promoters</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Learn more about
                <a
                  href="https://myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MYLE</a
                >
                - the World’s Most Dynamic Entertainment Platform</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Receive an introduction to
                <a
                  href="https://myle.com/extramyle"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Extra MYLE International</a
                >, our non-profit that will positively impact and benefit
                underprivileged youth in Atlanta.</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Meet the MYLE Co-Founders!</h8
              ><br />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-3">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/orlando_launch_couple_img.png"
                  alt="Saturday Event"
                  style="
                    max-width: 100%;
                    max-height: 100%;
                    transform: rotate(3deg);
                  "
                />
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-9 col-lg-9 col-sm-9">
              <h6
                class="mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
              >
                MYLE can help you:
              </h6>
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Promote your events or venue</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Engage audiences</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Get data insights on your customers</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Generate revenue</h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Sell Tickets</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Set-up and manage private and public events</h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Publicize and drive your events</h8
              ><br />
            </div>

            <div class="col-md-3 col-lg-3 col-sm-3">
              <div style="text-align: center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/orlando_launch_couple_img_2.png"
                  alt="Saturday Event"
                  style="
                    max-width: 100%;
                    max-height: 100%;
                    transform: rotate(-3deg);
                  "
                />
              </div>
            </div>
          </div>

          <h6
            class="mb-3 mt-5 text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 20px;
            "
          >
            <a
              href="https://myle.com/atlanta-rsvp"
              target="_blank"
              rel="noopener noreferrer"
            >
              RSVP</a
            >
            Today for you and your guests to join us for this fun, free and
            entertaining event!<br /><br />
          </h6>
        </div>

        <div class="col-md-12 text-center">
          <h4
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 28px;
            "
          >
            Limited Space - Registrations Required - Register Today!
          </h4>
        </div>
      </section>
      <hr />
      <!-- Information -->

      <!-- Register forms starts -->
      <div id="register">
        <form>
          <div class="container px-5">
            <div class="row">
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
                <img
                  class="download-img"
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/myle_clean_logo.png"
                  style="width: 60%; max-width: 60%; height: auto"
                />
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h4
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 25px;
                      "
                    >
                      Registration Form
                    </h4>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Name*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Name"
                      type="text"
                      required
                      v-model="payload.name"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Email*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Email"
                      type="email"
                      required
                      v-model="payload.email"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Company/Organization*</label
                    >
                    <input
                      class="form-control"
                      placeholder="If not affiliate with a company, type N/A"
                      type="text"
                      required
                      v-model="payload.company"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Role*</label
                    >
                    <select class="form-control" v-model="payload.role">
                      <option
                        class="form-control"
                        v-bind:key="role + 'b'"
                        v-for="role in roles"
                        :value="role"
                      >
                        {{ role }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Additional Attendees*</label
                    >
                    <select class="form-control" v-model="payload.attendees">
                      <option
                        class="form-control"
                        v-bind:key="attendee + 'b'"
                        v-for="attendee in attendees"
                        :value="attendee"
                      >
                        {{ attendee }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Have you downloaded or signed up for MYLE?*</label
                    ><br />
                    <input
                      type="radio"
                      name="appdownload"
                      value="1"
                      v-model="payload.appDownload"
                    />
                    Yes
                    <input
                      type="radio"
                      name="appdownload"
                      value="0"
                      v-model="payload.appDownload"
                      class="ml-3"
                    />
                    No
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <input type="checkbox" v-model="payload.receiveEmail" />
                    Yes! I want to receive emails from MYLE to find things to
                    do, places to go and food to eat. I understand I can
                    unsubscribe at anytime
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-center my-3">
                    <button
                      type="button"
                      @click="register()"
                      :disabled="
                        !payload.name.trim().length ||
                        !payload.company.trim().length ||
                        !payload.role.trim().length ||
                        !payload.attendees.trim().length ||
                        !invalidemail ||
                        isSubmitted
                      "
                      class="btn solid-btn m-auto"
                      style="pointer-events: all; cursor: pointer"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Register forms ends -->
    </div>
    <br />
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h3 class="text-center text-white">Fun is waiting for you.</h3>
            <h2 class="text-center text-white">Download or Sign-up Today!</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  name: "TrainingComponent",
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },
  computed: {
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.payload.email
      );
    },
  },
  methods: {
    register: function () {
      const payloadToPush = {
        name: this.payload.name,
        email: this.payload.email.toLowerCase(),
        role: this.payload.role,
        attendees: this.payload.attendees,
        organization: this.payload.company,
        app_downloaded: Number(this.payload.appDownload),
        subscribed: this.payload.receiveEmail ? 1 : 0,
      };
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/atlanta_rsvp_requested",
          payloadToPush
        )
        .then((response) => {
          vm.isSubmitted = false;
          if (
            response.data &&
            response.data.Message &&
            response.data.Status == "Success"
          ) {
            vm.$toast.success("Request submitted", { timeout: 4000 });
            vm.payload = {
              role: "Convention/Tourism Representative",
              name: "",
              email: "",
              company: "",
              attendees: "0",
              appDownload: 1,
              receiveEmail: 1,
            };
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Something went wrong", { timeout: 4000 });
        });
    },
  },
  data() {
    return {
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      isSubmitted: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      payload: {
        role: "Convention/Tourism Representative",
        name: "",
        email: "",
        company: "",
        attendees: "0",
        appDownload: 1,
        receiveEmail: true,
      },
      roles: [
        "Convention/Tourism Representative",
        "DJ",
        "Event Goer",
        "Event Planner",
        "Faith Based Representative",
        "Hotel Representative",
        "Influencer",
        "Investor",
        "Lifestyle Critic/Blogger/Writer",
        "Non-Profit Representative",
        "Promoter",
        "Venue Owner",
        "Social Organization Representative",
        "Other",
      ],
      attendees: ["0", "1", "2", "3", "4"],
    };
  },

  metaInfo: {
    title: "Atlanta RSVP | MYLE",

    meta: [
      {
        name: "description",
        content: "Regsiter yourself for Atlanta launch of MYLE",
      },

      {
        name: "keywords",
        content: "atlanta events | atlanta rsvp | atlanta launch rsvp",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/atlanta-rsvp",
      },
    ],
  },
};
</script>

<style>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
</style>
