<template>
  <header class="header">
    <!--start navbar-->
    <nav
      id="barr"
      class="navbar navbar-expand-lg navColor fixed-top"
      v-bind:class="{
        affix: hasAffix,
        'custom-nav': coloredLogo,
        'bg-transparent': !coloredLogo,
        'white-bg': coloredLogo,
      }"
    >
      <div class="container">
        <a class="navbar-brand" href="/"
          ><img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo-navbar.svg"
            width="210"
            alt="logo"
            class="img-fluid"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          @click="mobileNavClicked"
          v-bind:class="{ collapsed: collapsed }"
        >
          <span class="ti-menu"></span>
        </button>

        <div
          class="collapse navbar-collapse main-menu h-auto"
          v-bind:class="{ show: !collapsed }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item nav-link page-scroll">
              <router-link to="/#home" v-scroll-to="'#heroPlayer'"
                >Home</router-link
              >
            </li>
            <li class="nav-item nav-link page-scroll">
              <router-link to="/#features" v-scroll-to="'#features'"
                >Features</router-link
              >
            </li>
            <li class="nav-item nav-link page-scroll">
              <router-link to="business" v-scroll-to="'#business'"
                >MYLE for Business</router-link
              >
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link page-scroll dropdown-toggle"
                href="#"
                id="navbarDropdownHome"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Earn 💰
              </a>
              <div
                class="dropdown-menu submenu"
                aria-labelledby="navbarDropdownHome"
              >
                <router-link to="affiliate" v-scroll-to="'#affiliate'"
                  >Earn Cash as an Affiliate</router-link
                >

                <router-link to="profit-partner" v-scroll-to="'#profit-partner'"
                  >Become a Profit Partner</router-link
                >
              </div>
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link to="myle-ticketing" v-scroll-to="'#myle-ticketing'"
                >Ticketing</router-link
              >
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link page-scroll dropdown-toggle"
                href="#"
                id="navbarDropdownHome"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                MYLE Launches
              </a>
              <div
                class="dropdown-menu submenu"
                aria-labelledby="navbarDropdownHome"
              >
                <router-link to="orlando-launch">Orlando Launch</router-link>

                <router-link to="atlanta-launch">Road to Atlanta</router-link>

                <router-link to="columbus-cincinnati"
                  >Columbus to Cincy</router-link
                >
                <router-link to="kentucky">Kentucky</router-link>

                <!-- <router-link to="atlanta-rsvp"
                  >Sunset Sessions - ATL</router-link
                > -->

                <router-link to="tennessee">Tennessee</router-link>

                <router-link to="atlanta">Atlanta</router-link>

                <router-link to="road-trip-blog">The Blog</router-link>
              </div>
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link to="extramyle" v-scroll-to="'#extramyle'"
                >Extra MYLE</router-link
              >
            </li>

            <!--  <li class="nav-item nav-link page-scroll">
            <router-link to="#about" v-scroll-to="'#about'">About</router-link>
        </li> -->

            <!--         <li class="nav-item">
            <router-link
            class="nav-link page-scroll"
            to="#features"
            v-scroll-to="'#features'">Features</router-link>
        </li>
        <li class="nav-item">
            <router-link
            class="nav-link page-scroll"
            to="#pricing"
            v-scroll-to="'#pricing'">Pricing</router-link>
        </li>

        <li class="nav-item">
            <router-link
            class="nav-link page-scroll"
            to="#screenshots"
            v-scroll-to="'#screenshots'">Screenshots</router-link>
        </li>
        <li class="nav-item dropdown">
            <a
            class="nav-link page-scroll dropdown-toggle"
            href="#"
            id="navbarDropdownPage"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">Pages
        </a>
        <div
        class="dropdown-menu submenu"
        aria-labelledby="navbarDropdownPage"
        >
        <router-link
        class="dropdown-item"
        to="login-1"
        v-scroll-to="'#login-1'">Login Page 1</router-link>
        <router-link
        class="dropdown-item"
        to="login-2"
        v-scroll-to="'#login-2'">Login Page 2</router-link>

        <router-link
        class="dropdown-item"
        to="signup-1"
        v-scroll-to="'#signup-1'">Signup Page 1</router-link>
        <router-link
        class="dropdown-item"
        to="signup-2"
        v-scroll-to="'#signup-2'">Signup Page 2</router-link>
        <router-link
        class="dropdown-item"
        to="reset-password"
        v-scroll-to="'#reset-password'">Password Reset</router-link>
        <router-link
        class="dropdown-item"
        to="change-password"
        v-scroll-to="'#change-password'">Change Password</router-link>
        <router-link
        class="dropdown-item"
        to="download"
        v-scroll-to="'#download'">Download Page</router-link>
        <router-link
        class="dropdown-item"
        to="review"
        v-scroll-to="'#review'">Review Page</router-link>
        <router-link
        class="dropdown-item"
        to="faq"
        v-scroll-to="'#faq'">FAQ Page</router-link>
        <router-link
        class="dropdown-item"
        to="404"
        v-scroll-to="'#404'">404 Page</router-link>
        <router-link
        class="dropdown-item"
        to="coming-soon"
        v-scroll-to="'#coming-soon'">Coming Soon</router-link>
        <router-link
        class="dropdown-item"
        to="thank-you"
        v-scroll-to="'#thank-you'">Thank You</router-link>

        <router-link
        class="dropdown-item"
        to="team-1"
        v-scroll-to="'#team-1'">Team Page</router-link>
        <router-link
        class="dropdown-item"
        to="team-2"
        v-scroll-to="'#team-2'">Team Single</router-link>
    </div>
</li>
<li class="nav-item dropdown">
    <a
    class="nav-link page-scroll dropdown-toggle"
    href="#"
    id="navbarBlogPage"
    role="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false">Blog
</a>

<div
class="dropdown-menu submenu"
aria-labelledby="navbarBlogPage">
<router-link
class="dropdown-item"
to="blog-grid"
v-scroll-to="'#blog-grid'">Blog Grid</router-link>
<router-link
class="dropdown-item"
to="blog-left-sidebar"
v-scroll-to="'#blog-left-sidebar'">Blog Left Sidebar</router-link>
<router-link
class="dropdown-item"
to="blog-right-sidebar"
v-scroll-to="'#blog-right-sidebar'">Details Right Sidebar</router-link>
</div>
</li>
<li class="nav-item">
    <router-link
    class="nav-link page-scroll"
    to="#team"
    v-scroll-to="'#team'">Team</router-link>
</li> -->
            <li class="nav-item">
              <router-link
                class="nav-link page-scroll"
                to="/#contact"
                v-scroll-to="'#contact'"
                >Contact</router-link
              >
            </li>

            <!-- <li class="nav-item nav-link">
              <a
                href="https://www.facebook.com/MYLEMARKERS"
                target="_blank" rel="noopener noreferrer"
                title="Facebook"
                ><span class="ti-facebook"></span
              ></a>
            </li> -->

            <!-- <li class="nav-item nav-link">
              <a
                href="https://www.facebook.com/MYLEMARKERS"
                target="_blank" rel="noopener noreferrer"
                title="Facebook"
                ><span class="ti-facebook"></span
              ></a>
            </li>

            <li class="list-inline-item">
              <a
                href="https://twitter.com/MYLEMARKERS"
                target="_blank" rel="noopener noreferrer"
                title="Twitter"
                ><span class="ti-twitter"></span
              ></a>
            </li>

            <li class="list-inline-item">
              <a
                href="https://www.instagram.com/mylemarkers/"
                target="_blank" rel="noopener noreferrer"
                title="Instagram"
                ><span class="ti-instagram"></span
              ></a>
            </li>

            <li class="list-inline-item">
              <a
                href="https://www.linkedin.com/company/myle/"
                target="_blank" rel="noopener noreferrer"
                title="LinkedIn"
                ><span class="ti-linkedin"></span
              ></a>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
    <!--end navbar-->
  </header>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>
