<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3 class="w-auto d-inline-block">
                {{
                  $route.query.checkout == "success"
                    ? "Successful Purchased"
                    : "Oops!"
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              {{
                $route.query.checkout == "success"
                  ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                  : "Something went wrong with the payment. Please try again."
              }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <br />
              <button
                type="button"
                @click="$refs.confirmmodal.close()"
                class="btn solid-btn my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  width: 150px;
                  margin-top: 11px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Purchaser Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">{{
                [...section2, ...section3].includes(
                  TicketsResult[selected].ticketCode
                )
                  ? "Number of Sponsorship(s)"
                  : "Number of Ticket(s)"
              }}</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
                        <div
                          class="row col-md-12 mt-4"
                          v-if="TicketsResult[selected] && TicketsResult[selected].discount"
                        >
                          <div class="col-md-6">
                            <label class="">Do you have a promo code?</label>

                            <input
                              type="text"
                              v-model="redeempromo"
                              class="form-control"
                              placeholder="Promo code"
                            />
                          </div>

                          <div class="col-md-4">
                            <button
                              type="button"
                              @click="redeem()"
                              :disabled="!redeempromo.trim().length"
                              class="btn solid-btn btn-sm my-auto"
                              id="reserve"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                padding: 7px;
                                margin-top: 11px !important;
                              "
                            >
                              Redeem
                            </button>
                          </div>
                        </div> -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                [...section2, ...section3, ...section4, ...section5].includes(
                  TicketsResult[selected].ticketCode
                )
              "
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efName"
                  class="form-control"
                  placeholder="Primary Contact First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="elName"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                [...section2, ...section3, ...section4, ...section5].includes(
                  TicketsResult[selected].ticketCode
                )
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eEmail"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="emNumber"
                  class="form-control"
                  placeholder="Mobile Number"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                [...section2, ...section3, ...section4, ...section5].includes(
                  TicketsResult[selected].ticketCode
                )
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eTitle"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eRefferedBy"
                  class="form-control"
                  placeholder="Referred By: (if applicable)"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                [...section2, ...section3, ...section4, ...section5].includes(
                  TicketsResult[selected].ticketCode
                )
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="business_organization_name"
                  class="form-control"
                  placeholder="Business / Organization"
                />
              </div>
            </div>

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="[...section1].includes(TicketsResult[selected].ticketCode)"
            >
              <br />
              <hr class="w-100 p-2" />
              <br />

              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efatherName"
                  class="form-control"
                  placeholder="Father/Father Figure First Name"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="efEmail"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="[...section1].includes(TicketsResult[selected].ticketCode)"
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>
            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="[...section1].includes(TicketsResult[selected].ticketCode)"
            >
              <div
                class="col-md-6 pb-4"
                v-bind:key="'xxx' + indval"
                v-for="indval in agecount[TicketsResult[selected].ticketCode]"
              >
                <input
                  type="number"
                  min="1"
                  max="50"
                  v-model="age['age' + indval]"
                  class="form-control"
                  :placeholder="
                    agecount[TicketsResult[selected].ticketCode].length == 1
                      ? 'Child Age'
                      : agePlaceholders[indval - 1]
                  "
                  @keyup="vchange = !vchange"
                />
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected].discount
                    ? // &&
                      // TicketsResult[selected].ticketCode == 'tk_qsSj5fnRek75qJE'
                      !redeempromo.trim().length || !redeemData
                    : false) ||
                  checkSection
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BLACKFATHERDAUGHTERDANCEATCOSI.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                BLACK FATHER DAUGHTER DANCE AT COSI
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                Saturday, February 17, 2024 <br />
                07:00pm - 10:00pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://maps.app.goo.gl/ge9Dv4nnNLzt9Rif96"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  COSI
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">THANK YOU!!!</h3>
                  </div>

                  <div class="download-content mt-5">
                    <p
                      class=""
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                      "
                    >
                      On behalf of Male Behavioral Health (MBH) and the Columbus
                      Urban League (CUL), we would like to say THANK YOU for
                      your support of the Black Father Daughter Dance which was
                      held at COSI on Saturday, February 17th, 2024.

                      <br />

                      The pictures say it all.
                      <br />

                      Love, Fun and Amazing Memories!

                      <br />

                      <a
                        href="https://blackgirldadweek.com/gallery/#"
                        class="btn solid-btn mb-1 text-center mt-2"
                        target="_blank" rel="noopener noreferrer"
                        >Click here to view</a
                      >

                      <br /><br />

                      Note: We are still accepting donations to cover the cost
                      associated with hosting this amazing event and appreciate
                      your contributions to help us keep this event going.

                      <br />

                      When posting your photos, please tag @mylemarkers and use
                      the following hashtags: #blackfatherdaughterdance
                      #justMYLEit #makeyourlifeentertaining
                    </p>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="container">
              <div class="row w-100 m-0 text-center promo-section MYLE-green">
                <div class="col-lg-12 col-md-12">
                  <div class="mt-3">
                    <div class="section-heading mb-3">
                      <h5 class="text-center text-white">
                        Black Father Daughter Dance at COSI Recap
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <video-embed
                css="embed-responsive-16by9"
                src="https://youtu.be/WJJgvOM6I34"
              ></video-embed>
            </div>
            <br /><br />

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">SOLD OUT</h3>
                    <br />
                    <h5 class="w-auto d-inline-block mb-2">
                      TO BE PLACED ON WAITLIST FOR SELECT TICKETS
                      <a
                        href="https://forms.gle/mgjfi11sgjQVwTe59"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CLICK HERE</a
                      >
                    </h5>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section1Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h4
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 22px;
                  "
                >
                  For more than 4 children,
                  <a href="mailto:info@mbcinc.com"> contact us.</a>
                </h4>
              </div>
            </div>
            <!--
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3 class="w-auto d-inline-block mb-2">
                        DEPA TICKET OPTIONS ONLY
                        <span class="">
                          <h5 style="color: red">(SOLD OUT)</h5>
                        </span>
                      </h3>
                    </div>
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-3"
                        style="
                          font-family: 'Quicksand', sans-serif;
                          font-weight: 500;
                          font-size: 20px;
                        "
                      >
                        Unfortunately, we are at capacity at this time.
                        <br />
                        However, we are creating a WAITLIST with the hopes of
                        obtaining more space.
                        <br />
                        Tap the button below and complete the form to be added to
                        the WAITLIST
                      </h3>
                      <div class="text-center mb-2">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSdvmghiFwqHZCq3c164aCq6YuTQ2eE1qEPsT3isiyhl_Zu_1w/viewform"
                          class="btn solid-btn mb-1 text-center"
                          target="_blank" rel="noopener noreferrer"
                          >Join Waitlist</a
                        >
                      </div>
                    </div>

                    <div class="d-flex flex-row flex-wrap justify-content-center">
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 450px !important;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in section5Filter(TicketsResult)"
                      >
                        <div class="card-header MYLE-green white text-center">
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="display: flex; flex-direction: column"
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in filteredMessages(obj)"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p v-if="obj.ticketType != 'FREE'">
                              <b class="font-weight-bold">Total Price:</b>
                              ${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn"
                              id="reserve"
                              @click="openmodel(obj.ticketCode)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h4
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 22px;
                    "
                  >
                    For more than 4 children,
                    <a href="mailto:info@mbcinc.com"> contact us.</a>
                  </h4>
                </div>
              </div> -->

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">DONATIONS</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section4Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3 class="w-auto d-inline-block mb-2">SPONSORSHIPS</h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section5Filter(TicketsResult)"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br /><br />
                  <div class="section-heading mt-1">
                    <div class="text-center">
                      <a
                        href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/BLACK_GIRL_DAD_WEEK_SPONSORSHIP_PARTNERSHIP_PACKAGE_v2_2024.pdf"
                        class="btn solid-btn text-center"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Sponsorship Deck
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Section 2 -->
            <!-- Tickets Section starts -->
            <!-- <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3 class="w-auto d-inline-block mb-2">
                        SMALL NONPROFIT SPONSOR AND SMALL BUSINESSES
                      </h3>
                    </div>

                    <div class="d-flex flex-row flex-wrap justify-content-center">
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 450px !important;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in section2Filter(TicketsResult)"
                      >
                        <div class="card-header MYLE-green white text-center">
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="display: flex; flex-direction: column"
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in filteredMessages(obj)"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p v-if="obj.ticketType != 'FREE'">
                              <b class="font-weight-bold">Total Price:</b>
                              ${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn"
                              id="reserve"
                              @click="openmodel(obj.ticketCode)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h4
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 20px;
                    "
                  >
                    Direct Donations are tax-deductible. Donation letters will be
                    provided for donations above $250.
                  </h4>
                </div>
              </div> -->

            <!-- Section 3 -->
            <!-- Tickets Section starts -->
            <!-- <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3 class="w-auto d-inline-block mb-2">
                        COMMUNITY SPONSOR
                      </h3>
                    </div>

                    <div class="d-flex flex-row flex-wrap justify-content-center">
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 450px !important;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in section3Filter(TicketsResult)"
                      >
                        <div class="card-header MYLE-green white text-center">
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="display: flex; flex-direction: column"
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in filteredMessages(obj)"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p v-if="obj.ticketType != 'FREE'">
                              <b class="font-weight-bold">Total Price:</b>
                              ${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn"
                              id="reserve"
                              @click="openmodel(obj.ticketCode)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h4
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 20px;
                    "
                  >
                    Direct Donations are tax-deductible. Donation letters will be
                    provided for donations above $250.
                  </h4>
                  <br />
                  <div class="text-center">
                    <a
                      href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/Sponsorship_levels_Brochure_COMPLETE.pdf"
                      class="btn solid-btn mb-1 text-center"
                      target="_blank" rel="noopener noreferrer"
                      >Sponsorship Details</a
                    >
                  </div>
                </div>
              </div> -->

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <!-- The Black Food Truck Festival is a family-oriented event
                          featuring the finest food trucks in the region in Charleston,
                          South Carolina. Our mission is to make an economic and social
                          impact on the community by showcasing and investing in area
                          Black-owned businesses.
                          <br />
                          <br />
                          The Black Food Truck Festival is the ultimate family reunion
                          featuring kid-friendly activities in a safe, secure environment.
                          Expect to experience a range of different cuisines while
                          enjoying performances by local bands and musicians.
                          <br />
                          <br />
                          Our goal is to create an atmosphere of unity, love, and positive
                          vibes. Come celebrate with us and have a great time while
                          creating a memorable experience!
                          <br />
                          <br /> -->

                Calling all Dads and Father figures. Create memories that will
                last a lifetime during our 2nd Annual Black Father Daughter
                Dance at COSI

                <br /><br />
                Calling all Dads and Father figures to join us for this very
                SPECIAL NIGHT filled with memories that will last a lifetime.
                Dance the night away with a Live DJ and enjoy food, fun, and
                music with people from all over Central Ohio.
                <br /><br />
                This event will take place on Saturday, February, 17th, 2024 at
                COSI. The party begins at 7pm and will end at 10pm.
                <br /><br />
                For your convenience, we have partnered with MYLE as our ticket
                provider.
                <br /><br />
                MYLE - Make Your Life Entertaining is a Black-Owned Event and
                Entertainment platform.
                <br /><br />
                Be sure to download the MYLE Events App via www.myle.com or in
                your iOS or Google Play Store under MYLE Events and be sure to
                use referral code “MBH” during account set-up.
                <br /><br />
                The MYLE App provides seamless transportation options including
                Waze/Apple Maps/Google Maps/Uber and Lyft which are all
                integrated into the App for your traveling convenience.
                <br /><br />
                Note: Your tickets will be available on the app in the
                Accounts/Tickets section after purchase.
                <br /><br />
                We look forward to seeing you soon.
                <br /><br />
                #JustMYLEit and #makeyourlifeentertaining
              </p>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <span class="">
                  <h5 class="mb-1">About our Ticketing Vendor:</h5>
                </span>

                <br />
                For your convenience, we partnered with MYLE as our ticket
                provider.
                <br />
                MYLE - Make Your Life Entertaining is a Black-Owned Event and
                Entertainment platform.
                <br />

                Be sure to download the MYLE Events App via www.myle.com or in
                your iOS or Google Play Store under MYLE Events and be sure to
                use referral code “MBH” during account set-up.

                <br />
                The MYLE App provides seamless transportation options including
                Waze/Apple Maps/Google Maps/Uber and Lyft which are all
                integrated into the App for your traveling convenience.

                <br />
                Note: Tickets are available on the app in the Accounts/Tickets
                section after purchase.

                <br />
                Thanks again for your support of the Black Father Daughter
                Dance. We look forward to seeing you at the next one.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      agePlaceholders: [
        "First Child Age",
        "Second Child Age",
        "Third Child Age",
        "Fourth Child Age",
      ],
      vchange: true,
      validSection: true,
      efName: "",
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {
        tk_p9jsNMp6Nm8MFzW: [1],
        tk_r33e9zukv4vx78g: [1, 2],
        tk_fCDtRMdHJ8DDkQe: [1, 2, 3],
        tk_SOzFxdnY4oQAbz3: [1, 2, 3, 4],
        // tk_rLGBmaMcdmBZH7S: [1],
        // tk_y5N2FVmEQjIhNzv: [1, 2],
        // tk_ztMCL3FJ9NI416h: [1, 2, 3],
        // tk_6O2AEcnJjcbCthW: [1, 2, 3, 4],
      },
      section1: [
        "tk_p9jsNMp6Nm8MFzW",
        "tk_r33e9zukv4vx78g",
        "tk_fCDtRMdHJ8DDkQe",
        "tk_SOzFxdnY4oQAbz3",
      ],
      section2: [
        // "tk_7ExrCN8MwiiydEH",
        // "tk_CvznjUaEkAOV3S4",
        // "tk_k3SXLEvtVvYAgFd",
        // "tk_zPmjRBLOjSBCyDe",
      ],
      section3: [
        // "tk_YS7rcktiaak9ztM",
        // "tk_dqhpDtcs3Yb3bhE",
        // "tk_T0s3RR16OfcPjaf",
      ],
      section4: [
        "tk_X2AUx4ghlAigiT6",
        "tk_XzL2fIY1NZ05e6Z",
        "tk_kgIZtznzlYRkERU",
        "tk_MxPZWlwIFl6r75f",
      ],

      section5: [
        "tk_IsqYuIJTtdsFsf5",
        "tk_GVLJX2GErFQtlwl",
        "tk_e8PJgZkUmeU6pqo",
        "tk_1S9bYqZE7EYNSiQ",
        "tk_XesjRi46yFZFAW2",
        "tk_Mx4OxizypFmdFTo",
        "tk_u2Xlt7zOB9LgHFf",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_p9jsNMp6Nm8MFzW: {
          messages: ["Ticket Price: $40.00", "Processing fee of $4.32"],
        },

        tk_r33e9zukv4vx78g: {
          messages: ["Ticket Price: $50.00", "Processing fee of $4.99"],
        },

        tk_fCDtRMdHJ8DDkQe: {
          messages: ["Ticket Price: $60.00", "Processing fee of $5.66"],
        },

        tk_SOzFxdnY4oQAbz3: {
          messages: ["Ticket Price: $70.00", "Processing fee of $6.33"],
        },

        // tk_rLGBmaMcdmBZH7S: {
        //   messages: ["Ticket Price: $40.00", "Processing fee of $3.36"],
        // },

        // tk_y5N2FVmEQjIhNzv: {
        //   messages: ["Ticket Price: $50.00", "Processing fee of $4.05"],
        // },

        // tk_ztMCL3FJ9NI416h: {
        //   messages: ["Ticket Price: $60.00", "Processing fee of $4.74"],
        // },

        // tk_6O2AEcnJjcbCthW: {
        //   messages: ["Ticket Price: $70.00", "Processing fee of $5.43"],
        // },

        tk_X2AUx4ghlAigiT6: {
          messages: ["Ticket Price: $25.00", "Processing fee of $3.32"],
        },

        tk_XzL2fIY1NZ05e6Z: {
          messages: ["Ticket Price: $50.00", "Processing fee of $4.99"],
        },

        tk_kgIZtznzlYRkERU: {
          messages: ["Ticket Price: $100.00", "Processing fee of $8.35"],
        },

        tk_MxPZWlwIFl6r75f: {
          messages: ["Ticket Price: $250.00", "Processing fee of $18.41"],
        },

        tk_IsqYuIJTtdsFsf5: {
          messages: ["Ticket Price: $500.00", "Processing fee of $35.17"],
        },
        tk_GVLJX2GErFQtlwl: {
          messages: ["Ticket Price: $1500.00", "Processing fee of $102.25"],
        },
        tk_e8PJgZkUmeU6pqo: {
          messages: ["Ticket Price: $2500.00", "Processing fee of $169.32"],
        },
        tk_1S9bYqZE7EYNSiQ: {
          messages: ["Ticket Price: $3000.00", "Processing fee of $202.86"],
        },
        tk_XesjRi46yFZFAW2: {
          messages: ["Ticket Price: $5000.00", "Processing fee of $337"],
        },
        tk_Mx4OxizypFmdFTo: {
          messages: ["Ticket Price: $10000.00", "Processing fee of $672.37"],
        },
        tk_u2Xlt7zOB9LgHFf: {
          messages: ["Ticket Price: $20000.00", "Processing fee of $1343.1"],
        },

        // tk_7ExrCN8MwiiydEH: {
        //   messages: ["Logo recognition associated with marketing materials"],
        // },

        // tk_CvznjUaEkAOV3S4: {
        //   messages: [
        //     "Logo recognition associated with marketing materials",
        //     "One table (4 TICKETS/8 seats). ONLY 3 AVAILABLE",
        //   ],
        // },

        // tk_k3SXLEvtVvYAgFd: {
        //   messages: [
        //     "Sponsor logo recognition on event home page",
        //     "Logo recognition on print and digital marketing materials",
        //     "Two tables (8 TICKETS/16 seats). ONLY 3 AVAILABLE",
        //   ],
        // },

        // tk_zPmjRBLOjSBCyDe: {
        //   messages: [
        //     "Featured on program guide",
        //     "Sponsor Logo recognition event home page",
        //     "Logo recognition on ALL marketing materials including radio ",
        //     "Three tables (12 TICKETS/24 seats). ONLY 3 AVAILABLE",
        //   ],
        // },

        // tk_YS7rcktiaak9ztM: {
        //   messages: [
        //     "Logo recognition as Platinum Sponsor",
        //     "Company logo advertisement on LANDING PAGE as a Gold Sponsor",
        //     "Company logo on all print marketing",
        //     "Logo with hyperlink displayed on BLACK GIRLDAD WEEK & BLACKFATHER DAUGHTER DANCE website",
        //   ],
        // },

        // tk_dqhpDtcs3Yb3bhE: {
        //   messages: [
        //     "Logo recognition as Platinum Sponsor",
        //     "Company logo advertisement on LANDING PAGE as a Platinum Sponsor",
        //     "Company logo on all print marketing",
        //     "Logo with hyperlink displayed on BLACK GIRLDAD WEEK & BLACKFATHER DAUGHTER DANCE website",
        //   ],
        // },

        // tk_T0s3RR16OfcPjaf: {
        //   messages: [
        //     "Acknowledged in Event Press Release Company featured in all media commercials",
        //     "Company featured on radio broadcasts & advertisements Logo recognition as Diamond Sponsor",
        //     "Company logo advertisement on LANDING PAGE as a Diamond Sponsor",
        //     "Company logo printed on all event marketing",
        //     "Logo with hyperlink displayed on BLACK GIRLDAD WEEK & BLACKFATHER DAUGHTER DANCE website",
        //   ],
        // },
      },

      priceIds: {
        tk_X2AUx4ghlAigiT6: "price_1OYUPfD0vTZ4QB9ctnm4FoS8",
        tk_XzL2fIY1NZ05e6Z: "price_1OYUQ6D0vTZ4QB9c1NT7ddVV",
        tk_kgIZtznzlYRkERU: "price_1OYUQWD0vTZ4QB9cNMfxoHz9",
        tk_MxPZWlwIFl6r75f: "price_1OYURDD0vTZ4QB9c8Q1GocMV",

        tk_p9jsNMp6Nm8MFzW: "price_1NJyCID0vTZ4QB9cSBkBFL2I",
        tk_r33e9zukv4vx78g: "price_1NJyCJD0vTZ4QB9cKEMxREsw",
        tk_fCDtRMdHJ8DDkQe: "price_1NJyCJD0vTZ4QB9czG243jYN",
        tk_SOzFxdnY4oQAbz3: "price_1NJyCKD0vTZ4QB9crV7Pk1tQ",

        tk_IsqYuIJTtdsFsf5: "price_1OYGeOD0vTZ4QB9ctNvbW0pa",
        tk_GVLJX2GErFQtlwl: "price_1OYI3ND0vTZ4QB9cf4TFWmSt",
        tk_e8PJgZkUmeU6pqo: "price_1OYI3ND0vTZ4QB9cJzEBTOqg",
        tk_1S9bYqZE7EYNSiQ: "price_1OYI3OD0vTZ4QB9cxImsyDNk",
        tk_XesjRi46yFZFAW2: "price_1OYI3OD0vTZ4QB9cIN7as94t",
        tk_Mx4OxizypFmdFTo: "price_1OYI3PD0vTZ4QB9cdGCp2TOI",
        tk_u2Xlt7zOB9LgHFf: "price_1OYI3PD0vTZ4QB9ctUbn0tId",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },
  methods: {
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    section2Filter(list) {
      return list.filter((l) => this.section2.includes(l.ticketCode));
    },
    section3Filter(list) {
      return list.filter((l) => this.section3.includes(l.ticketCode));
    },
    section4Filter(list) {
      return list.filter((l) => this.section4.includes(l.ticketCode));
    },
    section5Filter(list) {
      return list.filter((l) => this.section5.includes(l.ticketCode));
    },

    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (code) {
      this.validSection = true;
      this.fullname = "";
      this.email = "";
      this.efName = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;

      if (code == "RZ44zOQ66InQgZ") {
        this.age["age1"] = "";
      } else if (code == "tk_eVzbCBqzSuFPfd5") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_g19EFw7kOMyfuL8") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_rFyeu7irKUhlI1U") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }

      if (code == "tk_rLGBmaMcdmBZH7S") {
        this.age["age1"] = "";
      } else if (code == "tk_y5N2FVmEQjIhNzv") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_ztMCL3FJ9NI416h") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_6O2AEcnJjcbCthW") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }

      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      if (
        [
          ...this.section2,
          ...this.section3,
          ...this.section4,
          ...this.section5,
        ].includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          firstName: this.efName.trim(),
          lastName: this.elName.trim(),
          title: this.eTitle.trim(),
          RefferedBy: this.eRefferedBy.trim(),
          email: this.eEmail.toLowerCase(),
          phoneNumber: this.emNumber,
          business_organization_name: this.business_organization_name,
        };
      } else if (
        [...this.section1].includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        payloadToPush.extraInfo = {
          fatherName: this.efatherName.trim(),
          email: this.efEmail.trim().toLowerCase(),
          phoneNumber: this.ePhoneNumber.trim(),
          orgName: this.eOrgNumber.trim(),
          ...this.age,
        };
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch2ndBLACKFATHERDAUGHTERDANCEATCOSI_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_X2AUx4ghlAigiT6: null,
            tk_XzL2fIY1NZ05e6Z: null,
            tk_kgIZtznzlYRkERU: null,
            tk_MxPZWlwIFl6r75f: null,
            tk_p9jsNMp6Nm8MFzW: null,
            tk_r33e9zukv4vx78g: null,
            tk_fCDtRMdHJ8DDkQe: null,
            tk_SOzFxdnY4oQAbz3: null,

            tk_IsqYuIJTtdsFsf5: null,
            tk_GVLJX2GErFQtlwl: null,
            tk_e8PJgZkUmeU6pqo: null,
            tk_1S9bYqZE7EYNSiQ: null,
            tk_XesjRi46yFZFAW2: null,
            tk_Mx4OxizypFmdFTo: null,
            tk_u2Xlt7zOB9LgHFf: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    checkSection() {
      if (
        (this.vchange || !this.vchange) &&
        [
          ...this.section2,
          ...this.section3,
          ...this.section4,
          ...this.section5,
        ].includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.efName.trim().length <= 0 ||
          this.elName.trim().length <= 0 ||
          this.eTitle.trim().length <= 0 ||
          this.business_organization_name.trim().length <= 0 ||
          !this.checkInvalidEmail(this.eEmail) ||
          !this.invalidNum(this.emNumber)
        );
      } else if (
        (this.vchange || !this.vchange) &&
        [...this.section1].includes(
          this.TicketsResult[this.selected].ticketCode
        )
      ) {
        return (
          this.efatherName.trim().length <= 0 ||
          this.efEmail.trim().length <= 0 ||
          this.ePhoneNumber.trim().length <= 0 ||
          Object.keys(this.age).filter((a) => this.age[a] <= 0).length > 0
        );
      }

      return false;
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | BLACK FATHER DAUGHTER DANCE AT COSI",

    meta: [
      {
        name: "description",
        content: "MYLE | BLACK FATHER DAUGHTER DANCE AT COSI",
      },

      {
        name: "keywords",
        content: "MYLE | BLACK FATHER DAUGHTER DANCE AT COSI",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/black-father-daughter-dance-at-cosi",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
