<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
          <div class="col-md-12" id="banner">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
              style="
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-top: 4.2rem;
              "
            />
          </div>
        </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section mt-5" style="background: #0068ef">
        <div class="col-lg-12 col-md-12 mt-5">
          <div class="mt-2">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 2.5em;
                  color: white;
                "
              >
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: What is the date and time of the event?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Wednesday, October 23, 2024 from 7:00 pm EST | 6:00 pm CST | 4:00 pm
            PST <br />
            The pre-show will start at 6:30 pm EST / 5:30 pm CST / 3:30 pm
            PST<br />
            We recommend joining the experience 30 minutes early to enjoy the
            pre-show, which will include instructions on preparing your
            ingredients and organizing your kitchen space.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: Where can I find instructions to join the experience?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            All registered Cardmembers will receive a reminder email 3-5 days
            prior to the experience. This email will contain a digital recipe
            card listing the required ingredients for preparing the dish, along
            with instructions on how to access the virtual platform.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: How do I join the virtual experience?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            <!-- The virtual event will take place on a virtual platform. You will
            not need to sign up for an account to participate. All registered
            cardmembers will receive a reminder email 3-5 days before the
            experience. This email will include a digital recipe card with a
            list of ingredients needed to prepare the dish, along with
            instructions on how to join the virtual platform. -->

            You will receive an email reminder 3-5 days before the event, which
            will include instructions on how to access the virtual event, along
            with the link.
            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: Will I need any ingredients or cooking utensils to participate in
            this event?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Prior to the experience, you will receive a digital recipe card
            outlining the ingredients and supplies needed to prepare the dish.
            We suggest having all ingredients and utensils ready before the
            event begins.
            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: If I have registered to attend this virtual experience, will I
            receive the complimentary gift box?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you are among the first 1,400 Cardmembers to register, you will
            receive a complimentary gift box. Eligible recipients will receive a
            separate email containing shipping details for the gift box.
            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: What is included in the gift box that is being delivered?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            We all love surprises, if you are one of the first 1,400 Cardmembers
            to register, you'll discover what's inside once it arrives.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: When and where will the gift box be delivered?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you are among the first 1,400 cardmembers to register and receive
            the gift box confirmation email, your gift boxes will be shipped on
            October 10, 2024, to the address you provided during registration.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: Where can I find information on the featured destination, Las
            Vegas?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you’re looking for travel inspiration to Las Vegas or want to
            find out more about Priceline’s travel deals, please visit our
            website by clicking
            <a
              href="https://www.priceline.com/partner/v2/packages-lasvegas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="bolded" style="text-decoration: underline"
                >here</span
              ></a
            >
            for more information.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: Can I transfer the invitation?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            This invitation is non-transferable and serves as a thank-you for
            your relationship with the Priceline Visa Card.

            <br />
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: bold;
              font-size: 1.5em;
              color: black;
            "
          >
            Q: What do I do if I have additional questions regarding the
            experience?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you have any additional questions regarding this experience,
            please email us at

            <a href="mailto:pricelinerewards@vegagroup.com">
              pricelinerewards@vegagroup.com</a
            >.

            <br />
          </p>

          <p
            class="text-center mt-5"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 500;
              font-size: 0.9em;
              color: black;
            "
          >
            The Priceline Rewards Visa Cards are issued by Barclays Bank
            Delaware pursuant to a license from Visa U.S.A Inc.<br />Visa and
            Visa Signature are registered trademarks and used under license from
            Visa U.S.A. Inc.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/Navbar2";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons3/");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon FAQs",

    meta: [
      {
        name: "description",
        content:
          "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon FAQs",
      },

      {
        name: "keywords",
        content:
          "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon FAQs",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon-faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}

.bolded {
  font-weight: bold;
}
</style>
