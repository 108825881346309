<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <!-- model -->
    <nu-modal ref="zmodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 28px;
                "
              >
                Place Your Bid
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div
              class="flex d-flex"
              style="
                width: 100%;
                justify-content: space-between;
                margin-top: 2rem;
                flex-wrap: wrap;
              "
            >
              <div>
                <p
                  style="
                    margin: 0;
                    color: black;
                    font-weight: bold;
                    font-size: 16px;
                  "
                >
                  Title
                </p>
                <p
                  style="
                    width: 18rem;
                    font-family: 'Quicksand', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                  "
                >
                  {{ selected ? selected.title : "" }}
                </p>
              </div>

              <div>
                <p
                  style="
                    margin: 0;
                    color: black;
                    font-weight: bold;
                    font-size: 16px;
                  "
                >
                  Current Bid Value
                </p>
                <p
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                  "
                >
                  ${{ selected ? selected.currentBidValue : "" }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <label>Name</label>
            <input
              type="text"
              class="form-control mb-3"
              placeholder="Name"
              required
              v-model="fullname"
            />
          </div>
          <div class="col-md-6">
            <label>Email</label>
            <input
              type="email"
              class="form-control mb-3"
              placeholder="Email"
              v-model="email"
              required
            />
          </div>
          <div class="col-md-6">
            <label>Bid Value ($)</label>
            <input
              type="number"
              :min="selected ? Number(selected.currentBidValue) + 10 : 0"
              class="form-control mb-3"
              placeholder="Bid Value"
              v-model="bidValue"
              required
            />
          </div>

          <div class="col-md-12 text-center mt-4">
            <button
              @click="bidNow()"
              :disabled="
                !fullname.trim().length ||
                !invalidemail ||
                bidValue < Number(selected ? selected.currentBidValue : 0) ||
                isSubmitted
              "
              class="btn solid-btn m-auto mr-2"
              id="reserve"
              style="pointer-events: all; cursor: pointer"
            >
              Bid Now
            </button>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_NAAIA.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                2023 NAAIA National Conference Gala
                <br />
                Silent Auction
              </h1>

              <!-- <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Saturday, August 12, 2023, <br />
                6:00pm - 10:00pm
              </h1> -->

              <!-- <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/kf49TUjDF71D52QA9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: black"
                >
                  InterContinental Buckhead Atlanta, an IHG Hotel
                </a>
              </h1> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test text-center mb-4"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 22px;
                "
              >
                Support the JL Tillman Scholarship through the Awards Gala
                Silent Auction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="section-heading mb-2 mt-5">
          <div class="text-center">
            <a
              href="https://myle.com/100-black-men-of-dekalb-county"
              class="btn solid-btn-100blackmen mb-1 text-center"
              target="_self"
              >Back To Main Page
            </a>
          </div>
        </div> -->

      <div class="row col-md-12">
        <div class="flexcontainer">
          <div v-bind:key="objk" v-for="(obj, objk) in list">
            <div
              class="card"
              style="
                width: 400px;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0px 0px 4px 4px #145143;
              "
            >
              <div
                class="card-body"
                style="display: flex; flex-direction: column; width: 100%"
              >
                <img
                  style="width: 100%; height: 20rem; object-fit: scale-down"
                  :src="
                    'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/' +
                    obj.image
                  "
                />
                <div
                  class="text-center mt-2"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    color: black;
                    min-height: 4.5rem;
                    line-height: 1.5rem;
                  "
                >
                  {{ obj.title }}
                </div>

                <div class="mt-2 flex d-flex justify-content-between">
                  <div>
                    <strong
                      style="
                        color: black;
                        font-family: 'Quicksand', sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                      "
                      >Current Bid</strong
                    >
                    <p
                      style="
                        font-size: 14px;
                        text-align: center;
                        font-family: 'Quicksand', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                      "
                    >
                      ${{ obj.currentBidValue }}
                    </p>
                  </div>

                  <div>
                    <strong
                      style="
                        color: black;
                        font-family: 'Quicksand', sans-serif;
                        font-size: 18px;
                        font-weight: 700;
                      "
                      >Time Remaining</strong
                    >
                    <p
                      style="
                        text-align: center;
                        font-family: 'Quicksand', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                      "
                    >
                      {{
                        calculateDays(
                          obj.auctionStartDateTime,
                          obj.auctionEndDateTime
                        )
                      }}
                      day(s)
                    </p>
                  </div>
                </div>

                <div class="text-center mb-4">
                  <label
                    style="
                      text-align: center;
                      font-family: 'Quicksand', sans-serif;
                      font-size: 16px;
                      font-weight: 500;
                    "
                    ><strong> ({{ obj.totalBids }})</strong> Bid(s)</label
                  >
                </div>

                <hr style="margin: 0; margin-bottom: 17px" />

                <div class="text-center">
                  <button
                    type="button"
                    :disabled="obj.status !== 'ACTIVE'"
                    class="btn solid-btn my-auto"
                    @click="openModel(obj)"
                  >
                    Place Your Bid
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 22px;
                "
              >
                Silent Auction Guidelines:
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                1. The Silent Auction will end at 8:30pm today, October 12,
                2023<br />
                2. The highest bidder at the end of the auction will win the
                selected item<br />
                3. Winners will be announced at the Gala and also informed via
                email notification<br />
                4. All winners will be required to pay for their item by 9pm ET
                to avoid forfeiting their bid to the runner up. If you choose to
                have your item shipped, you'll be invoiced for shipping<br />
                5. Winners will be contacted via email with a link to pay for
                their item online or they may visit the Auction Check-Out Table
                at the Gala for assistance with their payment. Winners should
                pay by 9:30pm<br />
                <br />
                Have a question? Visit us at the Auction Check-Out Table and we
                will be happy to assist you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="section-heading mb-2">
        <div class="text-center">
          <a
            href="https://myle.com/100-black-men-of-dekalb-county"
            class="btn solid-btn-100blackmen mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Back To Main Page</a
          >

          <a
            href="https://myle.com/scholarship-gala-donations"
            class="btn solid-btn-100blackmen ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Donations</a
          >
        </div>
      </div> -->
    </div>

    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center">Download today and try it for yourself</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// tslint-disable no-mixed-spaces-and-tabs
export default {
  name: "newyear",
  data() {
    return {
      selected: null,
      email: "",
      bidValue: "",
      fullname: "",
      list: [],
      isSubmitted: false,
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
  },
  methods: {
    calculateDays(d1, d2) {
      const dat = new Date();

      let date1 = new Date(d1);
      if (dat.getTime() > date1.getTime()) {
        date1 = dat;
      } else {
        date1 = new Date(d1);
      }
      const date2 = new Date(d2);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return dat.getTime() > date2.getTime()
        ? 0
        : Math.ceil(Difference_In_Days);
    },
    openModel(obj) {
      this.email = "";
      this.bidValue = Number(obj.currentBidValue) + 10;
      this.fullname = "";
      this.selected = obj;
      this.$refs.zmodal.open();
    },
    bidNow() {
      const req =
        "https://myle-19701.uc.r.appspot.com/api/auction_item_bidding";
      const self = this;
      self.isSubmitted = true;

      axios
        .post(req, {
          itemCode: self.selected.itemCode,
          name: self.fullname,
          email: self.email.toLowerCase(),
          bidValue: self.bidValue,
        })
        .then(() => {
          self.email = "";
          self.fullname = "";
          self.bidValue = "";
          self.selected = null;
          self.$refs.zmodal.close();
          self.getAutionItems();
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.Message
          ) {
            self.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
        })
        .finally(() => {
          self.isSubmitted = false;
        });
    },
    getAutionItems() {
      const req = "https://myle-19701.uc.r.appspot.com/api/get_auction_items";
      const self = this;
      self.isSubmitted = true;

      axios
        .post(req, {
          eventId: "2mx51ogC9D3XXX",
        })

        .then((res) => {
          if (res && res.data && res.data.Result) {
            self.list = res.data.Result;
          }
        })
        .finally(() => {
          self.isSubmitted = false;
        });
    },
  },
  mounted() {
    const self = this;
    self.getAutionItems();
  },
  computed: {
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
  },
  metaInfo: {
    title: "MYLE - 2023 NAAIA National Conference Gala",

    meta: [
      {
        name: "description",
        content: "MYLE - 2023 NAAIA National Conference Gala",
      },

      {
        name: "keywords",
        content: "MYLE - 2023 NAAIA National Conference Gala",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/2023-naaia-national-conference-gala",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 922px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 922.1px) {
  #d2 {
    overflow-x: hidden;
  }
}

@media (max-width: 450px) {
  .card {
    width: auto !important;
  }
}

hr.dashed {
  border-top: 3px solid;
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  margin: auto;
  justify-content: center;
  margin-bottom: 2rem;
}
</style>
