<template>
  <section
    id="screenshots"
    class="screenshots-section ptb-100"
    v-bind:class="isGray ? 'MYLE-green' : 'primary-bg'"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-heading text-center">
            <h2 class="white" v-bind:class="isGray ? '' : 'text-white'">
              Check out some of the best MYLE app features
            </h2>
          </div>
        </div>
      </div>

      <div class="screen-slider-content mt-5">
        <div class="screenshot-frame"></div>
        <carousel
          :autoplay="true"
          :dots="true"
          :loop="true"
          :responsive="{
            0: { items: 1 },
            768: { items: 3 },
            991: { items: 4 },
            1200: { items: 4 },
            1920: { items: 4 },
          }"
          :margin="0"
          :center="true"
          :nav="false"
          class="screen-carousel owl-carousel owl-theme dot-indicator owl-loaded owl-drag"
        >
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-1.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-2.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-3.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-4.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-5.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ss-6.png"
            class="img-fluid"
            alt="screenshots"
          />
        </carousel>
      </div>
      <div class="row align-items-center d-flex ptb-50">
        <div class="col-md-12 justify-content-center">
          <h2 class="white text-center">
            DOWNLOAD OR REGISTER NOW
            <br />
            FIND THOUSANDS OF EVENTS!!!
          </h2>
          <div
            class="download-btn mt-4 animate__animated animate__fadeIn animate__delay-2s d-flex justify-content-center"
          >
            <!-- <a
              href="https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
              class="btn google-play-btn mr-3"
              ><span class="ti-apple"></span> App Store</a
            >
            &nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
              class="btn google-play-btn mr-3"
              ><span class="ti-android"></span> Google Play</a
            >
            &nbsp;
            <a
              href="https://web.myle.com"
              class="btn google-play-btn"
              target="_blank"
              rel="noopener noreferrer"
              ><span class="ti-world"></span> MYLE Web</a
            > -->

            <a
              href="https://web.myle.com/post-event"
              class="btn solid-btn-white mr-3"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Create An Event</a
            >

            <a
              href="https://web.myle.com/login"
              class="btn solid-btn-white mr-3"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Register/Login</a
            >

            <a
              href="https://myle.com/app/MYLEMARKERS"
              class="btn solid-btn-white"
              target="_blank"
              rel="noopener noreferrer"
              ><span class=""></span> Get The App
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Screenshots",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  components: { carousel },
};
</script>
