<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <myleModal ref="modal3" :hideCloseButton="true">
      <div>
        <!-- Container starts -->
        <h1
          class="my-2 mx-auto"
          style="
            text-align: center;
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
          "
        >
          Passcode Protected Content
        </h1>
        <div class="px-2 mx-auto">
          <div
            class="grid grid-cols-1 w-full xl:gap-x-10 gap-y-10 gap-x-4 mx-auto"
          >
            <div
              class="md:col-span-1 col-span-2 shadow-box rounded-xl bg-white rounded"
              style="border: 1px solid #ac1e2e; box-shadow: 1px 1px #ac1e2e"
            >
              <!-- Sub container heading starts -->
              <div
                class="w-full py-2 md:px-5 px-3"
                style="background-color: #ac1e2e"
              >
                <p
                  class="text-lg text-white"
                  style="font-size: 18px; margin: 0"
                >
                  Passcode
                </p>
              </div>
              <!-- Sub container heading ends -->

              <!-- Name type section starts -->
              <div class="flex px-5 gap-4 pt-5 w-full flex-wrap">
                <!-- name section -->
                <div class="w-full text-center" style="font-size: 15px">
                  Please enter passcode to continue viewing this private event
                </div>
                <!-- name section -->
              </div>

              <!-- Name type section starts -->
              <div class="flex px-5 gap-4 py-5 pt-0 w-full flex-wrap">
                <!-- name section -->
                <div class="w-full">
                  <div class="w-full mt-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="passcode"
                    />
                  </div>
                </div>
                <!-- name section -->
              </div>
            </div>
          </div>

          <!-- Button section starts -->
          <div class="mx-auto mt-5 mb-5">
            <div
              class="mx-auto flex space-x-3"
              style="
                justify-content: center;
                width: auto !important;
                flex-wrap: wrap !important;
                text-align: center !important;
                flex-direction: row !important;
              "
            >
              <button
                :disabled="passcode.trim().length <= 0"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
                @click="matchPasscode"
                class="btn solid-btn-crimson btn-lg my-auto"
              >
                Verify Passcode
              </button>
            </div>
          </div>
          <!-- Button section starts -->
        </div>
      </div>
    </myleModal>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <nu-modal ref="modal2" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult2 && TicketsResult2[selected]
                    ? TicketsResult2[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult2[selected] &&
                  TicketsResult2[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult2[selected]
                    ? TicketsResult2[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult2[selected] &&
                  TicketsResult2[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult2[selected]
                      ? TicketsResult2[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            >
              <button
                type="button"
                @click="redeem2(TicketsResult2)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow2()"
                :disabled="
                  (TicketsResult2[selected] && TicketsResult2[selected].discount
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <nu-modal ref="modal1" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult1 && TicketsResult1[selected]
                    ? TicketsResult1[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult1[selected] &&
                  TicketsResult1[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult1[selected]
                    ? TicketsResult1[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult1[selected] &&
                  TicketsResult1[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult1[selected]
                      ? TicketsResult1[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            >
              <button
                type="button"
                @click="redeem(TicketsResult1)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow1()"
                :disabled="
                  (TicketsResult1[selected] && TicketsResult1[selected].discount
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="confirmmodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3 class="w-auto d-inline-block">
                {{
                  $route.query.checkout == "success"
                    ? "Successful Purchased"
                    : "Oops!"
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              {{
                $route.query.checkout == "success"
                  ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                  : "Something went wrong with the payment. Please try again."
              }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <br />
              <button
                type="button"
                @click="$refs.confirmmodal.close()"
                class="btn solid-btn my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  width: 150px;
                  margin-top: 11px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult && TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price || 0
                      : 0) * tickcount
                  ).toFixed(2) -
                  (TicketsResult[selected].ticketCode ==
                  "tk_KpVJNifcygPsrgK && false"
                    ? 50
                    : 0)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2) -
                  (TicketsResult[selected].ticketCode ==
                  "tk_KpVJNifcygPsrgK && false"
                    ? 50
                    : 0)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            >
              <button
                type="button"
                @click="redeem(TicketsResult)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-crimson btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  TicketsResult[selected].discount &&
                  TicketsResult[selected].ticketCode
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/FamilyOutingBanner_CustomPage.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12" style="background-color: #ac1e2e">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                Kappa Conclave Registered Members
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 20px;
                "
              >
                This page is for

                <span class="bolded"> REGISTERED MEMBERS ONLY!</span>
                <br />
                For additional events, tap the Conclave Events button.
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-2 mt-5">
        <div class="text-center">
          <a
            href="https://myle.com/kappa-conclave-2023"
            class="btn solid-btn-crimson mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Conclave Events
          </a>

          <a
            href="https://kappaconclave2023.com/86th-events"
            class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Conclave Website</a
          >

          <a
            href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/TAC%2086th%20GCM%20FAQ.pdf"
            class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQs</a
          >
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 32px;
                      "
                    >
                      Family Outing Ticket Options
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in TicketsResult"
                    >
                      <div class="card-header MYLE-crimson white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-crimson"
                            id="reserve"
                            @click="openmodel(objk)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <!-- The Black Food Truck Festival is a family-oriented event
                      featuring the finest food trucks in the region in Charleston,
                      South Carolina. Our mission is to make an economic and social
                      impact on the community by showcasing and investing in area
                      Black-owned businesses.
                      <br />
                      <br />
                      The Black Food Truck Festival is the ultimate family reunion
                      featuring kid-friendly activities in a safe, secure environment.
                      Expect to experience a range of different cuisines while
                      enjoying performances by local bands and musicians.
                      <br />
                      <br />
                      Our goal is to create an atmosphere of unity, love, and positive
                      vibes. Come celebrate with us and have a great time while
                      creating a memorable experience!
                      <br />
                      <br /> -->

                The 86th GCM Family Outing – one of the signature events hosted
                by Tampa (FL) Alumni Chapter – will provide the ultimate family
                experience.
                <br />
                <br />
                It will be held at Riverfront Park on Saturday, July 22, 2023,
                from 12:00 – 5:00 pm. Riverfront Park is on the Hillsborough
                River and walking distance from the host hotels along Tampa’s
                beautiful Riverwalk.
                <br />
                <br />
                This Family Outing Experience will have something for the entire
                family. There will be live entertainment provided by marquee
                talent. Beyond the entertainment and variety of food options,
                Riverfront Park provides a Splash Pad and Play Cove for the
                youngest members of our families.
                <br />
                <br />
                The Tampa River Center will be available to our VIP and Senior
                guests.
                <!-- <br /><br />
                  *Ticket Price includes processing fees of 6.9%+.60 -->
              </p>
            </div>

            <hr />
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="col-md-12">
          <!-- Tickets Section starts -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="py-5">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                    "
                  >
                    All Access Party Packages Ticket Options
                  </h3>
                  <!-- <br />
                  <h4
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 18px;
                    "
                  >
                    ✱DISCOUNTED OPTION✱
                  </h4> -->
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-center">
                  <div
                    class="card mx-4 mt-4"
                    style="
                      max-width: 450px !important;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-bind:key="objk"
                    v-for="(obj, objk) in TicketsResult2"
                  >
                    <div class="card-header MYLE-crimson white text-center">
                      {{ obj.ticketName }}
                    </div>
                    <div
                      class="card-body"
                      style="display: flex; flex-direction: column"
                    >
                      <p
                        v-bind:key="w"
                        v-for="(msgs, w) in filteredMessages2(obj)"
                      >
                        - {{ msgs }}
                      </p>
                      <div class="text-center" style="margin-top: auto">
                        <p v-if="obj.ticketType != 'FREE'">
                          <b class="font-weight-bold">Total Price:</b>
                          ${{ obj.price.toFixed(2) }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p>
                        <button
                          :disabled="
                            !obj.availableTickets ||
                            obj.status == 'EXPIRED' ||
                            obj.status == 'COMING_SOON'
                          "
                          v-bind:class="{
                            'bg-disable':
                              obj.status == 'EXPIRED' ||
                              !obj.availableTickets ||
                              obj.status == 'COMING_SOON',
                          }"
                          class="btn solid-btn-crimson"
                          id="reserve"
                          @click="openmodel2(objk)"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-size: 18px;
                          "
                        >
                          {{
                            obj.status == "EXPIRED" || !obj.availableTickets
                              ? "Sold Out"
                              : obj.status == "COMING_SOON"
                              ? "Coming Soon"
                              : "Purchase"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="download-content mt-5">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Two Night & Three Night Packages <br />
              <br />

              Two Night Packages include Thursday (Krimson Takeover Party) or
              Saturday (The Kulmination) and General Admission at Friday’s Kreme
              Kulture Jam.
              <br />
              <br />

              Three Night Package includes Thursday, Friday, and Saturday
              (Krimson Takeover Party, VIP Access at Kreme Kulture Jam and The
              Kulmination)
            </p>
          </div>
          <div class="section-heading mb-2">
            <div class="text-center">
              <a
                href="https://myle.com/kappa-conclave-2023"
                class="btn solid-btn-crimson mb-1 ml-3 mr-3 text-center"
                target="_self"
                >Conclave Events
              </a>

              <a
                href="https://kappaconclave2023.com/86th-events"
                class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
                target="_blank"
                rel="noopener noreferrer"
                >Conclave Website</a
              >

              <a
                href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/TAC%2086th%20GCM%20FAQ.pdf"
                class="btn solid-btn-crimson ml-3 mr-3 mb-1 text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs</a
              >
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="col-md-12">
          <!-- Tickets Section starts -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="py-5">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                    "
                  >
                    The Kulmination – The Grand Polemarch’s Closed Ball Ticket
                    Option
                  </h3>
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-center">
                  <div
                    class="card mx-4 mt-4"
                    style="
                      max-width: 450px !important;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-bind:key="objk"
                    v-for="(obj, objk) in TicketsResult1"
                  >
                    <div class="card-header MYLE-crimson white text-center">
                      {{ obj.ticketName }}
                    </div>
                    <div
                      class="card-body"
                      style="display: flex; flex-direction: column"
                    >
                      <p
                        v-bind:key="w"
                        v-for="(msgs, w) in filteredMessages1(obj)"
                      >
                        - {{ msgs }}
                      </p>
                      <div class="text-center" style="margin-top: auto">
                        <p v-if="obj.ticketType != 'FREE'">
                          <b class="font-weight-bold">Total Price:</b>
                          ${{ obj.price.toFixed(2) }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p>
                        <button
                          :disabled="
                            !obj.availableTickets ||
                            obj.status == 'EXPIRED' ||
                            obj.status == 'COMING_SOON'
                          "
                          v-bind:class="{
                            'bg-disable':
                              obj.status == 'EXPIRED' ||
                              !obj.availableTickets ||
                              obj.status == 'COMING_SOON',
                          }"
                          class="btn solid-btn-crimson"
                          id="reserve"
                          @click="openmodel1(objk)"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-size: 18px;
                          "
                        >
                          {{
                            obj.status == "EXPIRED" || !obj.availableTickets
                              ? "Sold Out"
                              : obj.status == "COMING_SOON"
                              ? "Coming Soon"
                              : "Purchase"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="download-content mt-5">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              <!-- The Black Food Truck Festival is a family-oriented event
                featuring the finest food trucks in the region in Charleston,
                South Carolina. Our mission is to make an economic and social
                impact on the community by showcasing and investing in area
                Black-owned businesses.
                <br />
                <br />
                The Black Food Truck Festival is the ultimate family reunion
                featuring kid-friendly activities in a safe, secure environment.
                Expect to experience a range of different cuisines while
                enjoying performances by local bands and musicians.
                <br />
                <br />
                Our goal is to create an atmosphere of unity, love, and positive
                vibes. Come celebrate with us and have a great time while
                creating a memorable experience!
                <br />
                <br /> -->
              The Tampa Alumni Chapter of Kappa Alpha Psi presents
              <span class="bolded">
                <span class="bolded"> The Kulmination.</span></span
              >
              <br /><br />
              As the Men of Kappa Alpha Psi Fraternity, Inc. bring the 86th GCM
              to a close, the Grand Polemarch’s Closed Ball morphs into The
              Kulmination, a klassy affair hosted by the Tampa (FL) Alumni
              Chapter.
              <br /><br />
              On the beautiful Hillsborough River, the JW Marriott Waterside
              Hotel promises to be the ultimate conclusion to a festive week of
              activities sponsored by the Brothers of Kappa Alpha Psi
              Fraternity, Inc. This spacious ballroom with spectacular artwork
              and décor allows patrons to be entertained by a national DJ and a
              live spectacular band. Patrons are encouraged to wear their gowns
              and cufflinks, if they dare.
              <br /><br />
              Tampa Alumni Chapter is celebrating our 95-year anniversary. Come
              dance the night away and make memories with the Nupes that will
              last a lifetime.
              <br /><br />
              And, as always... You’re Welcome!!!
              <br /><br />
              Price increases as of June 1, 2023. <br />Attire: Black & White –
              Dress to Impress
            </p>
          </div>

          <br />

          <p
            class="text-center lead-heading mb-3"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 18px;
            "
          >
            All social event ticket related matters will be handled by the Tampa
            Alumni Chapter. For inquiries, email
            <a href="mailto:kappaconclave@myle.com"> kappaconclave@myle.com.</a>
          </p>
        </div>
      </div>
    </div>

    <div class="row w-100 m-0 promo-section MYLE-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              DOWNLOAD MYLE TODAY TO GET STARTED
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
            <h3 class="text-center text-white mt-3">
              We look forward to seeing you soon at the “Klave on the Bay.”
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mt-0 mb-2"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
            background-color: #ac1e2e;
            color: white;
          "
        >
          Get ready for The 86th Kappa Konclave with MYLE
        </p>

        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          • Find all of the “Konclave on the Bay” events in MYLE
          <br />
          • Buy your event tickets with MYLE
          <br />
          • Keep all of your digital tickets in MYLE for easy and fast check-in
          <br />
          • Help the local chapter earn referral points – use referral code
          “TampaAlumniChapter” during account set-up
          <br />
          • Receive important updates before and during the event
          <br />
          • Get around Tampa seamlessly using the MYLE app for transportation
          options including Waze/Apple Maps/Google Maps/Uber and Lyft –
          integrated into MYLE mobile.
          <br />
          • Find other fun things to do in Tampa or on the way!
          <br />
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import myleModal from "./modal";
import confirmationPopup from "../../components/confirmationPopup";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      additionalExtra: {
        tk_BnTLy2lCn5MqzIH: {
          type: "Event",
          value: ["Krimson Takeover Party", "Kreme Kulture Jam"],
        },
        tk_eVUrQzfV4YgGdzx: {
          type: "Event",
          value: [
            "Kreme Kulture Jam",
            "The Kulmination – The Grand Polemarch’s Closed Ball",
          ],
        },
        tk_8ZXIhzwwI26G2yQ: {
          type: "Event",
          value: [
            "Krimson Takeover Party",
            "Kreme Kulture Jam",
            "The Kulmination – The Grand Polemarch’s Closed Ball",
          ],
        },
      },
      extras: {
        tk_BnTLy2lCn5MqzIH: {
          Event: ["Krimson Takeover Party", "Kreme Kulture Jam"],
        },
        tk_eVUrQzfV4YgGdzx: {
          Event: [
            "Kreme Kulture Jam",
            "The Kulmination – The Grand Polemarch’s Closed Ball",
          ],
        },
        tk_8ZXIhzwwI26G2yQ: {
          Event: [
            "Krimson Takeover Party",
            "Kreme Kulture Jam",
            "The Kulmination – The Grand Polemarch’s Closed Ball",
          ],
        },
      },
      passcode: "",
      promosAllow: [
        "tk_2e3AyNE7EKP79X6",
        "tk_3bntFuZt8MM35UU",
        "tk_arEt00MtX38bKhl",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList1: {
        // tk_afJU5Vuo4psCxL3: {
        //   messages: [
        //     "Two (2) Complimentary Tickets",
        //     "Maximum of two (2) complimentary tickets",
        //   ],
        // },

        tk_TzNlk6EZxsctziu: {
          messages: [
            "Two (2) Complimentary Tickets",
            "Maximum of two (2) complimentary tickets",
          ],
        },
      },

      priceIds1: {
        // tk_afJU5Vuo4psCxL3: "price_1Mv773D0vTZ4QB9cDc6t61e4",
        tk_TzNlk6EZxsctziu: "price_1Mor2kD0vTZ4QB9c2KOG2PPb",
      },
      tkList2: {
        tk_BnTLy2lCn5MqzIH: {
          messages: [
            "Ticket Price: $125.00",
            "Processing fee of $10.03",
            "Sales Tax of $8.78",
          ],
        },

        tk_eVUrQzfV4YgGdzx: {
          messages: [
            "Ticket Price: $75.00",
            "Processing fee of $6.67",
            "Sales Tax of $5.31",
            "✱DISCOUNTED OPTION✱",
            "Maximum of two (2) discounted tickets",
          ],
        },

        tk_8ZXIhzwwI26G2yQ: {
          messages: [
            "Ticket Price: $150.00",
            "Processing fee of $11.70",
            "Sales Tax of $10.51",
            "✱DISCOUNTED OPTION✱",
            "Maximum of two (2) discounted tickets",
          ],
        },
      },

      priceIds2: {
        tk_BnTLy2lCn5MqzIH: "price_1MoASqD0vTZ4QB9cPeW16oeU",
        tk_eVUrQzfV4YgGdzx: "price_1MnlLkD0vTZ4QB9cohDvnvqW",
        tk_8ZXIhzwwI26G2yQ: "price_1MnlLlD0vTZ4QB9cLk7PBQeU",
      },
      tkList: {
        tk_KpVJNifcygPsrgK: {
          messages: [
            "Ticket Price: $50.00",
            "Processing fee of $4.99",
            "Sales Tax of $3.57",
            "(1) Complimentary Ticket",
            "Discount applied at purchase",
            "Each ticket includes food voucher",
          ],
        },

        tk_Y0WyRP5kvt33Ba7: {
          messages: [
            "Ticket Price: $25.00",
            "Processing fee of $3.32",
            "Sales Tax of $1.84",
            "Each ticket includes food voucher",
          ],
        },
      },

      priceIds: {
        tk_KpVJNifcygPsrgK: "price_1MoAoeD0vTZ4QB9cT1wKhnNk",
        tk_Y0WyRP5kvt33Ba7: "price_1MoslDD0vTZ4QB9cJiWtoCID",
      },

      countsListShow: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      TicketsResult1: [],
      TicketsResult2: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    myleModal,
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    matchPasscode() {
      if (this.passcode == "REG$86GCM") {
        this.$refs.modal3.close();
      } else {
        this.$toast.error("Provided passcode is not correct", {
          timeout: 4000,
        });
      }
    },
    closeModal() {
      this.$refs.confirmmodal12.close();
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    filteredMessages1(obj) {
      if (obj && this.tkList1 && this.tkList1[obj.ticketCode]) {
        return this.tkList1[obj.ticketCode].messages;
      }

      return [];
    },
    filteredMessages2(obj) {
      if (obj && this.tkList2 && this.tkList2[obj.ticketCode]) {
        return this.tkList2[obj.ticketCode].messages;
      }

      return [];
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    openmodel1: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal1.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult1[idx].availableTickets >
        this.TicketsResult1[idx].allowedPerRequest
          ? this.TicketsResult1[idx].allowedPerRequest
          : this.TicketsResult1[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    openmodel2: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal2.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult2[idx].availableTickets >
        this.TicketsResult2[idx].allowedPerRequest
          ? this.TicketsResult2[idx].allowedPerRequest
          : this.TicketsResult2[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow2: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult2[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds2[this.TicketsResult2[vm.selected].ticketCode],
        eventId: this.TicketsResult2[vm.selected].eventId,
        ticketId: this.TicketsResult2[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };
      if (
        this.TicketsResult2[vm.selected].ticketCode &&
        this.extras[this.TicketsResult2[vm.selected].ticketCode]
      ) {
        payloadToPush.extraInfo =
          this.extras[this.TicketsResult2[vm.selected].ticketCode];
        payloadToPush.additionalInfo =
          this.additionalExtra[this.TicketsResult2[vm.selected].ticketCode];
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult2[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "kappa_registered_requested_tickets"
              : "requested_kappa_registered_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult2[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets2();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult2[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets2();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    paynow1: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult1[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds1[this.TicketsResult1[vm.selected].ticketCode],
        eventId: this.TicketsResult1[vm.selected].eventId,
        ticketId: this.TicketsResult1[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult1[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "kappa_registered_requested_tickets"
              : "requested_kappa_registered_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult1[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets1();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult1[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets1();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      let reqtosend = null;
      reqtosend =
        (this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets";

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" + reqtosend,
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketCode == "tk_KpVJNifcygPsrgK"
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function (r) {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: r[this.selected].eventId,
          ticketId: r[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets2: function () {
      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult2 = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchAllAccessKrimsonPartyPackages_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult2 = ticketsResult.data.Result;
          }

          vm.TicketsResult2 = vm.TicketsResult2.filter(
            (rec) => vm.tkList2[rec.ticketCode]
          );

          const objtmp = {
            tk_BnTLy2lCn5MqzIH: null,
            tk_eVUrQzfV4YgGdzx: null,
            tk_8ZXIhzwwI26G2yQ: null,
          };

          vm.TicketsResult2.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult2 = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets1: function () {
      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult1 = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchKRIMSONKlimaxPartyinBlack_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult1 = ticketsResult.data.Result;
          }

          vm.TicketsResult1 = vm.TicketsResult1.filter(
            (rec) => vm.tkList1[rec.ticketCode]
          );

          const objtmp = {
            // tk_afJU5Vuo4psCxL3: null,
            tk_TzNlk6EZxsctziu: null,
          };

          vm.TicketsResult1.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult1 = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchFamilyOutingontheRiverwalk_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_KpVJNifcygPsrgK: null,
            tk_Y0WyRP5kvt33Ba7: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email);
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    this.fetchAllTickets1();
    this.fetchAllTickets2();
    setTimeout(() => {
      this.$refs.modal3.open();
    }, 1000);
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Kappa Conclave Registered 2023",

    meta: [
      {
        name: "description",
        content: "MYLE | Kappa Conclave Registered 2023",
      },

      {
        name: "keywords",
        content: "MYLE | Kappa Conclave Registered 2023",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/kappa-conclave-registered-2023",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.bg-green-50 {
  background-color: #e9f5f2 !important;
}

.text-green-800 {
  color: #28816d !important;
}
</style>
