var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('nav-bar')],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_vm._m(5),_c('br'),_c('br'),_vm._m(6),_c('br'),_c('br'),_vm._m(7),_c('br'),_vm._m(8),_c('hr',{staticClass:"test",staticStyle:{"background":"#ac1e2e"}}),_c('br'),_vm._m(9),_vm._m(10)]),_c('site-footer',{attrs:{"show-subscribe":true}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12",attrs:{"id":"banner"}},[_c('img',{staticClass:"align-middle",staticStyle:{"width":"100%","max-width":"100%","height":"auto","margin-top":"4.6rem"},attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Kappa_Collection_Page_Banner_CustomPage.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between test",staticStyle:{"margin":"auto","margin-top":"20px"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading text-center mb-1",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"800","font-size":"45px"}},[_vm._v(" Kappa Conclave 2023 ")])]),_c('hr'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"download-content"},[_c('p',{staticClass:"lead-heading mb-5 text-center",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"22px"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 20 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/tampa-kulture-bus-tour","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" Tampa Kulture Bus Tour ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 20th 2023, 9:45 AM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/tampa-kulture-bus-tour","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/tampa-kulture-bus-tour","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-50 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Kulture_Tour.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 20 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/all-access-party-packages","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" All Access Party Packages ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 20th 2023, 8:00 PM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/all-access-party-packages","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/all-access-party-packages","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyerr_All_Access_Party_Package.png","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 20 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/krimson-takeover-party","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" Krimson Takeover Party ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 20th 2023, 8:00 PM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/krimson-takeover-party","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/krimson-takeover-party","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-50 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Krimson_Takeover_IHQ.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 21 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/kreme-kulture-jam","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" Kreme Kulture Jam ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 21st 2023, 8:00 PM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/kreme-kulture-jam","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/kreme-kulture-jam","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-50 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Kulture_Jame_IHQ.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 22 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/family-outing","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" Family Outing ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 22nd 2023, 12:00 PM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/family-outing","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/family-outing","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-50 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_Family_Outing.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"}),_c('div',{staticClass:"col-md-2 col-lg-2 align-items-center"},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"22px"}},[_vm._v(" 22 "),_c('br'),_vm._v(" July ")])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/the-kulmination-the-grand-polemarch-closed-ball","target":"_self"}},[_c('p',{staticClass:"text-center lead-heading ml-3 mb-0",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"24px","color":"#ac1e2e"}},[_vm._v(" The Kulmination – The Grand Polemarch’s Closed Ball ")]),_c('p',{staticClass:"text-center lead-heading ml-3 mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"300","font-size":"18px","color":"#ac1e2e"}},[_vm._v(" July, 22nd 2023, 8:00 PM ")]),_c('div',{staticClass:"section-heading mb-2"},[_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn solid-btn-crimson mb-1 text-center",attrs:{"href":"https://myle.com/the-kulmination-the-grand-polemarch-closed-ball","target":"_self"}},[_vm._v("Get Tickets ")])])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 align-items-center"},[_c('a',{attrs:{"href":"https://myle.com/the-kulmination-the-grand-polemarch-closed-ball","target":"_self"}},[_c('div',{staticClass:"justify-content-center d-flex"},[_c('img',{staticClass:"w-50 img-fluid",attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyre_Closed_BallIHQ.jpg","alt":"Saturday Event"}})])])]),_c('div',{staticClass:"col-md-1 col-lg-1 align-items-center"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center lead-heading mb-3",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"700","font-size":"18px"}},[_vm._v(" All social event ticket related matters will be handled by the Tampa Alumni Chapter. For inquiries, email "),_c('a',{attrs:{"href":"mailto:kappaconclave@myle.com"}},[_vm._v(" kappaconclave@myle.com.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w-100 m-0 promo-section MYLE-green"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"section-heading mb-5"},[_c('h2',{staticClass:"text-center text-white"},[_vm._v(" DOWNLOAD MYLE TODAY TO GET STARTED ")]),_c('div',{staticClass:"download-btn mt-4 text-center animate__animated animate__fadeIn"},[_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-apple"}),_vm._v(" App Store")]),_c('a',{staticClass:"btn google-play-btn mr-3",attrs:{"href":"https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-android"}),_vm._v(" Google Play")]),_c('a',{staticClass:"btn google-play-btn",attrs:{"href":"https://web.myle.com","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"ti-world"}),_vm._v(" MYLE Web")])]),_c('h3',{staticClass:"text-center text-white mt-3"},[_vm._v(" We look forward to seeing you soon at the “Klave on the Bay.” ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between test",staticStyle:{"margin":"auto","margin-top":"20px"}},[_c('div',{staticClass:"col-lg-3 col-md-3"},[_c('img',{staticClass:"align-middle",staticStyle:{"width":"100%","max-width":"100%","height":"auto"},attrs:{"src":"https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/kappa_mocks.png"}})]),_c('div',{staticClass:"col-lg-9 col-md-9"},[_c('p',{staticClass:"mt-0 mb-2",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"700","font-size":"24px","background-color":"#ac1e2e","color":"white"}},[_vm._v(" Get ready for The 86th Kappa Konclave with MYLE ")]),_c('p',{staticClass:"mb-2 ml-4",staticStyle:{"font-family":"'Quicksand', sans-serif","font-weight":"500","font-size":"18px"}},[_vm._v(" • Find all of the “Konclave on the Bay” events in MYLE "),_c('br'),_vm._v(" • Buy your event tickets with MYLE "),_c('br'),_vm._v(" • Keep all of your digital tickets in MYLE for easy and fast check-in "),_c('br'),_vm._v(" • Help the local chapter earn referral points – use referral code “TampaAlumniChapter” during account set-up "),_c('br'),_vm._v(" • Receive important updates before and during the event "),_c('br'),_vm._v(" • Get around Tampa seamlessly using the MYLE app for transportation options including Waze/Apple Maps/Google Maps/Uber and Lyft – integrated into MYLE mobile. "),_c('br'),_vm._v(" • Find other fun things to do in Tampa or on the way! "),_c('br')])])])
}]

export { render, staticRenderFns }