<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected] ? TicketsResult[selected].price : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <!--
            <div
              class="row col-md-12 mt-4"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <div class="col-md-6">
                <label class="">Do you have a promo code?</label>

                <input
                  type="text"
                  v-model="redeempromo"
                  class="form-control"
                  placeholder="Promo code"
                />
              </div>

              <div class="col-md-4">
                <button
                  type="button"
                  @click="redeem()"
                  :disabled="!redeempromo.trim().length"
                  class="btn solid-btn btn-sm my-auto"
                  id="reserve"
                  style="
                    pointer-events: all;
                    cursor: pointer;
                    padding: 7px;
                    margin-top: 11px !important;
                  "
                >
                  Redeem
                </button>
              </div>
            </div> -->

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/becoming_bankable_master_class_header.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 700"
              >
                Becoming Bankable Master Class
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Money Mogul Movement Present Becoming Bankable Master Class
                <br />
                If you’re ready to dive into your business and give it the
                funding that it deserves then this "Becoming Bankable
                Masterclass", presented by The Money Mogul Movement was made
                specifically for you!
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                This 9 HOUR Masterclass will be broken down in 3 hour increments
                over three days. Each day will cover the basics but you will
                also learn the necessary steps and the order of steps you need
                to take to get your business up to 100k in funding. Live
                hands-on training alongside your access to your own personal
                portal for your business.
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                I will teach you how to strategize during a live zoom
                presentation and you will also receive the PDF with assignments.
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                What will you learn:
                <br /><br />
                -How to get funding TODAY<br />
                -Strategies that allow you to obtain 100k in funding<br />
                -How to structure your business to appeal to lenders<br />
                -SBA Loan qualifications<br />
                -Building your business credit score from scratch even with bad
                personal credit<br />
              </p>
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                As an added bonus I’m giving you a list of 100 direct lenders,
                Business Plan Template, 60 days in the MMM Facebook Group for
                support.
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                If you’re ready to FULLY invest in your business .....LETS GO!
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                **SPACES ARE LIMITED**
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                **PLEASE NOTE"
                <br />
                UPON PAID REGISTRATION COMPLETION, YOU WILL RECEIVE THE VIRTUAL
                ACCESS ZOOM LINK FOR THE CLASS
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center mb-2">
              <h4 class="w-auto d-inline-block mb-2">
                All sales are final. No refunds!!!
              </h4>
            </div>
            <div class="text-center">
              <h3 class="w-auto d-inline-block mb-2">Ticket Options</h3>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in TicketsResult"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <!-- <p>
                    <b class="font-weight-bold">Price:</b>
                    ${{ obj.price }}
                  </p>
                  <p
                    v-bind:key="linek"
                    v-for="(line, linek) in obj.description"
                  >
                    {{ line }}
                  </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p v-if="obj.ticketType != 'FREE'">
                      <b class="font-weight-bold">Total Price:</b>
                      ${{ obj.price.toFixed(2) }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(objk)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MANUAL PURCHASE -->
      <section id="download" class="white-bg ptb-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2 class="mt-4">Other Payment Options</h2>
                <p class="lead">
                  We are also accepting Cash App, Paypal, Zelle and Venmo for
                  ticket payments
                </p>

                <div class="row equal">
                  <div
                    class="col-md-3 col-lg-3"
                    style="
                      background-image: linear-gradient(
                        136deg,
                        #145143 0%,
                        #0c2f1f 100%
                      );
                    "
                  >
                    <a
                      href="https://cash.app/$MYLEMARKERS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class="">
                            <img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_cash_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                            />
                          </span>
                        </div>
                        <h3 class="text-white bold">Cash App</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://www.paypal.me/myletickets?"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_paypal_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Paypal</h3>
                        <p class="text-white lead">tickets@myle.com</p>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <div
                      @click="openZelleModel"
                      class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                    >
                      <div class="circle-icon mb-3">
                        <span class=""
                          ><img
                            class="align-middle"
                            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_zelle_icon.png"
                            style="width: 15%; max-width: 15%; height: auto"
                        /></span>
                      </div>
                      <h3 class="text-white">Zelle</h3>
                      <p class="text-white lead">tickets@myle.com</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-lg-3">
                    <a
                      href="https://venmo.com/code?user_id=3449043321620189533"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center navColor p-3 h-30"
                      >
                        <div class="circle-icon mb-3">
                          <span class=""
                            ><img
                              class="align-middle"
                              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/ic_venmo_icon.png"
                              style="width: 15%; max-width: 15%; height: auto"
                          /></span>
                        </div>
                        <h3 class="text-white">Venmo</h3>
                        <p class="text-white lead">MYLEMARKERS</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h6 class="mb-3 mt-5 font-bold">
            If using Cash App, Paypal, Zelle or Venmo, please follow these
            steps:
          </h6>
          <span class="font-weight-bold selfBullet">•</span> Send payment (Total
          Price) to Cash App, Paypal, Zelle or Venmo account shared above<br />
          <span class="font-weight-bold selfBullet">•</span> Take a screenshot
          of your payment confirmation<br />

          <span class="font-weight-bold selfBullet">•</span> Complete the ticket
          confirmation section below, click choose file and submit with the
          screenshot as your receipt confirmation.<br />
          <span class="font-weight-bold selfBullet">•</span> Our team will
          process your transaction and you will receive an email confirmation
          with your tickets.

          <h6 class="mb-3 mt-3 font-italic">
            Note: Tickets for Cash App, Paypal, Zelle and Venmo transactions are
            processed by our team between 9am and 7pm EST daily. You may submit
            at any time.
          </h6>
        </div>
      </section>
      <hr />

      <!-- Form SECTION -->
      <section>
        <form>
          <div class="container mt-5">
            <!-- heading -->
            <div class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <h3 class="w-auto d-inline-block">Ticket Confirmation</h3>
                </div>
              </div>
            </div>
            <!-- heading -->

            <!-- form -->
            <div class="row my-3">
              <div class="col-md-4">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="ticketForm.name"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="ticketForm.email"
                  required
                />
              </div>
              <div class="col-md-4">
                <label>Phone No.</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone"
                  v-model="ticketForm.phone"
                  required
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-6">
                <label>Ticket Options</label>
                <select
                  class="form-control"
                  v-model="ticketForm.ticketName"
                  @change="onChangeTicket($event)"
                >
                  <option
                    class="form-control"
                    v-bind:key="count + 'a'"
                    v-for="count in eventTicketResult.filter(
                      (e) =>
                        e.status != 'EXPIRED' &&
                        e.availableTickets &&
                        e.status != 'COMING_SOON' &&
                        e.ticketType != 'FREE'
                    )"
                    :value="count.ticketName"
                  >
                    {{ count.ticketName }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>No. of attendees</label>
                <select class="form-control" v-model="ticketForm.ticketCount">
                  <option
                    class="form-control"
                    v-bind:key="count + 'b'"
                    v-for="count in countsListShow"
                    :value="count"
                  >
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12">
                <label class="mr-3">Receipt</label>
                <input
                  name="image"
                  type="file"
                  id="fileName"
                  accept=".jpg,.jpeg,.png"
                  @change="validateFileType($event)"
                />
              </div>
            </div>

            <div class="row my-3">
              <div class="col-md-12 d-flex justify-content-left">
                <label class="mr-3">Pay Method</label>
                <div class="payment-method mx-2">
                  <input
                    type="radio"
                    value="Paypal"
                    v-model="ticketForm.method"
                    id="paypal"
                  />
                  <label class="ml-1" for="paypal">Paypal</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="zelle"
                    type="radio"
                    value="Zelle"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="zelle">Zelle</label>
                </div>
                <div class="payment-method mx-2">
                  <input
                    id="venmo"
                    type="radio"
                    value="Venmo"
                    v-model="ticketForm.method"
                  />
                  <label class="ml-1" for="venmo">Venmo</label>
                </div>
                <div class="payment-method">
                  <input
                    type="radio"
                    value="Cash App"
                    v-model="ticketForm.method"
                    id="cashup"
                  />
                  <label class="ml-1" for="cashup">Cash App</label>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 mb-4">
                <input type="checkbox" v-model="ticketForm.accept" />
                I understand that all sales are final. No refunds. I have read
                and agree with the MYLE
                <a
                  href="https://myle.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions.</a
                >
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  @click="payfornow()"
                  :disabled="
                    !ticketForm.name.trim().length ||
                    !ticketForm.ticketName.trim().length ||
                    !ticketForm.ticketCount === 0 ||
                    !invalidTicketEmail ||
                    !invalidTicketPhone ||
                    !ticketForm.accept ||
                    !ticketForm.file ||
                    isSubmitted
                  "
                  class="btn solid-btn m-auto"
                  style="pointer-events: all; cursor: pointer"
                >
                  Reserve
                </button>
              </div>
            </div>
            <h6 class="mb-5 mt-5 font-italic">
              Reminder: Tickets for Cash App, Paypal, Zelle and Venmo
              transactions are processed by our team between 9am and 7pm EST
              daily. Once your payment submission is confirmed, you will receive
              your tickets via email from the MYLE Ticket Manager account.
            </h6>
          </div>
        </form>
      </section>
      <!-- Form SECTION -->

      <!-- <hr />
      <section class="download-section ptb-50 background-img bg-white">
        <div class="container">
          <div class="row justify-content-between">
            <div
              class="col-md-4 d-flex align-items-center justify-content-center"
            >
              <div class="download-img">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/STADIUM_LOGO.png"
                  alt="download"
                  class="img-fluid mobImg"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="download-content">
                <h3 class="ml-5">Bottle Service</h3>

                <ul class="lead ml-5">
                  <li>Endeavor Rum</li>
                  <li>Endeavor Gin</li>
                  <li>Endeavor Vodka</li>
                  <li>Bulleit Bourbon</li>
                  <li>Crown Royal Bourbon</li>
                  <li>Espolon Blanco</li>
                  <li>Espolon Reposado</li>
                </ul>
                <ul class="lead ml-5 mt-3">
                  <li>Includes Mixers</li>
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="download-content">
                <h3 class="ml-5">Menu</h3>

                <ul class="lead ml-5">
                  <li>Caesar Salad</li>
                  <li>Side Salad</li>
                </ul>
                <ul class="lead ml-5 mt-2">
                  <li>OMG CAULIFLOWER</li>
                  <li>Mozzarella Sticks</li>
                  <li>Street Corn</li>
                  <li>Creekside Fries</li>
                  <li>NBA JAM</li>
                  <li>The Greatest</li>
                  <li>Chicken n Waffle</li>
                </ul>
                <ul class="lead ml-5 mt-2">
                  <li>Boneless Wings</li>
                  <li>Traditional Wings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr /> -->

      <!-- <div class="row justify-content-center">
        <div class="col-md-8">
          <div
            class="row w-100 m-0 mt-5 text-center promo-section MYLE-lite-green"
          >
            <div class="col-lg-12 col-md-12">
              <div class="mt-3">
                <div class="section-heading mb-3">
                  <h5 class="text-center text-white">STADIUM Gallery</h5>
                </div>
              </div>
            </div>
          </div>
          <section class="correctZindex mb-4">
            <vue-flux
              :options="vfOptions"
              :images="vfEventImgs"
              :transitions="vfTransitions"
              ref="slider"
              style=""
            >
              <template v-slot:preloader>
                <flux-preloader />
              </template>
              <template v-slot:controls>
                <flux-controls />
              </template>
            </vue-flux>
          </section>
        </div>
      </div> -->

      <div class="row w-75 m-auto py-4" v-if="false">
        <div
          v-bind:key="im"
          class="col-md-2 col-sm-4"
          v-for="im in [
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f1.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f2.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f3.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f4.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f5.jpg',
            'https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/f6.jpg',
          ]"
        >
          <img
            :src="im"
            v-bind:key="im"
            alt="download"
            class="img-fluid mobImg"
          />
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_AABQ5NiCWbMK9GA: {
          messages: [
            "Ticket Price: $995",
            "Processing fee of $69.26",
            "Virtual Online Class that will cover 3 days of Online Teaching & Learning of how to get funding for your business. UPON PAID REGISTRATION COMPLETION, YOU WILL RECEIVE THE VIRTUAL ACCESS ZOOM LINK FOR THE CLASS.",
          ],
        },
      },

      priceIds: {
        tk_AABQ5NiCWbMK9GA: "price_1LJ0k7D0vTZ4QB9cS29yIryf",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetch_becoming_bankable_master_class_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_AABQ5NiCWbMK9GA: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Becoming Bankable Master Class",

    meta: [
      {
        name: "description",
        content: "MYLE - Becoming Bankable Master Class",
      },

      {
        name: "keywords",
        content: "MYLE - Becoming Bankable Master Class",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/becoming-bankable-master-class",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
