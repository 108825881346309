<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
          <div class="col-md-12" id="banner">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
              style="
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-top: 4.2rem;
              "
            />
          </div>
        </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section mt-5" style="background: #0f3d70">
        <div class="col-lg-12 col-md-12 mt-5">
          <div class="mt-2">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Source Sans 3';
                  font-weight: 700;
                  font-size: 2.5em;
                  color: white;
                "
              >
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            1. Why am I getting this invitation?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            You are receiving this invitation to an exclusive event in honor of
            Veterans Day as a gesture of appreciation for being a loyal member
            of Navy Federal Credit Union.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            2. Will I receive an email confirmation?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Yes, an email confirmation will be automatically sent to your
            registered email address immediately after you complete the
            registration process. An additional reminder email will be sent on
            November 4th.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            3. What is The Mission Continues?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            The Mission Continues is a non-profit organization that empowers
            veterans who are adjusting to life at home to find purpose through
            community impact.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            4. What is included in the registration fee?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            For a single donation of $20, both you and your guest will be
            treated to all-inclusive food and beverages, exclusive Navy Federal
            swag, and a rideshare voucher worth up to $100 to be shared between
            you and your guest for safe transportation to and from the event.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            5. Will there be a dress code for the event?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            While there is no formal dress code for this event, we recommend
            attending the event in smart casual.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            6. Will there be dietary accommodations?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Yes, please indicate in your registration form whether you or your
            guest have any dietary restrictions or allergies.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            7. How will I receive my rideshare code?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Rideshare codes and redemption instructions will be outlined in the
            confirmation email sent to the Navy Federal member’s registered
            email address.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            8. Are the rideshare codes transferable?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Rideshare codes are non-transferable.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            9. Can I transfer my registration to someone else?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            Registrations are non-transferrable.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            10. I can no longer attend, can I give my spot to someone else?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you are no longer able to attend this event, please contact us no
            later than 48-hours prior to the event date at
            <a style="color: #0068ef" href="mailto:visalifestyle@160over90.com">
              visalifestyle@160over90.com</a
            >
            to cancel your reservation. Your card will be refunded the full $20
            amount to the card provided.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            11. My original guest cannot attend, can I bring someone else
            instead?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            If you purchased two tickets, you may bring anyone (21+) you’d like
            as your guest. You’ll note on your tickets that the second ticket
            has your name and then indicates it’s for your guest. If your guest
            arrives separately from you, they should mention your name when
            checking in. If you indicated that you’re bringing a guest and you
            are no longer bringing a guest, please reach out to

            <a style="color: #0068ef" href="mailto:visalifestyle@160over90.com">
              visalifestyle@160over90.com</a
            >.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            12. Who should I contact if I have additional questions about the
            event?
          </p>

          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            For any other questions, please contact
            <a style="color: #0068ef" href="mailto:visalifestyle@160over90.com">
              visalifestyle@160over90.com</a
            >.
          </p>
        </div>

        <div class="col-md-12">
          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Source Sans 3';
              font-weight: 800;
              font-size: 1.8em;
              color: black;
            "
          >
            57th Fighter Group Restaurant
          </p>
        </div>

        <div class="col-md-12">
          <p
            class="lead-heading"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            • Follow I-85 N, I-75 N and I-85 N to I- 85 Access Rd/I - 85
            Frontage Rd in North Druid Hills. Take exit 91 from I-85 N. Take
            Clairmont Rd to your destination in Chamblee
            <br />
            • There is ample parking space onsite, entrance located on Clairmont
            Rd
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/Navbar4";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons6/");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Navy Federal Credit Member in GA - FAQs",

    meta: [
      {
        name: "description",
        content: "Navy Federal Credit Member in GA - FAQs",
      },

      {
        name: "keywords",
        content: "Navy Federal Credit Member in GA - FAQs",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/navy-federal-credit-member-in-ga-faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}

.bolded {
  font-weight: bold;
}
</style>
