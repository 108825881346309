<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <myleModal ref="modal3" :hideCloseButton="true">
      <div>
        <h1
          class="my-2 mx-auto"
          style="
            text-align: center;
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 24px;
          "
        >
          Passcode Protected Content
        </h1>
        <div class="px-2 mx-auto">
          <div
            class="grid grid-cols-1 w-full xl:gap-x-10 gap-y-10 gap-x-4 mx-auto"
          >
            <div
              class="md:col-span-1 col-span-2 shadow-box rounded-xl bg-white rounded"
              style="border: 1px solid #000000; box-shadow: 1px 1px #000000"
            >
              <div
                class="w-full py-2 md:px-5 px-3"
                style="background-color: #000000"
              >
                <p
                  class="text-lg text-white"
                  style="font-size: 18px; margin: 0"
                >
                  Passcode
                </p>
              </div>

              <div class="flex px-5 gap-4 pt-5 w-full flex-wrap">
                <div class="w-full text-center" style="font-size: 15px">
                  Please enter passcode to continue viewing this private event
                </div>
              </div>

              <div class="flex px-5 gap-4 py-5 pt-0 w-full flex-wrap">
                <div class="w-full">
                  <div class="w-full mt-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="passcode"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-auto mt-5 mb-5">
            <div
              class="mx-auto flex space-x-3"
              style="
                justify-content: center;
                width: auto !important;
                flex-wrap: wrap !important;
                text-align: center !important;
                flex-direction: row !important;
              "
            >
              <button
                :disabled="passcode.trim().length <= 0"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
                @click="matchPasscode"
                class="btn solid-btn btn-lg my-auto"
              >
                Verify Passcode
              </button>
            </div>
          </div>
        </div>
      </div>
    </myleModal>

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <nu-modal ref="modal2" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>

              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 17px;
                  "
                >
                  *Purchase one ticket and receive a 2nd one upon checkout
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult2 && TicketsResult2[selected]
                    ? TicketsResult2[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult2[selected] &&
                  TicketsResult2[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult2[selected]
                    ? TicketsResult2[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult2[selected] &&
                  TicketsResult2[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult2[selected]
                      ? TicketsResult2[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult2[selected] && TicketsResult2[selected].discount
              "
            >
              <button
                type="button"
                @click="redeem2(TicketsResult2)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow2()"
                :disabled="
                  (TicketsResult2[selected] && TicketsResult2[selected].discount
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <nu-modal ref="modal1" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 17px;
                  "
                >
                  *Purchase one ticket and receive a 2nd one upon checkout
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult1 && TicketsResult1[selected]
                    ? TicketsResult1[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult1[selected] &&
                  TicketsResult1[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult1[selected]
                    ? TicketsResult1[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult1[selected] &&
                  TicketsResult1[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult1[selected]
                      ? TicketsResult1[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult1[selected] && TicketsResult1[selected].discount
              "
            >
              <button
                type="button"
                @click="redeem(TicketsResult1)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow1()"
                :disabled="
                  (TicketsResult1[selected] && TicketsResult1[selected].discount
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="confirmmodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h3 class="w-auto d-inline-block">
                {{
                  $route.query.checkout == "success"
                    ? "Successful Purchased"
                    : "Oops!"
                }}
              </h3>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              {{
                $route.query.checkout == "success"
                  ? "You have successfully purchased the tickets. Please check your MYLE account email inbox for confirmation email with attached ticket file. You can also check your purchased history in account tab here and in MYLE mobile app as well."
                  : "Something went wrong with the payment. Please try again."
              }}
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <br />
              <button
                type="button"
                @click="$refs.confirmmodal.close()"
                class="btn solid-btn my-auto"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  width: 150px;
                  margin-top: 11px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 17px;
                  "
                >
                  *Purchase one ticket and receive a 2nd one upon checkout
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult && TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price || 0
                      : 0) * tickcount
                  ).toFixed(2) -
                  (TicketsResult[selected].ticketCode == "tk_KpVJNifcygPsrgK"
                    ? 50
                    : 0)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2) -
                  (TicketsResult[selected].ticketCode == "tk_KpVJNifcygPsrgK"
                    ? 50
                    : 0)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].discount &&
                TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
              "
            >
              <button
                type="button"
                @click="redeem(TicketsResult)"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  TicketsResult[selected].discount &&
                  TicketsResult[selected].ticketCode !== 'tk_KpVJNifcygPsrgK'
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_2ndJuneTeenthEvent.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                UNATION Juneteenth 407 Weekend
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 32px;
                      "
                    >
                      Better Together Juneteenth Mixer
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in TicketsResult"
                    >
                      <div class="card-header MYLE-green white text-center">
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p>
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn"
                            id="reserve"
                            @click="openmodel(objk)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Purchase"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="download-content mt-5">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Kickoff Juneteenth weekend in unity with Orlando’s Elite!
                Together Central FL’s Black Professional organizations are
                joining forces to celebrate unity
                <br />
                <br />
                Join us to enjoy: A Live Music, DJ, Food, Drinks, Photo
                Opportunities, and most importantly each of our organizations!
              </p>
            </div>

            <hr />
          </div>
        </div>
      </div>

      <!-- Description section ends -->
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="col-md-12">
          <!-- Tickets Section starts -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="py-5">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                    "
                  >
                    BLK YRD-Juneteenth Block Party
                  </h3>
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-center">
                  <div
                    class="card mx-4 mt-4"
                    style="
                      max-width: 450px !important;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-bind:key="objk"
                    v-for="(obj, objk) in TicketsResult2"
                  >
                    <div class="card-header MYLE-green white text-center">
                      {{ obj.ticketName }}
                    </div>
                    <div
                      class="card-body"
                      style="display: flex; flex-direction: column"
                    >
                      <p
                        v-bind:key="w"
                        v-for="(msgs, w) in filteredMessages2(obj)"
                      >
                        - {{ msgs }}
                      </p>
                      <div class="text-center" style="margin-top: auto">
                        <p v-if="obj.ticketType != 'FREE'">
                          <b class="font-weight-bold">Total Price:</b>
                          ${{ obj.price.toFixed(2) }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p>
                        <button
                          :disabled="
                            !obj.availableTickets ||
                            obj.status == 'EXPIRED' ||
                            obj.status == 'COMING_SOON'
                          "
                          v-bind:class="{
                            'bg-disable':
                              obj.status == 'EXPIRED' ||
                              !obj.availableTickets ||
                              obj.status == 'COMING_SOON',
                          }"
                          class="btn solid-btn"
                          id="reserve"
                          @click="openmodel2(objk)"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-size: 18px;
                          "
                        >
                          {{
                            obj.status == "EXPIRED" || !obj.availableTickets
                              ? "Sold Out"
                              : obj.status == "COMING_SOON"
                              ? "Coming Soon"
                              : "Purchase"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="download-content mt-5">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              The perfect way to celebrate Juneteenth is by buying black,
              circulating the black dollar + having a great time doing it! Join
              us to enjoy
              <br />
              <br />

              Vendors DJs<br />
              Guest Hosts<br />
              Kids Zone<br />
              Food Trucks<br />
              +The Noir Bar featuring ALL Black-Owned Alcohol Brands

              <br />
              <br />

              Our vendors range from clothing, self-care, financial expertise,
              and so much more so you can spend your money right in our
              community.

              <br />

              We also have a diverse list of DJs spinning everything from 90s
              R&B, Afrobeats, Hip Hop, and everything in between so there is
              literally something for everyone.
              <br />
              All you have to do is grab your friends, grab your tickets, and
              come out!
            </p>
          </div>

          <hr />
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="col-md-12">
          <!-- Tickets Section starts -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="py-5">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 32px;
                    "
                  >
                    The Anti Brunch
                  </h3>
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-center">
                  <div
                    class="card mx-4 mt-4"
                    style="
                      max-width: 450px !important;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-bind:key="objk"
                    v-for="(obj, objk) in TicketsResult1"
                  >
                    <div class="card-header MYLE-green white text-center">
                      {{ obj.ticketName }}
                    </div>
                    <div
                      class="card-body"
                      style="display: flex; flex-direction: column"
                    >
                      <p
                        v-bind:key="w"
                        v-for="(msgs, w) in filteredMessages1(obj)"
                      >
                        - {{ msgs }}
                      </p>
                      <div class="text-center" style="margin-top: auto">
                        <p v-if="obj.ticketType != 'FREE'">
                          <b class="font-weight-bold">Total Price:</b>
                          ${{ obj.price.toFixed(2) }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p>
                        <button
                          :disabled="
                            !obj.availableTickets ||
                            obj.status == 'EXPIRED' ||
                            obj.status == 'COMING_SOON'
                          "
                          v-bind:class="{
                            'bg-disable':
                              obj.status == 'EXPIRED' ||
                              !obj.availableTickets ||
                              obj.status == 'COMING_SOON',
                          }"
                          class="btn solid-btn"
                          id="reserve"
                          @click="openmodel1(objk)"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-size: 18px;
                          "
                        >
                          {{
                            obj.status == "EXPIRED" || !obj.availableTickets
                              ? "Sold Out"
                              : obj.status == "COMING_SOON"
                              ? "Coming Soon"
                              : "Purchase"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="download-content mt-5">
            <p
              class=""
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 18px;
              "
            >
              Join Juneteenth 407 this Father’s Day for a Day Party just for our
              KINGS! Enjoy food, games, music & tastings of the Premium Whiskey:
              Uncle Nearest
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row w-100 m-0 promo-section MYLE-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              DOWNLOAD MYLE TODAY TO GET STARTED
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import myleModal from "./modal";
import confirmationPopup from "../../components/confirmationPopup";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      passcode: "",
      promosAllow: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList1: {
        tk_mK9hy6SHHbUamxl: {
          messages: [
            "Ticket Price: $15.00",
            "Processing fee of $2.65",
            "Sales Tax of $1.15",
          ],
        },

        tk_JIlbx3cDFXUm0dL: {
          messages: [
            "Ticket Price: $25.00",
            "Processing fee of $3.32",
            "Sales Tax of $1.84",
          ],
        },
      },

      priceIds1: {
        tk_mK9hy6SHHbUamxl: "price_1NDmUBD0vTZ4QB9c7VbeuiFR",
        tk_JIlbx3cDFXUm0dL: "price_1NDmUCD0vTZ4QB9cpboNc2Ht",
      },
      tkList2: {
        tk_ra7AeQ9Ke777Q63: {
          messages: [
            "Ticket Price: $30.00",
            "Processing fee of $3.65",
            "Sales Tax of $2.19",
          ],
        },
      },

      priceIds2: {
        tk_ra7AeQ9Ke777Q63: "price_1NDmYkD0vTZ4QB9cwW0fVK0X",
      },
      tkList: {
        tk_KpYqjWtNHIoSdq4: {
          messages: [
            "Ticket Price: $30.00",
            "Processing fee of $3.65",
            "Sales Tax of $2.19",
          ],
        },
      },

      priceIds: {
        tk_KpYqjWtNHIoSdq4: "price_1NDmb3D0vTZ4QB9cPFWbPvcF",
      },

      countsListShow: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      TicketsResult1: [],
      TicketsResult2: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    myleModal,
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    matchPasscode() {
      if (this.passcode == "UNationBOGO23") {
        this.$refs.modal3.close();
      } else {
        this.$toast.error("Provided passcode is not correct", {
          timeout: 4000,
        });
      }
    },
    closeModal() {
      this.$refs.confirmmodal12.close();
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    filteredMessages1(obj) {
      if (obj && this.tkList1 && this.tkList1[obj.ticketCode]) {
        return this.tkList1[obj.ticketCode].messages;
      }

      return [];
    },
    filteredMessages2(obj) {
      if (obj && this.tkList2 && this.tkList2[obj.ticketCode]) {
        return this.tkList2[obj.ticketCode].messages;
      }

      return [];
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal.open();
      this.selected = idx;
      if (
        this.TicketsResult[this.selected].ticketCode == "tk_KpVJNifcygPsrgK"
      ) {
        this.TicketsResult[this.selected].price = 50;
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    openmodel1: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal1.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult1[idx].availableTickets >
        this.TicketsResult1[idx].allowedPerRequest
          ? this.TicketsResult1[idx].allowedPerRequest
          : this.TicketsResult1[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    openmodel2: function (idx) {
      this.redeemData = null;
      this.redeemPriceId = "";
      this.fullname = "";
      this.email = "";
      this.tickcount = 1;

      this.$refs.modal2.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult2[idx].availableTickets >
        this.TicketsResult2[idx].allowedPerRequest
          ? this.TicketsResult2[idx].allowedPerRequest
          : this.TicketsResult2[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow2: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult2[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds2[this.TicketsResult2[vm.selected].ticketCode],
        eventId: this.TicketsResult2[vm.selected].eventId,
        ticketId: this.TicketsResult2[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult2[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "kappa_registered_requested_tickets"
              : "requested_kappa_registered_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult2[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets2();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult2[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets2();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    paynow1: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult1[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds1[this.TicketsResult1[vm.selected].ticketCode],
        eventId: this.TicketsResult1[vm.selected].eventId,
        ticketId: this.TicketsResult1[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult1[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "kappa_registered_requested_tickets"
              : "requested_kappa_registered_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult1[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets1();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult1[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets1();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {
          type: "Note",
          value: "All sales are final - no refunds",
        },
      };

      let reqtosend = null;
      if (this.TicketsResult[vm.selected].ticketCode == "tk_KpVJNifcygPsrgK") {
        this.redeemData = null;
        if (this.tickcount == 1) {
          payloadToPush.priceId = "price_1MoAoeD0vTZ4QB9cb6EyjTlB";
          reqtosend = "requested_free_tickets";
        }

        if (this.tickcount == 2) {
          payloadToPush.priceId = "price_1MoAoeD0vTZ4QB9cT1wKhnNk";
          reqtosend = "requested_tickets";
        }
      } else {
        reqtosend =
          (this.TicketsResult[vm.selected].ticketType != "FREE" &&
            !this.redeemData) ||
          (this.redeemData && this.redeemData.price !== 0)
            ? "requested_tickets"
            : "requested_free_tickets";
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" + reqtosend,
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketCode == "tk_KpVJNifcygPsrgK"
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";
        })
        .catch((err) => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          } else if (err && err.response && err.response.data) {
            vm.$toast.error(err.response.data.Message, { timeout: 4000 });
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";
        });
    },
    redeem: function (r) {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: r[this.selected].eventId,
          ticketId: r[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets2: function () {
      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult2 = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchBLKYRDJuneteenthBlockPartyEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult2 = ticketsResult.data.Result;
          }

          vm.TicketsResult2 = vm.TicketsResult2.filter(
            (rec) => vm.tkList2[rec.ticketCode]
          );

          const objtmp = {
            tk_ra7AeQ9Ke777Q63: null,
          };

          vm.TicketsResult2.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult2 = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets1: function () {
      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult1 = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchTheAntiBrunchEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult1 = ticketsResult.data.Result;
          }

          vm.TicketsResult1 = vm.TicketsResult1.filter(
            (rec) => vm.tkList1[rec.ticketCode]
          );

          const objtmp = {
            tk_mK9hy6SHHbUamxl: null,
            tk_JIlbx3cDFXUm0dL: null,
          };

          vm.TicketsResult1.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult1 = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchBetterTogetherJuneteenthMixerEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_KpYqjWtNHIoSdq4: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    this.fetchAllTickets1();
    this.fetchAllTickets2();
    setTimeout(() => {
      this.$refs.modal3.open();
    }, 1000);
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - UNATION Juneteenth 407 Weekend ",

    meta: [
      {
        name: "description",
        content: "MYLE - UNATION Juneteenth 407 Weekend ",
      },

      {
        name: "keywords",
        content: "MYLE - UNATION Juneteenth 407 Weekend ",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/unation-juneteenth-407-weekend",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.bg-green-50 {
  background-color: #e9f5f2 !important;
}

.text-green-800 {
  color: #28816d !important;
}
</style>
