<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle imgbottom"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/MyleMixer.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->

      <!-- Information -->
      <section id="download" class="white-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="mt-4 text-center">
                <h2
                  class="mt-4"
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 26px;
                  "
                >
                  MYLE is collaborating with the Classic for Columbus to host an
                  All-Star Community Networking and Panel Discussion event.
                  Leaders from HBCUs, Greek organizations, and minority
                  businesses will discuss the financial impact of the HBCU
                  Football Classic and College Basketball All-Star Game and
                  Celebrity Extravaganza.
                </h2>
              </div>
            </div>
          </div>

          <h6
            class="mb-3 mt-5 text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 28px;
            "
          >
            To attend please
            <a href="#register"> register</a>
            below. <br /><br />
          </h6>

          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <h6
                class="mb-3 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 18px;
                "
              >
                REGISTER TO WIN: You could be the winner of (4) floor seats and
                a $100 Dining Card to the Classic for Columbus College All-Star
                Basketball Game & Celebrity Extravaganza on April 23 at
                Nationwide Arena. To enter, register below. The drawing will be
                held on April 12th during the event.
              </h6>

              <h6
                class="mt-4 mb-2 font-bold"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                "
              >
                Contest Rules:
              </h6>

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● This contest is sponsored by Classic For Columbus. </h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Must be 18 or older to participate </h8
              ><br />

              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Must have the MYLE app downloaded to participate </h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
                >● Must be present to win </h8
              ><br />
              <h8
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                "
              >
                ● Winners will be notified on the MYLE App and announced during
                the event.</h8
              ><br />
            </div>
          </div>
        </div>
      </section>
      <hr />
      <!-- Information -->

      <!-- Register forms starts -->
      <div id="register">
        <form>
          <div class="container px-5">
            <div class="row">
              <div
                class="col-md-6 d-flex align-items-center justify-content-center"
              >
                <img
                  class="download-img"
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/myle_clean_logo.png"
                  style="width: 60%; max-width: 60%; height: auto"
                />
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h4
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 25px;
                      "
                    >
                      Registration Form
                    </h4>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >First Name*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your first name"
                      type="text"
                      required
                      v-model="payload.firstName"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Last Name*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your last name"
                      type="text"
                      required
                      v-model="payload.lastName"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Address*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your home address"
                      type="text"
                      required
                      v-model="payload.address"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >City*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your city name"
                      type="text"
                      required
                      v-model="payload.city"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Zip Code*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your area zip code"
                      type="text"
                      required
                      v-model="payload.zipcode"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Company/Organization*</label
                    >
                    <input
                      class="form-control"
                      placeholder="If not affiliate with a company, type N/A"
                      type="text"
                      required
                      v-model="payload.organization"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Phone (optional)</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your phone number"
                      type="text"
                      required
                      v-model="payload.phone"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Email*</label
                    >
                    <input
                      class="form-control"
                      placeholder="Enter your email address"
                      type="email"
                      required
                      v-model="payload.email"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <label
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 16px;
                      "
                      >Have you downloaded or signed up for MYLE?*</label
                    ><br />
                    <input
                      type="radio"
                      name="appdownload"
                      value="1"
                      v-model="payload.appDownload"
                    />
                    Yes
                    <input
                      type="radio"
                      name="appdownload"
                      value="0"
                      v-model="payload.appDownload"
                      class="ml-3"
                    />
                    No
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-md-12">
                    <input type="checkbox" v-model="payload.receiveEmail" />
                    Yes! I want to receive emails from MYLE to find things to
                    do, places to go and food to eat. I understand I can
                    unsubscribe at anytime
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-center my-3">
                    <button
                      type="button"
                      @click="register()"
                      :disabled="
                        !payload.firstName.trim().length ||
                        !payload.lastName.trim().length ||
                        !payload.address.trim().length ||
                        !payload.city.trim().length ||
                        !payload.organization.trim().length ||
                        !invalidemail ||
                        !invalidphone ||
                        !invalidzipcode ||
                        isSubmitted
                      "
                      class="btn solid-btn m-auto"
                      style="pointer-events: all; cursor: pointer"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Register forms ends -->
    </div>
    <br />
    <div class="row mt-5 w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h3 class="text-center text-white">Fun is waiting for you.</h3>
            <h2 class="text-center text-white">Download or Sign-up Today!</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  name: "TrainingComponent",
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },
  computed: {
    invalidphone() {
      return (
        !this.payload.phone.length ||
        (!isNaN(this.payload.phone || "") &&
          (this.payload.phone || "").trim().length >= 10)
      );
    },
    invalidzipcode() {
      return (
        !isNaN(this.payload.zipcode || "") &&
        (this.payload.zipcode || "").trim().length < 6 &&
        (this.payload.zipcode || "").trim().length >= 4
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.payload.email
      );
    },
  },
  methods: {
    register: function () {
      const payloadToPush = {
        firstName: this.payload.firstName,
        lastName: this.payload.lastName,
        address: this.payload.address,
        city: this.payload.city,
        zipcode: this.payload.zipcode,
        organization: this.payload.organization,
        phone: this.payload.phone,
        email: this.payload.email.toLowerCase(),
        app_downloaded: Number(this.payload.appDownload),
        subscribed: this.payload.receiveEmail ? 1 : 0,
      };
      const vm = this;
      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/myle_mixer_registration_requested",
          payloadToPush
        )
        .then((response) => {
          vm.isSubmitted = false;
          if (
            response.data &&
            response.data.Message &&
            response.data.Status == "Success"
          ) {
            vm.$toast.success(response.data.Message, { timeout: 4000 });
            vm.payload = {
              firstName: "",
              lastName: "",
              address: "",
              city: "",
              zipcode: "",
              organization: "",
              phone: "",
              email: "",
              appDownload: 1,
              receiveEmail: 1,
            };
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Something went wrong", { timeout: 4000 });
        });
    },
  },
  data() {
    return {
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      isSubmitted: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      payload: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        zipcode: "",
        organization: "",
        phone: "",
        email: "",
        appDownload: 1,
        receiveEmail: true,
      },
      roles: [
        "Convention/Tourism Representative",
        "DJ",
        "Event Goer",
        "Event Planner",
        "Faith Based Representative",
        "Hotel Representative",
        "Influencer",
        "Investor",
        "Lifestyle Critic/Blogger/Writer",
        "Non-Profit Representative",
        "Promoter",
        "Venue Owner",
        "Social Organization Representative",
        "Other",
      ],
      attendees: ["0", "1", "2", "3", "4"],
    };
  },

  metaInfo: {
    title: "MYLE MIXER",

    meta: [
      {
        name: "description",
        content: "MYLE MIXER EVENT",
      },

      {
        name: "keywords",
        content: "MYLE MIXER EVENT",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/myle-mixer",
      },
    ],
  },
};
</script>

<style>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
</style>
