<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b class="font-weight-bold">Total Price:</b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-SWA btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <hr class="w-100 p-2" />

            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Required Information</h3>
              </div>
            </div>

            <div
              class="row w-100"
              v-if="
                TicketsResult[selected] &&
                isSponser.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Company Name"
                  v-model="sponsers.companyName"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Contact Number"
                  v-model="sponsers.contactNumber"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Mailing Address"
                  v-model="sponsers.mailingAddress"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="City"
                  v-model="sponsers.city"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="State"
                  v-model="sponsers.state"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Zip"
                  v-model="sponsers.zip"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Website"
                  v-model="website"
                  required
                />
              </div>
            </div>

            <div
              class="row w-100 m-auto"
              v-if="
                TicketsResult[selected] &&
                vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Company Name"
                  v-model="vendors.companyName"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Contact Number"
                  v-model="vendors.contactNumber"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Mailing Address"
                  v-model="vendors.mailingAddress"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="City"
                  v-model="vendors.city"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="State"
                  v-model="vendors.state"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Zip"
                  v-model="vendors.zip"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Website"
                  v-model="website"
                  required
                />
              </div>
            </div>

            <div
              class="row w-100"
              v-if="
                TicketsResult[selected] &&
                ceu.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <label class="mt-3">Employer Name</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Employer Name"
                  v-model="ceus.employersName"
                  required
                />
              </div>

              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Website"
                  v-model="website"
                  required
                />
              </div>
            </div>

            <div
              class="row w-100"
              v-if="
                TicketsResult[selected] &&
                cle.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <label class="mt-3">Bar Roll Number</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Bar Roll Number"
                  v-model="cles.barROll"
                  required
                />
              </div>

              <div class="col-md-6">
                <label class="mt-3">Website</label>
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Website"
                  v-model="website"
                  required
                />
              </div>
            </div>

            <div
              class="row m-auto"
              v-if="
                TicketsResult[selected] &&
                regular.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Company Name"
                  v-model="regis.companyName"
                  required
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Phone"
                  v-model="regis.phone"
                  required
                />
              </div>

              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="City"
                  v-model="regis.city"
                  required
                />
              </div>

              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="State"
                  v-model="regis.state"
                  required
                />
              </div>

              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control mb-3"
                  placeholder="Website"
                  v-model="website"
                  required
                />
              </div>
            </div>

            <div
              class="col-md-6 mt-2"
              v-if="
                TicketsResult[selected] &&
                !isSponser.includes(TicketsResult[selected].ticketCode) &&
                !vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <input
                type="checkbox"
                name="Breakout Session 1 - "
                v-model="vendorsession1"
                @click="
                  datachange({ target: { name: 'Breakout Session 1 - ' } })
                "
              />
              Breakout Session 1
              <br />
              <select
                class="form-control"
                name="Breakout Session 1 - "
                :disabled="!vendorsession1"
                @change="changes"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sessionTypes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-2"
              v-if="
                TicketsResult[selected] &&
                !isSponser.includes(TicketsResult[selected].ticketCode) &&
                !vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <input
                type="checkbox"
                name="Breakout Session 2 - "
                value="1"
                @click="
                  datachange({ target: { name: 'Breakout Session 2 - ' } })
                "
                v-model="vendorsession2"
              />
              Breakout Session 2
              <br />
              <select
                class="form-control"
                name="Breakout Session 2 - "
                :disabled="!vendorsession2"
                @change="changes"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sessionTypes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-2"
              v-if="
                TicketsResult[selected] &&
                !isSponser.includes(TicketsResult[selected].ticketCode) &&
                !vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <input
                type="checkbox"
                name="Breakout Session 3 - "
                @click="
                  datachange({ target: { name: 'Breakout Session 3 - ' } })
                "
                v-model="vendorsession3"
              />
              Breakout Session 3
              <br />
              <select
                class="form-control"
                name="Breakout Session 3 - "
                :disabled="!vendorsession3"
                @change="changes"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sessionTypes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-2"
              v-if="
                TicketsResult[selected] &&
                !isSponser.includes(TicketsResult[selected].ticketCode) &&
                !vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <input
                type="checkbox"
                name="Breakout Session 4 - "
                @click="
                  datachange({ target: { name: 'Breakout Session 4 - ' } })
                "
                v-model="vendorsession4"
              />
              Breakout Session 4
              <br />
              <select
                class="form-control"
                name="Breakout Session 4 - "
                :disabled="!vendorsession4"
                @change="changes"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in sessionTypes"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-2"
              v-if="
                TicketsResult[selected] &&
                !isSponser.includes(TicketsResult[selected].ticketCode) &&
                !vendorReg.includes(TicketsResult[selected].ticketCode)
              "
            >
              <label class="mt-3">Freebie</label>
              <select class="form-control" v-model="freevalue">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in Freebie"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  !validity
                "
                class="btn solid-btn-SWA m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Louisiana_MBDA_Training_Conference.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #2268aa"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="font-family: 'Quicksand', sans-serif; font-weight: 500"
              >
                EmpowerED Entrepreneurs: Accelerating Success
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                July 14, 6pm - 8pm<br />
                July 15, 8am - 3pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://maps.app.goo.gl/FpSY1L9zqCcazHGu5"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Renaissance Baton Rouge Hotel
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
              >
                Join us for the EmpowerED Entrepreneurs: Accelerating Success on
                Sunday, July 14 and Monday, July 15, 2024.
              </p>
              <br />
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <span class="bolded"> Event Details:</span>
                <br />
                • Location: Renaissance Hotel, 7000 Bluebonnet Blvd., Baton
                Rouge, LA 70810
                <br />
                • July 14, 6-8pm, Small Business Awards Reception
                <br />
                • July 15, 8am-3pm, Training Conference
                <br />
                • Hotel Reservation information listed below
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <span class="bolded"> Registration Types:</span>
                <br />
                • Regular Registration: $99<br />
                • CLE Registration (Attorneys): $149<br />
                • CEU Registration (Procurement Staff): $149<br />
                • Vendor Registration: $100
              </p>

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                All registration types include Awards Reception, Conference and
                Meals
                <br /><br />
                **First 100 businesses to early register will receive one of the
                following complimentary IT services: Business Starter Email; Web
                Design; Graphic Design; Video Package from We Greaux People
                (choose one)**
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <h3
              class="lead-heading text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 35px;
              "
            >
              Sessions
            </h3>
          </div>

          <div class="row justify-content-center">
            <div
              class="col-10 col-md-10 justify-content-center d-flex align-items-center"
            >
              <div class="">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/EmpowerED_sessions.png"
                  alt="Sessions"
                  class="w-100 img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <!-- Tickets Section starts -->
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="py-5">
                  <div class="text-center">
                    <h3
                      class="w-auto d-inline-block mb-2"
                      style="
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 700;
                        font-size: 35px;
                      "
                    >
                      Registration Options
                    </h3>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-center">
                    <div
                      class="card mx-4 mt-4"
                      style="
                        max-width: 450px !important;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-bind:key="objk"
                      v-for="(obj, objk) in section1Filter(TicketsResult)"
                    >
                      <div
                        class="card-header MYLE-green white text-center"
                        style="background: #304cb2"
                      >
                        {{ obj.ticketName }}
                      </div>
                      <div
                        class="card-body"
                        style="display: flex; flex-direction: column"
                      >
                        <!-- <p
                          v-bind:key="w"
                          v-for="(msgs, w) in filteredMessages(obj)"
                        >
                          - {{ msgs }}
                        </p> -->
                        <div class="text-center" style="margin-top: auto">
                          <p v-if="obj.ticketType != 'FREE'">
                            <b class="font-weight-bold">Total Price:</b>
                            ${{ obj.price.toFixed(2) }}
                          </p>
                          <p
                            v-bind:key="linek"
                            v-for="(line, linek) in obj.description"
                          >
                            {{ line }}
                          </p>
                          <button
                            :disabled="
                              !obj.availableTickets ||
                              obj.status == 'EXPIRED' ||
                              obj.status == 'COMING_SOON'
                            "
                            v-bind:class="{
                              'bg-disable':
                                obj.status == 'EXPIRED' ||
                                !obj.availableTickets ||
                                obj.status == 'COMING_SOON',
                            }"
                            class="btn solid-btn-SWA"
                            id="reserve"
                            @click="openmodel(obj.ticketCode)"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-size: 18px;
                            "
                          >
                            {{
                              obj.status == "EXPIRED" || !obj.availableTickets
                                ? "Sold Out"
                                : obj.status == "COMING_SOON"
                                ? "Coming Soon"
                                : "Reserve Now"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Description section starts -->
    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="col-md-12">
          <!-- Tickets Section starts -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="py-5">
                <div class="text-center">
                  <h3
                    class="w-auto d-inline-block mb-2"
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 700;
                      font-size: 35px;
                    "
                  >
                    Sponsorship Options
                  </h3>
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-center">
                  <div
                    class="card mx-4 mt-4"
                    style="
                      max-width: 450px !important;
                      border-radius: 10px;
                      overflow: hidden;
                    "
                    v-bind:key="objk"
                    v-for="(obj, objk) in section2Filter(TicketsResult)"
                  >
                    <div
                      class="card-header MYLE-green white text-center"
                      style="background: #304cb2"
                    >
                      {{ obj.ticketName }}
                    </div>
                    <div
                      class="card-body"
                      style="display: flex; flex-direction: column"
                    >
                      <!-- <p
                        v-bind:key="w"
                        v-for="(msgs, w) in filteredMessages(obj)"
                      >
                        - {{ msgs }}
                      </p> -->
                      <div class="text-center" style="margin-top: auto">
                        <p v-if="obj.ticketType != 'FREE'">
                          <b class="font-weight-bold">Total Price:</b>
                          ${{ obj.price.toFixed(2) }}
                        </p>
                        <p
                          v-bind:key="linek"
                          v-for="(line, linek) in obj.description"
                        >
                          {{ line }}
                        </p>
                        <button
                          :disabled="
                            !obj.availableTickets ||
                            obj.status == 'EXPIRED' ||
                            obj.status == 'COMING_SOON'
                          "
                          v-bind:class="{
                            'bg-disable':
                              obj.status == 'EXPIRED' ||
                              !obj.availableTickets ||
                              obj.status == 'COMING_SOON',
                          }"
                          class="btn solid-btn-SWA"
                          id="reserve"
                          @click="openmodel(obj.ticketCode)"
                          style="
                            pointer-events: all;
                            cursor: pointer;
                            font-size: 18px;
                          "
                        >
                          {{
                            obj.status == "EXPIRED" || !obj.availableTickets
                              ? "Sold Out"
                              : obj.status == "COMING_SOON"
                              ? "Coming Soon"
                              : "Reserve Now"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-md-12">
        <div class="download-content">
          <p
            class="lead-heading text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 35px;
            "
          >
            HOTEL ACCOMODATIONS
          </p>

          <p
            class="lead-heading text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 20px;
            "
          >
            Renaissance Baton Rouge Hotel<br />
            7000 Bluebonnet Boulevard, Baton Rouge, LA 70810
          </p>
        </div>

        <div class="row justify-content-center">
          <div
            class="col-8 col-md-6 justify-content-center d-flex align-items-center"
          >
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_RenaissanceBatonRouge.jpeg"
                alt="Slow Jam Social Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-lg-12 col-md-12">
          <div class="mt-1">
            <div class="section-heading mb-2">
              <div class="download-btn mt-4 text-center">
                <a
                  href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1711383010629&key=GRP&app=resvlink"
                  class="btn solid-btn-SWA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BOOK HOTEL</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #2268aa"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      freevalue: "Business Starter Email",
      Freebie: [
        "Business Starter Email",
        "Web Design Package",
        "Graphic Design (1 month)",
        "Video Package (3 month)",
      ],
      website: "",
      valuesMap: {},
      tempValues: {},
      vendorsession1: false,
      vendorsession2: false,
      vendorsession3: false,
      vendorsession4: false,
      isSponser: [
        "tk_bAc5IxLJxp4Gp5h",
        "tk_rNNddLQDZ0i3E80",
        "tk_rd1Ftx3bYZBTrMV",
        "tk_p48zy7FDh2Wgih7",
        "tk_sf4F8azmCBXwl7Q",
        "tk_HvVPABak4EMCb3W",
        "tk_9yHscylsOe9sdBK",
      ],
      vendorReg: ["tk_K9r9uYGxit1ZJp4"],
      ceu: ["tk_oF4qGtwAasm77Rb"],
      cle: ["tk_qUMnzrm1pKhwdFu"],
      regular: ["tk_49sWMBjq2UeZbkR"],
      sponsers: {
        companyName: "",
        contactNumber: "",
        mailingAddress: "",
        city: "",
        state: "",
        zip: "",
      },
      sessionlist: [
        "Breakout Session 1",
        "Breakout Session 2",
        "Breakout Session 3",
        "Breakout Session 4",
      ],
      sessionTypes: [
        "Classroom A",
        "Classroom B",
        "Classroom C",
        // "Salon III",
        "Salon IV",
        "Salons I & II",
      ],
      vendors: {
        zip: "",
        state: "",
        city: "",
        mailingAddress: "",
        contactNumber: "",
        companyName: "",
      },
      ceus: {
        employersName: "",
      },
      cles: {
        barROll: "",
      },
      regis: {
        companyName: "",
        phone: "",
        city: "",
        state: "",
        session: "Breakout Session 1",
        sessionType: "Classroom A",
      },
      address: "",
      telephoneNumber: "",
      emailAddress: "",
      showsuccess: false,
      section1: [
        // "tk_pppbkHV28k6TBw0",
        "tk_49sWMBjq2UeZbkR",
        "tk_qUMnzrm1pKhwdFu",
        "tk_oF4qGtwAasm77Rb",
        "tk_K9r9uYGxit1ZJp4",
        "tk_9yHscylsOe9sdBK",
        "tk_HvVPABak4EMCb3W",
        "tk_sf4F8azmCBXwl7Q",
        "tk_p48zy7FDh2Wgih7",
        "tk_rd1Ftx3bYZBTrMV",
        "tk_rNNddLQDZ0i3E80",
        "tk_bAc5IxLJxp4Gp5h",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        // tk_pppbkHV28k6TBw0: {
        //   messages: ["Ticket Price: $99", "Processing fee of $8.27"],
        // },

        tk_49sWMBjq2UeZbkR: {
          messages: ["Ticket Price: $149", "Processing fee of $11.63"],
        },

        tk_qUMnzrm1pKhwdFu: {
          messages: ["Ticket Price: $149", "Processing fee of $11.63"],
        },

        tk_oF4qGtwAasm77Rb: {
          messages: ["Ticket Price: $149", "Processing fee of $11.63"],
        },

        tk_K9r9uYGxit1ZJp4: {
          messages: ["Ticket Price: $100", "Processing fee of $8.35"],
        },

        tk_9yHscylsOe9sdBK: {
          messages: ["Ticket Price: $500", "Processing fee of $35.17"],
        },

        tk_HvVPABak4EMCb3W: {
          messages: ["Ticket Price: $1000", "Processing fee of $68.71"],
        },

        tk_sf4F8azmCBXwl7Q: {
          messages: ["Ticket Price: $5000", "Processing fee of $337"],
        },

        tk_p48zy7FDh2Wgih7: {
          messages: ["Ticket Price: $10000", "Processing fee of $672.37"],
        },

        tk_rd1Ftx3bYZBTrMV: {
          messages: ["Ticket Price: $15000", "Processing fee of $1007.73"],
        },

        tk_rNNddLQDZ0i3E80: {
          messages: ["Ticket Price: $20000", "Processing fee of $1343.1"],
        },

        tk_bAc5IxLJxp4Gp5h: {
          messages: ["Ticket Price: $25000", "Processing fee of $1678.46"],
        },
      },

      priceIds: {
        // tk_pppbkHV28k6TBw0: "price_1P5ENUD0vTZ4QB9cLFFLHePT",
        tk_49sWMBjq2UeZbkR: "price_1PIxxdD0vTZ4QB9cQsXfstXB",
        tk_qUMnzrm1pKhwdFu: "price_1P5ENED0vTZ4QB9ccdFaAUV8",
        tk_oF4qGtwAasm77Rb: "price_1P5ENKD0vTZ4QB9cAZ6SNcD4",
        tk_K9r9uYGxit1ZJp4: "price_1P5EVMD0vTZ4QB9c6TN0VFZw",
        tk_9yHscylsOe9sdBK: "price_1P57PID0vTZ4QB9cuuxzksmH",
        tk_HvVPABak4EMCb3W: "price_1P57PCD0vTZ4QB9cO5WhVztQ",
        tk_sf4F8azmCBXwl7Q: "price_1P57PhD0vTZ4QB9cCzOVrMAw",
        tk_p48zy7FDh2Wgih7: "price_1P57P2D0vTZ4QB9cVFEe1h4I",
        tk_rd1Ftx3bYZBTrMV: "price_1P57PoD0vTZ4QB9cz8rgq8go",
        tk_rNNddLQDZ0i3E80: "price_1P57PuD0vTZ4QB9c6ig7IsqY",
        tk_bAc5IxLJxp4Gp5h: "price_1P57Q0D0vTZ4QB9cr2aieW50",
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
  },
  methods: {
    changes(e) {
      this.valuesMap[e.target.name] = e.target.value;
    },
    datachange(e) {
      if (this.valuesMap[e.target.name]) {
        this.tempValues[e.target.name] = this.valuesMap[e.target.name];
        delete this.valuesMap[e.target.name];
        this.sessionTypes = [...this.sessionTypes];
      } else {
        this.valuesMap[e.target.name] =
          this.tempValues[e.target.name] || "Classroom A";
      }
    },
    section2Filter(list) {
      return list.filter((l) => this.isSponser.includes(l.ticketCode));
    },
    section1Filter(list) {
      return list.filter(
        (l) =>
          !this.isSponser.includes(l.ticketCode) &&
          this.section1.includes(l.ticketCode)
      );
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    openmodel: function (code) {
      this.vendorsession1 = false;
      this.vendorsession2 = false;
      this.vendorsession3 = false;
      this.vendorsession4 = false;
      this.website = "";
      this.tempValues = {};
      this.valuesMap = {};
      this.address = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.reserveFormAccept = false;
      (this.sponsers = {
        companyName: "",
        contactNumber: "",
        mailingAddress: "",
        city: "",
        state: "",
        zip: "",
      }),
        (this.vendors = {
          session: "Breakout Session 1",
          sessionType: "Classroom A",
          zip: "",
          state: "",
          city: "",
          mailingAddress: "",
          contactNumber: "",
          companyName: "",
        });
      this.ceus = {
        employersName: "",
        session: "Breakout Session 1",
        sessionType: "Classroom A",
      };
      this.cles = {
        barROll: "",
        session: "Breakout Session 1",
        sessionType: "Classroom A",
      };
      this.regis = {
        companyName: "",
        phone: "",
        city: "",
        state: "",
        session: "Breakout Session 1",
        sessionType: "Classroom A",
      };

      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;

      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (
        this.TicketsResult[this.selected] &&
        this.isSponser.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          company_name: this.sponsers.companyName,
          contact_number: this.sponsers.contactNumber,
          mailing_address: this.sponsers.mailingAddress,
          city: this.sponsers.city,
          state: this.sponsers.state,
          zip: this.sponsers.zip,
        };
      }

      if (
        this.TicketsResult[this.selected] &&
        this.vendorReg.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          company_name: this.vendors.companyName,
          zip: this.vendors.zip,
          state: this.vendors.state,
          city: this.vendors.city,
          mailing_address: this.vendors.mailingAddress,
          contact_number: this.vendors.contactNumber,
        };
      }

      if (
        this.TicketsResult[this.selected] &&
        this.ceu.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          employer_name: this.ceus.employersName,
        };
      }

      if (
        this.TicketsResult[this.selected] &&
        this.cle.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          company_name: this.cles.companyName,
          roll_number: this.cles.barROll,
        };
      }

      if (
        this.TicketsResult[this.selected] &&
        this.regular.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {
          company_name: this.regis.companyName,
          city: this.regis.city,
          state: this.regis.state,
          phone: this.regis.phone,
        };
      }

      if (Object.keys(this.valuesMap).length > 0) {
        console.log(this.valuesMap);
        const listt = [];
        Object.keys(this.valuesMap).forEach((r) => {
          listt.push(r + this.valuesMap[r]);
        });

        payloadToPush.extraInfo["session"] = listt.join(",");
      }

      payloadToPush.extraInfo["website"] = this.website;
      if (
        this.TicketsResult[this.selected] &&
        !this.isSponser.includes(
          this.TicketsResult[this.selected].ticketCode
        ) &&
        !this.vendorReg.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo["freebie"] = this.freevalue;
      }

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";

          setTimeout(() => {
            vm.showsuccess = true;
            vm.$refs.confirmmodal.open();
          }, 1000);
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";

          this.showsuccess = false;
          setTimeout(() => {
            this.$refs.confirmmodal.open();
          }, 1000);
        })
        .finally(() => {
          this.sponsers = {
            companyName: "",
            contactNumber: "",
            mailingAddress: "",
            city: "",
            state: "",
            zip: "",
          };
          this.vendors = {
            companyName: "",
            zip: "",
            state: "",
            city: "",
            mailingAddress: "",
            contactNumber: "",
          };
          this.ceus = {
            employersName: "",
          };
          this.cles = {
            barROll: "",
          };
          this.regis = {
            companyName: "",
            phone: "",
            city: "",
            state: "",
          };
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */
      const vm = this;
      this.isSubmitted = true;
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredLouisianaMBDATrainingConferencePoweringYourProgressTickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            // tk_pppbkHV28k6TBw0: null,
            tk_49sWMBjq2UeZbkR: null,
            tk_qUMnzrm1pKhwdFu: null,
            tk_oF4qGtwAasm77Rb: null,
            tk_K9r9uYGxit1ZJp4: null,
            tk_9yHscylsOe9sdBK: null,
            tk_HvVPABak4EMCb3W: null,
            tk_sf4F8azmCBXwl7Q: null,
            tk_p48zy7FDh2Wgih7: null,
            tk_rd1Ftx3bYZBTrMV: null,
            tk_rNNddLQDZ0i3E80: null,
            tk_bAc5IxLJxp4Gp5h: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
  },
  mounted() {
    this.fetchAllTickets();
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    validity() {
      if (
        this.TicketsResult[this.selected] &&
        this.isSponser.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.sponsers.companyName.trim().length > 0 &&
          this.sponsers.state.trim().length > 0 &&
          this.sponsers.city.trim().length > 0 &&
          this.sponsers.zip.trim().length > 0 &&
          this.sponsers.contactNumber.trim().length > 0 &&
          this.sponsers.mailingAddress.trim().length > 0 &&
          this.invalidcontactnumber
          // &&
          // this.website.trim().length > 0
        );
      }

      if (
        this.TicketsResult[this.selected] &&
        this.vendorReg.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.vendors.companyName.trim().length > 0 &&
          this.vendors.zip.trim().length > 0 &&
          this.vendors.state.trim().length > 0 &&
          this.vendors.city.trim().length > 0 &&
          this.vendors.mailingAddress.trim().length > 0 &&
          this.vendors.contactNumber.trim().length > 0 &&
          this.tellPno1
          // &&
          // this.website.trim().length > 0
        );
      }

      if (
        this.TicketsResult[this.selected] &&
        this.ceu.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.ceus.employersName.trim().length > 0
          // &&
          // this.website.trim().length > 0
        );
      }

      if (
        this.TicketsResult[this.selected] &&
        this.cle.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.cles.barROll.trim().length > 0
          // && this.website.trim().length > 0
        );
      }

      if (
        this.TicketsResult[this.selected] &&
        this.regular.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        return (
          this.regis.companyName.trim().length > 0 &&
          this.regis.city.trim().length > 0 &&
          this.tellPno &&
          this.regis.state.trim().length > 0 &&
          this.regis.phone.trim().length > 0
          // &&
          // this.website.trim().length > 0
        );
      }

      return true;
    },
    tellPno() {
      return (
        (this.regis.phone || "").match(/\d/g) &&
        (this.regis.phone || "").trim().length >= 10
      );
    },
    tellPno1() {
      return (
        (this.vendors.contactNumber || "").match(/\d/g) &&
        (this.vendors.contactNumber || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidemailm() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.vendors.mailingAddress
      );
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invalidemailingaddress() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.sponsers.mailingAddress
      );
    },
    invalidcontactnumber() {
      return (
        (this.sponsers.contactNumber || "").match(/\d/g) &&
        (this.sponsers.contactNumber || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
    meta: [
      {
        name: "description",
        content: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
      },

      {
        name: "keywords",
        content: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/empowered-entrepreneurs-accelerating-success",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}
.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
