<template>
  <div class="main">
    <section
      class="hero-section ptb-100 background-img full-screen banner-1-bg"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="error-content text-center text-white">
              <div class="notfound-404">
                <h1 class="text-white">404</h1>
              </div>
              <h3 class="text-white">Sorry, something went wrong</h3>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <a class="btn google-play-btn" href="https://myle.com/"
                >Go to Homepage</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Page404",

  metaInfo: {
    title: "MYLE | 404 - Page Not Found",

    meta: [
      {
        name: "description",
        content: "MYLE | 404 - Page Not Found",
      },

      {
        name: "keywords",
        content: "MYLE | 404 - Page Not Found",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/404",
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.main {
  font-family: "Quicksand", sans-serif;
}
.banner-1-bg {
  background: url("https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/app-hero-bg.png")
    no-repeat center center / cover !important;
}
.app-store-btn1:hover {
  color: transparent !important;
}
</style>
