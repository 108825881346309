<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>

              <p>
                <b class="font-weight-bold">Ticket Price:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].price
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Service Fees:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].service
                    : "0"
                }}
              </p>
              <p>
                <b class="font-weight-bold">Sales Tax:</b>
                {{
                  TicketsResult[selected] &&
                  tkListData[TicketsResult[selected].ticketCode]
                    ? tkListData[TicketsResult[selected].ticketCode].sales
                    : "0"
                }}
              </p>

              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6 mb-1">
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-6 mt-3">
              <label class="">Age Range</label>
              <select class="form-control" v-model="ageRange">
                <option
                  class="form-control"
                  v-bind:key="ageval + 'b'"
                  v-for="ageval in ageRangesList"
                  :value="ageval"
                >
                  {{ ageval }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mt-3">
              <label class="">Organization (optional)</label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Organization"
                v-model="organization"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  TicketsResult[selected].discount &&
                  TicketsResult[selected].ticketCode == 'tk_5yMTafspWD0H7vR'
                    ? !redeempromo.trim().length || !redeemData
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/BetterTogetherJuneteenthMixer.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section MYLE-lite-green">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                Better Together Juneteenth Mixer
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Friday, June 16th
                <br />
                6:00pm - 9:00pm
              </h1>

              <h1
                class="text-center text-white"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://goo.gl/maps/SByFWwVsJn8S2ukS9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Camping World Stadium - 1 Citrus Bowl Place</a
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Kickoff Juneteenth weekend in unity with Orlando’s Elite!
                Together Central FL’s Black Professional organizations are
                joining forces to celebrate unity

                <br /><br />
                Join us to enjoy:
                <br />
                A Live Music, DJ, Food, Drinks, Photo Opportunities, and most
                importantly each of our organizations!
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                Donate To The Black Business Scavenger Hunt Grant
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in donationTickets(TicketsResult)"
              >
                <div class="card-header MYLE-green white">
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Donate Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-2 mt-5">
        <div class="text-center">
          <a
            href="https://myle.com/juneteenth-407-weekend"
            class="btn solid-btn mb-1 text-center"
            target="_self"
            >Back To Main Page
          </a>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/NavBar";
import SiteFooter from "../../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      ageRangesList: [
        "Under 21",
        "21-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
      ],
      ageRange: "Under 21",
      required_power_needs: "No",
      organization: "",
      phone: "",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      vlist: [],
      individualsData: [
        "tk_ewnhCsJTCkaPmaD",
        // , "tk_5yMTafspWD0H7vR"
      ],
      donationData: [
        "tk_idrrpPsbNZvbKO8",
        "tk_XQo6FI87GSBikSE",
        "tk_Sq3AKlfCevFMz8J",
        "tk_trJe9fgE7BJqGpm",
        "tk_hFwjgnjJ7tYMvBr",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_ewnhCsJTCkaPmaD: {
          messages: ["Ticket Price: $30"],
        },

        // tk_5yMTafspWD0H7vR: {
        //   messages: ["Ticket Price: $0"],
        // },

        tk_idrrpPsbNZvbKO8: {
          messages: ["Ticket Price: $10"],
        },

        tk_XQo6FI87GSBikSE: {
          messages: ["Ticket Price: $25"],
        },

        tk_Sq3AKlfCevFMz8J: {
          messages: ["Ticket Price: $50"],
        },

        tk_trJe9fgE7BJqGpm: {
          messages: ["Ticket Price: $75"],
        },

        tk_hFwjgnjJ7tYMvBr: {
          messages: ["Ticket Price: $100"],
        },
      },

      tkListData: {
        tk_ewnhCsJTCkaPmaD: {
          price: "$30",
          service: "$3.65",
          sales: "$2.19",
        },

        // tk_5yMTafspWD0H7vR: {
        //   price: "$0",
        //   service: "$0",
        //   sales: "$0",
        // },

        tk_idrrpPsbNZvbKO8: {
          price: "$10",
          service: "2.31",
          sales: "$0.8",
        },

        tk_XQo6FI87GSBikSE: {
          price: "$25",
          service: "3.32",
          sales: "$1.84",
        },

        tk_Sq3AKlfCevFMz8J: {
          price: "$50",
          service: "4.99",
          sales: "$3.57",
        },

        tk_trJe9fgE7BJqGpm: {
          price: "$75",
          service: "6.67",
          sales: "$5.31",
        },

        tk_hFwjgnjJ7tYMvBr: {
          price: "$100",
          service: "8.35",
          sales: "$7.04",
        },
      },

      priceIds: {
        tk_ewnhCsJTCkaPmaD: "price_1NBF0aD0vTZ4QB9cyMrv4PVu",
        // tk_5yMTafspWD0H7vR: "price_1NBF0ZD0vTZ4QB9coSSbFCxW",

        tk_idrrpPsbNZvbKO8: "price_1NJuGvD0vTZ4QB9cKerQBKxc",
        tk_XQo6FI87GSBikSE: "price_1NJuGvD0vTZ4QB9cwxCc6eRU",
        tk_Sq3AKlfCevFMz8J: "price_1NJuGwD0vTZ4QB9cUK3hBOao",
        tk_trJe9fgE7BJqGpm: "price_1NJuGxD0vTZ4QB9cZbOqtTtY",
        tk_hFwjgnjJ7tYMvBr: "price_1NJuGyD0vTZ4QB9chh8djBZz",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    donationTickets(list) {
      return list.filter((t) => this.donationData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.reserveFormAccept = false;
      this.ageRange = "Under 21";

      this.organization = "";
      this.phone = "";
      this.fullname = "";
      this.email = "";
      this.isSubmitted = false;
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        extraInfo: {
          promo_code: this.redeempromo,
          agerange: this.ageRange,
          organization: this.organization,
        },
      };

      if (!vm.TicketsResult[vm.selected].discount) {
        delete payloadToPush["extraInfo"]["promo_code"];
      }

      if (vm.vlist.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["shirtSize"] = this.selectedSizes;
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_Juneteenth407_Free_tickets");

      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;

            vm.ageRange = "Under 21";

            vm.organization = "";
            vm.phone = "";
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchBetterTogetherJuneteenthMixerEventTickets_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_ewnhCsJTCkaPmaD: null,
            // tk_5yMTafspWD0H7vR: null,

            tk_idrrpPsbNZvbKO8: null,
            tk_XQo6FI87GSBikSE: null,
            tk_Sq3AKlfCevFMz8J: null,
            tk_trJe9fgE7BJqGpm: null,
            tk_hFwjgnjJ7tYMvBr: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE | Better Together Juneteenth Mixer",

    meta: [
      {
        name: "description",
        content: "MYLE | Better Together Juneteenth Mixer",
      },

      {
        name: "keywords",
        content: "MYLE | Better Together Juneteenth Mixer",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/better-together-juneteenth-mixer",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}
</style>
